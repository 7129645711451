var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "home" } }, [
    _vm.$store.getters.checkUserAccess(_vm.$router, "/statistiques")
      ? _c(
          "div",
          { staticClass: "d-flex flex-row align-items-center flex-wrap" },
          [
            _c("h1", { staticClass: "mr-5 bis" }, [_vm._v("Statistiques")]),
            _c(
              "div",
              { staticClass: "d-flex flex-row filtre-statistique" },
              [
                _c(
                  "div",
                  { staticStyle: { width: "280px" } },
                  [
                    _c("m-form-select", {
                      staticClass: "mx-1",
                      attrs: {
                        name: _vm.$Utils.randomstring("periodSelected"),
                        items: _vm.$store.state.items_period
                      },
                      on: { input: _vm.setFilterDate },
                      model: {
                        value: _vm.periodSelected,
                        callback: function($$v) {
                          _vm.periodSelected = $$v
                        },
                        expression: "periodSelected"
                      }
                    })
                  ],
                  1
                ),
                _c("m-form-date", {
                  staticClass: "mx-1 ml-5",
                  attrs: {
                    label: "Début",
                    name: _vm.$Utils.randomstring("filterDateStart"),
                    formatInput: "DD/MM/YYYY",
                    inline: true
                  },
                  model: {
                    value: _vm.filterDateStart,
                    callback: function($$v) {
                      _vm.filterDateStart = $$v
                    },
                    expression: "filterDateStart"
                  }
                }),
                _c("m-form-date", {
                  staticClass: "mx-1",
                  attrs: {
                    label: "Fin",
                    name: _vm.$Utils.randomstring("filterDateEnd"),
                    formatInput: "DD/MM/YYYY",
                    inline: true
                  },
                  model: {
                    value: _vm.filterDateEnd,
                    callback: function($$v) {
                      _vm.filterDateEnd = $$v
                    },
                    expression: "filterDateEnd"
                  }
                })
              ],
              1
            )
          ]
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "d-flex flex-row row-stats" },
      _vm._l(_vm.stats, function(stat, index) {
        return _c("div", { key: index, staticClass: "mr-2 card-stat mb-4" }, [
          _c(
            "div",
            { staticClass: "container-chiffre h-100 d-flex flex-row" },
            [
              _c("div", { staticClass: "col-md-8 mt-3" }, [
                _c("div", { staticClass: "mb-2" }, [
                  _c("div", { staticClass: "uppercase bis" }, [
                    _vm._v(_vm._s(stat.text))
                  ]),
                  _c("div", { staticClass: "uppercase small-text" }, [
                    _vm._v(
                      " " + _vm._s(stat.sousText ? stat.sousText : "-") + " "
                    )
                  ])
                ]),
                _c("p", { staticClass: "chiffre bis pl-3" }, [
                  _vm._v(" " + _vm._s(stat.chiffreN) + " ")
                ])
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "col-md-4 d-flex flex-column justify-content-between"
                },
                [
                  _c(
                    "div",
                    { staticClass: "delta", class: "delta-" + stat.variation },
                    [_c("div", [_vm._v(_vm._s(stat.delta))])]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "uppercase text-center",
                      class: "variation-" + stat.variation
                    },
                    [_vm._v(" " + _vm._s(stat.variation) + " ")]
                  )
                ]
              )
            ]
          )
        ])
      }),
      0
    ),
    _vm.$store.getters.checkUserAccess(_vm.$router, "/materials")
      ? _c(
          "div",
          { staticClass: "d-flex flex-row" },
          [
            _c("icon", {
              staticClass: "mr-2",
              attrs: {
                width: "22",
                height: "22",
                name: "exclamation-triangle",
                color: "red"
              }
            }),
            _c(
              "div",
              { staticClass: "d-flex flex-row flex-wrap" },
              [
                _vm.alertStock
                  ? _c("span", { staticClass: "bis" }, [
                      _vm._v(" Alerte stock :")
                    ])
                  : _vm._e(),
                _vm._l(_vm.alertStock, function(alert) {
                  return _c(
                    "div",
                    {
                      key: alert.ma_id,
                      staticClass: "color-danger ml-2 pointer",
                      on: {
                        click: function($event) {
                          return _vm.alertGo(alert)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(alert.ma_name) +
                          " (" +
                          _vm._s(alert.ma_stock_available) +
                          "), "
                      )
                    ]
                  )
                })
              ],
              2
            )
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "row mt-4" }, [
      _vm.$store.getters.checkUserAccess(_vm.$router, "/parcels/:en_id")
        ? _c("div", { staticClass: "col-md-4" }, [
            _c(
              "div",
              { staticClass: "card" },
              [
                _c("h6", [
                  _c("span", { staticClass: "number-title" }, [_vm._v("A")]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.parcels.length) +
                      " nouvelles commandes à traiter"
                  )
                ]),
                _c("m-list-simple", {
                  ref: "contactslistParcels",
                  attrs: {
                    dynamic: true,
                    items: _vm.parcels,
                    "item-value": "pa_id",
                    "item-height": 20,
                    limit: _vm.pa_limit,
                    total: _vm.pa_total
                  },
                  on: { itemclick: _vm.parcelClick },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "ths",
                        fn: function(ref) {
                          return [
                            _c("th", [_vm._v("Date")]),
                            _c("th", [_vm._v("Contact")])
                          ]
                        }
                      },
                      {
                        key: "tds",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm
                                    .$moment(item.createdAt)
                                    .format("DD/MM/YYYY HH[H]mm")
                                )
                              )
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatContactNameSimple")(
                                    item,
                                    "co_invoice_"
                                  )
                                )
                              )
                            ])
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1814201610
                  )
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "div",
        {
          class: _vm.$store.getters.checkUserAccess(
            _vm.$router,
            "/parcels/:en_id"
          )
            ? "col-md-5"
            : "col-md-7"
        },
        [
          _c(
            "div",
            { staticClass: "card" },
            [
              _c("h6", [
                _c("span", { staticClass: "number-title" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$store.getters.checkUserAccess(
                        _vm.$router,
                        "/parcels/:en_id"
                      )
                        ? "B"
                        : "A"
                    )
                  )
                ]),
                _vm._v(" Actions à traiter ")
              ]),
              _c("div", { staticClass: "d-flex align-items-end" }, [
                _c(
                  "div",
                  {
                    staticClass: "flex-fill",
                    staticStyle: { "margin-top": "-20px" }
                  },
                  [
                    _c("m-form-text", {
                      attrs: {
                        type: "text",
                        rows: 1,
                        label: "Ajouter une action sur un utilisateur final",
                        placeholder: "Commentaire",
                        "label-position": "top",
                        name: "commentAction"
                      },
                      model: {
                        value: _vm.commentAction,
                        callback: function($$v) {
                          _vm.commentAction = $$v
                        },
                        expression: "commentAction"
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "d-flex align-items-end",
                        staticStyle: { "margin-top": "-10px" }
                      },
                      [
                        _c("m-form-combobox", {
                          staticClass: "flex-grow-1",
                          attrs: {
                            placeholder: "Contact",
                            "label-position": "top",
                            name: _vm.$Utils.randomstring("commentContact"),
                            "store-url":
                              _vm.$config.server_url + "/extranet/2.0/contacts",
                            "store-params": {},
                            "item-value": "co_id",
                            "item-text": _vm.showContactName
                          },
                          model: {
                            value: _vm.commentContact,
                            callback: function($$v) {
                              _vm.commentContact = $$v
                            },
                            expression: "commentContact"
                          }
                        }),
                        _c("div", { staticClass: "flex-grow-0" }, [
                          _vm._v("OU")
                        ]),
                        _c("m-form-combobox", {
                          staticClass: "flex-grow-1",
                          attrs: {
                            placeholder: "Utilisateur final",
                            name: _vm.$Utils.randomstring("commentEnduser"),
                            "store-url":
                              _vm.$config.server_url + "/extranet/2.0/endusers",
                            "store-params": {},
                            "item-value": "en_id",
                            "item-text": "en_fullname"
                          },
                          model: {
                            value: _vm.commentEnduser,
                            callback: function($$v) {
                              _vm.commentEnduser = $$v
                            },
                            expression: "commentEnduser"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary ml-2",
                      on: { click: _vm.addCommentAction }
                    },
                    [_vm._v("Ajouter")]
                  )
                ])
              ]),
              _c("m-list-simple", {
                ref: "actionslist",
                attrs: {
                  dynamic: false,
                  items: _vm.actions,
                  "item-value": "ac_id"
                },
                on: { itemclick: _vm.boxClick },
                scopedSlots: _vm._u([
                  {
                    key: "ths",
                    fn: function(ref) {
                      return [
                        _c("th"),
                        _c("th", [_vm._v("Date")]),
                        _c("th", [_vm._v("Contact")]),
                        _c("th", [_vm._v("Commentaire")])
                      ]
                    }
                  },
                  {
                    key: "tds",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("td", [
                          _c("input", {
                            attrs: { type: "checkbox" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.setDoneToComment(item)
                              }
                            }
                          })
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .$moment(item.createdAt)
                                .format("DD/MM/YYYY HH[H]mm")
                            )
                          )
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatContactNameSimple")(item.co_id)
                            )
                          )
                        ]),
                        _c("td", [_vm._v(_vm._s(item.ac_comment))])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          class: _vm.$store.getters.checkUserAccess(
            _vm.$router,
            "/parcels/:en_id"
          )
            ? "col-md-3"
            : "col-md-5"
        },
        [
          _c(
            "div",
            { staticClass: "card" },
            [
              _c("h6", [
                _c("span", { staticClass: "number-title" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$store.getters.checkUserAccess(
                        _vm.$router,
                        "/parcels/:en_id"
                      )
                        ? "C"
                        : "B"
                    )
                  )
                ]),
                _vm._v(" 10 dernières boxes connectées ")
              ]),
              _c("m-list-simple", {
                ref: "contactslistEndusers",
                attrs: {
                  dynamic: true,
                  items: _vm.boxes,
                  "item-value": "bo_id",
                  "item-height": 20,
                  limit: _vm.bo_limit,
                  total: _vm.bo_total
                },
                on: { itemclick: _vm.boxClick },
                scopedSlots: _vm._u([
                  {
                    key: "ths",
                    fn: function(ref) {
                      return [
                        _c("th", [_vm._v("Date")]),
                        _c("th", [_vm._v("Utilisateur final")])
                      ]
                    }
                  },
                  {
                    key: "tds",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .$moment(item.createdAt)
                                .format("DD/MM/YYYY HH[H]mm")
                            )
                          )
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(item.en_id ? item.en_id.en_fullname : "") +
                              " (N°" +
                              _vm._s(item.en_id ? item.en_id.en_id : "") +
                              ")"
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]
      )
    ]),
    _vm.user &&
    _vm.user.us_type === "LATEAM" &&
    Array.isArray(_vm.user.establishments) &&
    _vm.user.establishments.find(function(establishment) {
      return establishment.es_name.toLowerCase() === "emotivi"
    })
      ? _c("div", { staticClass: "row mt-4" }, [
          _c("h1", { staticClass: "col-12" }, [
            _vm._v("Fonctionnalités administrateur")
          ]),
          _c("div", { staticClass: "col-md-6" }, [
            _c(
              "div",
              { staticClass: "alert alert-secondary d-flex flex-column" },
              [
                _c("box-versions-select"),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.activateAutomaticUpdate()
                      }
                    }
                  },
                  [_vm._v("Activer la mise à jour automatique")]
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "col-md-6" }, [
            _c(
              "div",
              { staticClass: "alert alert-secondary d-flex flex-column" },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.confirm(
                          "Etes-vous certain de vouloir effectuer des sauvegardes de la base de données ?"
                        )
                          ? (_vm.displayStartBackup = true)
                          : (_vm.displayStartBackup = false)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "Faire des sauvegardes de la base de données Emotivi"
                    )
                  ]
                ),
                _vm.displayStartBackup
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "form-group d-flex justify-content-center align-items-center"
                      },
                      [
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            placeholder: "Mot de passe",
                            type: "password",
                            id: "backup_password"
                          }
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.startBackup()
                              }
                            }
                          },
                          [_vm._v("Valider")]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            )
          ]),
          _vm.backup_db
            ? _c(
                "div",
                { staticClass: "col-md-4" },
                [
                  _c("v-remote-terminal", {
                    staticStyle: { width: "100%", height: "500px" },
                    attrs: { socket_response: _vm.backup_db_response }
                  })
                ],
                1
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }