var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: _vm.value ? "show" : "hide",
        style: _vm.value
          ? "display:block;background-color:#3333337a;"
          : "display:none;",
        attrs: { tabindex: "-1", role: "dialog", "aria-hidden": "true" }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-scrollable",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "div",
                {
                  staticClass: "modal-header d-flex flex-row align-items-center"
                },
                [
                  _vm.offer_id > 0
                    ? _c("div", [
                        _c("h1", [_vm._v(" Éditer une offre ")]),
                        _c("p", [
                          _vm._v(
                            "créé le : " +
                              _vm._s(
                                _vm
                                  .$moment(_vm.row_of.createdAt)
                                  .format("DD/MM/YYYY HH[H]mm")
                              )
                          )
                        ])
                      ])
                    : _c("div", [_c("h1", [_vm._v(" Création d'une offre ")])]),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-row align-items-center active"
                    },
                    [
                      _c("m-form-checkbox", {
                        attrs: {
                          label: "Actif",
                          name: _vm.$Utils.randomstring("of_active")
                        },
                        model: {
                          value: _vm.row_of.of_active,
                          callback: function($$v) {
                            _vm.$set(_vm.row_of, "of_active", $$v)
                          },
                          expression: "row_of.of_active"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", [
                  _c("h6", [
                    _c("span", { staticClass: "number-title" }, [_vm._v("1")]),
                    _vm._v("Nom et type")
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-8" },
                      [
                        _c("m-form-text", {
                          attrs: {
                            label: "Nom de l'offre",
                            "label-position": "top",
                            name: "of_name"
                          },
                          model: {
                            value: _vm.row_of.of_name,
                            callback: function($$v) {
                              _vm.$set(_vm.row_of, "of_name", $$v)
                            },
                            expression: "row_of.of_name"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-4 position-relative" },
                      [
                        _c("m-form-checkbox", {
                          staticClass: "position-absolute",
                          staticStyle: {
                            right: "15px",
                            top: "15px",
                            "z-index": "10"
                          },
                          attrs: { label: "Modifier" },
                          model: {
                            value: _vm.enableOfferKeyModification,
                            callback: function($$v) {
                              _vm.enableOfferKeyModification = $$v
                            },
                            expression: "enableOfferKeyModification"
                          }
                        }),
                        _c("m-form-text", {
                          attrs: {
                            label: "Identifiant",
                            "label-position": "top",
                            name: "of_key",
                            id: "of_key",
                            disabled: !_vm.enableOfferKeyModification
                          },
                          model: {
                            value: _vm.row_of.of_key,
                            callback: function($$v) {
                              _vm.$set(_vm.row_of, "of_key", $$v)
                            },
                            expression: "row_of.of_key"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-8" },
                      [
                        _c("m-form-text", {
                          attrs: {
                            label: "Nom sur la facture",
                            "label-position": "top",
                            name: "of_invoice_name"
                          },
                          model: {
                            value: _vm.row_of.of_invoice_name,
                            callback: function($$v) {
                              _vm.$set(_vm.row_of, "of_invoice_name", $$v)
                            },
                            expression: "row_of.of_invoice_name"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-4" },
                      [
                        _c("m-form-text", {
                          attrs: {
                            label: "Compte comptable",
                            "label-position": "top",
                            name: "of_accounting"
                          },
                          model: {
                            value: _vm.row_of.of_accounting,
                            callback: function($$v) {
                              _vm.$set(_vm.row_of, "of_accounting", $$v)
                            },
                            expression: "row_of.of_accounting"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-12" },
                      [
                        _c("m-form-text", {
                          attrs: {
                            type: "textarea",
                            label: "Description",
                            "label-position": "top",
                            name: "description"
                          },
                          model: {
                            value: _vm.row_of.of_description,
                            callback: function($$v) {
                              _vm.$set(_vm.row_of, "of_description", $$v)
                            },
                            expression: "row_of.of_description"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _c(
                  "div",
                  [
                    _c("h6", { staticClass: "mt-5 mb-3" }, [
                      _c("span", { staticClass: "number-title" }, [
                        _vm._v("2")
                      ]),
                      _vm._v(" Services et produits")
                    ]),
                    _vm._l(_vm.ProductsPrice, function(ofpr, index) {
                      return _c("div", { key: ofpr.ofpr_id }, [
                        _c(
                          "div",
                          {
                            staticClass: "row d-flex flex-row align-items-end"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "col-md-9" },
                              [
                                _c("m-form-combobox", {
                                  attrs: {
                                    label: "Produit",
                                    "label-position": "top",
                                    name: _vm.$Utils.randomstring("pr_id"),
                                    "store-url":
                                      _vm.$config.server_url +
                                      "/extranet/2.0/products",
                                    "store-params": {},
                                    "item-value": "pr_id",
                                    "item-text": "pr_name"
                                  },
                                  model: {
                                    value: ofpr.pr_id,
                                    callback: function($$v) {
                                      _vm.$set(ofpr, "pr_id", $$v)
                                    },
                                    expression: "ofpr.pr_id"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col-md-3" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-danger",
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteProductConfirm(
                                        index,
                                        ofpr
                                      )
                                    }
                                  }
                                },
                                [_vm._v("Supprimer le produit")]
                              )
                            ])
                          ]
                        ),
                        ofpr.pr_id.pr_type === "SUBSCRIPTION"
                          ? _c("div", [
                              _c(
                                "div",
                                { staticClass: "row d-flex align-items-end" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-3 uppercase" },
                                    [_vm._v("Frais de dossier/Caution")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-2" },
                                    [
                                      _c("m-form-text", {
                                        attrs: {
                                          label: "Prix HT",
                                          "label-position": "top",
                                          name: "priceht"
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.calculInitialPriceTtc(
                                              index
                                            )
                                          }
                                        },
                                        model: {
                                          value: ofpr.ofpr_initial_price_ht,
                                          callback: function($$v) {
                                            _vm.$set(
                                              ofpr,
                                              "ofpr_initial_price_ht",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ofpr.ofpr_initial_price_ht"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-2" },
                                    [
                                      _c("m-form-text", {
                                        attrs: {
                                          label: "TVA",
                                          "label-position": "top",
                                          name: "pricetva",
                                          disabled: ""
                                        },
                                        model: {
                                          value: _vm.tva,
                                          callback: function($$v) {
                                            _vm.tva = $$v
                                          },
                                          expression: "tva"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-2" },
                                    [
                                      _c("m-form-text", {
                                        attrs: {
                                          label: "Prix TTC",
                                          "label-position": "top",
                                          name: "pricettc",
                                          disabled: ""
                                        },
                                        model: {
                                          value: ofpr.initialPrice_ttc,
                                          callback: function($$v) {
                                            _vm.$set(
                                              ofpr,
                                              "initialPrice_ttc",
                                              $$v
                                            )
                                          },
                                          expression: "ofpr.initialPrice_ttc"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "row d-flex align-items-end" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-3 uppercase" },
                                    [_vm._v("Forfait")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-2" },
                                    [
                                      _c("m-form-text", {
                                        attrs: {
                                          label: "Prix HT",
                                          "label-position": "top",
                                          name: "priceht"
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.calculSubscriptionPriceTtc(
                                              index
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            ofpr.ofpr_subscription_price_ht,
                                          callback: function($$v) {
                                            _vm.$set(
                                              ofpr,
                                              "ofpr_subscription_price_ht",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ofpr.ofpr_subscription_price_ht"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-2" },
                                    [
                                      _c("m-form-text", {
                                        attrs: {
                                          label: "TVA",
                                          "label-position": "top",
                                          name: "pricetva",
                                          disabled: ""
                                        },
                                        model: {
                                          value: _vm.tva,
                                          callback: function($$v) {
                                            _vm.tva = $$v
                                          },
                                          expression: "tva"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-2" },
                                    [
                                      _c("m-form-text", {
                                        attrs: {
                                          label: "Prix TTC",
                                          "label-position": "top",
                                          name: "pricettc",
                                          disabled: ""
                                        },
                                        model: {
                                          value: ofpr.subscriptionPrice_ttc,
                                          callback: function($$v) {
                                            _vm.$set(
                                              ofpr,
                                              "subscriptionPrice_ttc",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ofpr.subscriptionPrice_ttc"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-2" },
                                    [
                                      _c("m-form-select", {
                                        attrs: {
                                          label: "Cycle du forfait",
                                          "label-position": "top",
                                          name: _vm.$Utils.randomstring(
                                            "ofpr_subscription_cycle_billing"
                                          ),
                                          items: _vm.itemCycleSubscription
                                        },
                                        model: {
                                          value:
                                            ofpr.ofpr_subscription_cycle_billing,
                                          callback: function($$v) {
                                            _vm.$set(
                                              ofpr,
                                              "ofpr_subscription_cycle_billing",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ofpr.ofpr_subscription_cycle_billing"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        ofpr.pr_id.pr_type === "SERVICE" ||
                        ofpr.pr_id.pr_type === "HARDWARE"
                          ? _c("div", [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-md-2" },
                                  [
                                    _c("m-form-text", {
                                      attrs: {
                                        label: "Prix HT",
                                        "label-position": "top",
                                        name: "priceht"
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.calculInitialPriceTtc(
                                            index
                                          )
                                        }
                                      },
                                      model: {
                                        value: ofpr.ofpr_initial_price_ht,
                                        callback: function($$v) {
                                          _vm.$set(
                                            ofpr,
                                            "ofpr_initial_price_ht",
                                            $$v
                                          )
                                        },
                                        expression: "ofpr.ofpr_initial_price_ht"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-md-2" },
                                  [
                                    _c("m-form-text", {
                                      attrs: {
                                        label: "TVA",
                                        "label-position": "top",
                                        name: "pricetva",
                                        disabled: ""
                                      },
                                      model: {
                                        value: _vm.tva,
                                        callback: function($$v) {
                                          _vm.tva = $$v
                                        },
                                        expression: "tva"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-md-2" },
                                  [
                                    _c("m-form-text", {
                                      attrs: {
                                        label: "Prix TTC",
                                        "label-position": "top",
                                        name: "pricettc",
                                        disabled: ""
                                      },
                                      model: {
                                        value: ofpr.initialPrice_ttc,
                                        callback: function($$v) {
                                          _vm.$set(
                                            ofpr,
                                            "initialPrice_ttc",
                                            $$v
                                          )
                                        },
                                        expression: "ofpr.initialPrice_ttc"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ])
                          : _vm._e(),
                        _c("hr")
                      ])
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        on: { click: _vm.addProduct }
                      },
                      [_vm._v("Ajouter produit / service")]
                    )
                  ],
                  2
                )
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "modal-footer d-flex flex-row justify-content-between"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      attrs: { type: "button" },
                      on: { click: _vm.deleteConfirmWin }
                    },
                    [_vm._v(" Supprimer ")]
                  ),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button" },
                        on: { click: _vm.cancelWin }
                      },
                      [_vm._v(" Annuler ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary ml-2",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.saveWin(false)
                          }
                        }
                      },
                      [_vm._v(" Enregistrer ")]
                    )
                  ])
                ]
              )
            ])
          ]
        ),
        _c("m-message-dialog", {
          attrs: { title: "Erreur", text: _vm.dialogErrTxt },
          model: {
            value: _vm.dialogErr,
            callback: function($$v) {
              _vm.dialogErr = $$v
            },
            expression: "dialogErr"
          }
        }),
        _c("m-confirm-dialog", {
          attrs: {
            text: "Souhaitez-vous cette offre ?",
            title: "Confirmation",
            width: "500px"
          },
          on: {
            canceled: function($event) {
              _vm.confirmdelete = false
            },
            confirmed: function($event) {
              return _vm.deleteWin()
            }
          },
          model: {
            value: _vm.confirmdelete,
            callback: function($$v) {
              _vm.confirmdelete = $$v
            },
            expression: "confirmdelete"
          }
        }),
        _c("m-confirm-dialog", {
          attrs: {
            text:
              "Souhaitez-vous le produit " +
              _vm.productToDelete.pr_id.pr_name +
              " ?",
            title: "Confirmation",
            width: "500px"
          },
          on: {
            canceled: function($event) {
              _vm.confirmdeleteProduct = false
            },
            confirmed: function($event) {
              return _vm.deleteProduct()
            }
          },
          model: {
            value: _vm.confirmdeleteProduct,
            callback: function($$v) {
              _vm.confirmdeleteProduct = $$v
            },
            expression: "confirmdeleteProduct"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }