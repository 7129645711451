var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "div",
      {
        staticClass: "modal",
        class: _vm.value ? "show" : "hide",
        style: _vm.value
          ? "display:block;background-color:#3333337a;"
          : "display:none;",
        attrs: { tabindex: "-1", role: "dialog", "aria-hidden": "true" }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-scrollable",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(0),
              _vm.eventsChanges.recurrent
                ? _c("div", { staticClass: "modal-body" }, [
                    _vm._m(1),
                    _c("p", [
                      _vm._v(
                        "Liste des événements récurrents (" +
                          _vm._s(_vm.eventsChanges.events.length) +
                          ") :"
                      )
                    ]),
                    _c("div", { staticClass: "card pt-3" }, [
                      _vm._m(2),
                      _c("hr", { staticClass: "mt-2 mb-0" }),
                      _c(
                        "div",
                        { staticClass: "recurring-events-content" },
                        _vm._l(_vm.eventsChanges.events, function(
                          event,
                          index
                        ) {
                          return _c("div", { key: index }, [
                            _c(
                              "div",
                              {
                                class: [
                                  "row",
                                  index === 0 ? "event-target" : ""
                                ],
                                staticStyle: { height: "35px" }
                              },
                              [
                                _c("div", { staticClass: "col-5" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.simplifyEventsDate(event.start)
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.simplifyEventsTime(
                                          event.start,
                                          event.end
                                        )
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("div", { staticClass: "col" }, [
                                  _vm._v(" " + _vm._s(event.title) + " ")
                                ])
                              ]
                            ),
                            _c("hr", { staticClass: "m-0 mb-1" })
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                : _c("div", { staticClass: "modal-body" }, [
                    _c("p", [
                      _vm._v(
                        "Voulez-vous vraiment changer l'horaire de cet événement ?"
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " Ancienne date : " +
                          _vm._s(
                            _vm.simplifyEventsDate(
                              _vm.eventsChanges.events[0].start
                            )
                          ) +
                          ", " +
                          _vm._s(
                            _vm.simplifyEventsTime(
                              _vm.eventsChanges.events[0].start,
                              _vm.eventsChanges.events[0].end
                            )
                          ) +
                          " "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " Nouvelle date : " +
                          _vm._s(
                            _vm.simplifyEventsDate(
                              _vm.eventsChanges.newStartDate
                            )
                          ) +
                          ", " +
                          _vm._s(
                            _vm.simplifyEventsTime(
                              _vm.eventsChanges.newStartDate,
                              _vm.eventsChanges.newEndDate
                            )
                          ) +
                          " "
                      )
                    ])
                  ]),
              _c(
                "div",
                {
                  staticClass:
                    "modal-footer d-flex flex-row justify-content-between"
                },
                [
                  _vm.eventsChanges.recurrent
                    ? _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary mr-3",
                            attrs: { type: "button" },
                            on: { click: _vm.saveWin }
                          },
                          [_vm._v("Cette occurence uniquement")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.saveWin("all")
                              }
                            }
                          },
                          [_vm._v("Toutes les occurences")]
                        )
                      ])
                    : _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { type: "button" },
                            on: { click: _vm.saveWin }
                          },
                          [_vm._v("Enregistrer")]
                        )
                      ]),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button" },
                        on: { click: _vm.cancelWin }
                      },
                      [_vm._v("Annuler")]
                    )
                  ])
                ]
              )
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "modal-header d-flex flex-row align-items-center" },
      [_c("div", [_c("h1", [_vm._v("Modification d'événement(s)")])])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "alert alert-warning text-center" }, [
      _c("span", [
        _vm._v(
          " Vous êtes sur le point de modifier un événement qui possède plusieurs occurences. Souhaitez vous modifier toutes ses occurences ou seulement cette occurence ? "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-5" }, [_c("b", [_vm._v("DATE")])]),
      _c("div", { staticClass: "col" }, [_c("b", [_vm._v("ACTIVITE")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }