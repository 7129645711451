<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div>
              <h1>Envoyer un message à toutes les boxes</h1>
            </div>
          </div>
          <div class="modal-body">
            <div>
              <h6><span class="number-title">1</span>Établissement</h6>
              <div class="row">
                <div class="col-md-3 d-flex justify-content-center">
                  <div class="container-avatar" :style="styleAvatar"></div>
                </div>
                <div class="col-md-9">
                  <m-form-select
                    label="Établissements"
                    label-position="top"
                    :name="$Utils.randomstring('establishmentsSelected')"
                    v-model="establishmentsSelected"
                    :items="establishments"
                  ></m-form-select>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <h6><span class="number-title">2</span>Options</h6>
              <div class="d-flex flex-column">
                <m-form-checkbox label="Message de type ADMIN" :name="$Utils.randomstring('isAdmin')" v-model="isAdmin"></m-form-checkbox>
                <m-form-checkbox
                  v-if="isAdmin"
                  label="Confirmation de lecture"
                  :name="$Utils.randomstring('acknowledgment')"
                  v-model="acknowledgment"
                ></m-form-checkbox>
                <m-form-minutes
                  v-if="isAdmin"
                  class="mx-1"
                  label="Durée d'affichage (minutes:secondes)"
                  :name="$Utils.randomstring('duration')"
                  v-model="duration"
                ></m-form-minutes>
              </div>
            </div>
            <div class="mt-4">
              <form action enctype="multipart/form-data" method="post">
                <h6><span class="number-title">3</span>Message</h6>
                <m-form-text type="textarea" v-model="message" name="message" class=""></m-form-text>
                <h6><span class="number-title mt-4">4</span>Image</h6>
                <div v-show="!photoJoined">
                  <label class="fileContainer d-flex justify-content-center" for="upload">
                    <div class="align-self-center">Choisir un fichier</div>
                  </label>
                  <input
                    type="file"
                    class="form-control inputImage"
                    @change="handleFiles()"
                    id="upload"
                    name="file"
                    ref="myFiles"
                    style="display: none"
                  />
                </div>
                <div v-show="photoJoined">
                  <label id="container-media" for="upload" ref="previewContainer" style="width: 100%">
                    <div id="container-media-content" ref="preview"></div>
                  </label>
                  <button class="btn btn-rotate mr-2 btn-img rotate-left" v-on:click.prevent="rotateFiles($event, 'left')">
                    <icon name="undo-alt"></icon>
                  </button>
                  <button class="btn btn-rotate mr-2 btn-img rotate-right" v-on:click.prevent="rotateFiles($event, 'right')">
                    <icon name="redo-alt"></icon>
                  </button>
                  <button class="btn btn-danger mr-2 btn-img trash" v-on:click.prevent="deleteFiles($event)">
                    <icon name="trash-alt"></icon>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between">
            <div></div>
            <div>
              <button type="button" class="btn btn-secondary" @click="cancelWin">Annuler</button>
              <!-- <button type="button" class="btn btn-primary ml-2" @click="saveWin(false)">Envoyer</button> -->
              <button type="button" class="btn btn-primary ml-2" @click="sendMessage">Envoyer</button>
            </div>
          </div>
        </div>
      </div>
      <!-- <m-message-dialog v-model="dialogErr" title="Erreur" :text="dialogErrTxt"></m-message-dialog> -->
      <div style="display: none">
        <canvas ref="canvas" width="1024" height="768"></canvas>
      </div>
    </div>
  </transition>
</template>

<script>
const IMAGE_MAX = 1024;

export default {
  name: "ProductWinEdit",
  components: {},
  props: {
    value: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      establishments: [],
      establishmentsSelected: {},
      en_ids: [],
      row_es: {},
      message: "",
      isAdmin: true,
      // fileSelected: { image: null, binary: null },
      styleAvatar: "",
      image: null,
      photo: true,
      imageSize: { width: 0, height: 0 },
      angle: 0,
      photoJoined: false,
      acknowledgment: false,
      duration: "00:00"
    };
  },
  watch: {
    establishmentsSelected(val) {
      // console.log("val (es_id)", val, this.$store.state.user.establishments);
      let es = this.$_.find(this.$store.state.user.establishments, { es_id: val });
      if (es) this.styleAvatar = "background-image:url('" + this.$config.server_url + es.es_avatar + "')";
      this.row_es = es;
      // console.log("row_es", this.row_es);
    },
    duration(val) {
      //console.log("durationToSecond", this.$moment.duration("00:" + val).asSeconds() * 1);
    }
  },
  computed: {
    durationToSecond() {
      return this.$moment.duration("00:" + this.duration).asSeconds() * 1;
    }
  },

  mounted() {
    let establishments = [];
    for (let i = 0; i < this.$store.state.user.establishments.length; i++) {
      const row_es = this.$store.state.user.establishments[i];
      establishments.push({
        text: row_es.es_name,
        value: row_es.es_id
      });
    }
    this.establishments = establishments;
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  created() {},
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },

    cancelWin() {
      this.resetData();
      // this.$router.push("/boxes");
      this.$emit("input", false);
    },

    deleteFiles() {
      if (this.photo) {
        //document.querySelector(".containerToHide").style.display = "block";
        document.querySelector(".trash").style.display = "none";
        document.querySelector(".rotate-left").style.display = "none";
        document.querySelector(".rotate-right").style.display = "none";
        this.image.src = "";
        this.image = null;
        this.photoJoined = false;
      }
    },
    //   handleFiles($event) {
    handleFiles() {
      if (this.$refs.myFiles.files.length == 0) return;
      let file = this.$refs.myFiles.files[0];
      // console.log("file :>> ", file);
      let isImage = /^image\//.test(file.type);

      //  //let isVideo = /^video\//.test(file.type);
      //  this.fileSelected.image = $event[0];
      //  let isImage = /^image\//.test(this.fileSelected.image.type);

      //console.log("image size : ", file.size);
      // taille des médias bloquée à 5,1Mo.
      // if (file.size > 5064986) return alert(this.lang === "fr" ? this.pageText.alertImage_tooLarge.fr : this.pageText.alertImage_tooLarge.en);
      if (!isImage) {
        // return alert(this.lang === "fr" ? this.pageText.alertImage.fr : this.pageText.alertImage.en);
        //   this.fileSelected = { image: null, binary: null };
        //   this.deleteFiles();
        //   return;
      }
      if (document.querySelector("#media-to-send")) document.querySelector("#media-to-send").remove();
      let img = new Image();
      img.style.width = "100%";
      img.style.border = "1px solid #868b90";
      img.id = "media-to-send";
      this.photo = isImage ? true : false;
      let btn = document.querySelectorAll(".btn-img");
      btn.forEach(function(el) {
        el.style.display = "block";
      });
      this.photoJoined = true;
      // this.showFile(img, file);
      // let img = new Image();
      const fileReader_base64 = new FileReader();
      fileReader_base64.onload = e => {
        img.src = fileReader_base64.result;
        setTimeout(() => {
          this.image = img;
          this.imageSize.width = img.width;
          this.imageSize.height = img.height;
          this.$refs.preview.appendChild(img);
          let { w, h } = this._calculateWidthHeightWithMax(IMAGE_MAX);
          let ctx = this.$refs.canvas.getContext("2d");
          ctx.canvas.width = w;
          ctx.canvas.height = h;
          ctx.drawImage(img, 0, 0, w, h);
        }, 500);
      };
      fileReader_base64.readAsDataURL(file);
    },
    rotateFiles(event, sens) {
      this.angle = sens === "right" ? this.angle + 90 : this.angle - 90;
      if (this.angle === 360 || this.angle === -360) this.angle = 0;
      let max = this.$refs.previewContainer.offsetWidth;
      let translateY = 0,
        w = 0,
        h = 0;
      let { w: wOri, h: hOri } = this._calculateWidthHeightWithMax(IMAGE_MAX);
      let ctx = this.$refs.canvas.getContext("2d");
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.save();

      if (this.angle === 90 || this.angle === -90 || this.angle === 270 || this.angle === -270) {
        w = Math.ceil(max / (this.imageSize.height / this.imageSize.width));
        h = max;
        translateY = (w - h) / 2;
        this.$refs.previewContainer.style.height = w + "px";
        ctx.canvas.width = hOri;
        ctx.canvas.height = wOri;
        ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2);
        ctx.rotate((this.angle * Math.PI) / 180);
        ctx.drawImage(this.image, -ctx.canvas.width / 2, -ctx.canvas.height / 2, wOri, hOri);
      }
      if (this.angle === 0 || this.angle === 180 || this.angle === -180) {
        w = max;
        h = Math.ceil(max / (this.imageSize.width / this.imageSize.height));
        this.$refs.previewContainer.style.height = h + "px";
        ctx.canvas.width = wOri;
        ctx.canvas.height = hOri;
        ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2);
        ctx.rotate((this.angle * Math.PI) / 180);
        ctx.drawImage(this.image, -ctx.canvas.width / 2, -ctx.canvas.height / 2, ctx.canvas.width, ctx.canvas.height);
      }
      document.querySelector("#media-to-send").style.width = w + "px";
      document.querySelector("#media-to-send").style.height = h + "px";
      this.$refs.preview.style.transform = "translate(0," + translateY + "px) rotate(" + this.angle + "deg)";

      ctx.restore();
    },
    _calculateWidthHeightWithMax(max) {
      let w = this.imageSize.width,
        h = this.imageSize.height;
      if (this.imageSize.width > this.imageSize.height) {
        w = max;
        h = Math.ceil(max / (this.imageSize.width / this.imageSize.height));
      } else {
        w = Math.ceil(max / (this.imageSize.height / this.imageSize.width));
        h = IMAGE_MAX;
      }
      return { w, h };
    },

    async sendMessage() {
      //si ya pas de pseudo, on retourne
      // if (!this.pseudo) return alert(this.lang === "fr" ? this.pageText.alertPseudo.fr : this.pageText.alertPseudo.en);
      //si ya pas de message ni d'image on retourne
      if (!this.message && !this.image) return; // || (!this.photo && !this.messageVideoData)
      // // message photo ou video
      //  if (this.photo) {
      //    if (!this.image)
      //      this.waitingSpinner("flex", this.lang === "fr" ? this.pageText.spinner_send_message.fr : this.pageText.spinner_send_message.en);
      //    else this.waitingSpinner("flex", this.lang === "fr" ? this.pageText.spinner_send_image.fr : this.pageText.spinner_send_image.en);
      //  } else {
      //    this.waitingSpinner("flex", this.lang === "fr" ? this.pageText.spinner_send_video.fr : this.pageText.spinner_send_video.en);
      //    // if (this.message) this.messageVideoData.message = this.message;
      //  }
      //  let postMessage = {
      //    me_text: this.message,
      //    me_pseudo: this.pseudo,
      //    avatar_uuid: this.$store.state.user.uuid,
      //    avatar_data: this.$store.state.user.avatar,
      //    en_id: this.$store.state.enduser.en_id,
      //    me_type: this.image ? "PHOTO" : "TEXT"
      //  };
      //  try {
      //    let response = await this.$axios.post(this.$config.server_url + "/webapp/2.0/messages", postMessage, {
      //      headers: {
      //        "x-auth-accesstoken": this.$store.state.accesstoken
      //      }
      //    });
      //    if (!response.data || !response.data.data) return;
      //    let row_me = response.data.data;

      //    // on envoie le media
      //    if (this.image) {
      //      try {
      //        // row_me.angle = this.angle;
      //        let data = {
      //          me_id: row_me.me_id,
      //          media: this.$refs.canvas.toDataURL("image/jpeg", 1.0) //row_me.me_type === "VIDEO" ? this.videoBase64 : this.imageBase64
      //        };
      //        await this.$axios.post(this.$config.server_url + "/webapp/2.0/messages/media", data, {
      //          headers: {
      //            "x-auth-accesstoken": this.$store.state.accesstoken
      //          }
      //        });
      //      } catch (error) {
      //        console.error(error);
      //      }
      //    }
      if (!this.establishmentsSelected) return;

      let data = {
        es_id: this.establishmentsSelected,
        me_type: this.image ? "PHOTO" : "TEXT",
        me_text: this.message,
        me_isAdmin: this.isAdmin,
        me_duration: this.durationToSecond,
        me_acknowledgment: this.acknowledgment
      };
      try {
        let response;
        response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/messages/establishment", data);
        if (response.data.err) {
          this.$store.dispatch("showDialogError", response.data.err.message);
          return;
        }
        let rows_me = response.data.data;
        if (this.photoJoined) {
          // from webapp
          let dataMedia = {
            rows_me: rows_me,
            media: this.$refs.canvas.toDataURL("image/jpeg", 1.0) //row_me.me_type === "VIDEO" ? this.videoBase64 : this.imageBase64
          };
          await this.$axios.post(this.$config.server_url + "/extranet/2.0/messages/establishment/media", dataMedia);
        }
        // console.log("response", response);
        this.resetData();
        // this.image = null;
        // this.$router.push("/boxes");
        this.$emit("input", false);
      } catch (error) {
        console.error(error);
      }
    },

    resetData() {
      this.image = null;
      this.photo = true;
      this.message = "";
      this.isAdmin = true;
      //  this.messageVideoData = null;
      this.photoJoined = false;
      if (document.querySelector("#media-to-send")) document.querySelector("#media-to-send").remove();
      // this.fileSelected = { image: null, binary: null };
      this.styleAvatar = "";
      this.establishmentsSelected = {};
      this.duration = "00:00";
      this.acknowledgment = false;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";
.container-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: 8px;
  background-position: center;
  background-size: cover;
  border: 1px solid #b5b5b5;
  box-shadow: 2px 2px 6px 0px #d0d0d0;
}

// from WEBAPP
.avatar-message {
  width: 90%;
  margin: auto;
}
.btn-envoyer {
  width: 100%;
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: 500;
}
input {
  margin-top: 20px;
}
.colWithoutPadding {
  padding-right: 10px;
}
.fileContainer {
  height: 50px;
  background-color: #ffffff;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 0;
  align-items: flex-end;
  border: 1px solid #ced4da;
  //   background-image: url("../../../public/assets/addphoto.png");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}
.labelImage {
  color: #6d6c64;
  font-size: 20px;
  padding: 10px;
  margin-bottom: 0;
}
textarea {
  font-size: 20px;
}
.imagetosend {
  width: 100%;
  margin-top: 20px;
  border: 1px solid gray;
}
.titre {
  margin-bottom: 0;
}
.btn-img {
  padding: 6px;
  display: none;
  position: absolute;
  //   bottom: 62px;
  bottom: 0px;
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
  border: 1px solid #b7b5b5;
}
.rotate-left {
  right: 150px;
}
.rotate-right {
  right: 110px;
}
.trash {
  right: 65px;
}
.btn-rotate {
  background-color: #ffffff8f;
}
#media-to-send {
  transform-origin: left;
}
/****** toggle button  ******/
/* The switch - the box around the slider */
.switch {
  // float: left;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  min-width: 60px;
  margin-right: 10px;
  margin-left: 10px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #6da;
}

input:focus + .slider {
  box-shadow: 0 0 1px #6da;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.container-logout {
  text-align: left;
}
.btn-logout {
  text-align: left;
  background-color: #ffffff00;
  border: 0;
}
.iconMarginRight {
  width: 40px;
  width: 40px;
  height: 40px;
  opacity: 0.2;
}
</style>
