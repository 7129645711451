var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("m-form-select", {
        attrs: {
          label: _vm.setLabel(),
          name: _vm.$Utils.randomstring("boxversion"),
          items: _vm.boxVersionsObject
        },
        on: { input: _vm.setBoxVersion },
        model: {
          value: _vm.selectedBoxVersion,
          callback: function($$v) {
            _vm.selectedBoxVersion = $$v
          },
          expression: "selectedBoxVersion"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }