<template>
  <div
    class="modal"
    :class="value ? 'show' : 'hide'"
    :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content" :style="'width:' + width">
        <div class="modal-header">
          <h3 class="pt-2 ml-3">{{ title }}</h3>
        </div>
        <div class="modal-body">
          <p>{{ text }}</p>
          <p>{{ sousText ? sousText : "" }}</p>
        </div>
        <div class="modal-footer">
          <button class="btn btn-reverse" @click="cancelWin">{{ cancelTextButton }}</button>
          <button class="btn btn-primary" @click="deleteWin">{{ successTextButton }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mconfirmdialog",
  props: ["value", "title", "text", "sousText", "width", "cancelText", "successText"],
  data() {
    return {
      dialog: false,
      cancelTextButton: "Annuler",
      successTextButton: "Ok"
    };
  },
  mounted() {
    if (this.cancelText) this.cancelTextButton = this.cancelText;
    if (this.successText) this.successTextButton = this.successText;
  },
  watch: {
    value: function(val) {
      this.dialog = val;
    }
  },
  components: {},
  methods: {
    cancelWin() {
      this.$emit("canceled");
    },
    deleteWin() {
      this.$emit("confirmed");
    }
  }
};
</script>
<style lang="scss"></style>
