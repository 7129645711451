<template>
  <div class="container">
    <div class="container-login">
      <div class="row">
        <div class="col-md-6 container-img-login">
          <img src="images/emotivi.png" class="img-login img-fluid" alt />
        </div>
        <div class="col-md-6 container-form-login d-flex align-items-center justify-content-center">
          <div class="container2-form-login">
            <div class="d-flex mb-3">
              <div v-if="$route.name === 'resetpass'" class="d-flex flex-column">
                <h3 class="mb-0">Modifier votre mot de passe</h3>
              </div>
              <div v-else class="d-flex flex-column">
                <h1 class="mb-0">Se connecter</h1>
                <p>Si vous avez déjà un compte</p>
              </div>
            </div>
            <div ref="part1" v-show="currentPart == 'part1'">
              <m-form-text label="Identifiant" label-position="top" v-model="us_login" name="login" autocomplete></m-form-text>
              <m-form-text type="password" label="Mot de passe" label-position="top" v-model="us_password" autocomplete name="password"></m-form-text>
              <div ref="alert" v-if="alert_txt">{{ alert_txt }}</div>
              <div class="d-flex justify-content-end">
                <a class="color-emotivi-red" @click="currentPart = 'part2'">Mot de passe oublié</a>
              </div>
              <div>
                <button @click="login()" class="btn btn-primary btn-connexion">Se connecter</button>
              </div>
            </div>

            <div ref="part2" v-show="currentPart == 'part2'">
              <m-form-text label="Identifiant ou Email" label-position="top" v-model="us_login" name="login" autocomplete></m-form-text>
              <div ref="alert" v-if="alert_txt_part2">{{ alert_txt_part2 }}</div>
              <div>
                <button @click="currentPart = 'part1'" class="btn btn-secondary">Annuler</button>
                <button @click="sendPassword" class="btn btn-info">Envoyer le mot de passe</button>
              </div>
            </div>

            <div ref="part3" v-show="currentPart == 'part3'">
              Vous allez recevoir un mail le plus rapidement possible, vous permettant de mettre à jour votre mot de passe.
              <div>
                <button color="black" @click="currentPart = 'part1'" class="btn btn-primary">Se connecter maintenant</button>
              </div>
            </div>

            <div ref="part4" v-show="currentPart == 'part4'">
              <div v-if="!alert_txt_part4">
                <m-form-text
                  type="password"
                  label="Nouveau mot de passe"
                  label-position="top"
                  v-model="us_password1"
                  autocomplete
                  name="password"
                ></m-form-text>
                <m-form-text
                  type="password"
                  label="Ressaisir le nouveau mot de passe"
                  label-position="top"
                  v-model="us_password2"
                  autocomplete
                  name="password"
                ></m-form-text>
                <button v-if="!challengeError" color="black" @click="newpassword" class="btn btn-primary mt-4">
                  Redéfinir le nouveau mot de passe
                </button>
              </div>
              <div ref="alert" v-if="alert_txt_part4">{{ alert_txt_part4 }}</div>
            </div>

            <div ref="part5" v-show="currentPart == 'part5'">
              C'est bon !
              <br />Votre nouveau mot de passe a été définit. <br />Vous pouvez maintenant vous connecter.
              <div>
                <button @click="currentPart = 'part1'" class="btn btn-primary">Se connecter maintenant</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "login",
  components: {},
  data() {
    return {
      currentPart: "part1",
      societyName: "",
      logoUrl: "",
      us_login: "",
      us_password: "",
      us_password1: "",
      us_password2: "",
      alert_txt: "",
      alert_type: "warning",
      alert_txt_part2: "",
      alert_type_part2: "warning",
      alert_txt_part4: "",
      alert_type_part4: "warning",
      challenge_newpassword: "",
      datetime_newpassord: "",
      challengeError: false,
      us_id: ""
    };
  },
  mounted() {
    //  this.$root.$children[0].hideMenus();
    //  this.$root.$children[0].resizeMainContainer();
    // this.loadAppData();

    if (this.$route.name == "resetpass") {
      this.currentPart = "part4";
      this.checkchallenge(this.$route.params.us_id, this.$route.params.challenge, this.$route.query.datetime);
    }
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Enter") {
        this.login();
      }
    },
    async newpassword() {
      if (!this.challenge_newpassword) return;
      let response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/signin/forgetpassword/newpassword", {
        us_password: this.us_password1,
        us_id: this.us_id,
        challenge: this.challenge_newpassword,
        datetime: this.datetime_newpassord
      });
      if (response.data.err) {
        this.alert_txt_part4 =
          response.data.err.key === "challenge_expired"
            ? "Désolé, vous n'êtes plus autorisé à modifier le mot de passe, veuillez recommencer la procédure de réinitialisation de mot de passe."
            : "Désolé, vous n'êtes pas autorisé à modifier le mot de passe";
      } else {
        this.currentPart = "part5";
      }
    },
    async checkchallenge(us_id, challenge, datetime) {
      this.us_id = us_id;
      let response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/signin/forgetpassword/checkchallenge", {
        us_id,
        challenge,
        datetime
      });
      if (response.data.err) {
        this.challengeError = true;
        this.alert_txt_part4 =
          response.data.err.key === "challenge_expired"
            ? "Désolé, cette page n'est plus accessible, veuillez recommencer la procédure de réinitialisation de mot de passe."
            : "Désolé, vous n'êtes pas autorisé à accéder à cette page";
      } else {
        // this.currentPart = "part3";
        this.challenge_newpassword = response.data.data.challenge;
        this.datetime_newpassord = response.data.data.datetime;
      }
    },
    async sendPassword() {
      let response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/signin/forgetpassword", { us_login: this.us_login });
      if (response.data.err) {
        this.alert_txt_part2 = "Nous n'avons pas pu retrouver votre identifiant";
      } else {
        this.currentPart = "part3";
      }
    },
    // async loadAppData() {
    //   let response = await this.$axios.get(
    //     this.$config.server_url + "/api/1.0/app-datas",
    //     {}
    //   );
    //   this.societyName = response.data.data.societyName;
    //   this.logoUrl = response.data.data.logoUrl;
    // },
    async login() {
      // console.log("this.$config", this.$config);
      try {
        let response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/signin", {
          us_login: this.us_login,
          us_password: this.us_password
        });
        if (response.data.err) {
          // console.log("response.data.err", response.data.err);
          this.alert_type = "warning";
          if (response.data.err.key == "not_found") this.alert_txt = "Utilisateur non trouvé";
          else if (response.data.err.key == "user_wrong_password") this.alert_txt = "Mot de passe erroné";
          else this.alert_txt = "Erreur de connexion";
          return;
        }
        this.alert_txt = "Vous êtes connecté";
        this.alert_type = "success";
        // this.$store.commit("set_token", response.data.accesstoken);
        // this.$store.commit("set_refreshtoken", response.data.refreshtoken);
        // this.$store.commit("set_user", response.data.data);
        // console.log("response.data", response.data);
        this.$store.commit("set_connexion", {
          accesstoken: response.data.data.accesstoken,
          refreshtoken: response.data.data.refreshtoken,
          user: response.data.data.row,
          preferences: response.data.data.preferences
        });
        //   this.$root.$children[0].showMenus();
        this.$router.push("/");
      } catch (error) {
        console.error("error", error);
        this.alert_txt = "Erreur!!!";
        this.alert_type = "warning";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";
.container-login {
  padding: 30px;
  margin: auto;
}
.btn-connexion {
  width: 100%;
  margin-top: 50px;
}
// .container-login {
//   // width: 100%;
// }
.img-fluid {
  width: 100%;
  height: auto;
}
.container-img-login {
  display: none;
  background-color: #daece7;
  border-radius: 10px 0 0 10px;
}

.container-form-login {
  padding: 50px 0;
  background-color: #fff;
  border-radius: 0 10px 10px 0;
}
.container2-form-login {
  width: 80%;
}
@media (min-width: 768px) {
  // .container-login {
  //   //width: 60%;
  // }
  .container-img-login {
    padding: 82px;
    display: block;
  }
  .container-form-login {
    padding: 10px;
  }
}
</style>
