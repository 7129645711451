<template>
  <div id="scheduleMessage" ref="schedulemessage">
    <div class="mt-4 col-12">
      <h6>Edition du planning</h6>
      <div class="planning-editor mb-5">
        <div class="row">
         <div class="col-md-3">
           <m-form-select
             label="Heures"
             v-model="planningObject.options.timeslot"
             :name="$Utils.randomstring('timetable')"
             :items="planningTimetables"
             :clearable="false"
           ></m-form-select>
         </div>
         <div class="col-md-3">
           <m-form-date
             label="Date du planning"
             v-model="planningObject.date"
             :name="$Utils.randomstring('date')"
             :formatValue="'DD/MM/YYYY'"
           ></m-form-date>
         </div>
        </div>
        <div v-show="planningObject.rows.length > 0" class="row planning-editor-header mt-3">
          <div class="col-md-3">
             HORAIRES
          </div>
          <div class="col-md-3">
             TITRE
          </div>
          <div class="col">
             INFORMATIONS COMPLEMENTAIRES
          </div>
        </div>
        <div v-for="(row, index) in planningObject.rows" :key="index" class="row">
          <div class="col-md-3">
            <div class="row">
               <div class="col pr-0">
                  <m-form-hour
                     :name="$Utils.randomstring('planningHour')"
                     formatInput="HH:mm"
                     formatValue="HH:mm"
                     v-model="planningObject.rows[index].startTime"
                  ></m-form-hour>
               </div>
               <div v-show="planningObject.options.timeslot" class="col-2 mt-3 text-center">
                  <span>à</span>
               </div>
               <div v-show="planningObject.options.timeslot" class="col pl-0">
                  <m-form-hour
                     :name="$Utils.randomstring('planningHour')"
                     formatInput="HH:mm"
                     formatValue="HH:mm"
                     v-model="planningObject.rows[index].endTime"
                  ></m-form-hour>
               </div>
            </div>
          </div>
          <div class="col-md-3">
            <m-form-text name="title" class="" v-model="planningObject.rows[index].title"></m-form-text>
          </div>
          <div class="col">
            <m-form-text name="description" class="" v-model="planningObject.rows[index].description"></m-form-text>
          </div>
          <button type="button" class="btn btn-danger mt-3 p-1 mr-3" style="height: 30px; width:30px" @click="removeColumn(index)">X</button>
        </div>
        <p v-show="planningObject.rows.length == 0" class="alert alert-warning mt-3">Ajoutez une première rangée ou importez un planning.</p>
        <div class="row justify-content-end mt-3">
          <div class="col">
            <button type="button" class="btn btn-primary mr-3" @click="addColumn">Ajouter une rangée</button>
          </div>
          <div class="col d-flex justify-content-end">
            <input type="file" id="planningFile" style="visibility:hidden;" accept="application/json"/>
            <label for="planningFile" class="btn btn-primary" style="width: 250px">Importer un planning</label>
          </div>
        </div>
      </div>
      <h6>Prévisualisation du planning</h6>
      <div v-if="planningObject.rows.length > 0" class="planning-preview mt-4 mb-5">
        <h4 class="text-center mb-1"> Planning du {{planningObject.date}} </h4>
        <div v-for="(row, index) in planningObject.rows" :key="index" class="row">
          <hr class="hr-mini w-100">
          <div v-if="planningObject.options.timeslot" class="col-md-3">
            <span v-if="planningObject.rows[index].startTime != ''"> De {{planningObject.rows[index].startTime}} </span> 
            <span v-if="planningObject.rows[index].endTime != ''"> à {{planningObject.rows[index].endTime}} </span>
          </div>
          <div v-else class="col-md-3">
            <span v-if="planningObject.rows[index].startTime != ''"> A partir de {{planningObject.rows[index].startTime}} </span> 
          </div>
          <div class="col-md-3">
            {{planningObject.rows[index].title}}
          </div>
          <div class="col-md">
            <span>{{planningObject.rows[index].description}}</span>
          </div>
        </div>
      </div>
      <div v-else class="mt-4">
         <p style="color: grey; font-style: italic; text-align: center;" > Aucun planning à afficher.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
   name: "SchedulePlanning",
   mounted(){
     this.setInputFileEvent();
   },
   data() {
      return {
         planningObject: {
            options: {
               timeslot: 0
            },
            rows: [],
            date: this.getDate()
         },
         emptyPlanningObject: {
            startTime: "",
            endTime: "",
            title: "",
            description: ""
         },
         planningTimetables: [
            {
               value: 0,
               text: "Heure fixe"
            },
            {
               value: 1,
               text: "Créneaux"
            }
         ]
      };
   },
   watch: {

   },
   methods: {
      addColumn(){
         this.planningObject.rows.push(JSON.parse(JSON.stringify(this.emptyPlanningObject)));
      },
      removeColumn(index){
         this.planningObject.rows.splice(index, 1)
      },
      getDate(){
         let date = new Date();
         let options = { weekday: 'long', month: 'long', day: 'numeric' };
         return date.toLocaleDateString("fr-FR", options);
      },
      setInputFileEvent(){
        const self = this;  
        document.getElementById('planningFile').onchange = function(evt) {
          try {
            let files = evt.target.files;
            if (!files.length) {
               alert('No file selected!');
               return;
            }
            let file = files[0];
            let reader = new FileReader();
            reader.onload = function(event){
              let rawPlanning = JSON.parse(event.target.result);
              //Check JSON planning validity
              if(!rawPlanning.rows || rawPlanning.rows.length == 0) return console.log("rows");;
              if(!rawPlanning.date) return console.log("date");
              if(!rawPlanning.options) return console.log("options");

              let newPlanning = {
                rows: rawPlanning.rows,
                date: rawPlanning.date,
                options: rawPlanning.options
              }
              
              self.planningObject = newPlanning;
              console.log(self.planningObject);
            };
            reader.readAsText(file);
          } catch (err) {
            console.error(err);
          }
        }
      },
   }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.planning-editor-header{
   font-weight:500;
}
.planning-preview {
   border: 2px solid grey;
   border-radius: 10px;
   padding: 15px;
}
</style>
