var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "d-flex w-100" }, [
    _c(
      "div",
      { staticClass: "d-flex flex-column", attrs: { id: "invoices" } },
      [
        _c(
          "div",
          {
            staticClass:
              "row header-emotivi mb-3 justify-content-around align-items-end"
          },
          [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("m-form-select", {
                  attrs: {
                    label: "Documents",
                    name: _vm.$Utils.randomstring("documents"),
                    items: _vm.documentTypesList
                  },
                  model: {
                    value: _vm.documentType,
                    callback: function($$v) {
                      _vm.documentType = $$v
                    },
                    expression: "documentType"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c("div", { staticClass: "flex-grow-1 overflow-hidden d-flex" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.documentType == 1,
                  expression: "documentType == 1"
                }
              ],
              staticClass: "card card-no-margin"
            },
            [
              _c("h2", [_vm._v(_vm._s(_vm.invoices_total) + " factures")]),
              _c(
                "div",
                {
                  staticClass:
                    "flex-grow-1 d-flex justify-content-end align-items-start mb-3",
                  staticStyle: { "margin-top": "-60px" }
                },
                [
                  _c("m-form-combobox", {
                    attrs: {
                      width: 250,
                      label: "Rechercher par contact",
                      "label-position": "top",
                      name: _vm.$Utils.randomstring("filterContact"),
                      "store-url":
                        _vm.$config.server_url + "/extranet/2.0/allcontacts",
                      "store-params": {},
                      "item-value": "co_id",
                      "item-text": _vm.showContactName
                    },
                    on: { input: _vm.loadInvoices },
                    model: {
                      value: _vm.filterContact,
                      callback: function($$v) {
                        _vm.filterContact = $$v
                      },
                      expression: "filterContact"
                    }
                  }),
                  _c("m-form-text", {
                    staticClass: "ml-3",
                    attrs: {
                      label: "Rechercher",
                      "label-position": "top",
                      name: "search",
                      autocomplete: ""
                    },
                    on: { input: _vm.loadInvoices },
                    model: {
                      value: _vm.filterText,
                      callback: function($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText"
                    }
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "ml-3 btn btn-sm btn-primary color-white",
                      staticStyle: { "margin-top": "39px" },
                      attrs: { type: "button" },
                      on: { click: _vm.addInvoices }
                    },
                    [
                      _c("icon", {
                        staticClass: "mr-2",
                        attrs: { name: "plus", scale: "1" }
                      }),
                      _vm._v(" Nouveau ")
                    ],
                    1
                  )
                ],
                1
              ),
              _c("m-list", {
                ref: "invoiceslist",
                attrs: {
                  dynamic: true,
                  items: _vm.invoices,
                  "item-value": "or_id",
                  "item-height": 30,
                  limit: _vm.invoices_limit,
                  skip: _vm.invoices_skip,
                  total: _vm.invoices_total,
                  bgstrip: true
                },
                on: {
                  changerange: _vm.invoicesChangerange,
                  itemclick: _vm.editInvoices
                },
                scopedSlots: _vm._u([
                  {
                    key: "ths",
                    fn: function(ref) {
                      return [
                        _c(
                          "th",
                          {
                            staticStyle: { width: "150px" },
                            on: {
                              click: function($event) {
                                return _vm.sortOrderItems(["createdAt"])
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "pointer" }, [
                              _vm._v("Date de création")
                            ])
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticStyle: { width: "120px" },
                            on: {
                              click: function($event) {
                                return _vm.sortOrderItems(["or_num"])
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "pointer" }, [
                              _vm._v("Num")
                            ])
                          ]
                        ),
                        _c(
                          "th",
                          {
                            on: {
                              click: function($event) {
                                return _vm.sortOrderItems(["or_name"])
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "pointer" }, [
                              _vm._v("Nom")
                            ])
                          ]
                        ),
                        _c("th", [
                          _c("span", { staticClass: "pointer" }, [
                            _vm._v("Contact")
                          ])
                        ]),
                        _c(
                          "th",
                          {
                            staticStyle: { width: "150px" },
                            on: {
                              click: function($event) {
                                return _vm.sortOrderItems(["or_status"])
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "pointer" }, [
                              _vm._v("Statut")
                            ])
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticStyle: {
                              width: "150px",
                              "text-align": "right"
                            },
                            on: {
                              click: function($event) {
                                return _vm.sortOrderItems(["or_status"])
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "pointer" }, [
                              _vm._v("Somme TTC")
                            ])
                          ]
                        ),
                        _c("th", { staticStyle: { width: "120px" } }, [
                          _c("span", { staticClass: "pointer" }, [
                            _vm._v("Imprimer")
                          ])
                        ])
                      ]
                    }
                  },
                  {
                    key: "tds",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("td", { staticStyle: { width: "150px" } }, [
                          _vm._v(
                            _vm._s(
                              _vm
                                .$moment(item.createdAt)
                                .format("DD/MM/YYYY HH[H]mm")
                            )
                          )
                        ]),
                        _c("td", { staticStyle: { width: "120px" } }, [
                          _vm._v(_vm._s(item.or_num))
                        ]),
                        _c("td", [_vm._v(_vm._s(item.or_name))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatContactNameSimple")(
                                item.co_id,
                                "co_invoice_"
                              )
                            )
                          )
                        ]),
                        _c("td", { staticStyle: { width: "150px" } }, [
                          _vm._v(_vm._s(item.or_status))
                        ]),
                        _c(
                          "td",
                          {
                            staticStyle: {
                              width: "150px",
                              "text-align": "right"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatPrice")(item.or_pricettc / 100)
                              )
                            )
                          ]
                        ),
                        _c(
                          "td",
                          {
                            staticStyle: {
                              "text-align": "center",
                              width: "120px"
                            },
                            on: {
                              click: function($event) {
                                return _vm.print(item, $event)
                              }
                            }
                          },
                          [_c("icon", { attrs: { name: "print" } })],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("invoice-win-edit", {
                attrs: { or_id: _vm.itemSelected.or_id },
                on: { reload: _vm.loadInvoices },
                model: {
                  value: _vm.dialog,
                  callback: function($$v) {
                    _vm.dialog = $$v
                  },
                  expression: "dialog"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.documentType == 2,
                  expression: "documentType == 2"
                }
              ],
              staticClass: "card card-no-margin"
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("h2", { staticClass: "mb-0" }, [
                    _vm._v(
                      _vm._s(_vm.attestations_total) + " attestations fiscales"
                    )
                  ]),
                  _c(
                    "p",
                    {
                      staticClass: "ml-2",
                      staticStyle: {
                        color: "red",
                        "font-weight": "bold",
                        "font-size": "20px"
                      }
                    },
                    [_vm._v(_vm._s(_vm.attestationsPending) + " en attente")]
                  )
                ]),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-end align-items-start mb-3"
                    },
                    [
                      _c("m-form-select", {
                        attrs: {
                          width: 150,
                          label: "Année",
                          "label-position": "top",
                          name: _vm.$Utils.randomstring("filterYear"),
                          items: _vm.attestationYears
                        },
                        on: { input: _vm.loadAttestations },
                        model: {
                          value: _vm.filterAttestationYear,
                          callback: function($$v) {
                            _vm.filterAttestationYear = $$v
                          },
                          expression: "filterAttestationYear"
                        }
                      }),
                      _c("m-form-select", {
                        staticClass: "ml-3",
                        attrs: {
                          width: 150,
                          label: "Status",
                          "label-position": "top",
                          name: _vm.$Utils.randomstring("filterStatus"),
                          items: _vm.attestationStatus
                        },
                        on: { input: _vm.loadAttestations },
                        model: {
                          value: _vm.filterAttestationStatus,
                          callback: function($$v) {
                            _vm.filterAttestationStatus = $$v
                          },
                          expression: "filterAttestationStatus"
                        }
                      }),
                      _c("m-form-combobox", {
                        ref: "search-contacts",
                        staticClass: "ml-3",
                        attrs: {
                          width: 220,
                          label: "Contact",
                          "label-position": "top",
                          name: _vm.$Utils.randomstring(
                            "filterContactAttestations"
                          ),
                          "store-url":
                            _vm.$config.server_url +
                            "/extranet/2.0/allcontacts",
                          "store-params": {
                            co_invoice_name: _vm.searchContactsValue,

                            limit: _vm.searchContactsValue ? 10000 : 100
                          },
                          "item-value": "co_id",
                          "item-text": _vm.showContactName
                        },
                        on: {
                          input: _vm.loadAttestations,
                          onsearchinput: _vm.searchContacts
                        },
                        model: {
                          value: _vm.filterContactAttestation,
                          callback: function($$v) {
                            _vm.filterContactAttestation = $$v
                          },
                          expression: "filterContactAttestation"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _c("m-list", {
                ref: "attestationslist",
                attrs: {
                  dynamic: true,
                  items: _vm.attestations,
                  "item-value": "fisc_id",
                  "item-height": 30,
                  limit: _vm.attestations_limit,
                  skip: _vm.attestations_skip,
                  total: _vm.attestations_total,
                  bgstrip: true
                },
                on: {
                  changerange: _vm.attestationsChangerange,
                  itemclick: _vm.editAttestations
                },
                scopedSlots: _vm._u([
                  {
                    key: "ths",
                    fn: function(ref) {
                      return [
                        _c(
                          "th",
                          {
                            staticStyle: { width: "150px" },
                            on: {
                              click: function($event) {
                                return _vm.sortAttestationItems(["createdAt"])
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "pointer" }, [
                              _vm._v("Date de la demande")
                            ])
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticStyle: { width: "80px" },
                            on: {
                              click: function($event) {
                                return _vm.sortAttestationItems(["fisc_id"])
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "pointer" }, [
                              _vm._v("Année")
                            ])
                          ]
                        ),
                        _c("th", [
                          _c("span", { staticClass: "pointer" }, [
                            _vm._v("Contact")
                          ])
                        ]),
                        _c(
                          "th",
                          {
                            staticStyle: { width: "150px" },
                            on: {
                              click: function($event) {
                                return _vm.sortAttestationItems(["fisc_status"])
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "pointer" }, [
                              _vm._v("Statut")
                            ])
                          ]
                        )
                      ]
                    }
                  },
                  {
                    key: "tds",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("td", { staticStyle: { width: "150px" } }, [
                          _vm._v(
                            _vm._s(
                              _vm
                                .$moment(item.createdAt)
                                .format("DD/MM/YYYY HH[H]mm")
                            )
                          )
                        ]),
                        _c("td", { staticStyle: { width: "80px" } }, [
                          _vm._v(_vm._s(item.fisc_year))
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(item.fisc_civility) +
                              " " +
                              _vm._s(item.fisc_fullname)
                          )
                        ]),
                        _c(
                          "td",
                          {
                            staticStyle: { width: "150px" },
                            style: {
                              color:
                                item.fisc_status == "2"
                                  ? "red"
                                  : item.fisc_status == "1"
                                  ? "green"
                                  : "orange"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.fisc_status == "0"
                                    ? "En attente"
                                    : item.fisc_status == "1"
                                    ? "Validée"
                                    : item.fisc_status == "2"
                                    ? "Irrecevable"
                                    : "En attente"
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("attestation-fiscale", {
                attrs: { row_en: _vm.row_en },
                on: {
                  winRequestAttestationCanceled: _vm.cancelWinRequestAttestation
                },
                model: {
                  value: _vm.displayFormAttestationFiscale,
                  callback: function($$v) {
                    _vm.displayFormAttestationFiscale = $$v
                  },
                  expression: "displayFormAttestationFiscale"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.documentType == 0,
                  expression: "documentType == 0"
                }
              ],
              staticClass: "row"
            },
            [_vm._m(1)]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("h1", [_vm._v("Comptabilité")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("p", [_vm._v("Veuillez sélectionner un type de document")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }