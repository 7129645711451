var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "d-flex w-100" }, [
    _c("div", { staticClass: "w-100", attrs: { id: "boxes" } }, [
      _c("div", { staticClass: "d-flex header-emotivi" }, [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c("h1", [
            _vm._v(
              "Statistiques " +
                _vm._s(
                  _vm.$store.state.user.us_type === "LATEAM"
                    ? "V2 uniquement (la V1 n'est pas prise en compte)"
                    : ""
                )
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "w-100" }, [
        _vm.loading
          ? _c("div", [
              _c(
                "div",
                {
                  staticClass:
                    "spinner row flex-column justify-content-center alignt-items-center m-5 pt-5"
                },
                [
                  _c("h6", { staticClass: "col text-center" }, [
                    _vm._v(
                      "Chargement des statistiques en cours (environ 30 secondes)..."
                    )
                  ]),
                  _c("icon", {
                    staticClass: "col",
                    attrs: { name: "spinner", scale: "5", spin: "" }
                  })
                ],
                1
              )
            ])
          : _c("div", [
              _c(
                "div",
                { staticClass: "card card-no-margin w-100" },
                [
                  _c("h6", [_vm._v("Clients actifs")]),
                  _c("p", [
                    _vm._v(
                      " Recherche si un client a fait au moins un appel (audio, vidéo, message). " +
                        _vm._s(
                          _vm.$store.state.user.us_type === "LATEAM"
                            ? "Recherche les appels vidéos, audio, messages envoyés dans la table action et groupe par boxes pour le mois."
                            : ""
                        ) +
                        " "
                    )
                  ]),
                  _vm.chartBarLoaded
                    ? _c("m-chart-bar", {
                        attrs: {
                          chartdata: _vm.alldatas.statCustomersActif,
                          options: _vm.chartOptions
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card card-no-margin w-100" },
                [
                  _c("h6", [_vm._v("Boxes actives")]),
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$store.state.user.us_type === "LATEAM"
                            ? "Compte les connexions des websocket sur le serveur (dans la table action le type 'BOX_CONNECTION', où une action est rajouté quand une box se (re)connect au serveur) et groupe par boxes. Normalement on redémarre le serveur au moins une fois par mois, donc ce chiffre devrait être juste..."
                            : "Boxes connectées au serveur Emotivi."
                        ) +
                        " "
                    )
                  ]),
                  _vm.chartBarLoaded
                    ? _c("m-chart-bar", {
                        attrs: {
                          chartdata: _vm.alldatas.statBoxesActif,
                          options: _vm.chartOptions
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.$store.state.user.us_type === "LATEAM"
                ? _c("div", { staticClass: "card card-no-margin w-100" }, [
                    _c("h6", [_vm._v("CLIENTS ABONNÉS")]),
                    _c("p", [
                      _vm._v(
                        " Recherche les boxes actives sur les 90 derniers jours (pourquoi pas ?), joint la table des boxes, regarde la donnée 'source_emotivi_usecec' et 'source_emotivi_useir' "
                      )
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _vm.chartBarLoaded
                        ? _c("div", { staticClass: "mr-2 frame-stat" }, [
                            _c("div", [_vm._v("RIB")]),
                            _c("div", { staticClass: "title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.alldatas.statCustomersSubscribe.ribp
                                ) + "%"
                              )
                            ]),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.alldatas.statCustomersSubscribe.rib)
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.chartBarLoaded
                        ? _c("div", { staticClass: "mr-2 frame-stat" }, [
                            _c("div", [_vm._v("CB")]),
                            _c("div", { staticClass: "title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.alldatas.statCustomersSubscribe.cbp
                                ) + "%"
                              )
                            ]),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm.alldatas.statCustomersSubscribe.cb)
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.chartBarLoaded
                        ? _c("div", { staticClass: "mr-2 frame-stat" }, [
                            _c("div", [_vm._v("Total")]),
                            _c("div", { staticClass: "title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.alldatas.statCustomersSubscribe.total
                                )
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "card card-no-margin w-100" },
                [
                  _c("h6", [
                    _vm._v("Nombres d'appels vidéos, audios, messages")
                  ]),
                  _c("p", [
                    _vm._v("Recherche le nombre d'appels envoyés pour le mois")
                  ]),
                  _vm.chartBarLoaded
                    ? _c("m-chart-bar", {
                        attrs: {
                          chartdata: _vm.alldatas.statNbMessagesVisiosAudios,
                          options: _vm.chartOptions
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card card-no-margin w-100" },
                [
                  _c("h6", [_vm._v("TEMPS MOYEN D'UN APPEL")]),
                  _c("p", [
                    _vm._v(
                      " Recherche les appels vidéos ou audio supérieur à 60s. " +
                        _vm._s(
                          _vm.$store.state.user.us_type === "LATEAM"
                            ? "(pour éliminer les appels sans décroché), additionne le temps de chaque appel et divise par le nombre d'appels sur le mois"
                            : ""
                        ) +
                        " "
                    )
                  ]),
                  _vm.chartBarLoaded
                    ? _c("m-chart-bar", {
                        attrs: {
                          chartdata: _vm.alldatas.statTempsMoyenAppels,
                          options: _vm.chartOptions
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card card-no-margin w-100" },
                [
                  _c("h6", [_vm._v("RÉPARTITION DES APPELS PAR HEURE")]),
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$store.state.user.us_type === "LATEAM"
                            ? "Recherche les appels audio ou vidéo depuis la création de la V2 et affiche combien il y en a eu par 1/4 d'heure."
                            : "Recherche les appels audio ou vidéo et affiche combien il y en a eu par 1/4 d'heure."
                        ) +
                        " "
                    )
                  ]),
                  _vm.chartBarLoaded
                    ? _c("m-chart-bar", {
                        attrs: {
                          chartdata: _vm.alldatas.statHours,
                          options: _vm.chartOptions
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card card-no-margin w-100" },
                [
                  _c("h6", [
                    _vm._v("RÉPARTITION DES APPELS PAR JOURS DE LA SEMAINE")
                  ]),
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$store.state.user.us_type === "LATEAM"
                            ? "Recherche les appels audio ou vidéo depuis la création de la V2 et affiche combien il y en a eu par jour de la semaine."
                            : "Recherche les appels audio ou vidéo et affiche combien il y en a eu par jour de la semaine."
                        ) +
                        " "
                    )
                  ]),
                  _vm.chartBarLoaded
                    ? _c("m-chart-bar", {
                        attrs: {
                          chartdata: _vm.alldatas.statDaysWeek,
                          options: _vm.chartOptions
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card card-no-margin w-100" },
                [
                  _c("h6", [_vm._v("RÉPARTITION DES APPELS PAR SEMAINE")]),
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$store.state.user.us_type === "LATEAM"
                            ? "Recherche les appels audio ou vidéo depuis la création de la V2 et affiche combien il y en a eu par semaine."
                            : "Recherche les appels audio ou vidéo et affiche combien il y en a eu par semaine."
                        ) +
                        " "
                    )
                  ]),
                  _vm.chartBarLoaded
                    ? _c("m-chart-bar", {
                        attrs: {
                          chartdata: _vm.alldatas.statWeeks,
                          options: _vm.chartOptions
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card card-no-margin w-100" },
                [
                  _c("h6", [
                    _vm._v(
                      "Nombre d'appels en moyenne par mois par utilisateur"
                    )
                  ]),
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$store.state.user.us_type === "LATEAM"
                            ? "Recherche les appels audio ou vidéo depuis la création de la V2 et affiche combien il y en a eu en moyenne par mois par utilisateur."
                            : "Recherche les appels audio ou vidéo et affiche combien il y en a eu en moyenne par mois par utilisateur."
                        ) +
                        " "
                    )
                  ]),
                  _vm.chartBarLoaded
                    ? _c("m-chart-bar", {
                        attrs: {
                          chartdata:
                            _vm.alldatas.statNbreMoyenAppelsParMoisParUser,
                          options: _vm.chartOptions
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card card-no-margin w-100" },
                [
                  _c("h6", [_vm._v("UTILISATION DU BOUTON PAR APPELS")]),
                  _c("p", [
                    _vm._v(
                      " Recherche les appels visio ou audio puis additionne ceux avec ou sans bouton et fait l'addition des deux nombres pour trouver le nombre d'appels, par mois. "
                    )
                  ]),
                  _vm.chartBarLoaded
                    ? _c("m-chart-bar", {
                        attrs: {
                          chartdata: _vm.alldatas.statButton,
                          options: _vm.chartOptions
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("div", { staticClass: "card card-no-margin w-100" }, [
                _c("h6", [_vm._v("SOURCES UTILISÉS")]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$store.state.user.us_type === "LATEAM"
                          ? "Recherche les boxes actives sur les 90 derniers jours (pourquoi pas ?), joint la table des boxes, regarde la donnée 'source_emotivi_usecec' et 'source_emotivi_useir'"
                          : "Recherche les boxes actives sur les 90 derniers jours."
                      ) +
                      " "
                  )
                ]),
                _c("div", { staticClass: "d-flex" }, [
                  _vm.chartBarLoaded
                    ? _c("div", { staticClass: "mr-2 frame-stat" }, [
                        _c("div", [_vm._v("CEC uniquement")]),
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.alldatas.statCecIr.cecp) + "%")
                        ]),
                        _c("div", [_vm._v(_vm._s(_vm.alldatas.statCecIr.cec))])
                      ])
                    : _vm._e(),
                  _vm.chartBarLoaded
                    ? _c("div", { staticClass: "mr-2 frame-stat" }, [
                        _c("div", [_vm._v("IR uniquement")]),
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.alldatas.statCecIr.irp) + "%")
                        ]),
                        _c("div", [_vm._v(_vm._s(_vm.alldatas.statCecIr.ir))])
                      ])
                    : _vm._e(),
                  _vm.chartBarLoaded
                    ? _c("div", { staticClass: "mr-2 frame-stat" }, [
                        _c("div", [_vm._v("CEC et IR")]),
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.alldatas.statCecIr.cecandirp) + "%")
                        ]),
                        _c("div", [
                          _vm._v(_vm._s(_vm.alldatas.statCecIr.cecandir))
                        ])
                      ])
                    : _vm._e()
                ])
              ]),
              _c("div", { staticClass: "card card-no-margin w-100" }, [
                _c("h6", [_vm._v("RÉSEAU UTILISÉ")]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$store.state.user.us_type === "LATEAM"
                          ? "Recherche les boxes actives sur les 90 derniers jours (pourquoi pas), joint la table des boxes, regarde la donnée 'bo_config_network.configuration.type'"
                          : "Recherche les boxes actives sur les 90 derniers jours."
                      ) +
                      " "
                  )
                ]),
                _c("div", { staticClass: "d-flex" }, [
                  _vm.chartBarLoaded
                    ? _c("div", { staticClass: "mr-2 frame-stat" }, [
                        _c("div", [_vm._v("Wifi")]),
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.alldatas.statNetwork.wifip) + "%")
                        ]),
                        _c("div", [
                          _vm._v(_vm._s(_vm.alldatas.statNetwork.wifi))
                        ])
                      ])
                    : _vm._e(),
                  _vm.chartBarLoaded
                    ? _c("div", { staticClass: "mr-2 frame-stat" }, [
                        _c("div", [_vm._v("Ethernet")]),
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            _vm._s(_vm.alldatas.statNetwork.ethernetp) + "%"
                          )
                        ]),
                        _c("div", [
                          _vm._v(_vm._s(_vm.alldatas.statNetwork.ethernet))
                        ])
                      ])
                    : _vm._e(),
                  _vm.chartBarLoaded
                    ? _c("div", { staticClass: "mr-2 frame-stat" }, [
                        _c("div", [_vm._v("Clé 4g")]),
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.alldatas.statNetwork.key4gp) + "%")
                        ]),
                        _c("div", [
                          _vm._v(_vm._s(_vm.alldatas.statNetwork.key4g))
                        ])
                      ])
                    : _vm._e()
                ])
              ]),
              _vm.$store.state.user.us_type === "LATEAM"
                ? _c(
                    "div",
                    { staticClass: "card card-no-margin w-100" },
                    [
                      _c("h6", [_vm._v("Les clients")]),
                      _vm.chartBarLoaded
                        ? _c("m-chart-bar", {
                            attrs: {
                              chartdata: _vm.chartData,
                              options: _vm.chartOptions
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }