<template>
  <section class="d-flex tvs">
    <div class="d-flex flex-column" id="tvs">
      <div class="d-flex header-emotivi">
        <div class="d-flex align-items-center">
          <h1>Liste des téléviseurs ({{ tvs_total }})</h1>
        </div>
        <div class="flex-grow-1 d-flex flex-column justify-content-end align-items-end">
          <div class="d-flex flex-wrap" style="margin-top:-45px;">
            <!-- <m-form-checkbox
              class="ml-2 col-form-label-sm"
              label="A faire"
              name="aFaire"
              v-model="aFaire"
              @input="loadTvs(true)"
              style="margin-bottom: 0px;padding-top: 45px;"
            ></m-form-checkbox> -->
            <m-form-text
              class="ml-2 col-form-label-sm"
              width="200px"
              label="Rechercher"
              label-position="top"
              v-model="filtersTv.filterText"
              name="search"
              autocomplete
              @input="loadTvs(true)"
            ></m-form-text>
            <m-form-text
              class="ml-2 col-form-label-sm"
              v-if="$store.state.user.us_type === 'LATEAM'"
              label="Filtrer par numéro de version"
              label-position="top"
              :name="$Utils.randomstring('filterVersionTv')"
              v-model="filtersTv.filterVersionTv"
              @input="loadTvs('deviceOnly', 1000)"
            ></m-form-text>
            <!-- <m-form-select
              class="ml-2 col-form-label-sm"
              v-if="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
              label="Etat de la commande"
              label-position="top"
              :name="$Utils.randomstring('filterStatusTv')"
              v-model="filtersTv.filterStatusTv"
              :items="items_box_connectee"
              @input="loadTvs(false)"
            ></m-form-select> -->
            <m-form-select
              class="ml-2 col-form-label-sm"
              v-if="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
              label="Groupes"
              label-position="top"
              item-value="gr_id"
              :name="$Utils.randomstring('filterStatus')"
              v-model="filterStatus"
              :items="$store.state.mookGroups"
              @input="loadTvs(true)"
            ></m-form-select>
            <m-form-combobox
              class="ml-2 col-form-label-sm"
              v-if="$store.getters.checkUserAccess($router, '/establishments')"
              label="Etablissement"
              label-position="top"
              :name="$Utils.randomstring('filterEstablishment')"
              v-model="filtersTv.filterEstablishment"
              :store-url="$config.server_url + '/extranet/2.0/establishments'"
              :store-params="{ ignorePopulate: true }"
              item-value="es_id"
              item-text="es_name"
              @input="loadTvs(true)"
            ></m-form-combobox>
          </div>
          <div class="d-flex flex-wrap">
            <button
              v-if="$store.getters.checkUserAccess($router, '/tvs/new')"
              class="btn btn-primary color-white input-filter btn-sm"
              type="button"
              @click="newEnduser"
            >
              <icon name="plus" scale="1" class="mr-2"></icon> Nouveau
            </button>
            <button
              v-if="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'DIRECTOR' || $store.state.user.us_type === 'STAFF'"
              class="btn btn-primary color-white input-filter btn-sm"
              type="button"
              @click="showModalToSendMessage"
            >
              Envoyer un message à tous
            </button>
            <button
              v-if="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'DIRECTOR' || $store.state.user.us_type === 'STAFF'"
              class="btn btn-primary color-white input-filter btn-sm"
              type="button"
              @click="showModalToScheduleMessage"
            >
              Planifier un message pour tous
            </button>
          </div>
        </div>
      </div>

      <div class="flex-grow-1 overflow-hidden d-flex">
        <div class="card card-no-margin">
          <m-list
            ref="tvslist"
            :dynamic="true"
            :items="tvs"
            item-value="en_id"
            :item-height="30"
            :limit="tvs_limit"
            :skip="tvs_skip"
            :total="tvs_total"
            :current="tvs_current"
            :bgstrip="true"
            @changerange="tvsChangerange"
            @itemclick="goTv"
          >
            <template v-slot:ths="{}">
              <th class="" @click="sortItems(['en_id'])">
                <span class="pointer">Numéro (en_id)</span>
              </th>

              <th @click="sortItems(['en_fullname'])">
                <span class="pointer">Utilisateur final</span>
              </th>
              <th>
                <span class="pointer">Établissement</span>
              </th>
              <th v-if="$store.state.user.us_type !== 'PARTNER'" style="min-width: 140px">
                <span class="pointer">Vidéo/Ok/Voix/Ok/Message</span>
              </th>
              <th style="width: 120px">
                <span class="pointer">A faire</span>
              </th>

              <th v-if="$store.state.user.us_type !== 'PARTNER'" style="width: 120px">
                <span class="pointer">Actions</span>
              </th>
            </template>
            <template v-slot:tds="{ item }">
              <td>
                {{ item.en_id }} <span v-if="item.de_id">{{ item.de_id.de_mac_address }}</span>
              </td>

              <td>
                {{ item.en_fullname }}
                <span class="color-emotivi-blue">
                  {{
                    item.co_id.afco_id && item.co_id.afco_id.af_id && item.co_id.afco_id.af_id.af_code
                      ? "(Affilié à " + item.co_id.afco_id.af_id.af_name + " " + item.co_id.afco_id.af_id.af_code + ")"
                      : ""
                  }}
                  {{ item.en_linkello ? "(Associé à Linkello)" : "" }}
                </span>
              </td>
              <td>
                {{ item.co_id && item.co_id.es_id ? item.co_id.es_id.es_name : "" }}
              </td>
              <td v-if="$store.state.user.us_type !== 'PARTNER'">
                <span v-for="(number, index) in item.infos.nbCalls" :key="index"
                  >{{ number }} <span v-if="index < item.infos.nbCalls.length - 1">/ </span></span
                >

                <span
                  style="margin-left:20px;background-color: green;color: white; font-weight:bold;border-radius: 50%;width:25px;display: inline-block;text-align: center;padding: 5px;margin-top: 5px"
                  v-if="item.newMessage && item.newMessage.length > 0"
                  :data-popover="textMessage(item.newMessage.length)"
                >
                  {{ item.newMessage.length }}
                </span>
              </td>
              <td style="width: 120px" :data-popover="toDo(item).replace(/Box/g, 'TV')">
                <span>{{ toDo(item).replace(/Box/g, "TV") }}</span>
              </td>

              <td v-if="$store.state.user.us_type !== 'PARTNER'" style="width: 120px">
                <div class="d-flex flex-row">
                  <div @click="sendEmail(item, $event)" v-if="item.co_id && $store.state.user.us_type !== 'TECHNICIAN'">
                    <a :href="'mailto:' + item.co_id.co_invoice_email"
                      ><icon width="20" height="20" name="envelope" color="black" class="ml-2"></icon
                    ></a>
                  </div>
                  <!-- <div @click="sendIdentifiant(item, $event)">
                    <icon width="20" height="20" name="key" class="ml-2"></icon>
                  </div> -->
                </div>
              </td>
            </template>
          </m-list>
        </div>
      </div>
    </div>
    <m-message-dialog v-model="dialogDiagnostiqueReseau" title="Diagnostic réseau" :text="dialogDiagnostiqueReseauTxt" width="70%"></m-message-dialog>
    <m-message-dialog v-model="dialogMessage" title="Confirmation" :text="dialogMessageTxt"></m-message-dialog>
    <enduser-win-edit :en_id="-1" v-model="dialogEnduser" @WinEditSaved="loadTvs" @winEditCanceled="cancelWin"></enduser-win-edit>
    <modal-send-message-all v-model="modalSendMessage"></modal-send-message-all>
    <modal-schedule-message-all v-model="modalScheduleMessage"></modal-schedule-message-all>
    <modal-send-ir-macro v-model="modalSendIrMacro"></modal-send-ir-macro>
    <popover ref="popover" />
  </section>
</template>

<script>
import Popover from "../components/Popover.vue";
export default {
  name: "tvs",
  components: { Popover },
  data() {
    return {
      filtersTv: { ...this.$store.state.filtersTv },
      tvs_current: "",
      tvs: [],
      en_ids: [],
      tvs_total: 0,
      tvs_limit: 50,
      tvs_skip: 0,
      aFaire: false,
      showNoActive: false,
      showOccupedRoom: false,
      filterText: "",
      filterEstablishment: "",
      filterStatus: "",
      filterStatusTv: "",
      filterVersionTv: "",
      sortOrder: "desc",
      sortFields: ["en_id"],

      dialog: false,
      itemSelected: {},
      dialogDiagnostiqueReseau: false,
      dialogDiagnostiqueReseauTxt: "",
      dialogMessage: false,
      dialogMessageTxt: "",
      dialogEnduser: false,
      items_device_connectee: [
        { text: "Active", value: "ACTIVE" },
        { text: "Non active", value: "NOTACTIVE" },
        { text: "Non payé", value: "UNPAID" }
      ],
      modalSendMessage: false,
      modalSendIrMacro: false,
      modalScheduleMessage: false
    };
  },
  watch: {},
  async mounted() {
    await this.loadTvs(true);
    this.$nextTick(() => {
      this.$refs.tvslist.update(this.$store.state.scrollPosition);
    });
  },
  computed: {},
  methods: {
    textMessage(nbre) {
      const message = "Vous avez reçu " + nbre + " nouveau message(s)";
      return message;
    },
    newEnduser() {
      this.dialogEnduser = true;
      this.$router.push("/tvs/new").catch(err => {});
    },
    async sendIdentifiant(item, $event) {
      $event.stopPropagation();
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/endusers/" + item.en_id + "/sendlogin");
      if (!response || !response.data) return;
      if (response.data.data.success) {
        this.dialogMessageTxt = "Les identifiants et mot de passe ont bien été envoyé au contact";
        this.dialogMessage = true;
      }
    },
    sendEmail(item, $event) {
      $event.stopPropagation();
    },

    sortItems(fields) {
      // console.log("fields", fields);
      if (fields.length && this.filtersTv.sortFields.length && fields[0] == this.filtersTv.sortFields[0]) {
        this.filtersTv.sortOrder = this.filtersTv.sortOrder == "asc" ? "desc" : "asc";
      }
      this.filtersTv.sortFields = fields;
      if (fields[0] !== "co_connection_date") {
        this.loadTvs(true);
      } else {
        this.patients.sort((a, b) => {
          if (this.filtersTv.sortOrder === "asc") {
            if (this.$moment(a.co_connection_date).isBefore(b.co_connection_date)) return -1;
            if (this.$moment(a.co_connection_date).isAfter(b.co_connection_date)) return 1;
            if (this.$moment(a.co_connection_date).isSame(b.co_connection_date)) return 0;
          } else {
            if (this.$moment(a.co_connection_date).isBefore(b.co_connection_date)) return 1;
            if (this.$moment(a.co_connection_date).isAfter(b.co_connection_date)) return -1;
            if (this.$moment(a.co_connection_date).isSame(b.co_connection_date)) return 0;
          }
        });
      }
    },
    /**
     * loadTvs - triggered on page load or on search input - get end user list by range ( skip && limit )
     * @param deviceOnly
     * @param limit
     */
    async loadTvs(deviceOnly, limit) {
      let types = [];
      let sort = this.filtersTv.sortFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.filtersTv.sortOrder;
        return accumulator;
      }, "");
      let params = {
        types: types,
        skip: this.tvs_skip,
        limit: limit || this.tvs_limit,
        sort: sort
      };
      if (deviceOnly) params.de_id = ">0";
      if (this.filterText) params.text = this.filterText;
      if (this.filterEstablishment) params.establishment = this.filterEstablishment.es_id;
      if (this.filterStatus) params.gr_id = this.filterStatus;
      if (this.aFaire) params.aFaire = this.aFaire;
      if (this.filterStatusTv) params.en_active = this.filterStatusTv;
      if (this.filterVersionTv) params.de_version = this.filterVersionTv;
      if (this.filtersTv.filterText) params.text = this.filtersTv.filterText;
      if (this.filtersTv.filterEstablishment) params.establishment = this.filtersTv.filterEstablishment.es_id;
      if (this.filtersTv.filterStatusTv) params.en_active = this.filtersTv.filterStatusTv;
      if (this.filtersTv.filterVersionTv) params.de_version = this.filtersTv.filterVersionTv;
      if (this.$store.state.user.us_type === "PARTNER") params.en_code_affiliation = this.$store.state.user.us_code_affiliation;
      this.cancelAxiosLoadContacts && this.cancelAxiosLoadContacts();
      this.$store.commit("set_filtersTv", this.filtersTv);
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/endusers", {
        params,
        cancelToken: new this.$axios.CancelToken(c => {
          this.cancelAxiosLoadContacts = c;
        })
      });
      if (!response || !response.data) return;
      this.tvs = response.data.data;
      this.tvs_total = response.data.meta.total;
      // this.$refs.tvslist.update();
      // console.log("this.tvs", this.tvs);
    },
    toDo(device) {
      let problems = [];
      if (device.en_state_parcel == "TO_PREPARE") {
        problems.push(this.$Utils.getTextFromState(this.$store.state.items_en_state_parcel, "TO_PREPARE"));
      }
      if (device.en_state_parcel == "TO_SEND") {
        problems.push(this.$Utils.getTextFromState(this.$store.state.items_en_state_parcel, "TO_SEND"));
      }
      if (device.en_state_parcel == "WAITING") {
        problems.push(this.$Utils.getTextFromState(this.$store.state.items_en_state_parcel, "WAITING"));
      }
      if (device.en_state_box == "NOT_INSTALLED") {
        problems.push(this.$Utils.getTextFromState(this.$store.state.items_en_state_box, "NOT_INSTALLED"));
      }
      if (device.en_state_caution == "REFUND_WAITING") {
        problems.push(this.$Utils.getTextFromState(this.$store.state.items_en_state_caution, "REFUND_WAITING"));
      }
      if (device.en_state_caution == "NOT_PAYED") {
        problems.push(this.$Utils.getTextFromState(this.$store.state.items_en_state_caution, "NOT_PAYED"));
      }
      if (device.en_state_subscription == "WAITING_STOPPED") {
        problems.push(this.$Utils.getTextFromState(this.$store.state.items_en_state_subscription, "WAITING_STOPPED"));
      }
      if (device.en_state_subscription == "RIB_TO_CONFIRM") {
        problems.push(this.$Utils.getTextFromState(this.$store.state.items_en_state_subscription, "RIB_TO_CONFIRM"));
      }
      if (device.en_state_subscription == "RIB_PROBLEM") {
        problems.push(this.$Utils.getTextFromState(this.$store.state.items_en_state_subscription, "RIB_PROBLEM"));
      }
      if (device.en_state_subscription == "CB_PROBLEM") {
        problems.push(this.$Utils.getTextFromState(this.$store.state.items_en_state_subscription, "CB_PROBLEM"));
      }
      if (device.en_state_support != "NONE") {
        problems.push(this.$Utils.getTextFromState(this.$store.state.items_en_state_support, device.en_state_support));
      }
      return problems.join(", ");
    },
    tvsChangerange(skip, scrollTop) {
      // console.log("skip, scrollTop", skip, scrollTop);
      this.tvs_skip = skip;
      this.loadTvs(true);
    },
    goTv(item) {
      if (this.$store.state.user.us_type === "PARTNER") return;
      this.$store.commit("set_scrollPosition", this.$refs.tvslist.scrollTop);
      this.itemSelected = item;
      this.$router.push("/tvs/" + this.itemSelected.en_id);
      this.dialog = true;
    },
    showModalToSendMessage() {
      this.modalSendMessage = true;
    },
    showModalToScheduleMessage() {
      this.modalScheduleMessage = true;
    },
    showModalToSendIrMacro() {
      this.modalSendIrMacro = true;
    },
    cancelWin() {
      this.$router.push("/tvs");
    }
  },
  updated() {
    // console.log("update");
    this.$refs.popover.init();
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";
.mygreen {
  color: green;
  font-weight: bold;
}
.myred {
  color: red;
}
.input-filter {
  width: 100%;
}
@media (min-width: 576px) {
  .input-filter {
    width: auto;
    margin-left: 5px;
  }
}
@media (min-width: 768px) {
  .tvs {
    padding-top: 0px !important;
  }
}
.badge {
  position: relative;
}
.badge[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 0.7em;
  background: green;
  color: white;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  box-shadow: 0 0 1px #333;
}
.badge1 {
  position: relative;
  color: green;
  top: 2px;
  border-radius: 50px !important;
}
.badge1[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  // top: -10px;
  // right: -10px;
  font-size: 0.7em;
  background: green;
  color: white;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  box-shadow: 0 0 1px #333;
}
</style>
