<template>
  <div>
    <label class="m-form-checkbox">
      <input type="checkbox" :disabled="disabled" :checked="value" :name="name2" @input="setValue($event.target.checked)" />
      {{ label }}
    </label>
    <div class="small-text" v-if="subText">{{ subText }}</div>
  </div>
</template>

<script>
export default {
  name: "mformcheckbox",
  props: {
    value: [String, Number, Boolean],
    label: String,
    id: {
      default: "",
      type: String
    },
    name: {
      default: "",
      type: String
    },
    readonly: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    subText: {
      default: "",
      type: String
    }
  },
  mounted() {
    // console.log("this.value", this.value);
    //   if(this.value)
    //  if (this.name) this.name2 = this.name;
    //  else this.name2 = this.$Utils.randomstring();
  },
  data() {
    return {
      id2: "",
      name2: "",
      checked: false,
      checked2: false
    };
  },
  watch: {
    checked2: function(val) {}
  },
  components: {},
  methods: {
    setValue(val) {
      // this.$parent.$parent.setValue(val);
      // console.log("val", val);
      this.$emit("input", val);
    },
    setName(name) {
      this.name2 = name;
    },
    setChecked(checked) {
      this.checked = !!checked;
    },
    onclick(evt) {
      // evt.stopPropagation();
      this.$emit("click", evt);
    },
    onfocus(evt) {
      if (this.autoSelectOnFocus) evt.target.select();
      // evt.stopPropagation();
      // this.$emit("click", evt);
    }
  }
};
</script>

<style lang="scss">
input[type="checkbox"] {
  width: auto;
  margin-right: 8px;
}
.form-control {
  // border-radius: 0;
  // border-radius: 0;
  //border: 0px;
  //border-bottom: 1px solid #999999;
  background-color: #ffffff00;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  margin-bottom: 10px;
}
.input-group-nomargin {
  margin: 0 !important;
}
.textarea {
  background-color: #ffffff;
  border: 1px solid #eaeaea;
}
.form-group {
  position: relative;
  margin: 15px 0 0 0;
}
/* .input-group > .form-control {
  height: 34px;
} */
.label {
  margin-bottom: 0px;
  /* position: absolute;
  top: 0;
  transition: all 0.3s ease; */
}
// .label_focus {
//   /*  top: -20px;
//   font-size: 12px;
//   color: gray; */
// }
.textarea_label {
  position: relative;
}
.m-form-base {
  .m-form-base__container-input {
    textarea {
      border-radius: 5px;
      border: 1px solid gray;
    }
  }
}
// .sub-text {
//   color: #4950579c;
// }
</style>
