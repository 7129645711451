<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div>
              <h1 v-if="ma_id === 0">Création d'une macro pour le modèle de télévision {{ tt_name }}</h1>
              <h1 v-else>Modification de la macro {{ row_ma.ma_key }}</h1>
            </div>
          </div>
          <div class="modal-body">
            <div class="row justify-content-around" v-if="selectOrCreate">
              <button class="col-5 btn btn-primary" @click="selectOrCreateFunc('create')">Créer une macro</button>
              <button class="col-5 btn btn-primary" @click="selectOrCreateFunc('select')">Sélectionner une macro</button>
            </div>
            <div v-else>
              <div v-if="createTvMacro">
                <m-form-text label="Clé *" v-model="row_ma.ma_key" :name="$Utils.randomstring('ma_key')"></m-form-text>
                <span v-if="notUnique" style="color: red"
                  ><icon name="exclamation-triangle"></icon> Cette clé est déjà utilisée et doit être unique!</span
                >
              </div>
              <div v-else>
                <m-form-select
                  label="Clé *"
                  v-model="row_ma.ma_key"
                  @input="setLabel"
                  :name="$Utils.randomstring('ma_key')"
                  :items="macrosKeys"
                ></m-form-select>
                <span v-if="notUnique" style="color: red"
                  ><icon name="exclamation-triangle"></icon> Cette clé est déjà utilisée et doit être unique!</span
                >
              </div>

              <m-form-text
                label="Label *"
                type="text"
                v-model="row_ma.ma_label"
                :name="$Utils.randomstring('ma_label')"
                :rules="[$Validation.mandatory]"
              ></m-form-text>
            </div>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between">
            <div>
              <button type="button" v-if="ma_id != 0" class="btn btn-danger" @click="deleteConfirmWin">Supprimer</button>
            </div>
            <div class="d-flex flex-row align-items-end">
              <button type="button" class="btn btn-secondary" @click="cancelWin">Annuler</button>
              <button type="button" class="btn btn-primary btn-mouveal ml-2" @click="saveWin">Sauvegarder</button>
            </div>
          </div>
        </div>
      </div>
      <m-message-dialog v-model="dialogErr" title="Erreur" :text="dialogErrTxt"></m-message-dialog>
      <m-confirm-dialog
        v-model="confirmdelete"
        :text="`Souhaitez-vous effacer la macro ${tt_name} ?`"
        sousText="Tous les codes macros associés seront également supprimés."
        title="Confirmation"
        width="500px"
        @canceled="confirmdelete = false"
        @confirmed="deleteWin()"
      ></m-confirm-dialog>
    </div>
  </transition>
</template>

<script>
import Icon from "vue-awesome/components/Icon.vue";
export default {
  name: "TvMacroWinEdit",
  components: { Icon },
  props: {
    value: {
      default: false,
      type: Boolean
    },
    tt_id: Number,
    tt_name: String,
    ma_id: {
      default: 0,
      type: Number
    }
  },
  mounted() {
    this.getIrMacrosList();
  },
  watch: {
    value: function(val) {
      if (val) {
        this.getMacro();
      }
    }
  },
  data() {
    return {
      rows_ma: [],
      row_ma: {
        ma_key: "",
        ma_label: ""
      },
      dialogErr: false,
      dialogErrTxt: "",
      avatar: null,
      confirmdelete: false,
      notUnique: false,
      macrosKeys: [],
      selectOrCreate: true,
      createTvMacro: false
    };
  },
  methods: {
    async saveWin() {
      let err = [];
      let fieldRequired = [
        { field: "ma_key", text: "Label de la macro" },
        { field: "ma_label", text: "Label de la macro" }
      ];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.row_ma[field.field]) err.push(field);
      }
      if (err.length) {
        this.dialogErrTxt = "<span class='bis'>Vous devez remplir les champs : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error.text + " <br>";
        }
        this.dialogErr = true;
      } else {
        this.createTvMacro = false;
        this.selectOrCreate = true;
        this.row_ma.tt_id = this.tt_id;
        let response;
        if (this.row_ma.ma_id) {
          response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/tvMacro", this.row_ma);
        } else {
          response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/tvMacros", this.row_ma);
        }
        if (response.data.error) {
          this.notUnique = true;
        } else {
          this.notUnique = false;
          this.$emit("winEditSaved", response, this.row_ma.ma_label);
          this.addNewMacroInList();
        }
      }
    },
    cancelWin() {
      this.selectOrCreate = true;
      this.createTvMacro = false;
      this.$emit("winEditCanceled");
    },
    async getMacro() {
      if (!this.ma_id) return;
      this.selectOrCreate = false;
      this.createTvMacro = false;
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/tvmacro/" + this.ma_id);
      if (response.data && response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }
      this.row_ma = response.data && response.data.data ? response.data.data : {};
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteWin() {
      await this.$axios.delete(this.$config.server_url + "/extranet/2.0/tvMacro/" + this.ma_id);
      await this.$axios.delete(this.$config.server_url + "/extranet/2.0/tvmacroscodemacro/" + this.ma_id);
      this.$emit("winEditDeleted", this.row_ma.ma_key, "macro");
      this.confirmdelete = false;
      this.selectOrCreate = true;
      this.createTvMacro = false;
    },
    async getIrMacrosList() {
      try {
        let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/irmacros");
        if (response && response.data && response.data.data) {
          this.rows_ma = response.data.data;
          for (const row_ma of this.rows_ma) {
            if (this.macrosKeys.find(macro => row_ma.value === macro.value)) continue;
            else this.macrosKeys.push({ value: row_ma.value, text: row_ma.value });
          }
        }
      } catch (error) {
        console.error("ERROR getIrMacrosList > ", error.message);
      }
    },
    selectOrCreateFunc(action) {
      this.selectOrCreate = false;
      if (action === "create") {
        (this.row_ma = {
          ma_key: "",
          ma_label: ""
        }),
          (this.createTvMacro = true);
      }
    },
    setLabel(ma_key) {
      let selectedMacro = this.rows_ma.find(macro => macro.value === ma_key);
      this.row_ma.ma_label = selectedMacro.text;
    },
    addNewMacroInList() {
      if (!this.rows_ma.find(macro => macro.value === this.row_ma.ma_key)) {
        this.macrosKeys.push({ value: this.row_ma.ma_key, text: this.row_ma.ma_key });
        this.rows_ma.push({ value: this.row_ma.ma_key, text: this.row_ma.ma_label });
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";

.winedit-container-header {
  width: 100%;
  justify-content: space-between;
}
.input-avatar {
  padding: 0;
}
.modal {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.active {
  min-width: 150px;
  input {
    width: auto;
  }
}
/*************** table droits **************/
tr {
  border-bottom: 0.5px solid #ccc9c9;
}
td:first-child {
  text-align: left;
}
td,
th {
  border-right: 0.5px solid #ccc9c9;
  border-left: 0.5px solid #ccc9c9;
  padding: 5px 10px;
  text-align: center;
}
th {
  background-color: #013c62;
  color: #fff;
  text-transform: uppercase;
}
th.groupe {
  color: black;
}
.groupe {
  background-color: #b5b2b2;
}
</style>
