<template>
  <div class="" id="research">
    <div class="row">
      <div class="col-md-12">
        <h1>
          Résultats de votre
          <span class="bis">recherche</span> :
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card" v-if="researchResponse.rows_co && researchResponse.rows_co.length">
          <h6>
            <span class="number-title"> <icon width="18" height="18" name="user"></icon></span> {{ researchResponse.total_co }}
            {{ researchResponse.total_co > 1 ? "Contacts trouvés" : "Contact trouvé" }}
          </h6>
          <m-list-simple ref="contactslist" :items="researchResponse.rows_co" item-value="co_id" :item-height="20" @itemclick="goToResearch">
            <template v-slot:ths="{}">
              <th>Nom</th>

              <th>email</th>
            </template>
            <template v-slot:tds="{ item }">
              <td>
                {{ item | formatContactNameSimple("co_invoice_") }}
              </td>
              <td>
                {{ item.co_invoice_email }}
              </td>
            </template>
          </m-list-simple>
          <!--           <div class="searchResult" v-for="row in researchResponse.rows_co" :key="row.co_id">
            <a @click="goToResearchDetail('contacts', row.co_id)">{{ row | formatContactNameSimple("co_invoice_") }}</a>
            <br />
          </div> -->
        </div>
        <div class="card" v-if="researchResponse.rows_en && researchResponse.rows_en.length">
          <h6>
            <span class="number-title"> <icon width="18" height="18" name="user-injured"></icon></span> {{ researchResponse.total_en }}
            {{ researchResponse.total_en > 1 ? "utilisateurs finaux trouvés" : "utilisateur final trouvé" }}
          </h6>
          <m-list-simple
            ref="enduserslist"
            :items="researchResponse.rows_en"
            item-value="en_id"
            :item-height="20"
            :total="researchResponse.total_en"
            @itemclick="goToResearch"
          >
            <template v-slot:ths="{}">
              <th>Numéro box</th>
              <th>Contact</th>
              <th>Utilisateur final</th>
              <th>Date</th>
            </template>
            <template v-slot:tds="{ item }">
              <td>
                {{ item.en_id }}
              </td>
              <td>
                {{ item.en_fullname }}
              </td>
              <td>
                {{ item.co_id | formatContactNameSimple("co_invoice_") }}
              </td>
              <td>{{ $moment(item.createdAt).format("DD/MM/YYYY HH[H]mm") }}</td>
            </template>
          </m-list-simple>
          <!--  <div class="searchResult" v-for="row in researchResponse.rows_en" :key="row.en_id">
            <a @click="goToResearchDetail('boxes', row.en_id)">{{ row.en_fullname }}</a>
            <br />
          </div> -->
        </div>

        <div class="card" v-if="researchResponse.rows_pa && researchResponse.rows_pa.length">
          <h6>
            <span class="number-title"> <icon width="18" height="18" name="user-injured"></icon></span> {{ researchResponse.total_pa }}
            {{ researchResponse.total_pa > 1 ? "colis (utilisateurs finaux) trouvés" : "colis (utilisateur final) trouvé" }}
          </h6>
          <m-list-simple
            ref="parcelslist"
            :items="researchResponse.rows_pa"
            item-value="en_id.en_id"
            :item-height="20"
            :total="researchResponse.total_pa"
            @itemclick="goToResearch"
          >
            <template v-slot:ths="{}">
              <th>Numéro box</th>
              <th>Contact</th>
              <th>Utilisateur final</th>
              <th>Date</th>
            </template>
            <template v-slot:tds="{ item }">
              <td>
                {{ item.en_id.en_id }}
              </td>
              <td>
                {{ item.en_id.en_fullname }}
              </td>
              <td>
                {{ item.en_id.co_id | formatContactNameSimple("co_invoice_") }}
              </td>
              <td>{{ $moment(item.createdAt).format("DD/MM/YYYY HH[H]mm") }}</td>
            </template>
          </m-list-simple>
          <!--  <div class="searchResult" v-for="row in researchResponse.rows_en" :key="row.en_id">
            <a @click="goToResearchDetail('boxes', row.en_id)">{{ row.en_fullname }}</a>
            <br />
          </div> -->
        </div>
        <div class="card" v-if="researchResponse.rows_us && researchResponse.rows_us.length">
          <h6>
            <span class="number-title"> <icon width="18" height="18" name="user-tie"></icon></span> {{ researchResponse.total_us }}
            {{ researchResponse.total_us > 1 ? "membres trouvés" : "membre trouvé" }}
          </h6>
          <m-list-simple ref="userslist" :items="researchResponse.rows_us" item-value="us_id" :item-height="20" @itemclick="goToResearch">
            <template v-slot:ths="{}">
              <th>Nom</th>
              <th>type</th>
              <th>email</th>
              <th>Établissements</th>
            </template>
            <template v-slot:tds="{ item }">
              <td>
                {{ item | formatContactNameSimple("us_") }}
              </td>
              <td>
                {{ item.us_type }}
              </td>
              <td>
                {{ item.us_email }}
              </td>
              <td></td>
            </template>
          </m-list-simple>
          <!--           <div class="searchResult" v-for="row in researchResponse.rows_us" :key="row.us_id">
            <a @click="goToResearch('users', row.us_id)">{{ row | formatContactNameSimple("us_") }}</a>
            <br />
          </div> -->
        </div>
        <div class="card" v-if="researchResponse.rows_es && researchResponse.rows_es.length">
          <h6>
            <span class="number-title"> <icon width="18" height="18" name="clinic-medical"></icon></span>
            {{ researchResponse.total_es }} {{ researchResponse.total_es > 1 ? "établissements trouvés" : "établissement trouvé" }}
          </h6>
          <m-list-simple ref="establishmentslist" :items="researchResponse.rows_es" item-value="es_id" :item-height="20" @itemclick="goToResearch">
            <template v-slot:ths="{}">
              <th>Nom</th>
              <th>Ville</th>

              <th>Email</th>
              <th>Tel</th>
            </template>
            <template v-slot:tds="{ item }">
              <td>
                {{ item.es_name }}
              </td>
              <td>
                {{ item.es_city }}
              </td>
              <td>
                {{ item.es_email }}
              </td>
              <td>{{ item.es_tel }}</td>
            </template>
          </m-list-simple>
          <!--           <div class="searchResult" v-for="row in researchResponse.rows_es" :key="row.es_id">
            <a @click="goToResearch('establisments', row.es_id)">{{ row.es_name }}</a>
            <br />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "home",
  components: {},
  props: { researchVal: String, filterDateStart: String, filterDateEnd: String },
  data() {
    return {
      user: {},
      researchResponse: {},
      tsSearch: null
    };
  },
  mounted() {
    this.user = this.$store.state.user;
  },
  watch: {
    researchVal: function(val) {
      if (this.tsSearch) window.clearTimeout(this.tsSearch);
      this.tsSearch = window.setTimeout(() => {
        this.research(val);
      }, 1000);
    },
    filterDateStart: function(val) {
      if (this.tsSearch) window.clearTimeout(this.tsSearch);
      this.tsSearch = window.setTimeout(() => {
        this.research(val);
      }, 1000);
    },
    filterDateEnd: function(val) {
      if (this.tsSearch) window.clearTimeout(this.tsSearch);
      this.tsSearch = window.setTimeout(() => {
        this.research(val);
      }, 1000);
    }
  },
  filters: {},
  methods: {
    async research() {
      const body = {
        filterDateStart: this.filterDateStart,
        filterDateEnd: this.filterDateEnd
      };
      let response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/searches/search/" + this.researchVal, body);
      this.researchResponse = response.data;
    },
    goToResearch(item) {
      let route = "";
      if (item && item.us_id) route = "/users/" + item.us_id;
      if (item && item.en_id && !item.en_id.en_id) route = "/boxes/" + item.en_id;
      if (item && item.pa_id && item.en_id && item.en_id.en_id) route = "/boxes/" + item.en_id.en_id;
      if (item && item.es_id && !item.co_id) route = "/establishments/" + item.es_id;
      if (item && item.co_id && item.es_id) route = "/contacts/" + item.co_id + "/details";
      this.$router.push(route);
      this.$emit("deleteResearch");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";
.searchResult {
  margin: (5px 0 5px 30px);
}
</style>
