var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "d-flex tvs" },
    [
      _c("div", { staticClass: "d-flex flex-column", attrs: { id: "tvs" } }, [
        _c("div", { staticClass: "d-flex header-emotivi" }, [
          _c("div", { staticClass: "d-flex align-items-center" }, [
            _c("h1", [
              _vm._v("Liste des téléviseurs (" + _vm._s(_vm.tvs_total) + ")")
            ])
          ]),
          _c(
            "div",
            {
              staticClass:
                "flex-grow-1 d-flex flex-column justify-content-end align-items-end"
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex flex-wrap",
                  staticStyle: { "margin-top": "-45px" }
                },
                [
                  _c("m-form-text", {
                    staticClass: "ml-2 col-form-label-sm",
                    attrs: {
                      width: "200px",
                      label: "Rechercher",
                      "label-position": "top",
                      name: "search",
                      autocomplete: ""
                    },
                    on: {
                      input: function($event) {
                        return _vm.loadTvs(true)
                      }
                    },
                    model: {
                      value: _vm.filtersTv.filterText,
                      callback: function($$v) {
                        _vm.$set(_vm.filtersTv, "filterText", $$v)
                      },
                      expression: "filtersTv.filterText"
                    }
                  }),
                  _vm.$store.state.user.us_type === "LATEAM"
                    ? _c("m-form-text", {
                        staticClass: "ml-2 col-form-label-sm",
                        attrs: {
                          label: "Filtrer par numéro de version",
                          "label-position": "top",
                          name: _vm.$Utils.randomstring("filterVersionTv")
                        },
                        on: {
                          input: function($event) {
                            return _vm.loadTvs("deviceOnly", 1000)
                          }
                        },
                        model: {
                          value: _vm.filtersTv.filterVersionTv,
                          callback: function($$v) {
                            _vm.$set(_vm.filtersTv, "filterVersionTv", $$v)
                          },
                          expression: "filtersTv.filterVersionTv"
                        }
                      })
                    : _vm._e(),
                  _vm.$store.state.user.us_type === "LATEAM" ||
                  _vm.$store.state.user.us_type === "EMOTIVI_USER"
                    ? _c("m-form-select", {
                        staticClass: "ml-2 col-form-label-sm",
                        attrs: {
                          label: "Groupes",
                          "label-position": "top",
                          "item-value": "gr_id",
                          name: _vm.$Utils.randomstring("filterStatus"),
                          items: _vm.$store.state.mookGroups
                        },
                        on: {
                          input: function($event) {
                            return _vm.loadTvs(true)
                          }
                        },
                        model: {
                          value: _vm.filterStatus,
                          callback: function($$v) {
                            _vm.filterStatus = $$v
                          },
                          expression: "filterStatus"
                        }
                      })
                    : _vm._e(),
                  _vm.$store.getters.checkUserAccess(
                    _vm.$router,
                    "/establishments"
                  )
                    ? _c("m-form-combobox", {
                        staticClass: "ml-2 col-form-label-sm",
                        attrs: {
                          label: "Etablissement",
                          "label-position": "top",
                          name: _vm.$Utils.randomstring("filterEstablishment"),
                          "store-url":
                            _vm.$config.server_url +
                            "/extranet/2.0/establishments",
                          "store-params": { ignorePopulate: true },
                          "item-value": "es_id",
                          "item-text": "es_name"
                        },
                        on: {
                          input: function($event) {
                            return _vm.loadTvs(true)
                          }
                        },
                        model: {
                          value: _vm.filtersTv.filterEstablishment,
                          callback: function($$v) {
                            _vm.$set(_vm.filtersTv, "filterEstablishment", $$v)
                          },
                          expression: "filtersTv.filterEstablishment"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("div", { staticClass: "d-flex flex-wrap" }, [
                _vm.$store.getters.checkUserAccess(_vm.$router, "/tvs/new")
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-primary color-white input-filter btn-sm",
                        attrs: { type: "button" },
                        on: { click: _vm.newEnduser }
                      },
                      [
                        _c("icon", {
                          staticClass: "mr-2",
                          attrs: { name: "plus", scale: "1" }
                        }),
                        _vm._v(" Nouveau ")
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$store.state.user.us_type === "LATEAM" ||
                _vm.$store.state.user.us_type === "DIRECTOR" ||
                _vm.$store.state.user.us_type === "STAFF"
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-primary color-white input-filter btn-sm",
                        attrs: { type: "button" },
                        on: { click: _vm.showModalToSendMessage }
                      },
                      [_vm._v(" Envoyer un message à tous ")]
                    )
                  : _vm._e(),
                _vm.$store.state.user.us_type === "LATEAM" ||
                _vm.$store.state.user.us_type === "DIRECTOR" ||
                _vm.$store.state.user.us_type === "STAFF"
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-primary color-white input-filter btn-sm",
                        attrs: { type: "button" },
                        on: { click: _vm.showModalToScheduleMessage }
                      },
                      [_vm._v(" Planifier un message pour tous ")]
                    )
                  : _vm._e()
              ])
            ]
          )
        ]),
        _c("div", { staticClass: "flex-grow-1 overflow-hidden d-flex" }, [
          _c(
            "div",
            { staticClass: "card card-no-margin" },
            [
              _c("m-list", {
                ref: "tvslist",
                attrs: {
                  dynamic: true,
                  items: _vm.tvs,
                  "item-value": "en_id",
                  "item-height": 30,
                  limit: _vm.tvs_limit,
                  skip: _vm.tvs_skip,
                  total: _vm.tvs_total,
                  current: _vm.tvs_current,
                  bgstrip: true
                },
                on: { changerange: _vm.tvsChangerange, itemclick: _vm.goTv },
                scopedSlots: _vm._u([
                  {
                    key: "ths",
                    fn: function(ref) {
                      return [
                        _c(
                          "th",
                          {
                            on: {
                              click: function($event) {
                                return _vm.sortItems(["en_id"])
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "pointer" }, [
                              _vm._v("Numéro (en_id)")
                            ])
                          ]
                        ),
                        _c(
                          "th",
                          {
                            on: {
                              click: function($event) {
                                return _vm.sortItems(["en_fullname"])
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "pointer" }, [
                              _vm._v("Utilisateur final")
                            ])
                          ]
                        ),
                        _c("th", [
                          _c("span", { staticClass: "pointer" }, [
                            _vm._v("Établissement")
                          ])
                        ]),
                        _vm.$store.state.user.us_type !== "PARTNER"
                          ? _c(
                              "th",
                              { staticStyle: { "min-width": "140px" } },
                              [
                                _c("span", { staticClass: "pointer" }, [
                                  _vm._v("Vidéo/Ok/Voix/Ok/Message")
                                ])
                              ]
                            )
                          : _vm._e(),
                        _c("th", { staticStyle: { width: "120px" } }, [
                          _c("span", { staticClass: "pointer" }, [
                            _vm._v("A faire")
                          ])
                        ]),
                        _vm.$store.state.user.us_type !== "PARTNER"
                          ? _c("th", { staticStyle: { width: "120px" } }, [
                              _c("span", { staticClass: "pointer" }, [
                                _vm._v("Actions")
                              ])
                            ])
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "tds",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("td", [
                          _vm._v(" " + _vm._s(item.en_id) + " "),
                          item.de_id
                            ? _c("span", [
                                _vm._v(_vm._s(item.de_id.de_mac_address))
                              ])
                            : _vm._e()
                        ]),
                        _c("td", [
                          _vm._v(" " + _vm._s(item.en_fullname) + " "),
                          _c("span", { staticClass: "color-emotivi-blue" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.co_id.afco_id &&
                                    item.co_id.afco_id.af_id &&
                                    item.co_id.afco_id.af_id.af_code
                                    ? "(Affilié à " +
                                        item.co_id.afco_id.af_id.af_name +
                                        " " +
                                        item.co_id.afco_id.af_id.af_code +
                                        ")"
                                    : ""
                                ) +
                                " " +
                                _vm._s(
                                  item.en_linkello ? "(Associé à Linkello)" : ""
                                ) +
                                " "
                            )
                          ])
                        ]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.co_id && item.co_id.es_id
                                  ? item.co_id.es_id.es_name
                                  : ""
                              ) +
                              " "
                          )
                        ]),
                        _vm.$store.state.user.us_type !== "PARTNER"
                          ? _c(
                              "td",
                              [
                                _vm._l(item.infos.nbCalls, function(
                                  number,
                                  index
                                ) {
                                  return _c("span", { key: index }, [
                                    _vm._v(_vm._s(number) + " "),
                                    index < item.infos.nbCalls.length - 1
                                      ? _c("span", [_vm._v("/ ")])
                                      : _vm._e()
                                  ])
                                }),
                                item.newMessage && item.newMessage.length > 0
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "20px",
                                          "background-color": "green",
                                          color: "white",
                                          "font-weight": "bold",
                                          "border-radius": "50%",
                                          width: "25px",
                                          display: "inline-block",
                                          "text-align": "center",
                                          padding: "5px",
                                          "margin-top": "5px"
                                        },
                                        attrs: {
                                          "data-popover": _vm.textMessage(
                                            item.newMessage.length
                                          )
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.newMessage.length) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              2
                            )
                          : _vm._e(),
                        _c(
                          "td",
                          {
                            staticStyle: { width: "120px" },
                            attrs: {
                              "data-popover": _vm
                                .toDo(item)
                                .replace(/Box/g, "TV")
                            }
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.toDo(item).replace(/Box/g, "TV"))
                              )
                            ])
                          ]
                        ),
                        _vm.$store.state.user.us_type !== "PARTNER"
                          ? _c("td", { staticStyle: { width: "120px" } }, [
                              _c("div", { staticClass: "d-flex flex-row" }, [
                                item.co_id &&
                                _vm.$store.state.user.us_type !== "TECHNICIAN"
                                  ? _c(
                                      "div",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.sendEmail(item, $event)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "mailto:" +
                                                item.co_id.co_invoice_email
                                            }
                                          },
                                          [
                                            _c("icon", {
                                              staticClass: "ml-2",
                                              attrs: {
                                                width: "20",
                                                height: "20",
                                                name: "envelope",
                                                color: "black"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ])
      ]),
      _c("m-message-dialog", {
        attrs: {
          title: "Diagnostic réseau",
          text: _vm.dialogDiagnostiqueReseauTxt,
          width: "70%"
        },
        model: {
          value: _vm.dialogDiagnostiqueReseau,
          callback: function($$v) {
            _vm.dialogDiagnostiqueReseau = $$v
          },
          expression: "dialogDiagnostiqueReseau"
        }
      }),
      _c("m-message-dialog", {
        attrs: { title: "Confirmation", text: _vm.dialogMessageTxt },
        model: {
          value: _vm.dialogMessage,
          callback: function($$v) {
            _vm.dialogMessage = $$v
          },
          expression: "dialogMessage"
        }
      }),
      _c("enduser-win-edit", {
        attrs: { en_id: -1 },
        on: { WinEditSaved: _vm.loadTvs, winEditCanceled: _vm.cancelWin },
        model: {
          value: _vm.dialogEnduser,
          callback: function($$v) {
            _vm.dialogEnduser = $$v
          },
          expression: "dialogEnduser"
        }
      }),
      _c("modal-send-message-all", {
        model: {
          value: _vm.modalSendMessage,
          callback: function($$v) {
            _vm.modalSendMessage = $$v
          },
          expression: "modalSendMessage"
        }
      }),
      _c("modal-schedule-message-all", {
        model: {
          value: _vm.modalScheduleMessage,
          callback: function($$v) {
            _vm.modalScheduleMessage = $$v
          },
          expression: "modalScheduleMessage"
        }
      }),
      _c("modal-send-ir-macro", {
        model: {
          value: _vm.modalSendIrMacro,
          callback: function($$v) {
            _vm.modalSendIrMacro = $$v
          },
          expression: "modalSendIrMacro"
        }
      }),
      _c("popover", { ref: "popover" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }