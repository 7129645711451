<template>
  <div :style="{ display: displayAudiowaves }">
    <div id="bars">
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
    </div>
    <div id="bars2">
      <div class="bar2"></div>
      <div class="bar2"></div>
      <div class="bar2"></div>
      <div class="bar2"></div>
      <div class="bar2"></div>
      <div class="bar2"></div>
      <div class="bar2"></div>
      <div class="bar2"></div>
      <div class="bar2"></div>
      <div class="bar2"></div>
      <div class="bar2"></div>
      <div class="bar2"></div>
      <div class="bar2"></div>
      <div class="bar2"></div>
      <div class="bar2"></div>
      <div class="bar2"></div>
      <div class="bar2"></div>
      <div class="bar2"></div>
      <div class="bar2"></div>
      <div class="bar2"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AudioWaves",

  data() {
    return {
      display: "none"
    };
  },
  methods: {
    changeDisplay(display) {
      this.display = display;
    }
  },
  computed: {
    displayAudiowaves() {
      return this.display;
    }
  }
};
</script>
<style>
#bars {
  height: 40%;
  /* left: 50%; */
  /*margin: -30px 0 0 -20px;*/
  position: absolute;
  top: 10%;
  width: inherit;
}
#bars2 {
  height: 40%;
  /* left: 50%; */
  /*margin: -30px 0 0 -20px;*/
  position: absolute;
  top: 30%;
  width: inherit;
}

.bar {
  background: #010101;
  bottom: 1%;
  /* height: 3%; */
  position: absolute;
  width: 3%;
  animation: sound 0ms -100ms linear infinite alternate;
}
.bar2 {
  background: #010101;
  /* bottom: 1%; */
  /* height: 3%; */
  top: 50%;
  left: 0;
  position: absolute;
  width: 3%;
  animation: sound 0ms -100ms linear infinite alternate;
}
@keyframes sound {
  0% {
    opacity: 0.35;
    height: 3%;
  }
  100% {
    opacity: 1;
    height: 28%;
  }
}

.bar:nth-child(1) {
  left: 0%;
  animation-duration: 474ms;
}
.bar:nth-child(2) {
  left: 5%;
  animation-duration: 433ms;
}
.bar:nth-child(3) {
  left: 10%;
  animation-duration: 407ms;
}
.bar:nth-child(4) {
  left: 15%;
  animation-duration: 458ms;
}
.bar:nth-child(5) {
  left: 20%;
  animation-duration: 400ms;
}
.bar:nth-child(6) {
  left: 25%;
  animation-duration: 427ms;
}
.bar:nth-child(7) {
  left: 30%;
  animation-duration: 441ms;
}
.bar:nth-child(8) {
  left: 35%;
  animation-duration: 419ms;
}
.bar:nth-child(9) {
  left: 40%;
  animation-duration: 487ms;
}
.bar:nth-child(10) {
  left: 45%;
  animation-duration: 442ms;
}
.bar:nth-child(11) {
  left: 50%;
  animation-duration: 442ms;
}
.bar:nth-child(12) {
  left: 55%;
  animation-duration: 433ms;
}
.bar:nth-child(13) {
  left: 60%;
  animation-duration: 407ms;
}
.bar:nth-child(14) {
  left: 65%;
  animation-duration: 458ms;
}
.bar:nth-child(15) {
  left: 70%;
  animation-duration: 400ms;
}
.bar:nth-child(16) {
  left: 75%;
  animation-duration: 427ms;
}
.bar:nth-child(17) {
  left: 80%;
  animation-duration: 441ms;
}
.bar:nth-child(18) {
  left: 85%;
  animation-duration: 419ms;
}
.bar:nth-child(19) {
  left: 90%;
  animation-duration: 487ms;
}
.bar:nth-child(20) {
  left: 95%;
  animation-duration: 442ms;
}
.bar2:nth-child(1) {
  left: 0%;
  animation-duration: 474ms;
}
.bar2:nth-child(2) {
  left: 5%;
  animation-duration: 433ms;
}
.bar2:nth-child(3) {
  left: 10%;
  animation-duration: 407ms;
}
.bar2:nth-child(4) {
  left: 15%;
  animation-duration: 458ms;
}
.bar2:nth-child(5) {
  left: 20%;
  animation-duration: 400ms;
}
.bar2:nth-child(6) {
  left: 25%;
  animation-duration: 427ms;
}
.bar2:nth-child(7) {
  left: 30%;
  animation-duration: 441ms;
}
.bar2:nth-child(8) {
  left: 35%;
  animation-duration: 419ms;
}
.bar2:nth-child(9) {
  left: 40%;
  animation-duration: 487ms;
}
.bar2:nth-child(10) {
  left: 45%;
  animation-duration: 442ms;
}
.bar2:nth-child(11) {
  left: 50%;
  animation-duration: 442ms;
}
.bar2:nth-child(12) {
  left: 55%;
  animation-duration: 433ms;
}
.bar2:nth-child(13) {
  left: 60%;
  animation-duration: 407ms;
}
.bar2:nth-child(14) {
  left: 65%;
  animation-duration: 458ms;
}
.bar2:nth-child(15) {
  left: 70%;
  animation-duration: 400ms;
}
.bar2:nth-child(16) {
  left: 75%;
  animation-duration: 427ms;
}
.bar2:nth-child(17) {
  left: 80%;
  animation-duration: 441ms;
}
.bar2:nth-child(18) {
  left: 85%;
  animation-duration: 419ms;
}
.bar2:nth-child(19) {
  left: 90%;
  animation-duration: 487ms;
}
.bar2:nth-child(20) {
  left: 95%;
  animation-duration: 442ms;
}
</style>
