<template>
  <section class="d-flex w-100">
    <div class="w-100" id="boxes">
      <div class="d-flex header-emotivi">
        <div class="d-flex align-items-center">
          <h1>Statistiques {{ $store.state.user.us_type === "LATEAM" ? "V2 uniquement (la V1 n'est pas prise en compte)" : "" }}</h1>
        </div>
      </div>
      <div class="w-100">
        <div v-if="loading">
          <div class="spinner row flex-column justify-content-center alignt-items-center m-5 pt-5">
            <h6 class="col text-center">Chargement des statistiques en cours (environ 30 secondes)...</h6>
            <icon class="col" name="spinner" scale="5" spin></icon>
          </div>
        </div>
        <div v-else>
          <div class="card card-no-margin w-100">
            <h6>Clients actifs</h6>
            <p>
              Recherche si un client a fait au moins un appel (audio, vidéo, message).
              {{
                $store.state.user.us_type === "LATEAM"
                  ? "Recherche les appels vidéos, audio, messages envoyés dans la table action et groupe par boxes pour le mois."
                  : ""
              }}
            </p>
            <m-chart-bar v-if="chartBarLoaded" :chartdata="alldatas.statCustomersActif" :options="chartOptions" />
          </div>

          <div class="card card-no-margin w-100">
            <h6>Boxes actives</h6>
            <p>
              {{
                $store.state.user.us_type === "LATEAM"
                  ? "Compte les connexions des websocket sur le serveur (dans la table action le type 'BOX_CONNECTION', où une action est rajouté quand une box se (re)connect au serveur) et groupe par boxes. Normalement on redémarre le serveur au moins une fois par mois, donc ce chiffre devrait être juste..."
                  : "Boxes connectées au serveur Emotivi."
              }}
            </p>
            <m-chart-bar v-if="chartBarLoaded" :chartdata="alldatas.statBoxesActif" :options="chartOptions" />
          </div>

          <div v-if="$store.state.user.us_type === 'LATEAM'" class="card card-no-margin w-100">
            <h6>CLIENTS ABONNÉS</h6>
            <p>
              Recherche les boxes actives sur les 90 derniers jours (pourquoi pas ?), joint la table des boxes, regarde la donnée
              'source_emotivi_usecec' et 'source_emotivi_useir'
            </p>
            <div class="d-flex">
              <div v-if="chartBarLoaded" class="mr-2 frame-stat">
                <div>RIB</div>
                <div class="title">{{ alldatas.statCustomersSubscribe.ribp }}%</div>
                <div>{{ alldatas.statCustomersSubscribe.rib }}</div>
              </div>
              <div v-if="chartBarLoaded" class="mr-2 frame-stat">
                <div>CB</div>
                <div class="title">{{ alldatas.statCustomersSubscribe.cbp }}%</div>
                <div>{{ alldatas.statCustomersSubscribe.cb }}</div>
              </div>
              <div v-if="chartBarLoaded" class="mr-2 frame-stat">
                <div>Total</div>
                <div class="title">{{ alldatas.statCustomersSubscribe.total }}</div>
              </div>
            </div>
          </div>
          <div class="card card-no-margin w-100">
            <h6>Nombres d'appels vidéos, audios, messages</h6>
            <p>Recherche le nombre d'appels envoyés pour le mois</p>
            <m-chart-bar v-if="chartBarLoaded" :chartdata="alldatas.statNbMessagesVisiosAudios" :options="chartOptions" />
          </div>

          <div class="card card-no-margin w-100">
            <h6>TEMPS MOYEN D'UN APPEL</h6>
            <p>
              Recherche les appels vidéos ou audio supérieur à 60s.
              {{
                $store.state.user.us_type === "LATEAM"
                  ? "(pour éliminer les appels sans décroché), additionne le temps de chaque appel et divise par le nombre d'appels sur le mois"
                  : ""
              }}
            </p>
            <m-chart-bar v-if="chartBarLoaded" :chartdata="alldatas.statTempsMoyenAppels" :options="chartOptions" />
          </div>

          <div class="card card-no-margin w-100">
            <h6>RÉPARTITION DES APPELS PAR HEURE</h6>
            <p>
              {{
                $store.state.user.us_type === "LATEAM"
                  ? "Recherche les appels audio ou vidéo depuis la création de la V2 et affiche combien il y en a eu par 1/4 d'heure."
                  : "Recherche les appels audio ou vidéo et affiche combien il y en a eu par 1/4 d'heure."
              }}
            </p>
            <m-chart-bar v-if="chartBarLoaded" :chartdata="alldatas.statHours" :options="chartOptions" />
          </div>

          <div class="card card-no-margin w-100">
            <h6>RÉPARTITION DES APPELS PAR JOURS DE LA SEMAINE</h6>
            <p>
              {{
                $store.state.user.us_type === "LATEAM"
                  ? "Recherche les appels audio ou vidéo depuis la création de la V2 et affiche combien il y en a eu par jour de la semaine."
                  : "Recherche les appels audio ou vidéo et affiche combien il y en a eu par jour de la semaine."
              }}
            </p>
            <m-chart-bar v-if="chartBarLoaded" :chartdata="alldatas.statDaysWeek" :options="chartOptions" />
          </div>

          <div class="card card-no-margin w-100">
            <h6>RÉPARTITION DES APPELS PAR SEMAINE</h6>
            <p>
              {{
                $store.state.user.us_type === "LATEAM"
                  ? "Recherche les appels audio ou vidéo depuis la création de la V2 et affiche combien il y en a eu par semaine."
                  : "Recherche les appels audio ou vidéo et affiche combien il y en a eu par semaine."
              }}
            </p>
            <m-chart-bar v-if="chartBarLoaded" :chartdata="alldatas.statWeeks" :options="chartOptions" />
          </div>

          <div class="card card-no-margin w-100">
            <h6>Nombre d'appels en moyenne par mois par utilisateur</h6>
            <p>
              {{
                $store.state.user.us_type === "LATEAM"
                  ? "Recherche les appels audio ou vidéo depuis la création de la V2 et affiche combien il y en a eu en moyenne par mois par utilisateur."
                  : "Recherche les appels audio ou vidéo et affiche combien il y en a eu en moyenne par mois par utilisateur."
              }}
            </p>
            <m-chart-bar v-if="chartBarLoaded" :chartdata="alldatas.statNbreMoyenAppelsParMoisParUser" :options="chartOptions" />
          </div>

          <div class="card card-no-margin w-100">
            <h6>UTILISATION DU BOUTON PAR APPELS</h6>
            <p>
              Recherche les appels visio ou audio puis additionne ceux avec ou sans bouton et fait l'addition des deux nombres pour trouver le nombre
              d'appels, par mois.
            </p>
            <m-chart-bar v-if="chartBarLoaded" :chartdata="alldatas.statButton" :options="chartOptions" />
          </div>

          <div class="card card-no-margin w-100">
            <h6>SOURCES UTILISÉS</h6>
            <p>
              {{
                $store.state.user.us_type === "LATEAM"
                  ? "Recherche les boxes actives sur les 90 derniers jours (pourquoi pas ?), joint la table des boxes, regarde la donnée 'source_emotivi_usecec' et 'source_emotivi_useir'"
                  : "Recherche les boxes actives sur les 90 derniers jours."
              }}
            </p>

            <div class="d-flex">
              <div v-if="chartBarLoaded" class="mr-2 frame-stat">
                <div>CEC uniquement</div>
                <div class="title">{{ alldatas.statCecIr.cecp }}%</div>
                <div>{{ alldatas.statCecIr.cec }}</div>
              </div>
              <div v-if="chartBarLoaded" class="mr-2 frame-stat">
                <div>IR uniquement</div>
                <div class="title">{{ alldatas.statCecIr.irp }}%</div>
                <div>{{ alldatas.statCecIr.ir }}</div>
              </div>
              <div v-if="chartBarLoaded" class="mr-2 frame-stat">
                <div>CEC et IR</div>
                <div class="title">{{ alldatas.statCecIr.cecandirp }}%</div>
                <div>{{ alldatas.statCecIr.cecandir }}</div>
              </div>
            </div>
          </div>

          <div class="card card-no-margin w-100">
            <h6>RÉSEAU UTILISÉ</h6>
            <p>
              {{
                $store.state.user.us_type === "LATEAM"
                  ? "Recherche les boxes actives sur les 90 derniers jours (pourquoi pas), joint la table des boxes, regarde la donnée 'bo_config_network.configuration.type'"
                  : "Recherche les boxes actives sur les 90 derniers jours."
              }}
            </p>
            <div class="d-flex">
              <div v-if="chartBarLoaded" class="mr-2 frame-stat">
                <div>Wifi</div>
                <div class="title">{{ alldatas.statNetwork.wifip }}%</div>
                <div>{{ alldatas.statNetwork.wifi }}</div>
              </div>
              <div v-if="chartBarLoaded" class="mr-2 frame-stat">
                <div>Ethernet</div>
                <div class="title">{{ alldatas.statNetwork.ethernetp }}%</div>
                <div>{{ alldatas.statNetwork.ethernet }}</div>
              </div>
              <div v-if="chartBarLoaded" class="mr-2 frame-stat">
                <div>Clé 4g</div>
                <div class="title">{{ alldatas.statNetwork.key4gp }}%</div>
                <div>{{ alldatas.statNetwork.key4g }}</div>
              </div>
            </div>
          </div>
          <div v-if="$store.state.user.us_type === 'LATEAM'" class="card card-no-margin w-100">
            <h6>Les clients</h6>
            <m-chart-bar v-if="chartBarLoaded" :chartdata="chartData" :options="chartOptions" />
          </div>
        </div>
      </div>
      <!-- <div class=" d-flex w-100" style="margin-top:25px">
        <div class="card card-no-margin w-100">
          <h6>Utilisation du décrochage automatique</h6>
          Nombre d’appels avec le décrochage automatique : {{ withAutoresponse }} <br />
          Nombre d’appels sans le décrochage automatique : {{ withoutAutoresponse }}
        </div>
      </div> -->
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import { Bar } from "vue-chartjs";
export default {
  name: "statistiques",
  components: {},
  extends: Bar,
  data() {
    return {
      withAutoresponse: 0,
      withoutAutoresponse: 0,
      alldatas: {},
      chartData: {
        labels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
        datasets: []
      },
      chartOptions: {
        height: 200,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      },
      chartBarLoaded: false,
      loading: true
    };
  },
  async mounted() {
    this.loadCharts();
    this.loadChartBar();
    // this.autoresponse();
  },
  methods: {
    async loadCharts() {
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/stats/all");
      this.loading = false;
      // console.log("response.data.data", response.data.data);
      let alldatas = response.data.data;
      // console.log("🚀 ~ file: Statistiques.vue ~ line 68 ~ loadCharts ~ alldatas", alldatas);

      this.alldatas.statCustomersActif = {
        labels: alldatas.statCustomersActif.map(el => {
          return this.$moment(el.month + "-01").format("MMMM YYYY");
        }),
        datasets: [
          {
            label: "Nombre de clients actifs",
            data: alldatas.statCustomersActif.map(el => {
              return el.val;
            })
          }
        ]
      };

      this.alldatas.statBoxesActif = {
        labels: alldatas.statBoxesActif.map(el => {
          return this.$moment(el.month + "-01").format("MMMM YYYY");
        }),
        datasets: [
          {
            label: "Nombre de boxes actives",
            data: alldatas.statBoxesActif.map(el => {
              return el.val;
            })
          }
        ]
      };

      this.alldatas.statNbMessagesVisiosAudios = {
        labels: alldatas.statNbMessagesVisiosAudios.map(el => {
          return this.$moment(el.month + "-01").format("MMMM YYYY");
        }),
        datasets: [
          {
            label: "Nombre de messages envoyés",
            data: alldatas.statNbMessagesVisiosAudios.map(el => {
              return el.messages;
            })
          },
          {
            label: "Nombre de visios",
            data: alldatas.statNbMessagesVisiosAudios.map(el => {
              return el.visios;
            })
          },
          {
            label: "Nombre de audio",
            data: alldatas.statNbMessagesVisiosAudios.map(el => {
              return el.audios;
            })
          }
        ]
      };

      this.alldatas.statTempsMoyenAppels = {
        labels: alldatas.statTempsMoyenAppels.map(el => {
          return this.$moment(el.month + "-01").format("MMMM YYYY");
        }),
        datasets: [
          {
            label: "Temps en minutes",
            data: alldatas.statTempsMoyenAppels.map(el => {
              return el.val;
            })
          }
        ]
      };

      this.alldatas.statHours = {
        labels: alldatas.statHours.map(el => {
          return el.from + " à " + el.to;
        }),
        datasets: [
          {
            label: "Nombre d'appels",
            data: alldatas.statHours.map(el => {
              return el.val;
            })
          }
        ]
      };

      let itemp = 0;
      this.alldatas.statDaysWeek = {
        labels: alldatas.statDaysWeek.map(el => {
          let d = this.$moment().day(itemp).format("dddd");
          itemp++;
          return d;
        }),
        datasets: [
          {
            label: "Nombre d'appels",
            data: alldatas.statDaysWeek.map(el => {
              return el;
            })
          }
        ]
      };

      this.alldatas.statWeeks = {
        labels: alldatas.statWeeks.map(el => {
          console.log(el.from);
          return this.$moment(el.from).format("MMMM YYYY") + " " + Math.ceil(this.$moment(el.from).date() / 7);
        }),
        datasets: [
          {
            label: "Nombre d'appels",
            data: alldatas.statWeeks.map(el => {
              return el.total;
            })
          }
        ]
      };

      this.alldatas.statNbreMoyenAppelsParMoisParUser = {
        labels: alldatas.statNbreMoyenAppelsParMoisParUser.map(el => {
          return this.$moment(el.month + "-01").format("MMMM YYYY");
        }),
        datasets: [
          {
            label: "Nombre d'appels moyen",
            data: alldatas.statNbreMoyenAppelsParMoisParUser.map(el => {
              return el.val;
            })
          }
        ]
      };

      console.log("alldatas", alldatas);
      this.alldatas.statButton = {
        labels: alldatas.statButton.map(el => {
          return this.$moment(el.month + "-01").format("MMMM YYYY");
        }),
        datasets: [
          {
            label: "% N'utilise pas le bouton",
            data: alldatas.statButton.map(el => {
              return el.withoutbuttonp;
            })
          },
          {
            label: "% Utilise le bouton",
            data: alldatas.statButton.map(el => {
              return el.withbuttonp;
            })
          }
        ]
      };

      this.alldatas.statNetwork = alldatas.statNetwork;
      this.alldatas.statCustomersSubscribe = alldatas.statCustomersSubscribe;
      this.alldatas.statCecIr = alldatas.statCecIr;

      this.chartBarLoaded = true;
    },
    async loadChartBar() {
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/actions/chartclients");
      this.chartData.datasets = response.data.data;
      // console.log("🚀 ~ file: Statistiques.vue ~ line 72 ~ loadChartBar ~ this.chartData.datasets", this.chartData.datasets);
      this.chartBarLoaded = true;
    },
    async autoresponse() {
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/actions/statistiquesAutoresponse");
      // console.log("response", response);
      this.withAutoresponse = response.data.data.withAutoresponse;
      this.withoutAutoresponse = response.data.data.withoutAutoresponse;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";
h6 {
  font-size: 20px;
}
.frame-stat {
  border: 1px solid rgb(68, 68, 68);
  border-radius: 20px;
  padding: 20px 70px;
  text-align: center;
  .title {
    font-weight: bold;
    font-size: 2rem;
    line-height: 2rem;
  }
}
</style>
