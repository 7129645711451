<template>
  <section>
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div>
              <h1>Modification d'événement(s)</h1>
            </div>
          </div>
          <div v-if="eventsChanges.recurrent" class="modal-body">
            <div class="alert alert-warning text-center">
              <span>
                Vous êtes sur le point de modifier un événement qui possède plusieurs occurences. Souhaitez vous modifier toutes ses occurences ou
                seulement cette occurence ?
              </span>
            </div>
            <p>Liste des événements récurrents ({{ eventsChanges.events.length }}) :</p>
            <div class="card pt-3">
              <div class="row">
                <div class="col-5">
                  <b>DATE</b>
                </div>
                <div class="col">
                  <b>ACTIVITE</b>
                </div>
              </div>
              <hr class="mt-2 mb-0" />
              <div class="recurring-events-content">
                <div v-for="(event, index) in eventsChanges.events" :key="index">
                  <div :class="['row', index === 0 ? 'event-target' : '']" style="height: 35px;">
                    <div class="col-5">
                      {{ simplifyEventsDate(event.start) }}
                      {{ simplifyEventsTime(event.start, event.end) }}
                    </div>
                    <div class="col">
                      {{ event.title }}
                    </div>
                  </div>
                  <hr class="m-0 mb-1" />
                </div>
              </div>
            </div>
          </div>
          <div v-else class="modal-body">
            <p>Voulez-vous vraiment changer l'horaire de cet événement ?</p>
            <p>
              Ancienne date : {{ simplifyEventsDate(eventsChanges.events[0].start) }},
              {{ simplifyEventsTime(eventsChanges.events[0].start, eventsChanges.events[0].end) }}
            </p>
            <p>
              Nouvelle date : {{ simplifyEventsDate(eventsChanges.newStartDate) }},
              {{ simplifyEventsTime(eventsChanges.newStartDate, eventsChanges.newEndDate) }}
            </p>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between">
            <div v-if="eventsChanges.recurrent">
              <button type="button" class="btn btn-primary mr-3" @click="saveWin">Cette occurence uniquement</button>
              <button type="button" class="btn btn-primary" @click="saveWin('all')">Toutes les occurences</button>
            </div>
            <div v-else>
              <button type="button" class="btn btn-primary" @click="saveWin">Enregistrer</button>
            </div>
            <div>
              <button type="button" class="btn btn-secondary" @click="cancelWin">Annuler</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ModalEventDragAndDrop",
  props: {
    eventsChanges: {
      type: Object
    },
    value: {
      default: false,
      type: Boolean
    }
  },
  components: {},
  data() {
    return {};
  },
  async mounted() {
    console.log(this.eventsChanges);
    this.eventsChanges.events.sort((eventA, eventB) => {
      return new Date(eventA.start) - new Date(eventB.end);
    });
  },
  methods: {
    simplifyEventsDate(_startDate) {
      let startDate = this.$moment(_startDate);

      return `Le ${startDate.format("DD/MM/YYYY")}`;
    },
    simplifyEventsTime(_startDate, _endDate) {
      let startDate = this.$moment(_startDate);
      let endDate = this.$moment(_endDate);

      return `de ${startDate.format("HH:mm")} à ${endDate.format("HH:mm")}`;
    },
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    cancelWin() {
      this.$emit("input", false);
      this.$emit("reloadEvents", true);
      this.$destroy();
    },
    async saveWin(choice) {
      let data = {};

      data.events = choice === "all" ? this.eventsChanges.events : [this.eventsChanges.events[0]];
      data.newStartDate = this.eventsChanges.newStartDate;
      data.newEndDate = this.eventsChanges.newEndDate;
      data.reminder_1 = this.eventsChanges.reminder_1;
      data.reminder_2 = this.eventsChanges.reminder_2;
      data.activity = this.eventsChanges.activity;
      data.boxes = this.eventsChanges.boxes;
      console.log("data ::", data);
      try {
        let response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/calendar/events", {
          data
        });
        if (response.data.error) {
          this.$store.dispatch("showDialogError", response.data.err.message);
          return;
        }
        this.cancelWin();
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.event-target {
  background-color: #fff3cd;
  font-weight: bold;
  border: 1px solid #ffeeba;
  margin: 0;
  padding-top: 5px;
}
.event-target .col-5 {
  padding: 0;
}
</style>
