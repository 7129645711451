<template>
  <div>
    <m-form-select
      :label="setLabel()"
      v-model="selectedBoxVersion"
      :name="$Utils.randomstring('boxversion')"
      :items="boxVersionsObject"
      @input="setBoxVersion"
    ></m-form-select>
  </div>
</template>

<script>
import MFormSelect from "./MFormSelect.vue";
export default {
  components: { MFormSelect },
  name: "BoxVersionsSelect",
  props: {
    row_bo: Object
  },
  async mounted() {
    await this.getBoxVersions();
    await this.getBoxVersion();
    this.setLabel();
  },
  data() {
    return {
      boxVersions: [],
      selectedBoxVersion: "",
      savedBoxVersion: ""
    };
  },

  methods: {
    async getBoxVersions() {
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/box/versions");
      if (response.data.data) {
        this.boxVersions = response.data.data;
        if (this.row_bo && this.row_bo.bo_id) this.boxVersions.push("Version stable");
      }
    },
    async setStableBoxVersion() {
      if (!this.selectedBoxVersion || typeof this.selectedBoxVersion !== "string") return;
      if (this.selectedBoxVersion === this.savedBoxVersion) return;

      let response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/preferences", {
        pr_value: this.selectedBoxVersion,
        pr_key: "box_stable_version"
      });
      if (response.data.err) console.error(response.data.err);
    },
    async getStableBoxVersion() {
      let params = {
        limit: 1,
        pr_key: "box_stable_version"
      };
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/preferences", params);

      if (response.data.data && Array.isArray(response.data.data)) {
        this.selectedBoxVersion = response.data.data[0].pr_value;
        this.savedBoxVersion = response.data.data[0].pr_value;
      }
    },
    async setOneBoxVersion() {
      if (!this.selectedBoxVersion || typeof this.selectedBoxVersion !== "string") return;
      if (this.selectedBoxVersion === this.savedBoxVersion || this.selectedBoxVersion === "Version stable") return;

      let response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/boxes/" + this.row_bo.bo_id, {
        bo_to_update: this.selectedBoxVersion
      });
      if (response.data.err) console.error(response.data.err);
    },
    async getOneBoxVersion() {
      if (this.row_bo.bo_to_update !== "0" && this.row_bo.bo_to_update !== "1") {
        this.selectedBoxVersion = this.row_bo.bo_to_update;
        this.savedBoxVersion = this.row_bo.bo_to_update;
      } else this.selectedBoxVersion = "Version stable";
    },
    setBoxVersion() {
      if (this.row_bo && this.row_bo.bo_id) this.setOneBoxVersion();
      else this.setStableBoxVersion();
    },
    getBoxVersion() {
      if (this.row_bo && this.row_bo.bo_id) this.getOneBoxVersion();
      else this.getStableBoxVersion();
    },
    setLabel() {
      if (this.row_bo && this.row_bo.bo_id) return "Sélectionner la version";
      else return "Sélectionner la version stable des boxes pour la mise  à jour automatique";
    }
  },
  computed: {
    boxVersionsObject() {
      return this.boxVersions.map(version => {
        return { text: version, value: version };
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
