<template>
  <div
    class="modal"
    :class="value ? 'show' : 'hide'"
    :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content" :style="'width:' + width + ';height:' + height">
        <div class="modal-header">
          <h3 class="pt-2 ml-3">{{ title }}</h3>
        </div>
        <div class="modal-body">
          <p>{{ text }}</p>
          <p>{{ sousText ? sousText : "" }}</p>
          <m-form-select
            :width="500"
            label=""
            v-model="selectedValue"
            :name="$Utils.randomstring('selectValues')"
            :items="selectValues"
          ></m-form-select>
        </div>
        <div class="modal-footer">
          <button class="btn btn-reverse" @click="cancelWin">Annuler</button>

          <button class="btn btn-primary" @click="deleteWin">Ok</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mselectdialog",
  props: ["value", "title", "text", "sousText", "width", "height", "selectValues"],
  data() {
    return {
      dialog: false,
      selectedValue: null
    };
  },
  mounted() {},
  watch: {
    value: function(val) {
      this.dialog = val;
    }
  },
  components: {},
  methods: {
    cancelWin() {
      this.$emit("canceled");
    },
    deleteWin() {
      this.$emit("confirmed", this.selectedValue);
    }
  }
};
</script>
<style lang="scss"></style>
