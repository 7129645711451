<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div class="row align-items-center w-100 justify-content-start">
              <h3 class="modal-title bis flex-grow-1" v-if="enduser_id >= 0">Modification d'un utilisateur final</h3>
              <h3 class="modal-title bis flex-grow-1" v-else>Création d'un utilisateur final</h3>
              <div v-if="row_en.en_linkello"><img class="logo-linkello" src="/images/linkello.png" /></div>
            </div>
            <!--  <div class="d-flex flex-row ">
              <div class=" ml-3 d-flex flex-row align-items-center active">
                <label class="label-checkbox" for="active">{{ row_en.en_active ? "Enduser actif" : "Enduser non-actif" }}</label>
                <input class="ml-2" type="checkbox" id="active" name="active" v-model="row_en.en_active" />
              </div>
            </div> -->
          </div>
          <div class="modal-body">
            <div class="row modal-padding">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <h6><span class="number-title">1</span>Contact associé</h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <m-form-combobox
                      ref="search-contacts"
                      label="Contact"
                      label-position="top"
                      :name="$Utils.randomstring('contactSelected')"
                      v-model="contactSelected"
                      :store-url="$config.server_url + '/extranet/2.0/contacts'"
                      :store-params="{
                        co_invoice_name: searchContactsValue,

                        limit: searchContactsValue ? 10000 : 100
                      }"
                      item-value="co_id"
                      :item-text="showContactName"
                      @onsearchinput="searchContacts"
                    >
                    </m-form-combobox>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12">
                    <h6><span class="number-title">2</span>Coordonnées</h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <m-form-text
                      label="Nom complet"
                      type="text"
                      v-model="row_en.en_fullname"
                      :name="$Utils.randomstring('en_fullname')"
                    ></m-form-text>
                  </div>
                  <div class="col-md-6">
                    <m-form-date label="Date de naissance" v-model="row_en.en_birthday" :name="$Utils.randomstring('en_birthday')"></m-form-date>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <m-form-text label="Chambre" type="text" v-model="row_en.en_room" :name="$Utils.randomstring('en_room')"></m-form-text>
                  </div>
                </div>
                <div class="row">
                  <!-- <div class="col-md-6">
                    <m-form-select
                      label="Statut du enduser "
                      v-model="row_en.en_active"
                      :name="$Utils.randomstring('en_active')"
                      :rules="[$Validation.mandatory]"
                      :items="$store.state.items_en_state"
                    ></m-form-select>
                  </div> -->
                  <div class="col-md-6">
                    <m-form-select
                      label="Statut de la box "
                      v-model="row_en.en_state_box"
                      :name="$Utils.randomstring('en_state_box')"
                      :rules="[$Validation.mandatory]"
                      :items="$store.state.items_en_state_box"
                    ></m-form-select>
                  </div>
                  <div class="col-md-6">
                    <m-form-select
                      label="Statut de l'abonnement "
                      v-model="row_en.en_state_subscription"
                      :name="$Utils.randomstring('en_state_subscription')"
                      :rules="[$Validation.mandatory]"
                      :items="$store.state.items_en_state_subscription"
                    ></m-form-select>
                  </div>
                </div>
                <!-- <div class="row" v-if="row_en.en_state_subscription === 'NOT_TO_START'">
                  <div class="col-md-6"></div>
                  <div class="col-md-6">
                    <m-form-date
                      label="Date de démarrage de l'abonnement"
                      v-model="row_en.en_birthday"
                      :name="$Utils.randomstring('en_birthday')"
                    ></m-form-date>
                  </div>
                </div> -->
                <div class="row">
                  <div class="col-md-6">
                    <m-form-select
                      label="Statut du colis "
                      v-model="row_en.en_state_parcel"
                      :name="$Utils.randomstring('en_state_parcel')"
                      :rules="[$Validation.mandatory]"
                      :items="$store.state.items_en_state_parcel"
                    ></m-form-select>
                  </div>
                  <div class="col-md-6">
                    <m-form-select
                      label="Statut du support "
                      v-model="row_en.en_state_support"
                      :name="$Utils.randomstring('en_state_support')"
                      :rules="[$Validation.mandatory]"
                      :items="$store.state.items_en_state_support"
                    ></m-form-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <m-form-select
                      label="Statut frais de dossier/caution "
                      v-model="row_en.en_state_caution"
                      :name="$Utils.randomstring('en_state_caution')"
                      :rules="[$Validation.mandatory]"
                      :items="$store.state.items_en_state_caution"
                    ></m-form-select>
                  </div>
                  <div class="col-md-6">
                    <m-form-checkbox
                      label="Résilié"
                      :name="$Utils.randomstring('resilie')"
                      v-model="row_en.en_resiliate_subscription"
                      class="mt-2"
                    ></m-form-checkbox>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12">
                    <h6><span class="number-title">3</span>Informations de connexion</h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <m-form-text
                      label="Identifiant webapp"
                      type="text"
                      placeholder="Laisser vide pour le générer automatiquement"
                      v-model="row_en.en_webapp_login"
                      :name="$Utils.randomstring('en_webapp_login')"
                    ></m-form-text>
                  </div>
                  <div class="col-md-6">
                    <m-form-text
                      label="Mot de passe webapp"
                      type="text"
                      placeholder="Laisser vide pour le générer automatiquement"
                      v-model="row_en.en_webapp_password"
                      :name="$Utils.randomstring('en_webapp_password')"
                    ></m-form-text>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <m-form-text
                      label="Code administrateur"
                      type="text"
                      placeholder="Laisser vide pour le générer automatiquement"
                      v-model="row_en.en_admincode"
                      :name="$Utils.randomstring('en_admincode')"
                    ></m-form-text>
                  </div>
                </div>
                <!-- pour le moment il n'y a pas de champs commentaire en base de données -->
                <!-- <div class="row mt-4">
                  <div class="col-md-12">
                    <h6><span class="number-title">4</span>Autres informations</h6>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-md-12">
                    <m-form-text
                      label="Commentaire"
                      type="textarea"
                      :rows="3"
                      v-model="row_en.en_comment"
                      :name="$Utils.randomstring('en_comment')"
                      disabled
                    ></m-form-text>
                  </div>
                </div> -->
                <div class="row mt-4">
                  <div class="col-md-12">
                    <h6><span class="number-title">4</span>Informations techniques</h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <m-form-text
                      label="Adresse Mac Wifi"
                      type="text"
                      v-model="row_en.en_box_mac_address_wifi"
                      :name="$Utils.randomstring('en_box_mac_address_wifi')"
                      :rules="[$Validation.mandatory]"
                      @keyup.delete="addColons('del_mac_address_wifi')"
                      @input="addColons('mac_address_wifi')"
                    ></m-form-text>
                    <span v-if="mac_wifi" style="color: red"
                      ><icon name="exclamation-triangle" scale="1" class="mr-2"></icon>Nombre de caractères atteint!</span
                    >
                  </div>
                  <div class="col-md-6">
                    <m-form-text
                      label="Adresse Mac Ethernet"
                      type="text"
                      @keyup.delete="addColons('del_mac_address_ethernet')"
                      @input="addColons('mac_address_ethernet')"
                      v-model="row_en.en_box_mac_address_ethernet"
                      :name="$Utils.randomstring('en_box_mac_address_ethernet')"
                    ></m-form-text>
                    <span v-if="mac_ethernet" style="color: red"
                      ><icon name="exclamation-triangle" scale="1" class="mr-2"></icon>Nombre de caractères atteint!</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between">
            <button type="button" class="btn btn-danger" @click="deleteConfirmWin">Supprimer</button>
            <button v-if="$store.state.user.us_type === 'LATEAM' && bo_id" type="button" class="btn btn-danger" @click="deleteEnduserBox">
              Supprimer la box
            </button>

            <div class="d-flex flex-row align-items-center">
              <div v-if="enduser_id >= 0" class="form-group row mr-2">
                <m-form-checkbox ref="new_end_user" id="new_end_user" v-model="createNewEndUser"></m-form-checkbox>
                <label for="new_end_user">Nouvel utilisateur final</label>
              </div>

              <button type="button" class="btn btn-secondary" @click="cancelWin">Annuler</button>
              <button
                v-if="
                  ($store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER') && row_en.en_id >= 0 && !row_en.en_linkello
                "
                type="button"
                class="btn btn-warning ml-2"
                @click="saveToLinkello"
              >
                Associer à Linkello
              </button>
              <button
                v-if="
                  ($store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER') && row_en.en_id >= 0 && row_en.en_linkello
                "
                type="button"
                class="btn btn-warning ml-2"
                @click="saveToLinkello"
              >
                Mettre à jour sur Linkello
              </button>
              <button
                v-if="
                  ($store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER') && row_en.en_id >= 0 && row_en.en_linkello
                "
                type="button"
                class="btn btn-warning ml-2"
                @click="deleteFromLinkello"
              >
                Supprimer de Linkello
              </button>
              <button type="button" class="btn btn-primary btn-mouveal ml-2" @click="saveWin">Sauvegarder</button>
            </div>
          </div>
        </div>
      </div>
      <m-confirm-dialog
        v-model="confirmdelete"
        text="Souhaitez-vous effacer cet utilisateur final  ?"
        title="Confirmation"
        @canceled="confirmdelete = false"
        @confirmed="deleteWin()"
      ></m-confirm-dialog>
      <m-message-dialog v-model="dialogErr" title="Erreur" :text="dialogErrTxt"></m-message-dialog>
    </div>
  </transition>
</template>

<script>
import MFormCheckbox from "../components/MFormCheckbox.vue";
export default {
  name: "EnduserWinEdit",
  components: { MFormCheckbox },
  props: {
    en_id: Number,
    bo_id: Number,
    value: {
      default: false,
      type: Boolean
    },
    maFiche: Boolean,
    contactType: String
  },
  data() {
    return {
      enduser_id: -1,
      dialogErr: false,
      dialogErrTxt: "",
      confirmdelete: false,
      row_en: {},
      contactSelected: {},
      mac_wifi: false,
      mac_ethernet: false,
      createNewEndUser: false,
      confirmNewEndUser: false,
      searchContactsValue: ""
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.enduser_id = this.en_id ? this.en_id : -1;
        this.loadEnduser();
      }
    },
    contactSelected(val) {
      if (val && !this.row_en.en_fullname) this.row_en.en_fullname = this.$options.filters.formatContactNameSimple(val, "co_invoice_");
    },
    createNewEndUser() {
      this.newEndUser();
    }
  },
  computed: {},
  created() {},
  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    async searchContacts(val) {
      this.searchContactsValue = `contains:${val}`;
      await this.$nextTick();
      this.$refs["search-contacts"].search_go(val);
    },
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    //sert à afficher les nom et prénom des contact dans le combobox
    showContactName(val) {
      let txt = "";
      if (val.co_invoice_name) txt += val.co_invoice_name.toUpperCase();
      if (val.co_invoice_firstname) txt += " " + val.co_invoice_firstname.charAt(0).toUpperCase() + val.co_invoice_firstname.substr(1);
      return txt;
    },
    async loadEnduser() {
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/endusers/" + this.enduser_id);
      this.row_en = response.data.data;
      this.contactSelected = this.row_en.co_id;
    },
    async saveWin() {
      let err = [];
      if (!this.contactSelected || !this.contactSelected.co_id) {
        err.push({ field: "co_id", text: "Contact" });
      }
      if (this.row_en.en_box_mac_address_wifi.length > 1 && this.row_en.en_box_mac_address_wifi.length < 17) {
        err.push({ field: "en_box_mac_address_wifi", text: "Mauvais format de l'adresse MAC wifi" });
      }
      if (this.row_en.en_box_mac_address_ethernet.length > 1 && this.row_en.en_box_mac_address_ethernet.length < 17) {
        err.push({ field: "en_box_mac_address_ethernet", text: "Mauvais format de l'adresse MAC Ethernet" });
      }
      let fieldRequired = [{ field: "en_fullname", text: "nom complet" }];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.row_en[field.field]) err.push(field);
      }
      if (err.length) {
        this.dialogErrTxt = "<span class='bis'>Vous devez remplir les champs : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error.text + " <br>";
        }
        this.dialogErr = true;
      } else {
        let response;
        if (this.row_en.en_id) {
          if (this.createNewEndUser && this.confirmNewEndUser) this.row_en.reset = true;
          else {
            if (this.row_en.hasOwnProperty("reset")) delete this.row_en.reset;
          }

          // if new contact association
          if (this.row_en.co_id && this.row_en.co_id.co_id && this.contactSelected.co_id !== this.row_en.co_id.co_id) {
            this.row_en.co_id = this.contactSelected.co_id;
          }

          response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/endusers/" + this.row_en.en_id, this.row_en);
          if (!response.data.err && this.createNewEndUser && this.confirmNewEndUser) {
            // unselect reset checkbox
            delete this.row_en.reset;
            this.confirmNewEndUser = false;
            this.createNewEndUser = false;
          }
        } else {
          this.row_en.co_id = this.contactSelected.co_id;
          response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/endusers", this.row_en);
        }
        if (response.data.err) {
          this.$store.dispatch("showDialogError", response.data.err.message);
          return;
        }
        let row_en = response.data.data;

        this.$emit("input", false);
        this.$emit("winEditSaved", row_en.en_id);
      }
    },
    cancelWin() {
      this.$emit("input", false);
      this.$emit("winEditCanceled");
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteWin() {
      let en_id = this.row_en.en_id;
      await this.$axios.delete(this.$config.server_url + "/extranet/2.0/endusers/" + en_id);
      this.confirmdelete = false;
      this.$emit("input", false);
      this.$emit("winEditDeleted", en_id);
    },
    async newEndUser() {
      if (this.createNewEndUser) {
        this.confirmNewEndUser = window.confirm(
          "Attention un nouvel identifiant et mot de passe vont être générés et TOUS les messages seront effacés. Souhaitez-vous continuer ?"
        );
        // await next tick to uncheck the checkbox if canceled
        await this.$nextTick();
        this.createNewEndUser = this.confirmNewEndUser;
      }
    },
    async saveToLinkello() {
      let firstname = "";
      let lastname = "";
      try {
        firstname = this.row_en.en_fullname.split(" ")[1];
        lastname = this.row_en.en_fullname.split(" ")[0];
      } catch (error) {}
      let body = {
        firstname: firstname ? firstname : "",
        lastname: lastname ? lastname : this.row_en.en_fullname,
        email: this.row_en.co_id && this.row_en.co_id.co_invoice_email ? this.row_en.co_id.co_invoice_email : "",
        lang: this.row_en.co_i && this.row_en.co_id.co_invoice_country ? this.row_en.co_id.co_invoice_country.substring(0, 2).toLowerCase() : "fr",
        extIdEmotivi: this.row_en.en_id
      };
      let response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/linkello/user/" + this.row_en.en_id, body);
      if (response.data.err) {
        console.error(response.data.err);
        this.$store.dispatch("showDialogError", "L'utilisateur final n'a pas pu être enregistré sur la plateforme Linkello.");
        return;
      }
      this.$emit("input", false);
      this.$emit("winEditSaved", this.row_en.en_id);
    },
    async deleteFromLinkello() {
      let response = await this.$axios.delete(this.$config.server_url + "/extranet/2.0/linkello/user/" + this.row_en.en_id);
      if (response.data.err) {
        console.error(response.data.err);
        this.$store.dispatch("showDialogError", "L'utilisateur final n'a pas pu être supprimé de la plateforme Linkello.");
        return;
      }
      this.$emit("input", false);
      this.$emit("winEditSaved", this.row_en.en_id);
    },
    addColons(mac) {
      if (mac === "del_mac_address_wifi") {
        this.mac_wifi = false;
        let len = this.row_en.en_box_mac_address_wifi.length - 1;
        this.row_en.en_box_mac_address_wifi = this.row_en.en_box_mac_address_wifi.slice(0, len);
      } else if (mac === "del_mac_address_ethernet") {
        this.mac_ethernet = false;
        let len = this.row_en.en_box_mac_address_ethernet.length - 1;
        this.row_en.en_box_mac_address_ethernet = this.row_en.en_box_mac_address_ethernet.slice(0, len);
      } else if (mac === "mac_address_wifi") {
        if (this.row_en.en_box_mac_address_wifi.length >= 17) {
          this.mac_wifi = true;
          this.row_en.en_box_mac_address_wifi = this.row_en.en_box_mac_address_wifi.slice(0, 17);
        } else if (this.row_en.en_box_mac_address_wifi.slice(-2).indexOf(":") === -1 && this.row_en.en_box_mac_address_wifi.length > 1) {
          this.row_en.en_box_mac_address_wifi += ":";
        }
      } else if (mac === "mac_address_ethernet") {
        if (this.row_en.en_box_mac_address_ethernet.length >= 17) {
          this.mac_ethernet = true;
          this.row_en.en_box_mac_address_ethernet = this.row_en.en_box_mac_address_ethernet.slice(0, 17);
        } else if (this.row_en.en_box_mac_address_ethernet.slice(-2).indexOf(":") === -1 && this.row_en.en_box_mac_address_ethernet.length > 1) {
          this.row_en.en_box_mac_address_ethernet += ":";
        }
      }
    },
    async deleteEnduserBox() {
      if (!this.bo_id) return;
      let ok = confirm("Étes vous sûr de vouloir supprimer la box associée à l'utilisateur final " + this.row_en.en_fullname + " ?");
      if (ok) {
        let response = await this.$axios.delete(this.$config.server_url + "/extranet/2.0/boxes/" + this.bo_id + "/" + this.row_en.en_id);
        if (response && response.data && response.data.err) alert("La box n'a pas pu être supprimée.");
        else if (response && response.data && response.data.data && response.data.data.bo_id) {
          this.$emit("input", false);
          this.$emit("winEditSaved", this.row_en.en_id, "box_deleted");
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";

/* .winedit-container-header {
  width: 100%;
  justify-content: space-between;
}

.modal {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.active {
  min-width: 150px;
  input {
    width: auto;
  }
} */
.logo-linkello {
  width: 150px;
}
</style>
