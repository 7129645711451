<template>
  <div id="app" class="d-flex flex-column">
    <div class="top-bar flex-grow-0">
      <div class="d-flex topbar">
        <div class="d-flex align-items-center">
          <div class="collapse-menu img-hamberger mr-2 pointer" @click="showMenus($event)" v-if="$store.state.connected">
            <icon width="30" height="30" name="bars" class="mr-2"></icon>
          </div>
          <div>
            <img @click="go('/')" class="img-fluid pointer img-logo" src="images/logo-emotivi.png" alt="logo Mouveal" />
          </div>
        </div>
        <div class="d-flex flex-grow-1 justify-content-end" v-if="$store.state.connected">
          <div v-if="$store.state.user.us_type !== 'PARTNER'" class="d-flex container-search align-items-center">
            <!-- <div class="d-flex flex-row align-items-center"> -->
            <m-form-date
              class="mx-1 col-form-label-sm withouttopmargin"
              label="Début"
              :name="$Utils.randomstring('filterDateStart')"
              formatInput="DD/MM/YYYY"
              v-model="filterDateStart"
              :inline="true"
            ></m-form-date>
            <m-form-date
              class="mx-1 col-form-label-sm withouttopmargin"
              label="Fin"
              :name="$Utils.randomstring('filterDateEnd')"
              formatInput="DD/MM/YYYY"
              v-model="filterDateEnd"
              :inline="true"
            ></m-form-date>
            <!-- </div> -->
            <m-form-text
              class="mr-2 input-research"
              :name="$Utils.randomstring('search')"
              placeholder="Rechercher sur tout le site"
              v-model="search"
              :withoutMargin="true"
              appendIcon="search"
            ></m-form-text>
            <!-- <div class="pointer mx-2" @click="screenSize">
            <icon width="22" height="22" name="desktop" class="mr-2" color="#199aaa"></icon>
          </div> -->
          </div>
          <div class="d-flex container-name align-items-center">
            <div>
              <div>{{ user | formatContactNameSimple("us_") }}</div>
              <div class="small-text">{{ user.us_type | formatvalue("items_us_type", true, false) }}</div>
            </div>
          </div>
          <div v-if="$store.state.user.us_type !== 'PARTNER'" class="documentation ml-2 d-flex align-items-center">
            <a href="documents\Documentation_etablissement_interface_de_gestion.pdf" target="_blank">
              <icon width="22" height="22" name="book" class="mr-2" color="#199aaa"></icon
            ></a>
          </div>
          <div class="deconnexion pointer ml-2 d-flex align-items-center" @click="logout">
            <icon width="22" height="22" name="sign-out-alt" class="mr-2" color="#199aaa"></icon>
          </div>
        </div>
      </div>
      <div class="menu" v-if="this.$store.state.showMenu">
        <ul class="nav d-flex flex-column justify-content-center">
          <li v-if="$store.getters['checkUserAccess']($router, '/home')" class="nav-item">
            <div @click="go('/')" class="nav-link pointer" to="/">Accueil</div>
          </li>
          <li v-if="$store.getters['checkUserAccess']($router, '/boxes')" class="nav-item">
            <div @click="go('/boxes')" class="nav-link pointer" :to="{ path: '/boxes' }">Boxes</div>
          </li>
          <!-- <li v-if="$store.getters['checkUserAccess']($router, '/boxes')" class="nav-item">
            <div @click="go({ path: '/boxes', query: { onlyBoxes: true } })" class="nav-link pointer" :to="{ path: '/boxes' }">Boxes</div>
          </li>
          <li v-if="$store.getters['checkUserAccess']($router, '/tvs')" class="nav-item">
            <div @click="go('/tvs')" class="nav-link pointer" :to="{ path: '/tvs' }">Téléviseurs</div>
          </li> -->
          <li v-if="$store.getters['checkUserAccess']($router, '/contacts')" class="nav-item">
            <div @click="go('/contacts')" class="nav-link pointer" :to="{ path: '/contacts' }">Contacts</div>
          </li>
          <li class="nav-item" v-if="$store.getters['checkUserAccess']($router, '/calendar')">
            <div @click="go('/calendar')" class="nav-link pointer" :to="{ path: '/calendar' }">Calendrier</div>
          </li>
          <li class="nav-item" v-if="$store.getters['checkUserAccess']($router, '/invoices')">
            <div @click="go('/invoices')" class="nav-link pointer" :to="{ path: '/invoices' }">Comptabilité</div>
          </li>
          <li v-if="$store.getters['checkUserAccess']($router, '/materials')" class="border-plus"></li>

          <li class="nav-item" v-if="$store.getters['checkUserAccess']($router, '/materials')">
            <div @click="go('/materials')" class="nav-link pointer">Matériels</div>
          </li>
          <li class="nav-item" v-if="$store.getters['checkUserAccess']($router, '/products')">
            <div @click="go('/products')" class="nav-link pointer" :to="{ path: '/products' }">Produits et services</div>
          </li>
          <li class="nav-item" v-if="$store.getters['checkUserAccess']($router, '/offers')">
            <div @click="go('/offers')" class="nav-link pointer" :to="{ path: '/offers' }">Offres commerciales</div>
          </li>
          <li v-if="$store.getters['checkUserAccess']($router, '/users')" class="border-plus"></li>
          <li class="nav-item" v-if="$store.getters['checkUserAccess']($router, '/users')">
            <div @click="go('/users')" class="nav-link pointer" :to="{ path: '/users' }">Equipe</div>
          </li>

          <li class="nav-item" v-if="$store.getters['checkUserAccess']($router, '/establishments')">
            <div @click="go('/establishments')" class="nav-link pointer" :to="{ path: '/establishments' }">Etablissements</div>
          </li>
          <li v-if="$store.getters['checkUserAccess']($router, '/statistiques')" class="border-plus"></li>
          <li v-if="$store.getters['checkUserAccess']($router, '/statistiques')" class="nav-item">
            <div @click="go('/statistiques')" class="nav-link pointer">Statistiques</div>
          </li>
          <li v-if="$store.getters['checkUserAccess']($router, '/actions')" class="nav-item">
            <div @click="go('/actions')" class="nav-link pointer" :to="{ path: '/actions' }">Actions</div>
          </li>
          <li v-if="$store.getters['checkUserAccess']($router, '/infrarouges')" class="nav-item">
            <div @click="go('/infrarouges')" class="nav-link pointer" :to="{ path: '/infrarouges' }">Infrarouges</div>
          </li>
          <li v-if="$store.getters['checkUserAccess']($router, '/fonctionnalites')" class="nav-item">
            <div @click="go('/fonctionnalites')" class="nav-link pointer" :to="{ path: '/fonctionnalites' }">Fonctionnalités Bouton</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="container-emotivi flex-grow-1 d-flex">
      <router-view v-if="!this.$store.state.showResearchView" />
      <research-view
        v-if="this.$store.state.showResearchView"
        :researchVal="this.$store.state.research"
        :filterDateStart="this.$store.state.filterDateStart"
        :filterDateEnd="this.$store.state.filterDateEnd"
        @deleteResearch="deleteResearch"
      ></research-view>
    </div>
    <footer class="flex-grow-0">
      <div class="d-flex flex-row align-items-center" style="padding: 5px 50px">
        <!-- <img style="width: 7%" class="img-fluid mx-3" src="images/logo-emotivi.png" alt="logo Mouveal" /> -->
        <div>
          Design et développement : <span class="bis ml-2">La.team - {{ theCurrentYear }} - </span>
          <span class="font-weight-bold text-secondary" id="appVersion"></span>
        </div>
      </div>
    </footer>
    <m-message-dialog
      v-model="$store.state.dialogErr"
      title="Erreur"
      :text="$store.state.dialogErrTxt"
      @close="$store.dispatch('showDialogError', false)"
    ></m-message-dialog>
  </div>
</template>

<script>
import { version } from "../package.json";
export default {
  name: "App",
  components: {},
  async created() {
    // let ok = await this.$Utils.wait(() => {
    //   return window.bc;
    // }, 10000);
    window.onBistriConferenceReady = async () => {
      console.info("Bistri SDK loaded");
      // alert("ici");
      this.$store.commit("set_bistri_sdk_state", 1);
    };
    // if (ok) console.info("Bistri SDK loaded");
    // else console.error("Bistri SDK not loaded");
    // this.$store.commit("set_bistri_sdk_state", ok);
  },
  data() {
    return {
      confirmLogoutDialog: false,
      drawer: true,
      //user: null,
      search: this.$store.state.research,
      showGestionStock: false,
      filterDateStart: this.$store.state.filterDateStart,
      filterDateEnd: this.$store.state.filterDateEnd,
      theCurrentYear: this.$moment().format("YYYY")
    };
  },
  watch: {
    $route(to, from) {},
    search(val) {
      if (val) {
        this.$store.dispatch("showResearchView", true);
        let data1 = {
          researchVal: val,
          filterDateStart: this.filterDateStart,
          filterDateEnd: this.filterDateEnd
        };
        this.$nextTick().then(() => {
          // wait for research view displayed
          // if copy paste on search input, the result of this.$store.state.research is changed before the showResearchView and result not displayed
          this.$store.dispatch("showResearch", data1);
        });
      } else {
        this.$store.dispatch("showResearchView", false);
        let data1 = {
          researchVal: "",
          filterDateStart: "2020-01-01",
          filterDateEnd: ""
        };
        this.$store.dispatch("showResearch", data1);
      }
    },
    filterDateStart(val) {
      if (val && this.search) {
        this.$store.dispatch("showResearchView", true);
        let data1 = {
          researchVal: this.search,
          filterDateStart: val,
          filterDateEnd: this.filterDateEnd
        };
        this.$store.dispatch("showResearch", data1);
      }
    },
    filterDateEnd(val) {
      if (val && this.search) {
        this.$store.dispatch("showResearchView", true);
        let data1 = {
          researchVal: this.search,
          filterDateStart: this.filterDateStart,
          filterDateEnd: val
        };
        this.$store.dispatch("showResearch", data1);
      }
    }
  },
  sockets: {},
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  async mounted() {
    window.document.title = "Emotivi Extranet";
    document.getElementById("appVersion").innerText = "v" + version;
    window.addEventListener("click", this.onclickWindow);
  },
  destroyed() {
    window.removeEventListener("click", this.onclickWindow);
  },
  methods: {
    onclickWindow() {
      this.$store.dispatch("showMenu", false);
    },
    gestionStock($event) {
      $event.stopPropagation();
      this.showGestionStock = !this.showGestionStock;
    },
    go(where) {
      this.$router.push(where);
      this.deleteResearch();
    },
    deleteResearch() {
      this.$store.dispatch("showResearchView", false);
      let data1 = {
        researchVal: "",
        filterDateStart: "2020-01-01",
        filterDateEnd: ""
      };
      this.$store.dispatch("showResearch", data1);
      this.search = "";
      this.filterDateStart = "2020-01-01";
      this.filterDateEnd = "";
    },
    showMenus($event) {
      $event.stopPropagation();
      this.$store.dispatch("showMenu", !this.$store.state.showMenu);
    },
    screenSize() {},
    logout() {
      this.confirmLogoutDialog = false;
      this.$store.commit("set_connexion", {
        accesstoken: null,
        refreshtoken: null,
        user: null,
        preferences: null
      });
      this.$router.push("/login");
    },
    login() {
      this.$router.push("/login");
    },
    saveDocumentation() {}
  }
};
</script>
<style lang="scss">
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./scss/styles.scss";

#app {
  background-color: #f7f7f8;
  height: 100vh;
}
.top-bar {
  background-color: #fff;
  padding: 5px 20px;
  position: relative;
}
.d-flex.topbar {
  height: 60px;
}
.container-search {
  border-right: solid 1px #e6e6e6;
  margin-right: 10px;
}
.container-name {
  min-width: 160px;
}
/************ nav **************/
.menu {
  padding-top: 20px;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #1f1f1f;
  z-index: 100;
  .nav-item {
    padding: 0 10px;
    border-bottom: solid 1px #7f8ea2;
  }
  .border-plus {
    height: 5px;
    border-bottom: solid 3px #7f8ea2;
  }
  .nav-link {
    color: #7f8ea2;
  }
}
///

.container-emotivi {
  padding: 30px 15px;
  overflow: auto;
  // min-height: calc(100vh - 112px);
}
.header-emotivi {
  // height: 120px;
  // min-height: 120px;
  margin-bottom: 5px;
  h1 {
    margin: 0;
  }
}
.img-logo {
  max-width: 400px;
}
.input-research {
  width: 100%;
  margin: 0 10px 0 0 !important;
}
@media (max-width: 768px) {
  .img-logo {
    width: 100px;
  }
  .container-emotivi {
    padding: 30px 50px 0 50px;
  }
}
@media (min-width: 768px) {
  .img-logo {
    width: 150px;
  }
  .container-emotivi {
    padding: 30px 50px 0 50px;
  }
}

@media (min-width: 992px) {
  .input-research {
    width: 320px;
    margin: 0 10px 0 0 !important;
  }
  .img-logo {
    width: 150px;
  }
}
@media (max-width: 1280px) {
  .header-emotivi {
    // height: 100%;
    // min-height: unset;
    margin-bottom: 5px;
  }
}

/************ footer **************/
</style>
