import axios from "axios";
import config from "../config";
import store from "./store";
import _ from "lodash";

const Utils = {
  randomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  },

  randomstring(keep = "", length = 12) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    if (keep) result = keep + "__" + result;
    // console.log('result',result);
    return result;
  },

  async wait(funcToTest, maxTime) {
    return new Promise(function(resolve, reject) {
      let timeoutTest, timeoutMaxTime;
      function test() {
        timeoutTest = window.setTimeout(() => {
          if (funcToTest()) {
            if (timeoutMaxTime) window.clearTimeout(timeoutMaxTime);
            return resolve(true);
          }
          test();
        }, 10);
      }
      timeoutMaxTime = window.setTimeout(() => {
        if (timeoutTest) window.clearTimeout(timeoutTest);
        console.warn("timeoutmax");
        resolve(false);
      }, maxTime);
      test();
    });
  },
  async doRequestIo(method, url, params) {
    try {
      let response = await axios[method](config.serverurl + url, params ? params : null, {
        headers: {
          "x-auth-accesstoken": store.state.accesstoken,
          "Access-Control-Allow-Origin": "*"
        }
      });
      return response.data;
    } catch (e) {
      console.error("Error Ajax REquest - :", e);
      return null;
    }
  },
  getBase64Image(url) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.src = url;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        let dataURL = canvas.toDataURL("image/png");
        resolve(dataURL.replace(/^data:image\/(png|jpg);base64,/, ""));
      };
      img.addEventListener("error", event => {
        // console.log("not found:" + img.src);
        reject();
      });
    });
  },
  getTextFromState(dataStore, value) {
    return _.result(_.find(dataStore, { value: value }), "text", value);
  },
  isServiceProvider() {
    let isServiceProvider = false;
    if (store.state.user.us_type == "PARTNER" || store.state.user.us_type == "LATEAM") {
      // check if one of us_type of store.state.user.establishments is equal to 2
      store.state.user.establishments.forEach(establishment => {
        if (establishment.es_type == 2 || establishment.es_type == 0) {
          isServiceProvider = true;
        }
      });
    }
    return isServiceProvider;
  }

  // sortTable() {
  //   if (fields.length && this.sortFields.length && fields[0] == this.sortFields[0]) {
  //     this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
  //   }
  //   this.sortFields = fields;
  //   if (fields[0] !== "co_connection_date") {
  //     this.loadContacts();
  //   } else {
  //     this.patients.sort((a, b) => {
  //       if (this.sortOrder === "asc") {
  //         if (this.$moment(a.co_connection_date).isBefore(b.co_connection_date)) return -1;
  //         if (this.$moment(a.co_connection_date).isAfter(b.co_connection_date)) return 1;
  //         if (this.$moment(a.co_connection_date).isSame(b.co_connection_date)) return 0;
  //       } else {
  //         if (this.$moment(a.co_connection_date).isBefore(b.co_connection_date)) return 1;
  //         if (this.$moment(a.co_connection_date).isAfter(b.co_connection_date)) return -1;
  //         if (this.$moment(a.co_connection_date).isSame(b.co_connection_date)) return 0;
  //       }
  //     });
  //   }
  // }
};
export default Utils;
