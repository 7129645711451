<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none; '"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document" style="width: 600px; margin-top: 100px">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <h1 v-if="this.idselectedConfig == 0">Nouvelle fonctionnalité</h1>
            <h1 v-else>Modification de la fonctionnalité</h1>
          </div>
          <div class="modal-body">
            <m-form-text
              label="Nom de la fonctionnalité *"
              type="text"
              v-model="row_bf.bf_name"
              :name="$Utils.randomstring('bf_name')"
              :rules="[$Validation.mandatory]"
            ></m-form-text
            ><m-form-text
              v-if="$store.state.user.us_type === 'LATEAM'"
              label="Clé de la fonctionnalité*"
              type="text"
              v-model="row_bf.bf_key"
              :name="$Utils.randomstring('bf_key')"
            ></m-form-text>
            <m-form-select
              label="Établissements"
              v-model="row_bf.es_id"
              :name="$Utils.randomstring('es_id')"
              :rules="[$Validation.mandatory]"
              :items="user.establishments"
            ></m-form-select>
            <m-form-select
              class="flex-grow-1 basis-50"
              label="Protocole de communication *"
              label-position="top"
              :name="$Utils.randomstring('pc_id')"
              v-model="row_pc"
              :items="$store.state.items_bf_communication"
              @input="row_pc == 'sms' ? (row_bf.bf_message = 'C\'est %ENDUSER_NAME appelle moi sur Emotivi.') : (row_bf.bf_message = '')"
            ></m-form-select>
            <div v-if="row_pc !== 'none'">
              <m-form-checkbox
                label="Autologin sur la webapp"
                v-model="row_bf.bf_autologin"
                :name="$Utils.randomstring('ringingDisable')"
                class="mr-3"
                :setstyle="'white-space: normal !important'"
                @input="maxlength()"
              ></m-form-checkbox>
              <m-form-text
                label="Message *"
                type="textarea"
                v-model="row_bf.bf_message"
                :name="$Utils.randomstring('bf_message')"
                :rules="[$Validation.mandatory]"
                :maxlength="maxlength()"
              ></m-form-text>
              <div v-if="row_pc == 'sms'">
                <span
                  >Nombre de caractères {{ row_pc }}: {{ row_bf.bf_message.length }}
                  <span v-if="row_bf.bf_autologin">(1 SMS = 100 CARACTÈRES avec l'autologin)</span>
                  <span v-else>(1 SMS = 160 CARACTÈRES)</span>
                </span>
                <br />
                <span v-if="row_bf.bf_message.length > 160" style="color: red"
                  >Nombre de caractères atteintes <u>Nombre de Messages</u> : {{ nbremess() }}</span
                >
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between">
            <div>
              <button type="button" class="btn btn-danger" @click="deleteConfirmWin">Supprimer</button>
            </div>
            <div class="d-flex flex-row align-items-end">
              <button type="button" class="btn btn-secondary" @click="cancelWin">Annuler</button>
              <button type="button" class="btn btn-primary btn-mouveal ml-2" @click="saveWin">Sauvegarder</button>
            </div>
          </div>
        </div>
      </div>
      <m-message-dialog v-model="dialogErr" title="Erreur" :text="dialogErrTxt"></m-message-dialog>
      <m-confirm-dialog
        v-model="confirmdelete"
        :text="`Souhaitez-vous effacer cette fonctionnalité ?`"
        sousText="Vous ne pourrez plus revenir en arrière!"
        title="Confirmation"
        width="500px"
        @canceled="confirmdelete = false"
        @confirmed="deleteWin()"
      ></m-confirm-dialog></div
  ></transition>
</template>

<script>
export default {
  name: "ButtonFunctionalitiesWinEdit",
  components: {},
  props: {
    value: {
      default: false,
      type: Boolean
    },
    idselectedConfig: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      row_bf: {
        bf_name: "",
        bf_key: "",
        bf_message: "",
        pc_id: {},
        es_id: 0,
        bf_autologin: false
      },
      row_pc: "none",
      confirmdelete: false,
      dialogErr: false,
      dialogErrTxt: "",
      user: {}
    };
  },
  watch: {
    value(v) {
      if (v) {
        if (this.idselectedConfig != 0) {
          this.loadFonctionnality();
        } else {
          this.row_bf = {
            bf_name: "",
            bf_key: "",
            bf_message: "",
            pc_id: {},
            bf_autologin: false
          };
        }
      }
    }
  },
  computed: {},
  mounted() {},
  created() {
    let user = this.$store.state.user;
    if (user.establishments.length) {
      for (let ies = 0; ies < user.establishments.length; ies++) {
        const es = user.establishments[ies];
        es.text = es.es_name;
        es.value = es.es_id;
      }
    }
    this.user = user;
  },
  methods: {
    async saveWin() {
      let err = [];
      let fieldRequired = [
        { field: "bf_name", text: "Nom de la fonctionnalité" },
        { field: "es_id", text: "Etablissement" }
      ];
      if (this.row_pc !== "none") {
        fieldRequired.push({ field: "bf_message", text: "Message de la fonctionnalité" });
      }
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.row_bf[field.field]) err.push(field);
      }
      if (this.row_bf.bf_message.length > 160) {
        err.push({ text: "Le message ne doit pas dépasser 160 caractères" });
      }
      if (err.length) {
        this.dialogErrTxt = "<span class='bis'>Vous devez remplir les champs : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error.text + " <br>";
        }
        this.dialogErr = true;
      } else {
        let response;
        this.row_bf.pc_id = this.row_pc;
        if (this.row_bf.bf_id) {
          response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/buttonFunctionalities/" + this.row_bf.bf_id, this.row_bf);
        } else {
          response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/buttonFunctionalities", this.row_bf);
        }
        if (response.data.err) {
          if (response.data.err.key === "button_functionnality_key_exist")
            alert("La clé pour cette fonctionnalité existe déjà, veuillez en saisir une autre.");
        }
        if (response.data.message) {
          this.$emit("winEditClosed");
        }
      }
    },
    cancelWin() {
      this.$emit("winEditClosed");
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteWin() {
      await this.$axios.delete(this.$config.server_url + "/extranet/2.0/buttonFunctionalities/" + this.row_bf.bf_id);
      this.$emit("winEditClosed");
      this.confirmdelete = false;
    },
    async loadFonctionnality() {
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/buttonFunctionalities/" + this.idselectedConfig);
      if (response.data.data) {
        this.row_bf = response.data.data;
        this.row_pc = this.row_bf.pc_id;
      }
    },
    maxlength() {
      if (this.row_pc == "sms") {
        if (this.row_bf.bf_autologin) {
          return "100";
        } else {
          return "160";
        }
      }
    },
    nbremess() {
      let nbremess = 0;
      if (this.row_bf.bf_message) {
        nbremess = Math.floor(this.row_bf.bf_message.length / 160);
      }
      return nbremess + 1;
    }
    // addAutologinMessage() {
    //   if (this.row_bf.bf_autologin) {
    //     this.row_bf.bf_message =
    //       this.row_bf.bf_message + "\nLancez l'application Emotivi en cliquant sur ce lien : " + this.$config.webapp_url + "/tokenDuEnduser";
    //   } else {
    //     this.row_bf.bf_message = this.row_bf.bf_message.replace(
    //       "\nLancez l'application Emotivi en cliquant sur ce lien : " + this.$config.webapp_url + "/tokenDuEnduser",
    //       ""
    //     );
    //   }
    // }
  }
};
</script>
<style scoped lang="scss">
@import "../scss/styles.scss";

.winedit-container-header {
  width: 100%;
  justify-content: space-between;
}
.input-avatar {
  padding: 0;
}
.modal {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.active {
  min-width: 150px;
  input {
    width: auto;
  }
}
/*************** table droits **************/
tr {
  border-bottom: 0.5px solid #ccc9c9;
}
td:first-child {
  text-align: left;
}
td,
th {
  border-right: 0.5px solid #ccc9c9;
  border-left: 0.5px solid #ccc9c9;
  padding: 5px 10px;
  text-align: center;
}
th {
  background-color: #013c62;
  color: #fff;
  text-transform: uppercase;
}
th.groupe {
  color: black;
}
.groupe {
  background-color: #b5b2b2;
}
.more {
  border-radius: 50%;
  background-color: #91295a;
  width: 30px;
  height: 30px;
  text-align: center;
  color: white;
  padding: 6px;
  font-weight: bold;
}
</style>
