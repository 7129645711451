<template>
  <div class="h-75">
    <div class="row h-75">
      <m-message-dialog
        title="Erreur de communication"
        v-model="$store.state.dialogErr"
        :text="$store.state.dialogErrTxt"
        width="70%"
        @close="$store.dispatch('showDialogError', false)"
      />

      <!-- <div class="col-12">
				<p>{{ switchLang("labelCameraChoice") }}</p>
			</div> -->
      <!-- <div class="col-12 d-flex flex-row justify-content-center align-items-center mb-4">
				<p class="text mb-0">{{ switchLang("userCamera") }}</p>
				<label class="switch mb-0 mx-2">
					<input v-model="camera" type="checkbox" />
					<span class="slider round"></span>
				</label>
				<p class="text mb-0">{{ switchLang("environmentCamera") }}</p>
			</div> -->

      <div class="col-12">
        <div ref="videoContainer" class="videoContainer h-100" id="bistriVideos" v-bind:class="{ getfullscreen: getFullScreen }">
          <v-spinner ref="waitingSpinner"></v-spinner>
          <div style="position: relative" class="h-100">
            <button
              v-if="isFullScreen"
              @click="switchFullScreen()"
              v-show="!isOnlyAudio"
              class="btn-agrandir"
              style="position: absolute; top: 5%; right: 5%; z-index: 10000"
            >
              <icon name="compress-arrows-alt"></icon>
            </button>
            <div
              v-if="!onlyaudio && !isOnlyAudio"
              :style="visioConnected === 1 ? 'background-color:black;background-image:unset' : ''"
              ref="videoremote1"
              id="videoremote1"
              class="videoremote1"
            ></div>
            <div v-else class="h-100 w-100 d-flex justify-content-center align-items-center">
              <v-audio-waves ref="audiowaves" class=" h-100 w-50 justify-content-center"></v-audio-waves>
              <div ref="audioremote1" class="h-100 d-flex justify-content-center align-items-center" id="audioremote1" autoplay></div>
            </div>
          </div>

          <div v-if="!onlyaudio && !isOnlyAudio" class="janus-myvideo h-100" ref="myvideo" id="myvideo"></div>
          <div v-else ref="myaudio" class="myaudio h-100" id="myaudio"></div>
          <!-- <Spectrum
            v-if="isOnlyAudio"
            :remotestream="getRemoteStream"
            :mystream="getMyStream"
            :stoprender="stopSpectrum"
            ref="spectrum"
            id="spectrum"
          ></Spectrum>-->
          <!-- <div v-if="isOnlyAudio" id="spectrum_gif" style="filter: hue-rotate(-30deg)">
            
          </div> -->
          <button v-if="!isFullScreen" @click="switchFullScreen()" v-show="!isOnlyAudio" class="btn-agrandir" style="top: 0">
            <icon name="expand-arrows-alt"></icon>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button @click="bistriHangup(true)" ref="button_bistri_hangup" class="btn btn-danger btn-racrocher" :disabled="false">
          <icon class="iconMarginRight" name="phone-slash"></icon>
          {{ switchLang("hangup") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import bistri from "../plugins/bistri";

export default {
  name: "bistri",
  props: {
    onlyaudio: Boolean,
    en_id: Number,
    row_bo: Object
  },

  computed: {
    lang() {
      return this.$store.state.lang;
    },

    getFullScreen() {
      return this.isFullScreen;
    },

    orientationUV4L() {
      return this.$store.state.orientationUV4L;
    },
    pseudo() {
      return this.$store.state.user.us_firstname + " " + this.$store.state.user.us_name;
    },
    camera: {
      get() {
        return this.$store.state.camera && this.$store.state.camera === "environment" ? true : false;
      },
      set(val) {
        this.$store.commit("set_camera", val ? "environment" : "user");
      }
    },
    isHomePage() {
      return this.$store.state.isHomePage;
    },
    isCallPage() {
      return this.$store.state.isCallPage;
    },
    isAdminCodeValid() {
      return this.$store.state.isAdminCodeValid;
    }
  },

  async mounted() {
    // alert("ici1");
    //console.log("ROW BO : ", this.row_bo);
    let ok = await this.$Utils.wait(() => {
      return this.$store.state.mysocket_connected_to_room;
      // console.log("this.$store.state.mysocket_connected_to_room", this.$store.state.mysocket_connected_to_room);
    }, 5000);
    // alert("ici2");
    if (!ok) {
      this.showModalAlert(this.lang === "fr" ? this.pageText.alertNoNetwork.fr : this.pageText.alertNoNetwork.en);
      //return this.$router.push("home");
      document.location.reload();
      return;
    }

    this.mutationObserverVideoTag();
    try {
      this.autoDisconnect();
      bistri.call(this, this.en_id, this.row_bo);
    } catch (error) {
      console.error(error);
      this.destroyBistri("Nous sommes désolé, une erreur est survenue.", "error on start");
    }
  },
  // sockets: {
  // 	"/webapp/2.0/call-stop": function (reason) {
  // 		console.log("/webapp/2.0/call-stop", reason);
  // 		bistri.hangUp(false, true, reason);
  // 	}
  // 	// boxInfo(data) {
  // 	// 	console.warn("socket boxinfo", data);
  // 	// 	if (data.call_in_progress) {
  // 	// 		console.warn("socket call in progress");
  // 	// 		// l'emotivibox est déjà en cours d'appel, on raccroche uniquement si ce n'est pas celui qu iest en communication
  // 	// 		if (!this.visioConnected) {
  // 	// 			this.webApp_hangup = false;
  // 	// 			bistri.hangUp(true);
  // 	// 		}
  // 	// 	}
  // 	// },
  // 	// async disabledvisio(data) {
  // 	// 	// appel vidéo désactivé - horaires ou option dans le backoffice ?
  // 	// 	if (data.message) {
  // 	// 		bistri.hangUp(false, true, data.message);
  // 	// 	}
  // 	// }
  // },
  data() {
    return {
      // text
      pageText: {
        hangup: {
          fr: "Raccrocher",
          en: "Hang up"
        },
        alertHangup: {
          fr: "Aucune réponse, raccrochage automatique.",
          en: "No response, automatic hanging up"
        },
        alertErrorUV4L: {
          fr: "Erreur du serveur vidéo - raccrochage automatique",
          en: "Video server error - automatic hanging up"
        },
        alertError: {
          fr: "Erreur lors de l'appel - raccrochage automatique",
          en: "Video call error - automatic hanging up"
        },
        alertBistriError: {
          fr: "Erreur serveur - nous sommes désolé, une erreur est survenue. Nous vous invitons à contacter notre service technique.",
          en: "Server error - sorry, an error occured. We invite you to contact our technical service"
        },
        alertAutoHangup: {
          fr: "Aucune réponse après une minute d'attente. Votre appel a été annulé.",
          en: "No response after one minute. Your call has been canceled."
        },
        alertNoNetwork: {
          fr: "Erreur de connexion réseau... recharger l'application.",
          en: "Network error...please reload the application."
        },
        userCamera: { fr: "Caméra de face", en: "Front camera" },
        environmentCamera: { fr: "Caméra arrière", en: "Rear camera" },
        labelCameraChoice: {
          fr: "Choix de la caméra pour l'appel vidéo ( Smartphone / Tablette )",
          en: "Choice of camera for video call ( Smartphone / Tablet )"
        }
      },
      // états

      webApp_hangup: false,
      visioConnected: 0,

      isOnlyAudio: this.$props.onlyaudio,
      answeringMessage: false,
      isFullScreen: false,
      modalAlertVisible: false,
      modalAlertText: "",
      waitingToClose: false,
      currentstatus: "",
      userExist: false,
      stopSpectrum: false,
      // Données

      //timeout
      autodisconnect: null,
      timeoutQuit: null,
      timeoutSlowLink: null,
      intervalRedirect: null,
      //observer
      bodyObserver: null
    };
  },

  watch: {
    orientationUV4L(val, oldval) {
      if ((val === "180" || val === "0") && (oldval === "90" || oldval === "270") && !this.isFullScreen && this.visioConnected) {
        this.isFullScreen = true;
        this.autoSwitch_fullScreen = true;
      }
      if (
        (val === "90" || val === "270") &&
        (oldval === "0" || oldval === "180") &&
        this.isFullScreen &&
        this.autoSwitch_fullScreen &&
        this.visioConnected
      ) {
        this.isFullScreen = false;
      }
    }
  },

  methods: {
    switchFullScreen() {
      this.isFullScreen = !this.isFullScreen;
      this.autoSwitch_fullScreen = false;
    },
    clearAutoDisconnect() {
      if (this.autodisconnect) {
        clearTimeout(this.autodisconnect);
        this.autodisconnect = null;
      }
    },
    async autoDisconnect() {
      this.autodisconnect = setTimeout(async () => {
        // auto disconnect si aucun remote

        try {
          // let response =
          // await this.$Utils.doRequestIo("post", "/ws/screeenvisio", {
          // 	urltoken: this.$store.state.accesstoken,
          // 	onlyaudio: this.isOnlyAudio,
          // 	stop: true,
          // 	pseudo: this.pseudo ? this.pseudo : ""
          // });
          this.showModalAlert(this.lang === "fr" ? this.pageText.alertAutoHangup.fr : this.pageText.alertAutoHangup.en);

          this.bistriHangup();
        } catch (error) {
          console.error("ERROR AJAX SCREEENVISIO : ", error);
        }
      }, 1000 * 60);
    },
    disconnectAll() {
      this.bistriHangup();
    },
    /**  fonction qui envoie une requête http au serveur emotivi pour informer l'emobox que nous avons raccroché */
    // async notifStopVisio(where) {
    // 	await this.$Utils.doRequestIo("post", "/ws/screeenvisio", {
    // 		urltoken: this.$store.state.accesstoken,
    // 		onlyaudio: this.isOnlyAudio,
    // 		stop: true,
    // 		pseudo: this.pseudo ? this.pseudo : ""
    // 	});
    // },
    switchLang(key) {
      if (this.lang === "fr") {
        return this.pageText[key].fr;
      } else {
        return this.pageText[key].en;
      }
    },
    showModalAlert(text) {
      //if (this.$refs.alertModal) {
      this.$store.dispatch("showDialogError", text);
      //}
    },
    bistriHangup(webApp_hangup) {
      this.webApp_hangup = webApp_hangup;
      if (this.bodyObserver) this.bodyObserver.disconnect();

      bistri.hangUp();
    },

    mutationObserverVideoTag() {
      // empty video and audio html tag
      let tagToEmpty = ["myvideo", "myaudio", "videoremote1", "audioremote1"];
      tagToEmpty.forEach(tag => {
        let mytag = document.getElementById(tag);
        if (mytag) mytag.innerHTML = "";
      });
      let target = document.getElementById("bistriVideos");
      this.bodyObserver = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
          //How do i egt the video tags to add a listener?
          //console.log("MUTATION tag name : ", mutation.target.tagName);
          if (mutation.target.tagName === "VIDEO" || mutation.target.tagName === "video") {
            // console.log(" ----- VIDEO : ", mutation.target);
            mutation.target.style.width = "100%";

            mutation.target.style.height = "100%";
          }
        });
      });
      let bodyObserverConfig = {
        attributes: true,
        childList: true,
        subtree: true,
        characterData: true
      };
      this.bodyObserver.observe(target, bodyObserverConfig);
    },

    waitingSpinner(display, text) {
      if (this.$refs.waitingSpinner) {
        this.$refs.waitingSpinner.changeDisplay(display, text);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.video-image {
  border: 10px solid #fff;
}

.btn-agrandir {
  padding: 6px;
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ced4da;
}
.videoremote1 {
  width: 100%;

  background-image: url("/images/emotivi.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  animation: animatedBackground 1s ease-in-out 0s infinite alternate-reverse;

  height: 40vh;
}

.janus-myvideo {
  position: absolute;
  top: 4%;
  left: 4%;
  width: 25% !important;
  max-width: 200px;
  max-height: 200px;
  z-index: 1000;
}

.videoContainer {
  position: relative;
  width: 100%;
  height: 100%;
}
.btn-racrocher {
  width: 100%;
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: 500;
}
.getfullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5001;
}
.myaudio {
  height: 100%;
  width: 100%;
}
@keyframes animatedBackground {
  from {
    background-size: 35vh;
  }
  to {
    background-size: 45vh;
  }
}
@media (orientation: landscape) {
  .getfullscreen .videoremote1 {
    height: 100vh;
    width: 100vw;
  }
}

@media (orientation: portrait) {
  .getfullscreen .videoremote1 {
    height: 100vh;
    width: 100vw;
  }
}
</style>
