<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div>
              <h1>Afficher les logs de la box numéro {{ this.row_en.en_id }}</h1>
              <p></p>
            </div>
            <!--  <div class="d-flex flex-row align-items-center active">
              <label class="label-checkbox" for="active">Activer</label>
              <input type="checkbox" id="actif" name="actif" v-model="row_bo.bo_active" />
            </div> -->
          </div>
          <div class="modal-body modal-padding">
            <v-remote-terminal
              ref="logsTerminal"
              v-if="displayLogTerminal"
              :socket_response="get_logs_response ? get_logs_response.data.data : {}"
              style="width: 100%; height: 500px"
            ></v-remote-terminal>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between align-items-start">
            <div class="col-5">
              <m-form-select
                label="Sélectionnez le type de logs à afficher"
                v-model="logType"
                :name="$Utils.randomstring('logTypes')"
                :items="logTypes"
              ></m-form-select>
              <br /><br /><br />
              <button type="button" class="btn btn-secondary" @click="cancelWin">
                Annuler
              </button>
              <button type="button" class="btn btn-primary btn-mouveal ml-2" @click="displayTerminal">
                Afficher
              </button>
              <!-- this.$refs.logsTerminal.clear() -->
              <button type="button" class="btn btn-danger btn-mouveal ml-2" @click="clear">
                Effacer
              </button>
            </div>
            <div class="col-5 form-group">
              <label>Sélectionnez le nombre de lignes à afficher</label>
              <input v-model="nbLines" type="number" placeholder="max 50 000" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "BoxeWinLog",
  components: {},
  props: {
    row_en: Object,
    value: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      boxe: { bo_config_sources: {}, bo_config_resolution: { current: {}, availables: [] } },
      confirmdelete: false,
      items: [
        { text: "rouge", value: "0" },
        { text: "vert", value: "1" },
        { text: "rose", value: "2" },
        { text: "orange", value: "3" }
      ],
      // résolution
      resolutionSelected: {},
      resolutionItems: [],
      // les plages horaires
      manageHour: [],
      manageHourReformat: [],
      newHourStart: "",
      newHourEnd: "",
      addPlage: false,
      displayLogTerminal: false,
      bodyTerminal: { stdout: "" },
      nbLines: 500,
      logType: "emotivi/output.log",
      logTypes: [
        { value: "emotivi/output.log", text: "EMOTIVI sortie standard" },
        { value: "emotivi/error.log", text: "EMOTIVI sortie erreur" },
        { value: "syslog", text: "LOGS System" },
        { value: "kern.log", text: "LOGS Kernel" }
      ]
    };
  },
  watch: {
    value(v) {}
  },
  computed: {
    get_logs_response() {
      return this.$store.state.get_logs_response;
    }
  },

  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  created() {},
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    addPlageGo() {
      if (!this.newHourStart || !this.newHourEnd) return;
      this.manageHourReformat.push({
        start_time: { HH: this.newHourStart.split(":")[0], mm: this.newHourStart.split(":")[1] },
        end_time: { HH: this.newHourEnd.split(":")[0], mm: this.newHourEnd.split(":")[1] }
      });
      this.newHourStart = "";
      this.newHourEnd = "";
      this.addPlage = false;
    },
    deletePlage(plage, index) {
      // console.log("plage, index", plage, index);
      this.manageHourReformat.splice(index, 1);
    },
    async loadbox() {
      //   let params = {
      //   };
      //   let response = await this.$axios.get(
      //     this.$config.server_url + "/api/1.0/contacts/" + this.boxe_id,
      //     { params }
      //   );
      //   if (!response || !response.data) return;
      //   let row_fo = response.data.data;
      // console.log("this.manageHour", this.manageHour);
    },
    cancelWin() {
      // this.$router.push("/boxes/" + this.row_bo.bo_id);
      this.$emit("input", false);
      this.$emit("canceled");
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    displayTerminal() {
      this.displayLogTerminal = true;
      this.getLogs();
    },
    async getLogs() {
      //encodeURIComponent permet de formater la string
      let queryParams = { nbLines: this.nbLines, logType: encodeURIComponent(this.logType) };
      await this.$axios.get(this.$config.server_url + "/extranet/2.0/do/get-logs/" + this.row_en.en_id, {
        params: queryParams
      });
    },
    clear() {
      this.$refs.logsTerminal.clear();
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";
.time-picker input {
  border-radius: 4px;
}
.modal-footer {
  margin-top: 50px;
}
</style>
