import { render, staticRenderFns } from "./Invoices.vue?vue&type=template&id=06565bc0&scoped=true&"
import script from "./Invoices.vue?vue&type=script&lang=js&"
export * from "./Invoices.vue?vue&type=script&lang=js&"
import style0 from "./Invoices.vue?vue&type=style&index=0&id=06565bc0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06565bc0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/emotivi-v2-extranet/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('06565bc0')) {
      api.createRecord('06565bc0', component.options)
    } else {
      api.reload('06565bc0', component.options)
    }
    module.hot.accept("./Invoices.vue?vue&type=template&id=06565bc0&scoped=true&", function () {
      api.rerender('06565bc0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Invoices.vue"
export default component.exports