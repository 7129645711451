var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: _vm.value ? "show" : "hide",
        style: _vm.value
          ? "display:block;background-color:#3333337a;"
          : "display:none;",
        attrs: { tabindex: "-1", role: "dialog", "aria-hidden": "true" }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "div",
                {
                  staticClass: "modal-header d-flex flex-row align-items-center"
                },
                [
                  _c("div", [
                    _c("h1", [
                      _vm._v(
                        "Prévisualisation et confirmation de l'envoi de l'attestation"
                      )
                    ])
                  ])
                ]
              ),
              _c("div", { staticClass: "modal-body" }, [
                _c("iframe", {
                  attrs: {
                    src: _vm.urlPdf,
                    frameBorder: "0",
                    scrolling: "auto",
                    height: _vm.iframeHeight,
                    width: "100%",
                    target: "_parent"
                  }
                })
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "modal-footer d-flex flex-row justify-content-between"
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row align-items-end" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: { click: _vm.cancelWin }
                        },
                        [_vm._v("Annuler")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-mouveal ml-2",
                          attrs: { type: "button" },
                          on: { click: _vm.saveWin }
                        },
                        [_vm._v("Confirmer l'envoi de l'attestation")]
                      )
                    ]
                  )
                ]
              )
            ])
          ]
        ),
        _c("m-message-dialog", {
          attrs: { title: _vm.title, text: _vm.dialogTxt },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }