<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div>
              <h1>
                Création / Edition d'un produit ou service
              </h1>
            </div>
            <div class="d-flex flex-row align-items-center active">
              <m-form-checkbox label="Actif" :name="$Utils.randomstring('pr_active')" v-model="row_pr.pr_active"></m-form-checkbox>
            </div>
          </div>
          <div class="modal-body">
            <div>
              <h6><span class="number-title">1</span>Nom et type</h6>
              <div class="row">
                <div class="col-md-8">
                  <m-form-text label="Nom du produit / service" v-model="row_pr.pr_name" label-position="top" name="name"></m-form-text>
                </div>
                <div class="col-md-4">
                  <m-form-select
                    :items="$store.state.item_product_type"
                    label="Type"
                    label-position="top"
                    :name="$Utils.randomstring('pr_type')"
                    v-model="row_pr.pr_type"
                  ></m-form-select>
                </div>
              </div>
              <div class="d-flex flex-row">
                <m-form-text label="Prix achat HT" v-model="row_pr.pr_purchase_price_ht" label-position="top" name="price"></m-form-text
                ><m-form-text
                  class="ml-3"
                  label="Frais de port offerts"
                  v-model="row_pr.pr_shipping_cost"
                  label-position="top"
                  name="port"
                ></m-form-text>
              </div>
            </div>
            <hr />
            <div>
              <h6><span class="number-title">2</span> Matériels</h6>
              <div class="row d-flex flex-row align-items-end">
                <div class="col-md-8">
                  <m-form-combobox
                    label="Matériels"
                    label-position="top"
                    :name="$Utils.randomstring('materialToAdd')"
                    v-model="materialToAdd"
                    :store-url="$config.server_url + '/extranet/2.0/materials'"
                    :store-params="{}"
                    item-value="ma_id"
                    item-text="ma_name"
                  ></m-form-combobox>
                </div>
                <div class="col-md-2">
                  <m-form-text label="Quantité" v-model="addMaterialQte" label-position="top" name="qte"></m-form-text>
                </div>
                <div class="col-md-2">
                  <button type="button" class="btn btn-primary mr-5 ml-2" @click="addMaterial">
                    Ajouter
                  </button>
                </div>
              </div>
              <div class="d-flex flex-row mt-3">
                <div class="d-flex flex-column align-items-center mx-2" v-for="prma in materialList" :key="prma.prma_id">
                  <div>
                    <vue-load-image>
                      <img
                        slot="image"
                        :src="
                          $config.server_url +
                            '/extranet/2.0/materials/image/' +
                            prma.ma_id.ma_id +
                            '/70/50?d=' +
                            new Date().getTime() +
                            '&token=' +
                            $store.state.accesstoken
                        "
                      />
                      <img slot="preloader" src="images/image-loader70.gif" />
                      <div slot="error">pas d'image</div>
                    </vue-load-image>
                    <!--  <img
                      class="img-fluid"
                      :src="
                        $config.server_url +
                          '/extranet/2.0/materials/image/' +
                          prma.ma_id.ma_id +
                          '/70/50?d=' +
                          new Date().getTime() +
                          '&token=' +
                          $store.state.accesstoken
                      "
                      alt="image material"
                    /> -->
                  </div>
                  <div>
                    {{ prma.ma_id.ma_name }}
                  </div>
                  <div class="d-flex flex-row">
                    <div>
                      <m-form-text class="mt-0 mr-2" width="46px" v-model="prma.prma_quantity"> </m-form-text>
                    </div>
                    <div class="pointer" @click="deleteMaterial(prma)">
                      <icon name="trash-alt" color="red"></icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div>
              <h6><span class="number-title">3</span> Présent dans les offres suivantes</h6>
              <div class="ml-4" v-if="pr_id > 0">
                <ul>
                  <li class="pointer" @click="goOffer(offer)" v-for="ofpr in row_pr.offersproducts" :key="ofpr.ofpr_id">
                    {{ ofpr.of_id.of_name }}
                  </li>
                </ul>
              </div>
              <div v-else>Vous pouvez ajouter ce produit à une offre à partir de l'édition d'une offre.</div>
            </div>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between">
            <button type="button" class="btn btn-danger" @click="deleteConfirmWin">
              Supprimer
            </button>
            <div>
              <button type="button" class="btn btn-secondary" @click="cancelWin">
                Annuler
              </button>
              <button type="button" class="btn btn-primary ml-2" @click="saveWin(false)">
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <m-message-dialog v-model="dialogErr" title="Erreur" :text="dialogErrTxt"></m-message-dialog>
      <m-confirm-dialog
        v-model="confirmdelete"
        text="Ce produit sera mis en non-actif."
        title="Confirmation"
        width="500px"
        @canceled="confirmdelete = false"
        @confirmed="deleteWin()"
      ></m-confirm-dialog>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ProductWinEdit",
  components: {},
  props: {
    pr_id: Number,
    value: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      row_pr: {},
      product_id: 0,
      confirmdelete: false,
      materialList: [],
      materialToAdd: {},
      addMaterialQte: 1,
      dialogErrTxt: "",
      dialogErr: false
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.product_id = this.pr_id ? this.pr_id : -1;
        this.loadProducts();
      }
    }
  },
  computed: {},

  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  created() {},
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    async loadProducts() {
      let params = {};
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/products/" + this.product_id, { params });
      if (!response || !response.data) return;
      this.row_pr = response.data.data;
      this.materialList = this.row_pr.productsmaterials;
    },
    addMaterial() {
      let f = this.$_.find(this.materialList, { ma_id: this.materialToAdd.ma_id });
      if (!f) {
        let obj = {
          ma_id: this.materialToAdd,
          prma_quantity: this.addMaterialQte
        };
        this.materialList.push(obj);
        // console.log("this.materialList", this.materialList);
      }
      this.materialToAdd = {};
      this.addMaterialQte = 1;
    },
    deleteMaterial(prma) {
      let index = this.$_.findIndex(this.materialList, {
        ma_id: prma.ma_id
      });
      this.materialList.splice(index, 1);
    },
    addStockLine() {},
    addInventory() {},
    goOffer(offer) {
      this.$router.push("/offers/" + offer.pr_id);
    },
    async saveWin() {
      let err = [];
      let fieldRequired = [{ field: "pr_name", text: "nom" }];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.row_pr[field.field]) err.push(field);
      }
      if (err.length) {
        this.dialogErrTxt = "<span class='bis'>Vous devez remplir les champs : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error.text + " <br>";
        }
        this.dialogErr = true;
      } else {
        let response;
        this.row_pr.productsmaterials = this.materialList;
        if (this.row_pr.pr_id) {
          response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/products/" + this.row_pr.pr_id, this.row_pr);
        } else {
          this.row_pr.pr_active = true;
          response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/products", this.row_pr);
        }
        if (response.data.err) {
          this.$store.dispatch("showDialogError", response.data.err.message);
          return;
        }
        let row_pr = response.data.data;
        this.$emit("input", false);
        this.$emit("savedWin", row_pr.pr_id);
        this.$router.push("/products");
      }
    },
    cancelWin() {
      this.$router.push("/products");
      this.$emit("input", false);
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteWin() {
      await this.$axios.delete(this.$config.server_url + "/extranet/2.0/products/" + this.row_pr.pr_id);
      this.confirmdelete = false;
      this.$emit("input", false);
      this.$router.push("/products");
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";
</style>
