var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "d-flex w-100" }, [
    _c(
      "div",
      { staticClass: "d-flex flex-column  w-100", attrs: { id: "boxes" } },
      [
        _c("div", { staticClass: "d-flex header-emotivi" }, [
          _c("div", { staticClass: "d-flex align-items-center" }, [
            _c("h1", [_vm._v("Les " + _vm._s(_vm.actions_total) + " actions")])
          ])
        ]),
        _c("div", { staticClass: "flex-grow-1 overflow-hidden d-flex w-100" }, [
          _c(
            "div",
            { staticClass: "card card-no-margin w-100" },
            [
              _c("m-list", {
                ref: "actionslist",
                attrs: {
                  dynamic: true,
                  items: _vm.actions,
                  "item-value": "ac_id",
                  "item-height": 30,
                  limit: _vm.actions_limit,
                  skip: _vm.actions_skip,
                  total: _vm.actions_total,
                  bgstrip: true
                },
                on: { changerange: _vm.actionsChangerange },
                scopedSlots: _vm._u([
                  {
                    key: "ths",
                    fn: function(ref) {
                      return [
                        _c(
                          "th",
                          {
                            staticStyle: { width: "200px" },
                            on: {
                              click: function($event) {
                                return _vm.sortItems(["t1.createdAt"])
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "pointer" }, [
                              _vm._v("Date")
                            ])
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticStyle: { width: "400px" },
                            on: {
                              click: function($event) {
                                return _vm.sortItems(["ac_type"])
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "pointer" }, [
                              _vm._v("Type")
                            ])
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticStyle: { width: "200px" },
                            on: {
                              click: function($event) {
                                return _vm.sortItems([
                                  "t2.us_name",
                                  "t2.us_firstname"
                                ])
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "pointer" }, [
                              _vm._v("Us_id")
                            ])
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticStyle: { width: "200px" },
                            on: {
                              click: function($event) {
                                return _vm.sortItems(["t4.en_fullname"])
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "pointer" }, [
                              _vm._v("En_id")
                            ])
                          ]
                        ),
                        _c(
                          "th",
                          {
                            staticStyle: { width: "200px" },
                            on: {
                              click: function($event) {
                                return _vm.sortItems([
                                  "t3.co_invoice_name",
                                  "t3.co_invoice_firstname"
                                ])
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "pointer" }, [
                              _vm._v("Co_id")
                            ])
                          ]
                        ),
                        _c("th", [
                          _c("span", { staticClass: "pointer" }, [
                            _vm._v("Infos")
                          ])
                        ])
                      ]
                    }
                  },
                  {
                    key: "tds",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .$moment(item.createdAt)
                                .format("DD/MM/YYYY HH[H]mm")
                            )
                          )
                        ]),
                        _c("td", [_vm._v(" " + _vm._s(item.ac_type) + " ")]),
                        _c("td", [
                          item.us_id && item.us_id.us_id
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        to: {
                                          path: "/users/" + item.us_id.us_id
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatContactNameSimple")(
                                            item.us_id,
                                            "us_"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]),
                        _c("td", [
                          item.en_id && item.en_id.en_id
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        to: {
                                          path: "/boxes/" + item.en_id.en_id
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatEndUserName")(
                                            item.en_id,
                                            "en_"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]),
                        _c("td", [
                          item.co_id && item.co_id.co_id
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        to: {
                                          path:
                                            "/contacts/" +
                                            item.co_id.co_id +
                                            "/details"
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatContactNameSimple")(
                                            item.co_id,
                                            "co_invoice_"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]),
                        _c("td", [
                          item.ac_comment
                            ? _c("span", [_vm._v(_vm._s(item.ac_comment))])
                            : _vm._e(),
                          item.ac_elapsedtime > 0
                            ? _c("span", [
                                _vm._v(
                                  "Durée : " +
                                    _vm._s(
                                      Math.floor(
                                        (item.ac_elapsedtime / 60) * 100
                                      ) / 100
                                    ) +
                                    "mn"
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }