var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group textarea-markdown" }, [
    _vm.label
      ? _c(
          "label",
          {
            class: {
              label: true
            },
            attrs: { for: _vm.id2 }
          },
          [_vm._v(_vm._s(_vm.label))]
        )
      : _vm._e(),
    _c("div", { staticClass: "container-btn-markdown" }, [
      _c("div", { staticClass: "d-flex flex-row justify-content-end mr-4" }, [
        _c(
          "div",
          { staticClass: "container-icon ml-3", on: { click: _vm.bold } },
          [_c("icon", { attrs: { name: "bold" } })],
          1
        ),
        _c(
          "div",
          { staticClass: "container-icon ml-3", on: { click: _vm.italic } },
          [_c("icon", { attrs: { name: "italic" } })],
          1
        ),
        _c(
          "div",
          { staticClass: "container-icon ml-3", on: { click: _vm.color } },
          [_c("icon", { attrs: { name: "tint" } })],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "input-group" }, [
      _c("textarea", {
        ref: "myinput",
        staticClass: "textarea",
        class: {
          "form-control": true,
          "is-invalid": _vm.errormsg2
        },
        attrs: {
          "aria-haspopup": _vm.ariaHaspopup,
          "aria-expanded": _vm.ariaExpanded,
          rows: _vm.rows,
          name: _vm.name,
          id: _vm.id2,
          disabled: _vm.disabled,
          "aria-describedby": "",
          placeholder: _vm.placeholder,
          autocomplete: _vm.autocomplete
        },
        domProps: { value: _vm.value2 },
        on: {
          click: function($event) {
            return _vm.onclick($event)
          },
          input: function($event) {
            return _vm.oninput($event)
          },
          focus: function($event) {
            return _vm.onfocus($event)
          },
          blur: function($event) {
            return _vm.onblur($event)
          },
          keyup: function($event) {
            return _vm.onkeyup($event)
          },
          keydown: function($event) {
            return _vm.onkeydown($event)
          },
          select: _vm.selectText
        }
      }),
      _vm._v(" "),
      _vm.errormsg2
        ? _c(
            "div",
            { staticClass: "invalid-feedback", attrs: { id: _vm.id2 } },
            [_vm._v(" " + _vm._s(_vm.errormsg2) + " ")]
          )
        : _vm._e()
    ]),
    _c(
      "div",
      {
        staticClass: "btn-help",
        on: {
          click: function($event) {
            _vm.helpMarkdown = !_vm.helpMarkdown
          }
        }
      },
      [_vm._v("Afficher l'aide du markdown")]
    ),
    _vm.helpMarkdown ? _c("div", [_vm._m(0)]) : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        " - Pour faire un nouveau paragraphe, sauter deux lignes, c'est à dire laisser une ligne vide entre les deux paragraphes. Sauter une seule ligne dans le texte d'origine n'aura aucun effet à l'affichage (l'affichage sera en continu)."
      ),
      _c("br"),
      _vm._v(
        " - Pour faire un simple retour à la ligne, mettre deux espaces en fin de ligne."
      ),
      _c("br"),
      _vm._v(
        " - Pour mettre du texte en grande emphase, ce qui produit en général une mise en gras : **"
      ),
      _c("span", { staticClass: "italic" }, [_vm._v("votre texte en gras")]),
      _vm._v("** "),
      _c("br"),
      _vm._v(
        " - Pour faire une ligne de séparation hr : sauter une ligne puis mettre une série de tirets : ------------- "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }