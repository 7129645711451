<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div>
              <h1>Envoyer une macro infrarouge sur toutes les boxes de l'établissement</h1>
            </div>
          </div>
          <div class="modal-body ">
            <div>
              <h6><span class="number-title">1</span>Établissement</h6>
              <div class="row">
                <div class="col-md-9">
                  <m-form-select
                    label="Établissements"
                    label-position="top"
                    :name="$Utils.randomstring('establishmentsSelected')"
                    v-model="establishmentsSelected"
                    :items="establishments"
                  ></m-form-select>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <h6><span class="number-title">2</span>Macro infrarouge</h6>
              <div class="row">
                <div class="col-md-9">
                  <m-form-select
                    label="Macro infrarouge"
                    label-position="top"
                    :name="$Utils.randomstring('irmacroselected')"
                    v-model="irMacroSelected"
                    :items="irMacros"
                  ></m-form-select>
                </div>
              </div>
            </div>
            <div v-if="establishmentsSelected" class="mt-3">
              <m-form-checkbox
                label="Selectioner une liste de boxes"
                :name="$Utils.randomstring('selectboxes')"
                v-model="selectBoxes"
              ></m-form-checkbox>
            </div>
            <div class="row" v-if="selectBoxes">
              <hr class="hr-mini w-100" />
              <m-form-checkbox
                class="col-8"
                label="Afficher uniquement les boxes connectées"
                :name="$Utils.randomstring('selectconnectedboxes')"
                v-model="selectConnectedBoxes"
              ></m-form-checkbox>
              <m-form-text
                class="col-4 m-auto"
                width="200px"
                label="Rechercher"
                label-position="top"
                v-model="filterText"
                name="search"
                autocomplete
                @input="loadBoxes"
              ></m-form-text>
            </div>
            <div :style="'min-height:' + calculateHeight() + 'px;'" class="flex-grow-1  d-flex flex-column position-relative mt-2" v-if="selectBoxes">
              <div style="overflow:auto; position:absolute; top: 0; left:0; right:0; bottom:0" class="card card-no-margin">
                <m-list
                  ref="boxeslist"
                  :dynamic="true"
                  :items="selectConnectedBoxes ? connectedBoxes : boxes"
                  item-value="en_id"
                  :item-height="30"
                  :limit="boxes_limit"
                  :skip="boxes_skip"
                  :total="selectConnectedBoxes ? connected_boxes_total : boxes_total"
                  :current="boxes_current"
                  :bgstrip="true"
                  @changerange="boxesChangerange"
                >
                  <template v-slot:ths="{}">
                    <th class="" @click="sortItems(['en_id'])">
                      <span class="pointer">Numéro (en_id)</span>
                    </th>
                    <th style="min-width: 50px">
                      <span class="pointer">Connectée</span>
                    </th>

                    <th @click="sortItems(['en_fullname'])">
                      <span class="pointer">Utilisateur final</span>
                    </th>
                    <th>
                      <span class="pointer">Établissement</span>
                    </th>

                    <th style="width: 120px">
                      <span class="pointer">Selectionner</span>
                    </th>
                  </template>
                  <template v-slot:tds="{ item }">
                    <td>
                      {{ item.en_id }} <span v-if="item.bo_id">{{ item.bo_id.bo_mac_address }}</span>
                    </td>
                    <td
                      :class="{
                        mygreen: item.bo_id && $store.state.connectedBoxes.indexOf(item.bo_id.bo_mac_address) >= 0,
                        myred: !item.bo_id || $store.state.connectedBoxes.indexOf(item.bo_id.bo_mac_address) < 0
                      }"
                    >
                      {{ item.bo_id && $store.state.connectedBoxes.indexOf(item.bo_id.bo_mac_address) >= 0 ? "Connecté" : "Non connecté" }}
                    </td>

                    <td>
                      {{ item.en_fullname }}
                    </td>
                    <td>
                      {{ item.co_id && item.co_id.es_id ? item.co_id.es_id.es_name : "" }}
                    </td>

                    <td style="width: 120px">
                      <div class="d-flex flex-row">
                        <div @click="selectBox(item, $event)">
                          <icon
                            width="20"
                            height="20"
                            :name="selectedBoxes.includes(item.bo_id.bo_id) ? 'check' : 'times'"
                            :class="selectedBoxes.includes(item.bo_id.bo_id) ? 'text-info' : 'text-secondary'"
                          ></icon>
                        </div>
                      </div>
                    </td>
                  </template>
                </m-list>
              </div>
            </div>
          </div>
          <v-spinner ref="waitingSpinnerModal"></v-spinner>
          <div class="modal-footer d-flex flex-row justify-content-between">
            <div></div>
            <div>
              <button type="button" class="btn btn-secondary" @click="cancelWin">Annuler</button>
              <!-- <button type="button" class="btn btn-primary ml-2" @click="saveWin(false)">Envoyer</button> -->
              <button type="button" class="btn btn-primary ml-2" @click="sendIrMacro">Exécuter</button>
            </div>
          </div>
        </div>
      </div>
      <!-- <m-message-dialog v-model="dialogErr" title="Erreur" :text="dialogErrTxt"></m-message-dialog> -->
      <div style="display: none">
        <canvas ref="canvas" width="1024" height="768"></canvas>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalSendIrMacro",
  components: {},
  props: {
    value: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      establishments: [],
      establishmentsSelected: 0,
      irMacroSelected: "",
      irMacros: [],
      selectBoxes: false,
      filterText: "",
      filterEstablishment: "",
      filterStatusBox: "",
      sortOrder: "asc",
      sortFields: ["en_id"],
      boxes_limit: 50,
      boxes_skip: 0,
      boxes_total: 0,
      boxes_current: "",
      boxes: [],
      connectedBoxes: [],
      connected_boxes_total: 0,
      selectedBoxes: [],
      selectConnectedBoxes: false
    };
  },

  watch: {
    selectBoxes(value) {
      if (value && this.establishmentsSelected) this.loadBoxes();
    },
    establishmentsSelected(value) {
      this.getIrMacros();
      this.loadBoxes();
    }
  },

  async mounted() {
    // get user establishments
    let establishments = [];
    for (let i = 0; i < this.$store.state.user.establishments.length; i++) {
      const row_es = this.$store.state.user.establishments[i];
      establishments.push({
        text: row_es.es_name,
        value: row_es.es_id
      });
    }
    this.establishments = establishments;

    await this.getIrMacros();
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  created() {},
  methods: {
    async getIrMacros() {
      // get ir macros list
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/irmacros?es_id=" + this.establishmentsSelected + "&tt_id=0");
      if (response.data && response.data.data) this.irMacros = response.data.data;
    },
    calculateHeight() {
      return window.screen.height / 3;
    },
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
      if (e.key === "Enter") {
        this.sendIrMacro();
      }
    },

    cancelWin() {
      // this.$router.push("/boxes");
      this.resetData();
      this.$emit("input", false);
    },

    waitingSpinner(display, text) {
      if (this.$refs.waitingSpinnerModal) {
        this.$refs.waitingSpinnerModal.changeDisplay(display, text);
      }
    },
    resetData() {
      this.establishmentsSelected = 0;
      this.irMacroSelected = "";
      this.selectedBoxes = [];
      this.selectConnectedBoxes = false;
      this.selectBoxes = false;
    },
    async sendIrMacro() {
      if (!this.establishmentsSelected) return alert("Veuillez selectionner un établissement.");
      if (!this.irMacroSelected) return alert("Veuillez selectionner une macro infrarouge.");
      this.$refs.waitingSpinnerModal.changeDisplay("flex", "Envoi de la macro infrarouge en cours.");
      let response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/execute-ir-macro/" + this.establishmentsSelected, {
        macro: this.irMacroSelected,
        boxes: this.selectedBoxes
      });
      if (response.error) alert("La macro infrarouge n'a pas pu être éxecuté.");
      this.$refs.waitingSpinnerModal.changeDisplay("none", "");
      this.cancelWin();
    },
    async loadBoxes() {
      let types = [];
      let sort = this.sortFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.sortOrder;
        return accumulator;
      }, "");
      let params = {
        types: types,
        skip: this.boxes_skip,
        limit: this.boxes_limit,
        sort: sort,
        bo_id: ">0"
      };
      if (this.filterText) params.text = this.filterText;
      if (this.establishmentsSelected) params.establishment = this.establishmentsSelected;

      this.cancelAxiosLoadContacts && this.cancelAxiosLoadContacts();
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/endusers", {
        params,
        cancelToken: new this.$axios.CancelToken(c => {
          this.cancelAxiosLoadContacts = c;
        })
      });
      if (!response || !response.data) return;

      this.boxes = response.data.data;

      this.boxes_total = response.data.meta.total;
      this.connectedBoxes = this.boxes.filter(box => {
        return this.$store.state.connectedBoxes.includes(box.bo_id.bo_mac_address);
      });
      this.connected_boxes_total = this.connectedBoxes.length;
      // this.$refs.boxeslist.update();
      // console.log("this.boxes", this.boxes);
    },
    selectBox(box, event) {
      // if exist remove from the list of selected boxes ( uncheck )
      let boxId = box.bo_id.bo_id;
      if (this.selectedBoxes.includes(boxId)) this.selectedBoxes.splice(this.selectedBoxes.indexOf(boxId), 1);
      else this.selectedBoxes.push(boxId);
    },
    boxesChangerange(skip, scrollTop) {
      // console.log("skip, scrollTop", skip, scrollTop);
      this.boxes_skip = skip;
      this.loadBoxes();
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";
.container-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: 8px;
  background-position: center;
  background-size: cover;
  border: 1px solid #b5b5b5;
  box-shadow: 2px 2px 6px 0px #d0d0d0;
}

// from WEBAPP
.avatar-message {
  width: 90%;
  margin: auto;
}
.btn-envoyer {
  width: 100%;
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: 500;
}
input {
  margin-top: 20px;
}
.colWithoutPadding {
  padding-right: 10px;
}
.fileContainer {
  height: 50px;
  background-color: #ffffff;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 0;
  align-items: flex-end;
  border: 1px solid #ced4da;
  //   background-image: url("../../../public/assets/addphoto.png");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}
.labelImage {
  color: #6d6c64;
  font-size: 20px;
  padding: 10px;
  margin-bottom: 0;
}
textarea {
  font-size: 20px;
}
.imagetosend {
  width: 100%;
  margin-top: 20px;
  border: 1px solid gray;
}
.titre {
  margin-bottom: 0;
}
.btn-img {
  padding: 6px;
  display: none;
  position: absolute;
  //   bottom: 62px;
  bottom: 0px;
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
  border: 1px solid #b7b5b5;
}
.rotate-left {
  right: 150px;
}
.rotate-right {
  right: 110px;
}
.trash {
  right: 65px;
}
.btn-rotate {
  background-color: #ffffff8f;
}
#media-to-send {
  transform-origin: left;
}
/****** toggle button  ******/
/* The switch - the box around the slider */
.switch {
  // float: left;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  min-width: 60px;
  margin-right: 10px;
  margin-left: 10px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #6da;
}

input:focus + .slider {
  box-shadow: 0 0 1px #6da;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.container-logout {
  text-align: left;
}
.btn-logout {
  text-align: left;
  background-color: #ffffff00;
  border: 0;
}
.iconMarginRight {
  width: 40px;
  width: 40px;
  height: 40px;
  opacity: 0.2;
}
.mygreen {
  color: green;
  font-weight: bold;
}
.myred {
  color: red;
}
</style>
