var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "w-100" },
    [
      _c("div", { attrs: { id: "fiche" } }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-row flex-wrap justify-content-between align-items-center"
          },
          [
            _c("div", { staticClass: "d-flex flex-row align-items-center" }, [
              _c("h1", [
                _vm._v(
                  " Utilisateur final N°" + _vm._s(_vm.row_en.en_id) + " "
                ),
                !_vm.row_en.bo_id
                  ? _c("span", [_vm._v(" (Pas encore de box) ")])
                  : _vm._e(),
                _c("span", { staticStyle: { color: "lightgray" } }, [
                  _vm._v("|")
                ]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("formatContactNameSimple")(
                        _vm.row_co,
                        "co_invoice_"
                      )
                    ) +
                    " "
                ),
                _c("span", { staticStyle: { color: "lightgray" } }, [
                  _vm._v("|")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "text-red-emotivi" }, [
                  _vm._v(
                    _vm._s(_vm.row_co.es_id ? _vm.row_co.es_id.es_name : "")
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticStyle: { color: "lightgray" } }, [
                  _vm._v("|")
                ])
              ]),
              _c(
                "h4",
                {
                  class: {
                    "color-emotivi-blue": true,
                    "ml-2": true,
                    mygreen:
                      _vm.row_en.bo_id &&
                      _vm.$store.state.connectedBoxes.indexOf(
                        _vm.row_en.bo_id.bo_mac_address
                      ) >= 0,
                    myred:
                      !_vm.row_en.bo_id ||
                      _vm.$store.state.connectedBoxes.indexOf(
                        _vm.row_en.bo_id.bo_mac_address
                      ) < 0
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.row_en.bo_id &&
                          _vm.$store.state.connectedBoxes.indexOf(
                            _vm.row_en.bo_id.bo_mac_address
                          ) >= 0
                          ? "Box connectée"
                          : "Box non connectée"
                      ) +
                      " "
                  )
                ]
              )
            ]),
            _vm.row_en.newMessage > 0
              ? _c("div", [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "margin-left": "20px",
                        "background-color": "green",
                        color: "white",
                        "font-weight": "bold",
                        "border-radius": "50%",
                        width: "25px",
                        display: "inline-block",
                        "text-align": "center",
                        height: "25px"
                      }
                    },
                    [_vm._v(_vm._s(_vm.row_en.newMessage))]
                  ),
                  _vm._v(
                    " Vous avez reçu " +
                      _vm._s(_vm.row_en.newMessage) +
                      " nouveau(x) messages(s) aujourd'hui. "
                  )
                ])
              : _vm._e(),
            _c(
              "button",
              { staticClass: "btn btn-sm btn-primary" },
              [
                _c(
                  "router-link",
                  { staticClass: "color-white", attrs: { to: "/boxes" } },
                  [_vm._v(" Retour liste")]
                )
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.row_en.en_state_subscription === "SUSPENDED",
                expression: "row_en.en_state_subscription === 'SUSPENDED'"
              }
            ],
            staticClass: "row mt-5 mb-5"
          },
          [
            _c(
              "div",
              { staticClass: "col-md-9 d-flex align-items-center" },
              [
                _c("icon", {
                  staticClass: "mr-4",
                  staticStyle: { fill: "orange" },
                  attrs: { scale: "3", name: "exclamation-triangle" }
                }),
                _c("h3", { staticClass: "text-warning font-weight-bold" }, [
                  _vm._v("Service suspendu")
                ])
              ],
              1
            )
          ]
        ),
        _vm.filterInfoCom
          ? _c("div", [
              _vm._m(0),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-9" }, [
                  _vm.$store.getters.checkUserAccess(_vm.$router, "/contacts")
                    ? _c("div", { staticClass: "card" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row flex-wrap justify-content-between"
                          },
                          [
                            _c("h6", [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.$store.state.user.us_type ===
                                          "LATEAM" ||
                                        _vm.$store.state.user.us_type ===
                                          "EMOTIVI_USER",
                                      expression:
                                        "$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
                                    }
                                  ],
                                  staticClass: "number-title"
                                },
                                [_vm._v("1")]
                              ),
                              _vm._v("Contact (compte payeur) ")
                            ]),
                            _vm.$store.getters.checkUserAccess(
                              _vm.$router,
                              "/contacts/:co_id/edit"
                            )
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: { click: _vm.editContact }
                                  },
                                  [_vm._v(" Modifier ")]
                                )
                              : _vm._e()
                          ]
                        ),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("h5", [_vm._v("Coordonnées")]),
                            _c("div", [
                              _vm._v(" Téléphone mobile : "),
                              _c(
                                "span",
                                { staticClass: "color-emotivi-blue" },
                                [_vm._v(_vm._s(_vm.row_co.co_invoice_tel))]
                              )
                            ]),
                            _c("div", [
                              _vm._v(" Identifiant web : "),
                              _c(
                                "span",
                                { staticClass: "color-emotivi-blue" },
                                [_vm._v(_vm._s(_vm.row_co.co_login))]
                              )
                            ]),
                            _c("div", [
                              _vm._v(" Email : "),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "mailto:" + _vm.row_co.co_invoice_email
                                  }
                                },
                                [_vm._v(_vm._s(_vm.row_co.co_invoice_email))]
                              )
                            ]),
                            _c("div", { staticClass: "mt-3" }, [
                              _vm._v(
                                "Établissement : " +
                                  _vm._s(
                                    _vm.row_co.es_id
                                      ? _vm.row_co.es_id.es_name
                                      : ""
                                  )
                              )
                            ]),
                            _vm.row_co.afco_id && _vm.row_co.afco_id.af_id
                              ? _c("div", [
                                  _vm._v(
                                    "Code d'affiliation : " +
                                      _vm._s(_vm.row_co.afco_id.af_id.af_code)
                                  )
                                ])
                              : _vm._e(),
                            _vm.row_co.afco_id && _vm.row_co.afco_id.af_id
                              ? _c("div", [
                                  _vm._v(
                                    "Nom du revendeur : " +
                                      _vm._s(_vm.row_co.afco_id.af_id.af_name)
                                  )
                                ])
                              : _vm._e(),
                            _c("div", [
                              _vm._v(
                                "Site partenaire : " +
                                  _vm._s(
                                    _vm.row_co.co_partner_site
                                      ? _vm.row_co.co_partner_site
                                      : ""
                                  )
                              )
                            ]),
                            _c("div", [
                              _vm._v(
                                "Commentaire : " +
                                  _vm._s(
                                    _vm.row_co.co_comment
                                      ? _vm.row_co.co_comment
                                      : ""
                                  )
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("h5", [_vm._v("Adresse de facturation")]),
                            _c("div", [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push(
                                        "/contacts/" +
                                          _vm.row_co.co_id +
                                          "/details"
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatContactNameSimple")(
                                        _vm.row_co,
                                        "co_invoice_"
                                      )
                                    )
                                  )
                                ]
                              )
                            ]),
                            _c("div", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "https://maps.google.fr?q=" +
                                      _vm.co_invoice_address,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.row_co.co_invoice_address1)
                                  ),
                                  _c("br"),
                                  _vm.row_co.co_invoice_address2
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.row_co.co_invoice_address2)
                                        ),
                                        _c("br")
                                      ])
                                    : _vm._e(),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.row_co.co_invoice_zip) +
                                      " " +
                                      _vm._s(_vm.row_co.co_invoice_city)
                                  ),
                                  _c("br")
                                ]
                              )
                            ]),
                            _vm.$store.getters.checkUserAccess(
                              _vm.$router,
                              "/invoices"
                            )
                              ? _c("h5", { staticClass: "mt-2" }, [
                                  _vm._v("Prélévement bancaire")
                                ])
                              : _vm._e(),
                            _vm.$store.getters.checkUserAccess(
                              _vm.$router,
                              "/invoices"
                            ) && _vm.row_co.co_rib
                              ? _c("div", [
                                  _c("div", [
                                    _vm._v(
                                      "Titulaire : " +
                                        _vm._s(_vm.row_co.co_rib_name)
                                    )
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      "IBAN : " + _vm._s(_vm.row_co.co_rib_iban)
                                    )
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      "BIC : " + _vm._s(_vm.row_co.co_rib_bic)
                                    )
                                  ])
                                ])
                              : _vm.activePayment &&
                                _vm.activePayment.pm_method === "rib" &&
                                _vm.activePayment.pm_gocardless_ids
                              ? _c("div", [
                                  _vm._v(
                                    " Prélévement bancaire auprès de GOCARDLESS "
                                  )
                                ])
                              : _c("div", [_vm._v("Aucun")])
                          ]),
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("h5", [_vm._v("Adresse de livraison")]),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.row_co.co_shipping_society_name,
                                    expression:
                                      "row_co.co_shipping_society_name"
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  "Nom de la société : " +
                                    _vm._s(_vm.row_co.co_shipping_society_name)
                                )
                              ]
                            ),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatContactNameSimple")(
                                    _vm.row_co,
                                    "co_shipping_"
                                  )
                                )
                              )
                            ]),
                            _c("div", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "https://maps.google.fr?q=" +
                                      _vm.co_shipping_address,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.row_co.co_shipping_address1)
                                  ),
                                  _c("br"),
                                  _vm.row_co.co_shipping_address2
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.row_co.co_shipping_address2
                                          )
                                        ),
                                        _c("br")
                                      ])
                                    : _vm._e(),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.row_co.co_shipping_zip) +
                                      " " +
                                      _vm._s(_vm.row_co.co_shipping_city)
                                  ),
                                  _c("br")
                                ]
                              )
                            ]),
                            _vm.row_co.co_colissimo_ref
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "font-weight-bold text-danger"
                                  },
                                  [
                                    _vm._v(
                                      "POINT RELAIS : " +
                                        _vm._s(_vm.row_co.co_colissimo_ref)
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.row_co.es_id && _vm.row_co.es_id.es_white_label
                              ? _c("div", [
                                  _c("h5", { staticClass: "mt-2" }, [
                                    _vm._v("Fournisseur de services")
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col" }, [
                                      _vm._v(_vm._s(_vm.row_co.es_id.es_name))
                                    ]),
                                    _c("div", { staticClass: "col-auto" }, [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            _vm.$config.server_url +
                                            _vm.row_co.es_id.es_avatar,
                                          alt: "logo fournisseur",
                                          width: "50px"
                                        }
                                      })
                                    ])
                                  ])
                                ])
                              : _vm._e()
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm.$store.getters.checkUserAccess(
                    _vm.$router,
                    "/contacts/:co_id/order"
                  )
                    ? _c("div", { staticClass: "card" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row flex-wrap justify-content-between"
                          },
                          [
                            _c("h6", [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.$store.state.user.us_type ===
                                          "LATEAM" ||
                                        _vm.$store.state.user.us_type ===
                                          "EMOTIVI_USER",
                                      expression:
                                        "$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
                                    }
                                  ],
                                  staticClass: "number-title"
                                },
                                [_vm._v("3")]
                              ),
                              _vm._v("Paiements ")
                            ]),
                            _vm.loanedKit
                              ? _c("p", { staticStyle: { color: "#199aaa" } }, [
                                  _c("b", [
                                    _vm._v("Le kit est actuellement en prêt.")
                                  ])
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "row d-flex flex-row flex-wrap" },
                              [
                                _vm.loanedKit
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-warning mr-2 m-1",
                                        on: {
                                          click: function($event) {
                                            return _vm.confirmStopLoan()
                                          }
                                        }
                                      },
                                      [_vm._v("Kit restitué")]
                                    )
                                  : _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary mr-2 m-1",
                                        on: {
                                          click: function($event) {
                                            return _vm.confirmStartLoan()
                                          }
                                        }
                                      },
                                      [_vm._v("Kit en prêt")]
                                    ),
                                ((this.activePayment &&
                                  this.activePayment.pm_method === "cb") ||
                                  (!this.activePayment &&
                                    _vm.row_co.co_rib == 0)) &&
                                _vm.row_en.en_state_subscription !== "CB" &&
                                _vm.row_en.en_state_subscription !==
                                  "WAITING_STOPPED" &&
                                (!this.activeSubscription ||
                                  (this.activeSubscription &&
                                    this.activeSubscription.su_paymentmethod !==
                                      "cb"))
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary mr-2 m-1",
                                        on: {
                                          click: function($event) {
                                            return _vm.confirmStartSubscriptionDate(
                                              "cb"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Commencer abonnement CB ")]
                                    )
                                  : _vm._e(),
                                this.activePayment &&
                                this.activePayment.pm_rib == 1 &&
                                this.activePayment.pm_gocardless_ids &&
                                (!this.activeSubscription ||
                                  (this.activeSubscription &&
                                    this.activeSubscription.su_state !== 2))
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary mr-2 m-1",
                                        attrs: {
                                          disabled:
                                            _vm.disableGocardlessStartSubscriptionButton
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.confirmStartSubscriptionDate(
                                              "gocardless"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " Commencer abonnement RIB (gocardless) "
                                        )
                                      ]
                                    )
                                  : ((this.activePayment &&
                                      this.activePayment.pm_rib == 1) ||
                                      (!this.activePayment &&
                                        _vm.row_co.co_rib == 1)) &&
                                    _vm.row_en.en_state_subscription !==
                                      "RIB" &&
                                    _vm.row_en.en_state_subscription !==
                                      "WAITING_STOPPED" &&
                                    (!this.activeSubscription ||
                                      (this.activeSubscription &&
                                        this.activeSubscription
                                          .su_paymentmethod !== "rib"))
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary mr-2 m-1",
                                        attrs: {
                                          disabled:
                                            _vm.disableGocardlessStartSubscriptionButton
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.confirmStartSubscriptionDate(
                                              "rib"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Commencer abonnement RIB ")]
                                    )
                                  : _vm._e(),
                                _vm.displayConfirmStartSubscriptionDate
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-start align-items-center alert alert-secondary mt-1",
                                        staticStyle: { height: "38px" }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            " Choisir la date de démarrage "
                                          )
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "ml-2 d-flex align-items-center"
                                          },
                                          [
                                            _c("m-form-date", {
                                              ref: "newSubscriptionStartDate",
                                              model: {
                                                value:
                                                  _vm.newSubscriptionStartDate,
                                                callback: function($$v) {
                                                  _vm.newSubscriptionStartDate = $$v
                                                },
                                                expression:
                                                  "newSubscriptionStartDate"
                                              }
                                            }),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-sm btn-primary mt-1",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.createSubscriptionStartDate()
                                                  }
                                                }
                                              },
                                              [_vm._v("Démarrer")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-danger mr-2 m-1",
                                    on: {
                                      click: function($event) {
                                        return _vm.confirmWaitingRefund()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "Déclarer en attente de remboursement"
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        ),
                        _c("div", [
                          _vm.row_en.en_promo_code
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    padding: "1rem",
                                    border: "1px solid green",
                                    "border-radius": "0.5rem",
                                    display: "inline-block"
                                  }
                                },
                                [
                                  _vm._v(" Code promo : "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-weight": "bold",
                                        color: "green"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.row_en.en_promo_code))]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]),
                        _c(
                          "div",
                          { staticClass: "p-3 mt-3" },
                          [
                            _c("h5", {}, [
                              _vm._v(" Les paiements "),
                              _vm.loadingPayments
                                ? _c(
                                    "span",
                                    [
                                      _c("icon", {
                                        staticClass: "mr-2 fa-spin",
                                        attrs: {
                                          width: "30",
                                          height: "30",
                                          name: "sync"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]),
                            _c("m-list-simple", {
                              ref: "commandelist",
                              attrs: {
                                dynamic: true,
                                items: _vm.allCharges,
                                "item-value": "ac_id",
                                total: _vm.allCharges.length
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "ths",
                                    fn: function(ref) {
                                      return [
                                        _c("th", { attrs: { width: "170" } }, [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Date")]
                                          )
                                        ]),
                                        _c("th", { attrs: { width: "90" } }, [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Montant")]
                                          )
                                        ]),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Statut")]
                                          )
                                        ]),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Description")]
                                          )
                                        ]),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [
                                              _vm._v(
                                                "Id - lien vers le paiement"
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Plateforme")]
                                          )
                                        ]),
                                        _c("th", [
                                          _c("span", { staticClass: "pointer" })
                                        ]),
                                        _c("th", [
                                          _c("span", { staticClass: "pointer" })
                                        ]),
                                        _c("th", [
                                          _c("span", { staticClass: "pointer" })
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "tds",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$moment
                                                  .unix(item.created)
                                                  .isValid()
                                                  ? _vm.$moment
                                                      .unix(item.created)
                                                      .format(
                                                        "YYYY-MM-DD HH:mm:ss"
                                                      )
                                                  : _vm
                                                      .$moment(item.created)
                                                      .format(
                                                        "YYYY-MM-DD HH:mm:ss"
                                                      )
                                              ) +
                                              " "
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatPrice")(
                                                item.amount / 100
                                              )
                                            )
                                          )
                                        ]),
                                        _c(
                                          "td",
                                          {
                                            style:
                                              item.status === "FAILED"
                                                ? "color:red;"
                                                : ""
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.paymentsStatusText[
                                                    item.status
                                                  ]
                                                    ? _vm.paymentsStatusText[
                                                        item.status
                                                      ]
                                                    : item.status
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            style:
                                              item.status === "FAILED"
                                                ? "color:red;"
                                                : ""
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.description) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c("td", [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: item.receipt_url,
                                                target: "_blank"
                                              }
                                            },
                                            [_vm._v(_vm._s(item.id))]
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " + _vm._s(item.platform) + " "
                                          )
                                        ]),
                                        _c(
                                          "td",
                                          [
                                            item.payment_intent &&
                                            item.amount_refunded != item.amount
                                              ? _c("icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    width: "22",
                                                    height: "22",
                                                    name: "trash-alt",
                                                    color: "black"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.newCancelCharge(
                                                        item.payment_intent
                                                      )
                                                    }
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c("td", [
                                          item.amount_refunded
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "badge badge-danger"
                                                },
                                                [
                                                  _vm._v(
                                                    "REMBOURSÉ : " +
                                                      _vm._s(
                                                        _vm._f("formatPrice")(
                                                          item.amount_refunded /
                                                            100
                                                        )
                                                      )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]),
                                        _c("td", [
                                          item.end_user &&
                                          Array.isArray(item.end_user) &&
                                          item.end_user.length &&
                                          item.end_user[0].en_id ===
                                            _vm.row_en.en_id
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#91295a",
                                                    "font-weight": "bold"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "Caution liée à cette box"
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1710201818
                              )
                            }),
                            _c("hr", {
                              staticStyle: {
                                height: "5px",
                                "background-color": "lightgrey"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "p-3" },
                          [
                            _c("h5", {}, [
                              _vm._v(" Les abonnements "),
                              _vm.loadingPayments
                                ? _c(
                                    "span",
                                    [
                                      _c("icon", {
                                        staticClass: "mr-2 fa-spin",
                                        attrs: {
                                          width: "30",
                                          height: "30",
                                          name: "sync"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]),
                            _c("div", [
                              _vm.activeSubscription &&
                              _vm
                                .$moment(_vm.activeSubscription.su_startdate)
                                .isValid() &&
                              !_vm.activeSubscription.su_active
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-2 ml-2 mb-3 row align-items-center"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5"
                                        },
                                        [
                                          _c("small", [
                                            _vm._v(
                                              "Démarrage de l'abonnement prévu le : "
                                            ),
                                            _c(
                                              "b",
                                              {
                                                staticClass: "alert alert-info"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm
                                                      .$moment(
                                                        _vm.activeSubscription
                                                          .su_startdate
                                                      )
                                                      .format("L")
                                                  )
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 mt-3 mt-sm-3 mt-md-3 mt-lg-3 mt-xl-0 d-flex"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-content-start align-items-center"
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-warning btn-sm ml-2 mr-2 m-1",
                                                  staticStyle: {
                                                    "text-overflow": "ellipsis",
                                                    overflow: "hidden"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.modifySubscriptionStartDateDisplay()
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Modifier la date de démarrage "
                                                  )
                                                ]
                                              ),
                                              _vm.displayModifySubscriptionStartDate
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-items-center"
                                                    },
                                                    [
                                                      _c("m-form-date", {
                                                        model: {
                                                          value:
                                                            _vm.subscriptionStartDate,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.subscriptionStartDate = $$v
                                                          },
                                                          expression:
                                                            "subscriptionStartDate"
                                                        }
                                                      }),
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-sm btn-primary mt-1",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.modifySubscriptionStartDate()
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("Enregistrer")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          ),
                                          !_vm.displayModifySubscriptionStartDate &&
                                          !_vm.activeSubscription
                                            .subscriptionData.su_stripeid &&
                                          !_vm.activeSubscription
                                            .subscriptionData.su_gocardlessid
                                            ? _c("div", [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm btn-warning ml-2 mr-2 m-1",
                                                    staticStyle: {
                                                      "text-overflow":
                                                        "ellipsis",
                                                      overflow: "hidden"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.updateSubscription(
                                                          { su_active: true }
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Démarrer l'abonnement immediatement "
                                                    )
                                                  ]
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                : !_vm.activeSubscription &&
                                  _vm.row_en.en_startdate_subscription &&
                                  _vm
                                    .$moment(
                                      _vm.row_en.en_startdate_subscription
                                    )
                                    .isValid()
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-2 ml-2 mb-3 row align-items-center"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                        },
                                        [
                                          _c("small", [
                                            _vm._v(
                                              "Démarrage de l'abonnement prévu le : "
                                            ),
                                            _c(
                                              "b",
                                              {
                                                staticClass: "alert alert-info"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm
                                                      .$moment(
                                                        _vm.row_en
                                                          .en_startdate_subscription
                                                      )
                                                      .format("L")
                                                  )
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _vm.waitingSubscriptionStatus
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "row w-100",
                                    staticStyle: { height: "65px" }
                                  },
                                  [
                                    _c("v-spinner", {
                                      ref: "waitingSpinner",
                                      attrs: {
                                        _display: "flex",
                                        _text:
                                          "Votre demande a bien été prise en compte, la modification de l'abonnement est en cours de traitement, merci de patienter quelques minutes.",
                                        _backgroundColor:
                                          "rgba(255, 255, 255, 0.9)",
                                        _textColor: "black",
                                        _spinnerColor: "black"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.waitingPaymentMethodStatus
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "row w-100",
                                    staticStyle: { height: "65px" }
                                  },
                                  [
                                    _c("v-spinner", {
                                      ref: "waitingSpinner",
                                      attrs: {
                                        _display: "flex",
                                        _text:
                                          "Votre demande a bien été prise en compte, la modification du mode de paiement est en cours de traitement, merci de patienter quelques minutes.",
                                        _backgroundColor:
                                          "rgba(255, 255, 255, 0.9)",
                                        _textColor: "black",
                                        _spinnerColor: "black"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("m-list-simple", {
                              ref: "commandelist",
                              attrs: {
                                dynamic: true,
                                items: _vm.allSubscriptions,
                                "item-value": "ac_id",
                                total: _vm.allSubscriptions.length
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "ths",
                                    fn: function(ref) {
                                      return [
                                        _c(
                                          "th",
                                          { staticStyle: { width: "100px" } },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("Montant")]
                                            )
                                          ]
                                        ),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Statut")]
                                          )
                                        ]),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Description")]
                                          )
                                        ]),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Id")]
                                          )
                                        ]),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Début abonnement")]
                                          )
                                        ]),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Fin abonnement")]
                                          )
                                        ]),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Plateforme")]
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "tds",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "td",
                                          {
                                            style: {
                                              "background-color": item.color
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatPrice")(
                                                  item.plan.amount / 100
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            style: {
                                              "background-color": item.color
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.su_state_label) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            style: {
                                              "background-color": item.color
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.plan && item.plan.of_name
                                                    ? item.plan.of_name
                                                    : item.plan
                                                    ? item.plan.nickname
                                                    : ""
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            style: {
                                              "background-color": item.color
                                            }
                                          },
                                          [_vm._v(" " + _vm._s(item.id) + " ")]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            style: {
                                              "background-color": item.color
                                            }
                                          },
                                          [
                                            item.su_startdate
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm
                                                        .$moment(
                                                          item.su_startdate
                                                        )
                                                        .format(
                                                          "DD/MM/YYYY HH:mm:ss"
                                                        )
                                                    )
                                                  )
                                                ])
                                              : item.start_date
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$moment
                                                        .unix(item.start_date)
                                                        .format(
                                                          "DD/MM/YYYY HH:mm:ss"
                                                        )
                                                    )
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            style: {
                                              "background-color": item.color
                                            }
                                          },
                                          [
                                            item.su_enddate
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm
                                                        .$moment(
                                                          item.su_enddate
                                                        )
                                                        .format(
                                                          "DD/MM/YYYY HH:mm:ss"
                                                        )
                                                    )
                                                  )
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c("br"),
                                            item.subscriptionData.su_cancel_at
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "red",
                                                      "font-weight": "bold"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "(Stoppé le " +
                                                        _vm._s(
                                                          _vm
                                                            .$moment(
                                                              item
                                                                .subscriptionData
                                                                .su_cancel_at
                                                            )
                                                            .format(
                                                              "DD/MM/YYYY HH:mm:ss"
                                                            )
                                                        ) +
                                                        ")"
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            style: {
                                              "background-color": item.color
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.subscriptionData &&
                                                    item.subscriptionData
                                                      .su_gocardlessid
                                                    ? "GOCARDLESS"
                                                    : item.subscriptionData &&
                                                      item.subscriptionData
                                                        .su_stripeid
                                                    ? "STRIPE"
                                                    : "BANQUE"
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            style: {
                                              "background-color": item.color
                                            }
                                          },
                                          [
                                            item.typeproblem
                                              ? _c("icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    name:
                                                      "exclamation-triangle",
                                                    scale: "1",
                                                    width: "22",
                                                    color: "red",
                                                    height: "22",
                                                    "data-popover":
                                                      item.typeproblem
                                                  }
                                                })
                                              : _vm._e(),
                                            item.id &&
                                            typeof item.id === "string" &&
                                            item.id.indexOf("sub_") >= 0
                                              ? _c("icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    width: "22",
                                                    height: "22",
                                                    name: "trash-alt",
                                                    color: "black",
                                                    title:
                                                      "Arrêter l'abonnement Stripe"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.confirmCancelSubscription(
                                                        item.id
                                                      )
                                                    }
                                                  }
                                                })
                                              : item.id &&
                                                item.su_paymentmethod ===
                                                  "rib" &&
                                                item.su_state !== 0 &&
                                                item.subscriptionData &&
                                                item.subscriptionData
                                                  .su_gocardlessid
                                              ? _c("icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    width: "22",
                                                    height: "22",
                                                    name: "trash-alt",
                                                    color: "black",
                                                    disabled:
                                                      _vm.disableGocardlessStopSubscriptionButton,
                                                    title:
                                                      "Arrêter l'abonnement GoCardless"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.confirmCancelSubscriptionGocardless(
                                                        item.id,
                                                        item
                                                      )
                                                    }
                                                  }
                                                })
                                              : item.id &&
                                                item.su_paymentmethod ===
                                                  "rib" &&
                                                item.su_state !== 0 &&
                                                item.subscriptionData &&
                                                !item.subscriptionData
                                                  .su_stripeid &&
                                                !item.subscriptionData
                                                  .su_gocardlessid
                                              ? _c("icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    width: "22",
                                                    height: "22",
                                                    name: "trash-alt",
                                                    color: "black",
                                                    title:
                                                      "Arrêter l'abonnement SEPA"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.confirmCancelSubscriptionRib(
                                                        item.id,
                                                        item
                                                      )
                                                    }
                                                  }
                                                })
                                              : item.id &&
                                                (item.su_paymentmethod ===
                                                  "cb" ||
                                                  item.su_paymentmethod ===
                                                    "rib") &&
                                                item.su_state === 2 &&
                                                item.subscriptionData &&
                                                item.subscriptionData
                                                  .su_stripeid &&
                                                !item.subscriptionData
                                                  .su_gocardlessid
                                              ? _c("icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    width: "22",
                                                    height: "22",
                                                    name: "trash-alt",
                                                    color: "black",
                                                    title:
                                                      "Arrêter l'abonnement Stripe (CB/SEPA)"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.confirmCancelSubscription(
                                                        item.id
                                                      )
                                                    }
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1700542154
                              )
                            }),
                            _vm.oldRib
                              ? _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    "L'utilisateur souhaite payer par rib mais n'existe pas dans gocardless. "
                                  )
                                ])
                              : _vm._e(),
                            _c("hr", {
                              staticStyle: {
                                height: "5px",
                                "background-color": "lightgrey"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "p-3" },
                          [
                            _c("h5", {}, [
                              _vm._v(" Les modes de paiement "),
                              _vm.loadingPayments
                                ? _c(
                                    "span",
                                    [
                                      _c("icon", {
                                        staticClass: "mr-2 fa-spin",
                                        attrs: {
                                          width: "30",
                                          height: "30",
                                          name: "sync"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]),
                            this.activePayment &&
                            this.activePayment.pm_rib == 1 &&
                            this.activePayment.pm_gocardless_ids &&
                            this.activePayment.pm_gocardless_ids.customer
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "mt-3 btn btn-primary",
                                    attrs: {
                                      disabled: _vm.disableGocardlessButton
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.saveIBANToGocardless(
                                          "update"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " Mettre à jour l'IBAN sur Gocardless "
                                    )
                                  ]
                                )
                              : (this.activePayment &&
                                  this.activePayment.pm_rib == 1) ||
                                _vm.row_co.co_rib == 1
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "mt-3 btn btn-primary",
                                    attrs: {
                                      disabled: _vm.disableGocardlessButton
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.saveIBANToGocardless(
                                          "create"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " Enregistrer l'IBAN sur Gocardless "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.paymentMethods.length
                              ? _c("m-list-simple", {
                                  ref: "commandelist",
                                  attrs: {
                                    dynamic: true,
                                    items: _vm.paymentMethods,
                                    "item-value": "ac_id",
                                    total: _vm.paymentMethods.length
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "ths",
                                        fn: function(ref) {
                                          return [
                                            _c(
                                              "th",
                                              { attrs: { width: "200px" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "pointer" },
                                                  [_vm._v("Date")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { width: "200px" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "pointer" },
                                                  [_vm._v("Nom")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { width: "200px" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "pointer" },
                                                  [_vm._v("Mode")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { width: "500px" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "pointer" },
                                                  [_vm._v("Statut")]
                                                )
                                              ]
                                            ),
                                            _c("th", [
                                              _c(
                                                "span",
                                                { staticClass: "pointer" },
                                                [_vm._v("Type")]
                                              )
                                            ]),
                                            _c("th", [
                                              _c(
                                                "span",
                                                { staticClass: "pointer" },
                                                [_vm._v("Actions")]
                                              )
                                            ])
                                          ]
                                        }
                                      },
                                      {
                                        key: "tds",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            _c(
                                              "td",
                                              {
                                                style: {
                                                  "background-color": item.color
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm
                                                        .$moment(item.createdAt)
                                                        .isValid()
                                                        ? _vm
                                                            .$moment(
                                                              item.createdAt
                                                            )
                                                            .format(
                                                              "DD/MM/YYYY HH:mm:ss"
                                                            )
                                                        : ""
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                style: {
                                                  "background-color": item.color
                                                }
                                              },
                                              [_vm._v(_vm._s(item.pm_name))]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                style: {
                                                  "background-color": item.color
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.pm_gocardless_ids
                                                        ? _vm
                                                            .paymentMethodsName[
                                                            item.pm_method
                                                          ] + " - GoCardless"
                                                        : item.pm_stripe_ids
                                                        ? _vm
                                                            .paymentMethodsName[
                                                            item.pm_method
                                                          ] + " - Stripe"
                                                        : _vm
                                                            .paymentMethodsName[
                                                            item.pm_method
                                                          ] + " - Banque"
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                style: {
                                                  "background-color": item.color
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.displayPaymentMethodStatus(
                                                        item
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                style: {
                                                  "background-color": item.color
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.subscription
                                                        ? "Abonnement N° " +
                                                            item.subscription
                                                        : item.caution
                                                        ? "Caution/Frais de dossier"
                                                        : ""
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                style: {
                                                  "background-color": item.color
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "ml-2",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.displayPaymentMethodDetails(
                                                          item,
                                                          $event
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("icon", {
                                                      attrs: {
                                                        name: "info-circle"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2369561109
                                  )
                                })
                              : _vm._e(),
                            _c("hr", {
                              staticStyle: {
                                height: "5px",
                                "background-color": "lightgrey"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "p-3" },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("h5", { staticClass: "col-8" }, [
                                _vm._v(" Les attestations fiscales "),
                                _vm.loadingStripe
                                  ? _c(
                                      "span",
                                      [
                                        _c("icon", {
                                          staticClass: "mr-2 fa-spin",
                                          attrs: {
                                            width: "30",
                                            height: "30",
                                            name: "sync"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]),
                              !_vm.loadingStripe
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary col-4",
                                      on: {
                                        click: _vm.openFormAttestationFiscale
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " Générer une attestation fiscale "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _vm.attestations.length > 0
                              ? _c("m-list-simple", {
                                  ref: "commandelist",
                                  attrs: {
                                    dynamic: true,
                                    items: _vm.attestations,
                                    "item-value": "fisc_id"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "ths",
                                        fn: function(ref) {
                                          return [
                                            _c(
                                              "th",
                                              { attrs: { width: "200px" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "pointer" },
                                                  [_vm._v("Date de la demande")]
                                                )
                                              ]
                                            ),
                                            _c("th", [
                                              _c(
                                                "span",
                                                { staticClass: "pointer" },
                                                [_vm._v("Statut")]
                                              )
                                            ]),
                                            _c(
                                              "th",
                                              { attrs: { width: "150px" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "pointer" },
                                                  [_vm._v("Actions")]
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "tds",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            _c("td", [
                                              _vm._v(_vm._s(item.createdAt))
                                            ]),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: { width: "150px" },
                                                style: {
                                                  color:
                                                    item.fisc_status == "2"
                                                      ? "red"
                                                      : item.fisc_status == "1"
                                                      ? "green"
                                                      : "orange"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.fisc_status == "0"
                                                        ? "En attente"
                                                        : item.fisc_status ==
                                                          "1"
                                                        ? "Validée"
                                                        : item.fisc_status ==
                                                          "2"
                                                        ? "Irrecevable"
                                                        : "En attente"
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              {
                                                staticClass: "d-flex flex-row"
                                              },
                                              [
                                                _c("icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    width: "22",
                                                    height: "22",
                                                    name: "edit",
                                                    color: "black"
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.openFormAttestationFiscale
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2556839879
                                  )
                                })
                              : _c("div", [
                                  _vm._v(
                                    "Aucune demande d'attestation fiscale pour ce client."
                                  )
                                ])
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "p-3" },
                          [
                            _c("h5", {}, [
                              _vm._v(" Les factures "),
                              _vm.loadingPayments
                                ? _c(
                                    "span",
                                    [
                                      _c("icon", {
                                        staticClass: "mr-2 fa-spin",
                                        attrs: {
                                          width: "30",
                                          height: "30",
                                          name: "sync"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]),
                            _c("m-list-simple", {
                              ref: "commandelist",
                              attrs: {
                                dynamic: true,
                                items: _vm.orders,
                                "item-value": "or_id",
                                total: _vm.orders.length
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "ths",
                                    fn: function(ref) {
                                      return [
                                        _c(
                                          "th",
                                          { attrs: { width: "200px" } },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("nom")]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "th",
                                          { attrs: { width: "200px" } },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("Statut")]
                                            )
                                          ]
                                        ),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Méthode de paiement")]
                                          )
                                        ]),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Date")]
                                          )
                                        ]),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Total TTC")]
                                          )
                                        ]),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Actions")]
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "tds",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("td", [
                                          _vm._v(_vm._s(item.or_name))
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " + _vm._s(item.or_status) + " "
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.or_paymentmethod) +
                                              " "
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("formatDateTime")(
                                                  item.or_date
                                                )
                                              ) +
                                              " "
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(item.or_pricettc / 100) +
                                              " €"
                                          )
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "d-flex flex-row" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: "/invoices/" + item.or_id
                                                }
                                              },
                                              [
                                                _c("icon", {
                                                  attrs: {
                                                    color: "black",
                                                    name: "edit"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "ml-2",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.printOrder(
                                                      item,
                                                      $event
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("icon", {
                                                  attrs: { name: "print" }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "ml-2",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.updateOrderStatus(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                item.or_status === "UNPAID"
                                                  ? _c("icon", {
                                                      attrs: {
                                                        name: "euro-sign"
                                                      }
                                                    })
                                                  : item.or_status === "PAID"
                                                  ? _c(
                                                      "svg",
                                                      {
                                                        attrs: {
                                                          viewBox:
                                                            "0 0 320 512",
                                                          width: "16",
                                                          height: "16"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "g",
                                                          {
                                                            attrs: {
                                                              transform:
                                                                "matrix(1, 0, 0, 1, 23.662552, 0.580247)"
                                                            }
                                                          },
                                                          [
                                                            _c("path", {
                                                              attrs: {
                                                                d:
                                                                  "M310.7 413.8l8.8 44.5c1.2 6.2-2.6 12.4-8.8 14-12.5 3.2-34.8 7.8-61.1 7.8-104.4 0-182.5-65.3-207.5-155.6h-30.1c-6.6 0-12-5.4-12-12v-28.4c0-6.6 5.4-12 12-12h21.4c-1-13-0.8-28.6 0.2-42.2h-21.6c-6.6 0-12-5.4-12-12v-29.8c0-6.6 5.4-12 12-12h33.1c29-83.7 107.4-144 204.6-144 21.1 0 40.6 2.9 52.7 5.2 6.9 1.3 11.1 8.2 9.3 14.9l-12 44.4c-1.7 6.1-7.8 9.9-14 8.6-9.3-1.9-23.4-4.1-37.9-4.1-54.9 0-97 30-117.6 75h138.3c7.7 0 13.4 7.1 11.7 14.6l-6.5 29.8c-1.1 5.2-6.4 9.4-11.7 9.4v0h-146.6c-1.6 14-1.3 27.9-0.1 42.2h134.2c7.7 0 13.4 7.1 11.7 14.6l-6.4 28.4c-1.2 5.2-6.4 9.4-11.7 9.4h-113.7c19.5 50.7 64.7 85.4 121.5 85.4 18 0 34.7-3 45.4-5.4 6.6-1.5 13.1 2.7 14.4 9.4z"
                                                              }
                                                            }),
                                                            _c("path", {
                                                              staticStyle: {
                                                                "stroke-miterlimit":
                                                                  "2",
                                                                stroke:
                                                                  "rgb(0, 0, 0)",
                                                                "stroke-width":
                                                                  "70px"
                                                              },
                                                              attrs: {
                                                                d:
                                                                  "M 321.655 21.193 L 6.719 491.051"
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2497141809
                              )
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  this.tickets.length
                    ? _c("div", { staticClass: "card" }, [
                        _c("h6", [
                          _c("span", { staticClass: "number-title" }, [
                            _vm._v("6")
                          ]),
                          _vm._v("Tickets Freshdesk "),
                          _vm.loadingTickets
                            ? _c(
                                "span",
                                [
                                  _c("icon", {
                                    staticClass: "mr-2 fa-spin",
                                    attrs: {
                                      width: "30",
                                      height: "30",
                                      name: "sync"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "row mt-2" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-12" },
                            [
                              _c("m-list-simple", {
                                ref: "commandelist",
                                attrs: {
                                  dynamic: true,
                                  items: _vm.tickets,
                                  "item-value": "id",
                                  total: 1
                                },
                                on: { itemclick: _vm.goFreshdeskTicket },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "ths",
                                      fn: function(ref) {
                                        return [
                                          _c("th", [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("Date")]
                                            )
                                          ]),
                                          _c("th", [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("Sujet")]
                                            )
                                          ]),
                                          _c("th", {}, [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("État")]
                                            )
                                          ])
                                        ]
                                      }
                                    },
                                    {
                                      key: "tds",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDateTime")(
                                                  item.created_at
                                                )
                                              )
                                            )
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " + _vm._s(item.subject) + " "
                                            )
                                          ]),
                                          _c("td", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "ticket-status",
                                                class: "status-" + item.status
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f(
                                                        "formatStatusTicketsFreshdesk"
                                                      )(item.status)
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  975253670
                                )
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c("div", { staticClass: "card" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row flex-wrap justify-content-between"
                      },
                      [
                        _c("h6", [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.$store.state.user.us_type ===
                                      "LATEAM" ||
                                    _vm.$store.state.user.us_type ===
                                      "EMOTIVI_USER",
                                  expression:
                                    "$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
                                }
                              ],
                              staticClass: "number-title"
                            },
                            [_vm._v("2")]
                          ),
                          _vm._v("Utilisateur final ")
                        ]),
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mb-2",
                              on: { click: _vm.editEnduser }
                            },
                            [_vm._v("Modifier")]
                          )
                        ])
                      ]
                    ),
                    _c("div", [
                      _vm.row_en.en_linkello
                        ? _c("div", [
                            _c("img", {
                              staticClass: "logo-linkello",
                              attrs: { src: "/images/linkello.png" }
                            }),
                            _c("span", { staticClass: "linkello" }, [
                              _vm._v("Associé à Linkello")
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(
                        " Nom complet : " + _vm._s(_vm.row_en.en_fullname) + " "
                      ),
                      _c("hr", { staticClass: "hr-mini" }),
                      _c("div", { staticClass: "float-right" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-warning btn-sm mt-2",
                            on: { click: _vm.sendInvitationEmail }
                          },
                          [_vm._v("Renvoyer")]
                        )
                      ]),
                      _vm._v(" Ident. webapp : "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(
                                _vm.row_en.en_webapp_login
                              )
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.row_en.en_webapp_login))]
                      ),
                      _c("br"),
                      _vm._v(" Pass webapp :"),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(
                                _vm.row_en.en_webapp_password
                              )
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.row_en.en_webapp_password))]
                      ),
                      _c("hr", { staticClass: "hr-mini" }),
                      _vm._v(" Code administrateur : "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.row_en.en_admincode))]
                      ),
                      _c("hr", { staticClass: "hr-mini" }),
                      _vm._v(
                        " Chambre : " +
                          _vm._s(_vm.row_en.en_room ? _vm.row_en.en_room : "")
                      ),
                      _c("br"),
                      _vm._v(" Statut box : "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          style: _vm.getColorStatus(_vm.row_en.en_state_box),
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$Utils.getTextFromState(
                                _vm.$store.state.items_en_state_box,
                                _vm.row_en.en_state_box
                              )
                            )
                          )
                        ]
                      ),
                      _c("br"),
                      _vm._v(" Statut frais de dossier/caution: "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          style: _vm.getColorStatus(
                            _vm.row_en.en_state_caution
                          ),
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$Utils.getTextFromState(
                                _vm.$store.state.items_en_state_caution,
                                _vm.row_en.en_state_caution
                              )
                            )
                          )
                        ]
                      ),
                      _c("br"),
                      _vm._v(" Statut abonnement: "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          style: _vm.getColorStatus(
                            _vm.row_en.en_state_subscription
                          ),
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$Utils.getTextFromState(
                                _vm.$store.state.items_en_state_subscription,
                                _vm.row_en.en_state_subscription
                              )
                            )
                          )
                        ]
                      ),
                      _c("br"),
                      _vm._v(" Statut colis: "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          style: _vm.getColorStatus(_vm.row_en.en_state_parcel),
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$Utils.getTextFromState(
                                _vm.$store.state.items_en_state_parcel,
                                _vm.row_en.en_state_parcel
                              )
                            )
                          )
                        ]
                      ),
                      _c("br"),
                      _vm._v(" Statut support: "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          style: _vm.getColorStatus(
                            _vm.row_en.en_state_support
                          ),
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$Utils.getTextFromState(
                                _vm.$store.state.items_en_state_support,
                                _vm.row_en.en_state_support
                              )
                            )
                          )
                        ]
                      ),
                      _c("br"),
                      _vm.row_en.en_box_mac_address_wifi
                        ? _c("span", [
                            _c("hr", { staticClass: "hr-mini" }),
                            _vm._v(
                              " Adresse Mac WIFI : " +
                                _vm._s(_vm.row_en.en_box_mac_address_wifi)
                            )
                          ])
                        : _vm._e(),
                      _vm.row_en.en_box_mac_address_ethernet
                        ? _c("span", [
                            _c("hr", { staticClass: "hr-mini" }),
                            _vm._v(
                              " Adresse Mac Ethernet: " +
                                _vm._s(_vm.row_en.en_box_mac_address_ethernet)
                            )
                          ])
                        : _vm._e(),
                      _c("br"),
                      _vm.row_en.de_id
                        ? _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-danger mb-2",
                                staticStyle: { width: "180px" },
                                on: { click: _vm.resetTv }
                              },
                              [_vm._v("Réinitialiser la TV")]
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-danger mb-2",
                                staticStyle: { width: "180px" },
                                on: { click: _vm.deleteTv }
                              },
                              [_vm._v("Supprimer la TV")]
                            )
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm.$store.getters.checkUserAccess(
                    _vm.$router,
                    "/parcels/:en_id"
                  )
                    ? _c(
                        "div",
                        { staticClass: "card" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row flex-wrap justify-content-between"
                            },
                            [
                              _c("h6", [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.$store.state.user.us_type ===
                                            "LATEAM" ||
                                          _vm.$store.state.user.us_type ===
                                            "EMOTIVI_USER",
                                        expression:
                                          "$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
                                      }
                                    ],
                                    staticClass: "number-title"
                                  },
                                  [_vm._v("4")]
                                ),
                                _vm._v("Envois colis ")
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: { click: _vm.newParcel }
                                },
                                [_vm._v("Ajouter")]
                              )
                            ]
                          ),
                          _vm._l(_vm.parcels, function(parcel) {
                            return _c(
                              "div",
                              {
                                key: parcel.pa_id,
                                staticClass:
                                  "d-flex flex-row flex-wrap justify-content-between mt-3"
                              },
                              [
                                _c("div", [
                                  _c("div", [
                                    _vm._v(" Type de connexion : "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "color-emotivi-blue",
                                        class: _vm.getBold(
                                          _vm.row_co.co_box_connection_type
                                        ),
                                        style: {
                                          color: _vm.getColor(
                                            _vm.row_co.co_box_connection_type
                                          )
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.row_co.co_box_connection_type
                                          )
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("div", [
                                    _vm._v(" Société : "),
                                    _c(
                                      "span",
                                      { staticClass: "color-emotivi-blue" },
                                      [_vm._v(_vm._s(parcel.pa_company))]
                                    )
                                  ]),
                                  _c("div", [
                                    _vm._v(" N° suivi : "),
                                    _c(
                                      "span",
                                      { staticClass: "color-emotivi-blue" },
                                      [_vm._v(_vm._s(parcel.pa_trackingcode))]
                                    )
                                  ]),
                                  parcel.pa_sent_date &&
                                  parcel.pa_sent_date != "0000-00-00 00:00:00"
                                    ? _c("div", [
                                        _vm._v(" Date d'envoi : "),
                                        _c(
                                          "span",
                                          { staticClass: "color-emotivi-blue" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm
                                                  .$moment(parcel.pa_sent_date)
                                                  .format("DD/MM/YYYY HH[H]mm")
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _c("div", [
                                    _vm._v(" User : "),
                                    _c(
                                      "span",
                                      { staticClass: "color-emotivi-blue" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            parcel.us_id
                                              ? _vm.$options.filters.formatContactNameSimple(
                                                  parcel.us_id,
                                                  "us_"
                                                )
                                              : ""
                                          )
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("div", [
                                    _vm._v(" Etat :"),
                                    _c(
                                      "span",
                                      { staticClass: "color-emotivi-blue" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$Utils.getTextFromState(
                                                _vm.$store.state
                                                  .items_en_state_parcel,
                                                parcel.pa_state
                                              )
                                            )
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("div", [
                                    _vm._v(" Date : "),
                                    _c(
                                      "span",
                                      { staticClass: "color-emotivi-blue" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .$moment(parcel.updatedAt)
                                              .format("DD/MM/YYYY HH[H]mm")
                                          )
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("div", [
                                    _vm._v(" Tarif :"),
                                    _c(
                                      "span",
                                      { staticClass: "color-emotivi-blue" },
                                      [_vm._v(" " + _vm._s(parcel.pa_price))]
                                    )
                                  ])
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column align-items-end"
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-primary btn-sm w-100",
                                        on: {
                                          click: function($event) {
                                            return _vm.editParcel(parcel)
                                          }
                                        }
                                      },
                                      [_vm._v("Modifier")]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-warning my-1 btn-sm w-100",
                                        attrs: {
                                          disabled:
                                            parcel.pa_state !== "SENT" &&
                                            parcel.pa_state !== "PREPARING"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.mailParcel(parcel)
                                          }
                                        }
                                      },
                                      [_vm._v(" Envo. mail ")]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "w-100",
                                        attrs: {
                                          href:
                                            "https://www.laposte.fr/outils/suivre-vos-envois?code=" +
                                            parcel.pa_trackingcode,
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-warning btn-sm w-100"
                                          },
                                          [_vm._v("Suivre")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.$store.state.user.us_type === "LATEAM" ||
                            _vm.$store.state.user.us_type === "EMOTIVI_USER",
                          expression:
                            "$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
                        }
                      ],
                      staticClass: "card"
                    },
                    [
                      _c("h6", [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.$store.state.user.us_type === "LATEAM" ||
                                  _vm.$store.state.user.us_type ===
                                    "EMOTIVI_USER",
                                expression:
                                  "$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
                              }
                            ],
                            staticClass: "number-title"
                          },
                          [_vm._v("5")]
                        ),
                        _vm._v("Résumé ")
                      ]),
                      _c("div", { staticClass: "row mt-2" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("div", [
                            _vm._v(" Inscription le : "),
                            _c(
                              "span",
                              { staticClass: "color-emotivi-blue ml-2" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.row_en.infos.actionsFirstTime &&
                                        _vm.row_en.infos.actionsFirstTime
                                          .inscription
                                        ? _vm
                                            .$moment(
                                              _vm.row_en.infos.actionsFirstTime
                                                .inscription
                                            )
                                            .format("DD/MM/YYYY")
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _c("div", [
                            _vm._v(" Première install. le : "),
                            _c(
                              "span",
                              { staticClass: "color-emotivi-blue ml-2" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.row_en.infos.actionsFirstTime &&
                                        _vm.row_en.infos.actionsFirstTime
                                          .install
                                        ? _vm
                                            .$moment(
                                              _vm.row_en.infos.actionsFirstTime
                                                .install
                                            )
                                            .format("DD/MM/YYYY")
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _c("div", [
                            _vm._v(" Envoi colis le : "),
                            _c(
                              "span",
                              { staticClass: "color-emotivi-blue ml-2" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.row_en.infos.actionsFirstTime &&
                                        _vm.row_en.infos.actionsFirstTime
                                          .parcel_sended
                                        ? _vm
                                            .$moment(
                                              _vm.row_en.infos.actionsFirstTime
                                                .parcel_sended
                                            )
                                            .format("DD/MM/YYYY")
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _c("div", [
                            _vm._v(" Dernière connexion le : "),
                            _c(
                              "span",
                              { staticClass: "color-emotivi-blue ml-2" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.row_en.infos.actionsFirstTime &&
                                        _vm.row_en.infos.actionsFirstTime
                                          .connection
                                        ? _vm
                                            .$moment(
                                              _vm.row_en.infos.actionsFirstTime
                                                .connection
                                            )
                                            .format("DD/MM/YYYY")
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _c("div", [
                            _vm._v(" Réception colis le : "),
                            _c(
                              "span",
                              { staticClass: "color-emotivi-blue ml-2" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.row_en.infos.actionsFirstTime &&
                                        _vm.row_en.infos.actionsFirstTime
                                          .parcel_received
                                        ? _vm
                                            .$moment(
                                              _vm.row_en.infos.actionsFirstTime
                                                .parcel_received
                                            )
                                            .format("DD/MM/YYYY")
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _c("div", [
                            _vm._v(" Premier appel vidéo le : "),
                            _c(
                              "span",
                              { staticClass: "color-emotivi-blue ml-2" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.row_en.infos.actionsFirstTime &&
                                        _vm.row_en.infos.actionsFirstTime
                                          .videocall_firstSuccess
                                        ? _vm
                                            .$moment(
                                              _vm.row_en.infos.actionsFirstTime
                                                .videocall_firstSuccess
                                            )
                                            .format("DD/MM/YYYY")
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _c("div", [
                            _vm._v(" Premier appel audio le : "),
                            _c(
                              "span",
                              { staticClass: "color-emotivi-blue ml-2" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.row_en.infos.actionsFirstTime &&
                                        _vm.row_en.infos.actionsFirstTime
                                          .audiocall_firstSuccess
                                        ? _vm
                                            .$moment(
                                              _vm.row_en.infos.actionsFirstTime
                                                .audiocall_firstSuccess
                                            )
                                            .format("DD/MM/YYYY")
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _c("div", [
                            _vm._v(" Dernier appel le : "),
                            _c(
                              "span",
                              { staticClass: "color-emotivi-blue ml-2" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.row_en.infos.actionsFirstTime &&
                                        _vm.row_en.infos.actionsFirstTime
                                          .lastSuccessCall
                                        ? _vm
                                            .$moment(
                                              _vm.row_en.infos.actionsFirstTime
                                                .lastSuccessCall
                                            )
                                            .format("DD/MM/YYYY")
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _c("div", [
                            _vm._v(" VIDÉO/OK/VOIX/OK/MESSAGE "),
                            _c(
                              "span",
                              { staticClass: "color-emotivi-blue ml-2" },
                              _vm._l(_vm.row_en.infos.nbCalls, function(
                                number,
                                index
                              ) {
                                return _c("span", { key: index }, [
                                  _vm._v(_vm._s(number) + " "),
                                  index < _vm.row_en.infos.nbCalls.length - 1
                                    ? _c("span", [_vm._v("/ ")])
                                    : _vm._e()
                                ])
                              }),
                              0
                            )
                          ]),
                          _vm.row_en.en_suspended_service_dates &&
                          Array.isArray(
                            _vm.row_en.en_suspended_service_dates
                          ) &&
                          _vm.row_en.en_suspended_service_dates.length
                            ? _c(
                                "div",
                                { staticClass: "d-flex flex-column" },
                                [
                                  _vm._v(" Le service a été suspendu le(s) : "),
                                  _vm._l(
                                    _vm.row_en.en_suspended_service_dates,
                                    function(date, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass:
                                            "alert alert-warning d-flex flex-column"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm
                                                    .$moment(date.start)
                                                    .isValid(),
                                                  expression:
                                                    "$moment(date.start).isValid()"
                                                }
                                              ]
                                            },
                                            [
                                              _vm._v(" - Début : "),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm
                                                      .$moment(date.start)
                                                      .format(
                                                        "DD/MM/YYYY HH:mm:ss"
                                                      )
                                                  ) + " "
                                                )
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm
                                                    .$moment(date.end)
                                                    .isValid(),
                                                  expression:
                                                    "$moment(date.end).isValid()"
                                                }
                                              ]
                                            },
                                            [
                                              _vm._v(" - Fin : "),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm
                                                      .$moment(date.end)
                                                      .format(
                                                        "DD/MM/YYYY HH:mm:ss"
                                                      )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ]
                                      )
                                    }
                                  )
                                ],
                                2
                              )
                            : _vm._e()
                        ])
                      ])
                    ]
                  )
                ])
              ])
            ])
          : _vm._e(),
        _vm.filterInfoTech
          ? _c("div", [
              _vm._m(1),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-9" }, [
                  _c("div", { staticClass: "card" }, [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex flex-row justify-content-between"
                      },
                      [
                        _c("h6", [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.$store.state.user.us_type ===
                                      "LATEAM" ||
                                    _vm.$store.state.user.us_type ===
                                      "EMOTIVI_USER",
                                  expression:
                                    "$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
                                }
                              ],
                              staticClass: "number-title"
                            },
                            [_vm._v("1")]
                          ),
                          _vm._v("Informations techniques ")
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-lg-3 d-flex flex-column" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mt-2",
                              attrs: { disabled: !_vm.row_en.bo_id },
                              on: { click: _vm.editBoxe }
                            },
                            [_vm._v("Options")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mt-2",
                              attrs: { disabled: !_vm.row_en.bo_id },
                              on: { click: _vm.editBoxe }
                            },
                            [_vm._v("Modifier plage horaire")]
                          ),
                          _vm.$store.state.user.us_type === "LATEAM" ||
                          _vm.$store.state.user.us_type === "TECHNICIAN"
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary mt-2",
                                  attrs: { disabled: !_vm.row_en.bo_id },
                                  on: { click: _vm.displayBoxLogs }
                                },
                                [_vm._v(" Remonter les logs ")]
                              )
                            : _vm._e()
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-lg-3 d-flex flex-column" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mt-2",
                              attrs: { disabled: !_vm.row_en.bo_id },
                              on: { click: _vm.showConfigPage }
                            },
                            [_vm._v("Ouvrir config")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mt-2",
                              attrs: { disabled: !_vm.row_en.bo_id },
                              on: { click: _vm.showAnimation }
                            },
                            [_vm._v("Afficher l'animation")]
                          ),
                          _vm.$Utils.isServiceProvider()
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary mt-2",
                                  on: {
                                    click: function($event) {
                                      return _vm.reinitBoxe()
                                    }
                                  }
                                },
                                [_vm._v("Réinitialiser la boxe")]
                              )
                            : _vm._e()
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-lg-3 d-flex flex-column" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mt-2",
                              attrs: { disabled: !_vm.row_en.bo_id },
                              on: { click: _vm.diagnostikReseau }
                            },
                            [_vm._v("Diagnostic réseau")]
                          ),
                          _vm.$store.state.user.us_type === "LATEAM"
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary mt-2",
                                  attrs: { disabled: !_vm.row_en.bo_id },
                                  on: { click: _vm.startSSH }
                                },
                                [
                                  _vm._v(
                                    " SSH port " +
                                      _vm._s(_vm.row_bo.bo_ssh_port) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-lg-3 d-flex flex-column" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger mt-2",
                              attrs: { disabled: !_vm.row_en.bo_id },
                              on: { click: _vm.rebootBox }
                            },
                            [_vm._v("Redémarrer la box")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger mt-2",
                              attrs: { disabled: !_vm.row_en.bo_id },
                              on: { click: _vm.restartBox }
                            },
                            [_vm._v("Redémarrer l'app")]
                          ),
                          _c(
                            "div",
                            {},
                            [
                              (_vm.$store.state.user.us_type === "LATEAM" ||
                                _vm.$store.state.user.us_type ===
                                  "TECHNICIAN") &&
                              _vm.row_bo.bo_id
                                ? _c("box-versions-select", {
                                    attrs: { row_bo: _vm.row_bo }
                                  })
                                : _vm._e(),
                              _vm.$store.state.user.us_type === "LATEAM" ||
                              _vm.$store.state.user.us_type === "TECHNICIAN"
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-danger mt-2 w-100",
                                      attrs: { disabled: !_vm.row_en.bo_id },
                                      on: { click: _vm.updateBox }
                                    },
                                    [_vm._v(" Mettre à jour ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "row my-3" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _vm.row_bo.bo_callhours
                          ? _c("div", { staticClass: "bis uppercase mr-2" }, [
                              _vm._v("Plages horaire :")
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "d-flex flex-row flex-wrap mb-2" },
                          _vm._l(_vm.row_bo.bo_callhours, function(
                            plage,
                            index
                          ) {
                            return _c("div", { key: index }, [
                              plage.start
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          plage.start.split(":").join("H")
                                        ) +
                                        "-" +
                                        _vm._s(plage.end.split(":").join("H"))
                                    ),
                                    index < _vm.row_bo.bo_callhours.length - 1
                                      ? _c("span", { staticClass: "mx-1" }, [
                                          _vm._v(" / ")
                                        ])
                                      : _vm._e()
                                  ])
                                : _vm._e()
                            ])
                          }),
                          0
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _vm._v(" Type de connexion : "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.row_co.co_box_connection_type,
                                expression: "row_co.co_box_connection_type"
                              }
                            ],
                            staticClass: "color-emotivi-blue mb-4",
                            staticStyle: {
                              border: "solid",
                              "border-radius": "0",
                              "text-align": "center"
                            },
                            attrs: {
                              id: "co_box_connection_type",
                              name: "co_box_connection_type"
                            },
                            on: {
                              change: [
                                function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.row_co,
                                    "co_box_connection_type",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function($event) {
                                  return _vm.onChangeConnectionType($event)
                                }
                              ]
                            }
                          },
                          [
                            _c("option", { attrs: { value: "ETHERNET" } }, [
                              _vm._v("Câble ethernet")
                            ]),
                            _c("option", { attrs: { value: "WIFI" } }, [
                              _vm._v("WiFi")
                            ]),
                            _c("option", { attrs: { value: "CLE4G_PERSO" } }, [
                              _vm._v("Clé 4G personnelle")
                            ]),
                            _c(
                              "option",
                              { attrs: { value: "CLE4G_EMOTIVI" } },
                              [_vm._v("Clé 4G Emotivi")]
                            ),
                            _c(
                              "option",
                              { attrs: { value: "ROUTEUR4G_EMOTIVI" } },
                              [_vm._v("Routeur 4G Emotivi")]
                            ),
                            _c(
                              "option",
                              { attrs: { value: "ROUTEUR4G_PERSO" } },
                              [_vm._v("Routeur 4G personnel")]
                            )
                          ]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-warning",
                            attrs: { disabled: !_vm.row_en.bo_id },
                            on: {
                              click: function($event) {
                                _vm.showMainDetailsBox = !_vm.showMainDetailsBox
                              }
                            }
                          },
                          [
                            !_vm.showMainDetailsBox
                              ? _c("span", [_vm._v("Afficher")])
                              : _vm._e(),
                            _vm.showMainDetailsBox
                              ? _c("span", [_vm._v("Masquer")])
                              : _vm._e(),
                            _vm._v(" les principales informations de la box ")
                          ]
                        )
                      ])
                    ]),
                    _vm.showMainDetailsBox
                      ? _c("div", { staticClass: "row mt-4" }, [
                          _vm.row_bo
                            ? _c(
                                "div",
                                { staticClass: "col-md-12 d-flex flex-column" },
                                _vm._l(_vm.tabsInfos, function(tab, indexTab) {
                                  return _c(
                                    "div",
                                    { key: indexTab, staticClass: "mb-3" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "bis uppercase" },
                                        [_vm._v(_vm._s(tab.value))]
                                      ),
                                      _vm._l(_vm.maininfos(tab.key), function(
                                        info
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: info.key,
                                            staticClass: "row tr-boxedetail"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "col-md-6",
                                                attrs: { width: "200px" }
                                              },
                                              [_vm._v(_vm._s(info.key))]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-6 color-emotivi-blue"
                                              },
                                              [
                                                _c("pre", [
                                                  _c("code", [
                                                    _vm._v(_vm._s(info.val))
                                                  ])
                                                ])
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col" }, [
                              _vm.$store.state.user.us_type === "LATEAM" ||
                              _vm.$store.state.user.us_type === "TECHNICIAN"
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-warning",
                                      attrs: { disabled: !_vm.row_en.bo_id },
                                      on: {
                                        click: function($event) {
                                          _vm.showDetailsBox = !_vm.showDetailsBox
                                        }
                                      }
                                    },
                                    [
                                      !_vm.showDetailsBox
                                        ? _c("span", [_vm._v("Afficher")])
                                        : _vm._e(),
                                      _vm.showDetailsBox
                                        ? _c("span", [_vm._v("Masquer")])
                                        : _vm._e(),
                                      _vm._v(" les détails avancés de la box ")
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ])
                        ])
                      : _vm._e(),
                    _vm.showDetailsBox
                      ? _c("div", { staticClass: "row mt-4" }, [
                          _vm.row_bo
                            ? _c(
                                "div",
                                { staticClass: "col-md-12 d-flex flex-column" },
                                _vm._l(_vm.tabsInfos, function(tab, indexTab) {
                                  return _c(
                                    "div",
                                    { key: indexTab, staticClass: "mb-3" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "bis uppercase" },
                                        [_vm._v(_vm._s(tab.value))]
                                      ),
                                      _vm._l(_vm.allinfos(tab.key), function(
                                        info
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: info.key,
                                            staticClass: "row tr-boxedetail"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "col-md-3",
                                                attrs: { width: "200px" }
                                              },
                                              [_vm._v(_vm._s(info.key))]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-9 color-emotivi-blue"
                                              },
                                              [
                                                _c("pre", [
                                                  _c("code", [
                                                    _vm._v(_vm._s(info.val))
                                                  ])
                                                ])
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ])
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    { staticClass: "card" },
                    [
                      _c("div", { staticClass: "uppercase bis mb-3" }, [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.$store.state.user.us_type === "LATEAM" ||
                                  _vm.$store.state.user.us_type ===
                                    "EMOTIVI_USER",
                                expression:
                                  "$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
                              }
                            ],
                            staticClass: "number-title"
                          },
                          [_vm._v("4")]
                        ),
                        _vm._v("Messages ")
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-warning mt-1 mr-2",
                              attrs: { disabled: !_vm.row_en.bo_id },
                              on: {
                                click: function($event) {
                                  _vm.showMessages = !_vm.showMessages
                                }
                              }
                            },
                            [
                              !_vm.showMessages
                                ? _c("span", [_vm._v("Afficher")])
                                : _vm._e(),
                              _vm.showMessages
                                ? _c("span", [_vm._v("Masquer")])
                                : _vm._e(),
                              _vm._v(" les messages ")
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mt-1 mr-2",
                              attrs: { disabled: !_vm.row_en.bo_id },
                              on: {
                                click: function($event) {
                                  _vm.addMessage = !_vm.addMessage
                                }
                              }
                            },
                            [_vm._v("Envoyer un message")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mt-1 mr-2",
                              attrs: { disabled: !_vm.row_en.bo_id },
                              on: {
                                click: function($event) {
                                  _vm.scheduleMessage = !_vm.scheduleMessage
                                }
                              }
                            },
                            [_vm._v(" Planifier un message ")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mt-1 mr-2",
                              attrs: { disabled: !_vm.row_en.bo_id },
                              on: { click: _vm.videoCall }
                            },
                            [_vm._v("Démarrer un appel vidéo")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mt-1 mr-2",
                              attrs: { disabled: !_vm.row_en.bo_id },
                              on: { click: _vm.audioCall }
                            },
                            [_vm._v("Démarrer un appel audio")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger mt-1 mr-2",
                              attrs: { disabled: !_vm.row_en.bo_id },
                              on: { click: _vm.deleteAllMessages }
                            },
                            [_vm._v("Effacer les messages")]
                          )
                        ])
                      ]),
                      _vm.addMessage
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "row d-flex flex-row align-items-center"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-md-12" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-column" },
                                    [
                                      _c("m-form-checkbox", {
                                        staticClass: "mt-2",
                                        attrs: {
                                          label: "Message de type ADMIN",
                                          name: _vm.$Utils.randomstring(
                                            "isAdmin"
                                          )
                                        },
                                        model: {
                                          value: _vm.isAdmin,
                                          callback: function($$v) {
                                            _vm.isAdmin = $$v
                                          },
                                          expression: "isAdmin"
                                        }
                                      }),
                                      _vm.isAdmin
                                        ? _c("m-form-checkbox", {
                                            attrs: {
                                              label: "Confirmation de lecture",
                                              name: _vm.$Utils.randomstring(
                                                "acknowledgment"
                                              )
                                            },
                                            model: {
                                              value: _vm.acknowledgment,
                                              callback: function($$v) {
                                                _vm.acknowledgment = $$v
                                              },
                                              expression: "acknowledgment"
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.isAdmin
                                        ? _c("m-form-minutes", {
                                            staticClass: "mx-1",
                                            staticStyle: {
                                              "overflow-wrap": "normal"
                                            },
                                            attrs: {
                                              label:
                                                "Durée d'affichage (minutes:secondes)",
                                              name: _vm.$Utils.randomstring(
                                                "duration"
                                              )
                                            },
                                            model: {
                                              value: _vm.duration,
                                              callback: function($$v) {
                                                _vm.duration = $$v
                                              },
                                              expression: "duration"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c("m-form-text", {
                                    attrs: {
                                      label: "Message",
                                      type: "textarea",
                                      name: "message"
                                    },
                                    model: {
                                      value: _vm.newMessage,
                                      callback: function($$v) {
                                        _vm.newMessage = $$v
                                      },
                                      expression: "newMessage"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.photoJoined,
                                          expression: "!photoJoined"
                                        }
                                      ]
                                    },
                                    [
                                      _vm._m(2),
                                      _c("input", {
                                        ref: "myFiles",
                                        staticClass: "form-control inputImage",
                                        staticStyle: { display: "none" },
                                        attrs: {
                                          type: "file",
                                          id: "upload",
                                          name: "file"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.handleFiles()
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.photoJoined,
                                          expression: "photoJoined"
                                        }
                                      ]
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          ref: "previewContainer",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            id: "container-media",
                                            for: "upload"
                                          }
                                        },
                                        [
                                          _c("div", {
                                            ref: "preview",
                                            attrs: {
                                              id: "container-media-content"
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-rotate mr-2 btn-img rotate-left",
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.rotateFiles(
                                                $event,
                                                "left"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("icon", {
                                            attrs: { name: "undo-alt" }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-rotate mr-2 btn-img rotate-right",
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.rotateFiles(
                                                $event,
                                                "right"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("icon", {
                                            attrs: { name: "redo-alt" }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-danger mr-2 btn-img trash",
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.deleteFiles($event)
                                            }
                                          }
                                        },
                                        [
                                          _c("icon", {
                                            attrs: { name: "trash-alt" }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary mt-2",
                                      on: { click: _vm.sendMessage }
                                    },
                                    [_vm._v("Envoyer")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.showMessages
                        ? _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-12" },
                              [
                                _c("m-list-simple", {
                                  ref: "commandelist",
                                  attrs: {
                                    dynamic: true,
                                    items: _vm.messages,
                                    "item-value": "me_id",
                                    total: _vm.messages_length
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "ths",
                                        fn: function(ref) {
                                          return [
                                            _c(
                                              "th",
                                              { attrs: { width: "150px" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "pointer" },
                                                  [_vm._v("Date")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { width: "150px" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "pointer" },
                                                  [_vm._v("Auteur")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { width: "100px" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "pointer" },
                                                  [_vm._v("Avatar")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { width: "70px" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "pointer" },
                                                  [_vm._v("Type")]
                                                )
                                              ]
                                            ),
                                            _c("th", [
                                              _c(
                                                "span",
                                                { staticClass: "pointer" },
                                                [_vm._v("Message")]
                                              )
                                            ]),
                                            _c(
                                              "th",
                                              { attrs: { width: "70px" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "pointer" },
                                                  [_vm._v("Visible")]
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "tds",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .$moment(item.me_date)
                                                    .format("DD/MM/YYYY")
                                                )
                                              )
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(item.me_pseudo))
                                            ]),
                                            _c("td", [
                                              _c("div", {
                                                staticClass: "container-avatar",
                                                style:
                                                  "background-image:url(" +
                                                  _vm.$config.server_url +
                                                  item.me_avatar +
                                                  ")"
                                              })
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(item.me_type))
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(item.me_text))
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  item.me_invisible
                                                    ? "Non"
                                                    : "Oui"
                                                )
                                              )
                                            ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1494743576
                                  )
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _c("modal-schedule-message", {
                        attrs: { row_en: _vm.row_en },
                        model: {
                          value: _vm.scheduleMessage,
                          callback: function($$v) {
                            _vm.scheduleMessage = $$v
                          },
                          expression: "scheduleMessage"
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "card" }, [
                    _vm.$store.state.user.us_type === "LATEAM"
                      ? _c("div", { staticClass: "uppercase bis mb-3" }, [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.$store.state.user.us_type === "LATEAM",
                                  expression:
                                    "$store.state.user.us_type === 'LATEAM'"
                                }
                              ],
                              staticClass: "number-title"
                            },
                            [_vm._v("5")]
                          ),
                          _vm._v("Les actions ")
                        ])
                      : _vm._e(),
                    _vm.$store.state.user.us_type !== "LATEAM"
                      ? _c("div", { staticClass: "uppercase bis mb-3" }, [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.$store.state.user.us_type === "LATEAM",
                                  expression:
                                    "$store.state.user.us_type === 'LATEAM'"
                                }
                              ],
                              staticClass: "number-title"
                            },
                            [_vm._v("5")]
                          ),
                          _vm._v("Commentaire ")
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "row d-flex flex-row align-items-end mb-2"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-md-6 col-lg-7 col-xl-8 d-flex flex-column"
                          },
                          [
                            _vm.dialogAlertAction
                              ? _c("div", { staticClass: "col" }, [
                                  _c(
                                    "p",
                                    { staticClass: "alert alert-danger" },
                                    [_vm._v(_vm._s(_vm.dialogAlertAction))]
                                  )
                                ])
                              : _vm._e(),
                            _c("m-form-text", {
                              staticClass: "ml-3",
                              attrs: {
                                type: "textarea",
                                rows: 1,
                                label:
                                  _vm.$store.state.user.us_type === "LATEAM"
                                    ? "Ajouter une action"
                                    : "Commentaire",
                                "label-position": "top",
                                name: "newCommentAction"
                              },
                              model: {
                                value: _vm.newCommentAction,
                                callback: function($$v) {
                                  _vm.newCommentAction = $$v
                                },
                                expression: "newCommentAction"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6 col-lg-5 col-xl-4" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary ml-2 mb-2",
                                on: { click: _vm.addCommentAction }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$store.state.user.us_type === "LATEAM"
                                        ? "Ajouter"
                                        : "Ajouter un commentaire"
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _c("div", { staticClass: "row align-items-end mb-2" }, [
                      _c("div", { staticClass: "col-md-5 col-lg-4" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-warning ml-3",
                            on: {
                              click: function($event) {
                                return _vm.loadActions("showList")
                              }
                            }
                          },
                          [
                            !_vm.showActions
                              ? _c("span", [_vm._v("Afficher")])
                              : _vm._e(),
                            _vm.showActions
                              ? _c("span", [_vm._v("Masquer")])
                              : _vm._e(),
                            _vm._v(" les actions ")
                          ]
                        )
                      ]),
                      _vm.$store.state.user.us_type === "LATEAM"
                        ? _c(
                            "div",
                            { staticClass: "col-md-4 col-lg-4" },
                            [
                              _c("m-form-select", {
                                attrs: {
                                  label: "Type d'action",
                                  name: _vm.$Utils.randomstring("ac_type"),
                                  items: _vm.listActions
                                },
                                model: {
                                  value: _vm.selectedActionType,
                                  callback: function($$v) {
                                    _vm.selectedActionType = $$v
                                  },
                                  expression: "selectedActionType"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.$store.state.user.us_type === "LATEAM"
                        ? _c(
                            "div",
                            { staticClass: "col-md-3 col-lg-4 mb-n2" },
                            [
                              _c("m-form-date", {
                                staticClass: "mx-1",
                                attrs: {
                                  label: "Date de l'action",
                                  name: _vm.$Utils.randomstring("ac_date"),
                                  formatInput: "DD/MM/YYYY HH:mm:ss",
                                  formatValue: "YYYY-MM-DD HH:mm:ss"
                                },
                                model: {
                                  value: _vm.actionDate,
                                  callback: function($$v) {
                                    _vm.actionDate = $$v
                                  },
                                  expression: "actionDate"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _vm.showActions
                      ? _c(
                          "div",
                          [
                            _c("m-list-simple", {
                              ref: "commandelist",
                              attrs: {
                                limit: 300,
                                dynamic: true,
                                items: _vm.actions,
                                "item-value": "ac_id",
                                total: _vm.action_length
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "ths",
                                    fn: function(ref) {
                                      return [
                                        _c(
                                          "th",
                                          { attrs: { width: "160px" } },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("Date")]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "th",
                                          { attrs: { width: "220px" } },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("Type")]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "th",
                                          { attrs: { width: "220px" } },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("Contact")]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "th",
                                          { attrs: { width: "220px" } },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("Utilisateur final")]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "th",
                                          { attrs: { width: "220px" } },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("User")]
                                            )
                                          ]
                                        ),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Infos")]
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "tds",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatDateTime")(
                                                item.createdAt
                                              )
                                            )
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(item.ac_type))
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatContactNameSimple")(
                                                item.co_id
                                              )
                                            )
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatEndUserName")(
                                                item.en_id
                                              )
                                            )
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatUserName")(
                                                item.us_id
                                              )
                                            )
                                          )
                                        ]),
                                        _c("td", [
                                          item.ac_comment
                                            ? _c("span", [
                                                _vm._v(_vm._s(item.ac_comment))
                                              ])
                                            : _vm._e(),
                                          item.ac_elapsedtime > 0
                                            ? _c("span", [
                                                _vm._v(
                                                  "Durée : " +
                                                    _vm._s(
                                                      Math.floor(
                                                        (item.ac_elapsedtime /
                                                          60) *
                                                          100
                                                      ) / 100
                                                    ) +
                                                    "mn"
                                                )
                                              ])
                                            : _vm._e()
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3041250532
                              )
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c("div", { staticClass: "card" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row flex-wrap justify-content-between"
                      },
                      [
                        _c("h6", [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.$store.state.user.us_type ===
                                      "LATEAM" ||
                                    _vm.$store.state.user.us_type ===
                                      "EMOTIVI_USER",
                                  expression:
                                    "$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
                                }
                              ],
                              staticClass: "number-title"
                            },
                            [_vm._v("2")]
                          ),
                          _vm._v("Utilisateur final ")
                        ]),
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mb-2",
                              on: { click: _vm.editEnduser }
                            },
                            [_vm._v("Modifier")]
                          )
                        ])
                      ]
                    ),
                    _c("div", [
                      _vm.row_en.en_linkello
                        ? _c("div", [
                            _c("span", { staticClass: "linkello" }, [
                              _vm._v("Associé à Linkello")
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(
                        " Nom complet : " + _vm._s(_vm.row_en.en_fullname) + " "
                      ),
                      _c("hr", { staticClass: "hr-mini" }),
                      _c("div", { staticClass: "float-right" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-warning btn-sm mt-2",
                            on: { click: _vm.sendInvitationEmail }
                          },
                          [_vm._v("Renvoyer")]
                        )
                      ]),
                      _vm._v(" Ident. webapp : "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(
                                _vm.row_en.en_webapp_login
                              )
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.row_en.en_webapp_login))]
                      ),
                      _c("br"),
                      _vm._v(" Pass webapp :"),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(
                                _vm.row_en.en_webapp_password
                              )
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.row_en.en_webapp_password))]
                      ),
                      _c("hr", { staticClass: "hr-mini" }),
                      _vm._v(" Code administrateur : "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.row_en.en_admincode))]
                      ),
                      _c("hr", { staticClass: "hr-mini" }),
                      _vm._v(
                        " Chambre : " +
                          _vm._s(_vm.row_en.en_room ? _vm.row_en.en_room : "") +
                          " "
                      ),
                      _c("br"),
                      _vm._v(" Statut box : "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          style: _vm.getColorStatus(_vm.row_en.en_state_box),
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$Utils.getTextFromState(
                                _vm.$store.state.items_en_state_box,
                                _vm.row_en.en_state_box
                              )
                            )
                          )
                        ]
                      ),
                      _c("br"),
                      _vm._v(" Statut frais de dossier/caution: "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          style: _vm.getColorStatus(
                            _vm.row_en.en_state_caution
                          ),
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$Utils.getTextFromState(
                                _vm.$store.state.items_en_state_caution,
                                _vm.row_en.en_state_caution
                              )
                            )
                          )
                        ]
                      ),
                      _c("br"),
                      _vm._v(" Statut abonnement: "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          style: _vm.getColorStatus(
                            _vm.row_en.en_state_subscription
                          ),
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$Utils.getTextFromState(
                                _vm.$store.state.items_en_state_subscription,
                                _vm.row_en.en_state_subscription
                              )
                            )
                          )
                        ]
                      ),
                      _c("br"),
                      _vm._v(" Statut colis: "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          style: _vm.getColorStatus(_vm.row_en.en_state_parcel),
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$Utils.getTextFromState(
                                _vm.$store.state.items_en_state_parcel,
                                _vm.row_en.en_state_parcel
                              )
                            )
                          )
                        ]
                      ),
                      _c("br"),
                      _vm._v(" Statut support: "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          style: _vm.getColorStatus(
                            _vm.row_en.en_state_support
                          ),
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$Utils.getTextFromState(
                                _vm.$store.state.items_en_state_support,
                                _vm.row_en.en_state_support
                              )
                            )
                          )
                        ]
                      ),
                      _c("br"),
                      _c("hr", { staticClass: "hr-mini" }),
                      _vm._v(
                        " Adresse Mac Box : " +
                          _vm._s(
                            _vm.row_en.bo_id &&
                              typeof _vm.row_en.bo_id === "object"
                              ? _vm.row_en.bo_id.bo_mac_address
                              : ""
                          )
                      ),
                      _c("br"),
                      _c("br")
                    ])
                  ]),
                  _c("div", { staticClass: "card" }, [
                    _c("h6", [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.$store.state.user.us_type === "LATEAM" ||
                                _vm.$store.state.user.us_type ===
                                  "EMOTIVI_USER",
                              expression:
                                "$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
                            }
                          ],
                          staticClass: "number-title"
                        },
                        [_vm._v("3")]
                      ),
                      _vm._v("Résumé ")
                    ]),
                    _c("div", { staticClass: "row mt-2" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("div", [
                          _vm._v(" Inscription le : "),
                          _c(
                            "span",
                            { staticClass: "color-emotivi-blue ml-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.row_en.infos.actionsFirstTime &&
                                      _vm.row_en.infos.actionsFirstTime
                                        .inscription
                                      ? _vm
                                          .$moment(
                                            _vm.row_en.infos.actionsFirstTime
                                              .inscription
                                          )
                                          .format("DD/MM/YYYY")
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("div", [
                          _vm._v(" Première install. le : "),
                          _c(
                            "span",
                            { staticClass: "color-emotivi-blue ml-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.row_en.infos.actionsFirstTime &&
                                      _vm.row_en.infos.actionsFirstTime.install
                                      ? _vm
                                          .$moment(
                                            _vm.row_en.infos.actionsFirstTime
                                              .install
                                          )
                                          .format("DD/MM/YYYY")
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("div", [
                          _vm._v(" Envoi colis le : "),
                          _c(
                            "span",
                            { staticClass: "color-emotivi-blue ml-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.row_en.infos.actionsFirstTime &&
                                      _vm.row_en.infos.actionsFirstTime
                                        .parcel_sended
                                      ? _vm
                                          .$moment(
                                            _vm.row_en.infos.actionsFirstTime
                                              .parcel_sended
                                          )
                                          .format("DD/MM/YYYY")
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("div", [
                          _vm._v(" Dernière connexion le : "),
                          _c(
                            "span",
                            { staticClass: "color-emotivi-blue ml-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.row_en.infos.actionsFirstTime &&
                                      _vm.row_en.infos.actionsFirstTime
                                        .connection
                                      ? _vm
                                          .$moment(
                                            _vm.row_en.infos.actionsFirstTime
                                              .connection
                                          )
                                          .format("DD/MM/YYYY")
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("div", [
                          _vm._v(" Réception colis le : "),
                          _c(
                            "span",
                            { staticClass: "color-emotivi-blue ml-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.row_en.infos.actionsFirstTime &&
                                      _vm.row_en.infos.actionsFirstTime
                                        .parcel_received
                                      ? _vm
                                          .$moment(
                                            _vm.row_en.infos.actionsFirstTime
                                              .parcel_received
                                          )
                                          .format("DD/MM/YYYY")
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("div", [
                          _vm._v(" Premier appel vidéo le : "),
                          _c(
                            "span",
                            { staticClass: "color-emotivi-blue ml-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.row_en.infos.actionsFirstTime &&
                                      _vm.row_en.infos.actionsFirstTime
                                        .videocall_firstSuccess
                                      ? _vm
                                          .$moment(
                                            _vm.row_en.infos.actionsFirstTime
                                              .videocall_firstSuccess
                                          )
                                          .format("DD/MM/YYYY")
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("div", [
                          _vm._v(" Premier appel audio le : "),
                          _c(
                            "span",
                            { staticClass: "color-emotivi-blue ml-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.row_en.infos.actionsFirstTime &&
                                      _vm.row_en.infos.actionsFirstTime
                                        .audiocall_firstSuccess
                                      ? _vm
                                          .$moment(
                                            _vm.row_en.infos.actionsFirstTime
                                              .audiocall_firstSuccess
                                          )
                                          .format("DD/MM/YYYY")
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("div", [
                          _vm._v(" Dernier appel le : "),
                          _c(
                            "span",
                            { staticClass: "color-emotivi-blue ml-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.row_en.infos.actionsFirstTime &&
                                      _vm.row_en.infos.actionsFirstTime
                                        .lastSuccessCall
                                      ? _vm
                                          .$moment(
                                            _vm.row_en.infos.actionsFirstTime
                                              .lastSuccessCall
                                          )
                                          .format("DD/MM/YYYY")
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("div", [
                          _vm._v(" VIDÉO/OK/VOIX/OK/MESSAGE "),
                          _c(
                            "span",
                            { staticClass: "color-emotivi-blue ml-2" },
                            _vm._l(_vm.row_en.infos.nbCalls, function(
                              number,
                              index
                            ) {
                              return _c("span", { key: index }, [
                                _vm._v(_vm._s(number) + " "),
                                index < _vm.row_en.infos.nbCalls.length - 1
                                  ? _c("span", [_vm._v("/ ")])
                                  : _vm._e()
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          : _vm._e()
      ]),
      _c("m-message-dialog", {
        attrs: { title: _vm.dialogMessageTitle, text: _vm.dialogMessageTxt },
        model: {
          value: _vm.dialogMessage,
          callback: function($$v) {
            _vm.dialogMessage = $$v
          },
          expression: "dialogMessage"
        }
      }),
      _c("m-select-dialog", {
        attrs: {
          text:
            "Il s'agit d'un abonnement SEPA, souhaitez vous définir l'état comme :",
          title: "Confirmation",
          selectValues: [
            { text: "En attente d'annulation", value: 1 },
            {
              text: "Inactif (abonnement déjà arrêté auprés de la banque)",
              value: 0
            }
          ],
          width: "750px",
          height: "350px"
        },
        on: {
          canceled: function($event) {
            _vm.dialogConfirmCancelSubscriptionRib = false
          },
          confirmed: function($event) {
            this$1.dialogConfirmCancelSubscriptionRib = false
            this$1.cancelSubscriptionData.su_state = $event
            this$1.dialogConfirmSendEmailCancelSubscription = true
          }
        },
        model: {
          value: _vm.dialogConfirmCancelSubscriptionRib,
          callback: function($$v) {
            _vm.dialogConfirmCancelSubscriptionRib = $$v
          },
          expression: "dialogConfirmCancelSubscriptionRib"
        }
      }),
      _c("m-confirm-dialog", {
        attrs: {
          text: "Souhaitez-vous résilier cet abonnement ?",
          title: "Confirmation",
          width: "500px"
        },
        on: {
          canceled: function($event) {
            _vm.dialogConfirmCancelSubscription = false
          },
          confirmed: function() {
            this$1.dialogConfirmCancelSubscription = false
            this$1.dialogConfirmSendEmailCancelSubscription = true
          }
        },
        model: {
          value: _vm.dialogConfirmCancelSubscription,
          callback: function($$v) {
            _vm.dialogConfirmCancelSubscription = $$v
          },
          expression: "dialogConfirmCancelSubscription"
        }
      }),
      _c("m-confirm-dialog", {
        attrs: {
          text: "Souhaitez-vous rembourser ce paiement ?",
          title: "Confirmation",
          width: "500px"
        },
        on: {
          canceled: function($event) {
            _vm.dialogConfirmCancelCharge = false
          },
          confirmed: function($event) {
            return _vm.cancelChargeConfirmed()
          }
        },
        model: {
          value: _vm.dialogConfirmCancelCharge,
          callback: function($$v) {
            _vm.dialogConfirmCancelCharge = $$v
          },
          expression: "dialogConfirmCancelCharge"
        }
      }),
      _c("m-confirm-dialog", {
        ref: "dialogConfirmStartSubscription",
        attrs: {
          text: _vm.dialogConfirmStartSubscriptionText,
          title: "Confirmation",
          width: "600px"
        },
        on: {
          canceled: function($event) {
            _vm.dialogConfirmStartSubscription = false
          },
          confirmed: function($event) {
            return _vm.startSubscription()
          }
        },
        model: {
          value: _vm.dialogConfirmStartSubscription,
          callback: function($$v) {
            _vm.dialogConfirmStartSubscription = $$v
          },
          expression: "dialogConfirmStartSubscription"
        }
      }),
      _c("m-confirm-dialog", {
        attrs: {
          text: "Souhaitez-vous prêter le kit ?",
          title: "Confirmation",
          width: "500px"
        },
        on: {
          canceled: function($event) {
            _vm.dialogConfirmStartLoan = false
          },
          confirmed: function($event) {
            return _vm.startLoan()
          }
        },
        model: {
          value: _vm.dialogConfirmStartLoan,
          callback: function($$v) {
            _vm.dialogConfirmStartLoan = $$v
          },
          expression: "dialogConfirmStartLoan"
        }
      }),
      _c("m-confirm-dialog", {
        attrs: {
          text: "Le kit a t'il été restitué ?",
          title: "Confirmation",
          width: "500px"
        },
        on: {
          canceled: function($event) {
            _vm.dialogConfirmStopLoan = false
          },
          confirmed: function($event) {
            return _vm.stopLoan()
          }
        },
        model: {
          value: _vm.dialogConfirmStopLoan,
          callback: function($$v) {
            _vm.dialogConfirmStopLoan = $$v
          },
          expression: "dialogConfirmStopLoan"
        }
      }),
      _c("m-confirm-dialog", {
        attrs: {
          text:
            "Souhaitez-vous déclarer le contact en attente de remboursement ? Vous avez également la possibilité de rembourser directement le contact en cliquant sur la poubelle de la ligne 'Paiements'",
          title: "Confirmation",
          width: "500px"
        },
        on: {
          canceled: function($event) {
            _vm.dialogConfirmWaitingRefund = false
          },
          confirmed: function($event) {
            return _vm.startWaitingRefund()
          }
        },
        model: {
          value: _vm.dialogConfirmWaitingRefund,
          callback: function($$v) {
            _vm.dialogConfirmWaitingRefund = $$v
          },
          expression: "dialogConfirmWaitingRefund"
        }
      }),
      _c("m-confirm-dialog", {
        attrs: {
          text: "Souhaitez vous envoyer un email de résiliation au contact.",
          title: "Annuler l'abonnement",
          width: "500px",
          cancelText: "Non",
          successText: "Oui"
        },
        on: {
          canceled: function() {
            this$1.cancelSubscriptionData.en_send_cancel_sub_email = 0
            this$1.dialogConfirmSendEmailCancelSubscription = false
            /** if it's a RIB (no gocardless) cancellation and this.cancelSubscriptionData.su_state === 1 (waiting for cancelation) then do not display next alert that ask to user to cancel subscription now or at next programmed charge*/
            if (
              this$1.cancelSubscriptionData.hasOwnProperty("su_state") &&
              this$1.cancelSubscriptionData.su_state === 1
            ) {
              this$1.cancelSubscription("")
            } else {
              this$1.dialogConfirmCancelSubscriptionNow = true
            }
          },
          confirmed: function() {
            this$1.cancelSubscriptionData.en_send_cancel_sub_email = 1
            this$1.dialogConfirmSendEmailCancelSubscription = false
            /** if it's a RIB (no gocardless) cancellation and this.cancelSubscriptionData.su_state === 1 (waiting for cancelation) then do not display next alert that ask to user to cancel subscription now or at next programmed charge*/
            if (
              this$1.cancelSubscriptionData.hasOwnProperty("su_state") &&
              this$1.cancelSubscriptionData.su_state === 1
            ) {
              this$1.cancelSubscription("")
            }
            this$1.dialogConfirmCancelSubscriptionNow = true
          }
        },
        model: {
          value: _vm.dialogConfirmSendEmailCancelSubscription,
          callback: function($$v) {
            _vm.dialogConfirmSendEmailCancelSubscription = $$v
          },
          expression: "dialogConfirmSendEmailCancelSubscription"
        }
      }),
      _c("m-confirm-dialog", {
        attrs: {
          text:
            "Souhaitez vous annuler l'abonnement immédiatement ? Si oui, la date de fin sera enregistrée à ce jour, sinon à la fin de la période de facturation.",
          title: "Annuler l'abonnement",
          width: "500px",
          cancelText: "Non",
          successText: "Oui"
        },
        on: {
          canceled: function($event) {
            return _vm.cancelSubscription("")
          },
          confirmed: function($event) {
            return _vm.cancelSubscription("?now=ok")
          }
        },
        model: {
          value: _vm.dialogConfirmCancelSubscriptionNow,
          callback: function($$v) {
            _vm.dialogConfirmCancelSubscriptionNow = $$v
          },
          expression: "dialogConfirmCancelSubscriptionNow"
        }
      }),
      _c("parcel-win-edit", {
        attrs: { en_id: _vm.row_en.en_id, pa_id: _vm.parcelSelected.pa_id },
        on: { parcelSaved: _vm.parcelSaved },
        model: {
          value: _vm.dialogParcel,
          callback: function($$v) {
            _vm.dialogParcel = $$v
          },
          expression: "dialogParcel"
        }
      }),
      _vm.dialogBoxe
        ? _c("boxe-win-edit", {
            attrs: { row_en: _vm.row_en, row_bo: _vm.row_bo },
            on: { saved: _vm.boxeSaved, canceled: _vm.boxeCancel },
            model: {
              value: _vm.dialogBoxe,
              callback: function($$v) {
                _vm.dialogBoxe = $$v
              },
              expression: "dialogBoxe"
            }
          })
        : _vm._e(),
      _c("boxe-win-display-logs", {
        attrs: { row_en: _vm.row_en },
        on: { canceled: _vm.boxeCancel },
        model: {
          value: _vm.dialogBoxeLog,
          callback: function($$v) {
            _vm.dialogBoxeLog = $$v
          },
          expression: "dialogBoxeLog"
        }
      }),
      _c("contact-win-edit", {
        attrs: { co_id: _vm.row_co.co_id },
        on: { saved: _vm.reloadEnduser, canceled: _vm.cancelWin },
        model: {
          value: _vm.dialogContact,
          callback: function($$v) {
            _vm.dialogContact = $$v
          },
          expression: "dialogContact"
        }
      }),
      _c("enduser-win-edit", {
        attrs: { en_id: _vm.row_en.en_id, bo_id: _vm.boxId },
        on: {
          winEditSaved: _vm.reloadEnduser,
          winEditCanceled: _vm.cancelWin,
          winEditDeleted: _vm.deletedEnduser
        },
        model: {
          value: _vm.dialogEnduser,
          callback: function($$v) {
            _vm.dialogEnduser = $$v
          },
          expression: "dialogEnduser"
        }
      }),
      _vm.$store.getters.checkUserAccess(_vm.$router, "/invoices")
        ? _c("attestation-fiscale", {
            attrs: { row_fisc: _vm.attestations[0], row_en: _vm.row_en },
            on: {
              winRequestAttestationCanceled: _vm.cancelWinRequestAttestation
            },
            model: {
              value: _vm.displayFormAttestationFiscale,
              callback: function($$v) {
                _vm.displayFormAttestationFiscale = $$v
              },
              expression: "displayFormAttestationFiscale"
            }
          })
        : _vm._e(),
      _vm.selectedPayment
        ? _c("payment-details", {
            attrs: { payment: _vm.selectedPayment },
            on: {
              winClose: function($event) {
                return _vm.closeWin("displayPaymentDetails")
              }
            },
            model: {
              value: _vm.displayPaymentDetails,
              callback: function($$v) {
                _vm.displayPaymentDetails = $$v
              },
              expression: "displayPaymentDetails"
            }
          })
        : _vm._e(),
      _c("m-message-dialog", {
        attrs: { title: "Envoyé", text: "Email Colissimo envoyé" },
        on: {
          close: function($event) {
            _vm.mailParcelSend = false
          }
        },
        model: {
          value: _vm.mailParcelSend,
          callback: function($$v) {
            _vm.mailParcelSend = $$v
          },
          expression: "mailParcelSend"
        }
      }),
      _c("m-message-dialog", {
        attrs: {
          title: "Messages effacés",
          text: "Tous les messages ont été effacés"
        },
        on: {
          close: function($event) {
            _vm.deletedAllMessages = false
          }
        },
        model: {
          value: _vm.deletedAllMessages,
          callback: function($$v) {
            _vm.deletedAllMessages = $$v
          },
          expression: "deletedAllMessages"
        }
      }),
      _vm.displayVideoCall && _vm.row_bo
        ? _c("video-call", {
            attrs: {
              row_bo: _vm.row_bo,
              en_id: _vm.row_en.en_id,
              type: _vm.callType
            },
            on: { hangup: _vm.videoCallHangup },
            model: {
              value: _vm.dialogVideoCall,
              callback: function($$v) {
                _vm.dialogVideoCall = $$v
              },
              expression: "dialogVideoCall"
            }
          })
        : _vm._e(),
      _c("cancel-charge", {
        attrs: {
          maxvalue: (this.valueChargeAmount - this.valueChargeRefunded) / 100
        },
        on: { amountCancelChargeChanged: _vm.cancelCharge },
        model: {
          value: _vm.dialogCancelCharge,
          callback: function($$v) {
            _vm.dialogCancelCharge = $$v
          },
          expression: "dialogCancelCharge"
        }
      }),
      _c("div", { staticStyle: { display: "none" } }, [
        _c("canvas", { ref: "canvas", attrs: { width: "1024", height: "768" } })
      ]),
      _c("popover", { ref: "popover" })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-9" }, [
        _c("h3", {}, [_vm._v("Informations commerciales")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-9" }, [
        _c("h3", {}, [_vm._v("Informations techniques")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "fileContainer d-flex justify-content-center",
        attrs: { for: "upload" }
      },
      [
        _c("div", { staticClass: "align-self-center" }, [
          _vm._v("Choisir un fichier")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }