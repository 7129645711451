<template>
  <div
    class="modal"
    :class="value ? 'show' : 'hide'"
    :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable event-editor" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex flex-row align-items-center">
          <div>
            <h1 v-if="event_id === -1">Planifier un événement</h1>
            <h1 v-else>Modifier un événement</h1>
          </div>
        </div>
        <div class="modal-body">
          <h6><span class="number-title mb-2">1</span>Sélection des boxes</h6>
          <div class="m-2">
            <div class="row">
              <m-form-checkbox
                label="Sélectionner toutes les boxes"
                :name="$Utils.randomstring('sélection-boxes')"
                v-model="allBoxesSelected"
              ></m-form-checkbox>
              <m-form-text
                class="col-4 m-auto"
                width="200px"
                label="Rechercher"
                v-model="filterText"
                name="search"
                autocomplete
                @input="loadBoxes"
              ></m-form-text>
            </div>
            <div :style="'min-height: 300px;'" class="flex-grow-1 d-flex flex-column position-relative mt-2">
              <div style="overflow: auto; position: absolute; top: 0; left: 0; right: 0; bottom: 0" class="card card-no-margin">
                <m-list-simple
                  ref="boxeslist"
                  :dynamic="false"
                  :items="selectConnectedBoxes ? connectedBoxes : boxes"
                  item-value="en_id"
                  :item-height="30"
                  :limit="10000"
                  :current="boxes_current"
                  :bgstrip="true"
                  @changerange="boxesChangerange"
                >
                  <template v-slot:ths="{}">
                    <th style="width: 20px">
                      <icon width="18" height="18" name="check" class="text-info"></icon>
                    </th>
                    <th style="width: 120px">
                      <span class="pointer">état</span>
                    </th>

                    <th @click="sortItems(['en_fullname'])">
                      <span class="pointer">NOM Prénom</span>
                    </th>
                  </template>
                  <template v-slot:tds="{ item }">
                    <td style="width: 20px">
                      <div class="d-flex flex-row justify-content-center">
                        <input type="checkbox" @click="selectBox(item, $event)" :checked="selectedBoxes.includes(item.bo_id.bo_id) ? true : false" />
                        <!-- <icon
                            width="20"
                            height="20"
                            :name="selectedBoxes.includes(item.bo_id.bo_id) ? 'check' : 'times'"
                            :class="selectedBoxes.includes(item.bo_id.bo_id) ? 'text-info' : 'text-secondary'"
                          ></icon> -->
                        <!-- </div> -->
                      </div>
                    </td>
                    <td
                      :class="{
                        mygreen: item.bo_id && $store.state.connectedBoxes.indexOf(item.bo_id.bo_mac_address) >= 0,
                        myred: !item.bo_id || $store.state.connectedBoxes.indexOf(item.bo_id.bo_mac_address) < 0
                      }"
                    >
                      {{ item.bo_id && $store.state.connectedBoxes.indexOf(item.bo_id.bo_mac_address) >= 0 ? "Connectée" : "Non connectée" }}
                    </td>
                    <td>
                      {{ item.en_fullname }}
                    </td>
                  </template>
                </m-list-simple>
              </div>
            </div>
          </div>
          <h6><span class="number-title mt-2 mb-2">2</span>Evenement</h6>
          <div class="event-form m-2">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <label for="event-start-date" class="mb-0" style="width: 110px">Date début :</label>
                <input type="datetime-local" id="event-start-date" name="event-start-date" class="datetime mr-4" v-model="event.ce_startTime" />
              </div>
              <div>
                <label for="event-end-date" class="text-right pr-2 mb-0" style="width: 110px">Date fin :</label>
                <input type="datetime-local" id="event-end-date" name="event-end-date" class="datetime" v-model="event.ce_endTime" />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-lg-auto col-md-12 pr-0 mt-1" style="width: 110px">Activité :</div>
              <div class="col">
                <div style="position: relative">
                  <div class="activities-selecter" style="cursor: pointer" @click="selectActivities = !selectActivities">
                    <span>{{ event.ca_id ? savedActivities.find(act => act.value === event.ca_id).text : "Selectionner une activité" }}</span>
                  </div>
                  <div v-if="selectActivities" class="activities-container w-100">
                    <div class="row activities-list m-0">
                      <div v-if="savedActivities.length > 0" class="col p-0">
                        <div v-for="act in savedActivities" :key="act.value" class="activity-item">
                          <div class="row m-0">
                            <div class="col-auto pr-0 m-1 mt-2 justify-content-bottom">
                              <div class="activity-color-preview" :style="{ backgroundColor: act.color }"></div>
                            </div>
                            <div class="col m-1 pl-0" @click="setActivity(act.value)">
                              {{ act.text }}
                            </div>
                            <div class="col-auto pr-0">
                              <span class="delete-activity" @click="deleteActivity(act.value)">Supprimer</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else class="col p-3 text-center">
                        <span style="color: grey; font-style: italic">Créer une activité et définir une couleur associée.</span>
                      </div>
                    </div>
                    <hr class="m-0" />
                    <div class="row activities-add-container pl-3">
                      <div class="col-auto activity-input-color">
                        <input type="color" id="favcolor" name="favcolor" v-model="newActivity.color" />
                      </div>
                      <div class="col pl-0">
                        <m-form-text
                          :name="$Utils.randomstring('newActivity')"
                          placeholder="Nouvelle activité"
                          v-model="newActivity.title"
                        ></m-form-text>
                      </div>
                      <div class="col-auto pl-0">
                        <button
                          type="button"
                          class="btn btn-primary mr-3 p-0 pr-2 pl-2"
                          style="height: 30px; margin-top: 15px"
                          @click="addActivity()"
                        >
                          Ajouter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="row mt-2">
              <div class="col-auto pr-0">
                <span>Rappels ({{ reminders.length }}/2)</span>
              </div>
              <div class="col-auto">
                <button
                  type="button"
                  class="btn btn-primary pt-0 pb-0"
                  style="width: auto; height: 25px"
                  @click="addReminder()"
                  :disabled="reminders.length >= 2"
                >
                  Ajouter un rappel
                </button>
              </div>
            </div>
            <div v-for="(reminder, re_index) in reminders" :key="re_index">
              <div class="row align-items-center" style="height: 55px">
                <div class="col-auto pr-1">
                  <span>Envoyer un {{ re_index == 0 ? "premier" : "second" }} rappel </span>
                </div>
                <div class="col-auto p-1">
                  <m-form-text
                    type="number"
                    max="60"
                    min="5"
                    v-model="reminders[re_index]"
                    :name="$Utils.randomstring('ringingNumber')"
                    style="width: 60px"
                  ></m-form-text>
                </div>
                <div class="col p-0">
                  <span>minutes avant l'heure.</span>
                </div>
                <div class="col-auto">
                  <icon height="20px" width="20px" name="trash-alt" class="pointer" @click="removeReminder(re_index)"></icon>
                </div>
              </div>
            </div>
          </div>
          <div v-if="event_id === -1 && !eventDurationExceedOneDay">
            <h6><span class="number-title mt-2">3</span>Récurrence</h6>
            <div class="m-2">
              <div class="row align-items-baseline">
                <div class="col-auto" style="width: 200px">
                  <p>Type de récurrence :</p>
                </div>
                <div class="col">
                  <m-form-select
                    v-model="selectedRecurrence"
                    :name="$Utils.randomstring('establishments')"
                    :items="recurrenceList"
                    :clearable="false"
                  ></m-form-select>
                </div>
              </div>
              <div v-if="selectedRecurrence !== 0" class="d-flex align-items-baseline">
                <div class="col p-0">
                  <label for="event-end-date" class="text-right pr-2 mb-0">Répéter l'événement jusqu'au : </label>
                  <input type="date" id="event-end-date" name="event-end-date" class="datetime" lang="fr-FR" v-model="recurrenceEndDate" />
                </div>
              </div>
              <div v-if="selectedRecurrence === 2" class="mt-3">
                <div class="row justify-content-center text-center">
                  <div class="col-auto p-0">
                    <p style="width: 115px">Répétition :</p>
                  </div>
                  <div v-for="(day, index) in recurrenceDays" :key="index" class="col">
                    <p style="font-weight: bold; margin: 0">{{ day.dayName }}</p>
                    <m-form-checkbox :name="$Utils.randomstring('sélection-boxes')" v-model="day.selected"></m-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="eventDurationExceedOneDay">
            <h6><span class="number-title mt-2">3</span>Récurrence</h6>
            <div class="alert alert-warning">Cet événement ne peut pas être récurrent, sa durée étant égale ou supérieure à une journée complète</div>
          </div>
          <div v-if="recurrentEvents.length > 1">
            <h6><span class="number-title mt-2">3</span>Récurrence</h6>
            <div class="alert alert-warning">Cet événement est déjà récurrent</div>
          </div>
        </div>
        <div class="modal-footer d-flex flex-row justify-content-between">
          <div>
            <button v-if="event_id != -1" type="button" class="btn btn-danger" @click="deleteEvent">Supprimer l'événement</button>
          </div>
          <div>
            <button type="button" class="btn btn-secondary" @click="cancelWin">Annuler</button>
            <button type="button" class="btn btn-primary ml-2" @click="saveWin">Enregistrer</button>
          </div>
        </div>
      </div>
    </div>
    <m-confirm-dialog
      v-model="dialogConfirmReminderDelete"
      text="Souhaitez-vous vraiment supprimer ce rappel ?"
      title="Supprimer un rappel"
      width="500px"
      @canceled="dialogConfirmReminderDelete = false"
      @confirmed="
        () => {
          this.dialogConfirmReminderDelete = false;
          this.reminders.splice(this.tempDeleteReminder, 1);
        }
      "
    ></m-confirm-dialog>
    <modal-event-delete
      v-if="modalEventDelete"
      v-model="modalEventDelete"
      :event_uuid="event.ce_group_uuid"
      :event_id="event_id"
      @deletedEvent="$emit('reloadEvents', true)"
    ></modal-event-delete>
    <modal-event-changes
      v-if="modalEventChanges"
      v-model="modalEventChanges"
      :eventsChanges="eventsChanges"
      @reloadEvents="$emit('reloadEvents', true)"
    ></modal-event-changes>
  </div>
</template>
<script>
export default {
  name: "ModalEventEditor",
  props: {
    event_id: {
      default: 0,
      type: Number
    },
    es_id: {
      default: 0,
      type: Number
    },
    recurrentEvents: {
      type: Array
    },
    dateStr: {
      default: "",
      type: String
    },
    value: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      event: {},
      oldStartDate: "",
      oldEndDate: "",
      reminders: [],
      establishments: [],
      selectedEstablishment: 0,
      allBoxesSelected: false,
      savedActivities: [],
      selectActivities: false,
      newActivity: {
        title: "",
        color: "#6eccb2"
      },
      boxes_current: "",
      boxes: [],
      connectedBoxes: [],
      selectedBoxes: [],
      selectConnectedBoxes: false,
      sortOrder: "asc",
      sortFields: ["en_id"],
      recurrenceList: [
        {
          value: 0,
          text: "Aucune récurrence"
        },
        {
          value: 1,
          text: "Appliquer une récurrence tous les jours"
        },
        {
          value: 2,
          text: "Appliquer une récurrence personnalisée"
        }
      ],
      selectedRecurrence: 0,
      recurrenceExcludeWeekEnd: false,
      recurrenceEndDate: "",
      recurrenceDays: [
        {
          dayName: "Lun.",
          decimalValue: 1,
          selected: true
        },
        {
          dayName: "Mar.",
          decimalValue: 2,
          selected: true
        },
        {
          dayName: "Mer.",
          decimalValue: 3,
          selected: true
        },
        {
          dayName: "Jeu.",
          decimalValue: 4,
          selected: true
        },
        {
          dayName: "Ven.",
          decimalValue: 5,
          selected: true
        },
        {
          dayName: "Sam.",
          decimalValue: 6,
          selected: true
        },
        {
          dayName: "Dim.",
          decimalValue: 0,
          selected: true
        }
      ],
      modalEventDelete: false,
      modalEventChanges: false,
      dialogConfirmReminderDelete: false,
      eventDurationExceedOneDay: false,
      tempDeleteReminder: 0,
      eventsChanges: {
        events: [],
        recurrent: false,
        newStartDate: "",
        newEndDate: "",
        reminder_1: 0,
        reminder_2: 0,
        boxes: [],
        ca_id: 0
      },
      filterText: ""
    };
  },
  async mounted() {
    let establishments = this.$store.state.user.establishments.map(establishement => {
      return {
        text: establishement.es_name,
        value: establishement.es_id
      };
    });
    this.establishments = establishments;
    this.selectedEstablishment = 0;
    if (this.establishments.length) this.selectedEstablishment = this.establishments[0].value;
    await this.getSavedActivities();
    await this.getEvent(this.event_id);
    await this.loadBoxes();
  },
  watch: {
    selectedEstablishment(value) {
      this.loadBoxes();
    },
    allBoxesSelected(value) {
      if (value) this.selectedBoxes = this.boxes.map(box => box.bo_id.bo_id);
      else this.selectedBoxes = [];
    },
    event: {
      handler(newVal) {
        let startDate = this.$moment(this.event.ce_startTime);
        let endDate = this.$moment(this.event.ce_endTime);
        let duration = this.$moment.duration(endDate.diff(startDate));
        console.log(duration);
        if (duration.asHours() >= 24) this.eventDurationExceedOneDay = true;
        else this.eventDurationExceedOneDay = false;

        this.event.ce_startTime = startDate.format("YYYY-MM-DD HH:mm:ss");
        this.event.ce_endTime = endDate.format("YYYY-MM-DD HH:mm:ss");
      },
      deep: true
    }
  },
  computed: {},
  methods: {
    async getEvent(event_id) {
      try {
        let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/calendar/events/" + event_id);
        if (response.data.error) {
          this.$store.dispatch("showDialogError", response.data.err.message);
          return;
        }
        this.event = response.data.data;
        if (this.dateStr) {
          this.event.ce_startTime = this.dateStr;
          this.event.ce_endTime = this.$moment(this.event.ce_startTime).add(1, "hours").format("YYYY-MM-DD HH:mm:ss");
        }
        if (this.event_id !== -1) {
          this.oldStartDate = this.event.ce_startTime;
          this.oldEndDate = this.event.ce_endTime;
        }
        if (this.event.ce_reminder_1 !== 0) this.reminders.push(this.event.ce_reminder_1);
        if (this.event.ce_reminder_2 !== 0) this.reminders.push(this.event.ce_reminder_2);
        if (this.event.ce_boxes.length > 0) this.selectedBoxes = this.event.ce_boxes;
      } catch (error) {
        console.error(error);
      }
    },
    async deleteEvent() {
      this.modalEventDelete = true;
    }, // TO DO
    async getSavedActivities() {
      try {
        let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/calendar/activities", {
          params: {
            sort: "ca_activity asc"
          }
        });
        if (response.data.error) {
          this.$store.dispatch("showDialogError", response.data.err.message);
          return;
        }
        this.savedActivities = response.data.data.map(activity => {
          return {
            value: activity.ca_id,
            text: activity.ca_activity,
            color: activity.ca_color
          };
        });
      } catch (error) {
        console.error(error);
      }
    },
    async addActivity() {
      if (this.newActivity.title.length < 5) return;
      try {
        let response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/calendar/activities", {
          activity: this.newActivity.title,
          color: this.newActivity.color
        });
        if (response.data.error) {
          this.$store.dispatch("showDialogError", response.data.err.message);
          return;
        }
        await this.getSavedActivities();
        this.setActivity(this.savedActivities[0].value); // TO CHANGE
        this.newActivity.title = "";
        this.newActivity.color = "#6eccb2";
      } catch (error) {
        console.error(error);
      }
    },
    async deleteActivity(activityId) {
      try {
        let response = await this.$axios.delete(this.$config.server_url + "/extranet/2.0/activities/" + activityId);
        if (response.data.error) {
          this.$store.dispatch("showDialogError", response.data.err.message);
          return;
        }
        this.getSavedActivities();
      } catch (error) {
        console.error(error);
      }
    },
    async loadBoxes() {
      let types = [];
      let sort = this.sortFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.sortOrder;
        return accumulator;
      }, "");
      let params = {
        types: types,
        sort: sort,
        bo_id: ">0"
      };
      if (this.filterText) params.text = this.filterText; //TO DO
      if (this.es_id) params.establishment = this.es_id;

      this.cancelAxiosLoadContacts && this.cancelAxiosLoadContacts();
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/endusers", {
        params,
        cancelToken: new this.$axios.CancelToken(c => {
          this.cancelAxiosLoadContacts = c;
        })
      });
      if (!response || !response.data) return;

      this.boxes = response.data.data;

      this.connectedBoxes = this.boxes.filter(box => {
        return this.$store.state.connectedBoxes.includes(box.bo_id.bo_mac_address);
      });
      // this.$refs.boxeslist.update();
      // console.log("this.boxes", this.boxes);
    },
    displayRecurrenceDays() {},
    boxesChangerange(skip, scrollTop) {
      // console.log("skip, scrollTop", skip, scrollTop);
      this.boxes_skip = skip;
      this.loadBoxes();
    },
    selectBox(box, event) {
      // if exist remove from the list of selected boxes ( uncheck )
      let boxId = box.bo_id.bo_id;
      if (this.selectedBoxes.includes(boxId)) this.selectedBoxes.splice(this.selectedBoxes.indexOf(boxId), 1);
      else this.selectedBoxes.push(boxId);
    },
    setActivity(activityId) {
      this.event.ca_id = activityId;
      this.selectActivities = false;
    },
    addReminder() {
      this.reminders.push(10);
    },
    removeReminder(re_index) {
      this.tempDeleteReminder = re_index;
      this.dialogConfirmReminderDelete = true;
    },
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    cancelWin() {
      this.$emit("input", false);
      this.$destroy();
    },
    async askForRecurrentEvents() {
      this.eventsChanges.events = this.recurrentEvents;
      this.eventsChanges.recurrent = true;
      this.eventsChanges.newStartDate = this.event.ce_startTime;
      this.eventsChanges.newEndDate = this.event.ce_endTime;
      this.eventsChanges.boxes = this.selectedBoxes;
      this.eventsChanges.activity = this.event.ca_id;
      if (this.reminders[0]) this.eventsChanges.reminder_1 = this.reminders[0];
      if (this.reminders[1]) this.eventsChanges.reminder_2 = this.reminders[1];
      this.modalEventChanges = true;
    },
    async saveWin() {
      let recurrenceEndDate = "";
      let todayDate = "";

      /**
       *  CHECK RULES :
       **/

      // Un event doit avoir un id pour l'activité
      if (!this.event.ca_id) return this.$store.dispatch("showDialogError", "Veuillez selectionner une activité");

      // Un event doit avoir une date valide
      if (this.event.ce_startTime === "0000-00-00 00:00:00") return this.$store.dispatch("showDialogError", "Veuillez selectionner une date valide");

      // La date de début ne peut pas être égale ou inférieure à la date de fin
      console.log(this.event.ce_startTime);
      console.log(this.event.ce_endTime);

      if (this.event.ce_startTime >= this.event.ce_endTime)
        return this.$store.dispatch("showDialogError", "Veuillez selectionner une date de fin supérieure à la date de début.");

      // Un rappel est de 5 minutes minimum avant l'heure initiale
      if (this.reminders[0] < 5 || this.reminders[1] < 5)
        return this.$store.dispatch("showDialogError", "Les rappels doivent au moins êtres de 5 minutes avant l'heure initiale");

      // Le deuxième rappel ne peut pas être après le premier rappel, et ne peut pas être égale au premier rappel
      if (this.reminders[0] <= this.reminders[1])
        return this.$store.dispatch("showDialogError", "Le deuxième rappel ne peut pas être égal ou supérieur au premier rappel");

      // Si le nombre de boxes sélectionnées est supérieur à 0
      if (this.selectedBoxes.length == 0) return this.$store.dispatch("showDialogError", "Veuillez sélectionner des boxes");

      // Si la récurrence "tous les jours" est sélectionné mais qu'aucune date de fin n'est appliquée
      // Si la récurrence "tous les jours" est sélectionné mais la date de fin est égale à la date d'aujourd'hui
      if (this.selectedRecurrence !== 0) {
        if (this.recurrenceEndDate === "") return this.$store.dispatch("showDialogError", "Veuillez sélectionner une date de fin pour la récurrence");

        recurrenceEndDate = this.$moment(this.recurrenceEndDate).startOf("day");
        todayDate = this.$moment(this.event.ce_startTime).startOf("day");
        console.log(recurrenceEndDate);
        console.log(todayDate);
        if (recurrenceEndDate.isSame(todayDate) || recurrenceEndDate.isBefore(todayDate))
          return this.$store.dispatch("showDialogError", "La date de fin de la récurrence ne peut pas être égale ou inférieur au jour même");
      }

      // Si la récurrence "personnalisée" est sélectionné mais aucun jour n'a été appliqué
      if (this.selectedRecurrence == 2) {
        let result = this.recurrenceDays.find(day => day.selected === true);
        if (!result) return this.$store.dispatch("showDialogError", "Veuillez sélectionner au moins un jour récurrent");
      }

      console.log(this.event.ce_recurrence_type);
      if (this.recurrentEvents.length > 1) {
        console.log("test : ", this.event.ce_recurrence_type);
        if (this.event.ce_recurrence_type === 2 && this.event_id !== -1) {
          console.log("test 2");
          let newDate = this.$moment(this.event.ce_startTime).format("YYYY-MM-DD");
          let oldStartDate = this.$moment(this.oldStartDate).format("YYYY-MM-DD");

          console.log(this.event);

          if (newDate !== oldStartDate) {
            this.eventsChanges.events = [];
            this.eventsChanges.events.push({
              backgroundColor: this.recurrentEvents[0].backgroundColor,
              end: this.oldEndDate,
              start: this.oldStartDate,
              extendedProps: {
                group_uuid: this.event.ce_group_uuid,
                recurrence_type: this.event.ce_recurrence_type
              },
              id: this.event.ce_id,
              title: this.event.ce_title
            });
            this.eventsChanges.recurrent = false;
            this.eventsChanges.newStartDate = this.event.ce_startTime;
            this.eventsChanges.newEndDate = this.event.ce_endTime;
            this.eventsChanges.boxes = this.selectedBoxes;
            this.eventsChanges.activity = this.event.ca_id;
            if (this.reminders[0]) this.eventsChanges.reminder_1 = this.reminders[0];
            if (this.reminders[1]) this.eventsChanges.reminder_2 = this.reminders[1];
            this.modalEventChanges = true;
            return;
          } else {
            this.askForRecurrentEvents();
            return;
          }
        } else {
          this.askForRecurrentEvents();
          return;
        }
      }

      /**
       * BUILD EVENT OBJECT
       */

      // Sort recurrence dates
      let dates = [];
      let dateIndex = todayDate;
      let selectedDays = [];

      if (this.selectedRecurrence === 2) {
        selectedDays = this.recurrenceDays.filter(day => day.selected);
      }

      if (this.selectedRecurrence !== 0) {
        while (dateIndex.isSameOrBefore(recurrenceEndDate)) {
          if (this.selectedRecurrence === 1) dates.push(dateIndex.clone().format("YYYY-MM-DD"));
          if (this.selectedRecurrence === 2) {
            selectedDays.forEach(day => {
              if (dateIndex.day() === day.decimalValue) dates.push(dateIndex.clone().format("YYYY-MM-DD"));
            });
          }
          dateIndex.add(1, "days");
        }
      }

      console.log(dates);

      this.event.recurrenceDates = dates;

      // Adding reminders to the event
      if (this.reminders[0]) this.event.ce_reminder_1 = this.reminders[0];
      else this.event.ce_reminder_1 = 0;

      if (this.reminders[1]) this.event.ce_reminder_2 = this.reminders[1];
      else this.event.ce_reminder_2 = 0;

      // Adding selected boxes to the event
      this.event.ce_boxes = this.selectedBoxes;

      // Setting eventdate from datetime
      let startTime = new Date(this.event.ce_startTime);
      let date = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate());
      // console.log(date);

      this.event.ce_date = date;

      this.event.ce_recurrence_type = this.selectedRecurrence;
      this.event.us_id = this.$store.state.user.us_id;

      /**
       * SEND EVENT TO API
       */

      try {
        let method = "";
        let putUrl = "";

        if (this.event_id !== -1) {
          method = "put";
          putUrl = "/" + this.event_id;
        } else method = "post";

        let response = await this.$axios[method](this.$config.server_url + "/extranet/2.0/calendar/events" + putUrl, this.event);
        if (response.data.error) {
          this.$store.dispatch("showDialogError", response.data.err.message);
          return;
        }
        this.$emit("reloadEvents", true);
        this.cancelWin();
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "../scss/styles.scss";
.event-editor {
  max-width: 800px;
}
.datetime {
  height: 30px;
  width: 200px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  color: #6c757d;
}
.activities-selecter {
  border: 1px solid #ced4da;
  height: 30px;
  border-radius: 0.25rem;
  padding: 0.2rem 0.75rem 0.5rem 0.75rem;
  line-height: 1.5;
  color: #6c757d;
  font-size: 1rem;
  font-weight: 400;
  user-select: none;
}

.activities-container {
  border: 1px solid #ced4da;
  border-top: none;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  position: absolute;
  background-color: white;
  z-index: 2;
  margin-top: -5px;
  padding-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.activity-input-color {
  margin-top: 15px;
}

input[type="color"] {
  width: 30px;
  height: 30px;
  border: none;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
  border: none;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 0.35rem;
}
.activities-container hr {
  background-color: #ced4da;
}

.activities-list {
  max-height: 170px;
  overflow: auto;
}
.activity-color-preview {
  display: flex;
  height: 16px;
  width: 16px;
  background-color: red;
  border-radius: 0.25rem;
}
.activity-item {
  cursor: pointer;
  height: auto;
}

.activity-item:last-child {
  border-bottom: 0;
}

.activity-item:hover {
  background-color: #5897fb;
  color: white;
}

.delete-activity {
  height: 100%;
  display: block;
  padding: 5px 10px;
}

.delete-activity:hover {
  color: white;
  background-color: #dc3545;
}

.mygreen {
  color: green;
  font-weight: bold;
}

.myred {
  color: red;
}
</style>
