var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal",
      class: _vm.value ? "show" : "hide",
      style: _vm.value
        ? "display:block;background-color:#3333337a;"
        : "display:none;",
      attrs: { tabindex: "-1", role: "dialog", "aria-hidden": "true" }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-scrollable",
          attrs: { role: "document" }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-content",
              style: "width:" + _vm.width + ";height:" + _vm.height
            },
            [
              _c("div", { staticClass: "modal-header" }, [
                _c("h3", { staticClass: "pt-2 ml-3" }, [
                  _vm._v(_vm._s(_vm.title))
                ])
              ]),
              _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _c("p", [_vm._v(_vm._s(_vm.text))]),
                  _c("p", [_vm._v(_vm._s(_vm.sousText ? _vm.sousText : ""))]),
                  _c("m-form-select", {
                    attrs: {
                      width: 500,
                      label: "",
                      name: _vm.$Utils.randomstring("selectValues"),
                      items: _vm.selectValues
                    },
                    model: {
                      value: _vm.selectedValue,
                      callback: function($$v) {
                        _vm.selectedValue = $$v
                      },
                      expression: "selectedValue"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-reverse",
                    on: { click: _vm.cancelWin }
                  },
                  [_vm._v("Annuler")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: { click: _vm.deleteWin }
                  },
                  [_vm._v("Ok")]
                )
              ])
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }