<template>
  <section class="d-flex w-100">
    <div class="d-flex flex-column  w-100" id="boxes">
      <div class="d-flex header-emotivi">
        <div class="d-flex align-items-center">
          <h1>{{ products_total }} produits et services</h1>
        </div>
        <div class="flex-grow-1 d-flex justify-content-end align-items-end" style="margin-top:-40px;">
          <m-form-checkbox
            label="Non-actif"
            :name="$Utils.randomstring('filterNotActive')"
            v-model="filterNotActive"
            @input="loadProducts"
          ></m-form-checkbox>
          <m-form-text
            class="ml-3"
            label="Rechercher"
            label-position="top"
            v-model="filterText"
            name="search"
            autocomplete
            @input="loadProducts"
          ></m-form-text>
          <button class="ml-3 btn btn-sm btn-primary color-white" type="button" @click="addProducts">
            <icon name="plus" scale="1" class="mr-2"></icon> Nouveau
          </button>
        </div>
      </div>

      <div class="flex-grow-1 overflow-hidden d-flex w-100">
        <div class="card card-no-margin w-100">
          <m-list-simple
            ref="contactslist"
            :dynamic="true"
            :items="products"
            item-value="pr_id"
            :item-height="30"
            :limit="products_limit"
            :skip="products_skip"
            :total="products_total"
            @itemclick="editProducts"
          >
            <template v-slot:ths="{}">
              <th style="min-width:50px;" @click="sortItems([])">
                <span class="pointer">Désignation</span>
              </th>
              <th class="" @click="sortItems(['pr_email'])">
                <span class="pointer">Nb matériel</span>
              </th>
              <th class="" @click="sortItems(['pr_email'])">
                <span class="pointer">Nb stock possible</span>
              </th>
              <th @click="sortItems(['pr_post'])">
                <span class="pointer">Nb offres liées</span>
              </th>
              <th @click="sortItems(['pr_type'])">
                <span class="pointer">Type</span>
              </th>
            </template>
            <template v-slot:tds="{ item }">
              <td>
                {{ item.pr_name }}
              </td>
              <td>{{ item.productsmaterials.length }}</td>
              <td>{{ item.nb_stock_possible ? item.nb_stock_possible : "-" }}</td>
              <td>{{ item.offersproducts.length }}</td>
              <td>
                {{ item.pr_type }}
              </td>
            </template>
          </m-list-simple>
        </div>
      </div>
    </div>
    <products-win-edit :pr_id="itemSelected.pr_id" v-model="dialog" @savedWin="loadProducts"></products-win-edit>
  </section>
</template>

<script>
export default {
  name: "contacts",
  components: {},
  data() {
    return {
      products: [],
      products_total: 0,
      products_limit: 0,
      products_skip: 0,
      filterText: "",
      filterNotActive: false,
      sortOrder: "asc",
      sortFields: [],
      itemSelected: {},
      dialog: false
    };
  },
  watch: {},
  async mounted() {
    this.loadProducts();
  },
  computed: {},
  methods: {
    sortItems(fields) {
      if (fields.length && this.sortFields.length && fields[0] == this.sortFields[0]) {
        this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
      }
      this.sortFields = fields;
      if (fields[0] !== "pr_connection_date") {
        this.loadContacts();
      } else {
        this.patients.sort((a, b) => {
          if (this.sortOrder === "asc") {
            if (this.$moment(a.pr_connection_date).isBefore(b.pr_connection_date)) return -1;
            if (this.$moment(a.pr_connection_date).isAfter(b.pr_connection_date)) return 1;
            if (this.$moment(a.pr_connection_date).isSame(b.pr_connection_date)) return 0;
          } else {
            if (this.$moment(a.pr_connection_date).isBefore(b.pr_connection_date)) return 1;
            if (this.$moment(a.pr_connection_date).isAfter(b.pr_connection_date)) return -1;
            if (this.$moment(a.pr_connection_date).isSame(b.pr_connection_date)) return 0;
          }
        });
      }
    },
    async loadProducts() {
      let sort = this.sortFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.sortOrder;
        return accumulator;
      }, "");
      let params = {
        limit: this.patients_limit,
        notactive: this.filterNotActive,
        sort: sort
      };
      if (this.filterText) params.text = this.filterText;
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/products", { params });
      if (!response || !response.data) return;
      this.products = response.data.data;
      this.products_total = response.data.meta.total;
    },
    addProducts() {
      this.itemSelected = {};
      this.$router.push("/products/-1");
      this.dialog = true;
    },
    editProducts(item) {
      this.itemSelected = item;
      this.$router.push("/products/" + this.itemSelected.pr_id);
      this.dialog = true;
    },
    confirmDeleteProduct() {},
    deleteProduct() {},
    importProducts() {},
    exportProducts() {}
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";
.m-form-checkbox {
  margin-bottom: 0;
}
</style>
