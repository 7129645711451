<template>
  <section class="d-flex w-100">
    <div class="d-flex flex-column  w-100" id="boxes">
      <div class="d-flex header-emotivi">
        <div class="d-flex align-items-center">
          <h1>Les {{ actions_total }} actions</h1>
        </div>
      </div>
      <div class="flex-grow-1 overflow-hidden d-flex w-100">
        <div class="card card-no-margin w-100">
          <m-list
            ref="actionslist"
            :dynamic="true"
            :items="actions"
            item-value="ac_id"
            :item-height="30"
            :limit="actions_limit"
            :skip="actions_skip"
            :total="actions_total"
            :bgstrip="true"
            @changerange="actionsChangerange"
          >
            <template v-slot:ths="{}">
              <th style="width: 200px" @click="sortItems(['t1.createdAt'])">
                <span class="pointer">Date</span>
              </th>
              <th style="width: 400px" @click="sortItems(['ac_type'])">
                <span class="pointer">Type</span>
              </th>
              <th style="width: 200px" @click="sortItems(['t2.us_name', 't2.us_firstname'])">
                <span class="pointer">Us_id</span>
              </th>
              <th style="width: 200px" @click="sortItems(['t4.en_fullname'])">
                <span class="pointer">En_id</span>
              </th>
              <th style="width: 200px" @click="sortItems(['t3.co_invoice_name', 't3.co_invoice_firstname'])">
                <span class="pointer">Co_id</span>
              </th>
              <th>
                <span class="pointer">Infos</span>
              </th>
            </template>
            <template v-slot:tds="{ item }">
              <td>{{ $moment(item.createdAt).format("DD/MM/YYYY HH[H]mm") }}</td>
              <td>
                {{ item.ac_type }}
              </td>
              <td>
                <div v-if="item.us_id && item.us_id.us_id">
                  <router-link class="nav-link" :to="{ path: '/users/' + item.us_id.us_id }">{{
                    item.us_id | formatContactNameSimple("us_")
                  }}</router-link>
                </div>
              </td>
              <td>
                <div v-if="item.en_id && item.en_id.en_id">
                  <router-link class="nav-link" :to="{ path: '/boxes/' + item.en_id.en_id }">{{ item.en_id | formatEndUserName("en_") }}</router-link>
                </div>
              </td>
              <td>
                <div v-if="item.co_id && item.co_id.co_id">
                  <router-link class="nav-link" :to="{ path: '/contacts/' + item.co_id.co_id + '/details' }">{{
                    item.co_id | formatContactNameSimple("co_invoice_")
                  }}</router-link>
                </div>
              </td>
              <td>
                <span v-if="item.ac_comment">{{ item.ac_comment }}</span>
                <span v-if="item.ac_elapsedtime > 0">Durée : {{ Math.floor((item.ac_elapsedtime / 60) * 100) / 100 }}mn</span>
              </td>
            </template>
          </m-list>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "actions",
  components: {},
  data() {
    return {
      showActions: false,
      actions: [],
      sortOrder: "asc",
      sortFields: [],
      actions_limit: 50,
      actions_skip: 0,
      actions_total: 0
    };
  },
  mounted() {
    this.loadActions();
  },
  filters: {},
  methods: {
    actionsChangerange(skip, scrollTop) {
      this.actions_skip = skip;
      this.loadActions();
    },

    sortItems(fields) {
      // console.log("fields", fields);
      if (fields.length && this.sortFields.length && fields[0] == this.sortFields[0]) {
        this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
      }
      this.sortFields = fields;
      this.loadActions();
    },
    async loadActions() {
      let sort = this.sortFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.sortOrder;
        return accumulator;
      }, "");
      if (!sort) sort = "t1.createdAt desc";

      let params = { sort: sort, skip: this.actions_skip, limit: this.actions_limit };
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/actions", { params });
      this.actions = response.data.data;
      this.actions_total = response.data.meta.total;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";
#preferences {
  margin-bottom: 100px;
}
.img {
  background-color: #fff;
  padding: 5px 5px;
  border-radius: 50%;
  margin-right: 20px;
  .icon-gestion {
    color: #252525;
  }
  .img-fluid {
    width: 25px;
    height: 25px;
  }
}
.text {
  line-height: 29px;
}
a:hover {
  text-decoration: none;
}
.nav-link {
  padding: 0;
}
</style>
