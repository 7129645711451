<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div>
              <h1 v-if="tc_id === 0">Création du code {{ row_tc.tc_function }}</h1>
              <h1 v-else>Modification du code {{ row_tc.tc_function }} pour le modèle de télévision {{ modelTV.tt_name }}</h1>
            </div>
          </div>
          <div class="modal-body">
            <m-form-text
              label="Fonction *"
              type="text"
              v-model="row_tc.tc_function"
              :name="$Utils.randomstring('tc_function')"
              :rules="[$Validation.mandatory]"
            ></m-form-text>
            <div>
              <span class="more">i</span> <u>Cliquez sur la fonction pour l'utiliser:</u>
              <span v-for="item in functionsCodes" :key="item.value">
                <a href="javascript:void(0)" @click="usefunction(item.value)"> {{ item.value }}</a
                >,
              </span>
            </div>
            <m-form-text
              label="Hexcode *"
              type="text"
              v-model="row_tc.tc_hexcode"
              :name="$Utils.randomstring('tc_hexcode')"
              :rules="[$Validation.mandatory]"
            ></m-form-text>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between">
            <div>
              <button type="button" class="btn btn-danger" v-if="tc_id != 0" @click="deleteConfirmWin">
                Supprimer
              </button>
            </div>
            <div class="d-flex flex-row align-items-end">
              <button type="button" class="btn btn-secondary" @click="cancelWin">Annuler</button>
              <button type="button" class="btn btn-primary btn-mouveal ml-2" @click="saveWin">
                Sauvegarder
              </button>
            </div>
          </div>
        </div>
      </div>
      <m-message-dialog v-model="dialogErr" title="Erreur" :text="dialogErrTxt"></m-message-dialog>
      <m-confirm-dialog
        v-model="confirmdelete"
        :text="`Souhaitez-vous effacer le code ${row_tc.tc_function} dans le modèle de télévision ${modelTV.tt_name}  ?`"
        title="Confirmation"
        width="500px"
        @canceled="confirmdelete = false"
        @confirmed="deleteWin()"
      ></m-confirm-dialog>
    </div>
  </transition>
</template>

<script>
export default {
  name: "TvCodeWinEdit",
  components: {},
  props: {
    value: {
      default: false,
      type: Boolean
    },
    tc_id: {
      default: 0,
      type: Number
    },
    modelTV: {
      type: Object
    }
  },
  data() {
    return {
      row_tc: {
        tc_id: 0,
        tc_function: "SWITCH ON",
        tc_hexcode: ""
      },
      dialogErr: false,
      dialogErrTxt: "",
      avatar: null,
      confirmdelete: false,
      functionsCodes: [
        { value: "ANTENNA" },
        { value: "ANTENNA1" },
        { value: "ANTENNA2" },
        { value: "HDMI" },
        { value: "HDMI1" },
        { value: "HDMI2" },
        { value: "HDMI3" },
        { value: "HDMI4" },
        { value: "HDMI5" },
        { value: "HDMI6" },
        { value: "MUTE" },
        { value: "PREVIOUS CHANNEL" },
        { value: "SWITCH ON" },
        { value: "SWITCH OFF" },
        { value: "SOUND PLUS" },
        { value: "SOUND MINUS" },
        { value: "TV" },
        { value: "TV1" },
        { value: "TV2" }
      ]
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.getCode();
      }
    }
  },
  methods: {
    async getCode() {
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/tvcode/" + this.tc_id);
      if (response.data && response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }
      if (response.data && response.data.data) {
        this.row_tc = response.data && response.data.data ? response.data.data : {};
      } else {
        this.row_tc = { tc_id: 0, tc_function: "", tc_hexcode: "" };
      }
      // console.log("this.row_tc", this.row_tc);
    },
    async saveWin() {
      let err = [];
      let fieldRequired = [
        { field: "tc_function", text: "Fonction du code" },
        { field: "tc_hexcode", text: "Hexcode du code" }
      ];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.row_tc[field.field]) err.push(field);
      }
      if (err.length) {
        this.dialogErrTxt = "<span class='bis'>Vous devez remplir les champs : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error.text + " <br>";
        }
        this.dialogErr = true;
      } else {
        this.row_tc.tt_id = this.modelTV.tt_id;
        if (this.row_tc.tc_id) {
          await this.$axios.put(this.$config.server_url + "/extranet/2.0/tvcode", this.row_tc);
        } else {
          await this.$axios.post(this.$config.server_url + "/extranet/2.0/tvcode", this.row_tc);
        }
        this.$emit("winEditSaved", this.row_tc.tc_function);
      }
    },
    cancelWin() {
      this.$emit("winEditCanceled");
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteWin() {
      await this.$axios.delete(this.$config.server_url + "/extranet/2.0/tvcode/" + this.row_tc.tc_id);
      this.$emit("winEditDeleted", this.row_tc.tc_function, "code");

      this.confirmdelete = false;
    },
    async usefunction(functionname) {
      this.row_tc.tc_function = functionname;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";

.winedit-container-header {
  width: 100%;
  justify-content: space-between;
}
.input-avatar {
  padding: 0;
}
.modal {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.active {
  min-width: 150px;
  input {
    width: auto;
  }
}
/*************** table droits **************/
tr {
  border-bottom: 0.5px solid #ccc9c9;
}
td:first-child {
  text-align: left;
}
td,
th {
  border-right: 0.5px solid #ccc9c9;
  border-left: 0.5px solid #ccc9c9;
  padding: 5px 10px;
  text-align: center;
}
th {
  background-color: #013c62;
  color: #fff;
  text-transform: uppercase;
}
th.groupe {
  color: black;
}
.groupe {
  background-color: #b5b2b2;
}
.more {
  border-radius: 50%;
  background-color: #91295a;
  width: 30px;
  height: 30px;
  text-align: center;
  color: white;
  padding: 6px;
  font-weight: bold;
}
</style>
