var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: "spinner " + _vm.animatedSpinner,
      style: {
        display: _vm.displaySpinner,
        "background-color": _vm.backgroundColor,
        color: _vm.textColor,
        "--spinnerColor": _vm.spinnerColor
      }
    },
    [
      _vm._v(" " + _vm._s(_vm.textSpinner) + " "),
      _vm.textSpinner2
        ? _c("div", [_c("hr"), _c("p", [_vm._v(_vm._s(_vm.textSpinner2))])])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }