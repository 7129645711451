var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "div",
      {
        staticClass: "modal",
        class: _vm.value ? "show" : "hide",
        style: _vm.value
          ? "display:block;background-color:#3333337a;"
          : "display:none;",
        attrs: { tabindex: "-1", role: "dialog", "aria-hidden": "true" }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-scrollable",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(0),
              _vm.recurringEvents.length > 1
                ? _c("div", { staticClass: "modal-body" }, [
                    _vm._m(1),
                    _c("p", [
                      _vm._v(
                        "Liste des événements récurrents (" +
                          _vm._s(_vm.recurringEvents.length) +
                          ") :"
                      )
                    ]),
                    _c("div", { staticClass: "card pt-3" }, [
                      _vm._m(2),
                      _c("hr", { staticClass: "mt-2 mb-2" }),
                      _c(
                        "div",
                        { staticClass: "recurring-events-content" },
                        _vm._l(_vm.recurringEvents, function(event, index) {
                          return _c("div", { key: index }, [
                            _c(
                              "div",
                              {
                                class: [
                                  "row",
                                  "recurringEventsListElement",
                                  _vm.event_id === event.ce_id
                                    ? "eventTarget"
                                    : ""
                                ]
                              },
                              [
                                _c("div", { staticClass: "col-3" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.simplifyEventsDate(
                                          event.ce_startTime
                                        )
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("div", { staticClass: "col-3" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.simplifyEventsTime(
                                          event.ce_startTime,
                                          event.ce_endTime
                                        )
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("div", { staticClass: "col" }, [
                                  _vm._v(
                                    " " + _vm._s(event.ca_id.ca_activity) + " "
                                  )
                                ])
                              ]
                            ),
                            _c("hr", { staticClass: "m-0" })
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                : _c("div", { staticClass: "modal-body" }, [
                    _vm._m(3),
                    _c(
                      "div",
                      {
                        staticClass: "row",
                        staticStyle: { "font-weight": "600" }
                      },
                      [
                        _vm._m(4),
                        _c("div", { staticClass: "col-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.simplifyEventsDate(
                                  _vm.recurringEvents[0].ce_startTime
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c("div", { staticClass: "col-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.simplifyEventsTime(
                                  _vm.recurringEvents[0].ce_startTime,
                                  _vm.recurringEvents[0].ce_endTime
                                )
                              ) +
                              " "
                          )
                        ]),
                        _c("div", { staticClass: "col" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.recurringEvents[0].ca_id.ca_activity) +
                              " "
                          )
                        ])
                      ]
                    )
                  ]),
              _c(
                "div",
                {
                  staticClass:
                    "modal-footer d-flex flex-row justify-content-between"
                },
                [
                  _vm.recurringEvents.length > 1
                    ? _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger mr-3",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.deleteEvent()
                              }
                            }
                          },
                          [_vm._v("Supprimer cette occurence uniquement")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.deleteEvent("all")
                              }
                            }
                          },
                          [_vm._v("Supprimer toutes les occurences")]
                        )
                      ])
                    : _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.deleteEvent()
                              }
                            }
                          },
                          [_vm._v("Supprimer l'événement")]
                        )
                      ]),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button" },
                        on: { click: _vm.cancelWin }
                      },
                      [_vm._v("Annuler")]
                    )
                  ])
                ]
              )
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "modal-header d-flex flex-row align-items-center" },
      [_c("div", [_c("h1", [_vm._v("Suppression d'événement(s)")])])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "alert alert-danger text-center" }, [
      _c("span", [
        _vm._v(
          " Vous êtes sur le point de supprimer un événement qui possède plusieurs occurences. Souhaitez vous supprimer toutes ses occurences ou seulement cette occurence ? "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-3" }, [_c("b", [_vm._v("DATE")])]),
      _c("div", { staticClass: "col-3" }, [_c("b", [_vm._v("HORAIRES")])]),
      _c("div", { staticClass: "col" }, [_c("b", [_vm._v("ACTIVITE")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "alert alert-danger text-center" }, [
      _c("span", [
        _vm._v(" Êtes-vous sûr de vouloir supprimer cet événement ? ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-auto" }, [
      _c("p", [_vm._v("Evénement :")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }