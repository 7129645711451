<template>
  <section>
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div>
              <h1>Suppression d'événement(s)</h1>
            </div>
          </div>
          <div v-if="recurringEvents.length > 1" class="modal-body">
            <div class="alert alert-danger text-center">
              <span>
                Vous êtes sur le point de supprimer un événement qui possède plusieurs occurences. Souhaitez vous supprimer toutes ses occurences ou
                seulement cette occurence ?
              </span>
            </div>
            <p>Liste des événements récurrents ({{ recurringEvents.length }}) :</p>
            <div class="card pt-3">
              <div class="row">
                <div class="col-3">
                  <b>DATE</b>
                </div>
                <div class="col-3">
                  <b>HORAIRES</b>
                </div>
                <div class="col">
                  <b>ACTIVITE</b>
                </div>
              </div>
              <hr class="mt-2 mb-2" />
              <div class="recurring-events-content">
                <div v-for="(event, index) in recurringEvents" :key="index">
                  <div :class="['row', 'recurringEventsListElement', event_id === event.ce_id ? 'eventTarget' : '']">
                    <div class="col-3">
                      {{ simplifyEventsDate(event.ce_startTime) }}
                    </div>
                    <div class="col-3">
                      {{ simplifyEventsTime(event.ce_startTime, event.ce_endTime) }}
                    </div>
                    <div class="col">
                      {{ event.ca_id.ca_activity }}
                    </div>
                  </div>
                  <hr class="m-0" />
                </div>
              </div>
            </div>
          </div>
          <div v-else class="modal-body">
            <div class="alert alert-danger text-center">
              <span>
                Êtes-vous sûr de vouloir supprimer cet événement ?
              </span>
            </div>
            <div class="row" style="font-weight: 600">
              <div class="col-auto">
                <p>Evénement :</p>
              </div>
              <div class="col-3">
                {{ simplifyEventsDate(recurringEvents[0].ce_startTime) }}
              </div>
              <div class="col-3">
                {{ simplifyEventsTime(recurringEvents[0].ce_startTime, recurringEvents[0].ce_endTime) }}
              </div>
              <div class="col">
                {{ recurringEvents[0].ca_id.ca_activity }}
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between">
            <div v-if="recurringEvents.length > 1">
              <button type="button" class="btn btn-danger mr-3" @click="deleteEvent()">Supprimer cette occurence uniquement</button>
              <button type="button" class="btn btn-danger" @click="deleteEvent('all')">Supprimer toutes les occurences</button>
            </div>
            <div v-else>
              <button type="button" class="btn btn-danger" @click="deleteEvent()">Supprimer l'événement</button>
            </div>
            <div>
              <button type="button" class="btn btn-secondary" @click="cancelWin">Annuler</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ModalEventDelete",
  components: {},
  props: {
    value: {
      default: false,
      type: Boolean
    },
    event_id: {
      type: Number
    },
    event_uuid: {
      type: String
    }
  },
  data() {
    return {
      recurringEvents: [
        {
          ce_startTime: "",
          ce_endTime: "",
          ca_id: {
            ca_activity: ""
          }
        }
      ]
    };
  },
  async mounted() {
    this.recurringEvents = this.$store.state.calendar_events.filter(event => {
      return event.ce_group_uuid === this.event_uuid;
    });
    this.recurringEvents.sort((eventA, eventB) => {
      return new Date(eventA.ce_date) - new Date(eventB.ce_date);
    });
    console.log(this.recurringEvents);
  },
  methods: {
    simplifyEventsDate(_startDate) {
      let startDate = this.$moment(_startDate);

      return `Le ${startDate.format("DD/MM/YYYY")}`;
    },
    simplifyEventsTime(_startDate, _endDate) {
      let startDate = this.$moment(_startDate);
      let endDate = this.$moment(_endDate);

      return `De ${startDate.format("HH:mm")} à ${endDate.format("HH:mm")}`;
    },
    async deleteEvent(arg) {
      let finalUrl = arg === "all" ? "groups/" + this.event_uuid : this.event_id;

      try {
        let response = await this.$axios.delete(this.$config.server_url + "/extranet/2.0/calendar/events/" + finalUrl);
        console.log(response);
        this.$emit("deletedEvent", true);
        this.cancelWin();
      } catch (err) {
        console.log(err);
      }
    },
    cancelWin() {
      this.$emit("input", false);
      this.$destroy();
    }
  }
};
</script>

<style lang="scss" scoped>
.recurring-events-content {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.recurringEventsListElement {
  height: 35px;
  padding-top: 5px;
}
.eventTarget {
  background-color: #f9d7da;
  font-weight: bold;
  border: 1px solid #f5c6cb;
}
</style>
