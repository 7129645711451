var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("input", {
      staticClass: "form-control",
      attrs: {
        type: "file",
        label: _vm.label,
        accept: _vm.type === "images" ? "image/*" : "file/*"
      },
      on: {
        change: function($event) {
          return _vm.handleChangeFile($event)
        }
      }
    }),
    _vm.type === "images"
      ? _c(
          "div",
          { attrs: { wrap: "", "align-center": "" } },
          _vm._l(_vm.offer.images, function(row_fi) {
            return _c(
              "div",
              { key: row_fi.fi_id, staticStyle: { padding: "2px" } },
              [
                _c("v-img", {
                  attrs: {
                    src:
                      _vm.$config.server_url +
                      "/api/1.0/images/" +
                      row_fi.fi_id +
                      "/75/75?token=" +
                      _vm.$store.state.accesstoken,
                    height: "75px",
                    width: "75px"
                  }
                })
              ],
              1
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }