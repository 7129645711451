<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div>
              <h1 v-if="tt_id != 0">Modification du modèle de télévision {{ row_tt.tt_name }}</h1>
              <h1 v-else>Création du modèle de télévision {{ row_tt.tt_name }}</h1>
            </div>
          </div>
          <div class="modal-body">
            <m-form-text
              label="Nom *"
              type="text"
              v-model="row_tt.tt_name"
              :name="$Utils.randomstring('tt_name')"
              :rules="[$Validation.mandatory]"
            ></m-form-text>
            <m-form-text
              label="Clé *"
              type="text"
              v-model="row_tt.tt_key"
              :name="$Utils.randomstring('tt_key')"
              :rules="[$Validation.mandatory]"
            ></m-form-text>
            <m-form-text
              label="Groupe *"
              type="text"
              v-model="row_tt.tt_group"
              :name="$Utils.randomstring('tt_group')"
              :rules="[$Validation.mandatory]"
            ></m-form-text>
            <m-form-combobox
              label="Protocole"
              class="col-form-label-sm"
              label-position="top"
              v-model="row_tt.tt_protocol"
              :store-url="$config.server_url + '/extranet/2.0/protocoles'"
              item-value="tp_id"
              item-text="tp_name"
              labelButton="Nouveau Protocole"
              v-if="!createProtocol"
              @clickLabelButton="newProtocol"
            ></m-form-combobox>
            <button type="button" class="btn btn-secondary btn-sm" v-if="createProtocol" @click="useProtocol">Utiliser un protocole existant</button>
            <m-form-text
              label="Protocole"
              type="text"
              v-model="row_tt.tt_protocol"
              :name="$Utils.randomstring('tt_protocol')"
              v-if="createProtocol"
              :rules="[$Validation.mandatory]"
            ></m-form-text>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between">
            <div>
              <button type="button" class="btn btn-danger" v-if="tt_id != 0" @click="deleteConfirmWin">Supprimer</button>
            </div>
            <div class="d-flex flex-row align-items-end">
              <button type="button" class="btn btn-secondary" @click="cancelWin">Annuler</button>
              <button type="button" class="btn btn-primary btn-mouveal ml-2" @click="saveWin">Sauvegarder</button>
            </div>
          </div>
        </div>
      </div>
      <m-message-dialog v-model="dialogErr" title="Erreur" :text="dialogErrTxt"></m-message-dialog>
      <m-confirm-dialog
        v-model="confirmdelete"
        :text="`Souhaitez-vous effacer le modèle de télévision ${row_tt.tt_name}  ?`"
        title="Confirmation"
        width="500px"
        @canceled="confirmdelete = false"
        @confirmed="deleteWin()"
      ></m-confirm-dialog>
    </div>
  </transition>
</template>

<script>
export default {
  name: "TvModelWinEdit",
  components: {},
  props: {
    value: {
      default: false,
      type: Boolean
    },
    tt_id: {
      default: -1,
      type: Number
    }
  },
  watch: {
    value: function(val) {
      if (val) {
        this.getTvModel();
      }
    }
  },
  data() {
    return {
      row_tt: {
        tt_id: -1,
        tt_group: "",
        tt_name: "",
        tt_key: "",
        tt_protocol: {
          tp_id: 0,
          tp_name: ""
        },
        tp_id: 0
      },
      dialogErr: false,
      dialogErrTxt: "",
      avatar: null,
      createProtocol: false,
      confirmdelete: false
    };
  },
  methods: {
    async saveWin() {
      let err = [];
      let fieldRequired = [
        { field: "tt_name", text: "Nom du modèle" },
        { field: "tt_group", text: "Nom du groupe" },
        { field: "tt_key", text: "Clé du modèle" }
      ];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.row_tt[field.field]) err.push(field);
      }
      if (err.length) {
        this.dialogErrTxt = "<span class='bis'>Vous devez remplir les champs : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error.text + " <br>";
        }
        this.dialogErr = true;
      } else {
        let response;
        if (this.row_tt.tt_id != 0) {
          response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/tvtype/" + this.row_tt.tt_id, this.row_tt);
        } else {
          response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/tvtype", this.row_tt);
        }
        this.$emit("winEditSaved", response);
      }
    },
    cancelWin() {
      this.$emit("winEditCanceled");
    },
    newProtocol() {
      this.createProtocol = true;
    },
    useProtocol() {
      this.createProtocol = false;
    },
    async getTvModel() {
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/tvtype/" + this.tt_id);
      if (response.data && response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }
      this.row_tt = response.data && response.data.data ? response.data.data : {};
      if (this.row_tt) {
        this.row_tt.tt_protocol = this.row_tt.tp_id;
      }
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteWin() {
      await this.$axios.delete(this.$config.server_url + "/extranet/2.0/tvtype/" + this.tt_id);
      this.$emit("winEditDeleted", this.row_tt.tt_name, "modèle de télévision");
      this.confirmdelete = false;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";

.winedit-container-header {
  width: 100%;
  justify-content: space-between;
}
.input-avatar {
  padding: 0;
}
.modal {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.active {
  min-width: 150px;
  input {
    width: auto;
  }
}
/*************** table droits **************/
tr {
  border-bottom: 0.5px solid #ccc9c9;
}
td:first-child {
  text-align: left;
}
td,
th {
  border-right: 0.5px solid #ccc9c9;
  border-left: 0.5px solid #ccc9c9;
  padding: 5px 10px;
  text-align: center;
}
th {
  background-color: #013c62;
  color: #fff;
  text-transform: uppercase;
}
th.groupe {
  color: black;
}
.groupe {
  background-color: #b5b2b2;
}
</style>
