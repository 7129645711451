var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "d-flex w-100" },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-column  w-100", attrs: { id: "boxes" } },
        [
          _c("div", { staticClass: "d-flex header-emotivi" }, [
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("h1", [
                _vm._v(_vm._s(_vm.products_total) + " produits et services")
              ])
            ]),
            _c(
              "div",
              {
                staticClass:
                  "flex-grow-1 d-flex justify-content-end align-items-end",
                staticStyle: { "margin-top": "-40px" }
              },
              [
                _c("m-form-checkbox", {
                  attrs: {
                    label: "Non-actif",
                    name: _vm.$Utils.randomstring("filterNotActive")
                  },
                  on: { input: _vm.loadProducts },
                  model: {
                    value: _vm.filterNotActive,
                    callback: function($$v) {
                      _vm.filterNotActive = $$v
                    },
                    expression: "filterNotActive"
                  }
                }),
                _c("m-form-text", {
                  staticClass: "ml-3",
                  attrs: {
                    label: "Rechercher",
                    "label-position": "top",
                    name: "search",
                    autocomplete: ""
                  },
                  on: { input: _vm.loadProducts },
                  model: {
                    value: _vm.filterText,
                    callback: function($$v) {
                      _vm.filterText = $$v
                    },
                    expression: "filterText"
                  }
                }),
                _c(
                  "button",
                  {
                    staticClass: "ml-3 btn btn-sm btn-primary color-white",
                    attrs: { type: "button" },
                    on: { click: _vm.addProducts }
                  },
                  [
                    _c("icon", {
                      staticClass: "mr-2",
                      attrs: { name: "plus", scale: "1" }
                    }),
                    _vm._v(" Nouveau ")
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "flex-grow-1 overflow-hidden d-flex w-100" },
            [
              _c(
                "div",
                { staticClass: "card card-no-margin w-100" },
                [
                  _c("m-list-simple", {
                    ref: "contactslist",
                    attrs: {
                      dynamic: true,
                      items: _vm.products,
                      "item-value": "pr_id",
                      "item-height": 30,
                      limit: _vm.products_limit,
                      skip: _vm.products_skip,
                      total: _vm.products_total
                    },
                    on: { itemclick: _vm.editProducts },
                    scopedSlots: _vm._u([
                      {
                        key: "ths",
                        fn: function(ref) {
                          return [
                            _c(
                              "th",
                              {
                                staticStyle: { "min-width": "50px" },
                                on: {
                                  click: function($event) {
                                    return _vm.sortItems([])
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "pointer" }, [
                                  _vm._v("Désignation")
                                ])
                              ]
                            ),
                            _c(
                              "th",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.sortItems(["pr_email"])
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "pointer" }, [
                                  _vm._v("Nb matériel")
                                ])
                              ]
                            ),
                            _c(
                              "th",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.sortItems(["pr_email"])
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "pointer" }, [
                                  _vm._v("Nb stock possible")
                                ])
                              ]
                            ),
                            _c(
                              "th",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.sortItems(["pr_post"])
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "pointer" }, [
                                  _vm._v("Nb offres liées")
                                ])
                              ]
                            ),
                            _c(
                              "th",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.sortItems(["pr_type"])
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "pointer" }, [
                                  _vm._v("Type")
                                ])
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "tds",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("td", [
                              _vm._v(" " + _vm._s(item.pr_name) + " ")
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(item.productsmaterials.length))
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  item.nb_stock_possible
                                    ? item.nb_stock_possible
                                    : "-"
                                )
                              )
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(item.offersproducts.length))
                            ]),
                            _c("td", [_vm._v(" " + _vm._s(item.pr_type) + " ")])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ]
          )
        ]
      ),
      _c("products-win-edit", {
        attrs: { pr_id: _vm.itemSelected.pr_id },
        on: { savedWin: _vm.loadProducts },
        model: {
          value: _vm.dialog,
          callback: function($$v) {
            _vm.dialog = $$v
          },
          expression: "dialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }