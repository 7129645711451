var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: _vm.value ? "show" : "hide",
        style: _vm.value
          ? "display:block;background-color:#3333337a;"
          : "display:none;",
        attrs: { tabindex: "-1", role: "dialog", "aria-hidden": "true" }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-scrollable",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "div",
                {
                  staticClass: "modal-header d-flex flex-row align-items-center"
                },
                [_c("div", [_c("h1", [_vm._v("Création d'une facture")])])]
              ),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c("h6", [
                    _c("span", { staticClass: "number-title" }, [_vm._v("1")]),
                    _vm._v(" Le contact")
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("m-form-combobox", {
                        staticClass: "mt-0",
                        attrs: {
                          label: "Le contact",
                          "label-position": "top",
                          name: _vm.$Utils.randomstring("contactSelected"),
                          "store-url":
                            _vm.$config.server_url + "/extranet/2.0/contacts",
                          "store-params": {},
                          "item-value": "co_id",
                          "item-text": _vm.showContactName
                        },
                        model: {
                          value: _vm.contactSelected,
                          callback: function($$v) {
                            _vm.contactSelected = $$v
                          },
                          expression: "contactSelected"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row mt-3" }, [
                  _c("h6", [
                    _c("span", { staticClass: "number-title" }, [_vm._v("2")]),
                    _vm._v("Choisir l'offre")
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-md-6 d-flex flex-row",
                      staticStyle: { "margin-top": "-15px" }
                    },
                    [
                      _c("m-form-combobox", {
                        staticClass: "flex-grow-1 basis-50",
                        attrs: {
                          label: "L'offre",
                          "label-position": "top",
                          name: _vm.$Utils.randomstring("offerSelected"),
                          "store-url":
                            _vm.$config.server_url + "/extranet/2.0/offers",
                          "store-params": {},
                          "item-value": "of_id",
                          "item-text": "of_name"
                        },
                        on: { input: _vm.setOfferPriceht },
                        model: {
                          value: _vm.offerSelected,
                          callback: function($$v) {
                            _vm.offerSelected = $$v
                          },
                          expression: "offerSelected"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-md-6 d-flex flex-row",
                      staticStyle: { "margin-top": "-15px" }
                    },
                    [
                      _c("m-form-combobox", {
                        staticClass: "flex-grow-1  basis-50",
                        attrs: {
                          label: "L'utilisateur final",
                          "label-position": "top",
                          name: _vm.$Utils.randomstring("enduserSelected"),
                          "store-url":
                            _vm.$config.server_url + "/extranet/2.0/endusers",
                          "store-params": {
                            co_id: _vm.contactSelected
                              ? _vm.contactSelected.co_id
                              : -1
                          },
                          "item-value": "en_id",
                          "item-text": "en_fullname",
                          disabled:
                            !_vm.contactSelected || !_vm.contactSelected.co_id
                        },
                        model: {
                          value: _vm.enduserSelected,
                          callback: function($$v) {
                            _vm.enduserSelected = $$v
                          },
                          expression: "enduserSelected"
                        }
                      }),
                      _c("m-form-select", {
                        staticClass: "flex-grow-1  ml-1 basis-50",
                        attrs: {
                          label: "Méthode de paiement",
                          "label-position": "top",
                          name: _vm.$Utils.randomstring("orderPayementMethod"),
                          items: _vm.orderPaymentMethods,
                          disabled:
                            !_vm.contactSelected || !_vm.contactSelected.co_id
                        },
                        model: {
                          value: _vm.row_or.or_paymentmethod,
                          callback: function($$v) {
                            _vm.$set(_vm.row_or, "or_paymentmethod", $$v)
                          },
                          expression: "row_or.or_paymentmethod"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-md-6 d-flex flex-row",
                      staticStyle: { "margin-top": "-15px" }
                    },
                    [
                      _c("m-form-text", {
                        attrs: {
                          label: "Quantité",
                          type: "number",
                          name: _vm.$Utils.randomstring("ol_qte"),
                          rules: [_vm.$Validation.mandatory]
                        },
                        on: { input: _vm.calculPriceTotalHt },
                        model: {
                          value: _vm.newOrderline.ol_qte,
                          callback: function($$v) {
                            _vm.$set(_vm.newOrderline, "ol_qte", $$v)
                          },
                          expression: "newOrderline.ol_qte"
                        }
                      }),
                      _c("m-form-text", {
                        staticClass: "ml-4",
                        attrs: {
                          label: "TVA %",
                          type: "number",
                          name: _vm.$Utils.randomstring("tva_percent"),
                          rules: [_vm.$Validation.mandatory]
                        },
                        model: {
                          value: _vm.newOrderline.tva_percent,
                          callback: function($$v) {
                            _vm.$set(_vm.newOrderline, "tva_percent", $$v)
                          },
                          expression: "newOrderline.tva_percent"
                        }
                      }),
                      _c("m-form-text", {
                        staticClass: "ml-4",
                        attrs: {
                          label: "Prix unit. TTC",
                          type: "number",
                          name: _vm.$Utils.randomstring("ol_puttc"),
                          rules: [_vm.$Validation.mandatory]
                        },
                        on: { input: _vm.calculPriceTotalHt },
                        model: {
                          value: _vm.newOrderline.ol_puttc,
                          callback: function($$v) {
                            _vm.$set(_vm.newOrderline, "ol_puttc", $$v)
                          },
                          expression: "newOrderline.ol_puttc"
                        }
                      }),
                      _c("m-form-text", {
                        staticClass: "ml-4",
                        attrs: {
                          label: "Prix total TTC",
                          type: "number",
                          name: _vm.$Utils.randomstring("ol_totalttc"),
                          rules: [_vm.$Validation.mandatory],
                          disabled: ""
                        },
                        model: {
                          value: _vm.newOrderline.ol_totalttc,
                          callback: function($$v) {
                            _vm.$set(_vm.newOrderline, "ol_totalttc", $$v)
                          },
                          expression: "newOrderline.ol_totalttc"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-md-12 d-flex flex-row justify-content-end small-text"
                    },
                    [_vm._v("Prix en centimes d'euro *")]
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-sm",
                        on: {
                          click: function($event) {
                            _vm.showOffer = !_vm.showOffer
                          }
                        }
                      },
                      [_vm._v("Afficher l'offre")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-warning btn-sm ml-2",
                        on: {
                          click: function($event) {
                            return _vm.validLine(false)
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              this.newOrderline.ol_id
                                ? "Modifier la ligne"
                                : "Ajouter la ligne"
                            ) +
                            " "
                        )
                      ]
                    )
                  ])
                ]),
                _vm.showOffer && _vm.offerSelected.of_id
                  ? _c("div", { staticClass: "row mt-3" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("div", [
                          _vm._v(
                            "Description de l'offre : " +
                              _vm._s(_vm.offerSelected.of_description)
                          )
                        ]),
                        _c("div", [
                          _vm._v(
                            "Engagement : " +
                              _vm._s(_vm.offerSelected.of_commit_period)
                          )
                        ]),
                        _c("div", [
                          _vm._v(
                            "Paiement de départ : " +
                              _vm._s(_vm.offerSelected.of_deposit)
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "row mt-3" }, [
                  _c("h6", [
                    _c("span", { staticClass: "number-title" }, [_vm._v("3")]),
                    _vm._v("Votre commande")
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("m-list-simple", {
                        attrs: {
                          dynamic: true,
                          items: _vm.orderLines,
                          "item-value": "ol_id",
                          "item-height": 30,
                          limit: _vm.limitOrderLines
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "ths",
                            fn: function(ref) {
                              return [
                                _c(
                                  "th",
                                  { staticStyle: { "min-width": "50px" } },
                                  [
                                    _c("span", { staticClass: "pointer" }, [
                                      _vm._v("Offre")
                                    ])
                                  ]
                                ),
                                _c("th", {}, [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Produit")
                                  ])
                                ]),
                                _c("th", {}, [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Utilisateur final")
                                  ])
                                ]),
                                _c("th", [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Quantité")
                                  ])
                                ]),
                                _c("th", [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("PU TTC")
                                  ])
                                ]),
                                _c("th", {}, [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Tva")
                                  ])
                                ]),
                                _c("th", {}, [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Total TTC")
                                  ])
                                ]),
                                _c(
                                  "th",
                                  { staticStyle: { "min-width": "30px" } },
                                  [_vm._v("Éditer")]
                                )
                              ]
                            }
                          },
                          {
                            key: "tds",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.ol_offer_object
                                          ? item.ol_offer_object.of_name
                                          : ""
                                      ) +
                                      " "
                                  )
                                ]),
                                item.ol_offer_object
                                  ? _c(
                                      "td",
                                      _vm._l(
                                        item.ol_offer_object.offersproducts,
                                        function(pr) {
                                          return _c(
                                            "div",
                                            { key: pr.ofpr_id },
                                            [
                                              _vm._v(
                                                _vm._s(pr.pr_id.pr_name) + ","
                                              )
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _c("td"),
                                _c("td", {}, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.en_id && item.en_id.en_id
                                          ? item.en_id.en_fullname
                                          : ""
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("td", [
                                  _vm._v(" " + _vm._s(item.ol_qte) + " ")
                                ]),
                                _c("td", [
                                  _vm._v(
                                    " " + _vm._s(item.ol_puttc / 100) + " "
                                  )
                                ]),
                                _c("td", {}, [
                                  _vm._v(_vm._s(item.ol_tva / 100) + " %")
                                ]),
                                _c("td", {}, [
                                  _vm._v(
                                    " " + _vm._s(item.ol_totalttc / 100) + " "
                                  )
                                ]),
                                _c("td", {}, [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-row" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.editOrderLine(item)
                                            }
                                          }
                                        },
                                        [
                                          _c("icon", {
                                            attrs: { name: "edit" }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "ml-2",
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteOrderLine(item)
                                            }
                                          }
                                        },
                                        [
                                          _c("icon", {
                                            attrs: { name: "trash-alt" }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ])
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("div", { staticClass: "totaux-line bg-green" }, [
                        _vm._v(" Total HT : "),
                        _c("span", { staticStyle: { float: "right" } }, [
                          _vm._v(_vm._s(_vm.row_or.or_priceht / 100) + " €")
                        ])
                      ]),
                      _vm._l(_vm.row_or.or_tvas, function(item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "totaux-line bg-green" },
                          [
                            _vm._v(" TVA : " + _vm._s(item.tva / 100) + " % "),
                            _c("span", { staticStyle: { float: "right" } }, [
                              _vm._v(_vm._s(item.total / 100) + " €")
                            ])
                          ]
                        )
                      }),
                      _c("div", { staticClass: "totaux-line" }, [
                        _vm._v(" Total TTC : "),
                        _c("span", { staticStyle: { float: "right" } }, [
                          _vm._v(_vm._s(_vm.row_or.or_pricettc / 100) + " €")
                        ])
                      ])
                    ],
                    2
                  )
                ])
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "modal-footer d-flex flex-row justify-content-between mt-5"
                },
                [
                  _c("div"),
                  _c("div", { staticClass: "d-flex flex-row" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button" },
                        on: { click: _vm.cancelWin }
                      },
                      [_vm._v("Annuler")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-mouveal ml-2",
                        attrs: {
                          type: "button",
                          disabled: !_vm.orderLines.length
                        },
                        on: {
                          click: function($event) {
                            return _vm.saveWin(false)
                          }
                        }
                      },
                      [_vm._v("Valider")]
                    )
                  ])
                ]
              )
            ])
          ]
        ),
        _c("m-message-dialog", {
          attrs: { title: "Erreur", text: _vm.dialogErrTxt },
          model: {
            value: _vm.dialogErr,
            callback: function($$v) {
              _vm.dialogErr = $$v
            },
            expression: "dialogErr"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }