<template>
  <div>
    <div>
      <div class="d-flex flex-row flex-wrap justify-content-between mb-3">
        <h2>
          <span class="majuscule">
            {{ row_co | formatContactNameSimple("co_invoice_") }}
            <img v-if="row_co.es_id.es_white_label" :src="$config.server_url + row_co.es_id.es_avatar" alt="Logo fournisseur" width="50px" />
          </span>
        </h2>

        <div v-if="$store.getters.checkUserAccess($router, '/contacts/:co_id/edit')">
          <button class="btn btn-primary ml-2" @click="openWinEdit">Modifier</button>
          <button class="btn btn-secondary ml-2" @click="exportCSV()">Exporter en csv</button>
        </div>
      </div>
      <div class="d-flex flex-row flex-wrap justify-content-between mb-3 mt-n1">
        <span class="text-warning" v-if="row_co.co_followedup === 1">Le premier email de relance a été envoyé.</span>
        <span class="text-warning" v-else-if="row_co.co_followedup === 2">Le deuxième email de relance a été envoyé.</span>
        <span class="text-warning" v-else-if="row_co.co_followedup === 3">Le troisième email de relance a été envoyé.</span>
      </div>
      <div class="row mt-4">
        <div class="col-md-4 hr-right">
          <h5>coordonnées</h5>
          <div>
            <span class="color-emotivi-blue bis" v-if="row_co.co_invoice_tel">Tél.</span>
            {{ row_co.co_invoice_tel }}
          </div>
          <div>
            <span class="color-emotivi-blue bis">Email.</span>
            {{ row_co.co_invoice_email }}
          </div>
          <h5 class="mt-3">infos</h5>
          <!-- <div>Dans un établissement : {{ row_co.co_box_inestablishment == "no" ? "non" : "oui" }}</div> -->
          <div v-if="row_co.co_box_inestablishment == 'yes'"><b>Installation dans un établissement de santé</b></div>
          <div v-if="row_co.es_id && row_co.es_id.es_name">Établissement : {{ row_co.es_id.es_name }}</div>
          <div :style="{ color: getColor(row_co.co_box_connection_type) }">Type de connexion : {{ row_co.co_box_connection_type }}</div>
          <div>Marque TV : {{ row_co.co_box_tvbrand_other ? row_co.co_box_tvbrand_other : row_co.co_box_tvbrand }}</div>
          <div>Découverte d'Emotivi : {{ row_co.co_box_discover }}</div>
          <div v-if="row_co.afco_id && row_co.afco_id.af_id">Code d'affiliation : {{ row_co.afco_id.af_id.af_code }}</div>
          <div v-if="row_co.afco_id && row_co.afco_id.af_id">Nom du revendeur : {{ row_co.afco_id.af_id.af_name }}</div>
          <div >Site partenaire : {{ row_co.co_partner_site }}</div>
        </div>
        <div class="col-md-4 hr-right">
          <h5>Adresse de facturation</h5>
          <div>{{ row_co | formatContactNameSimple("co_invoice_") }}</div>
          <div>{{ row_co.co_invoice_address1 }}</div>
          <div>{{ row_co.co_invoice_address2 }}</div>
          <div>{{ row_co.co_invoice_zip }} <span v-if="row_co.co_invoice_city">,</span> {{ row_co.co_invoice_city }}</div>
          <h5 v-if="$store.getters.checkUserAccess($router, '/invoices')" class="mt-2">Prélévement bancaire</h5>
          <div v-if="$store.getters.checkUserAccess($router, '/invoices') && row_co.co_rib">
            <div>Titulaire : {{ row_co.co_rib_name }}</div>
            <div>IBAN : {{ row_co.co_rib_iban }}</div>
            <div>BIC : {{ row_co.co_rib_bic }}</div>
          </div>
          <div v-else>Aucun</div>
          <div v-if="row_co.es_id.es_white_label">
            <h5 class="mt-2">Fournisseur de services</h5>
            <div class="row">
              <div class="col">{{ row_co.es_id.es_name }}</div>
              <div class="col-auto">
                <img :src="$config.server_url + row_co.es_id.es_avatar" alt="logo fournisseur" width="30px" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <h5>Adresse de livraison</h5>
          <div v-show="row_co.co_shipping_society_name">Nom de la société : {{ row_co.co_shipping_society_name }}</div>
          <div>{{ row_co | formatContactNameSimple("co_shipping_") }}</div>
          <div>{{ row_co.co_shipping_address1 }}</div>
          <div>{{ row_co.co_shipping_address2 }}</div>
          <div>{{ row_co.co_shipping_zip }} <span v-if="row_co.co_shipping_city">,</span> {{ row_co.co_shipping_city }}</div>
          <div v-if="row_co.co_shipping_tel">Tél : {{ row_co.co_shipping_tel }}</div>
        </div>
      </div>

      <div v-if="$store.getters.checkUserAccess($router, '/boxes/:en_id')" class="row mt-3">
        <div class="col-md-12">
          <h5 class="">Liste des boxes associées</h5>
        </div>
        <div class="col-md-12" v-if="row_co && row_co.endusers && row_co.endusers.length">
          <m-list-simple ref="commandelist" :dynamic="true" :items="row_co.endusers" item-value="id" :total="1" @itemclick="goBox">
            <template v-slot:ths="{}">
              <th></th>
              <th>Utilisateur final</th>
              <th>box associée</th>
              <th>Premier appel réussi</th>
              <th>email d'invitation</th>
            </template>
            <template v-slot:tds="{ item }">
              <td>
                <span v-show="tabEnIds.length === 0"><icon width="22" height="22" name="arrow-right" class="mr-2" color="#199aaa"></icon></span>
                <span v-for="enId in tabEnIds" :key="enId" v-show="item.en_id === enId"
                  ><icon width="22" height="22" name="arrow-right" class="mr-2" color="#199aaa"></icon
                ></span>
              </td>
              <td>{{ item.en_fullname }}</td>
              <td>
                <span v-if="item.bo_id">{{ item.bo_id.bo_mac_address }}</span>
              </td>
              <td>
                <span v-if="item.firstSuccessCall != '0000-00-00 00:00:00'">{{ $moment(item.firstSuccessCall).format("DD/MM/YYYY [à] HH:mm") }}</span
                ><span v-else>-</span>
              </td>
              <td><button class="btn btn-warning btn-sm" @click="sendInvitationEmail(item, $event)">Envoyer</button></td>
            </template>
          </m-list-simple>
        </div>
        <div v-else>
          <div class="col-md-12">Pas encore de boxes associées</div>
        </div>
      </div>

      <div v-if="$store.getters.checkUserAccess($router, '/invoices')" class="row">
        <div class="col-md-12">
          <h5 class="">
            Les tickets <span v-if="loadingTickets"><icon width="30" height="30" name="sync" class="mr-2 fa-spin"></icon></span>
          </h5>
        </div>
        <div class="col-md-12" v-if="tickets.length">
          <m-list-simple ref="commandelist" :dynamic="true" :items="tickets" item-value="id" :total="1" @itemclick="goFreshdeskTicket">
            <template v-slot:ths="{}">
              <th style="width: 150px">
                <span class="pointer">Date</span>
              </th>
              <th>
                <span class="pointer">Sujet</span>
              </th>
              <th style="width: 80px">
                <span class="pointer">État</span>
              </th>
            </template>
            <template v-slot:tds="{ item }">
              <td>{{ item.created_at | formatDateTime }}</td>
              <td>
                {{ item.subject }}
              </td>
              <td>
                <div class="ticket-status" :class="'status-' + item.status">
                  {{ item.status | formatStatusTicketsFreshdesk }}
                </div>
              </td>
            </template>
          </m-list-simple>
        </div>
        <div v-else>
          <div class="col-md-12">Pas de tickets youhou!</div>
        </div>
      </div>

      <div v-if="$store.getters.checkUserAccess($router, '/invoices') && currentEnduser" class="row">
        <div class="col-md-12">
          <div>
            <h5 class="">
              Les paiements (frais de dossier + abonnement mensuel)
              <span v-if="loadingPayments"><icon width="30" height="30" name="sync" class="mr-2 fa-spin"></icon></span>
            </h5>
            <m-list-simple ref="commandelist" :dynamic="true" :items="allCharges" item-value="ac_id" :total="allCharges.length">
              <template v-slot:ths="{}">
                <th width="180">
                  <span class="pointer">Date</span>
                </th>
                <th width="100">
                  <span class="pointer">Montant</span>
                </th>
                <th width="160">
                  <span class="pointer">Statut</span>
                </th>
                <th>
                  <span class="pointer">Description</span>
                </th>
                <th style="width: 230px">
                  <span class="pointer">Id - lien vers stripe</span>
                </th>
                <!-- <span class="pointer">&nbsp;</span> -->
              </template>
              <template v-slot:tds="{ item }">
                <td>{{ $moment.unix(item.created).format("YYYY-MM-DD HH:mm:ss") }}</td>
                <td>{{ (item.amount / 100) | formatPrice }}</td>
                <td>
                  {{ item.status }}
                </td>
                <td>
                  {{ item.description }}
                </td>
                <td>
                  <a :href="item.receipt_url" target="_blank">{{ item.id }}</a>
                </td>
                <!-- <td>
                  <icon
                    v-if="item.payment_intent && !item.amount_refunded"
                    @click="confirmCancelCharge(item.payment_intent)"
                    width="22"
                    height="22"
                    name="trash-alt"
                    class="mr-2"
                    color="black"
                  ></icon>
                  <span v-else-if="item.amount_refunded" class="badge badge-danger"
                    >REMBOURSÉ&nbsp;:&nbsp;{{ (item.amount_refunded / 100) | formatPrice }}</span
                  >
                </td> -->
              </template>
            </m-list-simple>
          </div>
          <div>
            <h5 class="">
              Les abonnements <span v-if="loadingPayments"><icon width="30" height="30" name="sync" class="mr-2 fa-spin"></icon></span>
            </h5>
            <m-list-simple ref="commandelist" :dynamic="true" :items="allSubscriptions" item-value="ac_id" :total="allSubscriptions.length">
              <template v-slot:ths="{}">
                <th></th>
                <th style="width: 80px">
                  <span class="pointer">Montant</span>
                </th>
                <th style="width: 80px">
                  <span class="pointer">Statut</span>
                </th>
                <th>
                  <span class="pointer">Description</span>
                </th>
                <th>
                  <span class="pointer">Id</span>
                </th>
                <th style="width: 150px">
                  <span class="pointer">Début abonnement</span>
                </th>
                <th style="width: 150px">
                  <span class="pointer">Fin abonnement</span>
                </th>
                <th>
                  <span class="pointer">Plateforme</span>
                </th>
              </template>
              <template v-slot:tds="{ item }">
                <td v-if="item.end_user && Array.isArray(item.end_user) && item.end_user.length && item.end_user[0].en_id === currentEnduser">
                  <icon width="22" height="22" name="arrow-right" class="mr-2" color="#199aaa"></icon>
                </td>
                <td>{{ (item.plan.amount / 100) | formatPrice }}</td>
                <td>
                  {{ item.su_state_label }}
                </td>
                <td>
                  {{ item.plan.name }}
                </td>
                <td>
                  {{ item.id }}
                </td>
                <td>
                  {{ $moment(item.su_startdate).format("DD/MM/YYYY HH:mm:ss") }}
                </td>
                <td>
                  <span v-if="item.su_enddate">{{
                    $moment.unix(item.su_enddate).isValid()
                      ? $moment.unix(item.su_enddate).format("DD/MM/YYYY HH:mm:ss")
                      : $moment(item.su_enddate).format("DD/MM/YYYY HH:mm:ss")
                  }}</span>
                </td>
                <td>
                  {{ item.subscriptionData.su_gocardlessid ? "GOCARDLESS" : item.subscriptionData.su_stripeid ? "STRIPE" : "" }}
                </td>
              </template>
            </m-list-simple>
          </div>
          <div>
            <h5 class="">
              Les factures <span v-if="loadingPayments"><icon width="30" height="30" name="sync" class="mr-2 fa-spin"></icon></span>
            </h5>
            <m-list-simple ref="commandelist" :dynamic="true" :items="orders" item-value="ac_id" :total="stripeCharges.length">
              <template v-slot:ths="{}">
                <th width="200px">
                  <span class="pointer">nom</span>
                </th>
                <th width="200px">
                  <span class="pointer">Statut</span>
                </th>
                <th>
                  <span class="pointer">Date</span>
                </th>
                <th>
                  <span class="pointer">Total TTC</span>
                </th>
                <th>
                  <span class="pointer">Actions</span>
                </th>
              </template>
              <template v-slot:tds="{ item }">
                <td>{{ item.or_name }}</td>
                <td>
                  {{ item.or_status }}
                </td>
                <td>
                  {{ item.or_date | formatDateTime }}
                </td>
                <td>{{ item.or_pricettc / 100 }} €</td>
                <td class="d-flex flex-row">
                  <router-link :to="'/invoices/' + item.or_id"> <icon color="black" name="edit"></icon></router-link>
                  <div class="ml-2" @click="printOrder(item, $event)">
                    <icon name="print"></icon>
                  </div>
                </td>
              </template>
            </m-list-simple>
          </div>
        </div>
      </div>
      <div v-if="$store.getters.checkUserAccess($router, '/actions')" class="row">
        <div class="col-md-12">
          <button @click="clickActions" class="btn btn-primary w-100">
            {{ showActions ? "Masquer les actions" : "Afficher les actions" }}
          </button>
        </div>
      </div>
      <div class="row mt-4" v-if="showActions">
        <div class="col-md-12">
          <h5>Les actions</h5>
        </div>
        <div class="col-md-12">
          <m-list-simple ref="commandelist" :limit="300" :items="actions" item-value="ac_id" :total="action_length">
            <template v-slot:ths="{}">
              <th width="160px">
                <span class="pointer">Date</span>
              </th>
              <th width="220px">
                <span class="pointer">Type</span>
              </th>
              <th width="220px">
                <span class="pointer">Utilisateur final</span>
              </th>
              <th width="150px">
                <span class="pointer">User</span>
              </th>
              <th>
                <span class="pointer">Infos</span>
              </th>
            </template>
            <template v-slot:tds="{ item }">
              <td>{{ item.createdAt | formatDateTime }}</td>
              <td>{{ item.ac_type }}</td>
              <td>{{ item.en_id | formatEndUserName }}</td>
              <td>{{ item.us_id | formatUserName }}</td>
              <td>
                <span v-if="item.ac_comment">{{ item.ac_comment }}</span>
                <span v-if="item.ac_elapsedtime > 0">Durée : {{ Math.floor((item.ac_elapsedtime / 60) * 100) / 100 }}mn</span>
              </td>
            </template>
          </m-list-simple>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <div class="small">Créé le : {{ row_co.createdAt | formatDateTime }}</div>
          <div class="small">Modifié le : {{ row_co.updatedAt | formatDateTime }}</div>
        </div>
      </div>
    </div>
    <contact-win-edit
      ref="ContactWinEdit"
      v-model="showEdit"
      :co_id="value"
      @saved="contactWinEditSaved"
      @canceled="contactWinEditCanceled"
      @deleted="contactWinEditDeleted"
    ></contact-win-edit>
    <m-message-dialog v-model="dialogMessage" :title="dialogMessageTitle" :text="dialogMessageTxt"></m-message-dialog>
    <!-- <order-win-edit v-model="dialogCreateOrder" :co_id="value" @orderSaved="orderSaved"></order-win-edit> -->
  </div>
</template>

<script>
export default {
  name: "contactdetails",
  props: {
    value: Number,
    tabEnIds: Array
  },
  data() {
    return {
      loadingPayments: false,
      loadingTickets: false,
      row_co: { endusers: [], orders: [] },
      showEdit: false,
      commandes: [],
      showActions: false,
      actions: [],
      tickets: [],
      action_length: 0,
      dialogCreateOrder: false,
      dialogMessage: false,
      dialogMessageTitle: "",
      dialogMessageTxt: "",
      allSubscriptions: [],
      stripeCharges: [],
      orders: [],
      gocardlessCharges: [],
      allCharges: []
    };
  },
  mounted() {
    if (this.value) this.loadContact(this.value);
  },
  watch: {
    value(val) {
      this.loadContact(val);
    }
  },
  computed: {
    currentEnduser() {
      return this.tabEnIds.find(enId => {
        for (let index = 0; index < this.row_co.endusers.length; index++) {
          const enduser = this.row_co.endusers[index];

          return enId === enduser.en_id;
        }
      });
    }
  },
  methods: {
    async loadContact(co_id) {
      this.allSubscriptions = [];
      this.stripeCharges = [];
      this.orders = [];
      this.actions = [];
      this.tickets = [];
      let params = {};
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/contacts/" + co_id, { params });
      this.row_co = response.data.data;
      console.log(this.row_co);
      this.loadTickets();
      this.loadPayments();
      if (this.showActions) this.loadActions();
    },
    async loadTickets() {
      this.tickets = [];
      this.loadingTickets = true;
      let params = { email: this.row_co.co_invoice_email };
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/tickets", { params });
      if (response && response.data && response.data.data) this.tickets = response.data.data;

      this.loadingTickets = false;
    },
    goFreshdeskTicket(item) {
      window.open("https://emotivi.freshdesk.com/a/tickets/" + item.id, "_blank");
    },
    getColor(type) {
      if (type === "CLE4G_EMOTIVI") {
        return "red";
      }
    },

    async loadPayments() {
      this.allSubscriptions = [];
      this.stripeCharges = [];
      this.gocardlessCharges = [];
      this.orders = [];
      if (!this.row_co) return;
      if (!this.currentEnduser) return;

      /** get all payments and subscriptions data from API */
      this.loadingPayments = true;
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/endusers/payments/" + this.currentEnduser);
      if (response.data.err) {
        console.error("loadPayments error > ", response.data.err);
        this.loadingPayments = false;
        return;
      }

      /** get subscriptions and active subscription */
      if (response.data && response.data.data && response.data.data.subscriptions) {
        this.allSubscriptions = response.data.data.subscriptions;
        this.activeSubscription = this.allSubscriptions.find(subscription => {
          return subscription.su_state === 2;
        });
      } else {
        this.allSubscriptions = [];
      }

      /** get stripe payments */
      if (response.data && response.data.data && response.data.data.stripe_data && response.data.data.stripe_data.charges) {
        this.stripeCharges = response.data.data.stripe_data.charges;
      } else {
        this.stripeCharges = [];
      }

      /** get orders */
      if (response.data && response.data.data && response.data.data.rows_or) {
        this.orders = response.data.data.rows_or;
      } else {
        this.orders = [];
      }

      /** get payment methods */
      if (response.data && response.data.data && response.data.data.rows_pm) {
        this.paymentMethods = response.data.data.rows_pm;
        this.paymentMethods.forEach(payment => {
          if (payment.pm_active) this.activePayment = payment;
        });
      } else {
        this.paymentMethods = [];
      }

      /** get gocardless payments */
      if (
        response.data &&
        response.data.data &&
        response.data.data.gocardless_data &&
        Array.isArray(response.data.data.gocardless_data.payments) &&
        response.data.data.gocardless_data.payments.length
      ) {
        this.gocardlessCharges = response.data.data.gocardless_data.payments;
      }
      this.allCharges = [...this.stripeCharges, ...this.gocardlessCharges];
      this.loadingPayments = false;
    },
    openWinEdit() {
      this.showEdit = true;
      // this.$refs.ContactWinEdit.open(this.value);
      this.$emit("opened", this.value);
    },
    contactWinEditSaved(co_id) {
      this.loadContact(co_id);
      this.$emit("saved", co_id);
      // this.$emit("change", co_id);
    },
    contactWinEditCanceled(co_id) {
      this.$emit("canceled", co_id);
    },
    contactWinEditDeleted(co_id) {
      // this.$store.dispatch("load_nbclientsuivi");
      this.$emit("deleted", co_id);
      // this.$emit("change", co_id);
    },
    async sendInvitationEmail(item, $event) {
      $event.stopPropagation();
      let body = {
        co_id: this.row_co.co_id,
        en_id: item.en_id
      };
      let response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/endusers/activation", body);
      if (response.data.data && response.data.data.success) {
        this.dialogMessageTitle = "Confirmation";
        this.dialogMessageTxt = "Les identifiants ont bien été renvoyés";
      } else {
        this.dialogMessageTitle = "Erreur";
        this.dialogMessageTxt = "Une erreurs s'est produite, le mail n'a pas été envoyé";
      }
      this.dialogMessage = true;
    },
    goBox(item) {
      // console.log("item", item);
      this.$router.push("/boxes/" + item.en_id);
    },
    clickActions() {
      this.showActions = !this.showActions;
      if (this.showActions) this.loadActions();
    },
    async loadActions() {
      let params = { co_id: this.row_co.co_id, sort: "t1.createdAt desc" };
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/actions", { params });
      this.actions = response.data.data;
      this.action_length = response.data.meta.total;
      // console.log("response.data.meta.total", response.data.meta.total);
    },
    /*     createCommande() {
      this.dialogCreateOrder = true;
      this.$router.push("/contacts/" + this.value + "/order");
    }, */
    orderSaved(co_id) {
      this.loadContact(co_id);
    },
    async exportCSV() {
      let file = await this.$axios.get(this.$config.server_url + "/extranet/2.0/contacts/" + this.row_co.co_id + "/export/csv");

      const blob = new Blob([file.data], { type: "text/csv" });
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = `contact_${this.row_co.co_id}.csv`;
      document.body.appendChild(a);
      a.click();
    }
  }
};
</script>

<style lang="scss" scoped>
.hr-right {
  border-right: solid 1px gray;
}
</style>
