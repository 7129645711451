<template>
  <div id="remoteterminal" class="remote-terminal" ref="remoteterminal"></div>
</template>

<script>
export default {
  name: "RemoteTerminal",
  props: {
    socket_response: Object
  },
  data() {
    return {
      color: "white",
      totalText: "",
      currentText: "",
      stop: false
    };
  },
  methods: {
    displayData(backup_db_response_value) {
      //console.log("watch backup db response : ", backup_db_response_value);
      if (backup_db_response_value && backup_db_response_value.stdout) {
        this.color = "grey";
        this.currentText = backup_db_response_value.stdout;
        this.appendData();
      }
      if (backup_db_response_value && backup_db_response_value.stderr) {
        this.color = "red";
        this.currentText = backup_db_response_value.stderr;
        this.appendData();
      }
      if (backup_db_response_value && backup_db_response_value.hasOwnProperty("stop")) {
        // spawn process has stopped
        if (backup_db_response_value && backup_db_response_value.stop && typeof backup_db_response_value.stop === "number") {
          this.color = "red";
          this.currentText = backup_db_response_value.text;
          this.appendData();
          // process has stopped with error code
        } else {
          this.color = "white";
          this.currentText += backup_db_response_value.text;
          this.appendData();
          // spawn proccess has stopped with no error
        }
        this.stop = true;
      }
      if (backup_db_response_value && backup_db_response_value.hasOwnProperty("error")) {
        this.color = "red";
        this.currentText = backup_db_response_value.error;
        this.appendData();
        // spawn process has error messages to display
      }
    },
    appendData() {
      this.totalText += this.currentText;
      let paragraph = document.createElement("p");
      paragraph.style.color = this.color;
      paragraph.innerHTML = this.currentText;
      document.getElementById("remoteterminal").append(paragraph);
      document.getElementById("remoteterminal").scrollTop = document.getElementById("remoteterminal").scrollHeight;
    },
    clear() {
      document.getElementById("remoteterminal").innerHTML = "";
    }
  },

  mounted() {
    this.displayData(this.socket_response);
  },

  watch: {
    socket_response(backup_db_response_value) {
      //console.log("watch backup db response : ", backup_db_response_value);
      if (backup_db_response_value && backup_db_response_value.stdout) {
        this.color = "grey";
        this.currentText = backup_db_response_value.stdout;
      } else if (backup_db_response_value && backup_db_response_value.stderr) {
        this.color = "red";
        this.currentText = backup_db_response_value.stderr;
      } else if (backup_db_response_value && backup_db_response_value.hasOwnProperty("stop")) {
        // spawn proces shas stopped
        if (backup_db_response_value && backup_db_response_value.stop && typeof backup_db_response_value.stop === "number") {
          this.color = "red";
          this.currentText = backup_db_response_value.text;
          // process has stopped with error code
        } else {
          this.color = "white";
          this.currentText = backup_db_response_value.text;
          // spawn proccess has stopped with no error
        }
        this.stop = true;
      } else if (backup_db_response_value && backup_db_response_value.hasOwnProperty("error")) {
        this.color = "red";
        this.currentText = backup_db_response_value.error;
        // spawn process has error messages to display
      }
      this.totalText += this.currentText;
      let paragraph = document.createElement("p");
      paragraph.style.color = this.color;
      paragraph.innerHTML = this.currentText;
      document.getElementById("remoteterminal").append(paragraph);
      document.getElementById("remoteterminal").scrollTop = document.getElementById("remoteterminal").scrollHeight;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.remote-terminal {
  background-color: black;
  color: white;
  padding: 3%;
  border-radius: 2%;
  overflow: auto;
}
</style>
