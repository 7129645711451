import Vue from "vue";
// import jQuery from "jquery";

import "vue-awesome/icons/times";
import "vue-awesome/icons/plus";
// import "vue-awesome/icons/user-tie";
// import "vue-awesome/icons/user-md";
import "vue-awesome/icons/cog";
import "vue-awesome/icons/cogs";
import "vue-awesome/icons/envelope";
import "vue-awesome/icons/key";
import "vue-awesome/icons/sync";
// import "vue-awesome/icons/running";
// import "vue-awesome/icons/user-times";
// import "vue-awesome/icons/list-ul";
// import "vue-awesome/icons/fire";
// import "vue-awesome/icons/download";
// import "vue-awesome/icons/sign-in-alt";
import "vue-awesome/icons/question-circle";
import "vue-awesome/icons/edit";
import "vue-awesome/icons/print";
// import "vue-awesome/icons/file-signature";
// import "vue-awesome/icons/calendar";
import "vue-awesome/icons/arrow-left";
import "vue-awesome/icons/arrow-right";
import "vue-awesome/icons/arrow-up";
// import "vue-awesome/icons/chevron-left";
// import "vue-awesome/icons/chevron-right";
// import "vue-awesome/icons/calendar-check";
import "vue-awesome/icons/trash-alt";
// import "vue-awesome/icons/times-circle";
import "vue-awesome/icons/user";
import "vue-awesome/icons/user-check";
import "vue-awesome/icons/user-tie";
import "vue-awesome/icons/clinic-medical";
import "vue-awesome/icons/user-injured";
import "vue-awesome/icons/shopping-cart";
import "vue-awesome/icons/arrow-down";
import "vue-awesome/icons/bell";
import "vue-awesome/icons/exclamation-triangle";
import "vue-awesome/icons/desktop";
import "vue-awesome/icons/search";
import "vue-awesome/icons/bars";
import "vue-awesome/icons/sign-out-alt";
import "vue-awesome/icons/expand-arrows-alt";
import "vue-awesome/icons/compress-arrows-alt";
import "vue-awesome/icons/phone-slash";
import "vue-awesome/icons/redo-alt";
import "vue-awesome/icons/undo-alt";
import "vue-awesome/icons/trash-alt";
import "vue-awesome/icons/check";
import "vue-awesome/icons/save";
import "vue-awesome/icons/book";
import "vue-awesome/icons/box-open";
import "vue-awesome/icons/box";
import "vue-awesome/icons/money-check";
import "vue-awesome/icons/search-dollar";
import "vue-awesome/icons/download";
import "vue-awesome/icons/upload";
import "vue-awesome/icons/spinner";
import "vue-awesome/icons/sync-alt";
import "vue-awesome/icons/stop-circle";
import "vue-awesome/icons/ban";
import "vue-awesome/icons/play";
import "vue-awesome/icons/exclamation-triangle";
import "vue-awesome/icons/bug";
import "vue-awesome/icons/phone-alt";
import "vue-awesome/icons/phone-slash";
import "vue-awesome/icons/pause";
import "vue-awesome/icons/user-slash";
import "vue-awesome/icons/file-invoice-dollar";
import "vue-awesome/icons/hands-helping";
import "vue-awesome/icons/link";
import "vue-awesome/icons/user-times";
import "vue-awesome/icons/tv";
import "vue-awesome/icons/sim-card";
import "vue-awesome/icons/building";
import "vue-awesome/icons/info-circle";
import "vue-awesome/icons/address-book";
import "vue-awesome/icons/reply-all";
import "vue-awesome/icons/eject";
import "vue-awesome/icons/user-cog";
import "vue-awesome/icons/file-pdf";

import Icon from "vue-awesome/components/Icon";
Vue.component("Icon", Icon);

import VueLoadImage from "vue-load-image";
Vue.component("vue-load-image", VueLoadImage);

/** COMPONENTS */
import MFormText from "../components/MFormText";
Vue.component("m-form-text", MFormText);
import MFormTextMarkdown from "../components/MFormTextMarkdown";
Vue.component("m-form-text-markdown", MFormTextMarkdown);

import MFormCombobox from "../components/MFormCombobox";
Vue.component("m-form-combobox", MFormCombobox);

import MFormCheckbox from "../components/MFormCheckbox";
Vue.component("m-form-checkbox", MFormCheckbox);

import MFormDate from "../components/MFormDate";
Vue.component("m-form-date", MFormDate);
import MCalendar from "../components/MCalendar";
Vue.component("m-calendar", MCalendar);
import MFormHour from "../components/MFormHour";
Vue.component("m-form-hour", MFormHour);
import MHour from "../components/MHour";
Vue.component("m-hour", MHour);
import MFormMinutes from "../components/MFormMinutes";
Vue.component("m-form-minutes", MFormMinutes);
import MMinutes from "../components/MMinutes";
Vue.component("m-minutes", MMinutes);

import MFormDateHour from "../components/MFormDateHour";
Vue.component("m-form-date-hour", MFormDateHour);
import MConfirmDialog from "../components/MConfirmDialog";
Vue.component("m-confirm-dialog", MConfirmDialog);
import MMessageDialog from "../components/MMessageDialog";
Vue.component("m-message-dialog", MMessageDialog);
import MSelectDialog from "../components/MSelectDialog";
Vue.component("m-select-dialog", MSelectDialog);
import MFormFiles from "../components/MFormFiles";
Vue.component("m-form-file", MFormFiles);

import MFormSelect from "../components/MFormSelect";
Vue.component("m-form-select", MFormSelect);
import MFormSelectMultiple from "../components/MFormSelectMultiple";
Vue.component("m-form-select-multiple", MFormSelectMultiple);

import MList from "../components/MList";
Vue.component("m-list", MList);
import MListSimple from "../components/MListSimple";
Vue.component("m-list-simple", MListSimple);

//// charts
import MChartBar from "../components/MChartBar";
Vue.component("m-chart-bar", MChartBar);

import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
import VueTimepicker from "vue2-timepicker";
Vue.component("v-time-picker", VueTimepicker);
import "vue2-timepicker/dist/VueTimepicker.css";

import Spinner from "../components/Spinner.vue";
Vue.component("v-spinner", Spinner);
import AudioWaves from "../components/AudioWaves.vue";
Vue.component("v-audio-waves", AudioWaves);
import Bistri from "../components/Bistri.vue";
Vue.component("v-bistri", Bistri);
import RemoteTerminal from "../components/RemoteTerminal.vue";
Vue.component("v-remote-terminal", RemoteTerminal);
import ScheduleMessage from "../components/ScheduleMessage.vue";
Vue.component("v-schedule-message", ScheduleMessage);
import ScheduledMessagesList from "../components/ScheduledMessagesList.vue";
Vue.component("v-scheduled-messages-list", ScheduledMessagesList);

import BoxVersionsSelect from "../components/BoxVersionsSelect.vue";
Vue.component("box-versions-select", BoxVersionsSelect);

import PaymentDetails from "../components/PaymentDetails.vue";
Vue.component("payment-details", PaymentDetails);

import SchedulePlanning from "../components/SchedulePlanning.vue";
Vue.component("v-schedule-planning", SchedulePlanning);

/**  VIEWS */

import ContactDetails from "../views/ContactDetails";
Vue.component("contact-details", ContactDetails);

import ContactWinEdit from "../views/ContactWinEdit";
Vue.component("contact-win-edit", ContactWinEdit);
import EnduserWinEdit from "../views/EnduserWinEdit";
Vue.component("enduser-win-edit", EnduserWinEdit);

import VueDraggable from "vuedraggable";
Vue.component("draggable", VueDraggable);

import OrderWinEdit from "../views/OrderWinEdit";
Vue.component("order-win-edit", OrderWinEdit);
import BoxeWinEdit from "../views/BoxeWinEdit";
Vue.component("boxe-win-edit", BoxeWinEdit);
import BoxeWinDisplayLogs from "../views/BoxeWinLog";
Vue.component("boxe-win-display-logs", BoxeWinDisplayLogs);
import UserWinEdit from "../views/UserWinEdit";
import ModalSendMessageAll from "../views/ModalSendMessageAll.vue";
Vue.component("modal-send-message-all", ModalSendMessageAll);
Vue.component("user-win-edit", UserWinEdit);
import MaterialsWinEdit from "../views/MaterialsWinEdit";
Vue.component("materials-win-edit", MaterialsWinEdit);
import ProductsWinEdit from "../views/ProductsWinEdit";
Vue.component("products-win-edit", ProductsWinEdit);
import OffersWinEdit from "../views/OffersWinEdit";
Vue.component("offers-win-edit", OffersWinEdit);
import EstablishmentWinEdit from "../views/EstablishmentWinEdit";
Vue.component("establishment-win-edit", EstablishmentWinEdit);
import ParcelWinEdit from "../views/ParcelWinEdit.vue";
Vue.component("parcel-win-edit", ParcelWinEdit);
import InvoiceWinEdit from "../views/InvoicesWinEdit.vue";
Vue.component("invoice-win-edit", InvoiceWinEdit);

import Research from "../views/Research.vue";
Vue.component("research-view", Research);
import VideoCall from "../views/VideoCall.vue";
Vue.component("video-call", VideoCall);
import ModalSendIrMacro from "../views/ModalSendIrMacro.vue";
Vue.component("modal-send-ir-macro", ModalSendIrMacro);
import ModalScheduleMessageAll from "../views/ModalScheduleMessageAll.vue";
Vue.component("modal-schedule-message-all", ModalScheduleMessageAll);
import ModalEventEditor from "../views/ModalEventEditor.vue";
Vue.component("modal-event-editor", ModalEventEditor);
import ModalEventDelete from "../views/ModalEventDelete.vue";
Vue.component("modal-event-delete", ModalEventDelete);
import ModalEventChanges from "../views/ModalEventChanges.vue";
Vue.component("modal-event-changes", ModalEventChanges);
import ModalScheduleMessage from "../views/ModalScheduleMessage.vue";
Vue.component("modal-schedule-message", ModalScheduleMessage);
import ModalPlanningList from "../views/ModalPlanningList.vue";
Vue.component("modal-planning-list", ModalPlanningList);
import ModalPlanningEditor from "../views/ModalPlanningEditor.vue";
Vue.component("modal-planning-editor", ModalPlanningEditor);

/** TV */
import TvWinEdit from "../views/TvWinEdit.vue";
Vue.component("tv-win-edit", TvWinEdit);
