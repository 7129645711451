<template>
  <div
    class="modal"
    :class="value ? 'show' : 'hide'"
    :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex flex-row align-items-center">
          <div>
            <h1>Planifier un message</h1>
          </div>
        </div>
        <div class="modal-body">
          <div>
            <!-- ajouter le bouton Afficher la liste des messages planifiés -->
            <button type="button" class="btn btn-primary mb-5" @click="_displayScheduledMessagesList()">
              Afficher la liste des messages planifiés
            </button>
          </div>

          <div class="mt-4">
            <h6><span class="number-title">2</span>Planification</h6>
            <v-schedule-message
              v-show="establishmentsSelected"
              ref="schedulemessage"
              @saveplanning="savePlanningData"
              :inputPlanningObject="planning"
            ></v-schedule-message>
            <p v-show="!establishmentsSelected" class="alert alert-warning">Veuillez selectionner un établissement</p>
          </div>
        </div>
        <div class="modal-footer d-flex flex-row justify-content-between">
          <div></div>
          <div>
            <button type="button" class="btn btn-secondary" @click="cancelWin">Annuler</button>
            <!-- <button type="button" class="btn btn-primary ml-2" @click="saveWin(false)">Envoyer</button> -->
            <button type="button" class="btn btn-primary ml-2" @click="sendMessage">Envoyer</button>
          </div>
        </div>
      </div>
    </div>
    <!-- <m-message-dialog v-model="dialogErr" title="Erreur" :text="dialogErrTxt"></m-message-dialog> -->

    <v-scheduled-messages-list :row_en="row_en" @modifyplanning="modifyPlanning" v-model="displayScheduledMessagesList"></v-scheduled-messages-list>
  </div>
</template>

<script>
export default {
  name: "ModalScheduleMessage",
  components: {},
  props: {
    value: {
      default: false,
      type: Boolean
    },
    row_en: Object
  },
  data() {
    return {
      establishments: [],
      establishmentsSelected: 0,
      row_es: {},
      displayScheduledMessagesList: false,
      planning: {},
      planningToSave: {}
    };
  },

  computed: {},

  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  created() {},
  watch: {
    row_en(val) {
      if (val) {
        this.getPlanningEnduser();
      }
    }
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },

    cancelWin() {
      this.resetData();
      // this.$router.push("/boxes");
      this.$emit("input", false);
    },

    async sendMessage() {
      this.planningToSave.en_ids = [this.row_en.en_id];
      if (!this.establishmentsSelected) return this.$store.dispatch("showDialogError", "Veuillez selectionner un établissement");
      if (!this.planningToSave.en_ids || !this.planningToSave.en_ids.length)
        return this.$store.dispatch("showDialogError", "Cet établissement n'a aucune box active");
      if (!this.planningToSave.pl_message && !this.planningToSave.pl_messagemedia)
        return this.$store.dispatch("showDialogError", "Veuillez écrire un message  ou sélectionner une image");
      if (!this.planningToSave.pl_datedebut) return this.$store.dispatch("showDialogError", "Veuillez sélectionner une date de début");
      if (!this.planningToSave.pl_cycle) return this.$store.dispatch("showDialogError", "Veuillez sélectionner une récurrence");
      if (this.planningToSave.pl_cycle && this.planningToSave.pl_cycle !== "onetime" && !this.planningToSave.pl_cyclevalue)
        return this.$store.dispatch("showDialogError", "Veuillez entrer le cycle de récurrence");

      try {
        let method = "post";
        let putUrl = "";
        if (this.planningToSave.pl_id && this.planningToSave.pl_id !== -1) {
          method = "put";
          putUrl = "/" + this.planningToSave.pl_id;
        }
        let response = await this.$axios[method](this.$config.server_url + "/extranet/2.0/plannings" + putUrl, this.planningToSave);
        if (response.data.error) {
          this.$store.dispatch("showDialogError", response.data.err.message);
          return;
        }

        // cosole.log("response", response);
        this.resetData();
        // this.image = null;
        // this.$router.push("/boxes");
        this.$emit("input", false);
      } catch (error) {
        console.error(error);
      }
    },

    resetData() {
      this.planningToSave = {};
      this.planning = {};
      this.$refs.schedulemessage.resetData();
    },
    _displayScheduledMessagesList() {
      this.displayScheduledMessagesList = true;
    },
    modifyPlanning(planning) {
      this.displayScheduledMessagesList = false;
      // fill the ScheduleMessage formulaire with planning data
      this.planning = planning;
      this.getPlanningEnduser();
    },

    savePlanningData(planning) {
      this.planningToSave = planning;
    },
    async getPlanningEnduser() {
      let contact = this.$_.isObject(this.row_en.co_id) ? this.row_en.co_id : null;
      if (!contact) return console.error("getPlanningEnduser() > must populate contact data");
      this.establishmentsSelected = this.$_.isObject(contact.es_id) ? contact.es_id.es_id : contact.es_id;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";
.container-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: 8px;
  background-position: center;
  background-size: cover;
  border: 1px solid #b5b5b5;
  box-shadow: 2px 2px 6px 0px #d0d0d0;
}

// from WEBAPP
.avatar-message {
  width: 90%;
  margin: auto;
}
.btn-envoyer {
  width: 100%;
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: 500;
}
input {
  margin-top: 20px;
}
.colWithoutPadding {
  padding-right: 10px;
}
.fileContainer {
  height: 50px;
  background-color: #ffffff;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 0;
  align-items: flex-end;
  border: 1px solid #ced4da;
  //   background-image: url("../../../public/assets/addphoto.png");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}
.labelImage {
  color: #6d6c64;
  font-size: 20px;
  padding: 10px;
  margin-bottom: 0;
}
textarea {
  font-size: 20px;
}

/****** toggle button  ******/
/* The switch - the box around the slider */
.switch {
  // float: left;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  min-width: 60px;
  margin-right: 10px;
  margin-left: 10px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #6da;
}

input:focus + .slider {
  box-shadow: 0 0 1px #6da;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.container-logout {
  text-align: left;
}
.btn-logout {
  text-align: left;
  background-color: #ffffff00;
  border: 0;
}
.iconMarginRight {
  width: 40px;
  width: 40px;
  height: 40px;
  opacity: 0.2;
}
</style>
