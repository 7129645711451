var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: _vm.value ? "show" : "hide",
        style: _vm.value
          ? "display:block;background-color:#3333337a;"
          : "display:none;",
        attrs: { tabindex: "-1", role: "dialog", "aria-hidden": "true" }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-scrollable",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "div",
                {
                  staticClass: "modal-header d-flex flex-row align-items-center"
                },
                [
                  _c("div", [
                    _c("h1", [
                      _vm._v(
                        "Édition de la box numéro " + _vm._s(_vm.boxe.bo_id)
                      )
                    ]),
                    _c("p")
                  ])
                ]
              ),
              _c(
                "div",
                { staticClass: "modal-body modal-padding" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-row align-items-center active"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.boxe.bo_tvmobile,
                            expression: "boxe.bo_tvmobile"
                          }
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "tvmobile",
                          name: "tvmobile"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.boxe.bo_tvmobile)
                            ? _vm._i(_vm.boxe.bo_tvmobile, null) > -1
                            : _vm.boxe.bo_tvmobile
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.boxe.bo_tvmobile,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.boxe,
                                    "bo_tvmobile",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.boxe,
                                    "bo_tvmobile",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.boxe, "bo_tvmobile", $$c)
                            }
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "label-checkbox",
                          attrs: { for: "tvmobile" }
                        },
                        [_vm._v("Télévision mobile")]
                      )
                    ]
                  ),
                  _c("hr"),
                  _c("div", { staticClass: "mt-3" }, [
                    _c("h5", [_vm._v("Plages horaires des appels vidéo")]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("m-form-checkbox", {
                            attrs: {
                              label: "Désactiver les horaires",
                              name: _vm.$Utils.randomstring("disablehours")
                            },
                            model: {
                              value: _vm.boxe.bo_disable_callhours,
                              callback: function($$v) {
                                _vm.$set(_vm.boxe, "bo_disable_callhours", $$v)
                              },
                              expression: "boxe.bo_disable_callhours"
                            }
                          }),
                          _vm._l(_vm.manageHourReformat, function(
                            plage,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "mb-2" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-row align-items-end"
                                  },
                                  [
                                    _c("v-time-picker", {
                                      staticClass: "time-picker",
                                      attrs: { format: "HHHmm" },
                                      model: {
                                        value: plage.start_time,
                                        callback: function($$v) {
                                          _vm.$set(plage, "start_time", $$v)
                                        },
                                        expression: "plage.start_time"
                                      }
                                    }),
                                    _c("v-time-picker", {
                                      staticClass: "time-picker ml-2",
                                      attrs: { format: "HHHmm" },
                                      model: {
                                        value: plage.end_time,
                                        callback: function($$v) {
                                          _vm.$set(plage, "end_time", $$v)
                                        },
                                        expression: "plage.end_time"
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "ml-2 mb-1 pointer",
                                        on: {
                                          click: function($event) {
                                            return _vm.deletePlage(plage, index)
                                          }
                                        }
                                      },
                                      [
                                        _c("icon", {
                                          attrs: {
                                            width: "25",
                                            height: "25",
                                            name: "times"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mt-2",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  _vm.addPlage = true
                                }
                              }
                            },
                            [_vm._v("Ajouter une plage horaire")]
                          )
                        ]),
                        _vm.addPlage
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-row align-items-end mt-2"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    _c("div", [_vm._v("Heure de début")]),
                                    _c("v-time-picker", {
                                      staticClass: "time-picker",
                                      attrs: { "minute-interval": 10 },
                                      model: {
                                        value: _vm.newHourStart,
                                        callback: function($$v) {
                                          _vm.newHourStart = $$v
                                        },
                                        expression: "newHourStart"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    _c("div", [_vm._v("Heure de fin")]),
                                    _c("v-time-picker", {
                                      staticClass: "time-picker ml-2",
                                      attrs: { "minute-interval": 10 },
                                      model: {
                                        value: _vm.newHourEnd,
                                        callback: function($$v) {
                                          _vm.newHourEnd = $$v
                                        },
                                        expression: "newHourEnd"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary ml-2",
                                    attrs: { type: "button" },
                                    on: { click: _vm.addPlageGo }
                                  },
                                  [_vm._v("Ajouter")]
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ]),
                    _c("hr")
                  ]),
                  _c("h5", [_vm._v("Options des appels")]),
                  _c("m-form-checkbox", {
                    staticClass: "mr-3",
                    attrs: {
                      label:
                        "Décrochage automatique activé pour l’établissement et l’administrateur",
                      name: _vm.$Utils.randomstring("bo_autoresponse")
                    },
                    on: { input: _vm.onChangeAutoresponse },
                    model: {
                      value: _vm.boxe.bo_autoresponse,
                      callback: function($$v) {
                        _vm.$set(_vm.boxe, "bo_autoresponse", $$v)
                      },
                      expression: "boxe.bo_autoresponse"
                    }
                  }),
                  _c("m-form-checkbox", {
                    staticClass: "mr-3",
                    attrs: {
                      label:
                        "Décrochage automatique activé pour tous (établissement, administrateur et membres de la famille)",
                      name: _vm.$Utils.randomstring("bo_autoresponse_forall"),
                      disabled: !_vm.boxe.bo_autoresponse
                    },
                    model: {
                      value: _vm.boxe.bo_autoresponse_forall,
                      callback: function($$v) {
                        _vm.$set(_vm.boxe, "bo_autoresponse_forall", $$v)
                      },
                      expression: "boxe.bo_autoresponse_forall"
                    }
                  }),
                  _c("m-form-checkbox", {
                    staticClass: "mr-3",
                    attrs: {
                      label: "Désactiver l'appel vidéo",
                      name: _vm.$Utils.randomstring("bo_disable_video")
                    },
                    model: {
                      value: _vm.boxe.bo_disable_video,
                      callback: function($$v) {
                        _vm.$set(_vm.boxe, "bo_disable_video", $$v)
                      },
                      expression: "boxe.bo_disable_video"
                    }
                  }),
                  _c(
                    "div",
                    [
                      _c("hr"),
                      _c("h5", [
                        _vm._v("Envoyer une alerte email au service technique")
                      ]),
                      _c("m-form-checkbox", {
                        staticClass: "mr-3",
                        attrs: {
                          label: "Déconnexion de la box",
                          name: _vm.$Utils.randomstring(
                            "bo_send_alert_disconnection"
                          )
                        },
                        model: {
                          value: _vm.boxe.bo_send_alert_disconnection,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.boxe,
                              "bo_send_alert_disconnection",
                              $$v
                            )
                          },
                          expression: "boxe.bo_send_alert_disconnection"
                        }
                      }),
                      _c("m-form-checkbox", {
                        staticClass: "mr-3",
                        attrs: {
                          label: "Niveau de pile du bouton faible",
                          name: _vm.$Utils.randomstring(
                            "bo_send_alert_button_battery_low"
                          )
                        },
                        model: {
                          value: _vm.boxe.bo_send_alert_button_battery_low,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.boxe,
                              "bo_send_alert_button_battery_low",
                              $$v
                            )
                          },
                          expression: "boxe.bo_send_alert_button_battery_low"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.isBoxCompatible("options_bistri_display_preview")
                    ? _c(
                        "div",
                        [
                          _c("hr"),
                          _c("h5", [_vm._v("Options durant l'appel vidéo")]),
                          _c("m-form-checkbox", {
                            staticClass: "mr-3",
                            attrs: {
                              label:
                                "Activer l’aperçu du senior sur son téléviseur lors d’un appel vidéo",
                              name: _vm.$Utils.randomstring(
                                "options_bistri_display_preview"
                              )
                            },
                            model: {
                              value: _vm.screenDisplayPreview,
                              callback: function($$v) {
                                _vm.screenDisplayPreview = $$v
                              },
                              expression: "screenDisplayPreview"
                            }
                          }),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.screenDisplayPreview,
                                  expression: "screenDisplayPreview"
                                }
                              ],
                              staticClass: "alert alert-secondary"
                            },
                            [
                              _c("p", { staticClass: "section-subtitle" }, [
                                _vm._v("Options de l'aperçu")
                              ]),
                              _c("label", { attrs: { for: "screenPreview" } }, [
                                _vm._v(
                                  "Sélectionner l'emplacement de l'aperçu :"
                                )
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "screenPreview",
                                  attrs: { id: "screenpreview" }
                                },
                                [
                                  _c("icon", {
                                    staticClass: "screenPreviewTv",
                                    attrs: {
                                      width: "100%",
                                      height: "100%",
                                      name: "tv",
                                      color: "#afadad"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "screenPreviewChild mt-3" },
                                    [
                                      _c("m-form-checkbox", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          name: _vm.$Utils.randomstring(
                                            "topleft"
                                          )
                                        },
                                        model: {
                                          value: _vm.screenPreviewTopLeft,
                                          callback: function($$v) {
                                            _vm.screenPreviewTopLeft = $$v
                                          },
                                          expression: "screenPreviewTopLeft"
                                        }
                                      }),
                                      _c("m-form-checkbox", {
                                        staticClass: "ml-3",
                                        attrs: {
                                          name: _vm.$Utils.randomstring(
                                            "topright"
                                          )
                                        },
                                        model: {
                                          value: _vm.screenPreviewTopRight,
                                          callback: function($$v) {
                                            _vm.screenPreviewTopRight = $$v
                                          },
                                          expression: "screenPreviewTopRight"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "screenPreviewChild" },
                                    [
                                      _c("m-form-checkbox", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          name: _vm.$Utils.randomstring(
                                            "bottomleft"
                                          )
                                        },
                                        model: {
                                          value: _vm.screenPreviewBottomLeft,
                                          callback: function($$v) {
                                            _vm.screenPreviewBottomLeft = $$v
                                          },
                                          expression: "screenPreviewBottomLeft"
                                        }
                                      }),
                                      _c("m-form-checkbox", {
                                        staticClass: "ml-3",
                                        attrs: {
                                          name: _vm.$Utils.randomstring(
                                            "bottomright"
                                          )
                                        },
                                        model: {
                                          value: _vm.screenPreviewBottomRight,
                                          callback: function($$v) {
                                            _vm.screenPreviewBottomRight = $$v
                                          },
                                          expression: "screenPreviewBottomRight"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c("m-form-select", {
                                    attrs: {
                                      label: "Taille de l'aperçu :",
                                      "label-position": "top",
                                      name: _vm.$Utils.randomstring(
                                        "screenPreviewSize"
                                      ),
                                      items: _vm.screenPreviewSizes
                                    },
                                    model: {
                                      value: _vm.screenPreviewSize,
                                      callback: function($$v) {
                                        _vm.screenPreviewSize = $$v
                                      },
                                      expression: "screenPreviewSize"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.row_bo.bo_hardwareinfo &&
                  _vm.row_bo.bo_hardwareinfo.output_volume &&
                  _vm.row_bo.bo_hardwareinfo.input_volume
                    ? _c("div", [
                        _c("hr"),
                        _c("h5", [_vm._v("Gérer les niveaux audio")]),
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "d-flex align-items-center mr-5 col-6"
                              },
                              [
                                _vm._v(
                                  "Sortie audio HDMI ( " +
                                    _vm._s(
                                      _vm.row_bo.bo_hardwareinfo &&
                                        _vm.row_bo.bo_hardwareinfo.output_volume
                                        ? _vm.row_bo.bo_hardwareinfo
                                            .output_volume
                                        : ""
                                    ) +
                                    " ) :"
                                )
                              ]
                            ),
                            _c("div", { staticClass: "col-2" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn border btn-light text-info m-1",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.audioLevel(
                                        "output-volume-down"
                                      )
                                    }
                                  }
                                },
                                [_c("b", [_vm._v("-")])]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn border btn-light text-info m-1",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.audioLevel("output-volume-up")
                                    }
                                  }
                                },
                                [_c("b", [_vm._v("+")])]
                              )
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "col-3 d-flex align-items-center"
                              },
                              [
                                _c("m-form-text", {
                                  ref: "outputVolume",
                                  staticStyle: { margin: "0" },
                                  attrs: {
                                    min: "0",
                                    max: "100",
                                    placeholder: "0 - 100",
                                    label: "En pourcentage",
                                    type: "number"
                                  },
                                  model: {
                                    value: _vm.outputVolumeValue,
                                    callback: function($$v) {
                                      _vm.outputVolumeValue = $$v
                                    },
                                    expression: "outputVolumeValue"
                                  }
                                }),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn border btn-light text-info",
                                    staticStyle: { "align-self": "flex-end" },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.audioLevel(
                                          "output-volume",
                                          _vm.outputVolumeValue
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("icon", {
                                      attrs: {
                                        width: "15",
                                        height: "15",
                                        name: "save"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "d-flex align-items-center mr-5 col-6"
                                },
                                [
                                  _vm._v(
                                    "Gain du microphone de la webcam ( " +
                                      _vm._s(
                                        _vm.row_bo.bo_hardwareinfo.input_volume
                                      ) +
                                      " ) :"
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "col-2" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn border btn-light text-info m-1",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.audioLevel(
                                          "input-volume-down"
                                        )
                                      }
                                    }
                                  },
                                  [_c("b", [_vm._v("-")])]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn border btn-light text-info m-1",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.audioLevel("input-volume-up")
                                      }
                                    }
                                  },
                                  [_c("b", [_vm._v("+")])]
                                )
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "col-3 d-flex align-items-center"
                                },
                                [
                                  _c("m-form-text", {
                                    ref: "inputVolume",
                                    staticStyle: { margin: "0" },
                                    attrs: {
                                      min: "0",
                                      max: "100",
                                      placeholder: "0 - 100",
                                      label: "En pourcentage",
                                      type: "number"
                                    },
                                    model: {
                                      value: _vm.inputVolumeValue,
                                      callback: function($$v) {
                                        _vm.inputVolumeValue = $$v
                                      },
                                      expression: "inputVolumeValue"
                                    }
                                  }),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn border btn-light text-info",
                                      staticStyle: { "align-self": "flex-end" },
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          return _vm.audioLevel(
                                            "input-volume",
                                            _vm.inputVolumeValue
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("icon", {
                                        attrs: {
                                          width: "15",
                                          height: "15",
                                          name: "save"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _vm.majorBoxVersion === 2 &&
                          _vm.minorBoxVersion <= 17 &&
                          _vm.row_bo.bo_hardwareinfo.auto_input_volume
                            ? _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("m-form-checkbox", {
                                    attrs: {
                                      label:
                                        "Ajustement automatique du gain du microphone"
                                    },
                                    model: {
                                      value: _vm.autoInputVolume,
                                      callback: function($$v) {
                                        _vm.autoInputVolume = $$v
                                      },
                                      expression: "autoInputVolume"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ])
                    : _vm._e(),
                  _vm.isBoxCompatible("options_value_leds_duration")
                    ? _c("div", [
                        _c("hr"),
                        _c("h5", [_vm._v("Gestion des notifications")]),
                        _c(
                          "div",
                          { staticClass: "alert alert-secondary mt-2" },
                          [
                            _c("p", { staticClass: "section-subtitle" }, [
                              _vm._v("Pour les messages")
                            ]),
                            _c("m-form-checkbox", {
                              staticClass: "mr-3",
                              attrs: {
                                label: "Désactiver la notification lumineuse",
                                name: _vm.$Utils.randomstring(
                                  "ledsDisableDuration"
                                )
                              },
                              model: {
                                value: _vm.ledsDisableDuration,
                                callback: function($$v) {
                                  _vm.ledsDisableDuration = $$v
                                },
                                expression: "ledsDisableDuration"
                              }
                            }),
                            _c("m-form-hour", {
                              staticClass: "mx-1",
                              staticStyle: { "overflow-wrap": "normal" },
                              attrs: {
                                label:
                                  "Durée maximale de la notification lumineuse (heures:minutes)",
                                name: _vm.$Utils.randomstring("ledsDuration")
                              },
                              model: {
                                value: _vm.ledsDurationFormatted,
                                callback: function($$v) {
                                  _vm.ledsDurationFormatted = $$v
                                },
                                expression: "ledsDurationFormatted"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.isBoxCompatible("options_leds_disabled") &&
                        _vm.isBoxCompatible("options_value_ringing_number")
                          ? _c(
                              "div",
                              { staticClass: "alert alert-secondary mt-2" },
                              [
                                _c("p", { staticClass: "section-subtitle" }, [
                                  _vm._v("Pour les appels")
                                ]),
                                _c("m-form-checkbox", {
                                  staticClass: "mr-3",
                                  attrs: {
                                    label:
                                      "Désactiver la notification lumineuse",
                                    name: _vm.$Utils.randomstring(
                                      "ledsDisabled"
                                    )
                                  },
                                  model: {
                                    value: _vm.ledsDisabled,
                                    callback: function($$v) {
                                      _vm.ledsDisabled = $$v
                                    },
                                    expression: "ledsDisabled"
                                  }
                                }),
                                _c("m-form-checkbox", {
                                  staticClass: "mr-3",
                                  attrs: {
                                    label: "Désactiver la sonnerie",
                                    name: _vm.$Utils.randomstring(
                                      "ringingDisable"
                                    )
                                  },
                                  model: {
                                    value: _vm.ringingDisable,
                                    callback: function($$v) {
                                      _vm.ringingDisable = $$v
                                    },
                                    expression: "ringingDisable"
                                  }
                                }),
                                _c("m-form-text", {
                                  staticClass: "mr-3",
                                  attrs: {
                                    label: "Nombre de sonnerie",
                                    type: "number",
                                    name: _vm.$Utils.randomstring(
                                      "ringingNumber"
                                    )
                                  },
                                  model: {
                                    value: _vm.ringingNumber,
                                    callback: function($$v) {
                                      _vm.ringingNumber = $$v
                                    },
                                    expression: "ringingNumber"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm.isBoxCompatible("options_value_button_functionality") &&
                  this.enableButtonFunctionality
                    ? _c("div", [
                        _c("h5", [_vm._v("Gestion du bouton")]),
                        _c("div", { staticClass: "col-12" }, [
                          _vm.dialogErr
                            ? _c("div", { staticClass: "alert alert-danger" }, [
                                _vm._v(" " + _vm._s(_vm.dialogErrTxt) + " ")
                              ])
                            : _vm._e()
                        ]),
                        _c(
                          "div",
                          { staticClass: "alert alert-secondary mt-2" },
                          [
                            _c("p", { staticClass: "section-subtitle" }, [
                              _vm._v("Comportement au clique du bouton")
                            ]),
                            _c("m-form-combobox", {
                              staticClass: "flex-grow-1 basis-50",
                              attrs: {
                                label: "Comportement par défaut",
                                "label-position": "top",
                                name: _vm.$Utils.randomstring("bf_id"),
                                autoSelectOnFocus: true,
                                "store-url":
                                  _vm.$config.server_url +
                                  "/extranet/2.0/buttonFunctionalities/enduser/" +
                                  this.row_en.en_id,
                                "store-params": { bf_id: _vm.row_bf.bf_id },
                                "item-value": "bf_name",
                                "item-text": "bf_name"
                              },
                              model: {
                                value: _vm.row_bf,
                                callback: function($$v) {
                                  _vm.row_bf = $$v
                                },
                                expression: "row_bf"
                              }
                            }),
                            _vm.row_bf.pc_id != "none"
                              ? _c("div", { staticClass: "col-12 mt-3" }, [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v("Message qui sera envoyé :")
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "bg-white col-12" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "word-break": "break-word"
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.row_bf.bf_message))]
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm.row_bf.pc_id != "none"
                              ? _c("m-form-text", {
                                  attrs: {
                                    label: _vm.label,
                                    type: "text",
                                    placeholder: _vm.placeholder,
                                    name: _vm.$Utils.randomstring(
                                      "communication"
                                    )
                                  },
                                  model: {
                                    value: _vm.row_bfd.communication,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.row_bfd,
                                        "communication",
                                        $$v
                                      )
                                    },
                                    expression: "row_bfd.communication"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "modal-footer d-flex flex-row justify-content-between"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      attrs: { type: "button" },
                      on: { click: _vm.deleteConfirmWin }
                    },
                    [_vm._v("Supprimer")]
                  ),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button" },
                        on: { click: _vm.cancelWin }
                      },
                      [_vm._v("Annuler")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-mouveal ml-2",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.saveWin(false)
                          }
                        }
                      },
                      [_vm._v("Enregistrer")]
                    )
                  ])
                ]
              )
            ])
          ]
        ),
        _c("m-confirm-dialog", {
          attrs: {
            text: "Souhaitez-vous effacer cette box ?",
            title: "Confirmation",
            width: "500px"
          },
          on: {
            canceled: function($event) {
              _vm.confirmdelete = false
            },
            confirmed: function($event) {
              return _vm.deleteWin()
            }
          },
          model: {
            value: _vm.confirmdelete,
            callback: function($$v) {
              _vm.confirmdelete = $$v
            },
            expression: "confirmdelete"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }