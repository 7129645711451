<template>
  <div
    class="modal"
    :class="value ? 'show' : 'hide'"
    :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex flex-row align-items-center">
          <div>
            <h1>Afficher la liste des messages planifiés</h1>
          </div>
        </div>
        <div class="modal-body ">
          <div
            :style="'min-height:' + calculateHeight() + 'px;'"
            class="flex-grow-1  d-flex flex-column position-relative mt-2"
            v-if="plannings.length"
          >
            <div style="overflow:auto; position:absolute; top: 0; left:0; right:0; bottom:0" class="card card-no-margin">
              <m-list
                ref="planningslist"
                :dynamic="true"
                :items="plannings"
                item-value="pl_id"
                :item-height="30"
                :limit="plannings_limit"
                :skip="plannings_skip"
                :total="plannings_total"
                :current="plannings_current"
                :bgstrip="true"
                @changerange="planningsChangerange"
                @itemclick="goPlanning"
              >
                <template v-slot:ths="{}">
                  <th @click="sortItems(['pl_cycle'])">
                    <span class="pointer">Cycle</span>
                  </th>
                  <th>
                    <span class="pointer">Récurrence</span>
                  </th>
                  <th>
                    <span class="pointer">Jours</span>
                  </th>
                  <th>
                    <span class="pointer">Date de début</span>
                  </th>
                  <th>
                    <span class="pointer">Date de fin</span>
                  </th>
                  <th>
                    <span class="pointer">Message</span>
                  </th>
                  <th>
                    <span class="pointer">Supprimer</span>
                  </th>
                </template>
                <template v-slot:tds="{ item }">
                  <td>
                    {{ item.pl_cycle }}
                  </td>
                  <td>
                    {{ item.pl_cyclevalue ? item.pl_cyclevalue : "" }}
                  </td>
                  <td>
                    {{ getWeekDays(item.pl_weekdays) }}
                  </td>
                  <td>
                    {{ item.pl_datedebut }}
                  </td>
                  <td>
                    {{ item.pl_dateend && item.pl_dateend !== "0000-00-00" ? item.pl_dateend : "" }}
                  </td>
                  <td :id="'planningMessage_' + item.pl_id">
                    {{ item.pl_message ? item.pl_message.substring(0, 10) + "..." : "" }}
                    {{ item.pl_messagemedia ? getThumbnail(item) : "" }}
                  </td>
                  <td>
                    <div class="d-flex flex-row">
                      <div @click="deletePlanning(item, $event)">
                        <icon width="20" height="20" name="trash-alt" class="text-danger"></icon>
                      </div>
                    </div>
                  </td>
                </template>
              </m-list>
            </div>
          </div>
        </div>
        <v-spinner ref="waitingSpinnerModal"></v-spinner>
        <div class="modal-footer d-flex flex-row justify-content-between">
          <div></div>
          <div>
            <button type="button" class="btn btn-secondary" @click="cancelWin">Fermer</button>
          </div>
        </div>
      </div>
    </div>
    <!-- <m-message-dialog v-model="dialogErr" title="Erreur" :text="dialogErrTxt"></m-message-dialog> -->
    <div style="display: none">
      <canvas ref="canvas" width="1024" height="768"></canvas>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScheduledMessagesList",
  components: {},
  props: {
    value: {
      default: false,
      type: Boolean
    },
    allEndUsers: {
      default: 0,
      type: Number
    },
    row_en: Object
  },
  data() {
    return {
      sortOrder: "asc",
      sortFields: ["pl_id"],
      plannings_limit: 50,
      plannings_skip: 0,
      plannings_total: 0,
      plannings_current: "",
      plannings: []
    };
  },

  async mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  created() {},
  methods: {
    getThumbnail(planning) {
      if (document.getElementById("planningMessageMedia_" + planning.pl_id)) return;
      var myCan = document.createElement("canvas");
      var img = new Image();
      img.src = this.$config.server_url + planning.pl_messagemedia;
      img.crossOrigin = "anonymous";
      img.onload = function() {
        myCan.id = "planningMedia_" + planning.pl_id;
        myCan.width = 20;
        myCan.height = 20;
        if (myCan.getContext) {
          var cntxt = myCan.getContext("2d");
          cntxt.drawImage(img, 0, 0, myCan.width, myCan.height);
          var dataURL = myCan.toDataURL();

          if (dataURL != null && dataURL != undefined) {
            var nImg = document.createElement("img");
            nImg.id = "planningMessageMedia_" + planning.pl_id;
            nImg.src = dataURL;
            document.getElementById("planningMessage_" + planning.pl_id).appendChild(nImg);
          }
        }
      };
    },
    calculateHeight() {
      return window.screen.height / 3;
    },
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },

    cancelWin() {
      this.$emit("input", false);
    },

    waitingSpinner(display, text) {
      if (this.$refs.waitingSpinnerModal) {
        this.$refs.waitingSpinnerModal.changeDisplay(display, text);
      }
    },

    async loadPlannings() {
      let types = [];
      let sort = this.sortFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.sortOrder;
        return accumulator;
      }, "");
      let params = {
        types: types,
        skip: this.plannings_skip,
        limit: this.plannings_limit,
        sort: sort,
        us_id: this.$store.state.user.us_id,
        pl_sendtoall: this.allEndUsers
      };
      if (this.row_en && this.row_en.en_id) params.en_ids = "[" + this.row_en.en_id + "]";

      this.cancelAxiosLoadContacts && this.cancelAxiosLoadContacts();
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/plannings", {
        params,
        cancelToken: new this.$axios.CancelToken(c => {
          this.cancelAxiosLoadContacts = c;
        })
      });
      if (!response || !response.data) return;

      this.plannings = response.data.data;
      this.plannings_total = response.data.meta.total;
    },

    planningsChangerange(skip, scrollTop) {
      this.plannings_skip = skip;
      this.loadPlannings();
    },
    sortItems(fields) {
      // console.log("fields", fields);
      if (fields.length && this.sortFields.length && fields[0] == this.sortFields[0]) {
        this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
      }
      this.sortFields = fields;

      this.loadPlannings(false);
    },
    async deletePlanning(planning) {
      let response = await this.$axios.delete(this.$config.server_url + "/extranet/2.0/plannings/" + planning.pl_id);
      if (response && response.data && response.data.error) alert("Une erreur est survenue lors de la suppression du message planifié");
      else if (response && response.data && response.data.data) this.loadPlannings();
    },
    getWeekDays(weekDaysInteger) {
      let days = {
        lun: 1,
        mar: 2,
        mer: 4,
        jeu: 8,
        ven: 16,
        sam: 32,
        dim: 64
      };
      let weekDaysSelection = [];
      for (const day in days) {
        const dayInteger = days[day];
        if (dayInteger & weekDaysInteger) weekDaysSelection.push(day);
      }
      return weekDaysSelection.join();
    },
    goPlanning(planning) {
      this.$emit("modifyplanning", planning);
    }
  },
  watch: {
    value(val) {
      if (val) this.loadPlannings();
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";

input {
  margin-top: 20px;
}

.trash {
  right: 65px;
}
</style>
