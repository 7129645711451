<template>
  <section class="d-flex conf">
    <div class="d-flex flex-column" id="conf">
      <div class="d-flex header-emotivi">
        <div class="d-flex align-items-center">
          <h1>Liste des fonctionnalités assignables à un bouton</h1>
        </div>
        <div class="flex-grow-1 d-flex flex-column justify-content-end align-items-end">
          <div class="d-flex flex-wrap">
            <button class="btn btn-primary color-white input-filter btn-sm" type="button" @click="goConf">
              <icon name="plus" scale="1" class="mr-2"></icon> Nouvelle fonctionnalité
            </button>
          </div>
        </div>
      </div>
      <div class="flex-grow-1 overflow-hidden d-flex">
        <div class="card card-no-margin">
          <m-list ref="fonctionnalitelist" :dynamic="true" :items="fonctionnalitelist" item-value="bf_id" :item-height="30" @itemclick="goConf">
            <template v-slot:ths="{}">
              <th class="" @click="sortItems(['bf_id'])">
                <span class="pointer">Numéro (bf_id)</span>
              </th>
              <th style="min-width: 50px">
                <span class="pointer">Nom de la fonctionnalité</span>
              </th>
              <th style="min-width: 50px">
                <span class="pointer">Etablissement</span>
              </th>
              <th style="min-width: 50px">
                <span class="pointer">Protocole de communication</span>
              </th>
              <th style="min-width: 50px">
                <span class="pointer">Actions</span>
              </th>
            </template>
            <template v-slot:tds="{ item }">
              <td>{{ item.bf_id }}</td>
              <td>{{ item.bf_name }}</td>
              <td>
                {{ item.es_id && typeof item.es_id === "object" ? item.es_id.es_name : "" }}
              </td>
              <td>{{ $Utils.getTextFromState($store.state.items_bf_communication, item.pc_id) }}</td>
              <td>
                <icon name="edit"></icon>
              </td>
            </template>
          </m-list>
        </div>
      </div>
    </div>
    <button-functionalities-win-edit
      :idselectedConfig="idselectedConfig"
      v-model="dialogConfig"
      @winEditClosed="closeWin"
    ></button-functionalities-win-edit>
  </section>
</template>
<script>
import ButtonFunctionalitiesWinEdit from "./ButtonFunctionalitiesWinEdit.vue";
export default {
  name: "ButtonFunctionalities",
  components: { ButtonFunctionalitiesWinEdit },
  data() {
    return {
      fonctionnalitelist: [],
      dialogConfig: false,
      idselectedConfig: 0
    };
  },
  async mounted() {
    await this.loadconf();
  },
  computed: {},
  watch: {},
  methods: {
    async loadconf() {
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/buttonFunctionalities");
      this.fonctionnalitelist = response.data.data;
    },
    goConf(item) {
      this.idselectedConfig = item.bf_id;
      // console.log("this.selectedConfig ", this.selectedConfig);
      this.dialogConfig = true;
    },
    closeWin() {
      this.dialogConfig = false;
      this.loadconf();
      this.idselectedConfig = 0;
    }
  }
};
</script>
<style>
@media (min-width: 576px) {
  .input-filter {
    width: auto;
    margin-left: 5px;
  }
}
@media (min-width: 768px) {
  .boxes {
    padding-top: 0px !important;
  }
}
.badge {
  position: relative;
}
.badge[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 0.7em;
  background: green;
  color: white;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  box-shadow: 0 0 1px #333;
}
.badge1 {
  position: relative;
  color: green;
  top: 2px;
  border-radius: 50px !important;
}
.badge1[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  /* top: -10px;
  right: -10px; */
  font-size: 0.7em;
  background: green;
  color: white;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  box-shadow: 0 0 1px #333;
}
</style>
