var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "d-flex w-100" },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-column  w-100", attrs: { id: "boxes" } },
        [
          _c("div", { staticClass: "d-flex header-emotivi" }, [
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("h1", [
                _vm._v(_vm._s(_vm.offers_total) + " offres commerciales")
              ])
            ]),
            _c(
              "div",
              {
                staticClass:
                  "flex-grow-1 d-flex justify-content-end align-items-end",
                staticStyle: { "margin-top": "-40px" }
              },
              [
                _c("m-form-checkbox", {
                  attrs: {
                    label: "Non-actif",
                    name: _vm.$Utils.randomstring("filterNotActive")
                  },
                  on: { input: _vm.loadOffers },
                  model: {
                    value: _vm.filterNotActive,
                    callback: function($$v) {
                      _vm.filterNotActive = $$v
                    },
                    expression: "filterNotActive"
                  }
                }),
                _c("m-form-text", {
                  staticClass: "ml-3",
                  attrs: {
                    label: "Rechercher",
                    "label-position": "top",
                    name: "search",
                    autocomplete: ""
                  },
                  on: { input: _vm.loadOffers },
                  model: {
                    value: _vm.filterText,
                    callback: function($$v) {
                      _vm.filterText = $$v
                    },
                    expression: "filterText"
                  }
                }),
                _c(
                  "button",
                  {
                    staticClass: "ml-3 btn btn-sm btn-primary color-white",
                    attrs: { type: "button" },
                    on: { click: _vm.addOffers }
                  },
                  [
                    _c("icon", {
                      staticClass: "mr-2",
                      attrs: { name: "plus", scale: "1" }
                    }),
                    _vm._v(" Nouveau ")
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "flex-grow-1 overflow-hidden d-flex w-100" },
            [
              _c(
                "div",
                { staticClass: "card card-no-margin w-100" },
                [
                  _c("m-list-simple", {
                    ref: "contactslist",
                    attrs: {
                      dynamic: true,
                      items: _vm.offers,
                      "item-value": "of_id",
                      "item-height": 30,
                      total: _vm.offers_total
                    },
                    on: { itemclick: _vm.editOffers },
                    scopedSlots: _vm._u([
                      {
                        key: "ths",
                        fn: function(ref) {
                          return [
                            _c(
                              "th",
                              {
                                staticStyle: { width: "40%" },
                                on: {
                                  click: function($event) {
                                    return _vm.sortItems(["of_name"])
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "pointer" }, [
                                  _vm._v("Désignation")
                                ])
                              ]
                            ),
                            _c("th", {}, [
                              _c("span", { staticClass: "pointer" }, [
                                _vm._v("Produits")
                              ])
                            ]),
                            _c("th", [
                              _c("span", { staticClass: "pointer" }, [
                                _vm._v("Crée le")
                              ])
                            ])
                          ]
                        }
                      },
                      {
                        key: "tds",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("td", [
                              _vm._v(" " + _vm._s(item.of_name) + " ")
                            ]),
                            _c(
                              "td",
                              _vm._l(item.offersproducts, function(
                                ofpr,
                                index
                              ) {
                                return _c("div", { key: ofpr.ofpr_id }, [
                                  _vm._v(
                                    " " + _vm._s(ofpr.pr_id.pr_name) + " "
                                  ),
                                  index < item.offersproducts.length - 1
                                    ? _c("span", [_vm._v(" - ")])
                                    : _vm._e()
                                ])
                              }),
                              0
                            ),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm
                                      .$moment(item.createdAt)
                                      .format("DD/MM/YYYY HH[H]mm")
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ]
          )
        ]
      ),
      _c("offers-win-edit", {
        attrs: { of_id: _vm.itemSelected.of_id },
        on: { savedWin: _vm.loadOffers },
        model: {
          value: _vm.dialog,
          callback: function($$v) {
            _vm.dialog = $$v
          },
          expression: "dialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }