var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: _vm.value ? "show" : "hide",
        style: _vm.value
          ? "display:block;background-color:#3333337a;"
          : "display:none;",
        attrs: { tabindex: "-1", role: "dialog", "aria-hidden": "true" }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-scrollable",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "div",
                {
                  staticClass: "modal-header d-flex flex-row align-items-center"
                },
                [
                  _vm.pa_id && _vm.pa_id > 0
                    ? _c("div", [
                        _c("h1", [_vm._v("Modifier l'envoi")]),
                        _c("p", [
                          _vm._v(" Envoyé le : "),
                          _vm.row_pa.pa_sent_date != "0000-00-00 00:00:00"
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm
                                      .$moment(_vm.row_pa.pa_sent_date)
                                      .format("DD/MM/YYYY")
                                  ) + " "
                                )
                              ])
                            : _c("span", [_c("b", [_vm._v("Kit à préparer")])])
                        ])
                      ])
                    : _c("div", [_c("h1", [_vm._v("Nouvel envoi")])])
                ]
              ),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c("h6", [
                    _c("span", { staticClass: "number-title" }, [_vm._v("1")]),
                    _vm._v("Adresse de livraison")
                  ])
                ]),
                _c("div", { staticClass: "row mt-3" }, [
                  _c("div", { staticClass: "col-md-8" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row" },
                      [
                        _c("m-form-text", {
                          attrs: {
                            label: "Civilité",
                            width: "20%",
                            type: "text",
                            name: _vm.$Utils.randomstring(
                              "pa_shipping_civility"
                            ),
                            rules: [_vm.$Validation.mandatory]
                          },
                          model: {
                            value: _vm.row_pa.pa_shipping_civility,
                            callback: function($$v) {
                              _vm.$set(_vm.row_pa, "pa_shipping_civility", $$v)
                            },
                            expression: "row_pa.pa_shipping_civility"
                          }
                        }),
                        _c("m-form-text", {
                          staticClass: "mx-2",
                          attrs: {
                            label: "Nom",
                            type: "text",
                            name: _vm.$Utils.randomstring("pa_shipping_name"),
                            rules: [_vm.$Validation.mandatory]
                          },
                          model: {
                            value: _vm.row_pa.pa_shipping_name,
                            callback: function($$v) {
                              _vm.$set(_vm.row_pa, "pa_shipping_name", $$v)
                            },
                            expression: "row_pa.pa_shipping_name"
                          }
                        }),
                        _c("m-form-text", {
                          attrs: {
                            label: "Prénom",
                            type: "text",
                            name: _vm.$Utils.randomstring(
                              "pa_shipping_firstname"
                            ),
                            rules: [_vm.$Validation.mandatory]
                          },
                          model: {
                            value: _vm.row_pa.pa_shipping_firstname,
                            callback: function($$v) {
                              _vm.$set(_vm.row_pa, "pa_shipping_firstname", $$v)
                            },
                            expression: "row_pa.pa_shipping_firstname"
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c(
                            "div",
                            { staticStyle: { position: "relative" } },
                            [
                              _c("m-form-text", {
                                attrs: {
                                  label: "Adresse",
                                  disabled: _vm.foreignAddress ? true : false
                                },
                                model: {
                                  value: _vm.address_autocomplete,
                                  callback: function($$v) {
                                    _vm.address_autocomplete = $$v
                                  },
                                  expression: "address_autocomplete"
                                }
                              }),
                              _vm.address_choice.length
                                ? _c(
                                    "div",
                                    { staticClass: "container-address-choice" },
                                    _vm._l(_vm.address_choice, function(
                                      address
                                    ) {
                                      return _c("div", { key: address.id }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-column address-choice",
                                            on: {
                                              click: function($event) {
                                                return _vm.selectAddress(
                                                  address
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("div", { staticClass: "bis" }, [
                                              _vm._v(
                                                _vm._s(address.properties.label)
                                              )
                                            ]),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  address.properties.context
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c("m-form-checkbox", {
                            staticClass: "mt-1",
                            attrs: {
                              label: "Adresse à l'étranger",
                              name: _vm.$Utils.randomstring("foreignAddress")
                            },
                            on: { input: _vm.foreignAddresscheckbox },
                            model: {
                              value: _vm.foreignAddress,
                              callback: function($$v) {
                                _vm.foreignAddress = $$v
                              },
                              expression: "foreignAddress"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row" },
                      [
                        _c("m-form-text", {
                          staticClass: "w-100 mt-3",
                          attrs: {
                            placeholder: "Adresse",
                            type: "text",
                            name: _vm.$Utils.randomstring(
                              "pa_shipping_address1"
                            ),
                            rules: [_vm.$Validation.mandatory],
                            disabled: _vm.foreignAddress ? false : true
                          },
                          model: {
                            value: _vm.row_pa.pa_shipping_address1,
                            callback: function($$v) {
                              _vm.$set(_vm.row_pa, "pa_shipping_address1", $$v)
                            },
                            expression: "row_pa.pa_shipping_address1"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row" },
                      [
                        _c("m-form-text", {
                          staticClass: "w-100 mt-2",
                          attrs: {
                            placeholder: "Complément d'adresse",
                            type: "text",
                            name: _vm.$Utils.randomstring(
                              "pa_shipping_address2"
                            ),
                            rules: [_vm.$Validation.mandatory]
                          },
                          model: {
                            value: _vm.row_pa.pa_shipping_address2,
                            callback: function($$v) {
                              _vm.$set(_vm.row_pa, "pa_shipping_address2", $$v)
                            },
                            expression: "row_pa.pa_shipping_address2"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row" },
                      [
                        _c("m-form-text", {
                          staticClass: "flex-grow-1 mt-2",
                          attrs: {
                            placeholder: "cp",
                            type: "text",
                            name: _vm.$Utils.randomstring("pa_shipping_zip"),
                            rules: [_vm.$Validation.mandatory],
                            disabled: _vm.foreignAddress ? false : true
                          },
                          model: {
                            value: _vm.row_pa.pa_shipping_zip,
                            callback: function($$v) {
                              _vm.$set(_vm.row_pa, "pa_shipping_zip", $$v)
                            },
                            expression: "row_pa.pa_shipping_zip"
                          }
                        }),
                        _c("m-form-text", {
                          staticClass: "flex-grow-1 mt-2 ml-2",
                          attrs: {
                            placeholder: "ville",
                            type: "text",
                            name: _vm.$Utils.randomstring("pa_shipping_city"),
                            rules: [_vm.$Validation.mandatory],
                            disabled: _vm.foreignAddress ? false : true
                          },
                          model: {
                            value: _vm.row_pa.pa_shipping_city,
                            callback: function($$v) {
                              _vm.$set(_vm.row_pa, "pa_shipping_city", $$v)
                            },
                            expression: "row_pa.pa_shipping_city"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row" },
                      [
                        _c("m-form-text", {
                          staticClass: "w-100",
                          attrs: {
                            placeholder: "Informations complémentaires",
                            type: "textarea",
                            name: _vm.$Utils.randomstring("pa_comment"),
                            rules: [_vm.$Validation.mandatory]
                          },
                          model: {
                            value: _vm.row_pa.pa_comment,
                            callback: function($$v) {
                              _vm.$set(_vm.row_pa, "pa_comment", $$v)
                            },
                            expression: "row_pa.pa_comment"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "col-md-4" }, [
                    _c("div", { staticClass: "bis" }, [
                      _vm._v("Contact client :")
                    ]),
                    _c("div", [_c("i", [_vm._v("Facturation")])]),
                    _c("div", { staticClass: "d-flex flex-row" }, [
                      _c("div", [
                        _vm._v(_vm._s(_vm.row_co.co_invoice_civility))
                      ]),
                      _c("div", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(_vm.row_co.co_invoice_name))
                      ]),
                      _c("div", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(_vm.row_co.co_invoice_firstname))
                      ])
                    ]),
                    _c("div", [_vm._v(_vm._s(_vm.row_co.co_invoice_address1))]),
                    _c("div", [_vm._v(_vm._s(_vm.row_co.co_invoice_address2))]),
                    _c("div", { staticClass: "d-flex flex-row" }, [
                      _c("div", [_vm._v(_vm._s(_vm.row_co.co_invoice_zip))]),
                      _c("div", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(_vm.row_co.co_invoice_city))
                      ])
                    ]),
                    _c("div", { staticClass: "color-emotivi-blue" }, [
                      _vm._v(_vm._s(_vm.row_co.co_invoice_tel))
                    ]),
                    _c("div", { staticClass: "color-emotivi-blue" }, [
                      _vm._v(_vm._s(_vm.row_co.co_invoice_email))
                    ]),
                    _c("div", [_c("i", [_vm._v("Livraison")])]),
                    _c("div", { staticClass: "d-flex flex-row" }, [
                      _c("div", [
                        _vm._v(_vm._s(_vm.row_co.co_shipping_civility))
                      ]),
                      _c("div", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(_vm.row_co.co_shipping_name))
                      ]),
                      _c("div", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(_vm.row_co.co_shipping_firstname))
                      ])
                    ]),
                    _c("div", [
                      _vm._v(_vm._s(_vm.row_co.co_shipping_address1))
                    ]),
                    _c("div", [
                      _vm._v(_vm._s(_vm.row_co.co_shipping_address2))
                    ]),
                    _c("div", { staticClass: "d-flex flex-row" }, [
                      _c("div", [_vm._v(_vm._s(_vm.row_co.co_shipping_zip))]),
                      _c("div", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(_vm.row_co.co_shipping_city))
                      ])
                    ]),
                    _c("div", { staticClass: "color-emotivi-blue" }, [
                      _vm._v(_vm._s(_vm.row_co.co_shipping_tel))
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row mt-5" }, [
                  _c("h6", [
                    _c("span", { staticClass: "number-title" }, [_vm._v("2")]),
                    _vm._v("Information d'envoi et de suivi")
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("m-form-select", {
                        attrs: {
                          label: "Statut de l'envoi ",
                          name: _vm.$Utils.randomstring("pa_state"),
                          rules: [_vm.$Validation.mandatory],
                          items: _vm.$store.state.items_pa_state
                        },
                        model: {
                          value: _vm.row_pa.pa_state,
                          callback: function($$v) {
                            _vm.$set(_vm.row_pa, "pa_state", $$v)
                          },
                          expression: "row_pa.pa_state"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-5" },
                    [
                      _c("m-form-text", {
                        attrs: {
                          label: "Nom de l'envoi",
                          type: "text",
                          name: _vm.$Utils.randomstring("pa_name"),
                          rules: [_vm.$Validation.mandatory]
                        },
                        model: {
                          value: _vm.row_pa.pa_name,
                          callback: function($$v) {
                            _vm.$set(_vm.row_pa, "pa_name", $$v)
                          },
                          expression: "row_pa.pa_name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c("m-form-select", {
                        attrs: {
                          label: "Société expéditrice",
                          name: _vm.$Utils.randomstring("pa_company"),
                          rules: [_vm.$Validation.mandatory],
                          items: _vm.$store.state.items_company
                        },
                        model: {
                          value: _vm.row_pa.pa_company,
                          callback: function($$v) {
                            _vm.$set(_vm.row_pa, "pa_company", $$v)
                          },
                          expression: "row_pa.pa_company"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-2" },
                    [
                      _c("m-form-text", {
                        attrs: {
                          label: "Numéro de suivi",
                          type: "text",
                          name: _vm.$Utils.randomstring("pa_trackingcode"),
                          rules: [_vm.$Validation.mandatory]
                        },
                        model: {
                          value: _vm.row_pa.pa_trackingcode,
                          callback: function($$v) {
                            _vm.$set(_vm.row_pa, "pa_trackingcode", $$v)
                          },
                          expression: "row_pa.pa_trackingcode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-2" },
                    [
                      _c("m-form-text", {
                        attrs: {
                          label: "Tarif",
                          type: "text",
                          name: _vm.$Utils.randomstring("pa_price"),
                          rules: [_vm.$Validation.mandatory]
                        },
                        model: {
                          value: _vm.row_pa.pa_price,
                          callback: function($$v) {
                            _vm.$set(_vm.row_pa, "pa_price", $$v)
                          },
                          expression: "row_pa.pa_price"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row mt-5" }, [
                  _c("h6", [
                    _c("span", { staticClass: "number-title" }, [_vm._v("3")]),
                    _vm._v("Matériel")
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "row d-flex flex-row align-items-end mb-4" },
                  [
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("m-form-combobox", {
                          attrs: {
                            label: "Produits",
                            "label-position": "top",
                            name: _vm.$Utils.randomstring("productSelected"),
                            "store-url":
                              _vm.$config.server_url + "/extranet/2.0/products",
                            "store-params": {},
                            "item-value": "pr_id",
                            "item-text": "pr_name"
                          },
                          on: { input: _vm.selectProduct },
                          model: {
                            value: _vm.productSelected,
                            callback: function($$v) {
                              _vm.productSelected = $$v
                            },
                            expression: "productSelected"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("m-form-combobox", {
                          attrs: {
                            label: "Autre matériel",
                            "label-position": "top",
                            name: _vm.$Utils.randomstring("materialSelected"),
                            "store-url":
                              _vm.$config.server_url +
                              "/extranet/2.0/materials",
                            "store-params": {},
                            "item-value": "ma_id",
                            "item-text": "ma_name"
                          },
                          on: { input: _vm.selectMaterial },
                          model: {
                            value: _vm.materialSelected,
                            callback: function($$v) {
                              _vm.materialSelected = $$v
                            },
                            expression: "materialSelected"
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-md-12 d-flex flex-row align-items-center"
                    },
                    [
                      _vm.materialsToSendFromProduct.length
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "container-materials-from-product d-flex flex-row"
                            },
                            _vm._l(_vm.materialsToSendFromProduct, function(
                              prma,
                              index
                            ) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "d-flex flex-column align-items-center mx-2"
                                },
                                [
                                  prma.ma_id && prma.ma_id.ma_id
                                    ? _c(
                                        "div",
                                        [
                                          _c("vue-load-image", [
                                            _c("img", {
                                              attrs: {
                                                slot: "image",
                                                src:
                                                  _vm.$config.server_url +
                                                  "/extranet/2.0/materials/image/" +
                                                  prma.ma_id.ma_id +
                                                  "/70/50?d=" +
                                                  new Date().getTime() +
                                                  "&token=" +
                                                  _vm.$store.state.accesstoken
                                              },
                                              slot: "image"
                                            }),
                                            _c("img", {
                                              attrs: {
                                                slot: "preloader",
                                                src: "images/image-loader70.gif"
                                              },
                                              slot: "preloader"
                                            }),
                                            _c(
                                              "div",
                                              {
                                                attrs: { slot: "error" },
                                                slot: "error"
                                              },
                                              [_vm._v("pas d'image")]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          prma.ma_id && prma.ma_id.ma_name
                                            ? prma.ma_id.ma_name
                                            : ""
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-row" },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("m-form-text", {
                                            staticClass: "mt-0 mr-2",
                                            attrs: { width: "46px" },
                                            model: {
                                              value: prma.prma_quantity,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  prma,
                                                  "prma_quantity",
                                                  $$v
                                                )
                                              },
                                              expression: "prma.prma_quantity"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "pointer",
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteMaterial(
                                                "product",
                                                prma
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("icon", {
                                            attrs: {
                                              name: "trash-alt",
                                              color: "red"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm._l(_vm.materialsToSendFromMaterial, function(
                        material,
                        index
                      ) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass:
                              "d-flex flex-column align-items-center mx-2"
                          },
                          [
                            material.ma_id && material.ma_id.ma_id
                              ? _c(
                                  "div",
                                  [
                                    _c("vue-load-image", [
                                      _c("img", {
                                        attrs: {
                                          slot: "image",
                                          src:
                                            _vm.$config.server_url +
                                            "/extranet/2.0/materials/image/" +
                                            material.ma_id.ma_id +
                                            "/70/50?d=" +
                                            new Date().getTime() +
                                            "&token=" +
                                            _vm.$store.state.accesstoken
                                        },
                                        slot: "image"
                                      }),
                                      _c("img", {
                                        attrs: {
                                          slot: "preloader",
                                          src: "images/image-loader70.gif"
                                        },
                                        slot: "preloader"
                                      }),
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "error" },
                                          slot: "error"
                                        },
                                        [_vm._v("pas d'image")]
                                      )
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    material.ma_id && material.ma_id.ma_name
                                      ? material.ma_id.ma_name
                                      : ""
                                  ) +
                                  " "
                              )
                            ]),
                            _c("div", { staticClass: "d-flex flex-row" }, [
                              _c(
                                "div",
                                [
                                  _c("m-form-text", {
                                    staticClass: "mt-0 mr-2",
                                    attrs: { width: "46px" },
                                    model: {
                                      value: material.prma_quantity,
                                      callback: function($$v) {
                                        _vm.$set(material, "prma_quantity", $$v)
                                      },
                                      expression: "material.prma_quantity"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "pointer",
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteMaterial(
                                        "material",
                                        material
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("icon", {
                                    attrs: { name: "trash-alt", color: "red" }
                                  })
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      })
                    ],
                    2
                  )
                ])
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "modal-footer d-flex flex-row justify-content-between"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      attrs: { type: "button" },
                      on: { click: _vm.deleteConfirmWin }
                    },
                    [_vm._v("Supprimer")]
                  ),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button" },
                        on: { click: _vm.cancelWin }
                      },
                      [_vm._v("Annuler")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-mouveal ml-2",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.saveWin(false)
                          }
                        }
                      },
                      [_vm._v("Enregistrer")]
                    )
                  ])
                ]
              )
            ])
          ]
        ),
        _c("m-confirm-dialog", {
          attrs: {
            text: "Souhaitez-vous effacer ce colis ?",
            title: "Confirmation",
            width: "500px"
          },
          on: {
            canceled: function($event) {
              _vm.confirmdelete = false
            },
            confirmed: function($event) {
              return _vm.deleteWin()
            }
          },
          model: {
            value: _vm.confirmdelete,
            callback: function($$v) {
              _vm.confirmdelete = $$v
            },
            expression: "confirmdelete"
          }
        }),
        _c("m-message-dialog", {
          attrs: { title: "Erreur", text: _vm.dialogErrTxt },
          model: {
            value: _vm.dialogErr,
            callback: function($$v) {
              _vm.dialogErr = $$v
            },
            expression: "dialogErr"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }