import Vue from "vue";
import App from "./App.vue";
import router from "./plugins/router";
import store from "./plugins/store";
import Utils from "./plugins/utils";
import axios from "axios";
// import jquery from "jquery";
// import popper from "popper.js";
import "bootstrap";
import _ from "lodash";
import moment from "moment";
//import config from "./config.js";
import Validation from "./plugins/validation";
import jquery from "jquery";
import Popper from "popper.js";
import "./plugins/components";
import "./plugins/filters";
import SocketIO from "socket.io-client";

const config = {
  server_url: process.env.VUE_APP_BACKEND_URL,
  config_url: process.env.VUE_APP_CONFIG_URL,
  affiliate_url: process.env.VUE_APP_AFFILIATE_URL,
  webapp_url: process.env.VUE_APP_WEBAPP_URL,
  bistri: {
    app_id: process.env.VUE_APP_BISTRI_APP_ID,
    app_key: process.env.VUE_APP_BISTRI_APP_KEY
  }
};
// console.log("process.env", process.env);
// load external js script from url
import LoadScript from "vue-plugin-load-script";
Vue.use(LoadScript);
let urlBistriSDK = "https://cdn.jsdelivr.net/gh/bistri/bistri-conference-js/dist/bistri.conference-3.5.1.js";
//let urlBistriSDk = "https://cdn.jsdelivr.net/gh/bistri/bistri-conference-js/dist/bistri.conference-3.4.5.js";
Vue.loadScript(urlBistriSDK)
  .then(() => {
    console.info("Bistri SDK loaded");
  })
  .catch(() => {
    console.error("Cannot get Bistri SDK file");
  });
// UUID module for BISTRI ROOM
import UUID from "vue-uuid";
Vue.use(UUID);

Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;
moment.locale("fr");

const socket = SocketIO(config.server_url);
Object.defineProperty(Vue.prototype, "$SocketIO", { value: socket });
store.dispatch("initSocketEvents");

Object.defineProperty(Vue.prototype, "$config", {
  value: config
});
Vue.config.productionTip = false;
Object.defineProperty(Vue.prototype, "$_", { value: _ });
Object.defineProperty(Vue.prototype, "$$", { value: jquery });
Object.defineProperty(Vue.prototype, "$moment", { value: moment });
//Object.defineProperty(Vue.prototype, "$config", { value: config });
Object.defineProperty(Vue.prototype, "$Utils", { value: Utils });
Object.defineProperty(Vue.prototype, "$Validation", { value: Validation });
Object.defineProperty(Vue.prototype, "$Popper", { value: Popper });

Object.defineProperty(Vue.prototype, "$axios", { value: axios });
axios.defaults.headers.common["x-auth-accesstoken"] = store.state.accesstoken;
axios.interceptors.request.use(
  function(config2) {
    store.commit("set_mainisloading", true);
    return config2;
  },
  function(error) {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function(response) {
    store.commit("set_mainisloading", false);
    let ok = true;
    const originalRequest = response.config;
    if (response.data && response.data.err) {
      if (response.data.err.key == "token_mandatory" || response.data.err.key == "user_not_found") {
        ok = false;
      }
      if (response.data.err.key == "token_expired") {
        return axios
          .get(config.server_url + "/api/1.0/refreshtoken", {
            headers: { "x-auth-refreshtoken": store.state.refreshtoken }
          })
          .then(response2 => {
            if (!response2.data.err) {
              store.commit("set_tokens", {
                accesstoken: response2.data.accesstoken,
                refreshtoken: response2.data.refreshtoken
              });
              originalRequest.headers["x-auth-accesstoken"] = response2.data.accesstoken;
              return axios(originalRequest);
            }
          });
      }
    }
    if (!ok) {
      router.push("/login");
    }
    return response;
  },
  function(error) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled", error.message);
    } else {
      console.error("response error", error);
      return Promise.reject(error);
    }
  }
);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
