<template>
  <div
    class="modal"
    :class="value ? 'show' : 'hide'"
    :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content" :style="'width:' + widthData">
        <div class="modal-header">
          <h3 class="pt-2 ml-3">{{ title }}</h3>
        </div>
        <div class="modal-body" ref="modalBody" v-if="text2">
          <pre>{{ text2 }}</pre>
        </div>
        <div class="modal-body" ref="modalBody" v-else v-html="text"></div>
        <div class="modal-footer">
          <button class="btn btn-primary" @click="deleteWin">Ok</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mmessagedialog",
  props: {
    value: [Boolean],
    title: [String],
    text: [String, Object],
    sousText: [String],
    width: [String],
    redirect: [String]
  },
  data() {
    return {
      dialog: false,
      widthData: "",
      text2: ""
    };
  },
  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  watch: {
    value: function(val) {
      if (typeof this.text === "object") {
        this.text2 = JSON.stringify(this.text, undefined, 2);
      }
      this.widthData = this.width ? this.width : "500px";
      this.dialog = val;
    }
  },
  components: {},
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.deleteWin();
      }
    },
    deleteWin() {
      this.dialog = false;
      if (this.redirect) {
        this.$router.push("/" + this.redirect);
      }
      this.$emit("input", false);
      this.$emit("close");
    }
  }
};
</script>
