<template>
  <section class="d-flex">
    <div id="infrarouges">
      <div class="d-flex header-emotivi">
        <div class="d-flex align-items-center">
          <h1>Codes Infrarouges</h1>
        </div>
      </div>
      <div class="flex-grow-1 overflow-hidden d-flex" style="min-height: 460px">
        <div class="card card-no-margin">
          <div class="row">
            <div class="d-flex flex-row flex-wrap bis" style="margin-right: 20px; min-width: 350px">
              <m-form-combobox
                class="flex-grow-1 ml-4 basis-50"
                label="Modèle"
                label-position="top"
                :name="$Utils.randomstring('tt_id')"
                v-model="typetv"
                :autoSelectOnFocus="true"
                :store-url="$config.server_url + '/extranet/2.0/tvtype'"
                :store-params="{ tt_id: typetv.tt_id }"
                item-value="tt_name"
                item-text="tt_name"
                @input="loadCodes()"
              ></m-form-combobox>
            </div>
            <div style="margin-top: 40px">
              <button @click="createModel" class="btn btn-primary color-white input-filter btn-sm" type="button">
                <icon name="plus" scale="1" class="mr-2"></icon>
              </button>
              <button
                @click="openTvModelWinEdit"
                v-if="typetv.tt_id"
                class="btn btn-primary color-white input-filter btn-sm"
                type="button"
                style="margin-left: 20px"
              >
                <icon name="edit" scale="1" class="mr-2"></icon>
              </button>
            </div>
          </div>
          <br />
          <div class="d-flex flex-row">
            <div class="d-flex flex-row flex-wrap" style="margin-right: 20px">
              <span class="bis"> Modèle sélectionné : {{ modelName }}</span>
            </div>
            <div class="more" :data-popover="textMessage()">?</div>
          </div>
          <br />
          <div class="d-flex">
            <div class="w-100 mr-3" v-if="typetv.tt_id != -100">
              <div class="row">
                <div class="bis" style="margin-left: 30px">Liste des codes</div>
                <div class="w-20 mr-3">
                  <div class="d-flex" style="margin: 0px 0px 10px 10px">
                    <button @click="openTvCodeWinEdit" class="btn btn-primary color-white input-filter btn-sm" type="button">
                      <icon name="plus" scale="1" class="mr-2"></icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="card mb-0">
                <div class="flex-grow-0">
                  <div class="align-items-center">
                    <m-list-simple
                      :items="tvCodes"
                      item-value="ma_key"
                      v-if="tvCodes.length != 0"
                      :styleItemSelected="true"
                      @itemclick="openTvCodeWinEdit"
                    >
                      <template v-slot:tds="{ item }">
                        <td width="250">{{ item.tc_function }}</td>
                        <td class="d-flex justify-content-end">
                          <icon name="edit"></icon>
                        </td>
                      </template>
                    </m-list-simple>
                    <div v-else-if="!typetv.tt_id">Aucun Modèle n'est séléctionné!</div>
                    <div v-else>Ce modèle n'a aucun code pour le moment!</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-100 mr-3" :style="minwidth(typetv.tt_id)">
              <div class="row">
                <div class="bis" style="margin-left: 30px">Liste des macros</div>
                <div class="w-20 mr-3">
                  <div class="d-flex" style="margin: 0px 0px 10px 10px">
                    <button @click="openTvMacroWinEdit(null)" class="btn btn-primary color-white input-filter btn-sm" type="button">
                      <icon name="plus" scale="1" class="mr-2"></icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="card mb-0">
                <div class="flex-grow-0">
                  <div class="align-items-center">
                    <m-list-simple
                      :items="tvMacros"
                      item-value="ma_key"
                      v-if="tvMacros.length != 0"
                      :styleItemSelected="true"
                      :current="keySelectedMacros"
                      @itemclick="loadMacrosCode"
                    >
                      <template v-slot:ths="{}">
                        <th width="100">
                          <span class="pointer">Clé</span>
                        </th>
                        <th width="200">
                          <span class="pointer">Label</span>
                        </th>
                        <th width="40">
                          <span class="pointer">Actions</span>
                        </th>
                      </template>
                      <template v-slot:tds="{ item }">
                        <td>{{ item.ma_key }}</td>
                        <td>{{ item.ma_label }}</td>
                        <td class="d-flex justify-content-end" @click="openTvMacroWinEdit(item)">
                          <icon name="edit"></icon>
                        </td>
                      </template>
                    </m-list-simple>
                    <div v-else-if="!typetv.tt_id">Aucun Modèle n'est séléctionné!</div>
                    <div v-else>Ce modèle n'a aucune macro!</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-100 mr-3" style="min-width: 500px" v-if="typetv.tt_id != -100">
              <div class="row">
                <div class="bis" style="margin-left: 30px">Liste des codes macros</div>
                <div class="w-20 mr-3">
                  <div class="d-flex" style="margin: 0px 0px 10px 10px">
                    <button @click="openTvMacroCodeWinEdit" class="btn btn-primary color-white input-filter btn-sm" type="button">
                      <icon name="plus" scale="1" class="mr-2"></icon>
                    </button>
                  </div>
                </div>
              </div>
              <div class="card mb-0">
                <div class="flex-grow-0">
                  <div class="d-flex align-items-center">
                    <div v-if="!typetv.tt_id">Aucun Modèle n'est séléctionné!</div>
                    <div v-else-if="!selectedMacros.ma_id">Ce modèle n'a aucune code Macro</div>
                    <div v-else-if="!macrosCode.length && selectedMacros.ma_id">Aucune code macro n'a été défini pour ce macro!</div>
                    <m-list-simple v-else :items="macrosCode" item-value="ma_key" :styleItemSelected="true" @itemclick="openTvMacroCodeWinEdit">
                      <template v-slot:ths="{}">
                        <th width="100">
                          <span class="pointer">Ordre</span>
                        </th>
                        <th width="150">
                          <span class="pointer">Fonction</span>
                        </th>
                        <th width="100">
                          <span class="pointer">Délai</span>
                        </th>
                        <th width="100">
                          <span class="pointer" style="margin: 0 auto; width: 100px">Actions</span>
                        </th>
                        <th width="100">
                          <span class="pointer" style="margin: 0 auto; width: 100px">Changer Ordre</span>
                        </th>
                      </template>
                      <template v-slot:tds="{ item }">
                        <td>{{ item.mc_order }}</td>
                        <td>{{ item.tc_id.tc_function }}</td>
                        <td>{{ item.mc_delay }}</td>
                        <td>
                          <icon name="edit"></icon>
                        </td>
                        <td class="d-flex justify-content-end">
                          <icon
                            @click.stop="changerOrder(-1, item)"
                            class="icon-menu mr-2"
                            name="arrow-up"
                            :style="ColorArrowUp(item.mc_order)"
                          ></icon>
                          <icon
                            class="icon-menu"
                            name="arrow-down"
                            @click.stop="changerOrder(1, item)"
                            :style="ColorArrowDown(item.mc_order, macrosCode.length)"
                          ></icon>
                        </td>
                      </template>
                    </m-list-simple>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <tv-model-win-edit
        v-model="showTvModelWinEdit"
        @winEditCanceled="cancelWin"
        @winEditSaved="saveWin"
        :tt_id="typetv.tt_id"
        @winEditDeleted="deleteWin"
      ></tv-model-win-edit>
      <tv-macro-win-edit
        v-model="showTvMacroWinEdit"
        @winEditCanceled="cancelWin"
        @winEditSaved="saveWinMacro"
        :tt_id="typetv.tt_id"
        :tt_name="typetv.tt_name"
        :ma_id="selectedMacros.ma_id"
        @winEditDeleted="deleteWin"
      >
      </tv-macro-win-edit>
      <tv-macro-code-win-edit
        v-model="showTvMacroCodeWinEdit"
        @winEditCanceled="cancelWin"
        @winEditSaved="saveWinCodeMacro"
        :tt_id="typetv.tt_id"
        :selectedMacros="selectedMacros"
        @winEditDeleted="deleteWin"
        :mc_id="mc_id"
      ></tv-macro-code-win-edit>
      <tv-code-win-edit
        :tc_id="tc_id"
        v-model="showTvCodeWinEdit"
        @winEditCanceled="cancelWin"
        @winEditSaved="saveWinCode"
        @winEditDeleted="deleteWin"
        :modelTV="typetv"
      ></tv-code-win-edit>
      <m-message-dialog v-model="success" :title="successTitle" :text="successText"></m-message-dialog>

      <popover ref="popover" />
      <m-message-dialog v-model="notModelChoosen" title="Erreur" :text="notSelectedError" @close="notModelChoosen = false"></m-message-dialog>
    </div>
  </section>
</template>
<script>
import TvModelWinEdit from "./TvModelWinEdit.vue";
import TvMacroWinEdit from "./TvMacroWinEdit.vue";
import TvMacroCodeWinEdit from "./TvMacroCodeWinEdit.vue";
import Popover from "../components/Popover.vue";
import TvCodeWinEdit from "./TvCodeWinEdit.vue";
import MMessageDialog from "../components/MMessageDialog.vue";
export default {
  name: "infrarouges",
  components: {
    TvModelWinEdit,
    TvMacroWinEdit,
    TvMacroCodeWinEdit,
    Popover,
    TvCodeWinEdit
  },
  props: {},
  MMessageDialog,
  data() {
    return {
      showTvModelWinEdit: false,
      showTvMacroWinEdit: false,
      showTvMacroCodeWinEdit: false,
      showTvCodeWinEdit: false,
      notModelChoosen: false,
      notSelectedError: "",
      success: false,
      successTitle: "Ajout",
      successText: "Ajouté avec succès",
      successSousText: "",
      typetv: {},
      tvMacros: [],
      tvCodes: [],
      selectedMacros: {},
      macrosCode: [],
      modelName: "Aucun",
      tc_id: 0,
      mc_id: 0,
      styleObject: {
        color: "grey"
      },
      keySelectedMacros: ""
    };
  },
  watch: {},
  async mounted() {},
  computed: {},
  methods: {
    async loadCodes(theSelectedMacros) {
      this.macrosCode = [];
      this.selectedMacros = {};
      let response2 = await this.$axios.get(this.$config.server_url + "/extranet/2.0/tvcodes/" + this.typetv.tt_id);
      this.tvMacros = [];
      this.tvMacros = response2.data.data.macros;
      this.tvCodes = response2.data.data.codes;
      this.macrosCode = [];
      if (response2.data.data.macros.length) {
        this.macrosCode = response2.data.data.macros[0].macroscodes;
        if (theSelectedMacros) {
          this.selectedMacros = theSelectedMacros;
          for (let i = 0; i < this.tvMacros.length; i++) {
            if (this.tvMacros[i].ma_id == theSelectedMacros.ma_id) {
              this.macrosCode = this.tvMacros[i].macroscodes;

              break;
            }
          }
        } else {
          this.selectedMacros = response2.data.data.macros[0];
        }
      }
      this.modelName = this.typetv.tt_name;
    },
    async loadMacrosCode(item) {
      this.macrosCode = item.macroscodes;
      this.selectedMacros = item;
      this.keySelectedMacros = item.ma_key;
    },
    openTvModelWinEdit() {
      this.showTvModelWinEdit = true;
    },
    openTvMacroWinEdit(item) {
      if (!this.typetv.value) {
        this.notSelectedError = "Veuillez d'abord séléctionner un model!";
        this.notModelChoosen = true;
      } else {
        if (item) {
          this.selectedMacros = item;
        } else this.selectedMacros = {};
        this.showTvMacroWinEdit = true;
      }
    },
    openTvMacroCodeWinEdit(item) {
      if (!this.selectedMacros.ma_id) {
        this.notSelectedError = "Veuillez d'abord choisir une macro!";
        this.notModelChoosen = true;
      } else {
        this.mc_id = item.mc_id;
        this.showTvMacroCodeWinEdit = true;
      }
    },
    openTvCodeWinEdit(item) {
      if (!this.typetv.value) {
        this.notSelectedError = "Veuillez d'abord séléctionner un model!";
        this.notModelChoosen = true;
      } else {
        this.tc_id = item.tc_id;
        this.showTvCodeWinEdit = true;
      }
    },
    updated() {
      this.$refs.popover.init();
    },
    textMessage() {
      let message = "Aucun modèle TV Sélectionné";
      let protocol = "Aucun";
      if (this.typetv.tp_id) {
        protocol = this.typetv.tp_id.tp_name;
      }
      if (this.typetv.tt_name) {
        message = "Modèle télé : " + this.typetv.tt_name + "; Groupe de télé: " + this.typetv.tt_group + "; Protocole utilisé: " + protocol;
      }
      return message;
    },
    cancelWin() {
      this.showTvModelWinEdit = false;
      this.showTvMacroWinEdit = false;
      this.showTvMacroCodeWinEdit = false;
      this.showTvCodeWinEdit = false;
    },
    async saveWin(response) {
      if (response.data.data) this.typetv = response.data.data;
      this.showTvModelWinEdit = false;
      // typetv
    },
    saveWinMacro(reponse, name) {
      this.showTvMacroWinEdit = false;
      this.loadCodes();
    },
    saveWinCodeMacro(selected) {
      this.showTvMacroCodeWinEdit = false;
      this.keySelectedMacros = selected.ma_key;
      this.loadCodes(selected);
    },
    saveWinCode(name) {
      this.showTvCodeWinEdit = false;
      this.loadCodes();
    },
    createMacro() {
      this.selectedMacros = {};
      this.showTvMacroWinEdit = true;
    },
    createModel() {
      this.typetv = {};
      this.typetv.tt_id = -1;
      this.tvMacros = [];
      this.selectedMacros = {};
      this.showTvModelWinEdit = true;
    },
    deleteWin() {
      this.showTvModelWinEdit = false;
      this.showTvCodeWinEdit = false;
      this.showTvMacroWinEdit = false;
      this.showTvMacroCodeWinEdit = false;
      this.loadCodes(this.selectedMacros);
    },
    async changerOrder(sens, item) {
      // changeorder
      let response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/tvmacrocode/changeorder/" + sens + "/" + item.mc_id, {});
      this.macrosCode = response.data.data;
    },
    ColorArrowUp(mc_order) {
      if (mc_order === 0) return this.styleObject;
    },
    ColorArrowDown(mc_order, length) {
      let last = length - 1;
      if (mc_order === last) return this.styleObject;
    },
    minwidth(tt_id) {
      if (tt_id === -100) return "min-width:750px";
      return "min-width:550px";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";

.more {
  border-radius: 50%;
  background-color: #91295a;
  width: 30px;
  height: 30px;
  text-align: center;
  color: white;
  padding-top: 4px;
  font-weight: bold;
}

.table-list {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    display: block;
    cursor: pointer;

    td {
      padding: 2px 10px;
      font-size: 0.75rem;

      div.txtoverflow {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    // &:hover {
    //   // background-color: $mygris;
    // }
    // &.active {
    //   // background-color: $mybleu-clair;
    //   // td {
    //   //   color: $mybleu-fonce;
    //   //   i {
    //   //     color: $mybleu-fonce;
    //   //   }
    //   // }
    // }
  }
}

@media (min-width: 992px) {
  .card-group {
    height: 100%;
  }
}
</style>
