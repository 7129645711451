<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div>
              <h1>
                Création d'une commande
              </h1>
              <p>Pour le contact : {{ row_co | formatContactNameSimple("co_invoice_") }}</p>
            </div>

            <!--  <div class="d-flex flex-row align-items-center active">
              <label class="label-checkbox" for="active">Activer</label>
              <input type="checkbox" id="actif" name="actif" v-model="row_co.us_active" />
            </div> -->
          </div>
          <div class="modal-body">
            <div class="row">
              <h6>
                <span class="number-title"><icon name="shopping-cart"></icon></span>Votre commande
              </h6>
            </div>
            <div class="row">
              <div class="col-md-12">
                <m-list-simple :dynamic="true" :items="orderLines" item-value="us_id" :item-height="30">
                  <template v-slot:ths="{}">
                    <th style="min-width:50px;">
                      <span class="pointer">Offre</span>
                    </th>
                    <th class="">
                      <span class="pointer">Produit</span>
                    </th>
                    <th class="">
                      <span class="pointer">Utilisateur final</span>
                    </th>
                    <th style="min-width:30px;">Éditer</th>
                  </template>
                  <template v-slot:tds="{ item }">
                    <td>
                      {{ item.offer.of_name }}
                    </td>
                    <td>
                      <div v-for="pr in item.offer.offersproducts" :key="pr.ofpr_id">{{ pr.pr_id.pr_name }},</div>
                    </td>
                    <td class="">
                      {{ item.enduser.en_fullname }}
                    </td>
                    <td class="">
                      <div @click="editOrderLine(item)"><icon name="edit"></icon></div>
                    </td>
                  </template>
                </m-list-simple>
              </div>
            </div>
            <div class="row ">
              <h6><span class="number-title">1</span>Choisir l'offre</h6>
            </div>
            <div class="row d-flex flex-row align-items-end">
              <div class="col-md-6">
                <m-form-combobox
                  label="L'offre"
                  label-position="top"
                  :name="$Utils.randomstring('offerSelected')"
                  v-model="offerSelected"
                  :store-url="$config.server_url + '/extranet/2.0/offers'"
                  :store-params="{}"
                  item-value="of_id"
                  item-text="of_name"
                ></m-form-combobox>
              </div>
              <div class="col-md-6" v-if="isSubscriptionOffer">
                <m-form-date
                  label="Date de début de l'abonnement"
                  v-model="newOrder.or_subscription_date_start"
                  :name="$Utils.randomstring('or_subscription_date_start')"
                  :rules="[$Validation.mandatory]"
                ></m-form-date>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-12">
                <button class="btn btn-primary" @click="showOffer = !showOffer">Afficher l'offre</button>
              </div>
            </div>
            <div class="row mt-3" v-if="showOffer && offerSelected.of_id">
              <div class="col-md-12">
                <div>Description de l'offre : {{ offerSelected.of_description }}</div>
                <div>Engagement : {{ offerSelected.of_commit_period }}</div>
                <div>Paiement de départ : {{ offerSelected.of_deposit }}</div>
              </div>
            </div>
            <div v-if="isSubscriptionOffer">
              <div class="row mt-5">
                <h6><span class="number-title">2</span> Renseigner l'utilisateur final</h6>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <m-form-text
                    label="Nom complet (nom et prénom) *"
                    type="text"
                    v-model="newOrder.enduser.en_fullname"
                    :name="$Utils.randomstring('en_fullname')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                </div>
                <div class="col-md-6">
                  <m-form-date
                    label="Date de naissance"
                    v-model="newOrder.enduser.en_birthday"
                    :name="$Utils.randomstring('en_birthday')"
                  ></m-form-date>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <m-form-text
                    label="Webapp identifiant *"
                    type="text"
                    v-model="newOrder.enduser.en_webapp_login"
                    :name="$Utils.randomstring('en_webapp_login')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                </div>
                <div class="col-md-6">
                  <m-form-text
                    label="Webapp mot de passe *"
                    type="text"
                    v-model="newOrder.enduser.en_webapp_password"
                    :name="$Utils.randomstring('en_webapp_password')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                </div>
              </div>

              <div class="row mt-5">
                <h6><span class="number-title">3</span>Vérifier les informations du colis</h6>
              </div>

              <div class="row">
                <div class="col-md-12 mt-4">
                  <h5>Adresse de livraison</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <m-form-text
                    label="Civilité"
                    type="text"
                    v-model="newOrder.parcel.pa_shipping_civility"
                    :name="$Utils.randomstring('pa_shipping_civility')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                </div>
                <div class="col-md-5">
                  <m-form-text
                    label="Nom"
                    type="text"
                    v-model="newOrder.parcel.pa_shipping_name"
                    :name="$Utils.randomstring('pa_shipping_name')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                </div>
                <div class="col-md-5">
                  <m-form-text
                    label="Prénom"
                    type="text"
                    v-model="newOrder.parcel.pa_shipping_firstname"
                    :name="$Utils.randomstring('pa_shipping_firstname')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <m-form-text
                    label="Adresse 1"
                    type="text"
                    v-model="newOrder.parcel.pa_shipping_address1"
                    :name="$Utils.randomstring('pa_shipping_address1')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                </div>
                <div class="col-md-6">
                  <m-form-text
                    label="Adresse 2"
                    type="text"
                    v-model="newOrder.parcel.pa_shipping_address2"
                    :name="$Utils.randomstring('pa_shipping_address2')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <m-form-text
                    label="Code postal"
                    type="text"
                    v-model="newOrder.parcel.pa_shipping_zip"
                    :name="$Utils.randomstring('pa_shipping_zip')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                </div>
                <div class="col-md-5">
                  <m-form-text
                    label="Ville"
                    type="text"
                    v-model="newOrder.parcel.pa_shipping_city"
                    :name="$Utils.randomstring('pa_shipping_city')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                </div>
                <div class="col-md-5">
                  <m-form-text
                    label="Pays"
                    type="text"
                    v-model="newOrder.parcel.pa_shipping_country"
                    :name="$Utils.randomstring('pa_shipping_country')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                </div>
              </div>
              <div class="row d-flex flex-row align-items-end">
                <div class="col-md-6">
                  <m-form-text
                    label="Téléphone"
                    type="text"
                    v-model="newOrder.parcel.pa_shipping_tel"
                    :name="$Utils.randomstring('pa_shipping_tel')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                </div>
                <div class="col-md-6">
                  <button class="btn btn-primary">Vérifier l'adresse</button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mt-4">
                  <h5>Informations complémentaires</h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <m-form-text
                    label="Nom de l'envoi"
                    type="text"
                    v-model="newOrder.pa_name"
                    :name="$Utils.randomstring('pa_name')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-md-12">
                  <m-form-text
                    label="Commentaire"
                    type="textarea"
                    :rows="4"
                    v-model="newOrder.pa_comment"
                    :name="$Utils.randomstring('pa_comment')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-md-12">
                  <button class="btn btn-primary" @click="validLine(false)">
                    {{ this.newOrder.index ? "Éditer la ligne" : "Ajouter à la commande" }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between mt-5">
            <div>
              <button type="button" class="btn btn-secondary" @click="cancelWin">
                Annuler toute la commande
              </button>
            </div>
            <!--  <button type="button" class="btn btn-danger" @click="deleteConfirmWin">
              Supprimer
            </button> -->
            <div>
              <button type="button" class="btn btn-primary btn-mouveal ml-2" @click="saveWin(false)" :disabled="!orderLines.length">
                Valider la commande
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--   <m-confirm-dialog
        v-model="confirmdelete"
        text="Souhaitez-vous effacer ce client ?"
        title="Confirmation"
        width="500px"
        @canceled="confirmdelete = false"
        @confirmed="deleteWin()"
      ></m-confirm-dialog> -->
      <m-message-dialog v-model="dialogErr" title="Erreur" :text="dialogErrTxt"></m-message-dialog>
    </div>
  </transition>
</template>

<script>
export default {
  name: "orderWinEdit",
  components: {},
  props: {
    co_id: Number,
    value: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      row_co: {},
      //confirmdelete: false,
      offerSelected: {},
      newOrder: {
        of_id: 0,
        or_subscription_date_start: this.$moment(),
        enduser: { en_fullname: "", en_birthday: "", en_webapp_login: "", en_webapp_password: "" },
        parcel: {
          pa_shipping_civility: "",
          pa_shipping_name: "",
          pa_shipping_firstname: "",
          pa_shipping_address1: "",
          pa_shipping_address2: "",
          pa_shipping_zip: "",
          pa_shipping_city: "",
          pa_shipping_country: "",
          pa_shipping_tel: "",
          pa_comment: "",
          pa_name: "",
          pa_contact_fullname: ""
        }
      },
      dialogNewLigneOrder: false,
      showOffer: false,
      dialogErrTxt: "",
      dialogErr: false,
      orderLines: []
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.loadContact();
      }
    }
  },
  computed: {
    isSubscriptionOffer() {
      let isSubscription = false;
      if (this.offerSelected.of_id) {
        for (let i = 0; i < this.offerSelected.offersproducts.length; i++) {
          const ofpr = this.offerSelected.offersproducts[i];
          if (ofpr.pr_id.pr_type === "SUBSCRIPTION") {
            isSubscription = true;
            break;
          }
        }
      }
      return isSubscription;
    }
  },

  mounted() {
    this.user = this.$store.state.user;
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  created() {},
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    async loadContact() {
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/contacts/" + this.co_id);
      if (!response || !response.data) return;
      this.row_co = response.data.data;
      this.preFillShipping();
    },
    preFillShipping() {
      this.newOrder.parcel.pa_shipping_civility = this.row_co.co_shipping_civility;
      this.newOrder.parcel.pa_shipping_name = this.row_co.co_shipping_name;
      this.newOrder.parcel.pa_shipping_firstname = this.row_co.co_shipping_firstname;
      this.newOrder.parcel.pa_shipping_address1 = this.row_co.co_shipping_address1;
      this.newOrder.parcel.pa_shipping_address2 = this.row_co.co_shipping_address2;
      this.newOrder.parcel.pa_shipping_zip = this.row_co.co_shipping_zip;
      this.newOrder.parcel.pa_shipping_city = this.row_co.co_shipping_city;
      this.newOrder.parcel.pa_shipping_country = this.row_co.co_shipping_country;
      this.newOrder.parcel.pa_shipping_tel = this.row_co.co_shipping_tel;
    },
    resetNewOrder() {
      this.offerSelected = {};
      this.newOrder = {
        of_id: 0,
        or_subscription_date_start: this.$moment(),
        enduser: { en_fullname: "", en_birthday: "", en_webapp_login: "", en_webapp_password: "" },
        parcel: {
          pa_shipping_civility: "",
          pa_shipping_name: "",
          pa_shipping_firstname: "",
          pa_shipping_address1: "",
          pa_shipping_address2: "",
          pa_shipping_zip: "",
          pa_shipping_city: "",
          pa_shipping_country: "",
          pa_shipping_tel: "",
          pa_comment: "",
          pa_name: "",
          pa_contact_fullname: ""
        }
      };
    },
    resetOrderLines() {
      this.orderLines = [];
    },
    validLine() {
      if (!this.offerSelected.of_id) {
        this.dialogErrTxt = "<span class='bis'>Vous devez choisir une offre</span>";
        this.dialogErr = true;
        return;
      }
      let err = [];
      if (this.isSubscriptionOffer) {
        this.newOrder.parcel.us_id = this.user.us_id;
        let fieldRequired = [
          { objet: "enduser", field: "en_fullname", text: "Nom complet de l'utilisateur final" },
          { objet: "enduser", field: "en_webapp_login", text: "Webapp login" },
          { objet: "enduser", field: "en_webapp_password", text: "Webapp password" },
          { objet: "parcel", field: "pa_shipping_name", text: "Adresse de livraison > le nom " }
        ];
        for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
          const field = fieldRequired[ifi];
          if (!this.newOrder[field.objet][field.field]) err.push(field);
        }
      }
      if (err.length) {
        this.dialogErrTxt = "<span class='bis'>Vous devez remplir les champs : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error.text + " <br>";
        }
        this.dialogErr = true;
      } else {
        this.newOrder.of_id = this.offerSelected.of_id;
        this.newOrder.offer = this.offerSelected;
        if (!this.newOrder.index) {
          this.newOrder.index = this.orderLines.length + 1;
          this.orderLines.push(this.newOrder);
        } else {
          this.orderLines[this.newOrder.index - 1] = this.newOrder;
        }
        this.resetNewOrder();
        this.preFillShipping();
      }
    },
    editOrderLine(item) {
      this.newOrder = item;
      this.offerSelected = item.offer;
    },
    async saveWin() {
      let response;
      let dataToSend = {
        co_id: this.row_co.co_id,
        or_subscription_date_start: this.orderLines[0].or_subscription_date_start,
        orderlines_data: this.orderLines
      };
      await this.$axios.post(this.$config.server_url + "/extranet/2.0/orders", dataToSend);

      if (response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }
      this.resetNewOrder();
      this.resetOrderLines();
      this.$router.push("/contacts/" + this.co_id + "/details");
      this.$emit("input", false);
      this.$emit("orderSaved", this.co_id);
    },
    cancelWin() {
      this.resetNewOrder();
      this.resetOrderLines();
      this.$router.push("/contacts/" + this.co_id + "/details");
      this.$emit("input", false);
    },
    addOrderLine() {
      this.resetNewOrder();
      this.preFillShipping();
    }
    /*     deleteConfirmWin() {
      this.confirmdelete = true;
    }, */
    /*     async deleteWin() {
      await this.$axios.delete(this.$config.server_url + "/extranet/2.0/users/" + this.user_id);
      this.confirmdelete = false;
      this.$router.push("/contacts/" + this.co_id + "/details");
      this.$emit("input", false);
      this.$emit("orderSaved", this.co_id);
    } */
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";
.establishment {
  background-color: #daece7;
  padding: 5px 10px;
  margin-right: 10px;
}
</style>
