import Vue from "vue";
import VueRouter from "vue-router";

import store from "./store";
// import config from "../config.js";
import axios from "axios";

import Home from "../views/Home.vue";
// import ResetPass from "../views/ResetPass.vue";
import Login from "../views/Login.vue";
//import Contacts2 from "../views/Contacts2.vue";
import Actions from "../views/Actions.vue";
// import Rights from "../views/Rights.vue";
// import Logs from "../views/Logs.vue";
// import Import from "../views/Import.vue";
// import Exports from "../views/Exports.vue";
// import Cgu from "../views/Cgu.vue";
import Statistiques from "../views/Statistiques.vue";
import Contacts from "../views/Contacts.vue";
import Invoices from "../views/Invoices.vue";
import Boxes from "../views/Boxes.vue";
import Users from "../views/Users.vue";
import Materials from "../views/Materials.vue";
import Products from "../views/Products.vue";
import Offers from "../views/Offers.vue";
import BoxeDetails from "../views/BoxeDetails.vue";
import Establishments from "../views/Establishments.vue";
import Tvs from "../views/Tvs.vue";
import TvDetails from "../views/TvDetails.vue";
import Infrarouges from "../views/Infrarouges.vue";
import ButtonFunction from "../views/ButtonFunctionalities.vue";
import Calendar from "../views/Calendar.vue";
import AttestationFiscale from "../views/AttestationFiscale.vue";

// import VideoCall from "../views/VideoCall.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { menu: "home", permission: ["LATEAM", "DIRECTOR", "EMOTIVI_USER", "STAFF", "TECHNICIAN"] },
    beforeEnter: checkUserType
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: { menu: "home", permission: ["LATEAM", "DIRECTOR", "EMOTIVI_USER", "STAFF", "TECHNICIAN"] },
    beforeEnter: checkUserType
  },
  {
    path: "/boxes",
    name: "boxes",
    component: Boxes,
    meta: { menu: "boxes", permission: ["LATEAM", "DIRECTOR", "EMOTIVI_USER", "STAFF", "TECHNICIAN", "PARTNER"] },
    beforeEnter: checkUserType
  },
  {
    path: "/boxes/new",
    name: "boxes-new",
    component: Boxes,
    meta: { menu: "boxes", permission: ["LATEAM", "DIRECTOR", "EMOTIVI_USER", "TECHNICIAN"] },
    beforeEnter: checkUserType
  },
  {
    path: "/boxes/:en_id",
    name: "boxes-detail",
    component: BoxeDetails,
    meta: { menu: "boxes", permission: ["LATEAM", "DIRECTOR", "EMOTIVI_USER", "STAFF", "TECHNICIAN"] },
    beforeEnter: checkUserType
  },
  {
    path: "/boxes/:en_id/edit",
    name: "boxes-edit",
    component: BoxeDetails,
    meta: { menu: "boxes", permission: ["LATEAM", "DIRECTOR", "EMOTIVI_USER", "STAFF", "TECHNICIAN"] },
    beforeEnter: checkUserType
  },
  {
    path: "/boxes/:en_id/displayLogs",
    name: "boxes-display-logs",
    component: BoxeDetails,
    meta: { menu: "boxes", permission: ["LATEAM", "DIRECTOR", "EMOTIVI_USER", "STAFF", "TECHNICIAN"] },
    beforeEnter: checkUserType
  },
  {
    path: "/tvs",
    name: "tvs",
    component: Tvs,
    meta: { menu: "tvs", permission: ["LATEAM", "DIRECTOR", "EMOTIVI_USER", "STAFF", "TECHNICIAN", "PARTNER"] },
    beforeEnter: checkUserType
  },
  {
    path: "/tvs/new",
    name: "tvs-new",
    component: Tvs,
    meta: { menu: "tvs", permission: ["LATEAM", "DIRECTOR", "EMOTIVI_USER", "TECHNICIAN"] },
    beforeEnter: checkUserType
  },
  {
    path: "/tvs/:en_id",
    name: "tvs-detail",
    component: TvDetails,
    meta: { menu: "tvs", permission: ["LATEAM", "DIRECTOR", "EMOTIVI_USER", "STAFF", "TECHNICIAN"] },
    beforeEnter: checkUserType
  },
  {
    path: "/tvs/:en_id/edit",
    name: "tvs-edit",
    component: TvDetails,
    meta: { menu: "tvs", permission: ["LATEAM", "DIRECTOR", "EMOTIVI_USER", "STAFF", "TECHNICIAN"] },
    beforeEnter: checkUserType
  },

  {
    path: "/establishments",
    name: "establishments",
    component: Establishments,
    meta: { menu: "establishments", permission: ["LATEAM", "DIRECTOR", "PARTNER"] },
    beforeEnter: checkUserType
  },
  {
    path: "/establishments/:es_id",
    name: "establishments-detail",
    component: Establishments,
    meta: { menu: "establishments", permission: ["LATEAM", "DIRECTOR"] },
    beforeEnter: checkUserType
  },
  {
    path: "/contacts",
    name: "contactslist",
    component: Contacts,
    meta: { menu: "contacts", permission: ["LATEAM", "DIRECTOR", "EMOTIVI_USER", "STAFF", "TECHNICIAN", "PARTNER"] },
    beforeEnter: checkUserType
  },
  {
    path: "/calendar",
    name: "calendar",
    component: Calendar,
    meta: { menu: "calendar", permission: ["LATEAM", "DIRECTOR", "EMOTIVI_USER", "STAFF", "TECHNICIAN", "PARTNER"] },
    beforeEnter: checkUserType
  },
  {
    path: "/invoices",
    name: "invoiceslist",
    component: Invoices,
    meta: { menu: "invoices", permission: ["LATEAM", "EMOTIVI_USER"] },
    beforeEnter: checkUserType
  },
  {
    path: "/invoices/:or_id",
    name: "invoiceslist",
    component: Invoices,
    meta: { menu: "invoices", permission: ["LATEAM", "EMOTIVI_USER"] },
    beforeEnter: checkUserType
  },
  {
    path: "/contacts/:co_id/edit",
    name: "contactedit",
    component: Contacts,
    meta: { permission: ["LATEAM", "DIRECTOR", "EMOTIVI_USER", "STAFF", "PARTNER"] },
    beforeEnter: checkUserType
  },
  {
    path: "/contacts/:co_id/details",
    name: "contactdetails",
    component: Contacts,
    meta: { permission: ["LATEAM", "DIRECTOR", "EMOTIVI_USER", "STAFF", "TECHNICIAN", "PARTNER"] },
    beforeEnter: checkUserType
  },
  {
    path: "/contacts/:co_id/order",
    name: "contactdetails",
    component: Contacts,
    meta: { permission: ["LATEAM", "EMOTIVI_USER"] },
    beforeEnter: checkUserType
  },

  {
    path: "/parcels/:en_id",
    name: "parcel",
    component: BoxeDetails,
    meta: { menu: "parcels", permission: ["LATEAM", "EMOTIVI_USER"] },
    beforeEnter: checkUserType
  },
  {
    path: "/parcels/:en_id/edit/:pa_id",
    name: "parcel-edit",
    component: BoxeDetails,
    meta: { menu: "parcels", permission: ["LATEAM", "EMOTIVI_USER"] },
    beforeEnter: checkUserType
  },
  {
    path: "/users",
    name: "users",
    component: Users,
    meta: { menu: "users", permission: ["LATEAM", "DIRECTOR", "PARTNER"] },
    beforeEnter: checkUserType
  },
  {
    path: "/users/:us_id",
    name: "users-detail",
    component: Users,
    meta: { menu: "users", permission: ["LATEAM", "DIRECTOR", "PARTNER"] },
    beforeEnter: checkUserType
  },
  {
    path: "/materials",
    name: "materials",
    component: Materials,
    meta: { menu: "materials", permission: ["LATEAM", "EMOTIVI_USER"] },
    beforeEnter: checkUserType
  },
  {
    path: "/materials/:id",
    name: "materials-detail",
    component: Materials,
    meta: { menu: "materials", permission: ["LATEAM", "EMOTIVI_USER"] },
    beforeEnter: checkUserType
  },
  {
    path: "/products",
    name: "products",
    component: Products,
    meta: { menu: "products", permission: ["LATEAM", "EMOTIVI_USER"] },
    beforeEnter: checkUserType
  },
  {
    path: "/products/:id",
    name: "products-detail",
    component: Products,
    meta: { menu: "products", permission: ["LATEAM", "EMOTIVI_USER"] },
    beforeEnter: checkUserType
  },
  {
    path: "/offers",
    name: "offers",
    component: Offers,
    meta: { menu: "offers", permission: ["LATEAM", "EMOTIVI_USER"] },
    beforeEnter: checkUserType
  },
  {
    path: "/offers/:id",
    name: "offers-detail",
    component: Offers,
    meta: { menu: "offers", permission: ["LATEAM", "EMOTIVI_USER"] },
    beforeEnter: checkUserType
  },
  {
    path: "/actions",
    name: "actions",
    component: Actions,
    meta: { menu: "actions", permission: ["LATEAM", "EMOTIVI_USER", "DIRECTOR", "STAFF", "TECHNICIAN"] },
    beforeEnter: checkUserType
  },
  {
    path: "/infrarouges",
    name: "infrarouges",
    component: Infrarouges,
    meta: { menu: "infrarouges", permission: ["LATEAM"] },
    beforeEnter: checkUserType
  },
  {
    path: "/fonctionnalites",
    name: "ButtonFunctionalities",
    component: ButtonFunction,
    meta: { menu: "fonctionnalite", permission: ["LATEAM"] },
    beforeEnter: checkUserType
  },
  {
    path: "/attestationfiscale",
    name: "AttestationFiscale",
    component: AttestationFiscale,
    meta: { menu: "attestationfiscale", permission: ["LATEAM"] },
    beforeEnter: checkUserType
  },

  {
    path: "/statistiques",
    name: "statistiques",
    component: Statistiques,
    meta: { menu: "statistiques", permission: ["LATEAM", "DIRECTOR"] },
    beforeEnter: checkUserType
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/resetpass/:us_id/:challenge",
    name: "resetpass",
    component: Login
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach(async (to, from, next) => {
  async function autolog() {
    // console.log("to, from", to, from);
    if (!store.state.accesstoken) return false;
    if (store.state.connected) return true;
    try {
      let response = await axios.get(process.env.VUE_APP_BACKEND_URL + "/extranet/2.0/autosignin", {
        accesstoken: store.state.accesstoken
      });
      if (response.data.err) {
        return false;
      }
      store.commit("set_connexion", {
        accesstoken: response.data.data.accesstoken,
        refreshtoken: response.data.data.refreshtoken,
        user: response.data.data.row,
        preferences: response.data.data.preferences
      });
      return true;
    } catch (error) {
      console.error("ko", error);
      return false;
    }
  }
  if (to.name == "resetpass") return next();
  if (to.name == "login") return next();

  if (!store.state.connected) await autolog();

  if (to.name == "home" && store.state.connected) return next();
  if (to.name == "home" && !store.state.connected) return next({ path: "/login" });
  if (!store.state.connected) return next({ path: "/login" });

  store.dispatch("showMenu", false);
  return next();
});
// middleware to check user permission
function checkUserType(to, from, next) {
  if (store.state.user && store.state.user.us_type) {
    if (!to.meta.permission) {
      console.warn("NO META PERMISSION DATA IN THIS ROUTE : ", to.path);
      return next({ path: "/" });
    }
    let hasAccess = false;
    to.meta.permission.forEach(permission => {
      if (permission === store.state.user.us_type) hasAccess = true;
    });
    if (!hasAccess) {
      console.warn("USER IS NOT AUTHORIZED TO ACCESS THIS ROUTE : ", to.path);
      if (to.name == "home" && store.state.user.us_type === "PARTNER") return next({ path: "/contacts" });
      return next({ path: from.path });
    }
    next();
  } else next({ path: "/" });
}
export default router;
