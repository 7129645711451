var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column", attrs: { id: "app" } },
    [
      _c("div", { staticClass: "top-bar flex-grow-0" }, [
        _c("div", { staticClass: "d-flex topbar" }, [
          _c("div", { staticClass: "d-flex align-items-center" }, [
            _vm.$store.state.connected
              ? _c(
                  "div",
                  {
                    staticClass: "collapse-menu img-hamberger mr-2 pointer",
                    on: {
                      click: function($event) {
                        return _vm.showMenus($event)
                      }
                    }
                  },
                  [
                    _c("icon", {
                      staticClass: "mr-2",
                      attrs: { width: "30", height: "30", name: "bars" }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c("div", [
              _c("img", {
                staticClass: "img-fluid pointer img-logo",
                attrs: { src: "images/logo-emotivi.png", alt: "logo Mouveal" },
                on: {
                  click: function($event) {
                    return _vm.go("/")
                  }
                }
              })
            ])
          ]),
          _vm.$store.state.connected
            ? _c(
                "div",
                { staticClass: "d-flex flex-grow-1 justify-content-end" },
                [
                  _vm.$store.state.user.us_type !== "PARTNER"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex container-search align-items-center"
                        },
                        [
                          _c("m-form-date", {
                            staticClass:
                              "mx-1 col-form-label-sm withouttopmargin",
                            attrs: {
                              label: "Début",
                              name: _vm.$Utils.randomstring("filterDateStart"),
                              formatInput: "DD/MM/YYYY",
                              inline: true
                            },
                            model: {
                              value: _vm.filterDateStart,
                              callback: function($$v) {
                                _vm.filterDateStart = $$v
                              },
                              expression: "filterDateStart"
                            }
                          }),
                          _c("m-form-date", {
                            staticClass:
                              "mx-1 col-form-label-sm withouttopmargin",
                            attrs: {
                              label: "Fin",
                              name: _vm.$Utils.randomstring("filterDateEnd"),
                              formatInput: "DD/MM/YYYY",
                              inline: true
                            },
                            model: {
                              value: _vm.filterDateEnd,
                              callback: function($$v) {
                                _vm.filterDateEnd = $$v
                              },
                              expression: "filterDateEnd"
                            }
                          }),
                          _c("m-form-text", {
                            staticClass: "mr-2 input-research",
                            attrs: {
                              name: _vm.$Utils.randomstring("search"),
                              placeholder: "Rechercher sur tout le site",
                              withoutMargin: true,
                              appendIcon: "search"
                            },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "d-flex container-name align-items-center" },
                    [
                      _c("div", [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatContactNameSimple")(_vm.user, "us_")
                            )
                          )
                        ]),
                        _c("div", { staticClass: "small-text" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatvalue")(
                                _vm.user.us_type,
                                "items_us_type",
                                true,
                                false
                              )
                            )
                          )
                        ])
                      ])
                    ]
                  ),
                  _vm.$store.state.user.us_type !== "PARTNER"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "documentation ml-2 d-flex align-items-center"
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "documents\\Documentation_etablissement_interface_de_gestion.pdf",
                                target: "_blank"
                              }
                            },
                            [
                              _c("icon", {
                                staticClass: "mr-2",
                                attrs: {
                                  width: "22",
                                  height: "22",
                                  name: "book",
                                  color: "#199aaa"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "deconnexion pointer ml-2 d-flex align-items-center",
                      on: { click: _vm.logout }
                    },
                    [
                      _c("icon", {
                        staticClass: "mr-2",
                        attrs: {
                          width: "22",
                          height: "22",
                          name: "sign-out-alt",
                          color: "#199aaa"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ]),
        this.$store.state.showMenu
          ? _c("div", { staticClass: "menu" }, [
              _c(
                "ul",
                {
                  staticClass: "nav d-flex flex-column justify-content-center"
                },
                [
                  _vm.$store.getters["checkUserAccess"](_vm.$router, "/home")
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "nav-link pointer",
                            attrs: { to: "/" },
                            on: {
                              click: function($event) {
                                return _vm.go("/")
                              }
                            }
                          },
                          [_vm._v("Accueil")]
                        )
                      ])
                    : _vm._e(),
                  _vm.$store.getters["checkUserAccess"](_vm.$router, "/boxes")
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "nav-link pointer",
                            attrs: { to: { path: "/boxes" } },
                            on: {
                              click: function($event) {
                                return _vm.go("/boxes")
                              }
                            }
                          },
                          [_vm._v("Boxes")]
                        )
                      ])
                    : _vm._e(),
                  _vm.$store.getters["checkUserAccess"](
                    _vm.$router,
                    "/contacts"
                  )
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "nav-link pointer",
                            attrs: { to: { path: "/contacts" } },
                            on: {
                              click: function($event) {
                                return _vm.go("/contacts")
                              }
                            }
                          },
                          [_vm._v("Contacts")]
                        )
                      ])
                    : _vm._e(),
                  _vm.$store.getters["checkUserAccess"](
                    _vm.$router,
                    "/calendar"
                  )
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "nav-link pointer",
                            attrs: { to: { path: "/calendar" } },
                            on: {
                              click: function($event) {
                                return _vm.go("/calendar")
                              }
                            }
                          },
                          [_vm._v("Calendrier")]
                        )
                      ])
                    : _vm._e(),
                  _vm.$store.getters["checkUserAccess"](
                    _vm.$router,
                    "/invoices"
                  )
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "nav-link pointer",
                            attrs: { to: { path: "/invoices" } },
                            on: {
                              click: function($event) {
                                return _vm.go("/invoices")
                              }
                            }
                          },
                          [_vm._v("Comptabilité")]
                        )
                      ])
                    : _vm._e(),
                  _vm.$store.getters["checkUserAccess"](
                    _vm.$router,
                    "/materials"
                  )
                    ? _c("li", { staticClass: "border-plus" })
                    : _vm._e(),
                  _vm.$store.getters["checkUserAccess"](
                    _vm.$router,
                    "/materials"
                  )
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "nav-link pointer",
                            on: {
                              click: function($event) {
                                return _vm.go("/materials")
                              }
                            }
                          },
                          [_vm._v("Matériels")]
                        )
                      ])
                    : _vm._e(),
                  _vm.$store.getters["checkUserAccess"](
                    _vm.$router,
                    "/products"
                  )
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "nav-link pointer",
                            attrs: { to: { path: "/products" } },
                            on: {
                              click: function($event) {
                                return _vm.go("/products")
                              }
                            }
                          },
                          [_vm._v("Produits et services")]
                        )
                      ])
                    : _vm._e(),
                  _vm.$store.getters["checkUserAccess"](_vm.$router, "/offers")
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "nav-link pointer",
                            attrs: { to: { path: "/offers" } },
                            on: {
                              click: function($event) {
                                return _vm.go("/offers")
                              }
                            }
                          },
                          [_vm._v("Offres commerciales")]
                        )
                      ])
                    : _vm._e(),
                  _vm.$store.getters["checkUserAccess"](_vm.$router, "/users")
                    ? _c("li", { staticClass: "border-plus" })
                    : _vm._e(),
                  _vm.$store.getters["checkUserAccess"](_vm.$router, "/users")
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "nav-link pointer",
                            attrs: { to: { path: "/users" } },
                            on: {
                              click: function($event) {
                                return _vm.go("/users")
                              }
                            }
                          },
                          [_vm._v("Equipe")]
                        )
                      ])
                    : _vm._e(),
                  _vm.$store.getters["checkUserAccess"](
                    _vm.$router,
                    "/establishments"
                  )
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "nav-link pointer",
                            attrs: { to: { path: "/establishments" } },
                            on: {
                              click: function($event) {
                                return _vm.go("/establishments")
                              }
                            }
                          },
                          [_vm._v("Etablissements")]
                        )
                      ])
                    : _vm._e(),
                  _vm.$store.getters["checkUserAccess"](
                    _vm.$router,
                    "/statistiques"
                  )
                    ? _c("li", { staticClass: "border-plus" })
                    : _vm._e(),
                  _vm.$store.getters["checkUserAccess"](
                    _vm.$router,
                    "/statistiques"
                  )
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "nav-link pointer",
                            on: {
                              click: function($event) {
                                return _vm.go("/statistiques")
                              }
                            }
                          },
                          [_vm._v("Statistiques")]
                        )
                      ])
                    : _vm._e(),
                  _vm.$store.getters["checkUserAccess"](_vm.$router, "/actions")
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "nav-link pointer",
                            attrs: { to: { path: "/actions" } },
                            on: {
                              click: function($event) {
                                return _vm.go("/actions")
                              }
                            }
                          },
                          [_vm._v("Actions")]
                        )
                      ])
                    : _vm._e(),
                  _vm.$store.getters["checkUserAccess"](
                    _vm.$router,
                    "/infrarouges"
                  )
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "nav-link pointer",
                            attrs: { to: { path: "/infrarouges" } },
                            on: {
                              click: function($event) {
                                return _vm.go("/infrarouges")
                              }
                            }
                          },
                          [_vm._v("Infrarouges")]
                        )
                      ])
                    : _vm._e(),
                  _vm.$store.getters["checkUserAccess"](
                    _vm.$router,
                    "/fonctionnalites"
                  )
                    ? _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "nav-link pointer",
                            attrs: { to: { path: "/fonctionnalites" } },
                            on: {
                              click: function($event) {
                                return _vm.go("/fonctionnalites")
                              }
                            }
                          },
                          [_vm._v("Fonctionnalités Bouton")]
                        )
                      ])
                    : _vm._e()
                ]
              )
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "container-emotivi flex-grow-1 d-flex" },
        [
          !this.$store.state.showResearchView ? _c("router-view") : _vm._e(),
          this.$store.state.showResearchView
            ? _c("research-view", {
                attrs: {
                  researchVal: this.$store.state.research,
                  filterDateStart: this.$store.state.filterDateStart,
                  filterDateEnd: this.$store.state.filterDateEnd
                },
                on: { deleteResearch: _vm.deleteResearch }
              })
            : _vm._e()
        ],
        1
      ),
      _c("footer", { staticClass: "flex-grow-0" }, [
        _c(
          "div",
          {
            staticClass: "d-flex flex-row align-items-center",
            staticStyle: { padding: "5px 50px" }
          },
          [
            _c("div", [
              _vm._v(" Design et développement : "),
              _c("span", { staticClass: "bis ml-2" }, [
                _vm._v("La.team - " + _vm._s(_vm.theCurrentYear) + " - ")
              ]),
              _c("span", {
                staticClass: "font-weight-bold text-secondary",
                attrs: { id: "appVersion" }
              })
            ])
          ]
        )
      ]),
      _c("m-message-dialog", {
        attrs: { title: "Erreur", text: _vm.$store.state.dialogErrTxt },
        on: {
          close: function($event) {
            return _vm.$store.dispatch("showDialogError", false)
          }
        },
        model: {
          value: _vm.$store.state.dialogErr,
          callback: function($$v) {
            _vm.$set(_vm.$store.state, "dialogErr", $$v)
          },
          expression: "$store.state.dialogErr"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }