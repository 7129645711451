<template>
  <transition name="fade">
    <div
      class="modal d-flex justify-content-center align-items-center"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="videoContainer">
        <div class="row">
          <div class="col-12">
            <div v-if="type === 'audio'" class="h2">{{ switchLang("audioCall") }}</div>
            <div v-else class="h2">{{ switchLang("videoCall") }}</div>
            <p class="h4">{{ switchLang("subtitle") }}</p>
            <hr />
          </div>
        </div>

        <v-bistri
          class="bistriWindow"
          :row_bo="row_bo"
          :en_id="en_id"
          ref="bistritag"
          @hangup="hangup"
          :onlyaudio="type === 'audio' ? true : false"
        ></v-bistri>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "video-call",
  props: {
    row_bo: Object,
    en_id: Number,
    type: String,
    value: {
      default: false,
      type: Boolean
    }
  },

  computed: {
    lang() {
      return this.$store.state.lang;
    }
  },
  data() {
    return {
      pageText: {
        videoCall: {
          fr: "Appel Vidéo",
          en: "Video Call"
        },
        audioCall: {
          fr: "Appel Audio",
          en: "Video Call"
        },
        subtitle: {
          fr: "Contactez le résident via sa télévision",
          en: "Contact the resident on television"
        }
      }
    };
  },
  // async beforeRouteLeave(to, from, next) {
  //   if (!(from.name === "video" && to.name === "audio")) {
  //     await this.$refs.bistritag.disconnectAll();
  //     next();
  //   }
  // },
  methods: {
    switchLang(key) {
      if (this.lang === "fr") {
        return this.pageText[key].fr;
      } else {
        return this.pageText[key].en;
      }
    },
    hangup() {
      this.$emit("input", false);
      this.$emit("hangup");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
@import "../scss/styles.scss";
.modal-footer {
  margin-top: 50px;
}
.videoContainer {
  background-color: #fff;
  padding: 20px;
  height: 75%;
  width: 75%;
}
.videoContainer img {
  width: 100%;
}
.btn-agrandir {
  padding: 6px;
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ced4da;
}

.btn-racrocher {
  width: 100%;
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: 500;
}
</style>
