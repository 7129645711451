<template>
  <section class="d-flex w-100">
    <div class="d-flex flex-column  w-100" id="boxes">
      <div class="d-flex header-emotivi">
        <div class="d-flex align-items-center">
          <h1>{{ users_total }} membre{{ users_total > 1 ? "s" : "" }}</h1>
        </div>
        <div class="flex-grow-1 d-flex justify-content-end align-items-end" style="margin-top:-40px;">
          <m-form-text
            class="ml-3"
            label="Rechercher"
            label-position="top"
            v-model="filterText"
            name="saerch"
            autocomplete
            @input="loadUsers"
          ></m-form-text>
          <button class="ml-3 btn btn-sm btn-primary color-white" type="button" @click="addUsers">
            <icon name="plus" scale="1" class="mr-2"></icon> Nouveau
          </button>
        </div>
      </div>
      <div class="flex-grow-1 overflow-hidden d-flex w-100">
        <div class="card card-no-margin w-100">
          <m-list-simple
            ref="contactslist"
            :dynamic="true"
            :items="users"
            item-value="us_id"
            :item-height="30"
            :limit="users_limit"
            :skip="users_skip"
            :total="users_total"
            @itemclick="editUsers"
          >
            <template v-slot:ths="{}">
              <th style="min-width:50px;" @click="sortItems(['us_name'])">
                <span class="pointer">Nom</span>
              </th>
              <th class="" @click="sortItems(['us_type'])">
                <span class="pointer">Type</span>
              </th>
              <th class="" @click="sortItems(['us_login'])">
                <span class="pointer">Email</span>
              </th>
              <th class="" @click="sortItems([])">
                <span class="pointer">Établissement</span>
              </th>
              <th @click="sortItems(['createdAt'])">
                <span class="pointer">Date de création</span>
              </th>
            </template>
            <template v-slot:tds="{ item }">
              <td>
                {{ item | formatContactNameSimple("us_") }}
              </td>
              <td>{{ getLabelUserType(item.us_type) }}</td>
              <td class="">
                {{ item.us_login }}
              </td>
              <td>
                <div v-if="item.us_type !== 'LATEAM'">
                  <div v-for="es in item.establishments" :key="es.es_id">{{ es.es_name }},</div>
                </div>
                <div v-else>Tous</div>
              </td>
              <td>
                {{ $moment(item.createdAt).format("DD/MM/YYYY HH[H]mm") }}
              </td>
            </template>
          </m-list-simple>
        </div>
      </div>
    </div>
    <user-win-edit :us_id="itemSelected.us_id" v-model="dialog" @userSaved="userSaved" @userCancel="userCancel"></user-win-edit>
  </section>
</template>

<script>
export default {
  name: "contacts",
  components: {},
  data() {
    return {
      users: [],
      users_total: 0,
      users_limit: 0,
      users_skip: 0,
      showOccupedRoom: false,
      filterText: "",
      sortOrder: "asc",
      sortFields: [],
      itemsTypes: [
        { text: "Tous", value: "0" },
        { text: "Inscrit", value: "2" },
        { text: "Payé", value: "3" }
      ],
      itemSelected: {},
      dialog: false,
      backToEstablishment: false
    };
  },
  watch: {},
  async mounted() {
    let id = parseInt(this.$route.params.us_id);
    this.backToEstablishment = this.$route.query.establishment === "true" ? true : false;
    if (id) {
      this.itemSelected.us_id = id;
      this.dialog = true;
    }
    this.loadUsers();
  },
  computed: {},
  methods: {
    sortItems(fields) {
      if (fields.length && this.sortFields.length && fields[0] == this.sortFields[0]) {
        this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
      }
      this.sortFields = fields;
      if (fields[0] !== "us_connection_date") {
        this.loadUsers();
      } else {
        this.patients.sort((a, b) => {
          if (this.sortOrder === "asc") {
            if (this.$moment(a.us_connection_date).isBefore(b.us_connection_date)) return -1;
            if (this.$moment(a.us_connection_date).isAfter(b.us_connection_date)) return 1;
            if (this.$moment(a.us_connection_date).isSame(b.us_connection_date)) return 0;
          } else {
            if (this.$moment(a.us_connection_date).isBefore(b.us_connection_date)) return 1;
            if (this.$moment(a.us_connection_date).isAfter(b.us_connection_date)) return -1;
            if (this.$moment(a.us_connection_date).isSame(b.us_connection_date)) return 0;
          }
        });
      }
    },
    async loadUsers() {
      let sort = this.sortFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.sortOrder;
        return accumulator;
      }, "");
      let params = {
        sort: sort
      };
      if (this.users_limit) params.limit = this.users_limit;
      if (this.users_skip) params.skip = this.users_skip;
      if (this.filterText) params.text = this.filterText;
      this.cancelAxiosLoadContacts && this.cancelAxiosLoadContacts();
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/users", {
        params,
        cancelToken: new this.$axios.CancelToken(c => {
          this.cancelAxiosLoadContacts = c;
        })
      });
      if (!response || !response.data) return;
      this.users = response.data.data;
      this.users_total = response.data.meta.total;
    },
    addUsers() {
      this.itemSelected = {};
      this.$router.push("/users/-1");
      this.dialog = true;
    },
    editUsers(item) {
      this.itemSelected = item;
      this.$router.push("/users/" + this.itemSelected.us_id);
      this.dialog = true;
    },
    userCancel() {
      if (this.backToEstablishment) {
        this.$router.push("/establishments");
      }
    },
    userSaved(us_id) {
      if (this.backToEstablishment) {
        this.$router.push("/establishments");
      } else this.loadUsers();
    },
    importUsers() {},
    exportUsers() {},
    getLabelUserType(keyUserType) {
      if (this.$store.state.items_us_type && Array.isArray(this.$store.state.items_us_type)) {
        let labelUserType = "";
        this.$store.state.items_us_type.forEach(us_type => {
          if (us_type.value === keyUserType) labelUserType = us_type.text;
        });
        return labelUserType;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";
</style>
