<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div v-if="pa_id && pa_id > 0">
              <h1>Modifier l'envoi</h1>
              <p>
                Envoyé le : <span v-if="row_pa.pa_sent_date != '0000-00-00 00:00:00'">{{ $moment(row_pa.pa_sent_date).format("DD/MM/YYYY") }} </span>
                <span v-else><b>Kit à préparer</b></span>
              </p>
            </div>
            <div v-else>
              <h1>Nouvel envoi</h1>
            </div>
            <!--             <div class="d-flex flex-row align-items-center active">
              <label class="label-checkbox" for="active">Activer</label>
              <input type="checkbox" id="actif" name="actif" v-model="row_us.us_active" />
            </div> -->
          </div>
          <div class="modal-body">
            <div class="row">
              <h6><span class="number-title">1</span>Adresse de livraison</h6>
            </div>

            <div class="row mt-3">
              <div class="col-md-8">
                <div class="d-flex flex-row">
                  <m-form-text
                    label="Civilité"
                    width="20%"
                    type="text"
                    v-model="row_pa.pa_shipping_civility"
                    :name="$Utils.randomstring('pa_shipping_civility')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>

                  <m-form-text
                    label="Nom"
                    class="mx-2"
                    type="text"
                    v-model="row_pa.pa_shipping_name"
                    :name="$Utils.randomstring('pa_shipping_name')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>

                  <m-form-text
                    label="Prénom"
                    type="text"
                    v-model="row_pa.pa_shipping_firstname"
                    :name="$Utils.randomstring('pa_shipping_firstname')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div style="position: relative">
                      <m-form-text label="Adresse" v-model="address_autocomplete" :disabled="foreignAddress ? true : false"></m-form-text>
                      <div v-if="address_choice.length" class="container-address-choice">
                        <div v-for="address in address_choice" :key="address.id">
                          <div @click="selectAddress(address)" class="d-flex flex-column address-choice">
                            <div class="bis">{{ address.properties.label }}</div>
                            <div>{{ address.properties.context }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <m-form-checkbox
                      class="mt-1"
                      label="Adresse à l'étranger"
                      :name="$Utils.randomstring('foreignAddress')"
                      v-model="foreignAddress"
                      @input="foreignAddresscheckbox"
                    ></m-form-checkbox>
                  </div>
                </div>

                <div class="d-flex flex-row">
                  <m-form-text
                    class="w-100 mt-3"
                    placeholder="Adresse"
                    type="text"
                    v-model="row_pa.pa_shipping_address1"
                    :name="$Utils.randomstring('pa_shipping_address1')"
                    :rules="[$Validation.mandatory]"
                    :disabled="foreignAddress ? false : true"
                  ></m-form-text>
                </div>
                <div class="d-flex flex-row">
                  <m-form-text
                    placeholder="Complément d'adresse"
                    class="w-100 mt-2"
                    type="text"
                    v-model="row_pa.pa_shipping_address2"
                    :name="$Utils.randomstring('pa_shipping_address2')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                </div>
                <div class="d-flex flex-row">
                  <m-form-text
                    placeholder="cp"
                    class="flex-grow-1 mt-2"
                    type="text"
                    v-model="row_pa.pa_shipping_zip"
                    :name="$Utils.randomstring('pa_shipping_zip')"
                    :rules="[$Validation.mandatory]"
                    :disabled="foreignAddress ? false : true"
                  ></m-form-text>
                  <m-form-text
                    placeholder="ville"
                    class="flex-grow-1 mt-2 ml-2"
                    type="text"
                    v-model="row_pa.pa_shipping_city"
                    :name="$Utils.randomstring('pa_shipping_city')"
                    :rules="[$Validation.mandatory]"
                    :disabled="foreignAddress ? false : true"
                  ></m-form-text>
                </div>
                <div class="d-flex flex-row">
                  <m-form-text
                    placeholder="Informations complémentaires"
                    type="textarea"
                    class="w-100"
                    v-model="row_pa.pa_comment"
                    :name="$Utils.randomstring('pa_comment')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                </div>
              </div>
              <div class="col-md-4">
                <div class="bis">Contact client :</div>
                <div><i>Facturation</i></div>
                <div class="d-flex flex-row">
                  <div>{{ row_co.co_invoice_civility }}</div>
                  <div class="ml-2">{{ row_co.co_invoice_name }}</div>
                  <div class="ml-2">{{ row_co.co_invoice_firstname }}</div>
                </div>
                <div>{{ row_co.co_invoice_address1 }}</div>
                <div>{{ row_co.co_invoice_address2 }}</div>
                <div class="d-flex flex-row">
                  <div>{{ row_co.co_invoice_zip }}</div>
                  <div class="ml-2">{{ row_co.co_invoice_city }}</div>
                </div>
                <div class="color-emotivi-blue">{{ row_co.co_invoice_tel }}</div>
                <div class="color-emotivi-blue">{{ row_co.co_invoice_email }}</div>

                <div><i>Livraison</i></div>
                <div class="d-flex flex-row">
                  <div>{{ row_co.co_shipping_civility }}</div>
                  <div class="ml-2">{{ row_co.co_shipping_name }}</div>
                  <div class="ml-2">{{ row_co.co_shipping_firstname }}</div>
                </div>
                <div>{{ row_co.co_shipping_address1 }}</div>
                <div>{{ row_co.co_shipping_address2 }}</div>
                <div class="d-flex flex-row">
                  <div>{{ row_co.co_shipping_zip }}</div>
                  <div class="ml-2">{{ row_co.co_shipping_city }}</div>
                </div>
                <div class="color-emotivi-blue">{{ row_co.co_shipping_tel }}</div>
                <!-- <div class="color-emotivi-blue">{{ row_co.co_shipping_email }}</div> -->

                <!-- <div class="mt-4"><button class="btn btn-primary" @click="checkAddress">Vérifier adresse</button></div> -->
              </div>
            </div>
            <div class="row mt-5">
              <h6><span class="number-title">2</span>Information d'envoi et de suivi</h6>
            </div>
            <div class="row">
              <div class="col-md-12">
                <m-form-select
                  label="Statut de l'envoi "
                  v-model="row_pa.pa_state"
                  :name="$Utils.randomstring('pa_state')"
                  :rules="[$Validation.mandatory]"
                  :items="$store.state.items_pa_state"
                ></m-form-select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <m-form-text
                  label="Nom de l'envoi"
                  type="text"
                  v-model="row_pa.pa_name"
                  :name="$Utils.randomstring('pa_name')"
                  :rules="[$Validation.mandatory]"
                ></m-form-text>
              </div>
              <div class="col-md-3">
                <m-form-select
                  label="Société expéditrice"
                  v-model="row_pa.pa_company"
                  :name="$Utils.randomstring('pa_company')"
                  :rules="[$Validation.mandatory]"
                  :items="$store.state.items_company"
                ></m-form-select>
              </div>
              <div class="col-md-2">
                <m-form-text
                  label="Numéro de suivi"
                  type="text"
                  v-model="row_pa.pa_trackingcode"
                  :name="$Utils.randomstring('pa_trackingcode')"
                  :rules="[$Validation.mandatory]"
                ></m-form-text>
              </div>
              <div class="col-md-2">
                <m-form-text
                  label="Tarif"
                  type="text"
                  v-model="row_pa.pa_price"
                  :name="$Utils.randomstring('pa_price')"
                  :rules="[$Validation.mandatory]"
                ></m-form-text>
              </div>
            </div>
            <div class="row mt-5">
              <h6><span class="number-title">3</span>Matériel</h6>
            </div>
            <div class="row d-flex flex-row align-items-end mb-4">
              <div class="col-md-6">
                <m-form-combobox
                  label="Produits"
                  label-position="top"
                  :name="$Utils.randomstring('productSelected')"
                  v-model="productSelected"
                  :store-url="$config.server_url + '/extranet/2.0/products'"
                  :store-params="{}"
                  item-value="pr_id"
                  item-text="pr_name"
                  @input="selectProduct"
                ></m-form-combobox>
              </div>
              <div class="col-md-6">
                <m-form-combobox
                  label="Autre matériel"
                  label-position="top"
                  :name="$Utils.randomstring('materialSelected')"
                  v-model="materialSelected"
                  :store-url="$config.server_url + '/extranet/2.0/materials'"
                  :store-params="{}"
                  item-value="ma_id"
                  item-text="ma_name"
                  @input="selectMaterial"
                ></m-form-combobox>
              </div>
              <!--  <div class="col-md-3">
                <button class="btn btn-primary w-100">Ajouter fourniture</button>
              </div> -->
            </div>
            <div class="row">
              <div class="col-md-12 d-flex flex-row align-items-center">
                <div class="container-materials-from-product d-flex flex-row" v-if="materialsToSendFromProduct.length">
                  <div class="d-flex flex-column align-items-center mx-2" v-for="(prma, index) in materialsToSendFromProduct" :key="index">
                    <div v-if="prma.ma_id && prma.ma_id.ma_id">
                      <vue-load-image>
                        <img
                          slot="image"
                          :src="
                            $config.server_url +
                              '/extranet/2.0/materials/image/' +
                              prma.ma_id.ma_id +
                              '/70/50?d=' +
                              new Date().getTime() +
                              '&token=' +
                              $store.state.accesstoken
                          "
                        />
                        <img slot="preloader" src="images/image-loader70.gif" />
                        <div slot="error">pas d'image</div>
                      </vue-load-image>
                    </div>
                    <div>
                      {{ prma.ma_id && prma.ma_id.ma_name ? prma.ma_id.ma_name : "" }}
                    </div>
                    <div class="d-flex flex-row">
                      <div>
                        <m-form-text class="mt-0 mr-2" width="46px" v-model="prma.prma_quantity"> </m-form-text>
                      </div>
                      <div class="pointer" @click="deleteMaterial('product', prma)">
                        <icon name="trash-alt" color="red"></icon>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div v-if="materialsToSendFromProduct.length && materialsToSendFromMaterial.length" class="mt-2">
                  <icon class="mx-1" name="plus"></icon>
                </div> -->
                <div class="d-flex flex-column align-items-center mx-2" v-for="(material, index) in materialsToSendFromMaterial" :key="index">
                  <div v-if="material.ma_id && material.ma_id.ma_id">
                    <vue-load-image>
                      <img
                        slot="image"
                        :src="
                          $config.server_url +
                            '/extranet/2.0/materials/image/' +
                            material.ma_id.ma_id +
                            '/70/50?d=' +
                            new Date().getTime() +
                            '&token=' +
                            $store.state.accesstoken
                        "
                      />
                      <img slot="preloader" src="images/image-loader70.gif" />
                      <div slot="error">pas d'image</div>
                    </vue-load-image>
                  </div>
                  <div>
                    {{ material.ma_id && material.ma_id.ma_name ? material.ma_id.ma_name : "" }}
                  </div>
                  <div class="d-flex flex-row">
                    <div>
                      <m-form-text class="mt-0 mr-2" width="46px" v-model="material.prma_quantity"> </m-form-text>
                    </div>
                    <div class="pointer" @click="deleteMaterial('material', material)">
                      <icon name="trash-alt" color="red"></icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between">
            <button type="button" class="btn btn-danger" @click="deleteConfirmWin">Supprimer</button>
            <div>
              <button type="button" class="btn btn-secondary" @click="cancelWin">Annuler</button>
              <button type="button" class="btn btn-primary btn-mouveal ml-2" @click="saveWin(false)">Enregistrer</button>
            </div>
          </div>
        </div>
      </div>
      <m-confirm-dialog
        v-model="confirmdelete"
        text="Souhaitez-vous effacer ce colis ?"
        title="Confirmation"
        width="500px"
        @canceled="confirmdelete = false"
        @confirmed="deleteWin()"
      ></m-confirm-dialog>
      <m-message-dialog v-model="dialogErr" title="Erreur" :text="dialogErrTxt"></m-message-dialog>
    </div>
  </transition>
</template>

<script>
export default {
  name: "parcelWinEdit",
  components: {},
  props: {
    en_id: Number,
    pa_id: Number,
    value: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      user: {},
      //parcel_id: -1,
      row_pa: { pa_name: "", pa_price: 0 },
      row_en: {},
      row_co: {},
      confirmdelete: false,
      dialogErrTxt: "",
      dialogErr: false,
      /*** address autocomplete  ***/
      address_choice: [],
      address_autocomplete: "",
      addressSelected: {},
      timeout: null,
      foreignAddress: false,
      /**** add materials ****/
      productSelected: {},
      materialSelected: {},
      materialsToSendFromProduct: [],
      materialsToSendFromMaterial: []
    };
  },
  watch: {
    value(v) {
      if (v) {
        if (this.pa_id) {
          this.loadParcel();
          //this.parcel_id = this.pa_id;
        } else {
          this.loadEnduser();
        }
      }
    },
    address_autocomplete(val) {
      if (val) {
        if (this.timeout) window.clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
          let params = { address: val };
          let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/contacts/address", { params });
          if (!response || !response.data) return;
          this.address_choice = response.data.data;
        }, 1000);
      }
    }
  },
  computed: {},

  mounted() {
    this.user = this.$store.state.user;
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  created() {},
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    selectAddress(address) {
      this.addressSelected = address;
      this.row_pa.pa_shipping_address1 = address.properties.name;
      this.row_pa.pa_shipping_address2 = "";
      this.row_pa.pa_shipping_zip = address.properties.postcode;
      this.row_pa.pa_shipping_city = address.properties.city;
      this.address_choice = [];
    },
    // appeler seulement s'il y a un pa_id
    async loadParcel() {
      this.productSelected = {};
      this.materialSelected = {};
      this.materialsToSendFromProduct = [];
      this.materialsToSendFromMaterial = [];
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/parcels/" + this.pa_id);
      if (!response || !response.data) return;
      this.row_pa = response.data.data;
      this.row_co = response.data.data.co_id;
      if (this.row_pa.pr_id) this.productSelected = this.row_pa.pr_id;
      this.address_autocomplete = "";
      this.address_choice = [];
      if (this.row_pa.pa_materials_sended) {
        for (let iprma = 0; iprma < this.row_pa.pa_materials_sended.length; iprma++) {
          const prma = this.row_pa.pa_materials_sended[iprma];
          if (prma.pr_id) this.materialsToSendFromProduct.push(prma);
          else this.materialsToSendFromMaterial.push(prma);
        }
      }
      // console.log("this.materialsToSendFromProduct", this.materialsToSendFromProduct, this.materialsToSendFromMaterial);
    },
    // appeler seulement si pas de pa_id
    async loadEnduser() {
      this.productSelected = {};
      this.materialSelected = {};
      this.materialsToSendFromProduct = [];
      this.materialsToSendFromMaterial = [];
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/endusers/" + this.en_id);
      if (!response || !response.data) return;
      this.row_en = response.data.data;
      this.row_co = response.data.data.co_id;
      //this.address_autocomplete = this.row_co.co_invoice_address1;
      if (this.row_co.co_adresses_different) {
        this.row_pa = {
          pa_price: 7.46,
          pa_shipping_civility: this.row_co.co_shipping_civility,
          pa_shipping_name: this.row_co.co_shipping_name,
          pa_shipping_firstname: this.row_co.co_shipping_firstname,
          pa_shipping_address1: this.row_co.co_shipping_address1,
          pa_shipping_address2: this.row_co.co_shipping_address2,
          pa_shipping_city: this.row_co.co_shipping_city,
          pa_shipping_zip: this.row_co.co_shipping_zip,
          pa_shipping_country: this.row_co.co_shipping_country
        };
      } else {
        this.row_pa = {
          pa_price: 7.46,
          pa_shipping_civility: this.row_co.co_invoice_civility,
          pa_shipping_name: this.row_co.co_invoice_name,
          pa_shipping_firstname: this.row_co.co_invoice_firstname,
          pa_shipping_address1: this.row_co.co_invoice_address1,
          pa_shipping_address2: this.row_co.co_invoice_address2,
          pa_shipping_city: this.row_co.co_invoice_city,
          pa_shipping_zip: this.row_co.co_invoice_zip,
          pa_shipping_country: this.row_co.co_invoice_country
        };
      }
    },
    foreignAddresscheckbox() {
      if (this.foreignAddress) {
        this.address_autocomplete = "";
        this.address_choice = [];
      }
    },
    selectProduct() {
      if (!this.productSelected.productsmaterials) return;
      this.materialsToSendFromProduct = this.productSelected.productsmaterials;
      this.row_pa.pr_id = this.productSelected.pr_id;
      this.setPaName();
    },
    selectMaterial() {
      if (!this.materialSelected || !this.materialSelected.ma_id) return;
      let obj = {
        ma_id: this.materialSelected,
        prma_quantity: 1
      };
      this.materialsToSendFromMaterial.push(obj);
      this.setPaName();
    },
    setPaName() {
      let name = "";
      if (this.productSelected) name = this.productSelected.pr_name;
      if (this.materialsToSendFromMaterial.length) {
        if (this.productSelected) name += " + ";
        for (let i = 0; i < this.materialsToSendFromMaterial.length; i++) {
          const prma = this.materialsToSendFromMaterial[i];
          name += prma.ma_id.ma_name;
          if (i < this.materialsToSendFromMaterial.length - 1) name += ", ";
        }
      }
      this.row_pa.pa_name = name;
    },
    deleteMaterial(from, item) {
      let tabWhereDelete, i;
      if (from === "product") {
        tabWhereDelete = this.materialsToSendFromProduct;
        i = this.$_.findIndex(this.materialsToSendFromProduct, { prma_id: item.prma_id });
      }
      if (from === "material") {
        tabWhereDelete = this.materialsToSendFromMaterial;
        i = this.$_.findIndex(this.materialsToSendFromProduct, { ma_id: item.ma_id });
      }
      tabWhereDelete.splice(i, 1);
    },
    async saveWin() {
      let err = [];
      let fieldRequired = [
        //{ field: "pa_shipping_civility", text: "civilité" },
        { field: "pa_shipping_name", text: "nom" },
        { field: "pa_shipping_firstname", text: "prénom" },
        { field: "pa_shipping_address1", text: "adresse 1" },
        { field: "pa_shipping_zip", text: "code postal" },
        { field: "pa_shipping_city", text: "ville" },
        { field: "pr_id", text: "produit" }
      ];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.row_pa[field.field]) err.push(field);
      }
      if (err.length) {
        this.dialogErrTxt = "<span class='bis'>Vous devez remplir les champs : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error.text + " <br>";
        }
        this.dialogErr = true;
      } else {
        let response;
        this.row_pa.pa_materials_sended = this.materialsToSendFromProduct.concat(this.materialsToSendFromMaterial);
        for (let ima = 0; ima < this.row_pa.pa_materials_sended.length; ima++) {
          const prma = this.row_pa.pa_materials_sended[ima];
          if (!prma.ma_id) continue;
          prma.ma_id = prma.ma_id.ma_id;
        }
        if (this.pa_id) {
          response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/parcels/" + this.pa_id, this.row_pa);
        } else {
          this.row_pa.en_id = this.row_en.en_id;
          this.row_pa.co_id = this.row_en.co_id.co_id;
          this.row_pa.us_id = this.user.us_id;
          this.row_pa.pa_date = this.$moment().format("MM-DD-YYYY HH:mm:ss");
          response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/parcels", this.row_pa);
        }
        if (response.data.err) {
          this.$store.dispatch("showDialogError", response.data.err.message);
          return;
        }
        let row_pa = response.data.data;
        this.$emit("input", false);
        this.$emit("parcelSaved", row_pa.pa_id);
        this.row_pa = {};
      }
    },
    cancelWin() {
      this.$emit("input", false);
      this.row_pa = {};
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteWin() {
      await this.$axios.delete(this.$config.server_url + "/extranet/2.0/parcels/" + this.pa_id);
      this.confirmdelete = false;
      this.$emit("input", false);
      this.$emit("parcelSaved");
      this.row_pa = {};
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";
.establishment {
  background-color: #daece7;
  padding: 5px 10px;
  margin-right: 10px;
}
</style>
