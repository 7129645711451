var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-row flex-wrap justify-content-between mb-3"
          },
          [
            _c("h2", [
              _c("span", { staticClass: "majuscule" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("formatContactNameSimple")(
                        _vm.row_co,
                        "co_invoice_"
                      )
                    ) +
                    " "
                ),
                _vm.row_co.es_id.es_white_label
                  ? _c("img", {
                      attrs: {
                        src:
                          _vm.$config.server_url + _vm.row_co.es_id.es_avatar,
                        alt: "Logo fournisseur",
                        width: "50px"
                      }
                    })
                  : _vm._e()
              ])
            ]),
            _vm.$store.getters.checkUserAccess(
              _vm.$router,
              "/contacts/:co_id/edit"
            )
              ? _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary ml-2",
                      on: { click: _vm.openWinEdit }
                    },
                    [_vm._v("Modifier")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary ml-2",
                      on: {
                        click: function($event) {
                          return _vm.exportCSV()
                        }
                      }
                    },
                    [_vm._v("Exporter en csv")]
                  )
                ])
              : _vm._e()
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-row flex-wrap justify-content-between mb-3 mt-n1"
          },
          [
            _vm.row_co.co_followedup === 1
              ? _c("span", { staticClass: "text-warning" }, [
                  _vm._v("Le premier email de relance a été envoyé.")
                ])
              : _vm.row_co.co_followedup === 2
              ? _c("span", { staticClass: "text-warning" }, [
                  _vm._v("Le deuxième email de relance a été envoyé.")
                ])
              : _vm.row_co.co_followedup === 3
              ? _c("span", { staticClass: "text-warning" }, [
                  _vm._v("Le troisième email de relance a été envoyé.")
                ])
              : _vm._e()
          ]
        ),
        _c("div", { staticClass: "row mt-4" }, [
          _c("div", { staticClass: "col-md-4 hr-right" }, [
            _c("h5", [_vm._v("coordonnées")]),
            _c("div", [
              _vm.row_co.co_invoice_tel
                ? _c("span", { staticClass: "color-emotivi-blue bis" }, [
                    _vm._v("Tél.")
                  ])
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.row_co.co_invoice_tel) + " ")
            ]),
            _c("div", [
              _c("span", { staticClass: "color-emotivi-blue bis" }, [
                _vm._v("Email.")
              ]),
              _vm._v(" " + _vm._s(_vm.row_co.co_invoice_email) + " ")
            ]),
            _c("h5", { staticClass: "mt-3" }, [_vm._v("infos")]),
            _vm.row_co.co_box_inestablishment == "yes"
              ? _c("div", [
                  _c("b", [
                    _vm._v("Installation dans un établissement de santé")
                  ])
                ])
              : _vm._e(),
            _vm.row_co.es_id && _vm.row_co.es_id.es_name
              ? _c("div", [
                  _vm._v("Établissement : " + _vm._s(_vm.row_co.es_id.es_name))
                ])
              : _vm._e(),
            _c(
              "div",
              {
                style: {
                  color: _vm.getColor(_vm.row_co.co_box_connection_type)
                }
              },
              [
                _vm._v(
                  "Type de connexion : " +
                    _vm._s(_vm.row_co.co_box_connection_type)
                )
              ]
            ),
            _c("div", [
              _vm._v(
                "Marque TV : " +
                  _vm._s(
                    _vm.row_co.co_box_tvbrand_other
                      ? _vm.row_co.co_box_tvbrand_other
                      : _vm.row_co.co_box_tvbrand
                  )
              )
            ]),
            _c("div", [
              _vm._v(
                "Découverte d'Emotivi : " + _vm._s(_vm.row_co.co_box_discover)
              )
            ]),
            _vm.row_co.afco_id && _vm.row_co.afco_id.af_id
              ? _c("div", [
                  _vm._v(
                    "Code d'affiliation : " +
                      _vm._s(_vm.row_co.afco_id.af_id.af_code)
                  )
                ])
              : _vm._e(),
            _vm.row_co.afco_id && _vm.row_co.afco_id.af_id
              ? _c("div", [
                  _vm._v(
                    "Nom du revendeur : " +
                      _vm._s(_vm.row_co.afco_id.af_id.af_name)
                  )
                ])
              : _vm._e(),
            _c("div", [
              _vm._v("Site partenaire : " + _vm._s(_vm.row_co.co_partner_site))
            ])
          ]),
          _c("div", { staticClass: "col-md-4 hr-right" }, [
            _c("h5", [_vm._v("Adresse de facturation")]),
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm._f("formatContactNameSimple")(_vm.row_co, "co_invoice_")
                )
              )
            ]),
            _c("div", [_vm._v(_vm._s(_vm.row_co.co_invoice_address1))]),
            _c("div", [_vm._v(_vm._s(_vm.row_co.co_invoice_address2))]),
            _c("div", [
              _vm._v(_vm._s(_vm.row_co.co_invoice_zip) + " "),
              _vm.row_co.co_invoice_city ? _c("span", [_vm._v(",")]) : _vm._e(),
              _vm._v(" " + _vm._s(_vm.row_co.co_invoice_city))
            ]),
            _vm.$store.getters.checkUserAccess(_vm.$router, "/invoices")
              ? _c("h5", { staticClass: "mt-2" }, [
                  _vm._v("Prélévement bancaire")
                ])
              : _vm._e(),
            _vm.$store.getters.checkUserAccess(_vm.$router, "/invoices") &&
            _vm.row_co.co_rib
              ? _c("div", [
                  _c("div", [
                    _vm._v("Titulaire : " + _vm._s(_vm.row_co.co_rib_name))
                  ]),
                  _c("div", [
                    _vm._v("IBAN : " + _vm._s(_vm.row_co.co_rib_iban))
                  ]),
                  _c("div", [_vm._v("BIC : " + _vm._s(_vm.row_co.co_rib_bic))])
                ])
              : _c("div", [_vm._v("Aucun")]),
            _vm.row_co.es_id.es_white_label
              ? _c("div", [
                  _c("h5", { staticClass: "mt-2" }, [
                    _vm._v("Fournisseur de services")
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _vm._v(_vm._s(_vm.row_co.es_id.es_name))
                    ]),
                    _c("div", { staticClass: "col-auto" }, [
                      _c("img", {
                        attrs: {
                          src:
                            _vm.$config.server_url + _vm.row_co.es_id.es_avatar,
                          alt: "logo fournisseur",
                          width: "30px"
                        }
                      })
                    ])
                  ])
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "col-md-4" }, [
            _c("h5", [_vm._v("Adresse de livraison")]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.row_co.co_shipping_society_name,
                    expression: "row_co.co_shipping_society_name"
                  }
                ]
              },
              [
                _vm._v(
                  "Nom de la société : " +
                    _vm._s(_vm.row_co.co_shipping_society_name)
                )
              ]
            ),
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm._f("formatContactNameSimple")(_vm.row_co, "co_shipping_")
                )
              )
            ]),
            _c("div", [_vm._v(_vm._s(_vm.row_co.co_shipping_address1))]),
            _c("div", [_vm._v(_vm._s(_vm.row_co.co_shipping_address2))]),
            _c("div", [
              _vm._v(_vm._s(_vm.row_co.co_shipping_zip) + " "),
              _vm.row_co.co_shipping_city
                ? _c("span", [_vm._v(",")])
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.row_co.co_shipping_city))
            ]),
            _vm.row_co.co_shipping_tel
              ? _c("div", [
                  _vm._v("Tél : " + _vm._s(_vm.row_co.co_shipping_tel))
                ])
              : _vm._e()
          ])
        ]),
        _vm.$store.getters.checkUserAccess(_vm.$router, "/boxes/:en_id")
          ? _c("div", { staticClass: "row mt-3" }, [
              _vm._m(0),
              _vm.row_co && _vm.row_co.endusers && _vm.row_co.endusers.length
                ? _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("m-list-simple", {
                        ref: "commandelist",
                        attrs: {
                          dynamic: true,
                          items: _vm.row_co.endusers,
                          "item-value": "id",
                          total: 1
                        },
                        on: { itemclick: _vm.goBox },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "ths",
                              fn: function(ref) {
                                return [
                                  _c("th"),
                                  _c("th", [_vm._v("Utilisateur final")]),
                                  _c("th", [_vm._v("box associée")]),
                                  _c("th", [_vm._v("Premier appel réussi")]),
                                  _c("th", [_vm._v("email d'invitation")])
                                ]
                              }
                            },
                            {
                              key: "tds",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.tabEnIds.length === 0,
                                              expression:
                                                "tabEnIds.length === 0"
                                            }
                                          ]
                                        },
                                        [
                                          _c("icon", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              width: "22",
                                              height: "22",
                                              name: "arrow-right",
                                              color: "#199aaa"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._l(_vm.tabEnIds, function(enId) {
                                        return _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.en_id === enId,
                                                expression:
                                                  "item.en_id === enId"
                                              }
                                            ],
                                            key: enId
                                          },
                                          [
                                            _c("icon", {
                                              staticClass: "mr-2",
                                              attrs: {
                                                width: "22",
                                                height: "22",
                                                name: "arrow-right",
                                                color: "#199aaa"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      })
                                    ],
                                    2
                                  ),
                                  _c("td", [_vm._v(_vm._s(item.en_fullname))]),
                                  _c("td", [
                                    item.bo_id
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(item.bo_id.bo_mac_address)
                                          )
                                        ])
                                      : _vm._e()
                                  ]),
                                  _c("td", [
                                    item.firstSuccessCall !=
                                    "0000-00-00 00:00:00"
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm
                                                .$moment(item.firstSuccessCall)
                                                .format("DD/MM/YYYY [à] HH:mm")
                                            )
                                          )
                                        ])
                                      : _c("span", [_vm._v("-")])
                                  ]),
                                  _c("td", [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-warning btn-sm",
                                        on: {
                                          click: function($event) {
                                            return _vm.sendInvitationEmail(
                                              item,
                                              $event
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("Envoyer")]
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2775047966
                        )
                      })
                    ],
                    1
                  )
                : _c("div", [
                    _c("div", { staticClass: "col-md-12" }, [
                      _vm._v("Pas encore de boxes associées")
                    ])
                  ])
            ])
          : _vm._e(),
        _vm.$store.getters.checkUserAccess(_vm.$router, "/invoices")
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c("h5", {}, [
                  _vm._v(" Les tickets "),
                  _vm.loadingTickets
                    ? _c(
                        "span",
                        [
                          _c("icon", {
                            staticClass: "mr-2 fa-spin",
                            attrs: { width: "30", height: "30", name: "sync" }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ]),
              _vm.tickets.length
                ? _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("m-list-simple", {
                        ref: "commandelist",
                        attrs: {
                          dynamic: true,
                          items: _vm.tickets,
                          "item-value": "id",
                          total: 1
                        },
                        on: { itemclick: _vm.goFreshdeskTicket },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "ths",
                              fn: function(ref) {
                                return [
                                  _c(
                                    "th",
                                    { staticStyle: { width: "150px" } },
                                    [
                                      _c("span", { staticClass: "pointer" }, [
                                        _vm._v("Date")
                                      ])
                                    ]
                                  ),
                                  _c("th", [
                                    _c("span", { staticClass: "pointer" }, [
                                      _vm._v("Sujet")
                                    ])
                                  ]),
                                  _c("th", { staticStyle: { width: "80px" } }, [
                                    _c("span", { staticClass: "pointer" }, [
                                      _vm._v("État")
                                    ])
                                  ])
                                ]
                              }
                            },
                            {
                              key: "tds",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDateTime")(
                                          item.created_at
                                        )
                                      )
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(" " + _vm._s(item.subject) + " ")
                                  ]),
                                  _c("td", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "ticket-status",
                                        class: "status-" + item.status
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f(
                                                "formatStatusTicketsFreshdesk"
                                              )(item.status)
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3725711760
                        )
                      })
                    ],
                    1
                  )
                : _c("div", [
                    _c("div", { staticClass: "col-md-12" }, [
                      _vm._v("Pas de tickets youhou!")
                    ])
                  ])
            ])
          : _vm._e(),
        _vm.$store.getters.checkUserAccess(_vm.$router, "/invoices") &&
        _vm.currentEnduser
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c(
                  "div",
                  [
                    _c("h5", {}, [
                      _vm._v(
                        " Les paiements (frais de dossier + abonnement mensuel) "
                      ),
                      _vm.loadingPayments
                        ? _c(
                            "span",
                            [
                              _c("icon", {
                                staticClass: "mr-2 fa-spin",
                                attrs: {
                                  width: "30",
                                  height: "30",
                                  name: "sync"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _c("m-list-simple", {
                      ref: "commandelist",
                      attrs: {
                        dynamic: true,
                        items: _vm.allCharges,
                        "item-value": "ac_id",
                        total: _vm.allCharges.length
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "ths",
                            fn: function(ref) {
                              return [
                                _c("th", { attrs: { width: "180" } }, [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Date")
                                  ])
                                ]),
                                _c("th", { attrs: { width: "100" } }, [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Montant")
                                  ])
                                ]),
                                _c("th", { attrs: { width: "160" } }, [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Statut")
                                  ])
                                ]),
                                _c("th", [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Description")
                                  ])
                                ]),
                                _c("th", { staticStyle: { width: "230px" } }, [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Id - lien vers stripe")
                                  ])
                                ])
                              ]
                            }
                          },
                          {
                            key: "tds",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$moment
                                        .unix(item.created)
                                        .format("YYYY-MM-DD HH:mm:ss")
                                    )
                                  )
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatPrice")(item.amount / 100)
                                    )
                                  )
                                ]),
                                _c("td", [
                                  _vm._v(" " + _vm._s(item.status) + " ")
                                ]),
                                _c("td", [
                                  _vm._v(" " + _vm._s(item.description) + " ")
                                ]),
                                _c("td", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: item.receipt_url,
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.id))]
                                  )
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        743075030
                      )
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("h5", {}, [
                      _vm._v(" Les abonnements "),
                      _vm.loadingPayments
                        ? _c(
                            "span",
                            [
                              _c("icon", {
                                staticClass: "mr-2 fa-spin",
                                attrs: {
                                  width: "30",
                                  height: "30",
                                  name: "sync"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _c("m-list-simple", {
                      ref: "commandelist",
                      attrs: {
                        dynamic: true,
                        items: _vm.allSubscriptions,
                        "item-value": "ac_id",
                        total: _vm.allSubscriptions.length
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "ths",
                            fn: function(ref) {
                              return [
                                _c("th"),
                                _c("th", { staticStyle: { width: "80px" } }, [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Montant")
                                  ])
                                ]),
                                _c("th", { staticStyle: { width: "80px" } }, [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Statut")
                                  ])
                                ]),
                                _c("th", [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Description")
                                  ])
                                ]),
                                _c("th", [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Id")
                                  ])
                                ]),
                                _c("th", { staticStyle: { width: "150px" } }, [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Début abonnement")
                                  ])
                                ]),
                                _c("th", { staticStyle: { width: "150px" } }, [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Fin abonnement")
                                  ])
                                ]),
                                _c("th", [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Plateforme")
                                  ])
                                ])
                              ]
                            }
                          },
                          {
                            key: "tds",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                item.end_user &&
                                Array.isArray(item.end_user) &&
                                item.end_user.length &&
                                item.end_user[0].en_id === _vm.currentEnduser
                                  ? _c(
                                      "td",
                                      [
                                        _c("icon", {
                                          staticClass: "mr-2",
                                          attrs: {
                                            width: "22",
                                            height: "22",
                                            name: "arrow-right",
                                            color: "#199aaa"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatPrice")(
                                        item.plan.amount / 100
                                      )
                                    )
                                  )
                                ]),
                                _c("td", [
                                  _vm._v(
                                    " " + _vm._s(item.su_state_label) + " "
                                  )
                                ]),
                                _c("td", [
                                  _vm._v(" " + _vm._s(item.plan.name) + " ")
                                ]),
                                _c("td", [_vm._v(" " + _vm._s(item.id) + " ")]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm
                                          .$moment(item.su_startdate)
                                          .format("DD/MM/YYYY HH:mm:ss")
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("td", [
                                  item.su_enddate
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$moment
                                              .unix(item.su_enddate)
                                              .isValid()
                                              ? _vm.$moment
                                                  .unix(item.su_enddate)
                                                  .format("DD/MM/YYYY HH:mm:ss")
                                              : _vm
                                                  .$moment(item.su_enddate)
                                                  .format("DD/MM/YYYY HH:mm:ss")
                                          )
                                        )
                                      ])
                                    : _vm._e()
                                ]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.subscriptionData.su_gocardlessid
                                          ? "GOCARDLESS"
                                          : item.subscriptionData.su_stripeid
                                          ? "STRIPE"
                                          : ""
                                      ) +
                                      " "
                                  )
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1881880029
                      )
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("h5", {}, [
                      _vm._v(" Les factures "),
                      _vm.loadingPayments
                        ? _c(
                            "span",
                            [
                              _c("icon", {
                                staticClass: "mr-2 fa-spin",
                                attrs: {
                                  width: "30",
                                  height: "30",
                                  name: "sync"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _c("m-list-simple", {
                      ref: "commandelist",
                      attrs: {
                        dynamic: true,
                        items: _vm.orders,
                        "item-value": "ac_id",
                        total: _vm.stripeCharges.length
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "ths",
                            fn: function(ref) {
                              return [
                                _c("th", { attrs: { width: "200px" } }, [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("nom")
                                  ])
                                ]),
                                _c("th", { attrs: { width: "200px" } }, [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Statut")
                                  ])
                                ]),
                                _c("th", [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Date")
                                  ])
                                ]),
                                _c("th", [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Total TTC")
                                  ])
                                ]),
                                _c("th", [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Actions")
                                  ])
                                ])
                              ]
                            }
                          },
                          {
                            key: "tds",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("td", [_vm._v(_vm._s(item.or_name))]),
                                _c("td", [
                                  _vm._v(" " + _vm._s(item.or_status) + " ")
                                ]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatDateTime")(item.or_date)
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(item.or_pricettc / 100) + " €")
                                ]),
                                _c(
                                  "td",
                                  { staticClass: "d-flex flex-row" },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: { to: "/invoices/" + item.or_id }
                                      },
                                      [
                                        _c("icon", {
                                          attrs: {
                                            color: "black",
                                            name: "edit"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "ml-2",
                                        on: {
                                          click: function($event) {
                                            return _vm.printOrder(item, $event)
                                          }
                                        }
                                      },
                                      [
                                        _c("icon", { attrs: { name: "print" } })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2932103652
                      )
                    })
                  ],
                  1
                )
              ])
            ])
          : _vm._e(),
        _vm.$store.getters.checkUserAccess(_vm.$router, "/actions")
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-12" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary w-100",
                    on: { click: _vm.clickActions }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.showActions
                            ? "Masquer les actions"
                            : "Afficher les actions"
                        ) +
                        " "
                    )
                  ]
                )
              ])
            ])
          : _vm._e(),
        _vm.showActions
          ? _c("div", { staticClass: "row mt-4" }, [
              _vm._m(1),
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _c("m-list-simple", {
                    ref: "commandelist",
                    attrs: {
                      limit: 300,
                      items: _vm.actions,
                      "item-value": "ac_id",
                      total: _vm.action_length
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "ths",
                          fn: function(ref) {
                            return [
                              _c("th", { attrs: { width: "160px" } }, [
                                _c("span", { staticClass: "pointer" }, [
                                  _vm._v("Date")
                                ])
                              ]),
                              _c("th", { attrs: { width: "220px" } }, [
                                _c("span", { staticClass: "pointer" }, [
                                  _vm._v("Type")
                                ])
                              ]),
                              _c("th", { attrs: { width: "220px" } }, [
                                _c("span", { staticClass: "pointer" }, [
                                  _vm._v("Utilisateur final")
                                ])
                              ]),
                              _c("th", { attrs: { width: "150px" } }, [
                                _c("span", { staticClass: "pointer" }, [
                                  _vm._v("User")
                                ])
                              ]),
                              _c("th", [
                                _c("span", { staticClass: "pointer" }, [
                                  _vm._v("Infos")
                                ])
                              ])
                            ]
                          }
                        },
                        {
                          key: "tds",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDateTime")(item.createdAt)
                                  )
                                )
                              ]),
                              _c("td", [_vm._v(_vm._s(item.ac_type))]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatEndUserName")(item.en_id)
                                  )
                                )
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm._f("formatUserName")(item.us_id))
                                )
                              ]),
                              _c("td", [
                                item.ac_comment
                                  ? _c("span", [
                                      _vm._v(_vm._s(item.ac_comment))
                                    ])
                                  : _vm._e(),
                                item.ac_elapsedtime > 0
                                  ? _c("span", [
                                      _vm._v(
                                        "Durée : " +
                                          _vm._s(
                                            Math.floor(
                                              (item.ac_elapsedtime / 60) * 100
                                            ) / 100
                                          ) +
                                          "mn"
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3388236771
                    )
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _c("div", { staticClass: "row mt-3" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "small" }, [
              _vm._v(
                "Créé le : " +
                  _vm._s(_vm._f("formatDateTime")(_vm.row_co.createdAt))
              )
            ]),
            _c("div", { staticClass: "small" }, [
              _vm._v(
                "Modifié le : " +
                  _vm._s(_vm._f("formatDateTime")(_vm.row_co.updatedAt))
              )
            ])
          ])
        ])
      ]),
      _c("contact-win-edit", {
        ref: "ContactWinEdit",
        attrs: { co_id: _vm.value },
        on: {
          saved: _vm.contactWinEditSaved,
          canceled: _vm.contactWinEditCanceled,
          deleted: _vm.contactWinEditDeleted
        },
        model: {
          value: _vm.showEdit,
          callback: function($$v) {
            _vm.showEdit = $$v
          },
          expression: "showEdit"
        }
      }),
      _c("m-message-dialog", {
        attrs: { title: _vm.dialogMessageTitle, text: _vm.dialogMessageTxt },
        model: {
          value: _vm.dialogMessage,
          callback: function($$v) {
            _vm.dialogMessage = $$v
          },
          expression: "dialogMessage"
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12" }, [
      _c("h5", {}, [_vm._v("Liste des boxes associées")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12" }, [
      _c("h5", [_vm._v("Les actions")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }