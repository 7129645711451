var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        staticClass: "modal d-flex justify-content-center align-items-center",
        class: _vm.value ? "show" : "hide",
        style: _vm.value
          ? "display:block;background-color:#3333337a;"
          : "display:none;",
        attrs: { tabindex: "-1", role: "dialog", "aria-hidden": "true" }
      },
      [
        _c(
          "div",
          { staticClass: "videoContainer" },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _vm.type === "audio"
                  ? _c("div", { staticClass: "h2" }, [
                      _vm._v(_vm._s(_vm.switchLang("audioCall")))
                    ])
                  : _c("div", { staticClass: "h2" }, [
                      _vm._v(_vm._s(_vm.switchLang("videoCall")))
                    ]),
                _c("p", { staticClass: "h4" }, [
                  _vm._v(_vm._s(_vm.switchLang("subtitle")))
                ]),
                _c("hr")
              ])
            ]),
            _c("v-bistri", {
              ref: "bistritag",
              staticClass: "bistriWindow",
              attrs: {
                row_bo: _vm.row_bo,
                en_id: _vm.en_id,
                onlyaudio: _vm.type === "audio" ? true : false
              },
              on: { hangup: _vm.hangup }
            })
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }