var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mlist-container" }, [
    _c("div", { ref: "headers", staticClass: "mlist-container-headers" }, [
      _c("table", [_c("thead", [_c("tr", [_vm._t("ths")], 2)])])
    ]),
    _vm.dragable
      ? _c(
          "div",
          {
            ref: "container",
            staticClass: "mlist-container-datas",
            on: { scroll: _vm.onscroll }
          },
          [
            _c(
              "div",
              {
                class: { "mlist-contains-parent": true, bgstrip1: _vm.bgstrip1 }
              },
              [
                _c("div", { ref: "contains", staticClass: "mlist-contains" }, [
                  _c(
                    "table",
                    [
                      _c(
                        "draggable",
                        {
                          attrs: {
                            tag: "tbody",
                            list: _vm.items,
                            handle: ".handle"
                          }
                        },
                        _vm._l(_vm.items, function(item, indexItem) {
                          return _c(
                            "tr",
                            {
                              key: item[_vm.itemValue],
                              class: _vm.getTrClass(item, indexItem),
                              style: "height:" + _vm.itemHeight2 + "px;",
                              on: {
                                click: function($event) {
                                  return _vm.onitemclick(
                                    item,
                                    indexItem,
                                    $event
                                  )
                                }
                              }
                            },
                            [_vm._t("tds", null, { item: item })],
                            2
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ]
        )
      : _vm._e(),
    !_vm.dragable
      ? _c(
          "div",
          {
            ref: "container",
            staticClass: "mlist-container-datas",
            on: { scroll: _vm.onscroll }
          },
          [
            _c(
              "div",
              {
                class: { "mlist-contains-parent": true, bgstrip1: _vm.bgstrip1 }
              },
              [
                _c("div", { ref: "contains", staticClass: "mlist-contains" }, [
                  _c("table", [
                    _c(
                      "tbody",
                      _vm._l(_vm.items, function(item, indexItem) {
                        return _c(
                          "tr",
                          {
                            key: item[_vm.itemValue],
                            class: _vm.getTrClass(item),
                            style: "height:" + _vm.itemHeight2 + "px;",
                            on: {
                              click: function($event) {
                                return _vm.onitemclick(item, indexItem, $event)
                              }
                            }
                          },
                          [_vm._t("tds", null, { item: item })],
                          2
                        )
                      }),
                      0
                    )
                  ])
                ])
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }