var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "d-flex" }, [
    _c("div", { staticClass: "d-flex flex-column", attrs: { id: "boxes" } }, [
      _c("div", { staticClass: "d-flex header-emotivi" }, [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c("h1", [_vm._v(_vm._s(_vm.contacts_total) + " Contacts")])
        ]),
        _vm.$store.getters.checkUserAccess(_vm.$router, "/contacts/:co_id/edit")
          ? _c(
              "div",
              {
                staticClass:
                  "flex-grow-1 d-flex justify-content-end align-items-center"
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "ml-3 btn btn-sm btn-primary color-white",
                    attrs: { type: "button" },
                    on: { click: _vm.addNewContact }
                  },
                  [
                    _c("icon", {
                      staticClass: "mr-2",
                      attrs: { name: "plus", scale: "1" }
                    }),
                    _vm._v(" Nouveau ")
                  ],
                  1
                )
              ]
            )
          : _vm._e()
      ]),
      _c("div", { staticClass: "flex-grow-1 overflow-hidden d-flex" }, [
        _c("div", { staticClass: "w-20 mr-3" }, [
          _c(
            "div",
            {
              staticClass: "card overflow-hidden mb-0",
              style:
                _vm.windowWidth > 992
                  ? "height:100%;"
                  : "height:auto; margin-bottom:20px !important;"
            },
            [
              _c("div", { staticClass: "flex-grow-0" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-100 d-flex flex-row align-items-center justify-content-between",
                    on: {
                      click: function($event) {
                        _vm.groupsAreVisible = !_vm.groupsAreVisible
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "bis" }, [_vm._v("Groupes")]),
                    _vm.windowWidth <= 992
                      ? _c(
                          "div",
                          [
                            !_vm.groupsAreVisible
                              ? _c("icon", {
                                  staticClass: "ml-2",
                                  attrs: { name: "arrow-down" },
                                  on: { click: _vm.addNewGroup }
                                })
                              : _vm._e(),
                            _vm.groupsAreVisible
                              ? _c("icon", {
                                  staticClass: "ml-2",
                                  attrs: { name: "arrow-up" },
                                  on: { click: _vm.addNewGroup }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ]),
              _vm.groupsAreVisible
                ? _c(
                    "div",
                    { staticClass: "mt-3 flex-grow-1 overflow-auto" },
                    [
                      _c("m-list-simple", {
                        ref: "groupslist",
                        attrs: {
                          dynamic: true,
                          items: _vm.$store.state.mookGroups,
                          "item-value": "gr_id",
                          "item-height": 10,
                          value: _vm.contact_current_coid,
                          styleItemSelected: true
                        },
                        on: { itemclick: _vm.groupClick },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "ths",
                              fn: function(ref) {
                                return undefined
                              }
                            },
                            {
                              key: "tds",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "td",
                                    [
                                      _c("icon", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          width: "18",
                                          height: "18",
                                          name: item.icon,
                                          color: "#199aaa"
                                        }
                                      }),
                                      _vm._v(_vm._s(item.text))
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2616531477
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        ]),
        _c("div", { staticClass: "w-20 mr-3 d-flex" }, [
          _c("div", { staticClass: "card mb-0" }, [
            _c(
              "div",
              { staticClass: "flex-grow-0" },
              [
                _vm._m(0),
                _c("m-form-text", {
                  attrs: {
                    placeholder: "Rechercher",
                    tooltip:
                      "Recherche sur le nom, prénom, adresse, CP,<br>ville, email, téléphone, fax, mobile et ID",
                    "tooltip-position": "top",
                    name: _vm.$Utils.randomstring("searchcontact")
                  },
                  on: { input: _vm.loadContactsDelay },
                  model: {
                    value: _vm.searchcontact,
                    callback: function($$v) {
                      _vm.searchcontact = $$v
                    },
                    expression: "searchcontact"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex-grow-1 overflow-hidden mt-3 d-flex" },
              [
                _c("m-list", {
                  ref: "contactslist",
                  attrs: {
                    dynamic: true,
                    items: _vm.contacts,
                    "item-value": "co_id",
                    "item-height": 30,
                    limit: _vm.contacts_limit,
                    skip: _vm.contacts_skip,
                    total: _vm.contacts_total,
                    current: _vm.contact_current_coid,
                    bgstrip: true
                  },
                  on: {
                    changerange: _vm.changeContactsRange,
                    itemclick: _vm.contactClick
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "ths",
                      fn: function(ref) {
                        return undefined
                      }
                    },
                    {
                      key: "tds",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formatContactNameSimple")(
                                    item,
                                    "co_invoice_",
                                    false
                                  )
                                ) +
                                " "
                            ),
                            item.es_avatar
                              ? _c("img", {
                                  attrs: {
                                    src:
                                      _vm.$config.server_url + item.es_avatar,
                                    alt: "Logo fournisseur",
                                    width: "20px"
                                  }
                                })
                              : _vm._e()
                          ])
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "w-60" }, [
          _c(
            "div",
            { staticClass: "card h-100 overflow-auto" },
            [
              _c("contact-details", {
                ref: "contactdetails",
                attrs: {
                  value: _vm.contact_current_coid,
                  tabEnIds: _vm.tabEnIds
                },
                on: {
                  opened: _vm.contactWinEditOpen,
                  saved: _vm.contactWinEditSaved,
                  canceled: _vm.contactWinEditCanceled,
                  deleted: _vm.contactWinEditDeleted
                }
              })
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-items-center" }, [
      _c("div", { staticClass: "bis" }, [_vm._v("Contacts")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }