<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div>
              <h1>Prévisualisation et confirmation de l'envoi de l'attestation</h1>
            </div>
          </div>
          <div class="modal-body">
            <iframe :src="urlPdf" frameBorder="0" scrolling="auto" :height="iframeHeight" width="100%" target="_parent"></iframe>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between">
            <div class="d-flex flex-row align-items-end">
              <button type="button" class="btn btn-secondary" @click="cancelWin">Annuler</button>
              <button type="button" class="btn btn-primary btn-mouveal ml-2" @click="saveWin">Confirmer l'envoi de l'attestation</button>
            </div>
          </div>
        </div>
      </div>
      <m-message-dialog v-model="dialog" :title="title" :text="dialogTxt"></m-message-dialog>
    </div>
  </transition>
</template>
<script>
export default {
  name: "PrevisualizeAndSendFiscalAttestation",
  components: {},
  props: {
    value: {
      default: false,
      type: Boolean
    },
    contactFiscale: Object
  },
  data() {
    return {
      urlPdf: "",
      dialog: false,
      dialogTxt: "",
      title: "Erreur",
      iframeHeight: "0px"
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.pdfToView();
        this.iframeHeight = window.innerHeight * 60/100; //"500px";
      }
    }
  },
  async mounted() {

  },
  methods: {
    async pdfToView() {
      this.urlPdf =
        (await this.$config.server_url) +
        "/extranet/2.0/contact/pdfTax?d=" +
        new Date().getTime() +
        "&year=" +
        this.contactFiscale.year +
        "&civility=" +
        this.contactFiscale.civility +
        "&name=" +
        this.contactFiscale.name +
        "&price=" +
        this.contactFiscale.price +
        "&totalAmount=" +
        this.contactFiscale.totalAmount +
        "&address=" +
        this.contactFiscale.address +
        "&token=" +
        this.$store.state.accesstoken;
    },
    cancelWin() {
      this.$emit("winEditCanceled");
    },
    async saveWin() {
      let response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/contact/sendTaxPdf", this.contactFiscale);
      if (response.data.data === "success") {
        this.title = "Succès";
        this.dialogTxt = "L'attestation a été envoyée avec succès.";
        this.dialog = true;
        // timer
        setTimeout(() => {
          this.dialog = false;
          this.$emit("winEditCanceled");
        }, 3000);
      } else {
        this.title = "Erreur";
        this.dialogTxt = "Une erreur est survenue lors de l'envoi de l'attestation.";
        this.dialog = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../scss/styles.scss";

.winedit-container-header {
  width: 100%;
  justify-content: space-between;
}
.input-avatar {
  padding: 0;
}
.modal {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.active {
  min-width: 150px;
  input {
    width: auto;
  }
}
/*************** table droits **************/
tr {
  border-bottom: 0.5px solid #ccc9c9;
}
td:first-child {
  text-align: left;
}
td,
th {
  border-right: 0.5px solid #ccc9c9;
  border-left: 0.5px solid #ccc9c9;
  padding: 5px 10px;
  text-align: center;
}
th {
  background-color: #013c62;
  color: #fff;
  text-transform: uppercase;
}
th.groupe {
  color: black;
}
.groupe {
  background-color: #b5b2b2;
}

</style>
