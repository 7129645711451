var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: _vm.value ? "show" : "hide",
        style: _vm.value
          ? "display:block;background-color:#3333337a;"
          : "display:none; ",
        attrs: { tabindex: "-1", role: "dialog", "aria-hidden": "true" }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-scrollable",
            staticStyle: { width: "600px", "margin-top": "100px" },
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "div",
                {
                  staticClass: "modal-header d-flex flex-row align-items-center"
                },
                [
                  this.idselectedConfig == 0
                    ? _c("h1", [_vm._v("Nouvelle fonctionnalité")])
                    : _c("h1", [_vm._v("Modification de la fonctionnalité")])
                ]
              ),
              _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _c("m-form-text", {
                    attrs: {
                      label: "Nom de la fonctionnalité *",
                      type: "text",
                      name: _vm.$Utils.randomstring("bf_name"),
                      rules: [_vm.$Validation.mandatory]
                    },
                    model: {
                      value: _vm.row_bf.bf_name,
                      callback: function($$v) {
                        _vm.$set(_vm.row_bf, "bf_name", $$v)
                      },
                      expression: "row_bf.bf_name"
                    }
                  }),
                  _vm.$store.state.user.us_type === "LATEAM"
                    ? _c("m-form-text", {
                        attrs: {
                          label: "Clé de la fonctionnalité*",
                          type: "text",
                          name: _vm.$Utils.randomstring("bf_key")
                        },
                        model: {
                          value: _vm.row_bf.bf_key,
                          callback: function($$v) {
                            _vm.$set(_vm.row_bf, "bf_key", $$v)
                          },
                          expression: "row_bf.bf_key"
                        }
                      })
                    : _vm._e(),
                  _c("m-form-select", {
                    attrs: {
                      label: "Établissements",
                      name: _vm.$Utils.randomstring("es_id"),
                      rules: [_vm.$Validation.mandatory],
                      items: _vm.user.establishments
                    },
                    model: {
                      value: _vm.row_bf.es_id,
                      callback: function($$v) {
                        _vm.$set(_vm.row_bf, "es_id", $$v)
                      },
                      expression: "row_bf.es_id"
                    }
                  }),
                  _c("m-form-select", {
                    staticClass: "flex-grow-1 basis-50",
                    attrs: {
                      label: "Protocole de communication *",
                      "label-position": "top",
                      name: _vm.$Utils.randomstring("pc_id"),
                      items: _vm.$store.state.items_bf_communication
                    },
                    on: {
                      input: function($event) {
                        _vm.row_pc == "sms"
                          ? (_vm.row_bf.bf_message =
                              "C'est %ENDUSER_NAME appelle moi sur Emotivi.")
                          : (_vm.row_bf.bf_message = "")
                      }
                    },
                    model: {
                      value: _vm.row_pc,
                      callback: function($$v) {
                        _vm.row_pc = $$v
                      },
                      expression: "row_pc"
                    }
                  }),
                  _vm.row_pc !== "none"
                    ? _c(
                        "div",
                        [
                          _c("m-form-checkbox", {
                            staticClass: "mr-3",
                            attrs: {
                              label: "Autologin sur la webapp",
                              name: _vm.$Utils.randomstring("ringingDisable"),
                              setstyle: "white-space: normal !important"
                            },
                            on: {
                              input: function($event) {
                                return _vm.maxlength()
                              }
                            },
                            model: {
                              value: _vm.row_bf.bf_autologin,
                              callback: function($$v) {
                                _vm.$set(_vm.row_bf, "bf_autologin", $$v)
                              },
                              expression: "row_bf.bf_autologin"
                            }
                          }),
                          _c("m-form-text", {
                            attrs: {
                              label: "Message *",
                              type: "textarea",
                              name: _vm.$Utils.randomstring("bf_message"),
                              rules: [_vm.$Validation.mandatory],
                              maxlength: _vm.maxlength()
                            },
                            model: {
                              value: _vm.row_bf.bf_message,
                              callback: function($$v) {
                                _vm.$set(_vm.row_bf, "bf_message", $$v)
                              },
                              expression: "row_bf.bf_message"
                            }
                          }),
                          _vm.row_pc == "sms"
                            ? _c("div", [
                                _c("span", [
                                  _vm._v(
                                    "Nombre de caractères " +
                                      _vm._s(_vm.row_pc) +
                                      ": " +
                                      _vm._s(_vm.row_bf.bf_message.length) +
                                      " "
                                  ),
                                  _vm.row_bf.bf_autologin
                                    ? _c("span", [
                                        _vm._v(
                                          "(1 SMS = 100 CARACTÈRES avec l'autologin)"
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v("(1 SMS = 160 CARACTÈRES)")
                                      ])
                                ]),
                                _c("br"),
                                _vm.row_bf.bf_message.length > 160
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [
                                        _vm._v(
                                          "Nombre de caractères atteintes "
                                        ),
                                        _c("u", [_vm._v("Nombre de Messages")]),
                                        _vm._v(" : " + _vm._s(_vm.nbremess()))
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "modal-footer d-flex flex-row justify-content-between"
                },
                [
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: { type: "button" },
                        on: { click: _vm.deleteConfirmWin }
                      },
                      [_vm._v("Supprimer")]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row align-items-end" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button" },
                          on: { click: _vm.cancelWin }
                        },
                        [_vm._v("Annuler")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-mouveal ml-2",
                          attrs: { type: "button" },
                          on: { click: _vm.saveWin }
                        },
                        [_vm._v("Sauvegarder")]
                      )
                    ]
                  )
                ]
              )
            ])
          ]
        ),
        _c("m-message-dialog", {
          attrs: { title: "Erreur", text: _vm.dialogErrTxt },
          model: {
            value: _vm.dialogErr,
            callback: function($$v) {
              _vm.dialogErr = $$v
            },
            expression: "dialogErr"
          }
        }),
        _c("m-confirm-dialog", {
          attrs: {
            text: "Souhaitez-vous effacer cette fonctionnalité ?",
            sousText: "Vous ne pourrez plus revenir en arrière!",
            title: "Confirmation",
            width: "500px"
          },
          on: {
            canceled: function($event) {
              _vm.confirmdelete = false
            },
            confirmed: function($event) {
              return _vm.deleteWin()
            }
          },
          model: {
            value: _vm.confirmdelete,
            callback: function($$v) {
              _vm.confirmdelete = $$v
            },
            expression: "confirmdelete"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }