<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" style="max-width: 800px" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div>
              <div>
                <h1 v-if="cp_id === -1">Créer un planning</h1>
                <h1 v-else>Modifier un planning</h1>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <h6><span class="number-title mb-2">1</span>Sélection des boxes</h6>
            <div class="m-2">
              <div class="row">
                <m-form-checkbox
                  label="Sélectionner toutes les boxes"
                  :name="$Utils.randomstring('sélection-boxes')"
                  v-model="allBoxesSelected"
                ></m-form-checkbox>
                <m-form-text
                  class="col-4 m-auto"
                  width="200px"
                  label="Rechercher"
                  v-model="filterText"
                  name="search"
                  autocomplete
                  @input="loadBoxes"
                ></m-form-text>
              </div>

              <div :style="'min-height: 200px'" class="flex-grow-1 d-flex flex-column position-relative mt-2">
                <div style="overflow: auto; position: absolute; top: 0; left: 0; right: 0; bottom: 0" class="card card-no-margin">
                  <m-list-simple
                    ref="boxeslist"
                    :dynamic="false"
                    :items="selectConnectedBoxes ? connectedBoxes : boxes"
                    item-value="en_id"
                    :item-height="30"
                    :limit="10000"
                    :current="boxes_current"
                    :bgstrip="true"
                    @changerange="boxesChangerange"
                  >
                    <template v-slot:ths="{}">
                      <th style="width: 20px">
                        <icon width="18" height="18" name="check" class="text-info"></icon>
                      </th>
                      <th style="width: 120px">
                        <span class="pointer">état</span>
                      </th>

                      <th @click="sortItems(['en_fullname'])">
                        <span class="pointer">NOM Prénom</span>
                      </th>
                    </template>
                    <template v-slot:tds="{ item }">
                      <td style="width: 20px">
                        <div class="d-flex flex-row justify-content-center">
                          <input
                            type="checkbox"
                            @click="selectBox(item, $event)"
                            :checked="planning.cp_boxes.includes(item.bo_id.bo_id) ? true : false"
                          />
                          <!-- <icon
                            width="20"
                            height="20"
                            :name="selectedBoxes.includes(item.bo_id.bo_id) ? 'check' : 'times'"
                            :class="selectedBoxes.includes(item.bo_id.bo_id) ? 'text-info' : 'text-secondary'"
                          ></icon> -->
                          <!-- </div> -->
                        </div>
                      </td>
                      <td
                        :class="{
                          mygreen: item.bo_id && $store.state.connectedBoxes.indexOf(item.bo_id.bo_mac_address) >= 0,
                          myred: !item.bo_id || $store.state.connectedBoxes.indexOf(item.bo_id.bo_mac_address) < 0
                        }"
                      >
                        {{ item.bo_id && $store.state.connectedBoxes.indexOf(item.bo_id.bo_mac_address) >= 0 ? "Connectée" : "Non connectée" }}
                      </td>
                      <td>
                        {{ item.en_fullname }}
                      </td>
                    </template>
                  </m-list-simple>
                </div>
              </div>
            </div>
            <h6><span class="number-title mt-3 mb-2">2</span>Configuration du planning</h6>
            <div class="m-2">
              <div class="row align-items-baseline">
                <div class="col-auto pr-0">
                  <p>Type de planning :</p>
                </div>
                <div class="col">
                  <m-form-select
                    v-model="planning.cp_planning_type"
                    :name="$Utils.randomstring('establishments')"
                    :items="planningTypes"
                    :clearable="false"
                  ></m-form-select>
                </div>
                <div class="col-auto pr-0">
                  <p>Fréquence :</p>
                </div>
                <div class="col-auto pr-1">
                  <m-form-text
                    type="number"
                    max="24"
                    min="1"
                    v-model="planning.cp_interval"
                    :name="$Utils.randomstring('ringingNumber')"
                    style="width: 55px"
                  ></m-form-text>
                </div>
                <div class="col-auto pl-0">
                  <p>heure(s)</p>
                </div>
              </div>
            </div>
            <div class="m-2">
              <div class="row align-items-baseline">
                <div class="col-auto pr-0">
                  <p v-if="this.cp_id === -1">Date de démarrage :</p>
                  <p v-else>Prochain affichage :</p>
                </div>
                <div class="col">
                  <input
                    type="datetime-local"
                    id="event-end-date"
                    name="event-end-date"
                    class="datetime"
                    lang="fr-FR"
                    step="0"
                    v-model="planning.cp_next_planning"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between">
            <div>
              <button type="button" class="btn btn-secondary" @click="cancelWin">Fermer</button>
              <button type="button" class="btn btn-primary ml-2" @click="saveWin">Enregistrer</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "ModalPlanningEditor",
  components: {},
  props: {
    value: {
      default: false,
      type: Boolean
    },
    cp_id: {
      default: -1,
      type: Number
    },
    es_id: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      planning: {
        cp_boxes: [],
        cp_planning_type: 1,
        cp_interval: 1,
        cp_next_planning: null,
        cp_disabled: false,
        cp_date: null,
        es_id: this.es_id
      },
      planningTypes: [
        {
          value: 1,
          text: "Journalier"
        },
        {
          value: 2,
          text: "Hebdomadaire"
        }
      ],
      allBoxesSelected: false,
      boxes_current: "",
      boxes: [],
      connectedBoxes: [],
      selectedBoxes: [],
      selectConnectedBoxes: false,
      sortOrder: "asc",
      sortFields: ["en_id"],
      filterText: ""
    };
  },
  watch: {
    allBoxesSelected(value) {
      if (value) this.planning.cp_boxes = this.boxes.map(box => box.bo_id.bo_id);
      else this.planning.cp_boxes = [];
    }
  },
  async mounted() {
    this.loadBoxes();
    this.getPlanning();
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },

    cancelWin() {
      //this.resetData();
      this.$emit("input", false);
    },

    async getPlanning() {
      if (this.cp_id != -1) {
        let planning = await this.$axios.get(this.$config.server_url + "/extranet/2.0/calendar/plannings/" + this.cp_id);
        this.planning = planning.data.data;
      }
    },

    async saveWin() {
      // Checking
      if (this.planning.cp_boxes.length === 0) this.$store.dispatch("showDialogError", "Veuillez sélectionner au moins une box.");

      if (this.planning.cp_interval < 1 || this.planning.cp_interval > 24)
        this.$store.dispatch("showDialogError", "La fréquence doit être comprise entre 1 et 24 heures.");

      let currentDate = new Date();
      let checkingDate = new Date(this.planning.cp_next_planning);

      if (currentDate.getTime() > checkingDate.getTime())
        this.$store.dispatch("showDialogError", "La date d'affichage ne peut pas être antérieure à maintenant.");

      //Set date planning
      let date = new Date(this.planning.cp_next_planning);
      date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      this.planning.cp_date = date;

      //Create or update planning data
      try {
        if (this.cp_id === -1) {
          let response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/calendar/plannings/", this.planning);
          if (response.data.error) this.$store.dispatch("showDialogError", "Une erreur est survenue : " + response.data.error);
        } else {
          let response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/calendar/plannings/" + this.cp_id, this.planning);
          if (response.data.success === false) this.$store.dispatch("showDialogError", "Une erreur est survenue");
        }

        this.$emit("reloadEvents", true);
        this.cancelWin();
      } catch (error) {
        this.$store.dispatch("showDialogError", "Une erreur est survenue : " + error);
      }
    },

    async loadBoxes() {
      let types = [];
      let sort = this.sortFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.sortOrder;
        return accumulator;
      }, "");
      let params = {
        types: types,
        sort: sort,
        bo_id: ">0"
      };
      if (this.filterText) params.text = this.filterText; //TO DO
      if (this.es_id) params.establishment = this.es_id;

      this.cancelAxiosLoadContacts && this.cancelAxiosLoadContacts();
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/endusers", {
        params,
        cancelToken: new this.$axios.CancelToken(c => {
          this.cancelAxiosLoadContacts = c;
        })
      });
      if (!response || !response.data) return;

      this.boxes = response.data.data;

      this.connectedBoxes = this.boxes.filter(box => {
        return this.$store.state.connectedBoxes.includes(box.bo_id.bo_mac_address);
      });
    },

    boxesChangerange(skip, scrollTop) {
      this.boxes_skip = skip;
      this.loadBoxes();
    },

    selectBox(box, event) {
      // if exist remove from the list of selected boxes ( uncheck )
      let boxId = box.bo_id.bo_id;
      if (this.planning.cp_boxes.includes(boxId)) this.planning.cp_boxes.splice(this.planning.cp_boxes.indexOf(boxId), 1);
      else this.planning.cp_boxes.push(boxId);
    }
  }
};
</script>
<style scoped lang="scss">
@import "../scss/styles.scss";

.datetime {
  height: 30px;
  width: 200px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  color: #6c757d;
}
.mygreen {
  color: green;
  font-weight: bold;
}
.myred {
  color: red;
}
</style>
