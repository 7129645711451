var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: _vm.value ? "show" : "hide",
        style: _vm.value
          ? "display:block;background-color:#3333337a;"
          : "display:none;",
        attrs: { tabindex: "-1", role: "dialog", "aria-hidden": "true" }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-scrollable",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "div",
                {
                  staticClass: "modal-header d-flex flex-row align-items-center"
                },
                [
                  _c("div", [
                    _c("h1", [
                      _vm._v(" Création / Edition d'un produit ou service ")
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-row align-items-center active"
                    },
                    [
                      _c("m-form-checkbox", {
                        attrs: {
                          label: "Actif",
                          name: _vm.$Utils.randomstring("pr_active")
                        },
                        model: {
                          value: _vm.row_pr.pr_active,
                          callback: function($$v) {
                            _vm.$set(_vm.row_pr, "pr_active", $$v)
                          },
                          expression: "row_pr.pr_active"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", [
                  _c("h6", [
                    _c("span", { staticClass: "number-title" }, [_vm._v("1")]),
                    _vm._v("Nom et type")
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-8" },
                      [
                        _c("m-form-text", {
                          attrs: {
                            label: "Nom du produit / service",
                            "label-position": "top",
                            name: "name"
                          },
                          model: {
                            value: _vm.row_pr.pr_name,
                            callback: function($$v) {
                              _vm.$set(_vm.row_pr, "pr_name", $$v)
                            },
                            expression: "row_pr.pr_name"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-4" },
                      [
                        _c("m-form-select", {
                          attrs: {
                            items: _vm.$store.state.item_product_type,
                            label: "Type",
                            "label-position": "top",
                            name: _vm.$Utils.randomstring("pr_type")
                          },
                          model: {
                            value: _vm.row_pr.pr_type,
                            callback: function($$v) {
                              _vm.$set(_vm.row_pr, "pr_type", $$v)
                            },
                            expression: "row_pr.pr_type"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row" },
                    [
                      _c("m-form-text", {
                        attrs: {
                          label: "Prix achat HT",
                          "label-position": "top",
                          name: "price"
                        },
                        model: {
                          value: _vm.row_pr.pr_purchase_price_ht,
                          callback: function($$v) {
                            _vm.$set(_vm.row_pr, "pr_purchase_price_ht", $$v)
                          },
                          expression: "row_pr.pr_purchase_price_ht"
                        }
                      }),
                      _c("m-form-text", {
                        staticClass: "ml-3",
                        attrs: {
                          label: "Frais de port offerts",
                          "label-position": "top",
                          name: "port"
                        },
                        model: {
                          value: _vm.row_pr.pr_shipping_cost,
                          callback: function($$v) {
                            _vm.$set(_vm.row_pr, "pr_shipping_cost", $$v)
                          },
                          expression: "row_pr.pr_shipping_cost"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("hr"),
                _c("div", [
                  _c("h6", [
                    _c("span", { staticClass: "number-title" }, [_vm._v("2")]),
                    _vm._v(" Matériels")
                  ]),
                  _c(
                    "div",
                    { staticClass: "row d-flex flex-row align-items-end" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-8" },
                        [
                          _c("m-form-combobox", {
                            attrs: {
                              label: "Matériels",
                              "label-position": "top",
                              name: _vm.$Utils.randomstring("materialToAdd"),
                              "store-url":
                                _vm.$config.server_url +
                                "/extranet/2.0/materials",
                              "store-params": {},
                              "item-value": "ma_id",
                              "item-text": "ma_name"
                            },
                            model: {
                              value: _vm.materialToAdd,
                              callback: function($$v) {
                                _vm.materialToAdd = $$v
                              },
                              expression: "materialToAdd"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-2" },
                        [
                          _c("m-form-text", {
                            attrs: {
                              label: "Quantité",
                              "label-position": "top",
                              name: "qte"
                            },
                            model: {
                              value: _vm.addMaterialQte,
                              callback: function($$v) {
                                _vm.addMaterialQte = $$v
                              },
                              expression: "addMaterialQte"
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-md-2" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary mr-5 ml-2",
                            attrs: { type: "button" },
                            on: { click: _vm.addMaterial }
                          },
                          [_vm._v(" Ajouter ")]
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row mt-3" },
                    _vm._l(_vm.materialList, function(prma) {
                      return _c(
                        "div",
                        {
                          key: prma.prma_id,
                          staticClass:
                            "d-flex flex-column align-items-center mx-2"
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("vue-load-image", [
                                _c("img", {
                                  attrs: {
                                    slot: "image",
                                    src:
                                      _vm.$config.server_url +
                                      "/extranet/2.0/materials/image/" +
                                      prma.ma_id.ma_id +
                                      "/70/50?d=" +
                                      new Date().getTime() +
                                      "&token=" +
                                      _vm.$store.state.accesstoken
                                  },
                                  slot: "image"
                                }),
                                _c("img", {
                                  attrs: {
                                    slot: "preloader",
                                    src: "images/image-loader70.gif"
                                  },
                                  slot: "preloader"
                                }),
                                _c(
                                  "div",
                                  { attrs: { slot: "error" }, slot: "error" },
                                  [_vm._v("pas d'image")]
                                )
                              ])
                            ],
                            1
                          ),
                          _c("div", [
                            _vm._v(" " + _vm._s(prma.ma_id.ma_name) + " ")
                          ]),
                          _c("div", { staticClass: "d-flex flex-row" }, [
                            _c(
                              "div",
                              [
                                _c("m-form-text", {
                                  staticClass: "mt-0 mr-2",
                                  attrs: { width: "46px" },
                                  model: {
                                    value: prma.prma_quantity,
                                    callback: function($$v) {
                                      _vm.$set(prma, "prma_quantity", $$v)
                                    },
                                    expression: "prma.prma_quantity"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "pointer",
                                on: {
                                  click: function($event) {
                                    return _vm.deleteMaterial(prma)
                                  }
                                }
                              },
                              [
                                _c("icon", {
                                  attrs: { name: "trash-alt", color: "red" }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    }),
                    0
                  )
                ]),
                _c("hr"),
                _c("div", [
                  _c("h6", [
                    _c("span", { staticClass: "number-title" }, [_vm._v("3")]),
                    _vm._v(" Présent dans les offres suivantes")
                  ]),
                  _vm.pr_id > 0
                    ? _c("div", { staticClass: "ml-4" }, [
                        _c(
                          "ul",
                          _vm._l(_vm.row_pr.offersproducts, function(ofpr) {
                            return _c(
                              "li",
                              {
                                key: ofpr.ofpr_id,
                                staticClass: "pointer",
                                on: {
                                  click: function($event) {
                                    return _vm.goOffer(_vm.offer)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(ofpr.of_id.of_name) + " ")]
                            )
                          }),
                          0
                        )
                      ])
                    : _c("div", [
                        _vm._v(
                          "Vous pouvez ajouter ce produit à une offre à partir de l'édition d'une offre."
                        )
                      ])
                ])
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "modal-footer d-flex flex-row justify-content-between"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      attrs: { type: "button" },
                      on: { click: _vm.deleteConfirmWin }
                    },
                    [_vm._v(" Supprimer ")]
                  ),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button" },
                        on: { click: _vm.cancelWin }
                      },
                      [_vm._v(" Annuler ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary ml-2",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.saveWin(false)
                          }
                        }
                      },
                      [_vm._v(" Enregistrer ")]
                    )
                  ])
                ]
              )
            ])
          ]
        ),
        _c("m-message-dialog", {
          attrs: { title: "Erreur", text: _vm.dialogErrTxt },
          model: {
            value: _vm.dialogErr,
            callback: function($$v) {
              _vm.dialogErr = $$v
            },
            expression: "dialogErr"
          }
        }),
        _c("m-confirm-dialog", {
          attrs: {
            text: "Ce produit sera mis en non-actif.",
            title: "Confirmation",
            width: "500px"
          },
          on: {
            canceled: function($event) {
              _vm.confirmdelete = false
            },
            confirmed: function($event) {
              return _vm.deleteWin()
            }
          },
          model: {
            value: _vm.confirmdelete,
            callback: function($$v) {
              _vm.confirmdelete = $$v
            },
            expression: "confirmdelete"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }