<template>
  <div class="m-minutes" style="width: 520px">
    <div class="minutes d-flex flex-column mb-2">
      <div class="titre">Les minutes</div>

      <div class="d-flex flex-row flex-wrap mt-1">
        <div class="d-flex flex-row flex-wrap mt-1">
          <div
            v-for="minute in tabMinutes"
            :key="minute"
            class="form-control btn-time"
            :class="{ selected: minuteSelected === minute }"
            @click="setMinute(minute)"
          >
            {{ minute }}
          </div>
        </div>
      </div>
    </div>
    <div class="seconds d-flex flex-column">
      <div class="titre">Les secondes</div>
      <div class="d-flex flex-row flex-wrap mt-1">
        <div
          v-for="second in tabSeconds"
          :key="second"
          class="form-control btn-time"
          :class="{ selected: secondSelected === second }"
          @click="setSeconds(second)"
        >
          {{ second }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import moment from "moment";

export default {
  name: "mminutes",
  props: {
    value: {
      default: function() {
        return "00:00";
      },
      type: String
    },
    intervalSeconds: {
      default: function() {
        return 5;
      },
      type: Number
    }
  },
  data() {
    let tabSeconds = [];
    for (let i = 0; i < 60; i += this.intervalSeconds) {
      tabSeconds.push(i < 10 ? "0" + i : i + "");
    }
    let tabMinutes = [];
    for (let i = 0; i < 60; i++) {
      tabMinutes.push(i < 10 ? "0" + i : i + "");
    }
    return {
      secondSelected: "00",
      minuteSelected: "00",
      tabSeconds,
      tabMinutes
    };
  },
  watch: {
    value(v) {
      this.setValue(v);
    }
  },
  mounted() {
    if (this.value) this.setValue(this.value);
  },
  methods: {
    setValue(val) {
      let tabMinutes = val.split(":");
      this.minuteSelected = tabMinutes[0];
      this.secondSelected = tabMinutes[1];
    },
    setSeconds(seconds) {
      this.secondSelected = seconds;
      this.setTime();
    },
    setMinute(minute) {
      this.minuteSelected = minute;
      this.setTime();
      this.$emit("setMinute", {
        seconds: this.secondSelected,
        minutes: this.minuteSelected
      });
    },
    setTime() {
      this.$emit("setTime", {
        seconds: this.secondSelected,
        minutes: this.minuteSelected
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

.icon-menu {
  cursor: pointer;
}
.m-minutes {
  padding: 15px;
  .titre {
    border-bottom: solid 1px gray;
  }
  .btn-time {
    width: 40px;
    cursor: pointer;
  }
  .selected {
    background-color: $green;
  }
}
</style>
