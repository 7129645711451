var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal",
      class: _vm.value ? "show" : "hide",
      style: _vm.value
        ? "display:block;background-color:#3333337a;"
        : "display:none;",
      attrs: { tabindex: "-1", role: "dialog", "aria-hidden": "true" }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-scrollable event-editor",
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c(
              "div",
              {
                staticClass: "modal-header d-flex flex-row align-items-center"
              },
              [
                _c("div", [
                  _vm.event_id === -1
                    ? _c("h1", [_vm._v("Planifier un événement")])
                    : _c("h1", [_vm._v("Modifier un événement")])
                ])
              ]
            ),
            _c("div", { staticClass: "modal-body" }, [
              _vm._m(0),
              _c("div", { staticClass: "m-2" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("m-form-checkbox", {
                      attrs: {
                        label: "Sélectionner toutes les boxes",
                        name: _vm.$Utils.randomstring("sélection-boxes")
                      },
                      model: {
                        value: _vm.allBoxesSelected,
                        callback: function($$v) {
                          _vm.allBoxesSelected = $$v
                        },
                        expression: "allBoxesSelected"
                      }
                    }),
                    _c("m-form-text", {
                      staticClass: "col-4 m-auto",
                      attrs: {
                        width: "200px",
                        label: "Rechercher",
                        name: "search",
                        autocomplete: ""
                      },
                      on: { input: _vm.loadBoxes },
                      model: {
                        value: _vm.filterText,
                        callback: function($$v) {
                          _vm.filterText = $$v
                        },
                        expression: "filterText"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex-grow-1 d-flex flex-column position-relative mt-2",
                    style: "min-height: 300px;"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "card card-no-margin",
                        staticStyle: {
                          overflow: "auto",
                          position: "absolute",
                          top: "0",
                          left: "0",
                          right: "0",
                          bottom: "0"
                        }
                      },
                      [
                        _c("m-list-simple", {
                          ref: "boxeslist",
                          attrs: {
                            dynamic: false,
                            items: _vm.selectConnectedBoxes
                              ? _vm.connectedBoxes
                              : _vm.boxes,
                            "item-value": "en_id",
                            "item-height": 30,
                            limit: 10000,
                            current: _vm.boxes_current,
                            bgstrip: true
                          },
                          on: { changerange: _vm.boxesChangerange },
                          scopedSlots: _vm._u([
                            {
                              key: "ths",
                              fn: function(ref) {
                                return [
                                  _c(
                                    "th",
                                    { staticStyle: { width: "20px" } },
                                    [
                                      _c("icon", {
                                        staticClass: "text-info",
                                        attrs: {
                                          width: "18",
                                          height: "18",
                                          name: "check"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "th",
                                    { staticStyle: { width: "120px" } },
                                    [
                                      _c("span", { staticClass: "pointer" }, [
                                        _vm._v("état")
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.sortItems(["en_fullname"])
                                        }
                                      }
                                    },
                                    [
                                      _c("span", { staticClass: "pointer" }, [
                                        _vm._v("NOM Prénom")
                                      ])
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "tds",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c("td", { staticStyle: { width: "20px" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-row justify-content-center"
                                      },
                                      [
                                        _c("input", {
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: _vm.selectedBoxes.includes(
                                              item.bo_id.bo_id
                                            )
                                              ? true
                                              : false
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.selectBox(item, $event)
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ]),
                                  _c(
                                    "td",
                                    {
                                      class: {
                                        mygreen:
                                          item.bo_id &&
                                          _vm.$store.state.connectedBoxes.indexOf(
                                            item.bo_id.bo_mac_address
                                          ) >= 0,
                                        myred:
                                          !item.bo_id ||
                                          _vm.$store.state.connectedBoxes.indexOf(
                                            item.bo_id.bo_mac_address
                                          ) < 0
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.bo_id &&
                                              _vm.$store.state.connectedBoxes.indexOf(
                                                item.bo_id.bo_mac_address
                                              ) >= 0
                                              ? "Connectée"
                                              : "Non connectée"
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("td", [
                                    _vm._v(" " + _vm._s(item.en_fullname) + " ")
                                  ])
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ]
                )
              ]),
              _vm._m(1),
              _c(
                "div",
                { staticClass: "event-form m-2" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between"
                    },
                    [
                      _c("div", [
                        _c(
                          "label",
                          {
                            staticClass: "mb-0",
                            staticStyle: { width: "110px" },
                            attrs: { for: "event-start-date" }
                          },
                          [_vm._v("Date début :")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.event.ce_startTime,
                              expression: "event.ce_startTime"
                            }
                          ],
                          staticClass: "datetime mr-4",
                          attrs: {
                            type: "datetime-local",
                            id: "event-start-date",
                            name: "event-start-date"
                          },
                          domProps: { value: _vm.event.ce_startTime },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.event,
                                "ce_startTime",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _c("div", [
                        _c(
                          "label",
                          {
                            staticClass: "text-right pr-2 mb-0",
                            staticStyle: { width: "110px" },
                            attrs: { for: "event-end-date" }
                          },
                          [_vm._v("Date fin :")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.event.ce_endTime,
                              expression: "event.ce_endTime"
                            }
                          ],
                          staticClass: "datetime",
                          attrs: {
                            type: "datetime-local",
                            id: "event-end-date",
                            name: "event-end-date"
                          },
                          domProps: { value: _vm.event.ce_endTime },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.event,
                                "ce_endTime",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]
                  ),
                  _c("div", { staticClass: "row mt-2" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-lg-auto col-md-12 pr-0 mt-1",
                        staticStyle: { width: "110px" }
                      },
                      [_vm._v("Activité :")]
                    ),
                    _c("div", { staticClass: "col" }, [
                      _c("div", { staticStyle: { position: "relative" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "activities-selecter",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function($event) {
                                _vm.selectActivities = !_vm.selectActivities
                              }
                            }
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.event.ca_id
                                    ? _vm.savedActivities.find(function(act) {
                                        return act.value === _vm.event.ca_id
                                      }).text
                                    : "Selectionner une activité"
                                )
                              )
                            ])
                          ]
                        ),
                        _vm.selectActivities
                          ? _c(
                              "div",
                              { staticClass: "activities-container w-100" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "row activities-list m-0" },
                                  [
                                    _vm.savedActivities.length > 0
                                      ? _c(
                                          "div",
                                          { staticClass: "col p-0" },
                                          _vm._l(_vm.savedActivities, function(
                                            act
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                key: act.value,
                                                staticClass: "activity-item"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "row m-0" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-auto pr-0 m-1 mt-2 justify-content-bottom"
                                                      },
                                                      [
                                                        _c("div", {
                                                          staticClass:
                                                            "activity-color-preview",
                                                          style: {
                                                            backgroundColor:
                                                              act.color
                                                          }
                                                        })
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col m-1 pl-0",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.setActivity(
                                                              act.value
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(act.text) +
                                                            " "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-auto pr-0"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "delete-activity",
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.deleteActivity(
                                                                  act.value
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [_vm._v("Supprimer")]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          }),
                                          0
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "col p-3 text-center"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "grey",
                                                  "font-style": "italic"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "Créer une activité et définir une couleur associée."
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                  ]
                                ),
                                _c("hr", { staticClass: "m-0" }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row activities-add-container pl-3"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-auto activity-input-color"
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.newActivity.color,
                                              expression: "newActivity.color"
                                            }
                                          ],
                                          attrs: {
                                            type: "color",
                                            id: "favcolor",
                                            name: "favcolor"
                                          },
                                          domProps: {
                                            value: _vm.newActivity.color
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.newActivity,
                                                "color",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col pl-0" },
                                      [
                                        _c("m-form-text", {
                                          attrs: {
                                            name: _vm.$Utils.randomstring(
                                              "newActivity"
                                            ),
                                            placeholder: "Nouvelle activité"
                                          },
                                          model: {
                                            value: _vm.newActivity.title,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.newActivity,
                                                "title",
                                                $$v
                                              )
                                            },
                                            expression: "newActivity.title"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-auto pl-0" },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-primary mr-3 p-0 pr-2 pl-2",
                                            staticStyle: {
                                              height: "30px",
                                              "margin-top": "15px"
                                            },
                                            attrs: { type: "button" },
                                            on: {
                                              click: function($event) {
                                                return _vm.addActivity()
                                              }
                                            }
                                          },
                                          [_vm._v(" Ajouter ")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ])
                  ]),
                  _c("hr"),
                  _c("div", { staticClass: "row mt-2" }, [
                    _c("div", { staticClass: "col-auto pr-0" }, [
                      _c("span", [
                        _vm._v(
                          "Rappels (" + _vm._s(_vm.reminders.length) + "/2)"
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "col-auto" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary pt-0 pb-0",
                          staticStyle: { width: "auto", height: "25px" },
                          attrs: {
                            type: "button",
                            disabled: _vm.reminders.length >= 2
                          },
                          on: {
                            click: function($event) {
                              return _vm.addReminder()
                            }
                          }
                        },
                        [_vm._v(" Ajouter un rappel ")]
                      )
                    ])
                  ]),
                  _vm._l(_vm.reminders, function(reminder, re_index) {
                    return _c("div", { key: re_index }, [
                      _c(
                        "div",
                        {
                          staticClass: "row align-items-center",
                          staticStyle: { height: "55px" }
                        },
                        [
                          _c("div", { staticClass: "col-auto pr-1" }, [
                            _c("span", [
                              _vm._v(
                                "Envoyer un " +
                                  _vm._s(re_index == 0 ? "premier" : "second") +
                                  " rappel "
                              )
                            ])
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-auto p-1" },
                            [
                              _c("m-form-text", {
                                staticStyle: { width: "60px" },
                                attrs: {
                                  type: "number",
                                  max: "60",
                                  min: "5",
                                  name: _vm.$Utils.randomstring("ringingNumber")
                                },
                                model: {
                                  value: _vm.reminders[re_index],
                                  callback: function($$v) {
                                    _vm.$set(_vm.reminders, re_index, $$v)
                                  },
                                  expression: "reminders[re_index]"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._m(2, true),
                          _c(
                            "div",
                            { staticClass: "col-auto" },
                            [
                              _c("icon", {
                                staticClass: "pointer",
                                attrs: {
                                  height: "20px",
                                  width: "20px",
                                  name: "trash-alt"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.removeReminder(re_index)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ])
                  })
                ],
                2
              ),
              _vm.event_id === -1 && !_vm.eventDurationExceedOneDay
                ? _c("div", [
                    _vm._m(3),
                    _c("div", { staticClass: "m-2" }, [
                      _c("div", { staticClass: "row align-items-baseline" }, [
                        _vm._m(4),
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c("m-form-select", {
                              attrs: {
                                name: _vm.$Utils.randomstring("establishments"),
                                items: _vm.recurrenceList,
                                clearable: false
                              },
                              model: {
                                value: _vm.selectedRecurrence,
                                callback: function($$v) {
                                  _vm.selectedRecurrence = $$v
                                },
                                expression: "selectedRecurrence"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm.selectedRecurrence !== 0
                        ? _c(
                            "div",
                            { staticClass: "d-flex align-items-baseline" },
                            [
                              _c("div", { staticClass: "col p-0" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "text-right pr-2 mb-0",
                                    attrs: { for: "event-end-date" }
                                  },
                                  [_vm._v("Répéter l'événement jusqu'au : ")]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.recurrenceEndDate,
                                      expression: "recurrenceEndDate"
                                    }
                                  ],
                                  staticClass: "datetime",
                                  attrs: {
                                    type: "date",
                                    id: "event-end-date",
                                    name: "event-end-date",
                                    lang: "fr-FR"
                                  },
                                  domProps: { value: _vm.recurrenceEndDate },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.recurrenceEndDate =
                                        $event.target.value
                                    }
                                  }
                                })
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.selectedRecurrence === 2
                        ? _c("div", { staticClass: "mt-3" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row justify-content-center text-center"
                              },
                              [
                                _vm._m(5),
                                _vm._l(_vm.recurrenceDays, function(
                                  day,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "col" },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            "font-weight": "bold",
                                            margin: "0"
                                          }
                                        },
                                        [_vm._v(_vm._s(day.dayName))]
                                      ),
                                      _c("m-form-checkbox", {
                                        attrs: {
                                          name: _vm.$Utils.randomstring(
                                            "sélection-boxes"
                                          )
                                        },
                                        model: {
                                          value: day.selected,
                                          callback: function($$v) {
                                            _vm.$set(day, "selected", $$v)
                                          },
                                          expression: "day.selected"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                : _vm._e(),
              _vm.eventDurationExceedOneDay
                ? _c("div", [
                    _vm._m(6),
                    _c("div", { staticClass: "alert alert-warning" }, [
                      _vm._v(
                        "Cet événement ne peut pas être récurrent, sa durée étant égale ou supérieure à une journée complète"
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.recurrentEvents.length > 1
                ? _c("div", [
                    _vm._m(7),
                    _c("div", { staticClass: "alert alert-warning" }, [
                      _vm._v("Cet événement est déjà récurrent")
                    ])
                  ])
                : _vm._e()
            ]),
            _c(
              "div",
              {
                staticClass:
                  "modal-footer d-flex flex-row justify-content-between"
              },
              [
                _c("div", [
                  _vm.event_id != -1
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: { type: "button" },
                          on: { click: _vm.deleteEvent }
                        },
                        [_vm._v("Supprimer l'événement")]
                      )
                    : _vm._e()
                ]),
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: { click: _vm.cancelWin }
                    },
                    [_vm._v("Annuler")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary ml-2",
                      attrs: { type: "button" },
                      on: { click: _vm.saveWin }
                    },
                    [_vm._v("Enregistrer")]
                  )
                ])
              ]
            )
          ])
        ]
      ),
      _c("m-confirm-dialog", {
        attrs: {
          text: "Souhaitez-vous vraiment supprimer ce rappel ?",
          title: "Supprimer un rappel",
          width: "500px"
        },
        on: {
          canceled: function($event) {
            _vm.dialogConfirmReminderDelete = false
          },
          confirmed: function() {
            this$1.dialogConfirmReminderDelete = false
            this$1.reminders.splice(this$1.tempDeleteReminder, 1)
          }
        },
        model: {
          value: _vm.dialogConfirmReminderDelete,
          callback: function($$v) {
            _vm.dialogConfirmReminderDelete = $$v
          },
          expression: "dialogConfirmReminderDelete"
        }
      }),
      _vm.modalEventDelete
        ? _c("modal-event-delete", {
            attrs: {
              event_uuid: _vm.event.ce_group_uuid,
              event_id: _vm.event_id
            },
            on: {
              deletedEvent: function($event) {
                return _vm.$emit("reloadEvents", true)
              }
            },
            model: {
              value: _vm.modalEventDelete,
              callback: function($$v) {
                _vm.modalEventDelete = $$v
              },
              expression: "modalEventDelete"
            }
          })
        : _vm._e(),
      _vm.modalEventChanges
        ? _c("modal-event-changes", {
            attrs: { eventsChanges: _vm.eventsChanges },
            on: {
              reloadEvents: function($event) {
                return _vm.$emit("reloadEvents", true)
              }
            },
            model: {
              value: _vm.modalEventChanges,
              callback: function($$v) {
                _vm.modalEventChanges = $$v
              },
              expression: "modalEventChanges"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", [
      _c("span", { staticClass: "number-title mb-2" }, [_vm._v("1")]),
      _vm._v("Sélection des boxes")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", [
      _c("span", { staticClass: "number-title mt-2 mb-2" }, [_vm._v("2")]),
      _vm._v("Evenement")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col p-0" }, [
      _c("span", [_vm._v("minutes avant l'heure.")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", [
      _c("span", { staticClass: "number-title mt-2" }, [_vm._v("3")]),
      _vm._v("Récurrence")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-auto", staticStyle: { width: "200px" } },
      [_c("p", [_vm._v("Type de récurrence :")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-auto p-0" }, [
      _c("p", { staticStyle: { width: "115px" } }, [_vm._v("Répétition :")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", [
      _c("span", { staticClass: "number-title mt-2" }, [_vm._v("3")]),
      _vm._v("Récurrence")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", [
      _c("span", { staticClass: "number-title mt-2" }, [_vm._v("3")]),
      _vm._v("Récurrence")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }