<template>
  <section class="d-flex w-100">
    <div class="d-flex flex-column w-100" id="boxes">
      <div class="d-flex header-emotivi">
        <div class="d-flex align-items-center">
          <h1>Gestion des stocks matériel</h1>
        </div>
        <div class="flex-grow-1 d-flex justify-content-end align-items-end" style="margin-top: -40px">
          <m-form-checkbox label="Non-actif" :name="$Utils.randomstring('active')" v-model="filterNotActive" @input="loadMaterials"></m-form-checkbox>
          <m-form-text
            class="ml-3"
            label="Rechercher"
            label-position="top"
            v-model="filterText"
            name="search"
            autocomplete
            @input="loadMaterials"
          ></m-form-text>
          <m-form-combobox
            class="ml-3"
            :width="270"
            label="Rechercher par produit"
            label-position="top"
            :name="$Utils.randomstring('filter_product')"
            v-model="filterProduct"
            :store-url="$config.server_url + '/extranet/2.0/products'"
            :store-params="{}"
            item-value="pr_id"
            item-text="pr_name"
            @input="loadMaterials"
          ></m-form-combobox>
          <button class="ml-3 btn btn-sm btn-primary color-white" type="button" @click="addMaterials">
            <icon name="plus" scale="1" class="mr-2"></icon> Nouveau
          </button>
        </div>
      </div>

      <div class="flex-grow-1 overflow-hidden d-flex w-100">
        <div class="card card-no-margin w-100">
          <m-list-simple :items="materials" item-value="ma_id" @itemclick="editMaterials">
            <template v-slot:ths="{}">
              <th style="width: 90px"></th>
              <th style="min-width: 50px" @click="sortItems(['ma_name'])">
                <span class="pointer">Désignation</span>
              </th>
              <th class="" @click="sortItems(['ma_email'])">
                <span class="pointer">Stock inventaire</span>
              </th>
              <th class="" @click="sortItems(['ma_email'])">
                <span class="pointer">Stock actuel</span>
              </th>
              <th @click="sortItems(['ma_post'])">
                <span class="pointer">Stock commandé</span>
              </th>
              <th @click="sortItems(['ma_post'])">
                <span class="pointer">Derniere commande</span>
              </th>
            </template>
            <template v-slot:tds="{ item }">
              <td>
                <vue-load-image>
                  <img
                    slot="image"
                    :src="
                      $config.server_url +
                      '/extranet/2.0/materials/image/' +
                      item.ma_id +
                      '/70/50?d=' +
                      new Date().getTime() +
                      '&token=' +
                      $store.state.accesstoken
                    "
                  />
                  <img slot="preloader" src="images/image-loader70.gif" />
                  <div slot="error">pas d'image</div>
                </vue-load-image>
                <!--                 <img
                  class="img-fluid"
                  :src="
                    $config.server_url +
                      '/extranet/2.0/materials/image/' +
                      item.ma_id +
                      '/70/50?d=' +
                      new Date().getTime() +
                      '&token=' +
                      $store.state.accesstoken
                  "
                  alt="image material"
                /> -->
              </td>
              <td>
                {{ item.ma_name }}
              </td>
              <td>
                <div class="d-flex flex-column">
                  <div>{{ item.stockline_inventory ? item.stockline_inventory.st_quantity : "" }}</div>
                  <div>{{ item.stockline_inventory ? $moment(item.stockline_inventory.createdAt).format("DD/MM/YYYY") : "" }}</div>
                </div>
              </td>
              <td>
                {{ item.ma_stock_available }}
              </td>
              <td>
                {{ item.ma_stock_ordered }}
              </td>
              <td>
                <div class="d-flex flex-column">
                  <div>
                    {{ item.stockline_order ? item.stockline_order.st_quantity : "" }}
                    <span v-if="item.stockline_order.createdAt">{{
                      item.stockline_order && item.stockline_order.createdAt
                        ? "(le " + $moment(item.stockline_order.createdAt).format("DD/MM/YYYY") + ")"
                        : ""
                    }}</span>
                    <span v-else>-</span>
                  </div>
                  <div>{{ item.stockline_order ? item.stockline_order.st_provider_name : "" }}</div>
                </div>
              </td>
            </template>
          </m-list-simple>
        </div>
      </div>
    </div>
    <materials-win-edit :ma_id="itemSelected.ma_id" v-model="dialog" @materialSaved="loadMaterials"></materials-win-edit>
  </section>
</template>

<script>
export default {
  name: "materials",
  components: {},
  data() {
    return {
      materials: [],
      materials_total: 0,
      materials_limit: 0,
      materials_skip: 0,
      showOccupedRoom: false,
      filterText: "",
      filterNotActive: false,
      filterProduct: {},
      sortOrder: "asc",
      sortFields: [],
      itemsTypes: [
        { text: "Tous", value: "0" },
        { text: "Inscrit", value: "2" },
        { text: "Payé", value: "3" }
      ],
      itemSelected: {},
      dialog: false
    };
  },
  watch: {
    filterProduct() {
      this.loadMaterials();
    }
  },
  async mounted() {
    let id = parseInt(this.$route.params.id);
    if (id) {
      this.itemSelected.ma_id = id;
      this.dialog = true;
    }
    this.loadMaterials();
  },
  computed: {},
  methods: {
    sortItems(fields) {
      if (fields.length && this.sortFields.length && fields[0] == this.sortFields[0]) {
        this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
      }
      this.sortFields = fields;
      if (fields[0] !== "ma_connection_date") {
        this.loadContacts();
      } else {
        this.patients.sort((a, b) => {
          if (this.sortOrder === "asc") {
            if (this.$moment(a.ma_connection_date).isBefore(b.ma_connection_date)) return -1;
            if (this.$moment(a.ma_connection_date).isAfter(b.ma_connection_date)) return 1;
            if (this.$moment(a.ma_connection_date).isSame(b.ma_connection_date)) return 0;
          } else {
            if (this.$moment(a.ma_connection_date).isBefore(b.ma_connection_date)) return 1;
            if (this.$moment(a.ma_connection_date).isAfter(b.ma_connection_date)) return -1;
            if (this.$moment(a.ma_connection_date).isSame(b.ma_connection_date)) return 0;
          }
        });
      }
    },
    async loadMaterials() {
      let sort = this.sortFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.sortOrder;
        return accumulator;
      }, "");
      let params = {
        notactive: this.filterNotActive,
        sort: sort
      };
      if (this.filterText) params.text = this.filterText;
      let response;
      if (this.filterProduct && this.filterProduct.pr_id) {
        response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/productsmaterials/" + this.filterProduct.pr_id, {
          params
        });
        if (!response || !response.data) return;
        let mat = [];
        for (let iprma = 0; iprma < response.data.data.length; iprma++) {
          const prma = response.data.data[iprma];
          mat.push(prma.ma_id);
        }
        this.materials = mat;
        this.materials_total = mat.length;
      } else {
        response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/materials", {
          params
        });
        if (!response || !response.data) return;
        this.materials = response.data.data;
        console.log("this.materials ", this.materials);
        this.materials_total = response.data.meta.total;
      }
    },

    addMaterials() {
      this.itemSelected = {};
      this.$router.push("/materials/-1");
      this.dialog = true;
    },
    editMaterials(item) {
      this.itemSelected = item;
      this.$router.push("/materials/" + this.itemSelected.ma_id);
      this.dialog = true;
    },
    importMaterials() {},
    exportMaterials() {}
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";
.table td {
  vertical-align: middle;
}
</style>
