<template>
  <div id="scheduleMessage" ref="schedulemessage">
    <div class="mt-4 col-12">
      <form action enctype="multipart/form-data" method="post">
        <h6>Message</h6>
        <m-form-text type="textarea" v-model="outputPlanningObject.pl_message" name="message" class=""></m-form-text>
        <h6 class="mt-2">Image</h6>
        <div v-show="!photoJoined">
          <label class="fileContainer d-flex justify-content-center" for="planningmedia">
            <!-- <div class="align-self-center btn btn-primary">Choisir un fichier</div> -->
          </label>
          <input
            type="file"
            class="form-control inputImage"
            @change="handleFile()"
            id="planningmedia"
            name="file"
            ref="planningmedia"
            style="display: none"
          />
        </div>
        <div v-show="photoJoined">
          <label id="container-media-planning" for="planningmedia" ref="previewContainer" style="width: 100%; max-height:100%; position:relative">
            <div id="container-media-planning-content" ref="preview"></div>
            <button class="btn btn-rotate mr-2 btn-img rotate-left" v-on:click.prevent="rotateFile($event, 'left')">
              <icon name="undo-alt"></icon>
            </button>
            <button class="btn btn-rotate mr-2 btn-img rotate-right" v-on:click.prevent="rotateFile($event, 'right')">
              <icon name="redo-alt"></icon>
            </button>
            <button class="btn btn-danger mr-2 btn-img trash" v-on:click.prevent="deleteFile($event)">
              <icon name="trash-alt"></icon>
            </button>
          </label>
        </div>
        <div style="display: none">
          <canvas ref="canvas" width="1024" height="768"></canvas>
        </div>
        <h6 class="mt-2">Récurrence du message</h6>
        <div>
          <div class="row">
            <div class="col-md-10">
              <div class="row">
                <m-form-date
                  class="col-6"
                  label="Date de démarrage"
                  :name="$Utils.randomstring('pl_datedebut')"
                  formatInput="DD/MM/YYYY HH:mm:ss"
                  formatValue="YYYY-MM-DD HH:mm:ss"
                  v-model="outputPlanningObject.pl_datedebut"
                ></m-form-date>
                <m-form-hour
                  class="col-6"
                  label="Horaire de démarrage"
                  :name="$Utils.randomstring('planningHour')"
                  formatInput="HH:mm"
                  formatValue="HH:mm"
                  v-model="planningHour"
                ></m-form-hour>
              </div>

              <m-form-select
                label="Récurrence"
                v-model="outputPlanningObject.pl_cycle"
                :name="$Utils.randomstring('pl_cycle')"
                placeholder="Choisissez"
                :items="cycle"
              ></m-form-select>
              <m-form-date
                v-if="outputPlanningObject.pl_cycle && outputPlanningObject.pl_cycle !== 'onetime'"
                label="Date de fin"
                :name="$Utils.randomstring('pl_dateend')"
                formatInput="DD/MM/YYYY"
                formatValue="YYYY-MM-DD"
                v-model="outputPlanningObject.pl_dateend"
              ></m-form-date>
            </div>
          </div>
          <!-- Faire en sorte de n'afficher les 2 div class="row mt-2" ci-dessous que lorsque l'utilisateur a sélectionné la récurrence jour(s) ou semaine (s) -->
          <div v-if="outputPlanningObject.pl_cycle && outputPlanningObject.pl_cycle !== 'onetime'" class="row mt-2">
            <div class="col-md-12 justify-content-left">
              <span
                >Envoyer un message {{ outputPlanningObject.pl_cycle === "weeks" ? "toutes" : "tous" }} les
                <input
                  min="1"
                  :max="outputPlanningObject.pl_cycle === 'days' ? '30' : '4'"
                  v-model.number="outputPlanningObject.pl_cyclevalue"
                  type="number"
                  style="max-width: 50px; text-align: center;"
                />
                {{ outputPlanningObject.pl_cycle === "days" ? "jours" : outputPlanningObject.pl_cycle === "weeks" ? "semaines le(s) :" : "" }}</span
              >
            </div>
          </div>
          <div v-if="outputPlanningObject.pl_cycle === 'weeks'" class="row mt-2 justify-content-center">
            <div :class="daysButtonSwitch['monday'] ? 'col-md-1 btn btn-primary' : 'col-md-1 btn btn-secondary'" @click="setWeekDays(1, 'monday')">
              <!-- Faire en sorte que les jours soient cliquables avec en utilisant le v-model pl_weekdays pour récupérer l'info -->
              <!-- <input v-model="pl_weekdays" placeholder="L" style="text-align: center;"/> -->
              <span>L</span>
            </div>
            <div :class="daysButtonSwitch['tuesday'] ? 'col-md-1 btn btn-primary' : 'col-md-1 btn btn-secondary'" @click="setWeekDays(2, 'tuesday')">
              <span>Ma</span>
            </div>
            <div
              :class="daysButtonSwitch['wednesday'] ? 'col-md-1 btn btn-primary' : 'col-md-1 btn btn-secondary'"
              @click="setWeekDays(4, 'wednesday')"
            >
              <span>Me</span>
            </div>
            <div
              :class="daysButtonSwitch['thursday'] ? 'col-md-1 btn btn-primary' : 'col-md-1 btn btn-secondary'"
              @click="setWeekDays(8, 'thursday')"
            >
              <span>J</span>
            </div>
            <div :class="daysButtonSwitch['friday'] ? 'col-md-1 btn btn-primary' : 'col-md-1 btn btn-secondary'" @click="setWeekDays(16, 'friday')">
              <span>V</span>
            </div>
            <div
              :class="daysButtonSwitch['saturday'] ? 'col-md-1 btn btn-primary' : 'col-md-1 btn btn-secondary'"
              @click="setWeekDays(32, 'saturday')"
            >
              <span>S</span>
            </div>
            <div :class="daysButtonSwitch['sunday'] ? 'col-md-1 btn btn-primary' : 'col-md-1 btn btn-secondary'" @click="setWeekDays(64, 'sunday')">
              <span>D</span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
const IMAGE_MAX = 1024;
export default {
  name: "ScheduleMessage",
  props: {
    inputPlanningObject: {
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      outputPlanningObject: {
        pl_datedebut: this.$moment(),
        pl_dateend: null,
        pl_message: "",
        pl_cycle: "",
        pl_weekdays: 0,
        pl_id: -1,
        pl_messagemedia: "",
        us_id: this.$store.state.user.us_id,
        en_ids: []
      },
      cycle: [
        { text: "Ponctuelle", value: "onetime" },
        { text: "jour(s)", value: "days" },
        { text: "semaine(s)", value: "weeks" }
      ],
      image: "",
      photo: true,
      imageSize: { width: 0, height: 0 },
      angle: 0,
      photoJoined: false,
      planningMessageIsURL: false,
      planningHour: this.$moment().format("HH:mm"),
      daysButtonSwitch: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
      }
    };
  },
  methods: {
    deleteFile() {
      if (this.photo) {
        //document.querySelector(".containerToHide").style.display = "block";
        document.querySelector(".trash").style.display = "none";
        document.querySelector(".rotate-left").style.display = "none";
        document.querySelector(".rotate-right").style.display = "none";
        this.image.src = "";
        this.image = null;
        this.photoJoined = false;
        this.$refs.planningmedia.value = null;
      }
    },
    //   handleFile($event) {
    handleFile() {
      if (this.$refs.planningmedia.files.length == 0) return;
      this.planningMessageIsURL = false;
      let file = this.$refs.planningmedia.files[0];

      let isImage = /^image\//.test(file.type);

      //  //let isVideo = /^video\//.test(file.type);
      //  this.fileSelected.image = $event[0];
      //  let isImage = /^image\//.test(this.fileSelected.image.type);

      //console.log("image size : ", file.size);
      // taille des médias bloquée à 5,1Mo.
      // if (file.size > 5064986) return alert(this.lang === "fr" ? this.pageText.alertImage_tooLarge.fr : this.pageText.alertImage_tooLarge.en);
      if (!isImage) {
        // return alert(this.lang === "fr" ? this.pageText.alertImage.fr : this.pageText.alertImage.en);
        //   this.fileSelected = { image: null, binary: null };
        //   this.deleteFile();
        //   return;
      }
      if (document.querySelector("#media-to-send")) document.querySelector("#media-to-send").remove();
      let img = new Image();
      img.style.width = "100%";
      img.style.border = "1px solid #868b90";
      img.id = "media-to-send";
      this.photo = isImage ? true : false;
      let btn = document.querySelectorAll(".btn-img");
      btn.forEach(function(el) {
        el.style.display = "block";
      });
      this.photoJoined = true;
      // this.showFile(img, file);
      // let img = new Image();
      const fileReader_base64 = new FileReader();
      fileReader_base64.onload = e => {
        img.src = fileReader_base64.result;
        setTimeout(() => {
          this.image = img;
          this.imageSize.width = img.width;
          this.imageSize.height = img.height;
          this.$refs.preview.appendChild(img);
          let { w, h } = this._calculateWidthHeightWithMax(IMAGE_MAX);
          let ctx = this.$refs.canvas.getContext("2d");
          ctx.canvas.width = w;
          ctx.canvas.height = h;
          ctx.drawImage(img, 0, 0, w, h);
          this.outputPlanningObject.pl_messagemedia = this.$refs.canvas.toDataURL();
        }, 500);
      };
      fileReader_base64.readAsDataURL(file);
    },
    rotateFile(event, sens) {
      this.angle = sens === "right" ? this.angle + 90 : this.angle - 90;
      if (this.angle === 360 || this.angle === -360) this.angle = 0;
      let max = this.$refs.previewContainer.offsetWidth;

      let translateY = 0,
        w = 0,
        h = 0;
      let { w: wOri, h: hOri } = this._calculateWidthHeightWithMax(IMAGE_MAX);

      let ctx = this.$refs.canvas.getContext("2d");
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.save();

      if (this.angle === 90 || this.angle === -90 || this.angle === 270 || this.angle === -270) {
        w = Math.ceil(max / (this.imageSize.height / this.imageSize.width));
        h = max;

        if (sens === "right" && this.angle === 270) translateY = w - h;
        if (sens === "left" && this.angle === 90) translateY = w - h;
        if (this.angle === -90) translateY = w - h;
        this.$refs.previewContainer.style.height = w + "px";
        ctx.canvas.width = hOri;
        ctx.canvas.height = wOri;
        ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2);
        ctx.rotate((this.angle * Math.PI) / 180);
        ctx.drawImage(this.image, -ctx.canvas.width / 2, -ctx.canvas.height / 2, wOri, hOri);
      }
      if (this.angle === 0 || this.angle === 180 || this.angle === -180) {
        w = max;
        h = Math.ceil(max / (this.imageSize.width / this.imageSize.height));
        this.$refs.previewContainer.style.height = h + "px";
        ctx.canvas.width = wOri;
        ctx.canvas.height = hOri;
        ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2);
        ctx.rotate((this.angle * Math.PI) / 180);
        ctx.drawImage(this.image, -ctx.canvas.width / 2, -ctx.canvas.height / 2, ctx.canvas.width, ctx.canvas.height);
      }
      if (document.querySelector("#media-to-send")) document.querySelector("#media-to-send").style.width = w + "px";
      if (document.querySelector("#media-to-send")) document.querySelector("#media-to-send").style.height = h + "px";

      this.$refs.preview.style.transform = "translate(0," + translateY + "px) rotate(" + this.angle + "deg)";
      if (!this.planningMessageIsURL) this.outputPlanningObject.pl_messagemedia = this.$refs.canvas.toDataURL();

      ctx.restore();
    },
    _calculateWidthHeightWithMax(max) {
      let w = this.imageSize.width,
        h = this.imageSize.height;
      if (this.imageSize.width > this.imageSize.height) {
        w = max;
        h = Math.ceil(max / (this.imageSize.width / this.imageSize.height));
      } else {
        w = Math.ceil(max / (this.imageSize.height / this.imageSize.width));
        h = IMAGE_MAX;
      }
      return { w, h };
    },
    resetData() {
      this.image = null;
      this.photo = false;
      this.$refs.planningmedia.value = null;
      this.$refs.preview.innerHTML = "";
      this.photoJoined = false;
      if (document.querySelector("#media-to-send")) document.querySelector("#media-to-send").remove();
      this.outputPlanningObject = {
        pl_datedebut: this.$moment(),
        pl_dateend: null,
        pl_message: "",
        pl_cycle: "",
        pl_weekdays: 0,
        pl_id: -1,
        pl_messagemedia: "",
        us_id: this.$store.state.user.us_id,
        en_ids: []
      };
      this.imageSize = { width: 0, height: 0 };
      this.angle = 0;
      this.photoJoined = false;
      this.planningMessageIsURL = false;
      this.planningHour = this.$moment().format("HH:mm");
      this.daysButtonSwitch = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
      };
    },
    getImagePreview(planningImageUrl) {
      this.planningMessageIsURL = true;
      let img = new Image();
      if (document.querySelector("#media-to-send")) document.querySelector("#media-to-send").remove();

      img.style.width = "100%";
      img.style.border = "1px solid #868b90";
      img.id = "media-to-send";
      let btn = document.querySelectorAll(".btn-img");
      btn.forEach(function(el) {
        el.style.display = "block";
      });
      img.src = this.$config.server_url + planningImageUrl;
      img.crossOrigin = "anonymous";
      img.onload = event => {
        // A FINIR
        this.photoJoined = true;
        let width = event.target.width;
        let height = event.target.height;
        this.image = event.target;
        this.imageSize.width = width;
        this.imageSize.height = height;
        this.$refs.preview.appendChild(this.image);
        let { w, h } = this._calculateWidthHeightWithMax(IMAGE_MAX);
        let ctx = this.$refs.canvas.getContext("2d");
        ctx.canvas.width = w;
        ctx.canvas.height = h;
        ctx.drawImage(this.image, 0, 0, w, h);
      };
    },
    setWeekDays(dayIntegerValue, day) {
      if (this.daysButtonSwitch[day]) this.outputPlanningObject.pl_weekdays &= ~dayIntegerValue;
      else this.outputPlanningObject.pl_weekdays |= dayIntegerValue;
      if (this.outputPlanningObject.pl_weekdays & dayIntegerValue) this.daysButtonSwitch[day] = true;
      else this.daysButtonSwitch[day] = false;
    }
  },
  watch: {
    inputPlanningObject: {
      handler(value) {
        if (value && !this.$_.isEmpty(value)) {
          this.$refs.preview.innerHTML = "";
          // fill the form
          if (value.pl_message) this.outputPlanningObject.pl_message = value.pl_message;
          else this.outputPlanningObject.pl_message = "";
          if (value.pl_messagemedia) this.outputPlanningObject.pl_messagemedia = value.pl_messagemedia;
          else this.outputPlanningObject.pl_messagemedia = "";
          if (value.pl_cycle) this.outputPlanningObject.pl_cycle = value.pl_cycle;
          else this.outputPlanningObject.pl_cycle = "";
          if (value.pl_cyclevalue) this.outputPlanningObject.pl_cyclevalue = value.pl_cyclevalue;
          else this.outputPlanningObject.pl_cyclevalue = "";
          if (value.pl_datedebut) {
            this.outputPlanningObject.pl_datedebut = value.pl_datedebut;
            this.planningHour = this.$moment(value.pl_datedebut).format("HH:mm");
          } else this.outputPlanningObject.pl_datedebut = "";
          if (value.pl_dateend) this.outputPlanningObject.pl_dateend = value.pl_dateend;
          else this.outputPlanningObject.pl_dateend = "";
          if (value.en_ids) this.outputPlanningObject.en_ids = value.en_ids;
          else this.outputPlanningObject.en_ids = [];
          if (value.pl_cycle === "weeks" && value.pl_weekdays) this.outputPlanningObject.pl_weekdays = value.pl_weekdays;
          else this.outputPlanningObject.pl_weekdays = 0;
          if (value.pl_id) this.outputPlanningObject.pl_id = value.pl_id;
          else this.outputPlanningObject.pl_id = -1;

          // display image if url
          if (this.outputPlanningObject.pl_messagemedia.indexOf("/public/") >= 0) {
            this.planningMessageIsURL = true;

            this.getImagePreview(this.outputPlanningObject.pl_messagemedia);
          }
          // set week days button switch
          for (let index = 0; index < 7; index++) {
            if (this.outputPlanningObject.pl_weekdays & Math.pow(2, index)) this.daysButtonSwitch[Object.keys(this.daysButtonSwitch)[index]] = true;
            else this.daysButtonSwitch[Object.keys(this.daysButtonSwitch)[index]] = false;
          }
        }
      },
      deep: true
    },
    outputPlanningObject: {
      handler(value, oldValue) {
        if (value) {
          this.$emit("saveplanning", value);
        }
      },
      deep: true
    },
    "outputPlanningObject.pl_cyclevalue"(value) {
      if (value < 1) this.outputPlanningObject.pl_cyclevalue = 1;
      else if (value > 30 && this.outputPlanningObject.pl_cycle === "days") this.outputPlanningObject.pl_cyclevalue = 30;
      else if (value > 4 && this.outputPlanningObject.pl_cycle === "weeks") this.outputPlanningObject.pl_cyclevalue = 4;
    },
    planningHour(value) {
      if (value) {
        this.outputPlanningObject.pl_datedebut = this.$moment(this.outputPlanningObject.pl_datedebut)
          .set("hour", value.split(":")[0])
          .set("minute", value.split(":")[1])
          .format("YYY-MM-DD HH:mm:ss");
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
div.align-self-center:hover {
  background-color: lightgray;
}

.col-md-1 {
  border: 1px solid grey;
  padding: 2px;
  margin: 4px;
  text-align: center;
}
.col-md-1:hover {
  background-color: lightgray;
}
#media-to-send {
  transform-origin: left;
}
.btn-img {
  padding: 6px;
  display: none;
  position: absolute;
  bottom: 0px;
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
  border: 1px solid #b7b5b5;
}
.rotate-left {
  right: 100px;
}
.rotate-right {
  right: 60px;
}
.trash {
  right: 15px;
}
.btn-rotate {
  background-color: #ffffff8f;
}
.fileContainer {
  height: 176px;
  background-color: #ffffff;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 0;
  align-items: flex-end;
  border: 1px solid #ced4da;
  background-image: url("../../public/images/addphoto.png");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}
</style>
