<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div>
              <h1 v-if="ma_id">Modification matériel</h1>
              <h1 v-else>Création matériel</h1>
            </div>
            <div class="d-flex flex-row align-items-center active">
              <m-form-checkbox label="Actif" :name="$Utils.randomstring('ma_active')" v-model="row_ma.ma_active"></m-form-checkbox>
            </div>
          </div>
          <div class="modal-body">
            <div>
              <h6><span class="number-title">1</span>Nom, type et image</h6>
              <div class="row d-flex flex-row">
                <div class="col-md-9">
                  <m-form-text label="Nom" v-model="row_ma.ma_name" label-position="top" name="name" autocomplete></m-form-text>
                </div>
                <!--  <div class="col-md-4">
                  <m-form-select
                    class="ml-2 w-100"
                    label="Type"
                    label-position="top"
                    :name="$Utils.randomstring('type')"
                    v-model="row_ma.ma_type"
                  ></m-form-select>
                </div> -->
              </div>
              <div class="row mt-3">
                <div class="col-md-4">
                  <img class="img-fluid" :src="fileSelected.binary ? fileSelected.binary : getSrcImage()" alt="" />
                </div>
                <div class="col-md-5">
                  <m-form-file label="Sélectionnez une image" ref="inputImageMaterial" v-model="fileSelected" @inputfile="fileJusteSelected">
                  </m-form-file>
                  <m-form-checkbox
                    class="mt-3"
                    label="Supprimer l'image"
                    :name="$Utils.randomstring('deleteImage')"
                    v-model="deleteImage"
                  ></m-form-checkbox>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <h6><span class="number-title">2</span> Stock mini</h6>
              <div class="row">
                <div class="col-md-6">
                  <m-form-text label="Stock mini" v-model="row_ma.ma_stock_mini" label-position="top" name="inventaire"></m-form-text>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <div class="d-flex flex-row justify-content-between align-items-center">
                <h6><span class="number-title">3</span> Stock et historique</h6>
                <span v-if="errorQtite" style="color: red">Veuillez renseigner une quantité.</span>
                <m-form-checkbox label="Afficher livraisons" :name="$Utils.randomstring('show_liv')" v-model="show_liv"></m-form-checkbox>
              </div>

              <m-list-simple
                ref="contactslist"
                :dynamic="true"
                :items="rows_stl"
                item-value="st_id"
                :item-height="20"
                :limit="rowsstl_limit"
                :skip="rowsstl_skip"
                :total="rowsstl_total"
              >
                <template v-slot:ths="{}">
                  <th width="80px">
                    <m-form-text label="Qté" v-model="newStockLine.st_quantity" label-position="top" name="qte"></m-form-text>
                  </th>
                  <th>
                    <m-form-select
                      :width="130"
                      label="Type"
                      label-position="top"
                      :name="$Utils.randomstring('st_type')"
                      v-model="newStockLine.st_type"
                      :items="newStockLineType"
                    ></m-form-select>
                  </th>
                  <th>
                    <m-form-text
                      label="Nom du fournisseur"
                      v-model="newStockLine.st_provider_name"
                      label-position="top"
                      name="provider"
                    ></m-form-text>
                  </th>
                  <th>
                    <m-form-date
                      label="Date liv. estimée"
                      label-position="top"
                      :name="$Utils.randomstring('st_date_estim_received')"
                      formatInput="DD/MM/YYYY"
                      v-model="newStockLine.st_date_estim_received"
                    ></m-form-date>
                  </th>
                  <th>
                    <m-form-text label="Lien" v-model="newStockLine.ma_link_provider" label-position="top" name="ma_link_provider"></m-form-text>
                  </th>
                  <th width="100px">
                    <m-form-text label="Prix HT/pce" v-model="newStockLine.st_price" label-position="top" name="price"></m-form-text>
                  </th>
                  <th>
                    <button type="button" class="btn btn-primary" @click="addStockLine">Ajouter</button>
                  </th>
                  <th></th>
                </template>
                <template v-slot:tds="{ item }">
                  <td>
                    {{ item.st_quantity }}
                  </td>
                  <td>{{ findItem(item.st_type) }}</td>
                  <td>
                    {{ item.st_provider_name }}
                  </td>
                  <td>
                    {{
                      item.st_date_estim_received && item.st_date_estim_received !== "0000-00-00 00:00:00"
                        ? $moment(item.st_date_estim_received).format("DD/MM/YYYY")
                        : ""
                    }}
                  </td>
                  <td>
                    {{ item.st_provider_link }}
                  </td>

                  <td>{{ item.st_price ? item.st_price : "" }}</td>
                  <td>
                    {{ $moment(item.createdAt).format("DD/MM/YYYY HH[H]mm") }}
                  </td>
                  <td width="40" @click="deleteConfirmStockLine(item)">
                    <icon name="trash-alt"></icon>
                  </td>
                </template>
              </m-list-simple>
            </div>
            <hr />
            <div class="mb-5">
              <h6><span class="number-title">4</span> Présent dans les produits suivants</h6>
              <div class="ml-4" v-if="row_ma.productsmaterials && row_ma.productsmaterials.length">
                <ul>
                  <li class="pointer" @click="goProduct(product)" v-for="prma in row_ma.productsmaterials" :key="prma.prma_id">
                    {{ prma.pr_id.pr_name }}
                  </li>
                </ul>
              </div>
              <div v-else>Vous pouvez ajouter ce matériel à un produit à partir de l'édition d'un produit.</div>
              <!--<div v-else>
                <div class="row d-flex flex-row align-items-end">
                  <div class="col-md-10">
                    <m-form-combobox
                      label="Produits"
                      label-position="top"
                      :name="$Utils.randomstring('filter_product')"
                      v-model="inProduct"
                      :store-url="$config.server_url + '/extranet/2.0/products'"
                      :store-params="{}"
                      item-value="pr_id"
                      item-text="pr_name"
                    ></m-form-combobox>
                  </div>
                  <div class="col-md-2"><button class="btn btn-primary" @click="addInProduct">Ajouter</button></div>
                </div>

                <ul class="mt-3 ml-4">
                  <li class="pointer" v-for="product in products" :key="product.pr_id">
                    {{ product.pr_name }}
                    <span @click="deleteinProduct(product)">
                      <icon name="trash-alt"></icon>
                    </span>
                  </li>
                </ul>
              </div> -->
            </div>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between">
            <button type="button" class="btn btn-danger" @click="deleteConfirmWin">Supprimer</button>
            <div>
              <button type="button" class="btn btn-secondary" @click="cancelWin">Annuler</button>
              <button type="button" class="btn btn-primary ml-2" @click="saveWin(false)">Enregistrer</button>
            </div>
          </div>
        </div>
      </div>
      <m-message-dialog v-model="dialogErr" title="Erreur" :text="dialogErrTxt"></m-message-dialog>
      <m-confirm-dialog
        v-model="confirmdelete"
        text="Ce matériel sera mis en non-actif."
        title="Confirmation"
        width="500px"
        @canceled="confirmdelete = false"
        @confirmed="deleteWin()"
      ></m-confirm-dialog>
      <m-confirm-dialog
        v-model="confirmDeleteStockLine"
        text="Souhaitez-vous effacer cette ligne ?"
        title="Confirmation"
        width="500px"
        @canceled="confirmDeleteStockLine = false"
        @confirmed="deleteStockLine()"
      ></m-confirm-dialog>
    </div>
  </transition>
</template>

<script>
export default {
  name: "MaterialWinEdit",
  components: {},
  props: {
    ma_id: Number,
    value: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      errorQtite: false,
      row_ma: {},
      rows_stl: [],
      confirmdelete: false,
      confirmDeleteStockLine: false,
      stockLineToDelete: {},
      newStockLine: {
        st_type: "",
        st_date: "0000-00-00",
        st_date_estim_received: "0000-00-00",
        st_provider_name: "",
        st_provider_link: "",
        st_quantity: "",
        st_price: ""
      },
      newStockLineType: [
        { text: "Inventaire", value: "INVENTORY" },
        { text: "Commande fournisseur", value: "ORDER" },
        { text: "Reception", value: "RECEIVED" },
        { text: "Envoi", value: "SALE" }
      ],
      show_liv: false,
      rowsstl_limit: 0,
      rowsstl_total: 0,
      rowsstl_skip: 0,
      dialogErrTxt: "",
      dialogErr: false,
      // present dans les produits
      inProduct: {},
      products: [],
      fileSelected: { image: null, binary: null },
      deleteImage: false
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.material_id = this.ma_id ? this.ma_id : -1;
        this.loadMaterials();
        if (this.material_id > 0) this.loadStockLine();
      }
    }
  },
  computed: {},

  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  created() {},
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    findItem(val) {
      let f = this.$_.find(this.newStockLineType, { value: val });
      if (!f) return;
      return f.text;
    },
    resetNewStockLine() {
      this.newStockLine = {
        st_type: "",
        st_date: "0000-00-00",
        st_date_estim_received: "0000-00-00",
        st_provider_name: "",
        st_provider_link: "",
        st_quantity: "",
        st_price: ""
      };
    },
    async loadStockLine() {
      let params = {
        ma_id: this.ma_id,
        sort: "createdAt desc"
      };
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/stocklines", { params });
      if (!response || !response.data) return;
      this.rows_stl = response.data.data;
    },
    async loadMaterials() {
      let params = {};
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/materials/" + this.material_id, { params });
      if (!response || !response.data) return;
      let row_ma = response.data.data;
      if (row_ma.products) this.products = row_ma.products;
      this.row_ma = row_ma;
    },
    async addStockLine() {
      if (!this.newStockLine.st_quantity) {
        this.errorQtite = true;
        return;
      }
      this.newStockLine.ma_id = this.ma_id;
      // this.newStockLine.st_date = this.$moment();
      let response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/stocklines", this.newStockLine);
      if (!response || !response.data) return;
      this.loadStockLine();
      this.resetNewStockLine();
      this.errorQtite = false;
    },
    goProduct(product) {
      this.$router.push("/products/" + product.pr_id);
    },
    fileJusteSelected($event) {
      this.fileSelected.image = $event[0];
      var reader = new FileReader();
      var me = this;
      reader.onload = (function (theFile) {
        return function (e) {
          me.fileSelected.binary = e.target.result;
        };
      })($event[0]);
      reader.readAsDataURL($event[0]);
    },
    getSrcImage() {
      return (
        this.$config.server_url +
        "/extranet/2.0/materials/image/" +
        this.ma_id +
        "/250/150?d=" +
        new Date().getTime() +
        "&token=" +
        this.$store.state.accesstoken
      );
    },
    async saveWin() {
      let err = [];
      let fieldRequired = [{ field: "ma_name", text: "nom" }];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.row_ma[field.field]) err.push(field);
      }
      if (err.length) {
        this.dialogErrTxt = "<span class='bis'>Vous devez remplir le ou les champs : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error.text + " <br>";
        }
        this.dialogErr = true;
      } else {
        let response;
        this.row_ma.products = this.products;
        // account for new stocklines updates
        delete this.row_ma.ma_stock_available;
        delete this.row_ma.ma_stock_ordered;
        if (this.row_ma.ma_id) {
          this.row_ma.deleteImage = this.deleteImage;
          response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/materials/" + this.row_ma.ma_id, this.row_ma);
        } else {
          this.row_ma.ma_active = true;
          response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/materials", this.row_ma);
        }
        if (response.data.err) {
          this.$store.dispatch("showDialogError", response.data.err.message);
          return;
        }
        let row_ma = response.data.data;
        this.resetNewStockLine();
        this.rows_stl = [];
        this.$emit("materialSaved");
        this.$emit("input", false);
        if (this.fileSelected.image) {
          await this.saveImage(this.fileSelected.image, row_ma.ma_id);
        }
        this.$router.push("/materials");
        this.fileSelected = { image: null, binary: null };
      }
    },
    async saveImage(file, ma_id) {
      if (!file) return;
      let formData = new FormData();
      formData.append("ma_id", ma_id);
      formData.append("image", file, file.name);
      await this.$axios.post(this.$config.server_url + "/extranet/2.0/materials/image", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    },
    cancelWin() {
      this.resetNewStockLine();
      this.rows_stl = [];
      this.$router.push("/materials");
      this.$emit("materialSaved");
      this.$emit("input", false);
    },
    deleteConfirmStockLine(item) {
      this.confirmDeleteStockLine = true;
      this.stockLineToDelete = item;
    },
    async deleteStockLine() {
      let params = {
        ma_id: this.row_ma.ma_id
      };
      await this.$axios.delete(this.$config.server_url + "/extranet/2.0/stocklines/" + this.stockLineToDelete.st_id, { params });
      this.confirmDeleteStockLine = false;
      this.stockLineToDelete = {};
      this.loadStockLine();
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteWin() {
      this.resetNewStockLine();
      this.rows_stl = [];
      await this.$axios.delete(this.$config.server_url + "/extranet/2.0/materials/" + this.ma_id);
      this.confirmdelete = false;
      this.$router.push("/materials");
      this.$emit("materialSaved");
      this.$emit("input", false);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";
td {
  padding-left: 10px;
}
</style>
