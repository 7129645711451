<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div>
              <h1>Création d'une facture</h1>
            </div>

            <!--  <div class="d-flex flex-row align-items-center active">
              <label class="label-checkbox" for="active">Activer</label>
              <input type="checkbox" id="actif" name="actif" v-model="row_co.or_active" />
            </div> -->
          </div>
          <div class="modal-body">
            <div class="row">
              <h6><span class="number-title">1</span> Le contact</h6>
            </div>
            <div class="row">
              <div class="col-md-12">
                <m-form-combobox
                  class="mt-0"
                  label="Le contact"
                  label-position="top"
                  :name="$Utils.randomstring('contactSelected')"
                  v-model="contactSelected"
                  :store-url="$config.server_url + '/extranet/2.0/contacts'"
                  :store-params="{}"
                  item-value="co_id"
                  :item-text="showContactName"
                ></m-form-combobox>
              </div>
            </div>
            <div class="row mt-3">
              <h6><span class="number-title">2</span>Choisir l'offre</h6>
            </div>
            <div class="row">
              <div class="col-md-6 d-flex flex-row" style="margin-top:-15px;">
                <m-form-combobox
                  class="flex-grow-1 basis-50"
                  label="L'offre"
                  label-position="top"
                  :name="$Utils.randomstring('offerSelected')"
                  v-model="offerSelected"
                  :store-url="$config.server_url + '/extranet/2.0/offers'"
                  :store-params="{}"
                  item-value="of_id"
                  item-text="of_name"
                  @input="setOfferPriceht"
                ></m-form-combobox>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 d-flex flex-row" style="margin-top:-15px;">
                <m-form-combobox
                  class="flex-grow-1  basis-50"
                  label="L'utilisateur final"
                  label-position="top"
                  :name="$Utils.randomstring('enduserSelected')"
                  v-model="enduserSelected"
                  :store-url="$config.server_url + '/extranet/2.0/endusers'"
                  :store-params="{ co_id: contactSelected ? contactSelected.co_id : -1 }"
                  item-value="en_id"
                  item-text="en_fullname"
                  :disabled="!contactSelected || !contactSelected.co_id"
                ></m-form-combobox>
                <m-form-select
                  class="flex-grow-1  ml-1 basis-50"
                  label="Méthode de paiement"
                  label-position="top"
                  :name="$Utils.randomstring('orderPayementMethod')"
                  v-model="row_or.or_paymentmethod"
                  :items="orderPaymentMethods"
                  :disabled="!contactSelected || !contactSelected.co_id"
                ></m-form-select>
              </div>
              <div class="col-md-6 d-flex flex-row" style="margin-top:-15px;">
                <m-form-text
                  class=""
                  label="Quantité"
                  type="number"
                  v-model="newOrderline.ol_qte"
                  :name="$Utils.randomstring('ol_qte')"
                  :rules="[$Validation.mandatory]"
                  @input="calculPriceTotalHt"
                ></m-form-text>
                <m-form-text
                  class="ml-4"
                  label="TVA %"
                  type="number"
                  v-model="newOrderline.tva_percent"
                  :name="$Utils.randomstring('tva_percent')"
                  :rules="[$Validation.mandatory]"
                ></m-form-text>
                <m-form-text
                  class="ml-4"
                  label="Prix unit. TTC"
                  type="number"
                  v-model="newOrderline.ol_puttc"
                  :name="$Utils.randomstring('ol_puttc')"
                  :rules="[$Validation.mandatory]"
                  @input="calculPriceTotalHt"
                ></m-form-text>
                <m-form-text
                  class="ml-4"
                  label="Prix total TTC"
                  type="number"
                  v-model="newOrderline.ol_totalttc"
                  :name="$Utils.randomstring('ol_totalttc')"
                  :rules="[$Validation.mandatory]"
                  disabled
                ></m-form-text>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 d-flex flex-row justify-content-end small-text">Prix en centimes d'euro *</div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <button class="btn btn-primary btn-sm" @click="showOffer = !showOffer">Afficher l'offre</button>
                <button class="btn btn-warning btn-sm ml-2" @click="validLine(false)">
                  {{ this.newOrderline.ol_id ? "Modifier la ligne" : "Ajouter la ligne" }}
                </button>
              </div>
            </div>

            <div class="row mt-3" v-if="showOffer && offerSelected.of_id">
              <div class="col-md-12">
                <div>Description de l'offre : {{ offerSelected.of_description }}</div>
                <div>Engagement : {{ offerSelected.of_commit_period }}</div>
                <div>Paiement de départ : {{ offerSelected.of_deposit }}</div>
              </div>
            </div>

            <div class="row mt-3">
              <h6><span class="number-title">3</span>Votre commande</h6>
            </div>
            <div class="row">
              <div class="col-md-12">
                <m-list-simple :dynamic="true" :items="orderLines" item-value="ol_id" :item-height="30" :limit="limitOrderLines">
                  <template v-slot:ths="{}">
                    <th style="min-width: 50px">
                      <span class="pointer">Offre</span>
                    </th>
                    <th class="">
                      <span class="pointer">Produit</span>
                    </th>
                    <th class="">
                      <span class="pointer">Utilisateur final</span>
                    </th>
                    <th>
                      <span class="pointer">Quantité</span>
                    </th>
                    <th>
                      <span class="pointer">PU TTC</span>
                    </th>
                    <th class="">
                      <span class="pointer">Tva</span>
                    </th>
                    <th class="">
                      <span class="pointer">Total TTC</span>
                    </th>
                    <th style="min-width: 30px">Éditer</th>
                  </template>
                  <template v-slot:tds="{ item }">
                    <td>
                      {{ item.ol_offer_object ? item.ol_offer_object.of_name : "" }}
                    </td>
                    <td v-if="item.ol_offer_object">
                      <div v-for="pr in item.ol_offer_object.offersproducts" :key="pr.ofpr_id">{{ pr.pr_id.pr_name }},</div>
                    </td>
                    <td v-else></td>
                    <td class="">
                      {{ item.en_id && item.en_id.en_id ? item.en_id.en_fullname : "" }}
                    </td>
                    <td>
                      {{ item.ol_qte }}
                    </td>
                    <td>
                      {{ item.ol_puttc / 100 }}
                    </td>
                    <td class="">{{ item.ol_tva / 100 }} %</td>
                    <td class="">
                      {{ item.ol_totalttc / 100 }}
                    </td>
                    <td class="">
                      <div class="d-flex flex-row">
                        <div @click="editOrderLine(item)"><icon name="edit"></icon></div>
                        <div class="ml-2" @click="deleteOrderLine(item)"><icon name="trash-alt"></icon></div>
                      </div>
                    </td>
                  </template>
                </m-list-simple>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="totaux-line bg-green">
                  Total HT : <span style="float: right">{{ row_or.or_priceht / 100 }} €</span>
                </div>
                <div class="totaux-line bg-green" v-for="(item, index) in row_or.or_tvas" :key="index">
                  TVA : {{ item.tva / 100 }} % <span style="float: right">{{ item.total / 100 }} €</span>
                </div>
                <div class="totaux-line">
                  Total TTC :
                  <span style="float: right">{{ row_or.or_pricettc / 100 }} €</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between mt-5">
            <div></div>
            <!--  <button type="button" class="btn btn-danger" @click="deleteConfirmWin">
              Supprimer
            </button> -->
            <div class="d-flex flex-row">
              <button type="button" class="btn btn-secondary" @click="cancelWin">Annuler</button>
              <button type="button" class="btn btn-primary btn-mouveal ml-2" @click="saveWin(false)" :disabled="!orderLines.length">Valider</button>
            </div>
          </div>
        </div>
      </div>
      <!--   <m-confirm-dialog
        v-model="confirmdelete"
        text="Souhaitez-vous supprimer cette facture ?"
        title="Confirmation"
        width="500px"
        @canceled="confirmdelete = false"
        @confirmed="deleteWin()"
      ></m-confirm-dialog> -->
      <m-message-dialog v-model="dialogErr" title="Erreur" :text="dialogErrTxt"></m-message-dialog>
    </div>
  </transition>
</template>

<script>
export default {
  name: "invoiceWinEdit",
  components: {},
  props: {
    or_id: Number,
    value: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      row_or: { or_priceht: 0, or_pricettc: 0, or_tvas: [], or_paymentmethod: "CB" },
      order_id: -1,
      //confirmdelete: false,
      offerSelected: {},
      newOrderline: {
        en_id: 0,
        of_id: 0,
        ol_qte: 1,
        ol_totalht: 0,
        ol_puht: 0,
        ol_puttc: 0,
        ol_totalttc: 0,
        ol_tva: 2000,
        tva_percent: 20,
        ol_id: 0
      },
      editline: false,
      limitOrderLines: 1000,
      dialogNewLigneOrder: false,
      showOffer: false,
      dialogErrTxt: "",
      dialogErr: false,
      orderLines: [],
      enduserSelected: {},
      contactSelected: {},
      cmptOlId: -1,
      orderPaymentMethods: [
        { text: "CB", value: "CB" },
        { text: "SEPA", value: "SEPA" }
      ]
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.order_id = this.or_id ? this.or_id : -1;
        this.load();
      }
    }
  },
  computed: {},

  mounted() {
    this.user = this.$store.state.user;
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  created() {},
  methods: {
    // fermer la fen^tre avec la touche échape
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    //sert à afficher les nom et prénom des contact dans le combobox
    showContactName(val) {
      let txt = "";
      if (val.co_invoice_name) txt += val.co_invoice_name.toUpperCase();
      if (val.co_invoice_firstname) txt += " " + val.co_invoice_firstname.charAt(0).toUpperCase() + val.co_invoice_firstname.substr(1);
      return txt;
    },

    async load() {
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/orders/" + this.order_id);
      if (!response || !response.data) {
        this.row_or.or_id = this.or_id;
        return;
      }
      this.row_or = response.data.data;
      this.row_or.or_id = this.order_id;
      this.orderLines = this.row_or.orderlines;
      this.contactSelected = this.row_or.co_id ? this.row_or.co_id : {};
      //if (!this.contactSelected) this.contactSelected = {};
      for (let i = 0; i < this.orderLines.length; i++) {
        let row_ol = this.orderLines[i];
        row_ol.ol_totalttc = 0;
        if (row_ol.ol_qte && row_ol.ol_puttc) row_ol.ol_totalttc = row_ol.ol_qte * row_ol.ol_puttc;
        // add tva to display
        row_ol.tva_percent = row_ol.ol_tva / 100;
        // console.log("", row_ol.ol_qte, row_ol.ol_puht);
      }
      this.calculTotaux();
    },
    // le prix unitaire ht d'une ligne d'offre, est calculé en additionnant les ofpr_initial_price_ht et ofpr_subscription_price_ht de chaque ofpr de l'offre
    async setOfferPriceht(offer) {
      if (this.editline) {
        this.editline = false;
        return;
      }
      let price = 0;
      if (!offer.of_id) return;
      for (let iof = 0; iof < offer.offersproducts.length; iof++) {
        const ofpr = offer.offersproducts[iof];
        price += ofpr.ofpr_subscription_price_ht;
        price += ofpr.ofpr_initial_price_ht;
      }
      /** we have switched ttc and ht value in offerproducts , saved values in price_ht are in fact ttc values, TODO change field name in offerproducts model but check first if it will affect functionality */
      this.newOrderline.ol_puttc = price;
      this.calculPriceTotalHt();
    },
    // le prix total ht d'une ligne d'offre c'est le prix de l'offre * sa quantité
    async calculPriceTotalHt() {
      if (!this.newOrderline.ol_qte || !this.newOrderline.ol_puttc) return;
      this.newOrderline.ol_totalttc = this.newOrderline.ol_qte * this.newOrderline.ol_puttc;
    },

    // reset les champs d'une ligne d'offre
    resetnewOrderline() {
      this.offerSelected = {};
      this.enduserSelected = {};
      this.newOrderline = {
        en_id: 0,
        of_id: 0,
        ol_qte: 1,
        ol_totalht: 0,
        ol_totalttc: 0,
        ol_puht: 0,
        ol_puttc: 0,
        ol_tva: 2000,
        tva_percent: 20,
        ol_id: 0
      };
    },
    resetOrderLines() {
      this.orderLines = [];
      this.row_or = { or_priceht: 0, or_pricettc: 0, or_paymentmethod: "CB" };
    },

    // calcul les totaux : total ht, total ttc et les différentes tva
    async calculTotaux() {
      let tvas = [];
      let totalht = 0;
      let totalttc = 0;
      for (let iof = 0; iof < this.orderLines.length; iof++) {
        const orderline = this.orderLines[iof];

        if (!orderline.ol_puht) this.orderLines[iof].ol_puht = orderline.ol_puttc - orderline.ol_puttc * (orderline.ol_tva / 10000);
        let tvaAmount = orderline.ol_totalttc * (orderline.ol_tva / 10000);
        tvaAmount = tvaAmount;
        totalttc += orderline.ol_totalttc;
        totalht += orderline.ol_totalttc - tvaAmount;

        let f = this.$_.find(tvas, { tva: orderline.ol_tva });
        if (!f) {
          tvas.push({ tva: orderline.ol_tva, total: tvaAmount });
        } else {
          f.total += tvaAmount;
        }
      }

      this.row_or.or_tvas = tvas;
      this.row_or.or_priceht = totalht;
      this.row_or.or_pricettc = totalttc;
    },

    // ajoute une ligne d'offre , verifie les champs offre, quantité et tva
    validLine() {
      if (!this.offerSelected.of_id) {
        this.dialogErrTxt = "<span class='bis'>Vous devez choisir une offre</span>";
        this.dialogErr = true;
        return;
      }
      let err = [];
      let fieldRequired = [
        { field: "ol_qte", text: "quantité" },
        { field: "ol_tva", text: "tva" }
      ];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.newOrderline[field.field]) err.push(field);
      }
      if (err.length) {
        this.dialogErrTxt = "<span class='bis'>Vous devez remplir les champs : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error.text + " <br>";
        }
        this.dialogErr = true;
      } else {
        this.newOrderline.of_id = this.offerSelected.of_id;
        this.newOrderline.ol_offer_object = this.offerSelected;
        if (this.enduserSelected) this.newOrderline.en_id = this.enduserSelected;
        this.newOrderline.ol_tva = this.newOrderline.tva_percent * 100;
        delete this.newOrderline.tva_percent;
        if (this.newOrderline.ol_id === 0) {
          this.newOrderline.ol_id = this.cmptOlId--;
          this.orderLines.push(this.newOrderline);
        } else {
          let indexOfLineToEdit = this.$_.findIndex(this.orderLines, { ol_id: this.newOrderline.ol_id });
          if (indexOfLineToEdit < 0) return;
          this.orderLines[indexOfLineToEdit] = this.newOrderline;
        }

        //limitOrderLines est utilisé comme une keyLoad pour recharger la vu du tableau
        this.limitOrderLines++;
        this.calculTotaux();
        this.resetnewOrderline();
      }
    },
    editOrderLine(item) {
      this.newOrderline = this.$_.clone(item);
      this.editline = true;
      this.offerSelected = item.ol_offer_object;
      if (item.en_id && item.en_id.en_id) this.enduserSelected = item.en_id;
    },
    deleteOrderLine(item) {
      let indexOfLineToEdit = this.$_.findIndex(this.orderLines, { ol_id: item.ol_id });

      if (indexOfLineToEdit < 0) return;
      this.orderLines.splice(indexOfLineToEdit, 1);
    },
    async saveWin() {
      if (!this.contactSelected.co_id) {
        this.dialogErrTxt = "<span class='bis'>Vous devez choisir un contact</span>";
        this.dialogErr = true;
        return;
      }

      let response;
      // reset en_id to proper id for each orderline
      for (const orderline of this.orderLines) {
        if (orderline.en_id && orderline.en_id.en_id) {
          const tempId = orderline.en_id.en_id;
          orderline.en_id = tempId;
        }
      }
      this.row_or.orderlines = this.orderLines;
      this.row_or.co_id = this.contactSelected.co_id;
      if (this.row_or.or_id > 0) {
        response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/orders/" + this.row_or.or_id, this.row_or);
      } else {
        response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/orders", { ...this.row_or, or_id: -1 });
      }

      if (response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }
      this.resetnewOrderline();
      this.resetOrderLines();
      this.$emit("input", false);
      this.$emit("reload");
      this.$router.push("/invoices");
    },
    cancelWin() {
      this.resetnewOrderline();
      this.resetOrderLines();
      this.$emit("input", false);
      this.$router.push("/invoices");
    },
    addOrderLine() {
      this.resetnewOrderline();
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";
.establishment {
  background-color: #daece7;
  padding: 5px 10px;
  margin-right: 10px;
}
.totaux-line {
  background-color: $emotivi-blue;
  padding: 5px 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  color: #fff;
}
.bg-green {
  background-color: $emotivi-green;
}
.basis-50 {
  flex-basis: 50%;
}
</style>
