<template>
  <div class="" id="home">
    <div v-if="$store.getters.checkUserAccess($router, '/statistiques')" class="d-flex flex-row align-items-center flex-wrap">
      <h1 class="mr-5 bis">Statistiques</h1>
      <div class="d-flex flex-row filtre-statistique">
        <div style="width: 280px">
          <m-form-select
            class="mx-1"
            v-model="periodSelected"
            :name="$Utils.randomstring('periodSelected')"
            :items="$store.state.items_period"
            @input="setFilterDate"
          ></m-form-select>
        </div>
        <m-form-date
          class="mx-1 ml-5"
          label="Début"
          :name="$Utils.randomstring('filterDateStart')"
          formatInput="DD/MM/YYYY"
          v-model="filterDateStart"
          :inline="true"
        ></m-form-date>
        <m-form-date
          class="mx-1"
          label="Fin"
          :name="$Utils.randomstring('filterDateEnd')"
          formatInput="DD/MM/YYYY"
          v-model="filterDateEnd"
          :inline="true"
        ></m-form-date>
      </div>
    </div>

    <div class="d-flex flex-row row-stats">
      <div class="mr-2 card-stat mb-4" v-for="(stat, index) in stats" :key="index">
        <div class="container-chiffre h-100 d-flex flex-row">
          <div class="col-md-8 mt-3">
            <div class="mb-2">
              <div class="uppercase bis">{{ stat.text }}</div>
              <div class="uppercase small-text">
                {{ stat.sousText ? stat.sousText : "-" }}
              </div>
            </div>
            <p class="chiffre bis pl-3">
              {{ stat.chiffreN }}
            </p>
          </div>
          <div class="col-md-4 d-flex flex-column justify-content-between">
            <div class="delta" :class="'delta-' + stat.variation">
              <div>{{ stat.delta }}</div>
            </div>
            <div class="uppercase text-center" :class="'variation-' + stat.variation">
              {{ stat.variation }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$store.getters.checkUserAccess($router, '/materials')" class="d-flex flex-row">
      <icon width="22" height="22" name="exclamation-triangle" class="mr-2" color="red"></icon>
      <div class="d-flex flex-row flex-wrap">
        <span class="bis" v-if="alertStock"> Alerte stock :</span>
        <div @click="alertGo(alert)" v-for="alert in alertStock" :key="alert.ma_id" class="color-danger ml-2 pointer">
          {{ alert.ma_name }} ({{ alert.ma_stock_available }}),
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div v-if="$store.getters.checkUserAccess($router, '/parcels/:en_id')" class="col-md-4">
        <div class="card">
          <h6><span class="number-title">A</span> {{ parcels.length }} nouvelles commandes à traiter</h6>
          <m-list-simple
            ref="contactslistParcels"
            :dynamic="true"
            :items="parcels"
            item-value="pa_id"
            :item-height="20"
            :limit="pa_limit"
            :total="pa_total"
            @itemclick="parcelClick"
          >
            <template v-slot:ths="{}">
              <th>Date</th>
              <th>Contact</th>
            </template>
            <template v-slot:tds="{ item }">
              <td>{{ $moment(item.createdAt).format("DD/MM/YYYY HH[H]mm") }}</td>
              <td>{{ item | formatContactNameSimple("co_invoice_") }}</td>
            </template>
          </m-list-simple>
          <!-- <div class="d-flex flex-row justify-content-end">
            <button @click="pa_limit = pa_limit === CONST_PA_LIMIT ? parcels.length : CONST_PA_LIMIT" class="btn btn-primary btn-voirplus">
              {{ pa_limit === CONST_PA_LIMIT ? "Voir plus" : "Voir moins" }}
            </button>
          </div> -->
        </div>
      </div>

      <div :class="$store.getters.checkUserAccess($router, '/parcels/:en_id') ? 'col-md-5' : 'col-md-7'">
        <div class="card">
          <h6>
            <span class="number-title">{{ $store.getters.checkUserAccess($router, "/parcels/:en_id") ? "B" : "A" }}</span> Actions à traiter
          </h6>
          <div class="d-flex align-items-end">
            <div class="flex-fill" style="margin-top: -20px">
              <m-form-text
                class=""
                type="text"
                :rows="1"
                label="Ajouter une action sur un utilisateur final"
                placeholder="Commentaire"
                label-position="top"
                v-model="commentAction"
                name="commentAction"
              ></m-form-text>
              <div class="d-flex align-items-end" style="margin-top: -10px">
                <m-form-combobox
                  class="flex-grow-1"
                  placeholder="Contact"
                  label-position="top"
                  :name="$Utils.randomstring('commentContact')"
                  v-model="commentContact"
                  :store-url="$config.server_url + '/extranet/2.0/contacts'"
                  :store-params="{}"
                  item-value="co_id"
                  :item-text="showContactName"
                ></m-form-combobox>
                <div class="flex-grow-0">OU</div>
                <m-form-combobox
                  class="flex-grow-1"
                  placeholder="Utilisateur final"
                  :name="$Utils.randomstring('commentEnduser')"
                  v-model="commentEnduser"
                  :store-url="$config.server_url + '/extranet/2.0/endusers'"
                  :store-params="{}"
                  item-value="en_id"
                  item-text="en_fullname"
                ></m-form-combobox>
              </div>
            </div>
            <div>
              <button class="btn btn-primary ml-2" @click="addCommentAction">Ajouter</button>
            </div>
          </div>
          <m-list-simple ref="actionslist" :dynamic="false" :items="actions" item-value="ac_id" @itemclick="boxClick">
            <template v-slot:ths="{}">
              <!-- <th>Numéro box</th> -->
              <!-- <th>Contact</th> -->
              <th>&nbsp;</th>
              <th>Date</th>
              <th>Contact</th>
              <th>Commentaire</th>
            </template>
            <template v-slot:tds="{ item }">
              <td>
                <input type="checkbox" @click.stop="setDoneToComment(item)" />
              </td>
              <td>{{ $moment(item.createdAt).format("DD/MM/YYYY HH[H]mm") }}</td>
              <td>{{ item.co_id | formatContactNameSimple }}</td>
              <td>{{ item.ac_comment }}</td>
              <!-- <td>
                {{ item.en_id ? item.en_id.en_id : "" }}
              </td> -->
              <!-- <td>
                {{ item.en_id ? $options.filters.formatContactNameSimple(item.en_id.co_id, "co_invoice_") : "" }}
              </td> -->
            </template>
          </m-list-simple>
        </div>
      </div>

      <div :class="$store.getters.checkUserAccess($router, '/parcels/:en_id') ? 'col-md-3' : 'col-md-5'">
        <div class="card">
          <h6>
            <span class="number-title">{{ $store.getters.checkUserAccess($router, "/parcels/:en_id") ? "C" : "B" }}</span> 10 dernières boxes
            connectées
          </h6>
          <m-list-simple
            ref="contactslistEndusers"
            :dynamic="true"
            :items="boxes"
            item-value="bo_id"
            :item-height="20"
            :limit="bo_limit"
            :total="bo_total"
            @itemclick="boxClick"
          >
            <template v-slot:ths="{}">
              <th>Date</th>
              <th>Utilisateur final</th>
            </template>
            <template v-slot:tds="{ item }">
              <td>{{ $moment(item.createdAt).format("DD/MM/YYYY HH[H]mm") }}</td>
              <td>{{ item.en_id ? item.en_id.en_fullname : "" }} (N°{{ item.en_id ? item.en_id.en_id : "" }})</td>
            </template>
          </m-list-simple>
        </div>
      </div>
    </div>
    <div
      v-if="
        user &&
          user.us_type === 'LATEAM' &&
          Array.isArray(user.establishments) &&
          user.establishments.find(establishment => {
            return establishment.es_name.toLowerCase() === 'emotivi';
          })
      "
      class="row mt-4"
    >
      <h1 class="col-12">Fonctionnalités administrateur</h1>
      <div class="col-md-6">
        <div class="alert alert-secondary d-flex flex-column">
          <box-versions-select></box-versions-select>
          <button class="btn btn-primary" type="button" @click="activateAutomaticUpdate()">Activer la mise à jour automatique</button>
        </div>
      </div>
      <div class="col-md-6">
        <div class="alert alert-secondary d-flex flex-column">
          <a
            href="javascript:void(0)"
            @click="
              confirm('Etes-vous certain de vouloir effectuer des sauvegardes de la base de données ?')
                ? (displayStartBackup = true)
                : (displayStartBackup = false)
            "
            >Faire des sauvegardes de la base de données Emotivi</a
          >
          <div class="form-group d-flex justify-content-center align-items-center" v-if="displayStartBackup">
            <input placeholder="Mot de passe" class="form-control" type="password" id="backup_password" />
            <button class="btn btn-secondary" type="button" @click="startBackup()">Valider</button>
          </div>
        </div>
      </div>
      <div v-if="backup_db" class="col-md-4">
        <!-- socket_response à modifier -->
        <v-remote-terminal :socket_response="backup_db_response" style="width: 100%; height: 500px"></v-remote-terminal>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "home",
  components: {},
  data() {
    return {
      commentEnduser: {},
      commentContact: {},
      commentAction: "",
      user: {},
      parcels: [],
      pa_total: 0,
      pa_limit: 1000,
      //CONST_PA_LIMIT: 10,
      //CONST_BO_LIMIT: 10,
      pa_voirPlus: false,
      boxes: [],
      bo_total: 0,
      bo_limit: 10,
      alertStock: [],
      ///// stats ///////
      stats: [],
      periodSelected: "startemotivi",
      filterDateStart: "2020-01-01",
      filterDateEnd: this.$moment(),
      conpareDateStart: "",
      conpareDateEnd: "",
      actions: [],
      backup_db: false,
      displayStartBackup: false
    };
  },
  mounted() {
    this.$store.dispatch("showMenu", false);
    this.user = this.$store.state.user;
    //if (this.user && this.user.us_type === "PARTNER") return this.$router.push("/contacts");
    this.loadStats();
    this.loadParcels();
    this.loadBoxes();
    this.loadMessages();
    // console.log("this.user", this.user);
  },
  computed: {
    backup_db_response() {
      return this.$store.state.backup_db_response;
    }
  },
  watch: {
    filterDateStart(val) {
      this.loadStats();
    },
    filterDateEnd(val) {
      this.loadStats();
    }
  },
  methods: {
    confirm(text) {
      return window.confirm(text, "");
    },
    async startBackup() {
      let passphrase = document.getElementById("backup_password").value;
      if (!passphrase) return alert("Veuillez saisir un mot de passe");
      this.$store
        .dispatch("socketEmit", {
          cmd: "backup-db",
          data: { passphrase }
        })
        .then(response => {
          // console.log("startBackup callback", response);
          if (response === "ok") {
            this.backup_db = true;
          } else {
            this.backup_db = false;
          }
        });
    },
    async activateAutomaticUpdate() {
      let ok = window.confirm("Voulez vous mettre à jour toutes les boxes?", "");
      if (!ok) return;
      ok = window.confirm("Voulez vous mettre à jour toutes les boxes? Dernier avertissement.", "");
      if (!ok) return;
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/do/automaticupdate");
      if (response.data.err) alert("Une erreur est survenue lors du lancement de la mise à jour automatique.");
      else alert("La mise à jour automatique a été activée avec succès.");
    },

    async setDoneToComment(row_ac) {
      // evt.stopPropagation();
      if (window.confirm("Vous allez indiquer l'action comme faite ; vous ne pourrez pas annuler cette action.")) {
        await this.$axios.put(this.$config.server_url + "/extranet/2.0/actions/" + row_ac.ac_id, {
          ac_done: true
        });
        this.loadMessages();
      }
    },
    showContactName(val) {
      let txt = "";
      if (val.co_invoice_name) txt += val.co_invoice_name.toUpperCase();
      if (val.co_invoice_firstname) txt += " " + val.co_invoice_firstname.charAt(0).toUpperCase() + val.co_invoice_firstname.substr(1);
      return txt;
    },
    async addCommentAction() {
      // console.log("this.commentEnduser", this.commentEnduser);
      if (!this.commentAction || ((!this.commentEnduser || !this.commentEnduser.en_id) && (!this.commentContact || !this.commentContact.co_id)))
        return alert("Veuillez saisir un commentaire et un utilisateur final OU un contact");
      await this.$axios.post(this.$config.server_url + "/extranet/2.0/actions", {
        ac_type: "COMMENT",
        ac_comment: this.commentAction,
        ac_done: false,
        ac_date: this.$moment(),
        en_id: this.commentEnduser && this.commentEnduser.en_id ? this.commentEnduser.en_id : 0,
        co_id: this.commentContact && this.commentContact.co_id ? this.commentContact.co_id : 0,
        us_id: this.$store.state.user.us_id
      });
      this.loadMessages();
      this.commentAction = "";
      this.commentContact = null;
      this.commentEnduser = null;
    },
    async loadParcels() {
      // let params = { pa_states: ["TO_PREPARE", "PREPARING"], sort: "t1.createdAt desc" };
      // let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/parcels", {
      //   params
      // });
      // const ac_params = { types: ["PARCEL_TO_PREPARE", "PARCEL_PREPARING"], sort: "t1.createdAt desc" };
      // let ac_parcels = [];
      // ac_parcels = await this.$axios.get(this.$config.server_url + "/extranet/2.0/actions", {
      //   ac_params
      // });
      // let pa_ids = [];
      // if (!ac_parcels.length) return;
      // ac_parcels.forEach(ac_parcel => {
      //   pa_ids.push(ac_parcel.pa_id);
      // });
      // const params = { pa_ids: pa_ids };
      // let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/parcels", {
      //   params
      // });
      // if (!response || !response.data) return;
      // this.parcels = response.data.data;
      // this.pa_total = response.data.meta.total;

      // /extranet/2.0/contacts?gr_id=boxToPrepare&skip=0&limit=50
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.1/contacts?getEnduser=true", {
        params: { gr_id: "boxToPrepare", co_deleted: 0, addToPrepareDateAndEnduser: true, skip: 0, limit: 1000 }
      });
      this.parcels = response.data.data;
      // console.log("this.parcels", this.parcels);
    },
    async loadBoxes() {
      // const ac_params = { ac_type: "BOX_INSTALL_FIRST_CONNECTION", sort: "t1.createdAt desc", limit: 10 };
      // let ac_boxes = [];
      // ac_boxes = await this.$axios.get(this.$config.server_url + "/extranet/2.0/actions", {
      //   ac_params
      // });
      // let bo_ids = [];
      // if (!ac_boxes.length) return;
      // ac_boxes.forEach(ac_box => {
      //   bo_ids.push(ac_box.bo_id);
      // });
      // const params = { bo_ids: bo_ids };
      // let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/boxes", {
      //   params
      // });
      // if (!response || !response.data) return;
      // this.boxes = response.data.data;

      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/actions", {
        params: { ac_type: "BOX_INSTALL_FIRST_CONNECTION", sort: "t1.createdAt desc", limit: 10 }
      });
      this.boxes = response.data.data;
    },
    async loadMessages() {
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/actions", {
        params: { ac_type: "COMMENT", sort: "t1.createdAt desc", ac_done: false, limit: 10000 }
      });
      this.actions = response.data.data;
    },
    async loadStats() {
      let params = {
        datestart: this.filterDateStart,
        dateend: this.filterDateEnd,
        comparedatestart: this.conpareDateStart,
        comparedateend: this.conpareDateEnd
      };
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/stats", {
        params
      });
      if (!response || !response.data) return;
      this.stats = response.data.stats ? response.data.stats : [];
      this.alertStock = response.data.alerts ? response.data.alerts : [];

      for (let istat = 0; istat < this.stats.length; istat++) {
        const stat = this.stats[istat];
        let diff = stat.chiffreN - stat.chiffreOld;
        if (diff !== 0 && stat.chiffreOld !== 0) {
          let div = diff / stat.chiffreOld;
          let nombre = div * 100;
          let arrondi = nombre * 100;
          // console.log("diff, div, nombre, arrondi", diff, div, nombre, arrondi);
          arrondi = Math.round(arrondi);
          stat.delta = arrondi / 100;
          stat.variation = "stable";
          if (Math.sign(stat.delta) === 1 && stat.delta > 10) {
            stat.variation = "bon";
          }
          if (Math.sign(stat.delta) === -1 && stat.delta < -10) {
            stat.variation = "mauvais";
          }
          stat.delta += "%";
        } else {
          if (stat.chiffreOld === 0) {
            stat.variation = "bon";
            stat.delta = "*";
          }
          if (diff === 0) {
            stat.variation = "stable";
            stat.delta = 0 + "%";
          }
        }
      }

      //this.stats = stats;
    },
    /**
     * calcule les dates de début et de fin de la période et de la période présédente
     * en fonction de la période demandée
     */
    setFilterDate(item) {
      this.filterDateEnd = this.$moment().format("YYYY-MM-DD");
      switch (item) {
        case "last30days":
          this.filterDateStart = this.conpareDateEnd = this.$moment()
            .subtract(30, "days")
            .format("YYYY-MM-DD");
          this.conpareDateStart = this.$moment()
            .subtract(60, "days")
            .format("YYYY-MM-DD");
          break;
        case "last90days":
          this.filterDateStart = this.conpareDateEnd = this.$moment()
            .subtract(90, "days")
            .format("YYYY-MM-DD");
          this.conpareDateStart = this.$moment()
            .subtract(180, "days")
            .format("YYYY-MM-DD");
          break;
        case "thismonth":
          this.filterDateStart = this.$moment().startOf("month");
          this.conpareDateEnd = this.$moment()
            .startOf("month")
            .subtract(1, "day")
            .format("YYYY-MM-DD");
          this.conpareDateStart = this.$moment()
            .startOf("month")
            .subtract(1, "month")
            .format("YYYY-MM-DD");
          break;
        case "lastmonth":
          this.filterDateEnd = this.$moment()
            .startOf("month")
            .subtract(1, "day");
          this.filterDateStart = this.$moment()
            .startOf("month")
            .subtract(1, "month");
          this.conpareDateStart = this.$moment()
            .startOf("month")
            .subtract(2, "month")
            .format("YYYY-MM-DD");
          this.conpareDateEnd = this.$moment()
            .startOf("month")
            .subtract(1, "day")
            .subtract(1, "month")
            .format("YYYY-MM-DD");
          break;
        case "thisyear":
          this.filterDateStart = this.$moment().startOf("year");
          this.conpareDateEnd = this.$moment()
            .startOf("year")
            .subtract(1, "day")
            .format("YYYY-MM-DD");
          this.conpareDateStart = this.$moment()
            .startOf("year")
            .subtract(1, "year")
            .format("YYYY-MM-DD");
          break;
        case "lastyear":
          this.filterDateEnd = this.$moment()
            .startOf("year")
            .subtract(1, "day");
          this.filterDateStart = this.$moment()
            .startOf("year")
            .subtract(1, "year");
          this.conpareDateStart = this.$moment()
            .startOf("year")
            .subtract(2, "year")
            .format("YYYY-MM-DD");
          this.conpareDateEnd = this.$moment()
            .startOf("year")
            .subtract(1, "day")
            .subtract(1, "year")
            .format("YYYY-MM-DD");
          break;
        case "startemotivi":
          this.conpareDateStart = this.conpareDateEnd = "";
          this.filterDateStart = this.$moment("2020-01-01 00:00:00");
          break;
        default:
          this.conpareDateStart = this.conpareDateEnd = "";
          this.filterDateStart = this.$moment("2020-01-01 00:00:00");
          break;
      }
      //this.loadStats();
    },
    boxClick(item) {
      this.$router.push("/boxes/" + item.en_id.en_id);
    },
    parcelClick(item) {
      if (item.en_id && item.en_id.en_id) this.$router.push("/boxes/" + item.en_id.en_id);
    },
    alertGo(item) {
      this.$router.push("/materials/" + item.ma_id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";

#home {
  width: 100%;
}
.row-stats {
  margin-bottom: 10px;
  margin-top: 30px;
  flex-wrap: wrap;
}
.container-chiffre {
  position: relative;
  padding: 10px;
  background-color: #fff;
  border-radius: 7px;
  border: solid 1px #ced4db;
}
.container-chiffre:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  height: 50px;
  border-radius: 7px;
  overflow: hidden;
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.2), 0 -10px 0 -3px #f6f6f6, 0 -11px 1px -3px rgba(0, 0, 0, 0.2), 0 -19px 0 -6px #f6f6f6,
    0 -20px 2px -6px rgba(0, 0, 0, 0.2);
}
.card-stat {
  flex-basis: 100%;
}
.chiffre {
  font-size: 30px;
}
.delta-mauvais {
  border: double 5px #da0353;
  div {
    background-color: #da0353;
    color: #fff;
    text-align: center;
  }
}
.delta-bon {
  border: double 5px #40c191;
  div {
    background-color: #40c191;
    color: #fff;
    text-align: center;
  }
}
.delta-stable {
  border: double 5px #e08f49;
  div {
    background-color: #e08f49;
    color: #fff;
    text-align: center;
  }
}
.variation-mauvais {
  color: #da0353;
}
.variation-bon {
  color: #40c191;
}
.variation-stable {
  color: #e08f49;
}
/*************************/
.btn-voirplus {
  width: 30%;
}
@media (min-width: 1200px) {
  .row-stats {
    flex-wrap: nowrap;
  }
}
.filtre-statistique {
  margin-top: -24px;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
</style>
