<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div>
              <h1>Création / modification d'un contact</h1>
            </div>
            <div class="d-flex flex-row">
              <div class="ml-3 d-flex flex-row align-items-center active">
                <label class="label-checkbox" for="active">{{ row_co.co_active ? "Contact actif" : "Contact non-actif" }}</label>
                <input class="ml-2" type="checkbox" id="active" name="active" v-model="row_co.co_active" />
              </div>
            </div>
          </div>
          <div class="modal-body">
            <div class="row modal-padding">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <h6><span class="number-title">1</span>Coordonnées</h6>
                  </div>
                </div>
                <div class="row" style="margin-top: -15px">
                  <div class="col-md-2">
                    <m-form-select
                      label="Civilité"
                      v-model="row_co.co_invoice_civility"
                      :name="$Utils.randomstring('co_invoice_civility')"
                      :items="$store.state.items_civility"
                    ></m-form-select>
                  </div>

                  <div class="col-md-5">
                    <m-form-text
                      label="Prénom *"
                      type="text"
                      v-model="row_co.co_invoice_firstname"
                      :name="$Utils.randomstring('co_invoice_firstname')"
                      :rules="[$Validation.mandatory]"
                    ></m-form-text>
                  </div>
                  <div class="col-md-5">
                    <m-form-text
                      label="Nom *"
                      type="text"
                      v-model="row_co.co_invoice_name"
                      :name="$Utils.randomstring('co_invoice_name')"
                      :rules="[$Validation.mandatory]"
                    ></m-form-text>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <m-form-text
                      label="eMail *"
                      type="mail"
                      v-model="row_co.co_login"
                      :name="$Utils.randomstring('co_login')"
                      :rules="[$Validation.mandatory]"
                    ></m-form-text>
                  </div>
                  <div class="col-md-4">
                    <m-form-text
                      label="Téléphone mobile"
                      type="phone"
                      v-model="row_co.co_invoice_tel"
                      :name="$Utils.randomstring('co_invoice_tel')"
                      :rules="[$Validation.mandatory]"
                    ></m-form-text>
                  </div>
                  <div class="col-md-4">
                    <m-form-text
                      label="Société"
                      type="text"
                      v-model="row_co.co_invoice_society"
                      :name="$Utils.randomstring('co_invoice_society')"
                    ></m-form-text>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <m-form-combobox
                      label="Établissement"
                      label-position="top"
                      :name="$Utils.randomstring('es_id')"
                      v-model="etablissementSelected"
                      :store-url="$config.server_url + '/extranet/2.0/establishments'"
                      :store-params="{ ignorePopulate: true }"
                      item-value="es_id"
                      item-text="es_name"
                    ></m-form-combobox>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12">
                    <h5>Adresse de facturation</h5>
                  </div>
                </div>
                <div class="row d-flex" style="margin-top: -15px">
                  <div class="col-md-8">
                    <div style="position: relative">
                      <m-form-text
                        label="Rechercher une adresse"
                        v-model="address_autocomplete"
                        :disabled="foreignAddress ? true : false"
                        placeholder="Taper une adresse et sélectionner la bonne adresse"
                      ></m-form-text>
                      <div v-if="address_choice.length" class="container-address-choice">
                        <div v-for="address in address_choice" :key="address.id">
                          <div @click="selectAddress('co_invoice_', address)" class="d-flex flex-column address-choice">
                            <div class="bis">{{ address.properties.label }}</div>
                            <div>{{ address.properties.context }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <m-form-checkbox
                      label="Adresse à l'étranger"
                      :name="$Utils.randomstring('foreignAddress')"
                      v-model="foreignAddress"
                    ></m-form-checkbox>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <m-form-text
                      label="Adresse"
                      type="text"
                      v-model="row_co.co_invoice_address1"
                      :name="$Utils.randomstring('co_invoice_address1')"
                      :disabled="foreignAddress ? false : true"
                    ></m-form-text>
                  </div>
                  <div class="col-md-6">
                    <m-form-text
                      label="Complément d'adresse"
                      type="text"
                      v-model="row_co.co_invoice_address2"
                      :name="$Utils.randomstring('co_invoice_address2')"
                      @input="copyInShippindAddress('co_invoice_address2', 'co_shipping_address2')"
                    ></m-form-text>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2">
                    <m-form-text
                      label="Code postal"
                      type="text"
                      v-model="row_co.co_invoice_zip"
                      :name="$Utils.randomstring('co_invoice_zip')"
                      :disabled="foreignAddress ? false : true"
                    ></m-form-text>
                  </div>
                  <div class="col-md-5">
                    <m-form-text
                      label="Ville"
                      type="text"
                      v-model="row_co.co_invoice_city"
                      :name="$Utils.randomstring('co_invoice_city')"
                      :disabled="foreignAddress ? false : true"
                    ></m-form-text>
                  </div>
                  <div class="col-md-5">
                    <m-form-text
                      label="Pays"
                      type="text"
                      v-model="row_co.co_invoice_country"
                      :name="$Utils.randomstring('co_invoice_country')"
                      @input="copyInShippindAddress('co_invoice_country', 'co_shipping_country')"
                    ></m-form-text>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12">
                    <m-form-checkbox
                      label="Adresse de livraison différente de l'adresse de facturation"
                      :name="$Utils.randomstring('differentShippingAddress')"
                      v-model="row_co.co_adresses_different"
                      @input="copyAllInShippingAddress()"
                      :subText="
                        row_co.co_adresses_different
                          ? 'Attention ! En décochant cette case, l\'adresse de facturation est copiée à la place de l\'adresse de livraison'
                          : ''
                      "
                    ></m-form-checkbox>
                  </div>
                </div>
                <div v-if="row_co.co_adresses_different">
                  <div class="row mt-4">
                    <div class="col-md-12">
                      <h5>Adresse de livraison</h5>
                    </div>
                  </div>
                  <div class="row" style="margin-top: -15px">
                    <div class="col-md-2">
                      <m-form-select
                        label="Civilité"
                        v-model="row_co.co_shipping_civility"
                        :name="$Utils.randomstring('co_shipping_civility')"
                        :rules="[$Validation.mandatory]"
                        :items="$store.state.items_civility"
                      ></m-form-select>
                    </div>

                    <div class="col-md-5">
                      <m-form-text
                        label="Prénom *"
                        type="text"
                        v-model="row_co.co_shipping_firstname"
                        :name="$Utils.randomstring('co_shipping_firstname')"
                        :rules="[$Validation.mandatory]"
                      ></m-form-text>
                    </div>
                    <div class="col-md-5">
                      <m-form-text
                        label="Nom *"
                        type="text"
                        v-model="row_co.co_shipping_name"
                        :name="$Utils.randomstring('co_shipping_name')"
                        :rules="[$Validation.mandatory]"
                      ></m-form-text>
                    </div>
                    <div class="col-md-5">
                      <m-form-text
                        label="Nom de la société"
                        type="text"
                        v-model="row_co.co_shipping_society_name"
                        :name="$Utils.randomstring('co_shipping_society_name')"
                      ></m-form-text>
                    </div>
                  </div>
                  <div class="row d-flex flex-row align-items-end">
                    <div class="col-md-8">
                      <div style="position: relative">
                        <m-form-text label="Adresse" v-model="address_autocomplete2" :disabled="foreignAddress2 ? true : false"></m-form-text>
                        <div v-if="address_choice2.length" class="container-address-choice">
                          <div v-for="address in address_choice2" :key="address.id">
                            <div @click="selectAddress('co_shipping_', address)" class="d-flex flex-column address-choice">
                              <div class="bis">{{ address.properties.label }}</div>
                              <div>{{ address.properties.context }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <m-form-checkbox
                        label="Adresse à l'étranger"
                        :name="$Utils.randomstring('foreignAddress2')"
                        v-model="foreignAddress2"
                      ></m-form-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <m-form-text
                        label="Adresse"
                        type="text"
                        v-model="row_co.co_shipping_address1"
                        :name="$Utils.randomstring('co_shipping_address1')"
                        :disabled="foreignAddress2 ? false : true"
                      ></m-form-text>
                    </div>
                    <div class="col-md-6">
                      <m-form-text
                        label="Complément d'adresse"
                        type="text"
                        v-model="row_co.co_shipping_address2"
                        :name="$Utils.randomstring('co_shipping_address2')"
                      ></m-form-text>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-2">
                      <m-form-text
                        label="Code postal"
                        type="text"
                        v-model="row_co.co_shipping_zip"
                        :name="$Utils.randomstring('co_shipping_zip')"
                        :disabled="foreignAddress2 ? false : true"
                      ></m-form-text>
                    </div>
                    <div class="col-md-5">
                      <m-form-text
                        label="Ville"
                        type="text"
                        v-model="row_co.co_shipping_city"
                        :name="$Utils.randomstring('co_shipping_city')"
                        :disabled="foreignAddress2 ? false : true"
                      ></m-form-text>
                    </div>
                    <div class="col-md-5">
                      <m-form-text
                        label="Pays"
                        type="text"
                        v-model="row_co.co_shipping_country"
                        :name="$Utils.randomstring('co_shipping_country')"
                      ></m-form-text>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <m-form-text
                        label="Téléphone"
                        type="text"
                        v-model="row_co.co_shipping_tel"
                        :name="$Utils.randomstring('co_shipping_tel')"
                      ></m-form-text>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12">
                    <h6><span class="number-title">2</span>Informations de connexion</h6>
                  </div>
                </div>
                <div class="row" style="margin-top: -15px">
                  <div class="col-md-4">
                    <m-form-text
                      label="Login"
                      type="text"
                      v-model="row_co.co_login"
                      :name="$Utils.randomstring('co_login')"
                      subText="Votre login est votre adresse mail"
                      disabled
                    ></m-form-text>
                  </div>
                  <div class="col-md-4">
                    <m-form-text label="Mot de passe" type="password" v-model="password1" :name="$Utils.randomstring('password1')"></m-form-text>
                  </div>
                  <div class="col-md-4">
                    <m-form-text
                      label="Répétez le mot de passe"
                      type="password"
                      v-model="password2"
                      :name="$Utils.randomstring('password2')"
                    ></m-form-text>
                  </div>
                </div>

                <div v-if="$store.getters.checkUserAccess($router, '/invoices')" class="row mt-4">
                  <div class="col-md-12 mb-3">
                    <h6><span class="number-title">3</span>Prélévement bancaire RIB</h6>
                  </div>
                </div>
                <div v-if="$store.getters.checkUserAccess($router, '/invoices')" class="row" style="margin-top: -15px">
                  <div class="col">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" name="active" v-model="row_co.co_rib" id="co_rib" />
                      <label class="form-check-label" for="co_rib">Souhaite payer par RIB</label>
                    </div>
                  </div>
                </div>
                <div v-if="$store.getters.checkUserAccess($router, '/invoices')" class="row mb-4">
                  <div class="col-4">
                    <m-form-text label="Titulaire du compte" v-model="row_co.co_rib_name" :name="$Utils.randomstring('co_rib_name')"></m-form-text>
                  </div>
                  <div class="col-4">
                    <m-form-text label="IBAN" v-model="row_co.co_rib_iban" :name="$Utils.randomstring('co_rib_iban')"></m-form-text>
                  </div>
                  <div class="col-4">
                    <m-form-text label="BIC" v-model="row_co.co_rib_bic" :name="$Utils.randomstring('co_rib_bic')"></m-form-text>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-md-12">
                    <h6><span class="number-title">4</span>Autres informations</h6>
                  </div>
                </div>
                <div class="row mb-3" style="margin-top: -15px">
                  <div class="col-md-12">
                    <m-form-text
                      label="Commentaire"
                      type="textarea"
                      :rows="3"
                      v-model="row_co.co_comment"
                      :name="$Utils.randomstring('co_comment')"
                    ></m-form-text>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <m-form-checkbox label="Contact de test" name="testContact" v-model="row_co.co_test"></m-form-checkbox>
                  </div>
                </div>
              </div>
            </div>

            <!--             <div class="row modal-padding d-flex flex-column">
              <div class="col-md-12">
                <h3 class="color-blue1">Les <span class="bis">droits</span></h3>
                <div class="hscroll">
                  <table>
                    <thead>
                      <tr>
                        <th>Les droits</th>
                        <th colspan="4">Le contact</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style="min-width:170px;"></td>
                        <td style="min-width:120px;">A le droit</td>
                        <td style="min-width:120px;">Comme le groupe</td>
                        <td style="min-width:120px;">N'a pas le droit</td>
                        <td style="min-width:120px;" class="bg-success">
                          Droit final
                        </td>
                      </tr>
                      <tr v-for="row_rire in $store.state.items_rights_ressources" :key="row_rire.rire_id">
                        <td>{{ row_rire.rire_label }}</td>
                        <td>
                          <div>
                            <input
                              type="radio"
                              v-model="row_co.co_rights[row_rire.rire_id]"
                              :id="`${row_rire.rire_id}_yesright`"
                              :name="`${row_rire.rire_id}_right`"
                              :value="1"
                            />
                          </div>
                        </td>
                        <td>
                          <input
                            type="radio"
                            v-model="row_co.co_rights[row_rire.rire_id]"
                            :id="`${row_rire.rire_id}_groupright`"
                            :name="`${row_rire.rire_id}_right`"
                            :value="0"
                          />
                        </td>
                        <td>
                          <input
                            type="radio"
                            v-model="row_co.co_rights[row_rire.rire_id]"
                            :id="`${row_rire.rire_id}_noright`"
                            :name="`${row_rire.rire_id}_right`"
                            :value="-1"
                          />
                        </td>
                        <td :class="classRightCalculated(row_rire)">
                          {{ rightCalculated(row_rire) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div> -->
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between">
            <button
              v-if="$store.getters.checkUserAccess($router, '/contacts/:co_id/edit')"
              type="button"
              class="btn btn-danger"
              @click="deleteConfirmWin"
            >
              Supprimer
            </button>

            <div class="d-flex flex-row align-items-center">
              <button type="button" class="btn btn-secondary" @click="cancelWin">Annuler</button>
              <button
                v-if="$store.getters.checkUserAccess($router, '/contacts/:co_id/edit')"
                type="button"
                class="btn btn-primary btn-mouveal ml-2"
                @click="saveWin"
              >
                Sauvegarder
              </button>
            </div>
          </div>
        </div>
      </div>
      <m-confirm-dialog
        v-model="confirmdelete"
        text="Souhaitez-vous effacer ce contact ?"
        title="Confirmation"
        @canceled="confirmdelete = false"
        @confirmed="deleteWin()"
      ></m-confirm-dialog>
      <m-message-dialog v-model="dialogErr" title="Erreur" :text="dialogErrTxt"></m-message-dialog>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ContactWinEdit",
  components: {},
  props: {
    co_id: Number,
    value: {
      default: false,
      type: Boolean
    },
    maFiche: Boolean,
    contactType: String
  },
  data() {
    /* let itemsTypes = this.$store.state.items_rights_roles.map(item => {
      return { value: item.riro_id, text: item.riro_label };
    }); */
    return {
      dialogErr: false,
      dialogErrTxt: "",
      avatar: null,
      confirmdelete: false,
      row_co: { co_rights: {} },
      stylecontainer: ``,
      //      itemsTypes: [],
      password1: "",
      password2: "",
      address_choice: [],
      address_choice2: [],
      address_autocomplete: "",
      address_autocomplete2: "",
      addressSelected: {},
      timeout: null,
      // differentShippingAddress: false,
      foreignAddress: false,
      foreignAddress2: false,
      etablissementSelected: {}
    };
  },
  watch: {
    value(v) {
      //this.$$("#exampleModalScrollable").modal(v ? "show" : "hide");
      //this.refs.exampleModalScrollable.modal(v ? "show" : "hide");
      if (v) {
        this.getContact();

        // console.log("this.getContact();", this.contactType);
      }
    },
    address_autocomplete(val) {
      if (this.timeout) window.clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        let params = { address: val };
        let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/contacts/address", { params });
        if (!response || !response.data) return;
        this.address_choice = response.data.data;
      }, 1000);
    },
    address_autocomplete2(val) {
      if (this.timeout) window.clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        let params = { address: val };
        let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/contacts/address", { params });
        if (!response || !response.data) return;
        this.address_choice2 = response.data.data;
      }, 1000);
    }
  },
  computed: {},
  created() {},
  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    selectAddress(from, address) {
      this.addressSelected = address;
      this.row_co[from + "address1"] = address.properties.name;
      this.row_co[from + "zip"] = address.properties.postcode;
      this.row_co[from + "city"] = address.properties.city;
      // if (!this.row_co.co_adresses_different && from === "co_invoice_") {
      //   this.row_co.co_shipping_address1 = address.properties.name;
      //   this.row_co.co_shipping_zip = address.properties.postcode;
      //   this.row_co.co_shipping_city = address.properties.city;
      // }
      this.address_choice = [];
      this.address_choice2 = [];
      this.address_autocomplete2 = "";
      this.address_autocomplete = "";
    },
    copyInShippindAddress(copyfrom, copyto) {
      // if (this.row_co.co_adresses_different) this.row_co[copyto] = this.row_co[copyfrom];
    },
    copyAllInShippingAddress() {
      let tab = [
        { from: "co_invoice_civility", to: "co_shipping_civility" },
        { from: "co_invoice_name", to: "co_shipping_name" },
        { from: "co_invoice_firstname", to: "co_shipping_firstname" },
        { from: "co_invoice_address1", to: "co_shipping_address1" },
        { from: "co_invoice_address2", to: "co_shipping_address2" },
        { from: "co_invoice_city", to: "co_shipping_city" },
        { from: "co_invoice_zip", to: "co_shipping_zip" },
        { from: "co_invoice_tel", to: "co_shipping_tel" },
        { from: "co_invoice_country", to: "co_shipping_country" }
      ];
      if (!this.row_co.co_adresses_different) {
        //   for (let i = 0; i < tab.length; i++) {
        //     const field = tab[i];
        //     this.row_co[field.to] = this.row_co[field.from];
        //   }
        // } else {
        for (let i = 0; i < tab.length; i++) {
          const field = tab[i];
          this.row_co[field.to] = "";
        }
      }
    },
    rightCalculated(row_rire) {
      if (this.row_co.co_rights[row_rire.rire_id] === -1) return "NON";
      if (this.row_co.co_rights[row_rire.rire_id] === 1) return "OUI";
      if (
        this.$_.find(this.$store.state.items_rights_group, {
          rire_id: row_rire.rire_id,
          riro_id: this.row_co.co_type
        })
      ) {
        return "OUI";
      } else {
        return "NON";
      }
    },
    classRightCalculated(row_rire) {
      if (this.row_co.co_rights[row_rire.rire_id] === -1) return "bg-danger";
      if (this.row_co.co_rights[row_rire.rire_id] === 1) return "bg-success";
      if (
        this.$_.find(this.$store.state.items_rights_group, {
          rire_id: row_rire.rire_id,
          riro_id: this.row_co.co_type
        })
      ) {
        return "bg-success";
      } else {
        return "bg-danger";
      }
    },
    /*     getIconRightGroup(ressource) {
      if (this.row_co.co_type && this.row_co.co_type !== "contact") {
        if (
          this.$_.find(this.$store.state.items_rights_group, {
            rire_id: ressource.rire_id,
            riro_id: this.row_co.co_type
          })
        ) {
          return "OUI";
        } else {
          return "NON";
        }
      } else {
        return "?";
      }
    }, */
    // open() {
    //   // let co_id = this.$route.params.co_id;
    //   this.dialog = true;
    //   this.getContact(this.value);
    // },
    async getContact() {
      this.avatar = null;
      // if (this.$refs.inputAvatar) this.$refs.inputAvatar.reset();
      let params = { getacl: true };
      if (this.co_id == -1) params = {};
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/contacts/" + this.co_id, { params });
      if (response.data && response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }
      let row_co = response.data && response.data.data ? response.data.data : {};
      this.etablissementSelected = row_co.es_id ? row_co.es_id : {};
      if (this.co_id == -1 && this.$store.state.user.us_type !== "LATEAM" && this.$store.state.user.us_type !== "DIRECTOR") {
        // this user has no access to establishments list, so we set user establishment automatically to the new contact
        if (this.$store.state.user.establishments && Array.isArray(this.$store.state.user.establishments))
          this.etablissementSelected = this.$store.state.user.establishments[0];
      }
      if (!row_co.co_rights) row_co.co_rights = {};
      for (let iRe = 0; iRe < this.$store.state.items_rights_ressources.length; iRe++) {
        const ressource = this.$store.state.items_rights_ressources[iRe];
        if (!row_co.co_rights[ressource.rire_id]) row_co.co_rights[ressource.rire_id] = 0;
      }

      this.row_co = row_co;
    },
    async saveWin() {
      // console.log("savewin");
      let err = [];
      let fieldRequired = [
        { field: "co_invoice_name", text: "nom" },
        { field: "co_invoice_firstname", text: "prénom" },
        { field: "co_invoice_zip", text: "code postal" },
        { field: "co_invoice_city", text: "ville" },
        { field: "co_invoice_country", text: "pays" },
        { field: "co_login", text: "email" }
      ];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.row_co[field.field]) err.push(field);
      }

      // console.log("this.etablissementSelected", this.etablissementSelected);
      if (!this.etablissementSelected.es_id) {
        err.push({ field: "es_id", text: "Établissement" });
      } else {
        this.row_co.es_id = this.etablissementSelected.es_id;
        // if (this.etablissementSelected.es_name !== "Emotivi") this.row_co.co_box_inestablishment = "yes";
        // else this.row_co.co_box_inestablishment = "no";
      }

      /*** vérification du mot de passe ***/
      // si on est en création, les mots de passe sont obligatoires
      if (!this.row_co.co_id && (!this.password1 || !this.password2)) {
        err.push({
          field: "co_password",
          text: "Mot de passe"
        });
      }
      if (this.password1 !== this.password2) {
        err.push({
          field: "co_password",
          text: "<br><span class='bis'>Les mots de passe doivent être identiques</span>"
        });
      } else {
        this.row_co.co_password = this.password1;
        this.password1 = "";
        this.password2 = "";
      }

      /*** vérification adresse de livraison ***/
      let fieldSippingRequired = [
        // { field: "co_shipping_civility", text: "civilité", fieldInvoice: "co_invoice_civility" },
        { field: "co_shipping_name", text: "nom", fieldInvoice: "co_invoice_name" },
        { field: "co_shipping_firstname", text: "prénom", fieldInvoice: "co_invoice_firstname" },
        { field: "co_shipping_address1", text: "adresse 1", fieldInvoice: "co_invoice_address1" },
        // { field: "co_shipping_address2", text: "prénom", fieldInvoice: "co_invoice_address2" },
        { field: "co_shipping_zip", text: "code postal", fieldInvoice: "co_invoice_zip" },
        { field: "co_shipping_city", text: "ville", fieldInvoice: "co_invoice_city" }
        // { field: "co_shipping_country", text: "pays", fieldInvoice: "co_invoice_country" },
        // { field: "co_shipping_tel", text: "téléphone", fieldInvoice: "co_invoice_tel" }
      ];
      if (this.row_co.co_adresses_different) {
        let shippingErr = false;
        for (let ifi = 0; ifi < fieldSippingRequired.length; ifi++) {
          const field = fieldSippingRequired[ifi];
          if (!this.row_co[field.field]) {
            shippingErr = true;
            break;
          }
        }
        if (shippingErr) {
          err.push({
            field: "co_shipping",
            text: "<br><span class='bis'>Adresse de livraison incomplète</span>"
          });
        }
      } else {
        /** disable -> fill automatically the shipping fields with invoices fields value, it can erase shipping data if we uncheck co_adresses_different checkbox and save contact modification */
        // for (let ifi = 0; ifi < fieldSippingRequired.length; ifi++) {
        //   const field = fieldSippingRequired[ifi];
        //   this.row_co[field.field] = this.row_co[field.fieldInvoice];
        // }
      }

      if (err.length) {
        this.dialogErrTxt = "<span class='bis'>Vous devez remplir les champs : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error.text + " <br>";
        }
        this.dialogErr = true;
      } else {
        this.row_co.co_invoice_email = this.row_co.co_login;
        let response;
        if (this.row_co.co_id) {
          response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/contacts/" + this.row_co.co_id, this.row_co);
        } else {
          this.row_co.co_cgu_date = "0000-00-00 00:00:00";
          response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/contacts", this.row_co);
        }
        if (response.data.err) {
          this.$store.dispatch("showDialogError", response.data.err.message);
          return;
        }
        let row_co = response.data.data;
        if (row_co.co_id == this.$store.state.user.co_id) {
          this.$store.commit("set_user_simple", row_co);
        }
        this.password1 = "";
        this.password2 = "";
        this.$emit("input", false);
        this.$emit("saved", row_co.co_id);
      }
    },
    cancelWin() {
      this.password1 = "";
      this.password2 = "";
      this.$emit("input", false);
      this.$emit("canceled", this.row_co.co_id);
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteWin() {
      let co_id = this.row_co.co_id;
      await this.$axios.delete(this.$config.server_url + "/extranet/2.0/contacts/" + co_id);
      this.confirmdelete = false;
      // this.dialog = false;
      this.$emit("input", false);
      this.$emit("deleted", co_id);
      //      this.$emit("deleted", 0);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";
/* .slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
{
  transform: translateY(-10px);
  opacity: 0;
} */
.winedit-container-header {
  width: 100%;
  justify-content: space-between;
}
.input-avatar {
  padding: 0;
}
.modal {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.active {
  min-width: 150px;
  input {
    width: auto;
  }
}
/*************** table droits **************/
tr {
  border-bottom: 0.5px solid #ccc9c9;
}
td:first-child {
  text-align: left;
}
td,
th {
  border-right: 0.5px solid #ccc9c9;
  border-left: 0.5px solid #ccc9c9;
  padding: 5px 10px;
  text-align: center;
}
th {
  background-color: #013c62;
  color: #fff;
  text-transform: uppercase;
}
th.groupe {
  color: black;
}
.groupe {
  background-color: #b5b2b2;
}
</style>
