<template>
  <div
    class="modal"
    :class="value ? 'show' : 'hide'"
    :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex flex-row align-items-center">
          <div>
            <h1>Planifier un message pour toutes les boxes</h1>
          </div>
        </div>
        <div class="modal-body">
          <div>
            <!-- ajouter le bouton Afficher la liste des messages planifiés -->
            <button type="button" class="btn btn-primary mb-5" @click="_displayScheduledMessagesList()">
              Afficher la liste des messages planifiés
            </button>
            <h6><span class="number-title">1</span>Préférences</h6>
            <div class="row">
              <div class="col-md-3 d-flex justify-content-center">
                <div class="container-avatar" :style="styleAvatar"></div>
              </div>
              <div class="col-md-5">
                <m-form-select
                  label="Établissements"
                  label-position="top"
                  :name="$Utils.randomstring('establishmentsSelected')"
                  v-model="establishmentsSelected"
                  :items="establishments"
                ></m-form-select>
              </div>
              <div class="col-md-4">
                <m-form-select
                  label="Type de message"
                  label-position="top"
                  :name="$Utils.randomstring('messageTypeSelected')"
                  v-model="messageTypeSelected"
                  :items="messageTypes"
                ></m-form-select>
              </div>
            </div>
            <div class="row" v-if="selectBoxes">
              <hr class="hr-mini w-100" />
              <m-form-checkbox
                class="col-8 mt-3"
                label="Afficher uniquement les boxes connectées"
                :name="$Utils.randomstring('selectconnectedboxes')"
                v-model="selectConnectedBoxes"
                @input="loadBoxes"
              ></m-form-checkbox>
              <m-form-text
                class="col-4 m-auto"
                width="200px"
                label="Rechercher"
                label-position="top"
                v-model="filterText"
                name="search"
                autocomplete
                @input="loadBoxes"
              ></m-form-text>
            </div>
            <div :style="'min-height:' + calculateHeight() + 'px;'" class="flex-grow-1 d-flex flex-column position-relative mt-2" v-if="selectBoxes">
              <div style="overflow: auto; position: absolute; top: 0; left: 0; right: 0; bottom: 0" class="card card-no-margin">
                <m-list
                  ref="boxeslist"
                  :items="selectConnectedBoxes ? connectedBoxes : boxes"
                  item-value="en_id"
                  :item-height="30"
                  :limit="boxes_limit"
                  :skip="boxes_skip"
                  :total="selectConnectedBoxes ? connected_boxes_total : boxes_total"
                  :current="boxes_current"
                  :bgstrip="true"
                  @changerange="boxesChangerange"
                >
                  <template v-slot:ths="{}">
                    <th class="" @click="sortItems(['en_id'])">
                      <span class="pointer">Numéro (en_id)</span>
                    </th>
                    <th style="min-width: 50px">
                      <span class="pointer">Connectée</span>
                    </th>

                    <th @click="sortItems(['en_fullname'])">
                      <span class="pointer">Utilisateur final</span>
                    </th>
                    <th>
                      <span class="pointer">Établissement</span>
                    </th>

                    <th style="width: 120px">
                      <span class="pointer">Selectionner</span>
                    </th>
                  </template>
                  <template v-slot:tds="{ item }">
                    <td>
                      {{ item.en_id }} <span v-if="item.bo_id">{{ item.bo_id.bo_mac_address }}</span>
                    </td>
                    <td
                      :class="{
                        mygreen: item.bo_id && $store.state.connectedBoxes.indexOf(item.bo_id.bo_mac_address) >= 0,
                        myred: !item.bo_id || $store.state.connectedBoxes.indexOf(item.bo_id.bo_mac_address) < 0
                      }"
                    >
                      {{ item.bo_id && $store.state.connectedBoxes.indexOf(item.bo_id.bo_mac_address) >= 0 ? "Connecté" : "Non connecté" }}
                    </td>

                    <td>
                      {{ item.en_fullname }}
                    </td>
                    <td>
                      {{ item.co_id && item.co_id.es_id ? item.co_id.es_id.es_name : "" }}
                    </td>

                    <td style="width: 120px">
                      <div class="d-flex flex-row">
                        <div @click="selectBox(item, $event)">
                          <icon
                            width="20"
                            height="20"
                            :name="selectedBoxes.includes(item.bo_id.bo_id) ? 'check' : 'times'"
                            :class="selectedBoxes.includes(item.bo_id.bo_id) ? 'text-info' : 'text-secondary'"
                          ></icon>
                        </div>
                      </div>
                    </td>
                  </template>
                </m-list>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <h6><span class="number-title">2</span>Planification</h6>
            <v-schedule-message
              v-show="establishmentsSelected && messageTypeSelected == 1"
              ref="schedulemessage"
              @saveplanning="savePlanningData"
              :inputPlanningObject="planning"
            ></v-schedule-message>
            <v-schedule-planning v-show="establishmentsSelected && messageTypeSelected == 2" ref="scheduleplanning"></v-schedule-planning>
            <p v-show="!establishmentsSelected || !messageTypeSelected" class="alert alert-warning">
              Veuillez selectionner un établissement et un type de message.
            </p>
          </div>
        </div>
        <div class="modal-footer d-flex flex-row justify-content-between">
          <div></div>
          <div>
            <button type="button" class="btn btn-secondary" @click="cancelWin">Annuler</button>
            <!-- <button type="button" class="btn btn-primary ml-2" @click="saveWin(false)">Envoyer</button> -->
            <button type="button" class="btn btn-primary ml-2" @click="sendMessage">Envoyer</button>
          </div>
        </div>
      </div>
    </div>
    <!-- <m-message-dialog v-model="dialogErr" title="Erreur" :text="dialogErrTxt"></m-message-dialog> -->

    <v-scheduled-messages-list :allEndUsers="1" @modifyplanning="modifyPlanning" v-model="displayScheduledMessagesList"></v-scheduled-messages-list>
  </div>
</template>

<script>
export default {
  name: "ModalScheduleMessageAll",
  components: {},
  props: {
    value: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      establishments: [],
      establishmentsSelected: 0,
      messageTypes: [
        {
          text: "Texte et photo",
          value: 1
        },
        {
          text: "Planning",
          value: 2
        }
      ],
      selectBoxes: false,
      filterText: "",
      filterEstablishment: "",
      filterStatusBox: "",
      sortOrder: "asc",
      sortFields: ["en_id"],
      boxes_limit: 50,
      boxes_skip: 0,
      boxes_total: 0,
      boxes_current: "",
      boxes: [],
      connectedBoxes: [],
      connected_boxes_total: 0,
      selectedBoxes: [],
      selectConnectedBoxes: false,
      messageTypeSelected: 0,
      row_es: {},
      styleAvatar: "",
      displayScheduledMessagesList: false,
      planning: {},
      planningToSave: {}
    };
  },
  watch: {
    selectBoxes(value) {
      if (value && this.establishmentsSelected) this.loadBoxes();
    },
    establishmentsSelected(val) {
      //console.log("val (es_id)", val, this.$store.state.user.establishments);
      let es = this.$_.find(this.$store.state.user.establishments, { es_id: val });
      if (es) {
        this.selectBoxes = true;
        this.styleAvatar = "background-image:url('" + this.$config.server_url + es.es_avatar + "')";
      } else {
        this.selectBoxes = false;
      }
      this.row_es = es;
      // get the end user establishement list to fill the planning en_ids field
      this.getEndusers();
    }
  },
  computed: {},

  mounted() {
    let establishments = [];
    for (let i = 0; i < this.$store.state.user.establishments.length; i++) {
      const row_es = this.$store.state.user.establishments[i];
      establishments.push({
        text: row_es.es_name,
        value: row_es.es_id
      });
    }
    this.establishments = establishments;
    console.log(this.establishments);
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  created() {},
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    calculateHeight() {
      return window.screen.height / 3;
    },
    cancelWin() {
      this.resetData();
      // this.$router.push("/boxes");
      this.$emit("input", false);
    },

    async sendMessage() {
      if (!this.establishmentsSelected) return this.$store.dispatch("showDialogError", "Veuillez selectionner un établissement");
      if (!this.planningToSave.en_ids || !this.planningToSave.en_ids.length)
        return this.$store.dispatch("showDialogError", "Cet établissement n'a aucune box active");
      if (!this.planningToSave.pl_message && !this.planningToSave.pl_messagemedia)
        return this.$store.dispatch("showDialogError", "Veuillez écrire un message  ou sélectionner une image");
      if (!this.planningToSave.pl_datedebut) return this.$store.dispatch("showDialogError", "Veuillez sélectionner une date de début");
      if (!this.planningToSave.pl_cycle) return this.$store.dispatch("showDialogError", "Veuillez sélectionner une récurrence");
      if (this.planningToSave.pl_cycle && this.planningToSave.pl_cycle !== "onetime" && !this.planningToSave.pl_cyclevalue)
        return this.$store.dispatch("showDialogError", "Veuillez entrer le cycle de récurrence");

      try {
        let method = "post";
        let putUrl = "";
        this.planningToSave.pl_sendtoall = true;
        if (this.planningToSave.pl_id && this.planningToSave.pl_id !== -1) {
          method = "put";
          putUrl = "/" + this.planningToSave.pl_id;
        }
        let response = await this.$axios[method](this.$config.server_url + "/extranet/2.0/plannings" + putUrl, this.planningToSave);
        if (response.data.error) {
          this.$store.dispatch("showDialogError", response.data.err.message);
          return;
        }

        // cosole.log("response", response);
        this.resetData();
        // this.image = null;
        // this.$router.push("/boxes");
        this.$emit("input", false);
      } catch (error) {
        console.error(error);
      }
    },

    resetData() {
      this.styleAvatar = "";
      this.establishmentsSelected = 0;
      this.planningToSave = {};
      this.planning = {};
      this.$refs.schedulemessage.resetData();
    },
    _displayScheduledMessagesList() {
      this.displayScheduledMessagesList = true;
    },
    modifyPlanning(planning) {
      this.displayScheduledMessagesList = false;
      // fill the ScheduleMessage formulaire with planning data
      this.planning = planning;
      this.getPlanningEstablishment();
    },
    async getEndusers() {
      let params = {
        types: [],
        // limit: 0,
        bo_id: ">0",
        establishment: this.establishmentsSelected
      };

      this.cancelAxiosLoadContacts && this.cancelAxiosLoadContacts();
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/endusers", {
        params,
        cancelToken: new this.$axios.CancelToken(c => {
          this.cancelAxiosLoadContacts = c;
        })
      });
      let en_ids = [];
      if (!response || !response.data || !response.data.data) return;
      for (const enduser of response.data.data) {
        if (enduser.en_id) en_ids.push(enduser.en_id);
      }

      await this.loadBoxes();
      // use this.$set to make this.planning nested object content reactive to 'watch' inside ScheduleMessage components
      this.$set(this.planning, "en_ids", en_ids);

      return;

      // this.$refs.boxeslist.update();
      // console.log("this.boxes", this.boxes);
    },
    async loadBoxes() {
      let types = [];
      let sort = this.sortFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.sortOrder;
        return accumulator;
      }, "");
      let params = {
        types: types,
        skip: this.boxes_skip,
        limit: this.boxes_limit,
        sort: sort,
        bo_id: ">0"
      };
      if (this.selectConnectedBoxes) {
        params.skip = null;
        params.limit = null;
      }

      if (this.filterText) params.text = this.filterText;
      if (this.establishmentsSelected) params.establishment = this.establishmentsSelected;

      this.cancelAxiosLoadContacts && this.cancelAxiosLoadContacts();
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/endusers", {
        params,
        cancelToken: new this.$axios.CancelToken(c => {
          this.cancelAxiosLoadContacts = c;
        })
      });
      if (!response || !response.data) return;

      this.boxes = response.data.data;

      this.boxes_total = response.data.meta.total;
      this.connectedBoxes = this.boxes.filter(box => {
        return this.$store.state.connectedBoxes.includes(box.bo_id.bo_mac_address);
      });
      this.connected_boxes_total = this.connectedBoxes.length;
      // this.$refs.boxeslist.update();
      // console.log("this.boxes", this.boxes);
    },
    selectBox(box, event) {
      // if exist remove from the list of selected boxes ( uncheck )
      let boxId = box.bo_id.bo_id;
      if (this.selectedBoxes.includes(boxId)) this.selectedBoxes.splice(this.selectedBoxes.indexOf(boxId), 1);
      else this.selectedBoxes.push(boxId);
    },
    boxesChangerange(skip, scrollTop) {
      //console.log("skip, scrollTop", skip, scrollTop);
      this.boxes_skip = skip;
      this.loadBoxes();
    },
    savePlanningData(planning) {
      this.planningToSave = planning;
    },
    async getPlanningEstablishment() {
      let endUser = this.planning.en_ids[0];
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/endusers/" + endUser);
      if (response && response.data && response.data.data && response.data.data.co_id && response.data.data.co_id.es_id) {
        this.establishmentsSelected = this.$_.isObject(response.data.data.co_id.es_id)
          ? response.data.data.co_id.es_id.es_id
          : response.data.data.co_id.es_id;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";
.container-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: 8px;
  background-position: center;
  background-size: cover;
  border: 1px solid #b5b5b5;
  box-shadow: 2px 2px 6px 0px #d0d0d0;
}

// from WEBAPP
.avatar-message {
  width: 90%;
  margin: auto;
}
.btn-envoyer {
  width: 100%;
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: 500;
}
input {
  margin-top: 20px;
}
.colWithoutPadding {
  padding-right: 10px;
}
.fileContainer {
  height: 50px;
  background-color: #ffffff;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 0;
  align-items: flex-end;
  border: 1px solid #ced4da;
  //   background-image: url("../../../public/assets/addphoto.png");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}
.labelImage {
  color: #6d6c64;
  font-size: 20px;
  padding: 10px;
  margin-bottom: 0;
}
textarea {
  font-size: 20px;
}

/****** toggle button  ******/
/* The switch - the box around the slider */
.switch {
  // float: left;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  min-width: 60px;
  margin-right: 10px;
  margin-left: 10px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #6da;
}

input:focus + .slider {
  box-shadow: 0 0 1px #6da;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.container-logout {
  text-align: left;
}
.btn-logout {
  text-align: left;
  background-color: #ffffff00;
  border: 0;
}
.iconMarginRight {
  width: 40px;
  width: 40px;
  height: 40px;
  opacity: 0.2;
}
</style>
