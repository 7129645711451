// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../public/images/addphoto.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "div.align-self-center[data-v-4e0d4f70]:hover {\n  background-color: lightgray;\n}\n.col-md-1[data-v-4e0d4f70] {\n  border: 1px solid grey;\n  padding: 2px;\n  margin: 4px;\n  text-align: center;\n}\n.col-md-1[data-v-4e0d4f70]:hover {\n  background-color: lightgray;\n}\n#media-to-send[data-v-4e0d4f70] {\n  -webkit-transform-origin: left;\n          transform-origin: left;\n}\n.btn-img[data-v-4e0d4f70] {\n  padding: 6px;\n  display: none;\n  position: absolute;\n  bottom: 0px;\n  width: 40px;\n  height: 40px;\n  margin-bottom: 20px;\n  border: 1px solid #b7b5b5;\n}\n.rotate-left[data-v-4e0d4f70] {\n  right: 100px;\n}\n.rotate-right[data-v-4e0d4f70] {\n  right: 60px;\n}\n.trash[data-v-4e0d4f70] {\n  right: 15px;\n}\n.btn-rotate[data-v-4e0d4f70] {\n  background-color: #ffffff8f;\n}\n.fileContainer[data-v-4e0d4f70] {\n  height: 176px;\n  background-color: #ffffff;\n  width: 100%;\n  margin-top: 20px;\n  margin-bottom: 0;\n  -webkit-box-align: end;\n      -ms-flex-align: end;\n          align-items: flex-end;\n  border: 1px solid #ced4da;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: contain;\n  background-position: top;\n  background-repeat: no-repeat;\n}\n", ""]);
// Exports
module.exports = exports;
