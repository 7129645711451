<template>
  <section>
    <transition name="fade">
      <div
        class="modal"
        :class="value ? 'show' : 'hide'"
        :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-scrollable" style="max-width: 800px;" role="document">
          <div class="modal-content">
            <div class="modal-header d-flex flex-row align-items-center">
              <div>
                <h1>Liste des plannings</h1>
              </div>
            </div>
            <div class="modal-body">
              <div class="text-right">
                <button class="btn btn-sm btn-primary color-white" type="button" @click="openPlanningEditor(-1)">
                  <icon name="plus" scale="1" class="mr-2"></icon> Nouveau
                </button>
              </div>
              <div :style="'min-height: 200px;'" class="flex-grow-1  d-flex flex-column position-relative mt-2">
                <div style="overflow:auto; position:absolute; top: 0; left:0; right:0; bottom:0" class="card card-no-margin">
                  <m-list-simple
                    v-if="plannings.length > 0"
                    ref="commandelist"
                    :dynamic="true"
                    :items="plannings"
                    item-value="cp_id"
                    :total="plannings.length"
                  >
                    <template v-slot:ths="{}">
                      <th width="130px">
                        <span class="pointer">Type</span>
                      </th>
                      <th width="100px">
                        <span class="pointer">Etat</span>
                      </th>
                      <th width="auto">
                        <span class="pointer">Prochain affichage</span>
                      </th>
                      <th width="auto">
                        <span class="pointer">Actions</span>
                      </th>
                    </template>
                    <template v-slot:tds="{ item }">
                      <td>{{ item.cp_planning_type === 1 ? "Journalier" : "Hebdomadaire" }}</td>
                      <td>
                        <span :style="{ 'font-weight': 'bold', color: item.cp_disabled ? 'red' : 'green' }">{{
                          item.cp_disabled ? "Inactif" : "Actif"
                        }}</span>
                      </td>
                      <td>
                        {{ item.cp_next_planning }}
                      </td>
                      <td>
                        <icon name="edit" class="mr-2" @click="openPlanningEditor(item.cp_id)"></icon>
                        <icon :name="item.cp_disabled === true ? 'check' : 'ban'" class="mr-2" @click="setPlanningStatus(item)"></icon>
                        <icon name="trash-alt" @click="deletePlanning(item)"></icon>
                      </td>
                    </template>
                  </m-list-simple>
                  <div v-else>
                    <p class="text-center font-italic mt-5">
                      Vous n'avez aucun planning. <br />
                      Vous pouvez en créer en cliquant sur le bouton "+ Nouveau".
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer d-flex flex-row justify-content-between">
              <div></div>
              <div>
                <button type="button" class="btn btn-secondary" @click="cancelWin">Fermer</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <modal-planning-editor
      v-if="modalPlanningEditor"
      v-model="modalPlanningEditor"
      :cp_id="selectedPlanning"
      :es_id="es_id"
      @reloadEvents="getPlannings()"
    ></modal-planning-editor>
  </section>
</template>
<script>
export default {
  name: "ModalPlanningList",
  components: {},
  props: {
    value: {
      default: false,
      type: Boolean
    },
    es_id: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      plannings: [],
      modalPlanningEditor: false,
      selectedPlanning: -1
    };
  },
  async mounted() {
    await this.getPlannings();
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },

    cancelWin() {
      //this.resetData();
      this.$emit("input", false);
    },

    async getPlannings() {
      try {
        let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/calendar/plannings/establishment/" + this.es_id);
        if (response.data.meta.total > 0) this.plannings = response.data.data;
        else this.plannings = [];
      } catch (error) {
        this.$store.dispatch("showDialogError", "Une erreur est survenue : " + error);
      }
    },

    async setPlanningStatus(planning) {
      try {
        planning.cp_disabled = !planning.cp_disabled;
        let response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/calendar/plannings/" + planning.cp_id, planning);
        if (response.data.success === false) this.$store.dispatch("showDialogError", "Une erreur est survenue");
        await this.getPlannings();
      } catch (error) {
        this.$store.dispatch("showDialogError", "Une erreur est survenue : " + error);
      }
    },

    async deletePlanning(planning) {
      try {
        let response = await this.$axios.delete(this.$config.server_url + "/extranet/2.0/calendar/plannings/" + planning.cp_id);
        if (response.data.success === false) this.$store.dispatch("showDialogError", "Une erreur est survenue");
        await this.getPlannings();
      } catch (error) {
        this.$store.dispatch("showDialogError", "Une erreur est survenue : " + error);
      }
    },

    async openPlanningEditor(cp_id) {
      this.selectedPlanning = cp_id;
      this.modalPlanningEditor = true;
    }
  }
};
</script>
