var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-75" }, [
    _c(
      "div",
      { staticClass: "row h-75" },
      [
        _c("m-message-dialog", {
          attrs: {
            title: "Erreur de communication",
            text: _vm.$store.state.dialogErrTxt,
            width: "70%"
          },
          on: {
            close: function($event) {
              return _vm.$store.dispatch("showDialogError", false)
            }
          },
          model: {
            value: _vm.$store.state.dialogErr,
            callback: function($$v) {
              _vm.$set(_vm.$store.state, "dialogErr", $$v)
            },
            expression: "$store.state.dialogErr"
          }
        }),
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            {
              ref: "videoContainer",
              staticClass: "videoContainer h-100",
              class: { getfullscreen: _vm.getFullScreen },
              attrs: { id: "bistriVideos" }
            },
            [
              _c("v-spinner", { ref: "waitingSpinner" }),
              _c(
                "div",
                { staticClass: "h-100", staticStyle: { position: "relative" } },
                [
                  _vm.isFullScreen
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isOnlyAudio,
                              expression: "!isOnlyAudio"
                            }
                          ],
                          staticClass: "btn-agrandir",
                          staticStyle: {
                            position: "absolute",
                            top: "5%",
                            right: "5%",
                            "z-index": "10000"
                          },
                          on: {
                            click: function($event) {
                              return _vm.switchFullScreen()
                            }
                          }
                        },
                        [
                          _c("icon", { attrs: { name: "compress-arrows-alt" } })
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.onlyaudio && !_vm.isOnlyAudio
                    ? _c("div", {
                        ref: "videoremote1",
                        staticClass: "videoremote1",
                        style:
                          _vm.visioConnected === 1
                            ? "background-color:black;background-image:unset"
                            : "",
                        attrs: { id: "videoremote1" }
                      })
                    : _c(
                        "div",
                        {
                          staticClass:
                            "h-100 w-100 d-flex justify-content-center align-items-center"
                        },
                        [
                          _c("v-audio-waves", {
                            ref: "audiowaves",
                            staticClass: " h-100 w-50 justify-content-center"
                          }),
                          _c("div", {
                            ref: "audioremote1",
                            staticClass:
                              "h-100 d-flex justify-content-center align-items-center",
                            attrs: { id: "audioremote1", autoplay: "" }
                          })
                        ],
                        1
                      )
                ]
              ),
              !_vm.onlyaudio && !_vm.isOnlyAudio
                ? _c("div", {
                    ref: "myvideo",
                    staticClass: "janus-myvideo h-100",
                    attrs: { id: "myvideo" }
                  })
                : _c("div", {
                    ref: "myaudio",
                    staticClass: "myaudio h-100",
                    attrs: { id: "myaudio" }
                  }),
              !_vm.isFullScreen
                ? _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isOnlyAudio,
                          expression: "!isOnlyAudio"
                        }
                      ],
                      staticClass: "btn-agrandir",
                      staticStyle: { top: "0" },
                      on: {
                        click: function($event) {
                          return _vm.switchFullScreen()
                        }
                      }
                    },
                    [_c("icon", { attrs: { name: "expand-arrows-alt" } })],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ])
      ],
      1
    ),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "button",
          {
            ref: "button_bistri_hangup",
            staticClass: "btn btn-danger btn-racrocher",
            attrs: { disabled: false },
            on: {
              click: function($event) {
                return _vm.bistriHangup(true)
              }
            }
          },
          [
            _c("icon", {
              staticClass: "iconMarginRight",
              attrs: { name: "phone-slash" }
            }),
            _vm._v(" " + _vm._s(_vm.switchLang("hangup")) + " ")
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }