<template>
  <section>
    <div class="d-flex align-items-center">
      <h1>Calendrier</h1>
    </div>
    <div class="calendar-container card pt-0">
      <div class="row mb-3 align-items-end">
        <div class="col-3">
          <m-form-select
            v-model="selectedEstablishment"
            :name="$Utils.randomstring('establishments')"
            :items="establishments"
            :clearable="false"
            label="Etablissement"
            :disabled="establishments.length <= 1 ? true : false"
          ></m-form-select>
        </div>
        <div class="col-3">
          <m-form-select
            v-model="selectedBox"
            :name="$Utils.randomstring('establishments')"
            :items="boxes"
            :clearable="false"
            label="Boxes"
          ></m-form-select>
        </div>
        <div class="offset-4 col-2 text-right">
          <button class="btn btn-primary" @click="modalPlanningList = true">
            Plannings
          </button>
        </div>
      </div>

      <FullCalendar :options="calendarOptions" />
    </div>
    <modal-event-editor
      v-if="modalEventEditor"
      :event_id="this.eventId"
      :dateStr="dateStr"
      :recurrentEvents="recurrentEvents"
      :es_id="selectedEstablishment"
      v-model="modalEventEditor"
      @reloadEvents="getEvents(selectedBox)"
    ></modal-event-editor>
    <modal-event-changes
      v-if="modalEventChanges"
      v-model="modalEventChanges"
      :eventsChanges="eventsChanges"
      @reloadEvents="getEvents(selectedBox)"
    ></modal-event-changes>
    <modal-planning-list v-if="modalPlanningList" v-model="modalPlanningList" :es_id="selectedEstablishment"></modal-planning-list>
  </section>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import frLocale from "@fullcalendar/core/locales/fr";
import dayGridPlugin from "@fullcalendar/daygrid";

export default {
  name: "Calendar",
  components: {
    FullCalendar
  },
  data() {
    return {
      calendarOptions: {
        plugins: [interactionPlugin, timeGridPlugin, dayGridPlugin],
        initialView: "timeGridWeek",
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        eventDrop: this.handleEventDrop,
        locale: frLocale,
        editable: false,
        eventStartEditable: true,
        allDaySlot: false,
        events: [],
        firstHour: 6,
        eventTextColor: "black",
        headerToolbar: {
          left: "title",
          center: "",
          right: "dayGridMonth timeGridWeek timeGridDay today prev,next"
        }
      },
      savedActivities: [],
      modalEventEditor: false,
      modalEventChanges: false,
      modalPlanningList: false,
      eventsChanges: {
        events: [],
        recurrent: false,
        newStartDate: "",
        newEndDate: ""
      },
      eventId: 0,
      recurrentEvents: [],
      dateStr: "",
      establishments: [],
      selectedEstablishment: 0,
      boxes: [],
      selectedBox: 1
    };
  },
  watch: {
    selectedEstablishment(value) {
      this.loadBoxes();
      this.getEvents();
    },
    selectedBox(value) {
      this.getEvents(value);
    }
  },
  async mounted() {
    let establishments = this.$store.state.user.establishments.map(establishement => {
      return {
        text: establishement.es_name,
        value: establishement.es_id
      };
    });
    this.establishments = establishments;
    this.selectedEstablishment = 0;
    //this.establishments = [{ text: "test", value: 1 }];
    if (this.establishments.length) this.selectedEstablishment = this.establishments[0].value;
  },
  methods: {
    handleDateClick(arg) {
      this.recurrentEvents = [];
      this.eventId = -1;
      // this.dateStr = arg.dateStr.substr(0, arg.dateStr.length - 6); // convert avec moment
      this.dateStr = this.$moment(arg.dateStr).format("YYYY-MM-DD HH:mm:ss");
      this.modalEventEditor = true;
    },
    handleEventClick(arg) {
      let minimumDate = this.$moment(arg.event.start).format("YYYY-MM-DD");
      this.eventId = parseInt(arg.event.id);
      this.recurrentEvents = this.calendarOptions.events.filter(
        event => event.start >= minimumDate && event.extendedProps.group_uuid === arg.event.extendedProps.group_uuid
      );
      this.dateStr = "";
      this.modalEventEditor = true;
    },
    handleEventDrop(arg) {
      let newDate = this.$moment(arg.event.start).format("YYYY-MM-DD");
      let oldDate = this.$moment(arg.oldEvent.start).format("YYYY-MM-DD");

      if (arg.event.extendedProps.recurrence_type === 2 && newDate !== oldDate) {
        this.eventsChanges.events = [];
        this.eventsChanges.events.push({
          backgroundColor: arg.event.backgroundColor,
          end: arg.event.end,
          start: arg.event.start,
          extendedProps: {
            group_uuid: arg.event.extendedProps.group_uuid,
            recurrence_type: arg.event.extendedProps.recurrence_type
          },
          id: arg.event.id,
          title: arg.event.title
        });
      } else {
        let minimumDate = this.$moment(arg.oldEvent.start).format("YYYY-MM-DD");
        this.eventsChanges.events = this.calendarOptions.events.filter(
          event => event.start >= minimumDate && event.extendedProps.group_uuid === arg.oldEvent.extendedProps.group_uuid
        );
      }

      if (this.eventsChanges.events.length > 1) this.eventsChanges.recurrent = true;
      else this.eventsChanges.recurrent = false;

      this.eventsChanges.newStartDate = arg.event.start;
      this.eventsChanges.newEndDate = arg.event.end;
      this.modalEventChanges = true;
    },
    async getEvents(boxId) {
      this.modalEventEditor = false;
      this.calendarOptions.events = [];
      let filteredBoxes;
      try {
        let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/calendar/events/", {
          params: {
            es_id: this.selectedEstablishment
          }
        });
        if (response.data.error) {
          this.$store.dispatch("showDialogError", response.data.err.message);
          return;
        }

        if (boxId && boxId !== 0) {
          filteredBoxes = response.data.data.filter(event => {
            return event.ce_boxes.includes(boxId);
          });
        } else filteredBoxes = response.data.data;

        filteredBoxes.forEach(event => {
          this.calendarOptions.events.push({
            title: event.ca_id.ca_activity,
            start: event.ce_startTime,
            end: event.ce_endTime ? event.ce_endTime : "",
            id: event.ce_id,
            backgroundColor: event.ca_id.ca_color,
            extendedProps: {
              group_uuid: event.ce_group_uuid,
              recurrence_type: event.ce_recurrence_type
            }
          });
        });
        this.$store.dispatch("setCalendarEvents", response.data.data);
      } catch (error) {
        console.error(error);
      }
    },
    async loadBoxes() {
      this.boxes = [];
      let params = {
        bo_id: ">0"
      };
      if (this.selectedEstablishment) params.establishment = this.selectedEstablishment;

      this.cancelAxiosLoadContacts && this.cancelAxiosLoadContacts();
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/endusers", {
        params,
        cancelToken: new this.$axios.CancelToken(c => {
          this.cancelAxiosLoadContacts = c;
        })
      });
      if (!response || !response.data) return;

      this.boxes.push({
        text: "Toutes les boxes",
        value: 0
      });
      response.data.data.forEach(box => {
        this.boxes.push({
          text: box.en_fullname,
          value: box.bo_id.bo_id
        });
      });
      this.selectedBox = this.boxes[0].value;
    }
  }
};
</script>

<style lang="scss" scoped></style>
