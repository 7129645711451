var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: _vm.value ? "show" : "hide",
        style: _vm.value
          ? "display:block;background-color:#3333337a;"
          : "display:none;",
        attrs: { tabindex: "-1", role: "dialog", "aria-hidden": "true" }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-scrollable",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "div",
                {
                  staticClass: "modal-header d-flex flex-row align-items-center"
                },
                [
                  _c("div", [
                    _c("h1", [_vm._v(" Création d'une commande ")]),
                    _c("p", [
                      _vm._v(
                        "Pour le contact : " +
                          _vm._s(
                            _vm._f("formatContactNameSimple")(
                              _vm.row_co,
                              "co_invoice_"
                            )
                          )
                      )
                    ])
                  ])
                ]
              ),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c("h6", [
                    _c(
                      "span",
                      { staticClass: "number-title" },
                      [_c("icon", { attrs: { name: "shopping-cart" } })],
                      1
                    ),
                    _vm._v("Votre commande ")
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("m-list-simple", {
                        attrs: {
                          dynamic: true,
                          items: _vm.orderLines,
                          "item-value": "us_id",
                          "item-height": 30
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "ths",
                            fn: function(ref) {
                              return [
                                _c(
                                  "th",
                                  { staticStyle: { "min-width": "50px" } },
                                  [
                                    _c("span", { staticClass: "pointer" }, [
                                      _vm._v("Offre")
                                    ])
                                  ]
                                ),
                                _c("th", {}, [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Produit")
                                  ])
                                ]),
                                _c("th", {}, [
                                  _c("span", { staticClass: "pointer" }, [
                                    _vm._v("Utilisateur final")
                                  ])
                                ]),
                                _c(
                                  "th",
                                  { staticStyle: { "min-width": "30px" } },
                                  [_vm._v("Éditer")]
                                )
                              ]
                            }
                          },
                          {
                            key: "tds",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("td", [
                                  _vm._v(" " + _vm._s(item.offer.of_name) + " ")
                                ]),
                                _c(
                                  "td",
                                  _vm._l(item.offer.offersproducts, function(
                                    pr
                                  ) {
                                    return _c("div", { key: pr.ofpr_id }, [
                                      _vm._v(_vm._s(pr.pr_id.pr_name) + ",")
                                    ])
                                  }),
                                  0
                                ),
                                _c("td", {}, [
                                  _vm._v(
                                    " " + _vm._s(item.enduser.en_fullname) + " "
                                  )
                                ]),
                                _c("td", {}, [
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.editOrderLine(item)
                                        }
                                      }
                                    },
                                    [_c("icon", { attrs: { name: "edit" } })],
                                    1
                                  )
                                ])
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row " }, [
                  _c("h6", [
                    _c("span", { staticClass: "number-title" }, [_vm._v("1")]),
                    _vm._v("Choisir l'offre")
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "row d-flex flex-row align-items-end" },
                  [
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("m-form-combobox", {
                          attrs: {
                            label: "L'offre",
                            "label-position": "top",
                            name: _vm.$Utils.randomstring("offerSelected"),
                            "store-url":
                              _vm.$config.server_url + "/extranet/2.0/offers",
                            "store-params": {},
                            "item-value": "of_id",
                            "item-text": "of_name"
                          },
                          model: {
                            value: _vm.offerSelected,
                            callback: function($$v) {
                              _vm.offerSelected = $$v
                            },
                            expression: "offerSelected"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.isSubscriptionOffer
                      ? _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("m-form-date", {
                              attrs: {
                                label: "Date de début de l'abonnement",
                                name: _vm.$Utils.randomstring(
                                  "or_subscription_date_start"
                                ),
                                rules: [_vm.$Validation.mandatory]
                              },
                              model: {
                                value: _vm.newOrder.or_subscription_date_start,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.newOrder,
                                    "or_subscription_date_start",
                                    $$v
                                  )
                                },
                                expression:
                                  "newOrder.or_subscription_date_start"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                ),
                _c("div", { staticClass: "row mt-2" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        on: {
                          click: function($event) {
                            _vm.showOffer = !_vm.showOffer
                          }
                        }
                      },
                      [_vm._v("Afficher l'offre")]
                    )
                  ])
                ]),
                _vm.showOffer && _vm.offerSelected.of_id
                  ? _c("div", { staticClass: "row mt-3" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("div", [
                          _vm._v(
                            "Description de l'offre : " +
                              _vm._s(_vm.offerSelected.of_description)
                          )
                        ]),
                        _c("div", [
                          _vm._v(
                            "Engagement : " +
                              _vm._s(_vm.offerSelected.of_commit_period)
                          )
                        ]),
                        _c("div", [
                          _vm._v(
                            "Paiement de départ : " +
                              _vm._s(_vm.offerSelected.of_deposit)
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm.isSubscriptionOffer
                  ? _c("div", [
                      _c("div", { staticClass: "row mt-5" }, [
                        _c("h6", [
                          _c("span", { staticClass: "number-title" }, [
                            _vm._v("2")
                          ]),
                          _vm._v(" Renseigner l'utilisateur final")
                        ])
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("m-form-text", {
                              attrs: {
                                label: "Nom complet (nom et prénom) *",
                                type: "text",
                                name: _vm.$Utils.randomstring("en_fullname"),
                                rules: [_vm.$Validation.mandatory]
                              },
                              model: {
                                value: _vm.newOrder.enduser.en_fullname,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.newOrder.enduser,
                                    "en_fullname",
                                    $$v
                                  )
                                },
                                expression: "newOrder.enduser.en_fullname"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("m-form-date", {
                              attrs: {
                                label: "Date de naissance",
                                name: _vm.$Utils.randomstring("en_birthday")
                              },
                              model: {
                                value: _vm.newOrder.enduser.en_birthday,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.newOrder.enduser,
                                    "en_birthday",
                                    $$v
                                  )
                                },
                                expression: "newOrder.enduser.en_birthday"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("m-form-text", {
                              attrs: {
                                label: "Webapp identifiant *",
                                type: "text",
                                name: _vm.$Utils.randomstring(
                                  "en_webapp_login"
                                ),
                                rules: [_vm.$Validation.mandatory]
                              },
                              model: {
                                value: _vm.newOrder.enduser.en_webapp_login,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.newOrder.enduser,
                                    "en_webapp_login",
                                    $$v
                                  )
                                },
                                expression: "newOrder.enduser.en_webapp_login"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("m-form-text", {
                              attrs: {
                                label: "Webapp mot de passe *",
                                type: "text",
                                name: _vm.$Utils.randomstring(
                                  "en_webapp_password"
                                ),
                                rules: [_vm.$Validation.mandatory]
                              },
                              model: {
                                value: _vm.newOrder.enduser.en_webapp_password,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.newOrder.enduser,
                                    "en_webapp_password",
                                    $$v
                                  )
                                },
                                expression:
                                  "newOrder.enduser.en_webapp_password"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "row mt-5" }, [
                        _c("h6", [
                          _c("span", { staticClass: "number-title" }, [
                            _vm._v("3")
                          ]),
                          _vm._v("Vérifier les informations du colis")
                        ])
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12 mt-4" }, [
                          _c("h5", [_vm._v("Adresse de livraison")])
                        ])
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-2" },
                          [
                            _c("m-form-text", {
                              attrs: {
                                label: "Civilité",
                                type: "text",
                                name: _vm.$Utils.randomstring(
                                  "pa_shipping_civility"
                                ),
                                rules: [_vm.$Validation.mandatory]
                              },
                              model: {
                                value: _vm.newOrder.parcel.pa_shipping_civility,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.newOrder.parcel,
                                    "pa_shipping_civility",
                                    $$v
                                  )
                                },
                                expression:
                                  "newOrder.parcel.pa_shipping_civility"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-5" },
                          [
                            _c("m-form-text", {
                              attrs: {
                                label: "Nom",
                                type: "text",
                                name: _vm.$Utils.randomstring(
                                  "pa_shipping_name"
                                ),
                                rules: [_vm.$Validation.mandatory]
                              },
                              model: {
                                value: _vm.newOrder.parcel.pa_shipping_name,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.newOrder.parcel,
                                    "pa_shipping_name",
                                    $$v
                                  )
                                },
                                expression: "newOrder.parcel.pa_shipping_name"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-5" },
                          [
                            _c("m-form-text", {
                              attrs: {
                                label: "Prénom",
                                type: "text",
                                name: _vm.$Utils.randomstring(
                                  "pa_shipping_firstname"
                                ),
                                rules: [_vm.$Validation.mandatory]
                              },
                              model: {
                                value:
                                  _vm.newOrder.parcel.pa_shipping_firstname,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.newOrder.parcel,
                                    "pa_shipping_firstname",
                                    $$v
                                  )
                                },
                                expression:
                                  "newOrder.parcel.pa_shipping_firstname"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("m-form-text", {
                              attrs: {
                                label: "Adresse 1",
                                type: "text",
                                name: _vm.$Utils.randomstring(
                                  "pa_shipping_address1"
                                ),
                                rules: [_vm.$Validation.mandatory]
                              },
                              model: {
                                value: _vm.newOrder.parcel.pa_shipping_address1,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.newOrder.parcel,
                                    "pa_shipping_address1",
                                    $$v
                                  )
                                },
                                expression:
                                  "newOrder.parcel.pa_shipping_address1"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c("m-form-text", {
                              attrs: {
                                label: "Adresse 2",
                                type: "text",
                                name: _vm.$Utils.randomstring(
                                  "pa_shipping_address2"
                                ),
                                rules: [_vm.$Validation.mandatory]
                              },
                              model: {
                                value: _vm.newOrder.parcel.pa_shipping_address2,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.newOrder.parcel,
                                    "pa_shipping_address2",
                                    $$v
                                  )
                                },
                                expression:
                                  "newOrder.parcel.pa_shipping_address2"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-2" },
                          [
                            _c("m-form-text", {
                              attrs: {
                                label: "Code postal",
                                type: "text",
                                name: _vm.$Utils.randomstring(
                                  "pa_shipping_zip"
                                ),
                                rules: [_vm.$Validation.mandatory]
                              },
                              model: {
                                value: _vm.newOrder.parcel.pa_shipping_zip,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.newOrder.parcel,
                                    "pa_shipping_zip",
                                    $$v
                                  )
                                },
                                expression: "newOrder.parcel.pa_shipping_zip"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-5" },
                          [
                            _c("m-form-text", {
                              attrs: {
                                label: "Ville",
                                type: "text",
                                name: _vm.$Utils.randomstring(
                                  "pa_shipping_city"
                                ),
                                rules: [_vm.$Validation.mandatory]
                              },
                              model: {
                                value: _vm.newOrder.parcel.pa_shipping_city,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.newOrder.parcel,
                                    "pa_shipping_city",
                                    $$v
                                  )
                                },
                                expression: "newOrder.parcel.pa_shipping_city"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-5" },
                          [
                            _c("m-form-text", {
                              attrs: {
                                label: "Pays",
                                type: "text",
                                name: _vm.$Utils.randomstring(
                                  "pa_shipping_country"
                                ),
                                rules: [_vm.$Validation.mandatory]
                              },
                              model: {
                                value: _vm.newOrder.parcel.pa_shipping_country,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.newOrder.parcel,
                                    "pa_shipping_country",
                                    $$v
                                  )
                                },
                                expression:
                                  "newOrder.parcel.pa_shipping_country"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "row d-flex flex-row align-items-end" },
                        [
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("m-form-text", {
                                attrs: {
                                  label: "Téléphone",
                                  type: "text",
                                  name: _vm.$Utils.randomstring(
                                    "pa_shipping_tel"
                                  ),
                                  rules: [_vm.$Validation.mandatory]
                                },
                                model: {
                                  value: _vm.newOrder.parcel.pa_shipping_tel,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.newOrder.parcel,
                                      "pa_shipping_tel",
                                      $$v
                                    )
                                  },
                                  expression: "newOrder.parcel.pa_shipping_tel"
                                }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("button", { staticClass: "btn btn-primary" }, [
                              _vm._v("Vérifier l'adresse")
                            ])
                          ])
                        ]
                      ),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12 mt-4" }, [
                          _c("h5", [_vm._v("Informations complémentaires")])
                        ])
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c("m-form-text", {
                              attrs: {
                                label: "Nom de l'envoi",
                                type: "text",
                                name: _vm.$Utils.randomstring("pa_name"),
                                rules: [_vm.$Validation.mandatory]
                              },
                              model: {
                                value: _vm.newOrder.pa_name,
                                callback: function($$v) {
                                  _vm.$set(_vm.newOrder, "pa_name", $$v)
                                },
                                expression: "newOrder.pa_name"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "row mb-5" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c("m-form-text", {
                              attrs: {
                                label: "Commentaire",
                                type: "textarea",
                                rows: 4,
                                name: _vm.$Utils.randomstring("pa_comment"),
                                rules: [_vm.$Validation.mandatory]
                              },
                              model: {
                                value: _vm.newOrder.pa_comment,
                                callback: function($$v) {
                                  _vm.$set(_vm.newOrder, "pa_comment", $$v)
                                },
                                expression: "newOrder.pa_comment"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "row mb-4" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              on: {
                                click: function($event) {
                                  return _vm.validLine(false)
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    this.newOrder.index
                                      ? "Éditer la ligne"
                                      : "Ajouter à la commande"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "modal-footer d-flex flex-row justify-content-between mt-5"
                },
                [
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button" },
                        on: { click: _vm.cancelWin }
                      },
                      [_vm._v(" Annuler toute la commande ")]
                    )
                  ]),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-mouveal ml-2",
                        attrs: {
                          type: "button",
                          disabled: !_vm.orderLines.length
                        },
                        on: {
                          click: function($event) {
                            return _vm.saveWin(false)
                          }
                        }
                      },
                      [_vm._v(" Valider la commande ")]
                    )
                  ])
                ]
              )
            ])
          ]
        ),
        _c("m-message-dialog", {
          attrs: { title: "Erreur", text: _vm.dialogErrTxt },
          model: {
            value: _vm.dialogErr,
            callback: function($$v) {
              _vm.dialogErr = $$v
            },
            expression: "dialogErr"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }