<template>
  <div
    class="modal"
    :class="value ? 'show' : 'hide'"
    :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex flex-row align-items-center">
          <div>
            <h1>Afficher les détails du mode de paiement</h1>
          </div>
        </div>
        <div class="modal-body ">
          <div class="row justify-content-center">
            <div class="col-md-6">
              <m-form-text label="Nom" v-model="payment.pm_name" :readonly="true"></m-form-text>
            </div>
            <div class="col-md-6">
              <m-form-text label="Méthode de paiement" v-model="payment.pm_method" :readonly="true"></m-form-text>
            </div>
            <div class="col-md-6">
              <m-form-text :readonly="true" label="État du paiement" :value="payment.pm_active ? 'Paiement utilisé' : 'Paiement non utilisé'">
              </m-form-text>
            </div>
          </div>
          <hr />
          <div class="row justify-content-center ">
            <div v-if="payment.pm_method === 'rib'" class="col-md-5  m-1">
              <h5>Détails RIB</h5>
              <m-form-text :readonly="true" :label="'Nom'" :value="payment.pm_rib_name"> </m-form-text>
              <m-form-text :readonly="true" :label="'IBAN'" :value="payment.pm_rib_iban"> </m-form-text>
              <m-form-text :readonly="true" :label="'BIC'" :value="payment.pm_rib_bic"> </m-form-text>
              <div v-if="payment.pm_gocardless_ids" class="alert alert-info">
                <h6>GOCARDLESS</h6>
                <div v-for="(item, index) in payment.pm_gocardless_ids" :key="index">
                  <m-form-text :readonly="true" :label="index" :value="payment.pm_gocardless_ids[index]"> </m-form-text>
                </div>
              </div>
            </div>
            <div v-else class="col-md-5  m-1">
              <h5>Détails Stripe</h5>
              <div v-for="(item, index) in payment.pm_stripe_ids" :key="index">
                <m-form-text :readonly="true" :label="index" :value="payment.pm_stripe_ids[index]"> </m-form-text>
              </div>
            </div>
            <div class="col-md-5  m-1">
              <h5 class="">Détails Méthode de paiement</h5>
              <div v-for="(item, index) in payment.pm_method_details" :key="index">
                <div class="alert alert-info" v-if="typeof payment.pm_method_details[index] === 'object'">
                  <h6>{{ index }}</h6>
                  <div v-for="(item2, index2) in payment.pm_method_details[index]" :key="index2">
                    <div class="pl-5" v-if="typeof payment.pm_method_details[index][index2] === 'object' && payment.pm_method_details[index][index2]">
                      <div v-for="(item3, index3) in payment.pm_method_details[index][index2]" :key="index3">
                        <m-form-text
                          class="pl-5"
                          :readonly="true"
                          :label="index2"
                          :value="
                            typeof payment.pm_method_details[index][index2][index3] === 'boolean'
                              ? payment.pm_method_details[index][index2][index3] * 1
                              : typeof payment.pm_method_details[index][index2][index3] === 'object'
                              ? JSON.stringify(payment.pm_method_details[index][index2][index3])
                              : payment.pm_method_details[index][index2][index3]
                          "
                        >
                        </m-form-text>
                      </div>
                    </div>
                    <m-form-text
                      v-else
                      class="pl-5"
                      :readonly="true"
                      :label="index2"
                      :value="
                        typeof payment.pm_method_details[index][index2] === 'boolean'
                          ? payment.pm_method_details[index][index2] * 1
                          : typeof payment.pm_method_details[index][index2] === 'object'
                          ? JSON.stringify(payment.pm_method_details[index][index2])
                          : payment.pm_method_details[index][index2]
                      "
                    >
                    </m-form-text>
                  </div>
                </div>
                <m-form-text v-else :readonly="true" :label="index" :value="payment.pm_method_details[index]"> </m-form-text>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer d-flex flex-row justify-content-between">
          <div>
            <button type="button" class="btn btn-secondary" @click="winClose">Fermer</button>
          </div>
        </div>
      </div>
    </div>
    <!-- <m-message-dialog v-model="dialogErr" title="Erreur" :text="dialogErrTxt"></m-message-dialog> -->
  </div>
</template>

<script>
export default {
  name: "PaymentDetails",
  props: {
    payment: Object,
    value: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      color: "white",
      totalText: "",
      currentText: "",
      stop: false
    };
  },
  methods: {
    winClose() {
      this.$emit("winClose");
    }
  },

  mounted() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.remote-terminal {
  background-color: black;
  color: white;
  padding: 3%;
  border-radius: 2%;
  overflow: auto;
}
h6 {
  color: grey;
  font-size: smaller;
}
</style>
