var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "d-flex w-100" },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-column w-100", attrs: { id: "boxes" } },
        [
          _c("div", { staticClass: "d-flex header-emotivi" }, [
            _vm._m(0),
            _c(
              "div",
              {
                staticClass:
                  "flex-grow-1 d-flex justify-content-end align-items-end",
                staticStyle: { "margin-top": "-40px" }
              },
              [
                _c("m-form-checkbox", {
                  attrs: {
                    label: "Non-actif",
                    name: _vm.$Utils.randomstring("active")
                  },
                  on: { input: _vm.loadMaterials },
                  model: {
                    value: _vm.filterNotActive,
                    callback: function($$v) {
                      _vm.filterNotActive = $$v
                    },
                    expression: "filterNotActive"
                  }
                }),
                _c("m-form-text", {
                  staticClass: "ml-3",
                  attrs: {
                    label: "Rechercher",
                    "label-position": "top",
                    name: "search",
                    autocomplete: ""
                  },
                  on: { input: _vm.loadMaterials },
                  model: {
                    value: _vm.filterText,
                    callback: function($$v) {
                      _vm.filterText = $$v
                    },
                    expression: "filterText"
                  }
                }),
                _c("m-form-combobox", {
                  staticClass: "ml-3",
                  attrs: {
                    width: 270,
                    label: "Rechercher par produit",
                    "label-position": "top",
                    name: _vm.$Utils.randomstring("filter_product"),
                    "store-url":
                      _vm.$config.server_url + "/extranet/2.0/products",
                    "store-params": {},
                    "item-value": "pr_id",
                    "item-text": "pr_name"
                  },
                  on: { input: _vm.loadMaterials },
                  model: {
                    value: _vm.filterProduct,
                    callback: function($$v) {
                      _vm.filterProduct = $$v
                    },
                    expression: "filterProduct"
                  }
                }),
                _c(
                  "button",
                  {
                    staticClass: "ml-3 btn btn-sm btn-primary color-white",
                    attrs: { type: "button" },
                    on: { click: _vm.addMaterials }
                  },
                  [
                    _c("icon", {
                      staticClass: "mr-2",
                      attrs: { name: "plus", scale: "1" }
                    }),
                    _vm._v(" Nouveau ")
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "flex-grow-1 overflow-hidden d-flex w-100" },
            [
              _c(
                "div",
                { staticClass: "card card-no-margin w-100" },
                [
                  _c("m-list-simple", {
                    attrs: { items: _vm.materials, "item-value": "ma_id" },
                    on: { itemclick: _vm.editMaterials },
                    scopedSlots: _vm._u([
                      {
                        key: "ths",
                        fn: function(ref) {
                          return [
                            _c("th", { staticStyle: { width: "90px" } }),
                            _c(
                              "th",
                              {
                                staticStyle: { "min-width": "50px" },
                                on: {
                                  click: function($event) {
                                    return _vm.sortItems(["ma_name"])
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "pointer" }, [
                                  _vm._v("Désignation")
                                ])
                              ]
                            ),
                            _c(
                              "th",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.sortItems(["ma_email"])
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "pointer" }, [
                                  _vm._v("Stock inventaire")
                                ])
                              ]
                            ),
                            _c(
                              "th",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.sortItems(["ma_email"])
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "pointer" }, [
                                  _vm._v("Stock actuel")
                                ])
                              ]
                            ),
                            _c(
                              "th",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.sortItems(["ma_post"])
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "pointer" }, [
                                  _vm._v("Stock commandé")
                                ])
                              ]
                            ),
                            _c(
                              "th",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.sortItems(["ma_post"])
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "pointer" }, [
                                  _vm._v("Derniere commande")
                                ])
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "tds",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "td",
                              [
                                _c("vue-load-image", [
                                  _c("img", {
                                    attrs: {
                                      slot: "image",
                                      src:
                                        _vm.$config.server_url +
                                        "/extranet/2.0/materials/image/" +
                                        item.ma_id +
                                        "/70/50?d=" +
                                        new Date().getTime() +
                                        "&token=" +
                                        _vm.$store.state.accesstoken
                                    },
                                    slot: "image"
                                  }),
                                  _c("img", {
                                    attrs: {
                                      slot: "preloader",
                                      src: "images/image-loader70.gif"
                                    },
                                    slot: "preloader"
                                  }),
                                  _c(
                                    "div",
                                    { attrs: { slot: "error" }, slot: "error" },
                                    [_vm._v("pas d'image")]
                                  )
                                ])
                              ],
                              1
                            ),
                            _c("td", [
                              _vm._v(" " + _vm._s(item.ma_name) + " ")
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "d-flex flex-column" }, [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      item.stockline_inventory
                                        ? item.stockline_inventory.st_quantity
                                        : ""
                                    )
                                  )
                                ]),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      item.stockline_inventory
                                        ? _vm
                                            .$moment(
                                              item.stockline_inventory.createdAt
                                            )
                                            .format("DD/MM/YYYY")
                                        : ""
                                    )
                                  )
                                ])
                              ])
                            ]),
                            _c("td", [
                              _vm._v(
                                " " + _vm._s(item.ma_stock_available) + " "
                              )
                            ]),
                            _c("td", [
                              _vm._v(" " + _vm._s(item.ma_stock_ordered) + " ")
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "d-flex flex-column" }, [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.stockline_order
                                          ? item.stockline_order.st_quantity
                                          : ""
                                      ) +
                                      " "
                                  ),
                                  item.stockline_order.createdAt
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.stockline_order &&
                                              item.stockline_order.createdAt
                                              ? "(le " +
                                                  _vm
                                                    .$moment(
                                                      item.stockline_order
                                                        .createdAt
                                                    )
                                                    .format("DD/MM/YYYY") +
                                                  ")"
                                              : ""
                                          )
                                        )
                                      ])
                                    : _c("span", [_vm._v("-")])
                                ]),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      item.stockline_order
                                        ? item.stockline_order.st_provider_name
                                        : ""
                                    )
                                  )
                                ])
                              ])
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ]
          )
        ]
      ),
      _c("materials-win-edit", {
        attrs: { ma_id: _vm.itemSelected.ma_id },
        on: { materialSaved: _vm.loadMaterials },
        model: {
          value: _vm.dialog,
          callback: function($$v) {
            _vm.dialog = $$v
          },
          expression: "dialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex align-items-center" }, [
      _c("h1", [_vm._v("Gestion des stocks matériel")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }