var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "d-flex boxes" },
    [
      _c("div", { staticClass: "d-flex flex-column", attrs: { id: "boxes" } }, [
        _c("div", { staticClass: "d-flex header-emotivi" }, [
          _c("div", { staticClass: "d-flex align-items-center" }, [
            _c("h1", [
              _vm._v("Liste des boxes (" + _vm._s(_vm.boxes_total) + ")")
            ])
          ]),
          _c(
            "div",
            {
              staticClass:
                "flex-grow-1 d-flex flex-column justify-content-end align-items-end"
            },
            [
              _c(
                "div",
                { staticClass: "d-flex flex-wrap" },
                [
                  _c("m-form-checkbox", {
                    staticClass: "ml-2 col-form-label-sm",
                    attrs: { label: "A faire", name: "aFaire" },
                    on: {
                      input: function($event) {
                        return _vm.loadBoxes(false)
                      }
                    },
                    model: {
                      value: _vm.filtersBox.aFaire,
                      callback: function($$v) {
                        _vm.$set(_vm.filtersBox, "aFaire", $$v)
                      },
                      expression: "filtersBox.aFaire"
                    }
                  }),
                  _c("m-form-checkbox", {
                    staticClass: "ml-2 col-form-label-sm",
                    attrs: { label: "Boxes", name: "checkboxBoxes" },
                    on: {
                      input: function($event) {
                        return _vm.loadBoxes(false)
                      }
                    },
                    model: {
                      value: _vm.filtersBox.checkboxBoxes,
                      callback: function($$v) {
                        _vm.$set(_vm.filtersBox, "checkboxBoxes", $$v)
                      },
                      expression: "filtersBox.checkboxBoxes"
                    }
                  }),
                  _c("m-form-checkbox", {
                    staticClass: "ml-2 col-form-label-sm",
                    attrs: { label: "TV", name: "checkboxTV" },
                    on: {
                      input: function($event) {
                        return _vm.loadBoxes(false)
                      }
                    },
                    model: {
                      value: _vm.filtersBox.checkboxTV,
                      callback: function($$v) {
                        _vm.$set(_vm.filtersBox, "checkboxTV", $$v)
                      },
                      expression: "filtersBox.checkboxTV"
                    }
                  }),
                  _c("m-form-checkbox", {
                    staticClass: "ml-2 col-form-label-sm",
                    attrs: {
                      label: "Sans appareils",
                      name: "checkboxWithoutDevices"
                    },
                    on: {
                      input: function($event) {
                        return _vm.loadBoxes(false)
                      }
                    },
                    model: {
                      value: _vm.filtersBox.checkboxWithoutDevices,
                      callback: function($$v) {
                        _vm.$set(_vm.filtersBox, "checkboxWithoutDevices", $$v)
                      },
                      expression: "filtersBox.checkboxWithoutDevices"
                    }
                  }),
                  _c("m-form-text", {
                    staticClass: "ml-2 col-form-label-sm",
                    staticStyle: { "margin-top": "-5px" },
                    attrs: {
                      width: "150px",
                      placeholder: "Rechercher",
                      "label-position": "top",
                      name: "search",
                      autocomplete: ""
                    },
                    on: {
                      input: function($event) {
                        return _vm.loadBoxes(false)
                      }
                    },
                    model: {
                      value: _vm.filtersBox.filterText,
                      callback: function($$v) {
                        _vm.$set(_vm.filtersBox, "filterText", $$v)
                      },
                      expression: "filtersBox.filterText"
                    }
                  }),
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-secondary color-white input-filter btn-sm",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.eraseFilters()
                        }
                      }
                    },
                    [_vm._v("X")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "-12px", "z-index": "1000" } },
                [
                  _c(
                    "a",
                    {
                      staticClass: "ml-2 col-form-label-sm",
                      attrs: { href: "javascript:void(0);" },
                      on: {
                        click: function($event) {
                          _vm.moreFilters = !_vm.moreFilters
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.moreFilters ? "- de filtres" : "+ de filtres"
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "ml-2 col-form-label-sm",
                      attrs: { href: "javascript:void(0);" },
                      on: {
                        click: function($event) {
                          _vm.moreActions = !_vm.moreActions
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.moreActions ? "- d'actions" : "+ d'actions"
                          ) +
                          " "
                      )
                    ]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "more-filters",
                  style: {
                    display: _vm.moreFilters ? "flex" : "none",
                    "margin-top": "-20px"
                  }
                },
                [
                  _vm.$store.state.user.us_type === "LATEAM"
                    ? _c("m-form-text", {
                        staticClass: "ml-2 col-form-label-sm",
                        attrs: {
                          label: "Filtrer par numéro de version",
                          "label-position": "top",
                          name: _vm.$Utils.randomstring("filterVersionBox")
                        },
                        on: {
                          input: function($event) {
                            return _vm.loadBoxes("boxOnly", 1000)
                          }
                        },
                        model: {
                          value: _vm.filtersBox.filterVersionBox,
                          callback: function($$v) {
                            _vm.$set(_vm.filtersBox, "filterVersionBox", $$v)
                          },
                          expression: "filtersBox.filterVersionBox"
                        }
                      })
                    : _vm._e(),
                  _vm.$store.state.user.us_type === "LATEAM" ||
                  _vm.$store.state.user.us_type === "EMOTIVI_USER"
                    ? _c("m-form-select", {
                        staticClass: "ml-2 col-form-label-sm",
                        staticStyle: { width: "240px" },
                        attrs: {
                          label: "Groupes",
                          "label-position": "top",
                          "item-value": "gr_id",
                          name: _vm.$Utils.randomstring("filterStatus"),
                          items: _vm.$store.state.mookGroups
                        },
                        on: {
                          input: function($event) {
                            return _vm.loadBoxes(false)
                          }
                        },
                        model: {
                          value: _vm.filtersBox.filterStatus,
                          callback: function($$v) {
                            _vm.$set(_vm.filtersBox, "filterStatus", $$v)
                          },
                          expression: "filtersBox.filterStatus"
                        }
                      })
                    : _vm._e(),
                  _vm.$store.getters.checkUserAccess(
                    _vm.$router,
                    "/establishments"
                  )
                    ? _c("m-form-combobox", {
                        staticClass: "ml-2 col-form-label-sm",
                        staticStyle: { width: "200px" },
                        attrs: {
                          label: "Etablissement",
                          "label-position": "top",
                          name: _vm.$Utils.randomstring("filterEstablishment"),
                          "store-url":
                            _vm.$config.server_url +
                            "/extranet/2.0/establishments",
                          "store-params": { ignorePopulate: true },
                          "item-value": "es_id",
                          "item-text": "es_name"
                        },
                        on: {
                          input: function($event) {
                            return _vm.loadBoxes(false)
                          }
                        },
                        model: {
                          value: _vm.filtersBox.filterEstablishment,
                          callback: function($$v) {
                            _vm.$set(_vm.filtersBox, "filterEstablishment", $$v)
                          },
                          expression: "filtersBox.filterEstablishment"
                        }
                      })
                    : _vm._e(),
                  _vm.$store.state.user.us_type === "LATEAM"
                    ? _c("m-form-text", {
                        staticClass: "ml-2 col-form-label-sm",
                        attrs: {
                          label: "Filtrer par site partenaire",
                          "label-position": "top",
                          name: _vm.$Utils.randomstring("filterPartnerSite")
                        },
                        on: {
                          input: function($event) {
                            return _vm.loadBoxes("boxOnly", 1000)
                          }
                        },
                        model: {
                          value: _vm.filtersBox.filterPartnerSite,
                          callback: function($$v) {
                            _vm.$set(_vm.filtersBox, "filterPartnerSite", $$v)
                          },
                          expression: "filtersBox.filterPartnerSite"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "more-actions",
                  style: { display: _vm.moreActions ? "flex" : "none" }
                },
                [
                  _vm.$store.getters.checkUserAccess(_vm.$router, "/boxes/new")
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-primary color-white input-filter btn-sm",
                          attrs: { type: "button" },
                          on: { click: _vm.newEnduser }
                        },
                        [
                          _c("icon", {
                            staticClass: "mr-2",
                            attrs: { name: "plus", scale: "1" }
                          }),
                          _vm._v(" Nouveau ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$store.state.user.us_type === "LATEAM" ||
                  _vm.$store.state.user.us_type === "DIRECTOR" ||
                  _vm.$store.state.user.us_type === "STAFF"
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-primary color-white input-filter btn-sm",
                          attrs: { type: "button" },
                          on: { click: _vm.showModalToSendMessage }
                        },
                        [_vm._v(" Envoyer un message à tous ")]
                      )
                    : _vm._e(),
                  _vm.$store.state.user.us_type === "LATEAM" ||
                  _vm.$store.state.user.us_type === "DIRECTOR" ||
                  _vm.$store.state.user.us_type === "STAFF"
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-primary color-white input-filter btn-sm",
                          attrs: { type: "button" },
                          on: { click: _vm.showModalToScheduleMessage }
                        },
                        [_vm._v(" Planifier un message pour tous ")]
                      )
                    : _vm._e(),
                  _vm.$store.state.user.us_type === "LATEAM" ||
                  _vm.$store.state.user.us_type === "DIRECTOR" ||
                  _vm.$store.state.user.us_type === "TECHNICIAN" ||
                  _vm.$store.state.user.us_type === "STAFF"
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-primary color-white input-filter btn-sm",
                          attrs: { type: "button" },
                          on: { click: _vm.showModalToSendIrMacro }
                        },
                        [_vm._v(" Exécuter une macro infrarouge ")]
                      )
                    : _vm._e()
                ]
              )
            ]
          )
        ]),
        _c("div", { staticClass: "flex-grow-1 overflow-hidden d-flex" }, [
          _c(
            "div",
            { staticClass: "card card-no-margin" },
            [
              _c("m-list", {
                ref: "boxeslist",
                attrs: {
                  dynamic: true,
                  items: _vm.boxes,
                  "item-value": "en_id",
                  "item-height": 30,
                  limit: _vm.boxes_limit,
                  skip: _vm.boxes_skip,
                  total: _vm.boxes_total,
                  current: _vm.boxes_current,
                  bgstrip: true
                },
                on: {
                  changerange: _vm.boxesChangerange,
                  itemclick: _vm.goBoxe
                },
                scopedSlots: _vm._u([
                  {
                    key: "ths",
                    fn: function(ref) {
                      return [
                        _c(
                          "th",
                          {
                            staticStyle: { width: "150px" },
                            on: {
                              click: function($event) {
                                return _vm.sortItems(["en_id"])
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "pointer" }, [
                              _vm._v("Numéro (en_id)")
                            ])
                          ]
                        ),
                        _c("th", { staticStyle: { width: "120px" } }, [
                          _c("span", { staticClass: "pointer" }, [
                            _vm._v("Connectée")
                          ])
                        ]),
                        _c("th", { staticStyle: { width: "160px" } }, [
                          _c("span", { staticClass: "pointer" }, [
                            _vm._v("Bouton & mise à jour")
                          ])
                        ]),
                        _c(
                          "th",
                          {
                            staticStyle: { width: "180px" },
                            on: {
                              click: function($event) {
                                return _vm.sortItems(["en_fullname"])
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "pointer" }, [
                              _vm._v("Utilisateur final")
                            ])
                          ]
                        ),
                        _c("th", { staticStyle: { width: "180px" } }, [
                          _c("span", { staticClass: "pointer" }, [
                            _vm._v("Établissement")
                          ])
                        ]),
                        _c("th", [
                          _c(
                            "span",
                            {
                              staticClass: "pointer",
                              staticStyle: { width: "170px" }
                            },
                            [_vm._v("Fournisseurs")]
                          )
                        ]),
                        _vm.$store.state.user.us_type !== "PARTNER"
                          ? _c("th", { staticStyle: { width: "170px" } }, [
                              _c("span", { staticClass: "pointer" }, [
                                _vm._v("Vidéo/Ok/Voix/Ok/Msg")
                              ])
                            ])
                          : _vm._e(),
                        _c("th", [
                          _c("span", { staticClass: "pointer" }, [
                            _vm._v("A faire")
                          ])
                        ]),
                        _vm.$store.state.user.us_type !== "PARTNER"
                          ? _c("th", { staticStyle: { width: "80px" } }, [
                              _c("span", { staticClass: "pointer" }, [
                                _vm._v("Actions")
                              ])
                            ])
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "tds",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("td", { staticStyle: { width: "150px" } }, [
                          _vm._v(" " + _vm._s(item.en_id) + " "),
                          item.bo_id
                            ? _c("span", [
                                _vm._v(_vm._s(item.bo_id.bo_mac_address))
                              ])
                            : _vm._e()
                        ]),
                        _c(
                          "td",
                          {
                            class: {
                              mygreen:
                                item.bo_id &&
                                _vm.$store.state.connectedBoxes.indexOf(
                                  item.bo_id.bo_mac_address
                                ) >= 0,
                              myred:
                                !item.de_id &&
                                (!item.bo_id ||
                                  _vm.$store.state.connectedBoxes.indexOf(
                                    item.bo_id.bo_mac_address
                                  ) < 0),
                              myblack: item.de_id
                            },
                            staticStyle: { width: "120px" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.de_id
                                    ? "Television"
                                    : item.bo_id &&
                                      _vm.$store.state.connectedBoxes.indexOf(
                                        item.bo_id.bo_mac_address
                                      ) >= 0
                                    ? "Connecté"
                                    : "Non connecté"
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c("td", { staticStyle: { width: "160px" } }, [
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "d-flex flex-grow-1 justify-content-start align-items-center"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    item.bo_id &&
                                      item.bo_id.bo_config_button &&
                                      item.bo_id.bo_config_button.batterylevel
                                      ? item.bo_id.bo_config_button
                                          .batterylevel + "%"
                                      : ""
                                  )
                                )
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "d-flex flex-grow-1 justify-content-end align-items-center"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    item.bo_id &&
                                      item.bo_id.bo_to_update !== "0" &&
                                      item.bo_id.bo_to_update !== "1"
                                      ? "Mettre à jour sur " +
                                          item.bo_id.bo_to_update
                                      : ""
                                  )
                                )
                              ]
                            )
                          ])
                        ]),
                        _c(
                          "td",
                          {
                            class: {
                              resiliate: item.en_resiliate_subscription
                            },
                            staticStyle: { width: "180px" }
                          },
                          [
                            _vm._v(" " + _vm._s(item.en_fullname) + " "),
                            _c("span", { staticClass: "color-emotivi-blue" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.co_id.afco_id &&
                                      item.co_id.afco_id.af_id &&
                                      item.co_id.afco_id.af_id.af_code
                                      ? "(Affilié à " +
                                          item.co_id.afco_id.af_id.af_name +
                                          " " +
                                          item.co_id.afco_id.af_id.af_code +
                                          ")"
                                      : ""
                                  ) +
                                  " " +
                                  _vm._s(
                                    item.en_linkello
                                      ? "(Associé à Linkello)"
                                      : ""
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        ),
                        _c("td", { staticStyle: { width: "180px" } }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.co_id && item.co_id.es_id
                                  ? item.co_id.es_id.es_name
                                  : ""
                              ) +
                              " "
                          )
                        ]),
                        _c("td", [
                          item.co_id.es_id.es_white_label
                            ? _c(
                                "span",
                                {
                                  staticClass: "pointer",
                                  staticStyle: { width: "170px" }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.$config.server_url +
                                        item.co_id.es_id.es_avatar,
                                      alt: "logo fournisseur",
                                      width: "20px"
                                    }
                                  })
                                ]
                              )
                            : _vm._e()
                        ]),
                        _vm.$store.state.user.us_type !== "PARTNER"
                          ? _c(
                              "td",
                              { staticStyle: { width: "170px" } },
                              [
                                _vm._l(item.infos.nbCalls, function(
                                  number,
                                  index
                                ) {
                                  return _c("span", { key: index }, [
                                    _vm._v(_vm._s(number) + " "),
                                    index < item.infos.nbCalls.length - 1
                                      ? _c("span", [_vm._v("/ ")])
                                      : _vm._e()
                                  ])
                                }),
                                item.newMessage && item.newMessage > 0
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "20px",
                                          "background-color": "green",
                                          color: "white",
                                          "font-weight": "bold",
                                          "border-radius": "50%",
                                          width: "25px",
                                          display: "inline-block",
                                          "text-align": "center",
                                          padding: "5px",
                                          "margin-top": "5px"
                                        },
                                        attrs: {
                                          "data-popover": _vm.textMessage(
                                            item.newMessage
                                          )
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.newMessage) + " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              2
                            )
                          : _vm._e(),
                        _c(
                          "td",
                          { attrs: { "data-popover": _vm.toDo(item) } },
                          [_c("span", [_vm._v(_vm._s(_vm.toDo(item)))])]
                        ),
                        _vm.$store.state.user.us_type !== "PARTNER"
                          ? _c("td", { staticStyle: { width: "60px" } }, [
                              _c("div", { staticClass: "d-flex flex-row" }, [
                                _vm.$store.state.user.us_type !== "STAFF"
                                  ? _c(
                                      "div",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.diagnostikReseau(
                                              item,
                                              $event
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("icon", {
                                          attrs: {
                                            width: "20",
                                            height: "20",
                                            name: "cogs"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item.co_id &&
                                _vm.$store.state.user.us_type !== "TECHNICIAN"
                                  ? _c(
                                      "div",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.sendEmail(item, $event)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "mailto:" +
                                                item.co_id.co_invoice_email
                                            }
                                          },
                                          [
                                            _c("icon", {
                                              staticClass: "ml-2",
                                              attrs: {
                                                width: "20",
                                                height: "20",
                                                name: "envelope",
                                                color: "black"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ])
      ]),
      _c("m-message-dialog", {
        attrs: {
          title: "Diagnostic réseau",
          text: _vm.dialogDiagnostiqueReseauTxt,
          width: "70%"
        },
        model: {
          value: _vm.dialogDiagnostiqueReseau,
          callback: function($$v) {
            _vm.dialogDiagnostiqueReseau = $$v
          },
          expression: "dialogDiagnostiqueReseau"
        }
      }),
      _c("m-message-dialog", {
        attrs: { title: "Confirmation", text: _vm.dialogMessageTxt },
        model: {
          value: _vm.dialogMessage,
          callback: function($$v) {
            _vm.dialogMessage = $$v
          },
          expression: "dialogMessage"
        }
      }),
      _c("enduser-win-edit", {
        attrs: { en_id: -1 },
        on: { WinEditSaved: _vm.loadBoxes, winEditCanceled: _vm.cancelWin },
        model: {
          value: _vm.dialogEnduser,
          callback: function($$v) {
            _vm.dialogEnduser = $$v
          },
          expression: "dialogEnduser"
        }
      }),
      _c("modal-send-message-all", {
        model: {
          value: _vm.modalSendMessage,
          callback: function($$v) {
            _vm.modalSendMessage = $$v
          },
          expression: "modalSendMessage"
        }
      }),
      _c("modal-schedule-message-all", {
        model: {
          value: _vm.modalScheduleMessage,
          callback: function($$v) {
            _vm.modalScheduleMessage = $$v
          },
          expression: "modalScheduleMessage"
        }
      }),
      _c("modal-send-ir-macro", {
        model: {
          value: _vm.modalSendIrMacro,
          callback: function($$v) {
            _vm.modalSendIrMacro = $$v
          },
          expression: "modalSendIrMacro"
        }
      }),
      _c("popover", { ref: "popover" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }