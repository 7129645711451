var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        staticClass: "modal",
        class: _vm.value ? "show" : "hide",
        style: _vm.value
          ? "display:block;background-color:#3333337a;"
          : "display:none;",
        attrs: { tabindex: "-1", role: "dialog", "aria-hidden": "true" }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-scrollable",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "div",
                {
                  staticClass: "modal-header d-flex flex-row align-items-center"
                },
                [
                  _c("div", [
                    _c("h1", [
                      _vm._v("Édition de la tv numéro " + _vm._s(_vm.tv.de_id))
                    ]),
                    _c("p")
                  ])
                ]
              ),
              _c(
                "div",
                { staticClass: "modal-body modal-padding" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-row align-items-center active"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.tv.de_mobile,
                            expression: "tv.de_mobile"
                          }
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "tvmobile",
                          name: "tvmobile"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.tv.de_mobile)
                            ? _vm._i(_vm.tv.de_mobile, null) > -1
                            : _vm.tv.de_mobile
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.tv.de_mobile,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.tv,
                                    "de_mobile",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.tv,
                                    "de_mobile",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.tv, "de_mobile", $$c)
                            }
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "label-checkbox",
                          attrs: { for: "tvmobile" }
                        },
                        [_vm._v("Télévision mobile")]
                      )
                    ]
                  ),
                  _c("hr"),
                  _c("div", { staticClass: "mt-3" }, [
                    _c("h5", [_vm._v("Plages horaires des appels vidéo")]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("m-form-checkbox", {
                            attrs: {
                              label: "Désactiver les horaires",
                              name: _vm.$Utils.randomstring("disablehours")
                            },
                            model: {
                              value: _vm.tv.de_disable_callhours,
                              callback: function($$v) {
                                _vm.$set(_vm.tv, "de_disable_callhours", $$v)
                              },
                              expression: "tv.de_disable_callhours"
                            }
                          }),
                          _vm._l(_vm.manageHourReformat, function(
                            plage,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "mb-2" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-row align-items-end"
                                  },
                                  [
                                    _c("v-time-picker", {
                                      staticClass: "time-picker",
                                      attrs: { format: "HHHmm" },
                                      model: {
                                        value: plage.start_time,
                                        callback: function($$v) {
                                          _vm.$set(plage, "start_time", $$v)
                                        },
                                        expression: "plage.start_time"
                                      }
                                    }),
                                    _c("v-time-picker", {
                                      staticClass: "time-picker ml-2",
                                      attrs: { format: "HHHmm" },
                                      model: {
                                        value: plage.end_time,
                                        callback: function($$v) {
                                          _vm.$set(plage, "end_time", $$v)
                                        },
                                        expression: "plage.end_time"
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "ml-2 mb-1 pointer",
                                        on: {
                                          click: function($event) {
                                            return _vm.deletePlage(plage, index)
                                          }
                                        }
                                      },
                                      [
                                        _c("icon", {
                                          attrs: {
                                            width: "25",
                                            height: "25",
                                            name: "times"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mt-2",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  _vm.addPlage = true
                                }
                              }
                            },
                            [_vm._v(" Ajouter une plage horaire ")]
                          )
                        ]),
                        _vm.addPlage
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-row align-items-end mt-2"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    _c("div", [_vm._v("Heure de début")]),
                                    _c("v-time-picker", {
                                      staticClass: "time-picker",
                                      attrs: { "minute-interval": 10 },
                                      model: {
                                        value: _vm.newHourStart,
                                        callback: function($$v) {
                                          _vm.newHourStart = $$v
                                        },
                                        expression: "newHourStart"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    _c("div", [_vm._v("Heure de fin")]),
                                    _c("v-time-picker", {
                                      staticClass: "time-picker ml-2",
                                      attrs: { "minute-interval": 10 },
                                      model: {
                                        value: _vm.newHourEnd,
                                        callback: function($$v) {
                                          _vm.newHourEnd = $$v
                                        },
                                        expression: "newHourEnd"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary ml-2",
                                    attrs: { type: "button" },
                                    on: { click: _vm.addPlageGo }
                                  },
                                  [_vm._v(" Ajouter ")]
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ]),
                    _c("hr")
                  ]),
                  _c("h5", [_vm._v("Options des appels")]),
                  _c("m-form-checkbox", {
                    staticClass: "mr-3",
                    attrs: {
                      label:
                        "Décrochage automatique activé pour l’établissement et l’administrateur",
                      name: _vm.$Utils.randomstring("de_autoresponse")
                    },
                    on: { input: _vm.onChangeAutoresponse },
                    model: {
                      value: _vm.tv.de_autoresponse,
                      callback: function($$v) {
                        _vm.$set(_vm.tv, "de_autoresponse", $$v)
                      },
                      expression: "tv.de_autoresponse"
                    }
                  }),
                  _c("m-form-checkbox", {
                    staticClass: "mr-3",
                    attrs: {
                      label:
                        "Décrochage automatique activé pour tous (établissement, administrateur et membres de la famille)",
                      name: _vm.$Utils.randomstring("de_autoresponse_forall"),
                      disabled: !_vm.tv.de_autoresponse
                    },
                    model: {
                      value: _vm.tv.de_autoresponse_forall,
                      callback: function($$v) {
                        _vm.$set(_vm.tv, "de_autoresponse_forall", $$v)
                      },
                      expression: "tv.de_autoresponse_forall"
                    }
                  }),
                  _c("m-form-checkbox", {
                    staticClass: "mr-3",
                    attrs: {
                      label: "Désactiver l'appel vidéo",
                      name: _vm.$Utils.randomstring("de_disable_video")
                    },
                    model: {
                      value: _vm.tv.de_disable_video,
                      callback: function($$v) {
                        _vm.$set(_vm.tv, "de_disable_video", $$v)
                      },
                      expression: "tv.de_disable_video"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "modal-footer d-flex flex-row justify-content-between"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger",
                      attrs: { type: "button" },
                      on: { click: _vm.deleteConfirmWin }
                    },
                    [_vm._v(" Supprimer ")]
                  ),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        attrs: { type: "button" },
                        on: { click: _vm.cancelWin }
                      },
                      [_vm._v(" Annuler ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-mouveal ml-2",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.saveWin(false)
                          }
                        }
                      },
                      [_vm._v(" Enregistrer ")]
                    )
                  ])
                ]
              )
            ])
          ]
        ),
        _c("m-confirm-dialog", {
          attrs: {
            text: "Souhaitez-vous effacer cette tv ?",
            title: "Confirmation",
            width: "500px"
          },
          on: {
            canceled: function($event) {
              _vm.confirmdelete = false
            },
            confirmed: function($event) {
              return _vm.deleteWin()
            }
          },
          model: {
            value: _vm.confirmdelete,
            callback: function($$v) {
              _vm.confirmdelete = $$v
            },
            expression: "confirmdelete"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }