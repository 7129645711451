var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("m-form-select", {
    attrs: {
      items: _vm.items,
      placeholder: _vm.placeholder,
      name: _vm.name,
      readonly: false,
      label: _vm.label,
      "label-width": _vm.labelWidth,
      autoSelectOnFocus: true,
      labelPosition: _vm.labelPosition,
      disabled: _vm.disabled,
      width: _vm.width,
      labelButton: _vm.labelButton
    },
    on: {
      search: _vm.search_go,
      onsearchinput: _vm.onsearchinput,
      focus: _vm.onfocus,
      clickLabelButton: _vm.clickLabelButton
    },
    model: {
      value: _vm.val,
      callback: function($$v) {
        _vm.val = $$v
      },
      expression: "val"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }