import Vue from "vue";
import Vuex from "vuex";
import bistri from "./bistri";
import _ from "lodash";
import axios from "axios";
import router from "./router";

// import config from "../config";
// import moment from "moment";

Vue.use(Vuex);

let accesstoken = window.localStorage.getItem("accesstoken") ? window.localStorage.getItem("accesstoken") : "";
let refreshtoken = window.localStorage.getItem("refreshtoken") ? window.localStorage.getItem("refreshtoken") : "";

let defaultUser = {
  co_id: "",
  co_name: "",
  co_firstname: "",
  co_function: "",
  co_mobile1: "",
  co_tel1: "",
  co_email1: "",
  co_type: ""
};
let user = _.cloneDeep(defaultUser);

let items_us_type = [
  {
    value: "DIRECTOR",
    text: "Directeur d'établissement(s)"
  },
  {
    value: "TECHNICIAN",
    text: "Technicien"
  },
  {
    value: "LATEAM",
    text: "Administrateur"
  },
  {
    value: "STAFF",
    text: "Standard"
  },
  {
    value: "EMOTIVI_USER",
    text: "Membre Emotivi"
  },
  {
    value: "PARTNER",
    text: "Partenaire Emotivi"
  }
];
let items_en_state = [
  {
    value: "ACTIVE",
    text: "ACTIVE"
  },
  {
    value: "NOT_ACTIVE",
    text: "NOT_ACTIVE"
  },
  {
    value: "UNPAID",
    text: "UNPAID"
  },
  {
    value: "INITIAL_PAID",
    text: "INITIAL_PAID"
  },
  {
    value: "SUSPENDED",
    text: "SUSPENDED"
  }
];
let items_en_state_box = [
  {
    value: "INSTALLED",
    text: "Box installée"
  },
  {
    value: "NOT_INSTALLED",
    text: "Box jamais installée"
  },
  {
    value: "CONNECTED",
    text: "Box connectée"
  },
  {
    value: "RETURNED",
    text: "Box retournée"
  }
];
let items_en_state_caution = [
  {
    value: "NO_DEPOSIT",
    text: "Aucun frais de dossier"
  },
  {
    value: "NOT_PAYED",
    text: "non payé"
  },
  {
    value: "PAYED_CB",
    text: "payé par CB"
  },
  {
    value: "PAYED_RIB",
    text: "payé par RIB"
  },
  {
    value: "REFUND_WAITING",
    text: "remboursement demandé"
  },
  {
    value: "REFUNDED",
    text: "remboursé"
  },
  {
    value: "REFUNDED_PARTIALLY",
    text: "remboursé partiellement"
  },
  {
    value: "SOLD",
    text: "Box vendue"
  }
  //, {
  //   value: "INITIAL_PAID",
  //   text: "Remboursement demandé"
  // }
];
let items_en_state_parcel = [
  {
    value: "WAITING",
    text: "Box en attente"
  },
  {
    value: "SHIPPING_ERROR",
    text: "Erreur de livraison"
  },
  {
    value: "SENT_BACK_AFTER_FAILURE",
    text: "Colis retourné après erreur"
  },
  {
    value: "TO_PREPARE",
    text: "Box à préparer"
  },
  {
    value: "TO_SEND",
    text: "Box à envoyer"
  },
  {
    value: "SENDED",
    text: "Box envoyée"
  },
  {
    value: "RECEIVED",
    text: "Box reçue"
  },
  {
    value: "CARRIED_AWAY",
    text: "Box emportée"
  },
  {
    value: "TO_PREPARE_KEY4G",
    text: "Clé 4G à envoyer"
  }
];

let items_en_state_subscription = [
  {
    value: "WAITING_SUBS",
    text: "Abonnement non démarré"
  },
  {
    value: "ESTABLISHMENT",
    text: "Abonnement via un établissement"
  },
  {
    value: "RIB_TO_CONFIRM",
    text: "Abonnement RIB à confirmer par la banque"
  },
  {
    value: "RIB",
    text: "Abonnement RIB en cours"
  },
  {
    value: "CB",
    text: "Abonnement CB en cours"
  },
  {
    value: "RIB_PROBLEM",
    text: "Problème abonnement RIB "
  },
  {
    value: "CB_PROBLEM",
    text: "Problème abonnement CB"
  },
  {
    value: "WAITING_STOPPED",
    text: "Arrêt abonnement en attente"
  },
  {
    value: "STOPPED",
    text: "Abonnement arrêté"
  },
  {
    value: "LOANED",
    text: "Kit en prêt"
  },
  {
    value: "NOT_TO_START",
    text: "Ne pas démarrer abonnement"
  },
  {
    value: "SUSPENDED",
    text: "Service suspendu"
  }
];
let items_en_state_support = [
  {
    value: "NONE",
    text: "Aucun"
  },
  {
    value: "TO_DEFINE",
    text: "Problème à définir"
  },
  {
    value: "CONNEXION",
    text: "Problème connexion"
  },
  {
    value: "SOURCE",
    text: "Problème changement de source"
  },
  {
    value: "BUTTON",
    text: "Problème bouton"
  },
  {
    value: "DISPLAY",
    text: "Problème affichage"
  },
  {
    value: "SOUND",
    text: "Problème son"
  },
  {
    value: "MATERIAL",
    text: "Problème composant"
  },
  {
    value: "RESOLVED",
    text: "Résolu"
  }
];
let items_pa_state = [
  {
    value: "PREORDER",
    text: "PREORDER"
  },
  {
    value: "TO_PREPARE",
    text: "TO_PREPARE"
  },
  // {
  //   value: "PREPARING",
  //   text: "PREPARING"
  // },
  {
    value: "SENT",
    text: "SENT"
  },
  // {
  //   value: "SHIPPING_ERROR",
  //   text: "SHIPPING_ERROR"
  // },
  {
    value: "SENT_BACK_AFTER_FAILURE",
    text: "SENT_BACK_AFTER_FAILURE"
  },
  {
    value: "RECEIVED",
    text: "RECEIVED"
  },
  {
    value: "CARRIED_AWAY",
    text: "CARRIED_AWAY"
  }
];
let items_rights_roles = [];
let items_rights_group = [];
let items_rights_ressources = [];
let items_civility = [
  {
    value: "Mme",
    text: "Mme"
  },
  {
    value: "M",
    text: "M"
  }
];
let item_product_type = [
  { text: "Abonnement", value: "SUBSCRIPTION" },
  { text: "Service", value: "SERVICE" }
];
let mookGroups = [
  {
    gr_id: "all",
    value: "all",
    text: "Tous les contacts",
    display: true,
    icon: "book"
  },
  // registered et abandonedregistered ne sont plus utilisés car dans la nouvelle version d'inscription, le contact est créé que quand il a validé son inscription
  // {
  //   gr_id: "registered",
  //   value: "registered",
  //   text: "Inscrit",
  //   display: true,
  //   icon: "user-check"
  // },
  // {
  //   gr_id: "abandonedregistered",
  //   value: "abandonedregistered",
  //   text: "Inscription abandonnée",
  //   display: true,
  //   icon: "user-times"
  // },
  {
    gr_id: "registeredLast30Days",
    value: "registeredLast30Days",
    text: "Inscrit depuis - de 30 jrs",
    display: true,
    icon: "user"
  },
  {
    gr_id: "promocode",
    value: "promocode",
    text: "Code promo",
    display: "/invoices",
    // display: this.$store.getters.checkUserAccess(this.$router, "/invoices"),
    icon: "money-check"
  },
  {
    gr_id: "paidDeposit",
    value: "paidDeposit",
    text: "payé",
    display: "/invoices",
    // display: this.$store.getters.checkUserAccess(this.$router, "/invoices"),
    icon: "money-check"
  },
  {
    gr_id: "notPaidDeposit",
    value: "notPaidDeposit",
    text: "non payé",
    display: "/invoices",
    // display: this.$store.getters.checkUserAccess(this.$router, "/invoices"),
    icon: "search-dollar"
  },
  {
    gr_id: "boxToPrepare",
    value: "boxToPrepare",
    text: "Boxes à préparer",
    // display: this.$store.getters.checkUserAccess(this.$router, "/invoices"),
    display: "/invoices",

    icon: "box-open"
  },

  {
    gr_id: "boxSent",
    value: "boxSent",
    text: "Boxes envoyées",
    // display: this.$store.getters.checkUserAccess(this.$router, "/invoices"),
    display: "/invoices",

    icon: "box"
  },

  {
    value: "boxInstalled",
    gr_id: "boxInstalled",
    text: "Boxes installées",
    display: true,
    icon: "download"
  },
  {
    gr_id: "boxNotInstalled",
    value: "boxNotInstalled",
    text: "Boxes non installées",
    display: true,
    icon: "upload"
  },

  {
    value: "subscriptionActive",
    gr_id: "subscriptionActive",
    text: "Abonnements en cours",
    // display: this.$store.getters.checkUserAccess(this.$router, "/invoices"),
    display: "/invoices",

    icon: "sync-alt"
  },
  {
    value: "subscriptionActiveGoCardless",
    gr_id: "subscriptionActiveGoCardless",
    text: "Abonnements en cours GoCardless",
    // display: this.$store.getters.checkUserAccess(this.$router, "/invoices"),
    display: "/invoices",

    icon: "credit-card"
  },
  {
    value: "subscriptionActiveStripe",
    gr_id: "subscriptionActiveStripe",
    text: "Abonnements en cours Stripe",
    // display: this.$store.getters.checkUserAccess(this.$router, "/invoices"),
    display: "/invoices",

    icon: "credit-card"
  },
  {
    value: "subscriptionActiveRIB",
    gr_id: "subscriptionActiveRIB",
    text: "Abonnements en cours RIB",
    // display: this.$store.getters.checkUserAccess(this.$router, "/invoices"),
    display: "/invoices",

    icon: "credit-card"
  },
  {
    value: "stoppedSubscription",
    gr_id: "stoppedSubscription",
    text: "Abonnements arrêtés",
    // display: this.$store.getters.checkUserAccess(this.$router, "/invoices"),
    display: "/invoices",

    icon: "ban"
  },
  {
    gr_id: "subscriptionToStart",
    value: "subscriptionToStart",
    text: "Abonnements à démarrer",
    // display: this.$store.getters.checkUserAccess(this.$router, "/invoices"),
    display: "/invoices",

    icon: "play"
  },

  {
    value: "subscriptionPaymentError",
    gr_id: "subscriptionPaymentError",
    text: "Erreurs de paiement abonnement",
    // display: this.$store.getters.checkUserAccess(this.$router, "/invoices"),
    display: "/invoices",
    icon: "exclamation-triangle"
  },
  {
    value: "suspendedService",
    gr_id: "suspendedService",
    text: "Service suspendu",
    // display: this.$store.getters.checkUserAccess(this.$router, "/invoices"),
    display: "/invoices",

    icon: "lock"
  },
  {
    gr_id: "withOpenTicket",
    value: "withOpenTicket",
    text: "Avec ticket ouvert",
    // display: this.$store.getters.checkUserAccess(this.$router, "/invoices"),
    display: "/invoices",
    icon: "bug"
  },

  {
    value: "callInLast30Days",
    gr_id: "callInLast30Days",
    text: "Au moins un appel depuis 30j",
    display: true,
    icon: "phone-alt"
  },
  {
    value: "noCallInLast30Days",
    gr_id: "noCallInLast30Days",
    text: "Pas d'appel depuis 30j",
    display: true,
    icon: "phone-slash"
  },
  {
    value: "waitingRefund",
    gr_id: "waitingRefund",
    text: "En attente de remboursement",
    // display: this.$store.getters.checkUserAccess(this.$router, "/invoices"),
    display: "/invoices",

    icon: "pause"
  },
  {
    value: "waitingStoppedSubscription",
    gr_id: "waitingStoppedSubscription",
    text: "Résiliation à faire",
    // display: this.$store.getters.checkUserAccess(this.$router, "/invoices"),
    display: "/invoices",

    icon: "stop-circle"
  },
  {
    value: "refunded",
    gr_id: "refunded",
    text: "Remboursés",
    // display: this.$store.getters.checkUserAccess(this.$router, "/invoices"),
    display: "/invoices",

    icon: "file-invoice-dollar"
  },
  {
    value: "boxLoaned",
    gr_id: "boxLoaned",
    text: "Kits en prêt",
    // display: this.$store.getters.checkUserAccess(this.$router, "/invoices"),
    display: "/invoices",
    icon: "hands-helping"
  },
  {
    value: "boxLoanedReturned",
    gr_id: "boxLoanedReturned",
    text: "Kits en prêt retournés",
    // display: this.$store.getters.checkUserAccess(this.$router, "/invoices"),
    display: "/invoices",
    icon: "box"
  },

  {
    gr_id: "affiliated",
    value: "affiliated",
    text: "Contacts affiliés",
    // display: this.$store.getters.checkUserAccess(this.$router, "/contacts/:co_id/edit"),
    display: "/invoices",
    icon: "link"
  },
  {
    gr_id: "key4gDeposit",
    value: "key4gDeposit",
    text: "Commandes clé 4G à envoyer",
    // display: this.$store.getters.checkUserAccess(this.$router, "/contacts/:co_id/edit"),
    display: "/invoices",
    icon: "sim-card"
  },
  {
    gr_id: "BtoB",
    value: "BtoB",
    text: "Contacts BtoB",
    // display: this.$store.getters.checkUserAccess(this.$router, "/contacts/:co_id/edit"),
    display: "/invoices",
    icon: "building"
  },
  {
    value: "contactsToFollowUp",
    gr_id: "contactsToFollowUp",
    text: "Contacts à relancer",
    // display: this.$store.getters.checkUserAccess(this.$router, "/contacts/:co_id/edit"),
    display: "/invoices",
    icon: "reply-all"
  },
  {
    value: "contactsArchived",
    gr_id: "contactsArchived",
    text: "Contacts archivés",
    // display: this.$store.getters.checkUserAccess(this.$router, "/contacts/:co_id/edit"),
    display: "/invoices",
    icon: "address-book"
  },
  {
    gr_id: "resiliate",
    value: "resiliate",
    text: "Contacts Resiliés",
    display: "/invoices",
    icon: "eject"
  },
  {
    gr_id: "testContacts",
    value: "testContacts",
    text: "Contacts de test",
    display: "usertype:LATEAM",
    icon: "user-cog"
  },

  {
    value: "partnerContacts",
    gr_id: "partnerContacts",
    text: "Contacts venants d'un site partenaire",
    display: true,
    icon: "hands-helping"
  }
  // {
  //   value: "contactsDeleted",
  //   gr_id: "contactsDeleted",
  //   text: "Contacts supprimés",
  //   // display: this.$store.getters.checkUserAccess(this.$router, "/contacts/:co_id/edit"),
  //   display: "/contacts/:co_id/edit",
  //   icon: "user-slash"
  // }
];
let item_of_sub_trial_period = [
  { text: "15 jours", value: 15 },
  { text: "1 mois", value: 30 },
  { text: "2 mois", value: 60 },
  { text: "3 mois", value: 90 }
];
let items_company = [
  {
    value: "colissimo",
    text: "Colissimo"
  }
];
let items_log_types = [
  {
    value: "createcontact",
    text: "Création d'un contact"
  },
  {
    value: "editcontact",
    text: "Édition d'un contact"
  },
  {
    value: "deletecontact",
    text: "Suppression d'un contact"
  },
  {
    value: "signin",
    text: "Connexion"
  }
];

//utilisé sur la page d'accueil pour aider à selectionner des dates
let items_period = [
  {
    value: "startemotivi",
    text: "Depuis le début de Emotivi"
  },
  {
    value: "last30days",
    text: "Les 30 derniers jours"
  },
  {
    value: "last90days",
    text: "Les 90 derniers jours"
  },
  {
    value: "thismonth",
    text: "Le mois en cours"
  },
  {
    value: "lastmonth",
    text: "Le mois dernier (du 1 au 31)"
  }
  // à décommenter dans un an ;)
  /*   {
         value: "thisyear",
         text: "l'année en cours"
        },
        {
         value: "lastyear",
         text: "l'année dernière (du 01/01 au 31/12)"
        } */
];

let items_es_type = [
  {
    value: 0,
    text: "Emotivi"
  },
  {
    value: 1,
    text: "Revendeur (BtoBtoC)"
  },
  {
    value: 2,
    text: "Fournisseur de services (BtoBtoC)"
  },
  {
    value: 3,
    text: "Revendeur sans facturation (BtoBtoC)"
  },
  {
    value: 4,
    text: "Ehpad (BtoB)"
  }
];

let items_bf_communication = [
  {
    value: "none",
    text: "Aucune"
  },
  {
    value: "email",
    text: "Email"
  },
  {
    value: "sms",
    text: "SMS"
  }
];

export default new Vuex.Store({
  state: {
    connectedBoxes: [],
    showMenu: false,
    filtersBox: {
      aFaire: true,
      checkboxBoxes: true,
      checkboxTV: true,
      checkboxWithoutDevices: true,
      checfilterText: "",
      filterEstablishment: null,
      filterStatusBox: 0,
      filterVersionBox: "",
      filterPartnerSite: "",
      filterStatus: "",
      sortOrder: "desc",
      sortFields: ["en_id"]
    },
    filtersTv: { filterText: "", filterEstablishment: {}, filterStatusBox: 0, filterVersionTv: "", sortOrder: "desc", sortFields: ["en_id"] },
    scrollPosition: 0,
    items_us_type: items_us_type,
    items_log_types: items_log_types,
    items_period: items_period,
    items_es_type: items_es_type,
    items_bf_communication: items_bf_communication,
    items_civility: items_civility,
    items_company: items_company,
    item_of_sub_trial_period: item_of_sub_trial_period,
    item_product_type: item_product_type,
    mookGroups: mookGroups,
    items_rights_roles: items_rights_roles,
    items_rights_ressources: items_rights_ressources,
    items_rights_group: items_rights_group,
    items_pa_state: items_pa_state,
    items_en_state: items_en_state,
    items_en_state_box: items_en_state_box,
    items_en_state_caution: items_en_state_caution,
    items_en_state_parcel: items_en_state_parcel,
    items_en_state_subscription: items_en_state_subscription,
    items_en_state_support: items_en_state_support,
    items_fov_label_size: [
      { value: "h1", text: "Titre" },
      { value: "h2", text: "Sous-titre" },
      { value: "h3", text: "Sous-sous-titre" }
    ],
    accesstoken: "",
    isMainLoading: false,
    currentcontact: null,
    // lastcoid: "",
    mysocket: null,
    mysocket_connected: false,
    mysocket_connected_to_room: false,
    connected: false,
    accesstoken,
    refreshtoken,
    user,
    preferences: {
      personal: {},
      shared: {},
      rights: {}
    },
    reload_actions: 0,
    inputOpened: null,
    dialogErr: false,
    dialogErrTxt: "",
    research: "",
    showResearchView: false,
    socket_response: null,
    filterDateStart: "2020-01-01",
    filterDateEnd: "",
    bistri_sdk_state: 0,
    bistri_session: null,
    bistri_initialized: 0,
    lang: "fr",
    camera: window.localStorage.getItem("camera") ? window.localStorage.getItem("camera") : "",
    ac_id: "",
    backup_db_response: null,
    get_logs_response: null,
    get_gocardless_paymentmethod_response: null,
    get_gocardless_subscription_response: null,
    calendar_events: null,
    paymentMethodsStatusText: {
      fulfilled: "Le mode de paiement a été créé et finalisé.",
      flow_created: "L'interface d'enregistrement du mode de paiement a été créée.",
      flow_visited: "L'interface d'enregistrement du mode de paiement a été consultée.",
      flow_exited: "L'utilisateur a quitté l'interface d'enregistrement du mode de paiement.",
      collect_customer_details: "Les informations du client ont été rassemblées.",
      collect_bank_account: "Les coordonnées bancaires ont été collectées pour cette demande de facturation.",
      payer_details_confirmed: "Le payeur a confirmé toutes ses coordonnées pour cette demande de facturation.",
      bank_authorisation_visited: "Un lien d'autorisation bancaire pour cette demande de facturation a été consulté.",
      bank_authorisation_authorised: "Une autorisation bancaire pour cette demande de facturation a été autorisée par le payeur.",
      bank_authorisation_denied: "Une autorisation bancaire pour cette demande de facturation a expiré",
      bank_authorisation_expired: "Une autorisation bancaire pour cette demande de facturation a été refusée par le payeur.",
      bank_authorisation_failed:
        "Une autorisation bancaire pour cette demande de facturation a échoué en raison d'une incompatibilité de compte bancaire.",
      cancelled: "Ce mode de paiement a été annulé.",
      created: "Le mode de paiement a été créé",
      failed: "Problème de paiement",
      submitted: "Le paiement a été soumis aux banques"
    },
    subscriptionStatusText: {
      created: "L'abonnement a été créé.",
      customer_approval_granted:
        "L'abonnement nécessitait une approbation supplémentaire de la part du client avant de pouvoir devenir actif, et cette approbation a été accordée.",
      customer_approval_denied:
        "L'abonnement nécessitait une approbation supplémentaire de la part du client avant de pouvoir devenir actif, et cette approbation a été refusée.",
      payment_created: "Un paiement a été créé par cet abonnement.",
      cancelled: "Cet abonnement a été annulé. Aucun autre paiement ne sera créé.",
      finished: "Cet abonnement est terminé. Aucun autre paiement ne sera créé.",
      paused: "Cet abonnement a été suspendu.",
      resumed: "Cet abonnement a redémarré."
    },
    paymentsStatusText: {
      pending_submission: "Le paiement a été créé, mais pas encore soumis aux banques",
      pending_customer_approval: "Nous attendons que le client approuve ce paiement",
      submitted: "Le paiement a été soumis à la banque du client",
      confirmed: "Le paiement a été validé par la banque du client",
      paid_out: "Le paiement a été envoyé sur le compte bancaire du créancier",
      cancelled: "Le paiement a été annulé",
      customer_approval_denied: "Le client a refusé l'approbation du paiement. Vous devez contacter directement le client",
      failed: "Le paiement n'a pas pu être traité.",
      charged_back: "Le paiement a été remboursé",
      succeeded: "Paiement réussi"
    }
  },
  mutations: {
    // SOCKET_connect(state) {
    //   console.warn("SOCKET CONNECT MUTATION");
    // },

    // SOCKET_disconnect(state) {
    //   console.warn("SOCKET DISCONNECT MUTATION");
    //   state.mysocket_connected = false;
    //   state.mysocket = null;
    //   state.mysocket_connected_to_room = false;
    // },
    set_calendar_events(state, events) {
      state.calendar_events = events;
    },
    set_connected_boxes(state, rooms) {
      state.connectedBoxes = rooms;
    },
    set_filtersBox(state, filtersBox) {
      state.filtersBox = filtersBox;
    },
    set_filtersTv(state, filtersTv) {
      state.filtersTv = filtersTv;
    },
    set_scrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition;
    },
    set_showResearchView(state, showResearchView) {
      state.showResearchView = showResearchView;
    },
    set_research(state, research) {
      state.research = research;
    },
    set_filterDateStart(state, filterDateStart) {
      state.filterDateStart = filterDateStart;
    },
    set_filterDateEnd(state, filterDateEnd) {
      state.filterDateEnd = filterDateEnd;
    },
    set_showMenu(state, show) {
      state.showMenu = show;
    },
    set_inputOpened(state, input) {
      if (input) {
        if (state.inputOpened && input.name !== state.inputOpened.name) {
          state.inputOpened.hideDropdown();
        }
        state.inputOpened = input;
      } else {
        state.inputOpened = null;
      }
    },
    set_currentcontact(state, val) {
      state.currentcontact = val;
    },
    set_mainisloading(state, ok) {
      state.isMainLoading = ok;
    },
    // set_lastcoid(state, co_id) {
    //   state.lastcoid = co_id;
    // },
    set_mysocket(state, socketData) {
      if (socketData.hasOwnProperty("socket")) state.mysocket = socketData.socket;
      state.mysocket_connected = socketData.state;
      state.mysocket_connected_to_room = socketData.room;
    },
    set_reload_actions(state) {
      state.reload_actions = Math.random();
    },
    // utiliser simplement pour mettre à jour les infos essentielles
    set_user_simple(state, user) {
      state.user.co_name = user.co_name;
      state.user.co_firstname = user.co_firstname;
      state.user.co_type = user.co_type;
      state.user.updatedAt = user.updatedAt;
    },
    set_tokens(state, what) {
      axios.defaults.headers.common["x-auth-accesstoken"] = what.accesstoken;
      window.localStorage.setItem("accesstoken", what.accesstoken);
      state.accesstoken = what.accesstoken;
      window.localStorage.setItem("refreshtoken", what.refreshtoken);
      state.refreshtoken = what.refreshtoken;
    },
    set_connexion(state, what) {
      // console.log("what", what);
      if (!what.accesstoken) {
        axios.defaults.headers.common["x-auth-accesstoken"] = "";
        window.localStorage.removeItem("accesstoken");
        state.accesstoken = null;
        window.localStorage.removeItem("refreshtoken");
        state.refreshtoken = null;
        window.localStorage.removeItem("user");
        state.user = _.cloneDeep(defaultUser);
        state.connected = false;
        state.items_rights_group = [];
        return;
      }
      // console.log("token, refreshtoken, user", what.accesstoken, what.refreshtoken, what.user);
      axios.defaults.headers.common["x-auth-accesstoken"] = what.accesstoken;
      window.localStorage.setItem("accesstoken", what.accesstoken);
      state.accesstoken = what.accesstoken;
      window.localStorage.setItem("refreshtoken", what.refreshtoken);
      state.refreshtoken = what.refreshtoken;
      window.localStorage.setItem("user", JSON.stringify(what.user));
      state.user = _.cloneDeep(what.user);
      state.preferences = _.cloneDeep(what.preferences);
      //state.items_rights_ressources = what.preferences.rights.acl_resources;
      // state.items_rights_roles = what.preferences.rights.acl_roles;
      //state.items_rights_group = what.preferences.rights.acl_groups;
      //console.log("what.preferences", what.preferences);
      _.each(what.preferences.items, (val, key) => {
        state[key] = what.preferences.items[key];
      });

      // route qui va chercher tous les groupes des utilisateurs
      state.mookGroups = state.mookGroups.filter(mookGroup => {
        mookGroup.display;
        if (mookGroup.display === true || mookGroup.display === false) {
          return mookGroup.display;
        } else {
          let ok = false;
          if (typeof mookGroup.display === "string" && mookGroup.display.indexOf("usertype:") >= 0) {
            let userType = mookGroup.display.split(":")[1];
            ok = state.user.us_type === userType;
            return ok;
          }
          ok = this.getters.checkUserAccess(router, mookGroup.display);
          // console.log("mookGroup.display,ok", mookGroup.display, ok);
          return ok;
        }
      });
      state.connected = true;
      // this.dispatch("load_nbclientsuivi");
      this.dispatch("socketjoinroom");
    },
    set_user(state, user) {
      window.localStorage.setItem("user", JSON.stringify(user));
      state.user = _.cloneDeep(user);
    },
    set_acl_groups(state, what) {
      state.items_rights_group = what;
    },
    set_preferences(state, data) {
      state.preferences[data.preference] = data.data;
    },
    set_dialog_error(state, what) {
      // console.log("what", what);
      state.dialogErr = what.dialogErr;
      state.dialogErrTxt = what.dialogErrTxt;
    },
    set_socket_response(state, what) {
      state.socket_response = what;
    },
    set_bistri_sdk_state(state, bistri_sdk) {
      state.bistri_sdk_state = bistri_sdk;
    },
    set_bistri_initialized(state, bistri_initialized) {
      state.bistri_initialized = bistri_initialized;
    },
    set_bistri_session(state, bistri_session) {
      state.bistri_session = bistri_session;
    },
    set_camera(state, camera) {
      state.camera = camera;
      window.localStorage.setItem("camera", camera);
    },
    set_ac_id(state, ac_id) {
      state.ac_id = ac_id;
    },
    set_backup_db_response(state, backup_db_response) {
      state.backup_db_response = backup_db_response;
    },
    set_get_logs_response(state, get_logs_response) {
      state.get_logs_response = get_logs_response;
    },
    set_get_gocardless_subscription_response(state, get_gocardless_subscription_response) {
      state.get_gocardless_subscription_response = get_gocardless_subscription_response;
    },
    set_get_gocardless_paymentmethod_response(state, get_gocardless_paymentmethod_response) {
      state.get_gocardless_paymentmethod_response = get_gocardless_paymentmethod_response;
    }
  },
  actions: {
    initSocketEvents(ctx) {
      this._vm.$SocketIO.on("connect", socket => {
        console.warn("SOCKET CONNECTED ACTION", socket);

        ctx.commit("set_mysocket", { socket: this._vm.$SocketIO, state: true, room: false });
        ctx.dispatch("socketjoinroom");
        // if (ctx.state.mysocket) {
        //   ctx.state.mysocket.emit("/extranet/2.0/join", {}, () => {
        //     console.warn("SOCKET ROOM JOINED");
        //     ctx.commit("set_mysocket", { state: true, room: true });
        //   });
        // }
      });
      this._vm.$SocketIO.on("/extranet/2.0/response", response => {
        console.warn("SOCKET RESPONSE", response.cmd, response.data);
        ctx.commit("set_socket_response", response);
      });
      this._vm.$SocketIO.on("/extranet/2.0/updateboxes", response => {
        console.warn("SOCKET updateboxes", response);
        ctx.commit("set_connected_boxes", response);
        // get the new bo_challenge
        // ctx.commit("set_box_connected", response.box_connected);
        // if (response.box_connected) ctx.commit("set_box_challenge", response.bo_challenge);
      });

      this._vm.$SocketIO.on("disconnect", () => {
        ctx.commit("set_mysocket", { socket: null, state: false, room: false });
      });
      this._vm.$SocketIO.on("/extranet/2.0/call-stop", data => {
        console.warn("SOCKET call-stop", data);
        bistri.hangUp(false, true, data.reason);
      });
      this._vm.$SocketIO.on("/extranet/2.0/backup-db-response", data => {
        console.warn("SOCKET backup-db-response", data);

        ctx.commit("set_backup_db_response", data);
      });
      this._vm.$SocketIO.on("/extranet/2.0/get-logs-response", data => {
        console.warn("SOCKET get-logs-response", data);

        ctx.commit("set_get_logs_response", data);
      });
      this._vm.$SocketIO.on("/extranet/2.0/gocardless/subscription", data => {
        console.warn("SOCKET get-gocardless-subscription-states", data);

        ctx.commit("set_get_gocardless_subscription_response", data);
      });
      this._vm.$SocketIO.on("/extranet/2.0/gocardless/paymentmethod", data => {
        console.warn("SOCKET get-gocardless-paymentmethod-states", data);

        ctx.commit("set_get_gocardless_paymentmethod_response", data);
      });
    },
    setCalendarEvents(ctx, events) {
      ctx.commit("set_calendar_events", events);
    },
    showResearchView(ctx, showResearchView) {
      ctx.commit("set_showResearchView", showResearchView);
    },
    showResearch(ctx, data) {
      ctx.commit("set_research", data.researchVal);
      ctx.commit("set_filterDateStart", data.filterDateStart);
      ctx.commit("set_filterDateEnd", data.filterDateEnd);
    },
    showMenu(ctx, show) {
      ctx.commit("set_showMenu", show);
    },
    showDialogError(ctx, err) {
      ctx.commit("set_dialog_error", {
        dialogErr: err ? true : false,
        dialogErrTxt: err ? err : ""
      });
    },
    socketjoinroom(ctx) {
      if (ctx.state.mysocket_connected && ctx.state.connected) {
        console.warn("JOINING SOCKET ROOM");
        ctx.state.mysocket.emit("/extranet/2.0/join", ctx.state.accesstoken, () => {
          console.warn("SOCKET ROOM JOINED");
          ctx.state.mysocket_connected_to_room = true;
          bistri
            .init(Vue.prototype.$socket, Vue.prototype.$uuid, Vue.prototype.$config, Vue.prototype.$axios)
            .then(() => {
              // console.info("Bistri initialised");
              window.bc.connect();
            })
            .catch(error => {
              if (!process.env.NODE_ENV == "development") {
                console.error("An error has occured during the bistri initialisation > ", error);
              }
            });
        });
      }
    },
    socketEmit(ctx, emitObject) {
      return new Promise((resolve, reject) => {
        // console.log("emitObject", emitObject);
        if (ctx.state.mysocket_connected && ctx.state.connected) {
          console.warn("SEND SOCKET EVENT > ", emitObject.cmd, " WITH DATA > ", emitObject.data);
          ctx.state.mysocket.emit(`/extranet/2.0/do/${emitObject.cmd}`, { us_accesstoken: ctx.state.accesstoken, ...emitObject }, response => {
            console.warn(`SOCKET ${emitObject.cmd} SENT`, response);
            resolve(response);
          });
        }
      });
    }
  },
  modules: {},
  getters: {
    get_inputOpened(state) {
      return state.inputOpened;
    },
    checkUserAccess: state => (router, route) => {
      try {
        if (!_.find(router.options.routes, { path: route })) {
          console.warn("ROUTE NOT FOUND - ACCESS DENIED TO : ", route);
          return false;
        }
        if (!state.user || !state.user.us_type) {
          console.warn("NO USER OR NO USER TYPE - ACCESS DENIED TO : ", route);
          return false;
        }
        if (!_.find(items_us_type, { value: state.user.us_type })) {
          console.warn("USER TYPE NOT FOUND - ACCESS DENIED TO : ", route);
          return false;
        }
        let routeObject = router.options.routes.filter(routeObject => routeObject.path === route)[0];

        let modulePermission = routeObject.meta.permission;
        // console.log("modulePermission", modulePermission);
        if (!modulePermission || !Array.isArray(modulePermission)) {
          console.warn("META DATA PERMISSION NOT FOUND - ACCESS DENIED TO : ", route);
          return false;
        }

        return Boolean(modulePermission.includes(state.user.us_type));
      } catch (error) {
        console.error("ERROR CHECK USER ACCESS : ", error);
        return false;
      }
    }
  }
});
