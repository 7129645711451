var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mlistsimple-container hscroll" }, [
    _c(
      "table",
      { staticClass: "table" },
      [
        _c("thead", [_c("tr", { staticClass: "tr-head" }, [_vm._t("ths")], 2)]),
        _vm.dragable
          ? _c(
              "draggable",
              { attrs: { tag: "tbody", list: _vm.items2, handle: ".handle" } },
              _vm._l(_vm.items2, function(item, indexItem) {
                return _c(
                  "tr",
                  {
                    key: item[_vm.itemValue],
                    class: _vm.getTrClass(item),
                    on: {
                      click: function($event) {
                        return _vm.onitemclick(item, indexItem, $event)
                      }
                    }
                  },
                  [_vm._t("tds", null, { item: item })],
                  2
                )
              }),
              0
            )
          : _vm._e(),
        !_vm.dragable
          ? _c(
              "tbody",
              _vm._l(_vm.items2, function(item, indexItem) {
                return _c(
                  "tr",
                  {
                    key: item[_vm.itemValue],
                    class: _vm.getTrClass(item),
                    on: {
                      click: function($event) {
                        return _vm.onitemclick(item, indexItem, $event)
                      }
                    }
                  },
                  [_vm._t("tds", null, { item: item })],
                  2
                )
              }),
              0
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }