<template>
  <section class="d-flex">
    <div class="d-flex flex-column" id="boxes">
      <div class="d-flex header-emotivi">
        <div class="d-flex align-items-center">
          <h1>{{ contacts_total }} Contacts</h1>
        </div>
        <div
          v-if="$store.getters.checkUserAccess($router, '/contacts/:co_id/edit')"
          class="flex-grow-1 d-flex justify-content-end align-items-center"
        >
          <button @click="addNewContact" class="ml-3 btn btn-sm btn-primary color-white" type="button">
            <icon name="plus" scale="1" class="mr-2"></icon> Nouveau
          </button>
        </div>
      </div>

      <div class="flex-grow-1 overflow-hidden d-flex">
        <div class="w-20 mr-3">
          <div class="card overflow-hidden mb-0" :style="windowWidth > 992 ? 'height:100%;' : 'height:auto; margin-bottom:20px !important;'">
            <div class="flex-grow-0">
              <div @click="groupsAreVisible = !groupsAreVisible" class="w-100 d-flex flex-row align-items-center justify-content-between">
                <div class="bis">Groupes</div>
                <div v-if="windowWidth <= 992">
                  <icon v-if="!groupsAreVisible" @click="addNewGroup" class="ml-2" name="arrow-down"></icon>
                  <icon v-if="groupsAreVisible" @click="addNewGroup" class="ml-2" name="arrow-up"></icon>
                </div>
              </div>
            </div>
            <div v-if="groupsAreVisible" class="mt-3 flex-grow-1 overflow-auto">
              <m-list-simple
                ref="groupslist"
                :dynamic="true"
                :items="$store.state.mookGroups"
                item-value="gr_id"
                :item-height="10"
                :value="contact_current_coid"
                :styleItemSelected="true"
                @itemclick="groupClick"
              >
                <template v-slot:ths="{}"></template>
                <template v-slot:tds="{ item }">
                  <td><icon width="18" height="18" :name="item.icon" class="mr-2" color="#199aaa"></icon>{{ item.text }}</td>
                </template>
              </m-list-simple>
            </div>
          </div>
        </div>
        <div class="w-20 mr-3 d-flex">
          <div class="card mb-0">
            <div class="flex-grow-0">
              <div class="d-flex align-items-center">
                <div class="bis">Contacts</div>
              </div>
              <m-form-text
                placeholder="Rechercher"
                tooltip="Recherche sur le nom, prénom, adresse, CP,<br>ville, email, téléphone, fax, mobile et ID"
                tooltip-position="top"
                :name="$Utils.randomstring('searchcontact')"
                v-model="searchcontact"
                @input="loadContactsDelay"
              ></m-form-text>
            </div>
            <div class="flex-grow-1 overflow-hidden mt-3 d-flex">
              <m-list
                ref="contactslist"
                :dynamic="true"
                :items="contacts"
                item-value="co_id"
                :item-height="30"
                :limit="contacts_limit"
                :skip="contacts_skip"
                :total="contacts_total"
                :current="contact_current_coid"
                :bgstrip="true"
                @changerange="changeContactsRange"
                @itemclick="contactClick"
              >
                <template v-slot:ths="{}"></template>
                <template v-slot:tds="{ item }">
                  <!-- <td :style="`width:30px;`">
                  <div style="width:25px;height:25px;margin-right:10px;">
                    <icon class="icon-menu" name="user-check"></icon>
                  </div>
                </td> -->
                  <td>
                    {{ item | formatContactNameSimple("co_invoice_", false) }}
                    <img v-if="item.es_avatar" :src="$config.server_url + item.es_avatar" alt="Logo fournisseur" width="20px" />
                  </td>
                  <!-- <td style="width:10px;padding:0;">
            <div v-if="!item.hassearches && item.co_type == 'contact'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <div
                    v-on="on"
                    style="background-color:#ff4b56; border-radius:50%; width:6px; height:6px;"
                  ></div>
                </template>
                <span>Contact suivi, mais sans aucune recherche associée</span>
              </v-tooltip>
            </div>
          </td>-->
                </template>
              </m-list>
            </div>
          </div>
        </div>
        <div class="w-60">
          <div class="card h-100 overflow-auto">
            <contact-details
              ref="contactdetails"
              :value="contact_current_coid"
              :tabEnIds="tabEnIds"
              @opened="contactWinEditOpen"
              @saved="contactWinEditSaved"
              @canceled="contactWinEditCanceled"
              @deleted="contactWinEditDeleted"
            ></contact-details>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue";
export default {
  name: "contacts",
  components: {},
  data() {
    return {
      groupsAreVisible: true,
      windowWidth: window.innerWidth,
      cancelAxiosLoadContacts: null,
      searchcontact: "",
      filterText: "",
      group_current_grid: "all",
      contacts: [],
      contacts_skip: 0,
      contacts_total: 0,
      contacts_limit: 50,
      contacts_current: "",
      contact_current_coid: 0,
      currentContact: null,
      contact: null,
      delaySearch: null,
      tabEnIds: []
    };
  },

  watch: {
    windowHeight(val) {
      // console.log("val", val);
      if (val > 992) this.groupsAreVisible = true;
      else this.groupsAreVisible = false;
    }
    // async searchcontact(val) {
    //   await this.loadContacts(0);
    //   await this.loadFirstContactOfList();
    // }
    // async $route(to, from) {
    //   console.log("this.$route.name", this.$route.name);
    //   if (to.name == "contactsgroup") {
    //     await this.loadFirstContactOfList();
    //   } else if (this.$route.name == "contactslist") {
    //     if (this.$store.state.currentcontact)
    //       this.setCurrent(this.$store.state.currentcontact);
    //     else await this.loadFirstContactOfList();
    //   } else if (this.$route.name == "contactdetails") {
    //     // this.contact_current_coid = this.$route.params.co_id;
    //     this.setCurrent(this.$route.params.co_id);
    //     // await this.loadContact();
    //   } else if (this.$route.name == "contactedit") {
    //     // this.contact_current_coid = this.$route.params.co_id;
    //     this.setCurrent(this.$route.params.co_id);
    //     // await this.loadContact();
    //     Vue.nextTick(() => {
    //       this.$refs.contactdetails.openWinEdit();
    //     });
    //   }
    // }
  },
  // computed: {
  //   nbcontacts() {
  //     return this.contacts_total;
  //   }
  // },
  destroyed() {
    window.addEventListener("resize", this.onResize);
  },
  async mounted() {
    // regarder la taille de l'écran pour ouvrir ou fermer les groupes
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    // // route qui va chercher tous les groupes des utilisateurs
    // this.groups = this.$store.state.mookGroups.filter(mookGroup => {
    //   mookGroup.display;
    //   if (mookGroup.display === true || mookGroup.display === false) {
    //     return mookGroup.display;
    //   } else {
    //     let ok = this.$store.getters.checkUserAccess(this.$router, mookGroup.display);
    //     //console.log("mookGroup.display,ok", mookGroup.display, ok);
    //     return ok;
    //   }
    // });

    if (this.$route.name == "contactsgroup") {
      this.group_current_grid = this.$route.params.group;
      await this.loadContacts(0);
      await this.loadFirstContactOfList();
    } else if (this.$route.name == "contactslist") {
      await this.loadContacts(0);
      if (this.$store.state.currentcontact) await this.setCurrent(this.$store.state.currentcontact);
      else await this.loadFirstContactOfList();
    } else if (this.$route.name == "contactdetails") {
      // console.log("this.$route.params.co_id", this.$route.params.co_id);
      await this.loadContacts(0);
      await this.setCurrent(this.$route.params.co_id * 1);
    } else if (this.$route.name == "contactedit") {
      await this.loadContacts(0);
      await this.setCurrent(this.$route.params.co_id * 1);
      // await this.loadContact();
      Vue.nextTick(() => {
        this.$refs.contactdetails.openWinEdit();
      });
    } else {
      await this.loadContacts(0);
    }
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
      // console.log("this.windowWidth", this.windowWidth);
      if (this.windowWidth > 992) this.groupsAreVisible = true;
      else this.groupsAreVisible = false;
    },
    changeContactsRange(skip) {
      // console.log("skip", skip);
      this.loadContacts(skip);
    },
    addNewContact() {
      this.setCurrent(-1);
      Vue.nextTick(() => {
        this.$refs.contactdetails.openWinEdit();
      });
      // this.contactWinEditOpen(-1);
    },
    addNewGroup() {},
    async setCurrent(v) {
      // console.log("v", v);
      let co_id = v;
      if (v === -1) {
        this.contact_current_coid = co_id;
        return;
      }
      if (this.$_.isPlainObject(v)) {
        co_id = v.co_id;
        // this.$store.commit("set_currentcontact", v);
        this.currentContact = v;
      } else {
        let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/contacts/" + v);
        this.currentContact = response.data.data;
        //this.$store.commit("set_currentcontact", response.data.data.ac_id);
      }
      this.contact_current_coid = co_id;
      // console.log("this.currentContact", this.currentContact);
      // this.$store.commit("set_lastcoid", co_id);
      // this.loadContact();
    },
    async loadFirstContactOfList() {
      if (this.contacts.length) {
        // this.contact_current_coid = this.contacts[0].co_id;
        await this.setCurrent(this.contacts[0]);
        // await this.loadContact();
      }
    },
    loadContactsDelay() {
      if (this.delaySearch) window.clearTimeout(this.delaySearch);
      this.delaySearch = window.setTimeout(async () => {
        await this.loadContacts();
        await this.loadFirstContactOfList();
      }, 200);
    },
    async loadContacts(skip) {
      let params = {
        gr_id: this.group_current_grid,
        skip: skip, // ?
        limit: this.contacts_limit
      };
      if (this.searchcontact) params.text = this.searchcontact;
      this.cancelAxiosLoadContacts && this.cancelAxiosLoadContacts();
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.1/contacts", {
        params,
        cancelToken: new this.$axios.CancelToken(c => {
          this.cancelAxiosLoadContacts = c;
        })
      });

      // console.log("response", response.data);
      if (!response || !response.data || !response.data.data) return;

      this.contacts_skip = skip;
      this.contacts = response.data.data;
      this.contacts_total = response.data.meta.total;
      this.tabEnIds = response.data.meta.tabEnIds;
      // console.log("response.data", response.data);
      // console.log("this.contacts", this.contacts);
      // this.contacts = this.mookContactsAll;
      // this.contacts_total = this.contacts.length;

      // for (let iContact = 0; iContact < contacts.length; iContact++) {
      //   const row_co = contacts[iContact];
      //   row_co.mListTrClass = "contact-bg-white";
      //   if (row_co.co_type != "contact")
      //     row_co.mListTrClass = "contact-bg-grey";
      //   if (row_co.co_issociety) row_co.mListTrClass = "contact-bg-black";
      // }
      if (this.$refs.contactslist) {
        // this.$refs.contactslist.update();
        //if (skip == 0) this.$refs.contactslist.scrollToTop();
      }
    },
    async groupClick(item) {
      //let gr_id = evt.target.closest("tr").getAttribute("data-gr-id");
      if (item.gr_id) {
        this.group_current_grid = item.gr_id;
        //this.$router.push("/contacts/group/" + this.group_current_grid);
        await this.loadContacts(0);
        await this.loadFirstContactOfList();
      }
    },
    contactClick(row_co, indexCo, evt) {
      // let co_id = evt.target.closest("tr").getAttribute("data-co-id");
      if (row_co && row_co.co_id) {
        this.$router.push("/contacts/" + row_co.co_id + "/details").catch(err => {});
        // this.contact_current_coid = row_co.co_id;
        this.setCurrent(row_co);
        // this.loadContact();
      }
    },
    // async loadContact() {
    //   let params = {};
    //   let response = await this.$axios.get(
    //     this.$config.server_url + "/api/1.0/contacts/" + this.contact_current_coid,
    //     { params }
    //   );
    //   this.contact = response.data.data;
    //   // this.loadSuivi(response.data.data.co_id);
    //   this.$router.push("/contacts/" + this.contact.co_id + "/details");
    // },

    contactWinEditOpen(co_id) {
      this.$router.push("/contacts/" + co_id + "/edit").catch(err => {});
    },
    contactWinEditSaved(co_id) {
      // this.contact_current_coid = co_id;
      this.setCurrent(co_id);
      this.$router.push("/contacts/" + co_id + "/details").catch(err => {});
      this.loadContacts(this.contacts_skip);
      // this.loadContact();
    },
    contactWinEditCanceled(co_id) {
      if (!co_id) return this.loadFirstContactOfList();
      this.$router.push("/contacts/" + co_id + "/details").catch(err => {});
    },
    async contactWinEditDeleted(co_id) {
      await this.loadContacts(this.contacts_skip);
      await this.loadFirstContactOfList();
      if (this.contacts.length) {
        this.$router.push("/contacts/" + this.contacts[0].co_id + "/details").catch(err => {});
      }
    }
  },
  filters: {
    /*     formatContactName: function(contact) {
      if (!contact) return;
      return contact.co_name.toUpperCase() + " " + contact.co_firstname;
    } */
  }
};
</script>

<style lang="scss">
// @import "../baseStyle.scss";
// @import "../sass/variables.scss";
.table-list {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    display: block;
    cursor: pointer;
    td {
      padding: 2px 10px;
      font-size: 0.75rem;
      div.txtoverflow {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    // &:hover {
    //   // background-color: $mygris;
    // }
    // &.active {
    //   // background-color: $mybleu-clair;
    //   // td {
    //   //   color: $mybleu-fonce;
    //   //   i {
    //   //     color: $mybleu-fonce;
    //   //   }
    //   // }
    // }
  }
}
@media (min-width: 992px) {
  .card-group {
    height: 100%;
  }
}
// .header-emotivi {
//   // height: 24px;
//   // min-height: 70px !important;
//   margin-bottom: 15px;
// }
</style>
