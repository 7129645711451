<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div>
              <h1>Édition de la box numéro {{ boxe.bo_id }}</h1>
              <p></p>
            </div>
            <!--  <div class="d-flex flex-row align-items-center active">
              <label class="label-checkbox" for="active">Activer</label>
              <input type="checkbox" id="actif" name="actif" v-model="row_bo.bo_active" />
            </div> -->
          </div>
          <div class="modal-body modal-padding">
            <div class="d-flex flex-row align-items-center active">
              <input type="checkbox" id="tvmobile" name="tvmobile" v-model="boxe.bo_tvmobile" />
              <label class="label-checkbox" for="tvmobile">Télévision mobile</label>
            </div>
            <hr />
            <!-- <m-form-select placeholder="Modèle TV" :name="$Utils.randomstring('modeltv')" :items="items"></m-form-select>
            <m-form-select
              placeholder="Résolution TV"
              v-model="resolutionSelected.value"
              :name="$Utils.randomstring('resolutiontv')"
              :items="resolutionItems"
            ></m-form-select>
            <div class="mt-3">
              <h5>Paramétrage des sources vidéo</h5>
              <div class="d-flex flex-row align-items-end">
                <div class="">Entrée box :</div>
                <div>
                  <m-form-text
                    placeholder="Entrée box"
                    v-model="boxe.bo_config_sources.source_emotivi_ir"
                    name="entreebox"
                    class="ml-1"
                    autocomplete
                  ></m-form-text>
                </div>
                <div>
                  <m-form-checkbox
                    v-model="boxe.bo_config_sources.source_emotivi_use_cec"
                    label="Utilise CEC"
                    :name="$Utils.randomstring('box_usecec')"
                    class="ml-3 mb-0"
                  ></m-form-checkbox>
                </div>
                <div>
                  <m-form-checkbox
                    v-model="boxe.bo_config_sources.source_emotivi_use_ir"
                    label="Utilise IR"
                    :name="$Utils.randomstring('box_useir')"
                    class="ml-2 mb-0"
                  ></m-form-checkbox>
                </div>
              </div>
              <m-form-text
                label="TV > Box, code infrarouge"
                v-model="boxe.bo_config_sources.source_emotivi_ir"
                name="tvboxcodeir"
                autocomplete
              ></m-form-text>
              <div class="d-flex flex-row align-items-end">
                <div class="">Entrée chaines :</div>
                <div>
                  <m-form-text
                    placeholder="Entrée chaine"
                    v-model="boxe.bo_config_sources.source_tv"
                    name="entreechaine"
                    class="ml-1"
                    autocomplete
                  ></m-form-text>
                </div>
                <div>
                  <m-form-checkbox
                    v-model="boxe.bo_config_sources.source_tv_use_cec"
                    label="Utilise CEC"
                    :name="$Utils.randomstring('box_usecec')"
                    class="ml-3 mb-0"
                  ></m-form-checkbox>
                </div>
                <div>
                  <m-form-checkbox
                    label="Utilise IR"
                    v-model="boxe.bo_config_sources.source_tv_use_ir"
                    :name="$Utils.randomstring('box_useir')"
                    class="ml-2 mb-0"
                  ></m-form-checkbox>
                </div>
              </div>
              <m-form-text
                label="Box > TV, code infrarouge"
                v-model="boxe.bo_config_sources.source_tv_ir"
                name="tvboxcodeir"
                autocomplete
              ></m-form-text>
              <div class="d-flex flex-row">
                <m-form-text
                  label="Box > TV, attente impulsion"
                  v-model="boxe.bo_config_sources.source_ir_duration"
                  name="tvboxcodeir"
                  class=" flex-grow-1"
                  autocomplete
                ></m-form-text>
                <m-form-text
                  label="Box > TV, utilise les impulsions"
                  v-model="boxe.bo_config_sources.source_ir_impulse"
                  name="tvboxcodeir"
                  class="ml-3 flex-grow-1"
                  autocomplete
                ></m-form-text>
              </div> -->
            <!--               <m-form-text
                label="Code infrarouge, son plus"
                v-model="boxe.bo_config_sources.source_ir_sound_plus"
                name="tvboxcodeir"
                autocomplete
              ></m-form-text>
              <m-form-text
                label="Code infrarouge, son moins"
                v-model="boxe.bo_config_sources.source_ir_sound_minus"
                name="tvboxcodeir"
                autocomplete
              ></m-form-text>
              <m-form-text
                label="Code infrarouge, allumer TV"
                v-model="boxe.bo_config_sources.source_ir_switch_on"<m-form-checkbox
                label="Déconnection de la box"
                v-model="boxe.bo_send_alert_disconnection"
                :name="$Utils.randomstring('bo_send_alert_disconnection')"
                class="mr-3"
              ></m-form-checkbox>
              <m-form-checkbox
                label="Niveau de pile du bouton faible"
                v-model="boxe.bo_send_alert_button_battery_low"
                :name="$Utils.randomstring('bo_send_alert_button_battery_low')"
                class="mr-3"
              ></m-form-checkbox>
              ></m-form-text>
              <m-form-text
                label="Code infrarouge, éteindre TV"
                v-model="boxe.bo_config_sources.source_ir_switch_off"
                name="tvboxcodeir"
                autocomplete
              ></m-form-text> -->
            <!--  </div> -->
            <div class="mt-3">
              <h5>Plages horaires des appels vidéo</h5>
              <div class="row">
                <div class="col-md-6">
                  <m-form-checkbox
                    label="Désactiver les horaires"
                    v-model="boxe.bo_disable_callhours"
                    :name="$Utils.randomstring('disablehours')"
                    class=""
                  ></m-form-checkbox>
                  <div v-for="(plage, index) in manageHourReformat" :key="index" class="mb-2">
                    <div class="d-flex flex-row align-items-end">
                      <v-time-picker format="HHHmm" v-model="plage.start_time" class="time-picker"></v-time-picker>
                      <v-time-picker format="HHHmm" v-model="plage.end_time" class="time-picker ml-2"></v-time-picker>
                      <div @click="deletePlage(plage, index)" class="ml-2 mb-1 pointer">
                        <icon width="25" height="25" name="times"> </icon>
                      </div>
                      <!--  <m-form-hour placeholder="Heure de début" :name="$Utils.randomstring('start')" v-model="plage.start"></m-form-hour>
                  <m-form-hour class="ml-2" placeholder="Heure de fin" :name="$Utils.randomstring('end')" v-model="plage.end"></m-form-hour> -->
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                    <button @click="addPlage = true" type="button" class="btn btn-primary mt-2">Ajouter une plage horaire</button>
                  </div>
                  <div v-if="addPlage" class="d-flex flex-row align-items-end mt-2">
                    <div class="d-flex flex-column">
                      <div>Heure de début</div>
                      <v-time-picker v-model="newHourStart" class="time-picker" :minute-interval="10"></v-time-picker>
                    </div>
                    <div class="d-flex flex-column">
                      <div>Heure de fin</div>
                      <v-time-picker v-model="newHourEnd" class="time-picker ml-2" :minute-interval="10"></v-time-picker>
                    </div>
                    <button @click="addPlageGo" type="button" class="btn btn-primary ml-2">Ajouter</button>
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <h5>Options des appels</h5>
            <m-form-checkbox
              label="Décrochage automatique activé pour l’établissement et l’administrateur"
              v-model="boxe.bo_autoresponse"
              :name="$Utils.randomstring('bo_autoresponse')"
              class="mr-3"
              @input="onChangeAutoresponse"
            ></m-form-checkbox>
            <m-form-checkbox
              label="Décrochage automatique activé pour tous (établissement, administrateur et membres de la famille)"
              v-model="boxe.bo_autoresponse_forall"
              :name="$Utils.randomstring('bo_autoresponse_forall')"
              class="mr-3"
              :disabled="!boxe.bo_autoresponse"
            ></m-form-checkbox>
            <m-form-checkbox
              label="Désactiver l'appel vidéo"
              v-model="boxe.bo_disable_video"
              :name="$Utils.randomstring('bo_disable_video')"
              class="mr-3"
            ></m-form-checkbox>
            <div>
              <hr />
              <h5>Envoyer une alerte email au service technique</h5>
              <m-form-checkbox
                label="Déconnexion de la box"
                v-model="boxe.bo_send_alert_disconnection"
                :name="$Utils.randomstring('bo_send_alert_disconnection')"
                class="mr-3"
              ></m-form-checkbox>
              <m-form-checkbox
                label="Niveau de pile du bouton faible"
                v-model="boxe.bo_send_alert_button_battery_low"
                :name="$Utils.randomstring('bo_send_alert_button_battery_low')"
                class="mr-3"
              ></m-form-checkbox>
            </div>

            <div v-if="isBoxCompatible('options_bistri_display_preview')">
              <hr />
              <h5>Options durant l'appel vidéo</h5>
              <m-form-checkbox
                label="Activer l’aperçu du senior sur son téléviseur lors d’un appel vidéo"
                v-model="screenDisplayPreview"
                :name="$Utils.randomstring('options_bistri_display_preview')"
                class="mr-3"
              ></m-form-checkbox>
              <div v-show="screenDisplayPreview" class="alert alert-secondary">
                <p class="section-subtitle">Options de l'aperçu</p>
                <label for="screenPreview">Sélectionner l'emplacement de l'aperçu :</label>
                <div id="screenpreview" class="screenPreview">
                  <icon class="screenPreviewTv" width="100%" height="100%" name="tv" color="#afadad"></icon>
                  <div class="screenPreviewChild mt-3">
                    <m-form-checkbox v-model="screenPreviewTopLeft" :name="$Utils.randomstring('topleft')" class="mr-3"></m-form-checkbox>
                    <m-form-checkbox v-model="screenPreviewTopRight" :name="$Utils.randomstring('topright')" class="ml-3"></m-form-checkbox>
                  </div>
                  <div class="screenPreviewChild">
                    <m-form-checkbox v-model="screenPreviewBottomLeft" :name="$Utils.randomstring('bottomleft')" class="mr-3"></m-form-checkbox>
                    <m-form-checkbox v-model="screenPreviewBottomRight" :name="$Utils.randomstring('bottomright')" class="ml-3"></m-form-checkbox>
                  </div>
                </div>
                <div>
                  <m-form-select
                    label="Taille de l'aperçu :"
                    label-position="top"
                    :name="$Utils.randomstring('screenPreviewSize')"
                    v-model="screenPreviewSize"
                    :items="screenPreviewSizes"
                  ></m-form-select>
                </div>
              </div>
            </div>
            <div v-if="row_bo.bo_hardwareinfo && row_bo.bo_hardwareinfo.output_volume && row_bo.bo_hardwareinfo.input_volume">
              <hr />
              <h5>Gérer les niveaux audio</h5>

              <div class="d-flex align-items-center">
                <span class="d-flex align-items-center mr-5 col-6"
                  >Sortie audio HDMI (
                  {{ row_bo.bo_hardwareinfo && row_bo.bo_hardwareinfo.output_volume ? row_bo.bo_hardwareinfo.output_volume : "" }} ) :</span
                >
                <div class="col-2">
                  <button type="button" class="btn border btn-light text-info m-1" @click="audioLevel('output-volume-down')">
                    <b>-</b>
                  </button>
                  <button type="button" class="btn border btn-light text-info m-1" @click="audioLevel('output-volume-up')">
                    <b>+</b>
                  </button>
                </div>
                <div class="col-3 d-flex align-items-center">
                  <m-form-text
                    style="margin: 0"
                    min="0"
                    max="100"
                    placeholder="0 - 100"
                    ref="outputVolume"
                    label="En pourcentage"
                    type="number"
                    v-model="outputVolumeValue"
                  ></m-form-text>
                  <button
                    style="align-self: flex-end"
                    type="button"
                    class="btn border btn-light text-info"
                    @click="audioLevel('output-volume', outputVolumeValue)"
                  >
                    <icon width="15" height="15" name="save"></icon>
                  </button>
                </div>
              </div>
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center">
                  <span class="d-flex align-items-center mr-5 col-6"
                    >Gain du microphone de la webcam ( {{ row_bo.bo_hardwareinfo.input_volume }} ) :</span
                  >
                  <div class="col-2">
                    <button type="button" class="btn border btn-light text-info m-1" @click="audioLevel('input-volume-down')">
                      <b>-</b>
                    </button>
                    <button type="button" class="btn border btn-light text-info m-1" @click="audioLevel('input-volume-up')">
                      <b>+</b>
                    </button>
                  </div>
                  <div class="col-3 d-flex align-items-center">
                    <m-form-text
                      style="margin: 0"
                      min="0"
                      max="100"
                      placeholder="0 - 100"
                      ref="inputVolume"
                      label="En pourcentage"
                      type="number"
                      v-model="inputVolumeValue"
                    ></m-form-text>
                    <button
                      style="align-self: flex-end"
                      type="button"
                      class="btn border btn-light text-info"
                      @click="audioLevel('input-volume', inputVolumeValue)"
                    >
                      <icon width="15" height="15" name="save"></icon>
                    </button>
                  </div>
                </div>
                <div
                  v-if="majorBoxVersion === 2 && minorBoxVersion <= 17 && row_bo.bo_hardwareinfo.auto_input_volume"
                  class="d-flex align-items-center"
                >
                  <m-form-checkbox label="Ajustement automatique du gain du microphone" v-model="autoInputVolume"> </m-form-checkbox>
                </div>
              </div>
            </div>
            <div v-if="isBoxCompatible('options_value_leds_duration')">
              <hr />
              <h5>Gestion des notifications</h5>

              <div class="alert alert-secondary mt-2">
                <p class="section-subtitle">Pour les messages</p>
                <m-form-checkbox
                  label="Désactiver la notification lumineuse"
                  v-model="ledsDisableDuration"
                  :name="$Utils.randomstring('ledsDisableDuration')"
                  class="mr-3"
                ></m-form-checkbox>
                <m-form-hour
                  class="mx-1"
                  style="overflow-wrap: normal"
                  label="Durée maximale de la notification lumineuse (heures:minutes)"
                  :name="$Utils.randomstring('ledsDuration')"
                  v-model="ledsDurationFormatted"
                ></m-form-hour>
              </div>
              <div
                v-if="isBoxCompatible('options_leds_disabled') && isBoxCompatible('options_value_ringing_number')"
                class="alert alert-secondary mt-2"
              >
                <p class="section-subtitle">Pour les appels</p>
                <m-form-checkbox
                  label="Désactiver la notification lumineuse"
                  v-model="ledsDisabled"
                  :name="$Utils.randomstring('ledsDisabled')"
                  class="mr-3"
                ></m-form-checkbox>
                <m-form-checkbox
                  label="Désactiver la sonnerie"
                  v-model="ringingDisable"
                  :name="$Utils.randomstring('ringingDisable')"
                  class="mr-3"
                ></m-form-checkbox>
                <m-form-text
                  label="Nombre de sonnerie"
                  type="number"
                  v-model="ringingNumber"
                  :name="$Utils.randomstring('ringingNumber')"
                  class="mr-3"
                ></m-form-text>
              </div>
            </div>

            <!-- button functionalities options -->
            <div v-if="isBoxCompatible('options_value_button_functionality') && this.enableButtonFunctionality">
              <h5>Gestion du bouton</h5>
              <div class="col-12">
                <div v-if="dialogErr" class="alert alert-danger">
                  {{ dialogErrTxt }}
                </div>
              </div>
              <div class="alert alert-secondary mt-2">
                <p class="section-subtitle">Comportement au clique du bouton</p>
                <m-form-combobox
                  class="flex-grow-1 basis-50"
                  label="Comportement par défaut"
                  label-position="top"
                  :name="$Utils.randomstring('bf_id')"
                  v-model="row_bf"
                  :autoSelectOnFocus="true"
                  :store-url="$config.server_url + '/extranet/2.0/buttonFunctionalities/enduser/' + this.row_en.en_id"
                  :store-params="{ bf_id: row_bf.bf_id }"
                  item-value="bf_name"
                  item-text="bf_name"
                ></m-form-combobox>
                <div class="col-12 mt-3" v-if="row_bf.pc_id != 'none'">
                  <label for="">Message qui sera envoyé :</label>
                  <div class="bg-white col-12">
                    <span style="word-break: break-word">{{ row_bf.bf_message }}</span>
                  </div>
                </div>
                <m-form-text
                  v-if="row_bf.pc_id != 'none'"
                  :label="label"
                  v-model="row_bfd.communication"
                  type="text"
                  :placeholder="placeholder"
                  :name="$Utils.randomstring('communication')"
                ></m-form-text>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between">
            <button type="button" class="btn btn-danger" @click="deleteConfirmWin">Supprimer</button>
            <div>
              <button type="button" class="btn btn-secondary" @click="cancelWin">Annuler</button>
              <button type="button" class="btn btn-primary btn-mouveal ml-2" @click="saveWin(false)">Enregistrer</button>
            </div>
          </div>
        </div>
      </div>
      <m-confirm-dialog
        v-model="confirmdelete"
        text="Souhaitez-vous effacer cette box ?"
        title="Confirmation"
        width="500px"
        @canceled="confirmdelete = false"
        @confirmed="deleteWin()"
      ></m-confirm-dialog>
    </div>
  </transition>
</template>

<script>
import { phone } from "phone";
export default {
  name: "BoxeWinEdit",
  components: {},
  props: {
    row_bo: Object,
    row_en: Object,
    value: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      boxe: { bo_config_sources: {}, bo_config_resolution: { current: {}, availables: [] } },
      confirmdelete: false,
      items: [
        { text: "rouge", value: "0" },
        { text: "vert", value: "1" },
        { text: "rose", value: "2" },
        { text: "orange", value: "3" }
      ],
      // résolution
      resolutionSelected: {},
      resolutionItems: [],
      // les plages horaires
      manageHour: [],
      manageHourReformat: [],
      newHourStart: "",
      newHourEnd: "",
      addPlage: false,
      outputVolumeValue: 0,
      inputVolumeValue: 0,
      majorBoxVersion: 0,
      minorBoxVersion: 0,
      screenPreviewSizes: [
        { text: "Grande", value: 0.35 },
        { text: "Moyenne", value: 0.25 },
        { text: "Petite", value: 0.15 }
      ],

      ledsDisableDuration: false,
      ringingDisable: false,
      row_bf: {
        pc_id: ""
      },
      label: "",
      placeholder: "",
      row_bfd: { communication: "" },
      dialogErr: false,
      enableButtonFunctionality: false
    };
  },
  watch: {
    ledsDisableDuration(value) {
      if (value) {
        this.boxe.bo_config_options.options_value_leds_duration = 0;
      } else if (this.boxe.bo_config_options.options_value_leds_duration === 0) this.boxe.bo_config_options.options_value_leds_duration = 7200;
    },
    ledsDuration(value) {
      if (!value) {
        this.ledsDisableDuration = true;
      } else this.ledsDisableDuration = false;
    },
    ringingDisable(value) {
      if (value) {
        this.boxe.bo_config_options.options_value_ringing_number = 0;
      } else if (this.boxe.bo_config_options.options_value_ringing_number === 0) this.boxe.bo_config_options.options_value_ringing_number = 5;
    },
    ringingNumber(value) {
      if (!value) {
        this.ringingDisable = true;
      } else this.ringingDisable = false;
    },
    row_bf(value) {
      if (value) {
        if (this.row_bf.pc_id === "sms") {
          if (!this.checkPhone(this.row_bfd.communication).isValid) {
            this.row_bfd.communication = "";
          }
          this.label = "Téléphone";
          this.placeholder = "ex: 123456789";
        } else if (this.row_bf.pc_id === "email") {
          if (!this.matchEmail(this.row_bfd.communication)) {
            this.row_bfd.communication = "";
          }
          this.label = "Email";
          this.placeholder = "ex: monemail@email.fr";
        }
      }
    }
  },
  computed: {
    autoInputVolume: {
      get() {
        return this.row_bo.bo_hardwareinfo.auto_input_volume && this.row_bo.bo_hardwareinfo.auto_input_volume === "yes" ? true : false;
      },
      set(value) {
        this.audioLevel("auto-input-volume");
        return (this.row_bo.bo_hardwareinfo.auto_input_volume = value ? "yes" : "no");
      }
    },
    ledsDurationFormatted: {
      get() {
        return this.$moment.utc(this.ledsDuration * 1000).format("HH:mm");
      },
      set(value) {
        this.boxe.bo_config_options.options_value_leds_duration = this.$moment(value, "HH:mm:ss").diff(this.$moment().startOf("day"), "seconds");
      }
    },
    screenDisplayPreview: {
      get() {
        return this.boxe.bo_config_options && this.boxe.bo_config_options.hasOwnProperty("options_bistri_display_preview")
          ? this.boxe.bo_config_options.options_bistri_display_preview
          : false;
      },
      set(value) {
        if (this.boxe.bo_config_options) this.boxe.bo_config_options.options_bistri_display_preview = value;
      }
    },
    screenPreviewTopLeft: {
      get() {
        if (
          this.boxe.bo_config_options &&
          this.boxe.bo_config_options.options_value_bistri_preview_valign === "top" &&
          this.boxe.bo_config_options.options_value_bistri_preview_halign === "left"
        )
          return true;
        else return false;
      },
      set(value) {
        if (this.boxe.bo_config_options && value) {
          this.boxe.bo_config_options.options_value_bistri_preview_valign = "top";
          this.boxe.bo_config_options.options_value_bistri_preview_halign = "left";
        }
      }
    },
    screenPreviewTopRight: {
      get() {
        if (
          this.boxe.bo_config_options &&
          this.boxe.bo_config_options.options_value_bistri_preview_valign === "top" &&
          this.boxe.bo_config_options.options_value_bistri_preview_halign === "right"
        )
          return true;
        else return false;
      },
      set(value) {
        if (this.boxe.bo_config_options && value) {
          this.boxe.bo_config_options.options_value_bistri_preview_valign = "top";
          this.boxe.bo_config_options.options_value_bistri_preview_halign = "right";
        }
      }
    },
    screenPreviewBottomLeft: {
      get() {
        if (
          this.boxe.bo_config_options &&
          this.boxe.bo_config_options.options_value_bistri_preview_valign === "bottom" &&
          this.boxe.bo_config_options.options_value_bistri_preview_halign === "left"
        )
          return true;
        else return false;
      },
      set(value) {
        if (this.boxe.bo_config_options && value) {
          this.boxe.bo_config_options.options_value_bistri_preview_valign = "bottom";
          this.boxe.bo_config_options.options_value_bistri_preview_halign = "left";
        }
      }
    },
    screenPreviewBottomRight: {
      get() {
        if (
          this.boxe.bo_config_options &&
          this.boxe.bo_config_options.options_value_bistri_preview_valign === "bottom" &&
          this.boxe.bo_config_options.options_value_bistri_preview_halign === "right"
        )
          return true;
        else return false;
      },
      set(value) {
        if (this.boxe.bo_config_options && value) {
          this.boxe.bo_config_options.options_value_bistri_preview_valign = "bottom";
          this.boxe.bo_config_options.options_value_bistri_preview_halign = "right";
        }
      }
    },
    screenPreviewSize: {
      get() {
        return this.boxe.bo_config_options && this.boxe.bo_config_options.options_value_bistri_preview_size
          ? this.boxe.bo_config_options.options_value_bistri_preview_size
          : 0.25;
      },
      set(value) {
        if (this.boxe.bo_config_options && this.boxe.bo_config_options.hasOwnProperty("options_value_bistri_preview_size")) {
          this.boxe.bo_config_options.options_value_bistri_preview_size = value;
        }
      }
    },

    ledsDuration() {
      return this.boxe.bo_config_options &&
        (this.boxe.bo_config_options.options_value_leds_duration || this.boxe.bo_config_options.options_value_leds_duration === 0)
        ? this.boxe.bo_config_options.options_value_leds_duration
        : 7200;
    },
    ringingNumber: {
      get() {
        return this.boxe.bo_config_options &&
          (this.boxe.bo_config_options.options_value_ringing_number || this.boxe.bo_config_options.options_value_ringing_number === 0)
          ? this.boxe.bo_config_options.options_value_ringing_number
          : 5;
      },
      set(value) {
        this.boxe.bo_config_options.options_value_ringing_number = value;
      }
    },
    ledsDisabled: {
      get() {
        return this.boxe.bo_config_options && this.boxe.bo_config_options.options_leds_disabled
          ? this.boxe.bo_config_options.options_leds_disabled
          : false;
      },
      set(value) {
        this.boxe.bo_config_options.options_leds_disabled = value;
      }
    }
  },

  async mounted() {
    window.addEventListener("keyup", this.onKeyup);
    // get formated box version
    this.setOptionsData();
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  created() {},
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    onChangeAutoresponse() {
      if (!this.boxe.bo_autoresponse) {
        this.boxe.bo_autoresponse_forall = false;
      }
    },
    addPlageGo() {
      if (!this.newHourStart || !this.newHourEnd) return;
      this.manageHourReformat.push({
        start_time: { HH: this.newHourStart.split(":")[0], mm: this.newHourStart.split(":")[1] },
        end_time: { HH: this.newHourEnd.split(":")[0], mm: this.newHourEnd.split(":")[1] }
      });
      this.newHourStart = "";
      this.newHourEnd = "";
      this.addPlage = false;
    },
    deletePlage(plage, index) {
      // console.log("plage, index", plage, index);
      this.manageHourReformat.splice(index, 1);
    },
    async loadbox() {
      //   let params = {
      //   };
      //   let response = await this.$axios.get(
      //     this.$config.server_url + "/api/1.0/contacts/" + this.boxe_id,
      //     { params }
      //   );
      //   if (!response || !response.data) return;
      //   let row_fo = response.data.data;
      // console.log("this.manageHour", this.manageHour);
    },
    async saveWin() {
      //la résolution
      // console.log("this.resolutionSelected", this.resolutionSelected);
      // let f = this.$_.find(this.resolutionItems, { value: this.resolutionSelected.value });
      // console.log("f", f);
      // this.boxe.bo_config_resolution.current = { key: f.value, value: f.text };
      //les plages horaires
      this.manageHour = [];
      for (let iplage = 0; iplage < this.manageHourReformat.length; iplage++) {
        const plage = this.manageHourReformat[iplage];
        this.manageHour.push({ start: plage.start_time.HH + ":" + plage.start_time.mm, end: plage.end_time.HH + ":" + plage.end_time.mm });
      }
      this.boxe.bo_callhours = JSON.stringify(this.manageHour);
      if (this.isBoxCompatible("options_value_button_functionality") && this.enableButtonFunctionality) {
        /** manage button functionalities options */
        let err = [];
        let checkPhone;

        checkPhone = this.checkPhone(this.row_bfd.communication);
        
        if (this.row_bf.pc_id === "sms") {
          this.row_bf.communication = checkPhone.phoneNumber;
          if (!checkPhone.isValid) {
            err.push("Le numéro de téléphone doit être un numéro de téléphone valide");
            this.dialogErrTxt = "Le numéro de téléphone doit être du territoire français et valide!";
          }
        } else if (this.row_bf.pc_id === "email") {
          this.row_bf.communication = this.row_bfd.communication;
          if (!this.matchEmail(this.row_bfd.communication)) {
            err.push("L'adresse email n'est pas valide");
            this.dialogErrTxt = "L'adresse email n'est pas valide!";
          }
        } else {
          this.row_bf.communication = "";
        }

        if (err.length) {
          this.dialogErr = true;
          return;
        } else {
          this.row_bf.en_id = this.row_en.en_id;
          let response2 = await this.$axios.post(this.$config.server_url + "/extranet/2.0/buttonFunctionalities/enduser", this.row_bf);
          if (response2.data.data) {
            this.boxe.bo_config_options.options_value_button_functionality = response2.data.data.bf_id;
          } else {
            this.boxe.bo_config_options.options_value_button_functionality = 0;
          }
        }
      }

      let response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/boxes/" + this.boxe.bo_id, this.boxe);

      if (response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }

      this.dialogErr = false;
      this.dialogErrTxt = "";
      this.$emit("input", false);
      this.$emit("saved");
      //this.$router.push("/boxes/" + this.row_bo.bo_id);
      this.boxe = { bo_config_sources: {}, bo_config_resolution: { current: {}, availables: [] } };
    },
    cancelWin() {
      // this.$router.push("/boxes/" + this.row_bo.bo_id);
      this.$emit("input", false);
      this.$emit("canceled");
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteWin() {
      await this.$axios.delete(this.$config.server_url + "/api/1.0/boxes/" + this.boxe_id);
      this.confirmdelete = false;
      //this.$router.push("/boxes/" + this.row_bo.bo_id);
      this.$emit("input", false);
      this.$emit("canceled");
    },
    /**
     * audioLevel - set the volume on the box with amixer
     * @params action - ( output-volume-up || output-volume-down || output-input-up || output-input-down  )
     */
    async audioLevel(action, volumeLevel) {
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/do/set-audio-level/" + this.row_en.en_id, {
        params: { action, volumeLevel }
      });
      if (response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }
    },
    formatBoxVersion() {
      try {
        // get formated box version
        // ex 2.18.0 === [2,18,0]
        if (this.boxe.bo_hardwareinfo && this.boxe.bo_hardwareinfo.appemotivi_version) {
          let boxVersion = this.boxe.bo_hardwareinfo.appemotivi_version.split(".");
          this.majorBoxVersion = parseInt(boxVersion[0]);
          this.minorBoxVersion = parseInt(boxVersion[1]);
        }
      } catch (error) {
        console.error("cannot format box version : ", error);
      }
    },
    async saveOptions(option, data) {
      let response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/do/save-box-options/" + this.row_en.en_id, { option, data });
      if (response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }
    },
    /**
     * isBoxCompatible - check if the current box version is compatible with new options
     * @param {Object} row_en
     * @returns {Boolean}
     */
    isBoxCompatible(withWhat) {
      try {
        return this.boxe && this.boxe.bo_config_options && this.boxe.bo_config_options.hasOwnProperty(withWhat);
      } catch (error) {
        return false;
      }
    },
    matchEmail(email) {
      return email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    },
    checkPhone(telephone) {
      let result = phone(telephone, { country: "FRA" });
      return result;
      // console.log("phone", result);
    },
    async loadDefaultconf() {
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/buttonFunctionalities/enduser/" + this.row_en.en_id);
      if (response.data.defaultFunctionality) {
        this.row_bf = response.data.defaultFunctionality;
        this.row_bfd.communication = response.data.defaultFunctionality.moyenCommunication;
      } else if (!response.data.defaultFunctionality && response.data.data && Array.isArray(response.data.data) && response.data.data.length > 0) {
        for (let i = 0; i < response.data.data.length; i++) {
          let row = response.data.data[i];
          let index = row.bf_key.indexOf("diaporama");
          if (index !== -1) {
            this.row_bf = row;
            break;
          }
        }
      }
      return;
    },
    /**
     * hasOnlyDiaporamaFunctionality - check if the list of button functionalities have more than diaporama, enable the option if it's the case
     */
    async hasOnlyDiaporamaFunctionality() {
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/buttonFunctionalities/enduser/" + this.row_en.en_id);
      if (!response || !response.data || !response.data.data || !Array.isArray(response.data.data)) return;
      let otherFunctionalities = response.data.data.filter(functionality => {
        return functionality.bf_key.indexOf("diaporama") === -1;
      });
      if (otherFunctionalities.length > 0) this.enableButtonFunctionality = true;
    },
    async setOptionsData() {
      this.boxe = this.row_bo;
      this.formatBoxVersion();
      //les plages hoaraires
      if (this.boxe.bo_callhours) this.manageHour = this.boxe.bo_callhours;
      this.manageHourReformat = [];
      for (let iplage = 0; iplage < this.manageHour.length; iplage++) {
        const plage = this.manageHour[iplage];
        let obj = {};

        obj.start_time = { HH: plage.start.split(":")[0], mm: plage.start.split(":")[1] };
        obj.end_time = { HH: plage.end.split(":")[0], mm: plage.end.split(":")[1] };
        this.manageHourReformat.push(obj);
        // console.log("this.manageH", this.manageHourReformat);
      }
      // la résolution
      this.resolutionItems = [];
      for (let ir = 0; ir < this.boxe.bo_config_resolution.availables.length; ir++) {
        const resolution = this.boxe.bo_config_resolution.availables[ir];
        let text = resolution.value;
        let value = resolution.key;
        this.resolutionItems.push({ text, value });
      }
      if (this.boxe.bo_config_resolution.current) {
        this.resolutionSelected = {
          value: this.boxe.bo_config_resolution.current.key,
          text: this.boxe.bo_config_resolution.current.value
        };
      }
      if (this.boxe.bo_hardwareinfo && this.boxe.bo_hardwareinfo.output_volume)
        this.outputVolumeValue = this.boxe.bo_hardwareinfo.output_volume.replace("%", "");
      if (this.boxe.bo_hardwareinfo && this.boxe.bo_hardwareinfo.input_volume)
        this.inputVolumeValue = this.boxe.bo_hardwareinfo.input_volume.replace("%", "");

      // get formated box version
      this.formatBoxVersion();
      if (this.isBoxCompatible("options_value_button_functionality")) {
        await this.loadDefaultconf();
        await this.hasOnlyDiaporamaFunctionality();
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";
.time-picker input {
  border-radius: 4px;
}
.modal-footer {
  margin-top: 50px;
}
.screenPreview {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100px;
}
.screenPreviewChild {
  display: flex;
  margin-left: 17px;
  margin-bottom: -5px;
  width: 100px;
  justify-content: space-around;
  z-index: 1;
  height: 35px;
}
.screenPreviewTv {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  font-weight: 100;
  z-index: 0;
}
.section-subtitle {
  color: #199aaa;
  font-weight: bold;
}
h5 {
  color: #91295a;
  margin-bottom: 1rem;
}
</style>
