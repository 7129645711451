<template>
  <section class="d-flex w-100">
    <div class="d-flex flex-column" id="invoices">
      <div class="row header-emotivi mb-3 justify-content-around align-items-end">
        <div class="col">
          <h1>Comptabilité</h1>
        </div>
        <div class="col-md-3">
          <m-form-select label="Documents" :name="$Utils.randomstring('documents')" v-model="documentType" :items="documentTypesList"></m-form-select>
        </div>
      </div>
      <div class="flex-grow-1 overflow-hidden d-flex">
        <div v-show="documentType == 1" class="card card-no-margin">
          <h2>{{ invoices_total }} factures</h2>
          <div class="flex-grow-1 d-flex justify-content-end align-items-start mb-3" style="margin-top: -60px">
            <m-form-combobox
              :width="250"
              label="Rechercher par contact"
              label-position="top"
              :name="$Utils.randomstring('filterContact')"
              v-model="filterContact"
              :store-url="$config.server_url + '/extranet/2.0/allcontacts'"
              :store-params="{}"
              item-value="co_id"
              :item-text="showContactName"
              @input="loadInvoices"
            ></m-form-combobox>
            <m-form-text
              class="ml-3"
              label="Rechercher"
              label-position="top"
              v-model="filterText"
              name="search"
              autocomplete
              @input="loadInvoices"
            ></m-form-text>
            <button class="ml-3 btn btn-sm btn-primary color-white" type="button" @click="addInvoices" style="margin-top: 39px">
              <icon name="plus" scale="1" class="mr-2"></icon> Nouveau
            </button>
          </div>
          <m-list
            ref="invoiceslist"
            :dynamic="true"
            :items="invoices"
            item-value="or_id"
            :item-height="30"
            :limit="invoices_limit"
            :skip="invoices_skip"
            :total="invoices_total"
            :bgstrip="true"
            @changerange="invoicesChangerange"
            @itemclick="editInvoices"
          >
            <template v-slot:ths="{}">
              <th style="width: 150px" @click="sortOrderItems(['createdAt'])">
                <span class="pointer">Date de création</span>
              </th>
              <th style="width: 120px" @click="sortOrderItems(['or_num'])">
                <span class="pointer">Num</span>
              </th>
              <th class="" @click="sortOrderItems(['or_name'])">
                <span class="pointer">Nom</span>
              </th>
              <th>
                <span class="pointer">Contact</span>
              </th>
              <th style="width: 150px" @click="sortOrderItems(['or_status'])">
                <span class="pointer">Statut</span>
              </th>
              <th style="width: 150px; text-align: right" @click="sortOrderItems(['or_status'])">
                <span class="pointer">Somme TTC</span>
              </th>
              <th style="width: 120px">
                <span class="pointer">Imprimer</span>
              </th>
            </template>
            <template v-slot:tds="{ item }">
              <td style="width: 150px">{{ $moment(item.createdAt).format("DD/MM/YYYY HH[H]mm") }}</td>
              <td style="width: 120px">{{ item.or_num }}</td>
              <td>{{ item.or_name }}</td>
              <td>{{ item.co_id | formatContactNameSimple("co_invoice_") }}</td>
              <td style="width: 150px">{{ item.or_status }}</td>
              <td style="width: 150px; text-align: right">{{ (item.or_pricettc / 100) | formatPrice }}</td>
              <td @click="print(item, $event)" style="text-align: center; width: 120px">
                <icon name="print"></icon>
              </td>
            </template>
          </m-list>
          <invoice-win-edit :or_id="itemSelected.or_id" v-model="dialog" @reload="loadInvoices"></invoice-win-edit>
        </div>
        <div v-show="documentType == 2" class="card card-no-margin">
          <div class="row">
            <div class="col">
              <h2 class="mb-0">{{ attestations_total }} attestations fiscales</h2>
              <p class="ml-2" style="color: red; font-weight: bold; font-size: 20px">{{ attestationsPending }} en attente</p>
            </div>
            <div class="col">
              <div class="d-flex justify-content-end align-items-start mb-3">
                <m-form-select
                  :width="150"
                  label="Année"
                  label-position="top"
                  :name="$Utils.randomstring('filterYear')"
                  v-model="filterAttestationYear"
                  :items="attestationYears"
                  @input="loadAttestations"
                ></m-form-select>
                <m-form-select
                  :width="150"
                  class="ml-3"
                  label="Status"
                  label-position="top"
                  :name="$Utils.randomstring('filterStatus')"
                  v-model="filterAttestationStatus"
                  :items="attestationStatus"
                  @input="loadAttestations"
                ></m-form-select>
                <m-form-combobox
                  ref="search-contacts"
                  :width="220"
                  class="ml-3"
                  label="Contact"
                  label-position="top"
                  :name="$Utils.randomstring('filterContactAttestations')"
                  v-model="filterContactAttestation"
                  :store-url="$config.server_url + '/extranet/2.0/allcontacts'"
                  :store-params="{
                    co_invoice_name: searchContactsValue,

                    limit: searchContactsValue ? 10000 : 100
                  }"
                  item-value="co_id"
                  :item-text="showContactName"
                  @input="loadAttestations"
                  @onsearchinput="searchContacts"
                ></m-form-combobox>
              </div>
            </div>
          </div>
          <m-list
            ref="attestationslist"
            :dynamic="true"
            :items="attestations"
            item-value="fisc_id"
            :item-height="30"
            :limit="attestations_limit"
            :skip="attestations_skip"
            :total="attestations_total"
            :bgstrip="true"
            @changerange="attestationsChangerange"
            @itemclick="editAttestations"
          >
            <template v-slot:ths="{}">
              <th style="width: 150px" @click="sortAttestationItems(['createdAt'])">
                <span class="pointer">Date de la demande</span>
              </th>
              <th style="width: 80px" @click="sortAttestationItems(['fisc_id'])">
                <span class="pointer">Année</span>
              </th>
              <th>
                <span class="pointer">Contact</span>
              </th>
              <th style="width: 150px" @click="sortAttestationItems(['fisc_status'])">
                <span class="pointer">Statut</span>
              </th>
            </template>
            <template v-slot:tds="{ item }">
              <td style="width: 150px">{{ $moment(item.createdAt).format("DD/MM/YYYY HH[H]mm") }}</td>
              <td style="width: 80px">{{ item.fisc_year }}</td>
              <td>{{ item.fisc_civility }} {{ item.fisc_fullname }}</td>
              <!-- style 3 colors int td green red and yellow -->
              <td
                :style="{
                  color: item.fisc_status == '2' ? 'red' : item.fisc_status == '1' ? 'green' : 'orange'
                }"
                style="width: 150px"
              >
                <!-- en attente , walidée ou irrecevable -->
                {{
                  item.fisc_status == "0"
                    ? "En attente"
                    : item.fisc_status == "1"
                    ? "Validée"
                    : item.fisc_status == "2"
                    ? "Irrecevable"
                    : "En attente"
                }}
              </td>
            </template>
          </m-list>
          <attestation-fiscale
            v-model="displayFormAttestationFiscale"
            @winRequestAttestationCanceled="cancelWinRequestAttestation"
            :row_en="row_en"
          ></attestation-fiscale>
        </div>
        <div v-show="documentType == 0" class="row">
          <div class="col">
            <p>Veuillez sélectionner un type de document</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AttestationFiscale from "./AttestationFiscale.vue";
export default {
  name: "invoice",
  components: { AttestationFiscale },
  data() {
    return {
      documentType: 1,
      documentTypesList: [
        {
          value: 1,
          text: "Factures"
        },
        {
          value: 2,
          text: "Attestations fiscales"
        }
      ],
      //invoices
      invoices: [],
      invoices_total: 0,
      invoices_limit: 50,
      invoices_skip: 0,
      sortOrder: "asc",
      sortOrderFields: [],

      //attestations
      attestations: [],
      attestations_total: 0,
      attestations_limit: 50,
      attestations_skip: 0,
      displayFormAttestationFiscale: false,
      attestationsPending: 0,
      sortAttestation: "asc",
      sortAttestationFields: [],
      filterAttestationStatus: 0,
      filterAttestationYear: 0,
      attestationYears: [],
      attestationStatus: [
        {
          value: 1,
          text: "Envoyée"
        },
        {
          value: 2,
          text: "En attente"
        }
      ],
      filterContactAttestation: {},

      row_en: {},

      showOccupedRoom: false,
      filterText: "",
      filterContact: {},
      itemsTypes: [
        { text: "Tous", value: "0" },
        { text: "Inscrit", value: "2" },
        { text: "Payé", value: "3" }
      ],
      itemSelected: {},
      dialog: false,
      searchContactsValue: ""
    };
  },
  watch: {
    documentType(newValue, oldValue) {
      if (newValue == 1) this.loadInvoices();
      else if (newValue == 2) this.loadAttestations();
    }
  },
  async mounted() {
    let id = parseInt(this.$route.params.or_id);
    if (id) {
      this.itemSelected.or_id = id;
      this.dialog = true;
    }
    this.loadInvoices();
  },
  computed: {},
  methods: {
    invoicesChangerange(skip, scrollTop) {
      // console.log("skip, scrollTop", skip, scrollTop);
      this.invoices_skip = skip;
      this.loadInvoices();
    },
    attestationsChangerange(skip, scrollTop) {
      // console.log("skip, scrollTop", skip, scrollTop);
      this.attestations_skip = skip;
      this.loadAttestations();
    },
    sortOrderItems(fields) {
      if (fields.length && this.sortOrderFields.length && fields[0] == this.sortOrderFields[0]) {
        this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
      }
      this.sortOrderFields = fields;
      this.loadInvoices();
    },
    sortAttestationItems(fields) {
      if (fields.length && this.sortAttestationFields.length && fields[0] == this.sortAttestationFields[0]) {
        this.sortAttestation = this.sortAttestation == "asc" ? "desc" : "asc";
      }
      this.sortAttestationFields = fields;
      this.loadAttestations();
    },
    async loadAttestations() {
      let sort = this.sortAttestationFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.sortAttestation;
        return accumulator;
      }, "");
      if (!sort) sort = "t1.createdAt desc";
      let params = {
        sort: sort,
        skip: this.attestations_skip,
        limit: this.attestations_limit
      };

      if (this.filterAttestationYear) params.fisc_year = this.attestationYears[this.filterAttestationYear - 1].text;
      if (this.filterAttestationStatus) params.fisc_status = this.filterAttestationStatus === 1 ? 1 : 0;
      if (this.filterContactAttestation) params.fisc_fullname = this.filterContactAttestation.text;
      this.cancelAxiosLoadContacts && this.cancelAxiosLoadContacts();
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/attestationFiscale/list", {
        params,
        cancelToken: new this.$axios.CancelToken(c => {
          this.cancelAxiosLoadContacts = c;
        })
      });
      if (!response || !response.data) return;
      this.attestations = response.data.data;
      this.attestations_total = response.data.meta.total;
      this.attestationsPending = 0;
      if (this.attestationYears.length === 0) this.getAttestationYears();

      this.attestations.forEach(attestation => {
        if (!attestation.fisc_status) this.attestationsPending++;
      });
    },
    getAttestationYears() {
      this.attestations.forEach(attestation => {
        if (!this.attestationYears.filter(e => e.text === attestation.fisc_year).length > 0) {
          this.attestationYears.push({
            value: this.attestationYears.length + 1,
            text: attestation.fisc_year
          });
        }
      });
    },
    async loadInvoices() {
      let sort = this.sortOrderFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.sortOrder;
        return accumulator;
      }, "");
      if (!sort) sort = "t1.createdAt desc";
      let params = {
        sort: sort,
        skip: this.invoices_skip,
        limit: this.invoices_limit
      };
      if (this.filterText) params.text = this.filterText;
      if (this.filterContact) params.co_id = this.filterContact.co_id;
      this.cancelAxiosLoadContacts && this.cancelAxiosLoadContacts();
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/orders", {
        params,
        cancelToken: new this.$axios.CancelToken(c => {
          this.cancelAxiosLoadContacts = c;
        })
      });
      if (!response || !response.data) return;
      this.invoices = response.data.data;
      this.invoices_total = response.data.meta.total;
    },
    addInvoices() {
      this.itemSelected = {};
      this.$router.push("/invoices/-1");
      this.dialog = true;
    },
    editInvoices(item) {
      this.itemSelected = item;
      this.$router.push("/invoices/" + this.itemSelected.or_id);
      this.dialog = true;
    },
    async editAttestations(item) {
      this.row_en = item.en_id;
      this.displayFormAttestationFiscale = true;
    },
    cancelWinRequestAttestation() {
      this.row_en = {};
      this.loadAttestations();
      this.displayFormAttestationFiscale = false;
    },
    //sert à afficher les nom et prénom des contact dans le combobox
    showContactName(val) {
      let txt = "";
      if (val.co_invoice_name) txt += val.co_invoice_name.toUpperCase();
      if (val.co_invoice_firstname) txt += " " + val.co_invoice_firstname.charAt(0).toUpperCase() + val.co_invoice_firstname.substr(1);
      return txt;
    },
    async print(item, $event) {
      $event.stopPropagation();
      //console.log("item", item);
      window.open(
        this.$config.server_url + "/extranet/2.0/orders/" + item.or_id + "/pdf?d=" + new Date().getTime() + "&token=" + this.$store.state.accesstoken,
        "_blank"
      );
    },
    async searchContacts(val) {
      this.searchContactsValue = `contains:${val}`;
      await this.$nextTick();
      this.$refs["search-contacts"].search_go(val);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";
// .header-emotivi {
//   // height: 24px;
//   // min-height: 70px !important;
//   margin-bottom: -5px !important;
// }
</style>
