<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none; '"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document" style="width:400px;margin-top:100px">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <h1>Remboursement</h1>
          </div>

          <div class="modal-body">
            <div class="row">
              <m-form-text
                label="Combien souhaitez-vous rembourser?"
                type="number"
                v-model="amount"
                :name="$Utils.randomstring('montantCancel')"
                :rules="[$Validation.mandatory]"
              ></m-form-text>
              <p>La valeur max que vous pouvez rembourser: {{ this.maxvalue }}</p>
            </div>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between">
            <button type="button" class="btn btn-secondary" @click="cancelWin">Annuler</button>
            <div>
              <button type="button" class="btn btn-primary btn-mouveal ml-2" @click="addmontantCancel">Confirmer</button>
            </div>
          </div>
        </div>
      </div>

      <m-message-dialog
        v-model="montantInf"
        title="Erreur"
        :text="`Veuillez saisir un montant inférieur à ${this.maxvalue}  euros svp!`"
        @close="montantInf = false"
      ></m-message-dialog>
    </div>
  </transition>
</template>

<script>
export default {
  name: "cancelCharge",
  components: {},
  props: {
    value: {
      default: false,
      type: Boolean
    },
    maxvalue: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      amount: 100,
      montantInf: false
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.amount = this.maxvalue;
      }
    }
  },
  computed: {},
  mounted() {
    window.addEventListener("keyup", this.onKeyup);
    //console.log("maxvalue dans", this.maxvalue);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  created() {},
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    cancelWin() {
      this.$emit("input", false);
    },
    addmontantCancel() {
      // Récupération du formulaire.
      // Récupération des valeurs des champs du formulaire
      //let donneesFormulaire = new FormData(form);
      //this.montantCancel = donneesFormulaire.get("montantCancel");
      if (this.amount > this.maxvalue) {
        this.montantInf = true;
      } else {
        this.$emit("amountCancelChargeChanged", this.amount);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";
.establishment {
  background-color: #daece7;
  padding: 5px 10px;
  margin-right: 10px;
}
</style>
