<template>
  <section class="w-100">
    <div class="" id="fiche">
      <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
        <div class="d-flex flex-row align-items-center">
          <h1>
            (TV) Utilisateur final N°{{ row_en.en_id }}
            <span v-if="!row_en.de_id"> (Pas encore de TV) </span>
            <span style="color: lightgray">|</span>
            {{ row_co | formatContactNameSimple("co_invoice_") }}
            <span style="color: lightgray">|</span>&nbsp; <span class="text-red-emotivi">{{ row_co.es_id ? row_co.es_id.es_name : "" }}</span
            >&nbsp;
          </h1>
        </div>
        <div class="d-flex flex-column infComVsTech">
          <m-form-checkbox
            label="Informations commerciales"
            :name="$Utils.randomstring('filterInfoCom')"
            v-model="filterInfoCom"
            class="checkbox"
          ></m-form-checkbox>
          <m-form-checkbox label="Informations techniques" v-model="filterInfoTech" :name="$Utils.randomstring('filterInfoTech')"></m-form-checkbox>
        </div>
        <div v-if="row_en.newMessage && row_en.newMessage.length">
          <span
            style="margin-left:20px;background-color: green;color: white; font-weight:bold;border-radius: 50%;width:25px;display: inline-block;text-align: center;height: 25px;"
            >{{ row_en.newMessage.length }}</span
          >
          Vous avez reçu {{ row_en.newMessage.length }} nouveau(x) messages(s) aujourd'hui.
        </div>
        <button class="btn btn-sm btn-primary">
          <router-link class="color-white" to="/tvs"> Retour liste</router-link>
        </button>
      </div>

      <div v-if="filterInfoCom">
        <div class="row">
          <div class="col-md-9">
            <h3 class="">Informations commerciales</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9">
            <!-- CONTACTS CARD  -->
            <div v-if="$store.getters.checkUserAccess($router, '/contacts')" class="card">
              <div class="d-flex flex-row flex-wrap justify-content-between">
                <h6>
                  <span v-show="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'" class="number-title">1</span
                  >Contact (compte payeur)
                </h6>
                <button v-if="$store.getters.checkUserAccess($router, '/contacts/:co_id/edit')" class="btn btn-primary" @click="editContact">
                  Modifier
                </button>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <h5>Coordonnées</h5>
                  <div>
                    Téléphone mobile : <span class="color-emotivi-blue">{{ row_co.co_invoice_tel }}</span>
                  </div>
                  <div>
                    Identifiant web : <span class="color-emotivi-blue">{{ row_co.co_login }}</span>
                  </div>
                  <div>
                    Email :
                    <a :href="`mailto:${row_co.co_invoice_email}`">{{ row_co.co_invoice_email }}</a>
                  </div>
                  <div class="mt-3">Établissement : {{ row_co.es_id ? row_co.es_id.es_name : "" }}</div>
                  <div v-if="row_co.afco_id && row_co.afco_id.af_id">Code d'affiliation : {{ row_co.afco_id.af_id.af_code }}</div>
                  <div v-if="row_co.afco_id && row_co.afco_id.af_id">Nom du revendeur : {{ row_co.afco_id.af_id.af_name }}</div>
                  <div>Commentaire : {{ row_co.co_comment ? row_co.co_comment : "" }}</div>
                </div>
                <div class="col-md-4">
                  <h5>Adresse de facturation</h5>
                  <div>
                    <a href="javascript:void(0)" @click="$router.push('/contacts/' + row_co.co_id + '/details')">{{
                      row_co | formatContactNameSimple("co_invoice_")
                    }}</a>
                  </div>
                  <div>
                    <a :href="`https://maps.google.fr?q=${co_invoice_address}`" target="_blank">
                      {{ row_co.co_invoice_address1 }}<br />
                      <span v-if="row_co.co_invoice_address2">{{ row_co.co_invoice_address2 }}<br /></span>
                      {{ row_co.co_invoice_zip }} {{ row_co.co_invoice_city }}<br />
                    </a>
                  </div>
                  <h5 v-if="$store.getters.checkUserAccess($router, '/invoices')" class="mt-2">Prélévement bancaire</h5>
                  <div v-if="$store.getters.checkUserAccess($router, '/invoices') && row_co.co_rib">
                    <div>Titulaire : {{ row_co.co_rib_name }}</div>
                    <div>IBAN : {{ row_co.co_rib_iban }}</div>
                    <div>BIC : {{ row_co.co_rib_bic }}</div>
                  </div>
                  <div v-else>Aucun</div>
                </div>
                <div class="col-md-4">
                  <h5>Adresse de livraison</h5>
                  <div v-show="row_co.co_shipping_society_name">Nom de la société : {{ row_co.co_shipping_society_name }}</div>
                  <div>{{ row_co | formatContactNameSimple("co_shipping_") }}</div>
                  <div>
                    <a :href="`https://maps.google.fr?q=${co_shipping_address}`" target="_blank">
                      {{ row_co.co_shipping_address1 }}<br />
                      <span v-if="row_co.co_shipping_address2">{{ row_co.co_shipping_address2 }}<br /></span>
                      {{ row_co.co_shipping_zip }} {{ row_co.co_shipping_city }}<br />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- ORDERS CARD -->
            <div v-if="$store.getters.checkUserAccess($router, '/contacts/:co_id/order')" class="card">
              <div class="d-flex flex-row flex-wrap justify-content-between">
                <h6>
                  <span v-show="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'" class="number-title">3</span
                  >Paiements
                </h6>
                <p v-if="loanedKit" style="color:#199aaa"><b>Le kit est actuellement en prêt.</b></p>
                <div class="d-flex flex-row flex-wrap "></div>
              </div>

              <div>
                <h5 class="">
                  Les paiements (abonnement mensuel en CB)
                  <span v-if="loadingStripe"><icon width="30" height="30" name="sync" class="mr-2 fa-spin"></icon></span>
                </h5>
                <m-list-simple ref="commandelist" :dynamic="true" :items="stripeCharges" item-value="ac_id" :total="stripeCharges.length">
                  <template v-slot:ths="{}">
                    <th width="180">
                      <span class="pointer">Date</span>
                    </th>
                    <th width="100">
                      <span class="pointer">Montant</span>
                    </th>
                    <th width="160">
                      <span class="pointer">Statut</span>
                    </th>
                    <th>
                      <span class="pointer">Description</span>
                    </th>
                    <th>
                      <span class="pointer">Id - lien vers stripe</span>
                    </th>
                    <th>
                      <span class="pointer"></span>
                    </th>
                    <th>
                      <span class="pointer"></span>
                    </th>
                    <th>
                      <span class="pointer"></span>
                    </th>
                  </template>
                  <template v-slot:tds="{ item }">
                    <td>{{ $moment.unix(item.created).format("YYYY-MM-DD HH:mm:ss") }}</td>
                    <td>{{ (item.amount / 100) | formatPrice }}</td>
                    <td>
                      {{ item.status }}
                    </td>
                    <td>
                      {{ item.description }}
                    </td>
                    <td>
                      <a :href="item.receipt_url" target="_blank">{{ item.id }}</a>
                    </td>
                    <td>
                      <!--<icon 
                        v-if="item.payment_intent && !item.amount_refunded"
                        @click="confirmCancelCharge(item.payment_intent)"
                        width="22"
                        height="22"
                        name="trash-alt"
                        class="mr-2"
                        color="black"
                      ></icon> -->
                      <icon
                        v-if="item.payment_intent && item.amount_refunded != 10000"
                        @click="newCancelCharge(item.payment_intent)"
                        width="22"
                        height="22"
                        name="trash-alt"
                        class="mr-2"
                        color="black"
                      ></icon>
                    </td>
                    <td>
                      <span v-if="item.amount_refunded" class="badge badge-danger"
                        >REMBOURSÉ&nbsp;:&nbsp;{{ (item.amount_refunded / 100) | formatPrice }}</span
                      >
                    </td>
                    <td>
                      <span
                        style="color:#91295a;font-weight:bold"
                        v-if="item.end_user && Array.isArray(item.end_user) && item.end_user.length && item.end_user[0].en_id === row_en.en_id"
                        >Caution liée à cette TV</span
                      >
                    </td>
                  </template>
                </m-list-simple>
              </div>

              <div>
                <h5 class="">
                  Les abonnements <span v-if="loadingStripe"><icon width="30" height="30" name="sync" class="mr-2 fa-spin"></icon></span>
                </h5>
                <m-list-simple ref="commandelist" :dynamic="true" :items="stripeSubscriptions" item-value="ac_id" :total="stripeSubscriptions.length">
                  <template v-slot:ths="{}">
                    <th style="width: 75px">TV</th>
                    <th style="width: 120px">
                      <span class="pointer">Montant</span>
                    </th>
                    <th style="width: 120px">
                      <span class="pointer">Statut</span>
                    </th>
                    <th>
                      <span class="pointer">Description</span>
                    </th>
                    <th>
                      <span class="pointer">Id - lien vers stripe</span>
                    </th>
                    <th>
                      <span class="pointer">Début abonnement</span>
                    </th>
                    <th>
                      <span class="pointer">Fin abonnement</span>
                    </th>
                    <th>
                      <span class="pointer">&nbsp;</span>
                    </th>
                    <th>
                      <span class="pointer">&nbsp;</span>
                    </th>
                  </template>
                  <template v-slot:tds="{ item }">
                    <td>
                      <a
                        style="color:#91295a;font-weight:bold"
                        @click="$router.push('/tvs/' + item.end_user[0].en_id) && $router.go()"
                        v-if="item.end_user && Array.isArray(item.end_user) && item.end_user.length"
                        >TV {{ item.end_user[0].en_id }}</a
                      >
                    </td>
                    <td>{{ (item.plan.amount / 100) | formatPrice }}</td>
                    <td>
                      {{ item.su_state_label }}
                    </td>
                    <td>
                      {{ item.plan.of_name }}
                    </td>
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      <span v-if="item.su_startdate">{{ $moment(item.su_startdate).format("DD/MM/YYYY HH:mm:ss") }}</span>
                    </td>
                    <td>
                      <span v-if="item.su_enddate">{{ $moment(item.su_enddate).format("DD/MM/YYYY HH:mm:ss") }}</span>
                    </td>
                    <td>
                      <!-- <icon
                        v-if="item.id && typeof item.id === 'string' && item.id.indexOf('sub_') >= 0"
                        @click="confirmCancelSubscription(item.id)"
                        width="22"
                        height="22"
                        name="trash-alt"
                        class="mr-2"
                        color="black"
                      ></icon>
                      <icon
                        v-else-if="item.id && item.su_paymentmethod === 'rib' && item.su_state !== 0"
                        @click="confirmCancelSubscriptionRib(item.id, item)"
                        width="22"
                        height="22"
                        name="trash-alt"
                        class="mr-2"
                        color="black"
                      ></icon>
                      <icon
                        v-else-if="item.id && item.su_paymentmethod === 'cb' && item.su_state === 2"
                        @click="confirmCancelSubscription(item.id)"
                        width="22"
                        height="22"
                        name="trash-alt"
                        class="mr-2"
                        color="black"
                      ></icon> -->
                    </td>
                    <td>
                      <span
                        style="color:#91295a;font-weight:bold"
                        v-if="item.end_user && Array.isArray(item.end_user) && item.end_user.length && item.end_user[0].en_id === row_en.en_id"
                        >Abonnement lié à cette TV</span
                      >
                    </td>
                  </template>
                </m-list-simple>
              </div>

              <div>
                <h5 class="">
                  Les factures <span v-if="loadingStripe"><icon width="30" height="30" name="sync" class="mr-2 fa-spin"></icon></span>
                </h5>
                <m-list-simple ref="commandelist" :dynamic="true" :items="orders" item-value="ac_id" :total="stripeCharges.length">
                  <template v-slot:ths="{}">
                    <th width="200px">
                      <span class="pointer">nom</span>
                    </th>
                    <th width="200px">
                      <span class="pointer">Statut</span>
                    </th>
                    <th>
                      <span class="pointer">Date</span>
                    </th>
                    <th>
                      <span class="pointer">Total TTC</span>
                    </th>
                    <th>
                      <span class="pointer">Actions</span>
                    </th>
                  </template>
                  <template v-slot:tds="{ item }">
                    <td>{{ item.or_name }}</td>
                    <td>
                      {{ item.or_status }}
                    </td>
                    <td>
                      {{ item.or_date | formatDateTime }}
                    </td>
                    <td>{{ item.or_pricettc / 100 }} €</td>
                    <td class="d-flex flex-row">
                      <router-link :to="'/invoices/' + item.or_id"> <icon color="black" name="edit"></icon></router-link>
                      <div class="ml-2" @click="printOrder(item, $event)">
                        <icon name="print"></icon>
                      </div>
                    </td>
                  </template>
                </m-list-simple>
              </div>
              <div>
                <h5 class="">
                  Les modes de paiement
                  <span v-if="loadingStripe"><icon width="30" height="30" name="sync" class="mr-2 fa-spin"></icon></span>
                </h5>
                <m-list-simple
                  v-if="payments.length"
                  ref="commandelist"
                  :dynamic="true"
                  :items="payments"
                  item-value="ac_id"
                  :total="payments.length"
                >
                  <template v-slot:ths="{}">
                    <th width="200px">
                      <span class="pointer">Nom</span>
                    </th>
                    <th width="200px">
                      <span class="pointer">Méthode</span>
                    </th>
                    <th>
                      <span class="pointer">Statut</span>
                    </th>
                    <th>
                      <span class="pointer">Type</span>
                    </th>
                    <th>
                      <span class="pointer">Actions</span>
                    </th>
                  </template>
                  <template v-slot:tds="{ item }">
                    <td>{{ item.pm_name || item.pm_rib_name }}</td>
                    <td>
                      {{ item.pm_method }}
                    </td>
                    <td>
                      {{ item.pm_active ? "Utilisée" : "" }}
                    </td>
                    <td>
                      {{ item.subscription ? "Abonnement N° " + item.subscription : item.caution ? "Caution" : "" }}
                    </td>

                    <td class="">
                      <div class="ml-2" @click="displayPaymentMethodDetails(item, $event)">
                        <icon name="info-circle"></icon>
                      </div>
                    </td>
                  </template>
                </m-list-simple>
              </div>
            </div>
            <!-- FRESHDESK TICKETS CARD -->
            <div v-if="this.tickets.length" class="card">
              <h6>
                <span class="number-title">6</span>Tickets Freshdesk
                <span v-if="loadingTickets"><icon width="30" height="30" name="sync" class="mr-2 fa-spin"></icon></span>
              </h6>
              <div class="row mt-2">
                <div class="col-md-12">
                  <m-list-simple ref="commandelist" :dynamic="true" :items="tickets" item-value="id" :total="1" @itemclick="goFreshdeskTicket">
                    <template v-slot:ths="{}">
                      <th>
                        <span class="pointer">Date</span>
                      </th>
                      <th>
                        <span class="pointer">Sujet</span>
                      </th>
                      <th class="">
                        <span class="pointer">État</span>
                      </th>
                    </template>
                    <template v-slot:tds="{ item }">
                      <td>{{ item.created_at | formatDateTime }}</td>
                      <td>
                        {{ item.subject }}
                      </td>
                      <td>
                        <div class="ticket-status" :class="'status-' + item.status">
                          {{ item.status | formatStatusTicketsFreshdesk }}
                        </div>
                      </td>
                    </template>
                  </m-list-simple>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <!-- END USERS CARD -->
            <div class="card">
              <div class="d-flex flex-row flex-wrap justify-content-between">
                <h6>
                  <span v-show="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'" class="number-title">2</span
                  >Utilisateur final
                </h6>
                <div>
                  <button class="btn btn-primary mb-2" @click="editEnduser">Modifier</button>
                </div>
              </div>
              <!-- <div class="bis uppercase">Identifiant Webapp</div> -->
              <div>
                <div v-if="row_en.en_linkello">
                  <img class="logo-linkello" src="/images/linkello.png" /><span class="linkello">Associé à Linkello</span>
                </div>
                Nom complet : {{ row_en.en_fullname }}
                <hr class="hr-mini" />
                <div class="float-right">
                  <button class="btn btn-warning btn-sm mt-2" @click="sendInvitationEmail">Renvoyer</button>
                </div>
                Ident. webapp :
                <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_webapp_login)">{{ row_en.en_webapp_login }}</span
                ><br />
                Pass webapp :<span class="color-emotivi-blue" @click="copieClipboard(row_en.en_webapp_password)">
                  {{ row_en.en_webapp_password }}</span
                >
                <hr class="hr-mini" />
                Code administrateur : <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)">{{ row_en.en_admincode }}</span>
                <hr class="hr-mini" />
                <!-- Status : <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)">{{ row_en.en_active }}</span
                ><br /> -->
                Chambre : {{ row_en.en_room ? row_en.en_room : "" }}<br />
                Statut TV :
                <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)">{{
                  $Utils.getTextFromState($store.state.items_en_state_box, row_en.en_state_box).replace(/Box/g, "TV")
                }}</span
                ><br />
                Statut caution:
                <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)">{{
                  $Utils.getTextFromState($store.state.items_en_state_caution, row_en.en_state_caution)
                }}</span
                ><br />
                Statut abonnement:
                <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)">{{
                  $Utils.getTextFromState($store.state.items_en_state_subscription, row_en.en_state_subscription)
                }}</span
                ><br />
                Statut colis:
                <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)">{{
                  $Utils.getTextFromState($store.state.items_en_state_parcel, row_en.en_state_parcel).replace(/Box/g, "TV")
                }}</span
                ><br />
                Statut support:
                <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)">{{
                  $Utils.getTextFromState($store.state.items_en_state_support, row_en.en_state_support)
                }}</span
                ><br />
                <hr class="hr-mini mb-2" />
                <div>
                  <button class="btn btn-danger mb-2" @click="resetTv">Réinitialiser la TV</button>
                </div>
              </div>
              <!-- en_comment n'existe pas -->
              <!-- <div class="bis uppercase mt-3">Commentaire</div>
                  <div>{{ row_en.en_comment ? row_en.en_comment : "" }}</div> -->
            </div>
            <!-- PARCELS CARD -->
            <div v-if="$store.getters.checkUserAccess($router, '/parcels/:en_id')" class="card">
              <div class="d-flex flex-row flex-wrap justify-content-between">
                <h6>
                  <span v-show="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'" class="number-title">4</span
                  >Envois colis
                </h6>
                <button class="btn btn-primary" @click="newParcel">Ajouter</button>
              </div>
              <div class="d-flex flex-row flex-wrap justify-content-between mt-3" v-for="parcel in parcels" :key="parcel.pa_id">
                <div>
                  <div>
                    Type de connexion :

                    <span
                      class="color-emotivi-blue "
                      :class="getBold(row_co.co_box_connection_type)"
                      :style="{ color: getColor(row_co.co_box_connection_type) }"
                      >{{ row_co.co_box_connection_type }}</span
                    >
                  </div>

                  <div>
                    Société : <span class="color-emotivi-blue">{{ parcel.pa_company }}</span>
                  </div>
                  <div>
                    N° suivi : <span class="color-emotivi-blue">{{ parcel.pa_trackingcode }}</span>
                  </div>
                  <div>
                    Date : <span class="color-emotivi-blue">{{ $moment(parcel.updatedAt).format("DD/MM/YYYY HH[H]mm") }}</span>
                  </div>
                  <div>
                    User :
                    <span class="color-emotivi-blue">{{ parcel.us_id ? $options.filters.formatContactNameSimple(parcel.us_id, "us_") : "" }}</span>
                  </div>
                  <div>
                    Etat :<span class="color-emotivi-blue"> {{ parcel.pa_state }}</span>
                  </div>
                  <div>
                    Tarif :<span class="color-emotivi-blue"> {{ parcel.pa_price }}</span>
                  </div>
                </div>
                <div class="d-flex flex-column align-items-end">
                  <button class="btn btn-primary btn-sm w-100" @click="editParcel(parcel)">Modifier</button>
                  <button
                    class="btn btn-warning my-1 btn-sm w-100"
                    @click="mailParcel(parcel)"
                    :disabled="parcel.pa_state !== 'SENT' && parcel.pa_state !== 'PREPARING'"
                  >
                    Envo. mail
                  </button>
                  <a class="w-100" :href="'https://www.laposte.fr/outils/suivre-vos-envois?code=' + parcel.pa_trackingcode" target="_blank"
                    ><button class="btn btn-warning btn-sm w-100">Suivre</button></a
                  >
                </div>
              </div>
            </div>
            <!-- SUMMARY CARD -->
            <div v-show="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'" class="card">
              <h6>
                <span v-show="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'" class="number-title">5</span
                >Résumé
              </h6>
              <div class="row mt-2">
                <div class="col-md-12">
                  <div>
                    Inscription le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.inscription
                          ? $moment(row_en.infos.actionsFirstTime.inscription).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Première install. le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.install
                          ? $moment(row_en.infos.actionsFirstTime.install).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Envoi colis le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.parcel_sended
                          ? $moment(row_en.infos.actionsFirstTime.parcel_sended).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Dernière connexion le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.connection
                          ? $moment(row_en.infos.actionsFirstTime.connection).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Réception colis le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.parcel_received
                          ? $moment(row_en.infos.actionsFirstTime.parcel_received).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Premier appel vidéo le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.videocall_firstSuccess
                          ? $moment(row_en.infos.actionsFirstTime.videocall_firstSuccess).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Premier appel audio le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.audiocall_firstSuccess
                          ? $moment(row_en.infos.actionsFirstTime.audiocall_firstSuccess).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Dernier appel le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.lastSuccessCall
                          ? $moment(row_en.infos.actionsFirstTime.lastSuccessCall).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    VIDÉO/OK/VOIX/OK/MESSAGE
                    <span class="color-emotivi-blue ml-2">
                      <span v-for="(number, index) in row_en.infos.nbCalls" :key="index"
                        >{{ number }} <span v-if="index < row_en.infos.nbCalls.length - 1">/ </span></span
                      >
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="filterInfoTech">
        <div class="row">
          <div class="col-md-9">
            <h3 class="">Informations techniques</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9">
            <!-- TECHNICAL INFORMATION CARD -->
            <div class="card">
              <div class="d-flex flex-row justify-content-between">
                <h6>
                  <span v-show="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'" class="number-title">1</span
                  >Informations techniques
                </h6>
              </div>
              <div class="row">
                <div class="col-md-6 col-lg-3 d-flex flex-column">
                  <button class="btn btn-primary mt-2" :disabled="!row_en.de_id" @click="editTv">Options</button>
                  <button class="btn btn-primary mt-2" :disabled="!row_en.de_id" @click="editTv">Modifier plage horaire</button>
                </div>
              </div>

              <div class="row my-3">
                <div class="col-md-6">
                  <div class="bis uppercase mr-2" v-if="row_de.de_callhours">Plages horaire :</div>
                  <div class="d-flex flex-row flex-wrap mb-2">
                    <div v-for="(plage, index) in row_de.de_callhours" :key="index">
                      <div v-if="plage.start">
                        {{ plage.start.split(":").join("H") }}-{{ plage.end.split(":").join("H")
                        }}<span class="mx-1" v-if="index < row_de.de_callhours.length - 1"> / </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <button class="btn btn-warning" :disabled="!row_en.de_id" @click="showDetailsTv = !showDetailsTv">
                    <span v-if="!showDetailsTv">Afficher</span><span v-if="showDetailsTv">Masquer</span> les détails de la TV
                  </button>
                </div>
              </div>
              <div class="row mt-4" v-if="showDetailsTv">
                <div class="col-md-12 d-flex flex-column" v-if="row_de">
                  <div v-for="(tab, indexTab) in tabsInfos" :key="indexTab" class="mb-3">
                    <div class="bis uppercase">{{ tab }}</div>
                    <div v-for="info in allinfos(tab)" :key="info.key" class="row tr-tvdetail">
                      <div class="col-md-3" width="200px">{{ info.key }}</div>
                      <div class="col-md-9 color-emotivi-blue">
                        <pre><code>{{ info.val }}</code></pre>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- MESSAGES CARD -->
            <div class="card">
              <div class="uppercase bis mb-3">
                <span v-show="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'" class="number-title">4</span
                >Messages
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button class="btn btn-warning mt-1 mr-2" :disabled="!row_en.de_id" @click="showMessages = !showMessages">
                    <span v-if="!showMessages">Afficher</span><span v-if="showMessages">Masquer</span> les messages
                  </button>
                  <button class="btn btn-primary mt-1 mr-2" :disabled="!row_en.de_id" @click="addMessage = !addMessage">Envoyer un message</button>
                  <button class="btn btn-primary mt-1 mr-2" :disabled="!row_en.de_id" @click="scheduleMessage = !scheduleMessage">
                    Planifier un message
                  </button>
                  <button class="btn btn-primary mt-1 mr-2" :disabled="!row_en.de_id" @click="videoCall">Démarrer un appel vidéo</button>
                  <button class="btn btn-primary mt-1 mr-2" :disabled="!row_en.de_id" @click="audioCall">Démarrer un appel audio</button>
                  <button class="btn btn-danger mt-1 mr-2" :disabled="!row_en.de_id" @click="deleteAllMessages">Effacer les messages</button>
                </div>
              </div>
              <div class="row d-flex flex-row align-items-center" v-if="addMessage">
                <div class="col-md-12">
                  <div class="d-flex flex-column">
                    <m-form-checkbox
                      label="Message de type ADMIN"
                      :name="$Utils.randomstring('isAdmin')"
                      v-model="isAdmin"
                      class="mt-2"
                    ></m-form-checkbox>
                    <m-form-checkbox
                      v-if="isAdmin"
                      label="Confirmation de lecture"
                      :name="$Utils.randomstring('acknowledgment')"
                      v-model="acknowledgment"
                    ></m-form-checkbox>
                    <m-form-minutes
                      v-if="isAdmin"
                      class="mx-1"
                      style="overflow-wrap:normal"
                      label="Durée d'affichage (minutes:secondes)"
                      :name="$Utils.randomstring('duration')"
                      v-model="duration"
                    ></m-form-minutes>
                  </div>

                  <m-form-text label="Message" type="textarea" v-model="newMessage" name="message" class=""></m-form-text>

                  <div v-show="!photoJoined">
                    <label class="fileContainer d-flex justify-content-center" for="upload">
                      <div class="align-self-center">Choisir un fichier</div>
                    </label>
                    <input
                      type="file"
                      class="form-control inputImage"
                      @change="handleFiles()"
                      id="upload"
                      name="file"
                      ref="myFiles"
                      style="display: none"
                    />
                  </div>
                  <div v-show="photoJoined">
                    <label id="container-media" for="upload" ref="previewContainer" style="width: 100%">
                      <div id="container-media-content" ref="preview"></div>
                    </label>
                    <button class="btn btn-rotate mr-2 btn-img rotate-left" v-on:click.prevent="rotateFiles($event, 'left')">
                      <icon name="undo-alt"></icon>
                    </button>
                    <button class="btn btn-rotate mr-2 btn-img rotate-right" v-on:click.prevent="rotateFiles($event, 'right')">
                      <icon name="redo-alt"></icon>
                    </button>
                    <button class="btn btn-danger mr-2 btn-img trash" v-on:click.prevent="deleteFiles($event)">
                      <icon name="trash-alt"></icon>
                    </button>
                  </div>
                  <button class="btn btn-primary mt-2" @click="sendMessage">Envoyer</button>
                </div>
              </div>
              <div class="row" v-if="showMessages">
                <div class="col-md-12">
                  <m-list-simple ref="commandelist" :dynamic="true" :items="messages" item-value="me_id" :total="messages_length">
                    <template v-slot:ths="{}">
                      <th width="150px">
                        <span class="pointer">Date</span>
                      </th>
                      <th width="150px">
                        <span class="pointer">Auteur</span>
                      </th>
                      <th width="100px">
                        <span class="pointer">Avatar</span>
                      </th>
                      <th width="70px">
                        <span class="pointer">Type</span>
                      </th>
                      <th>
                        <span class="pointer">Message</span>
                      </th>
                      <th width="70px">
                        <span class="pointer">Visible</span>
                      </th>
                    </template>
                    <template v-slot:tds="{ item }">
                      <td>{{ $moment(item.me_date).format("DD/MM/YYYY") }}</td>
                      <td>{{ item.me_pseudo }}</td>
                      <td>
                        <div class="container-avatar" :style="'background-image:url(' + $config.server_url + item.me_avatar + ')'"></div>
                        <!-- <img class="img-fluid rounded-circle" slot="image" :src="$config.server_url + item.me_avatar" /> -->
                      </td>
                      <td>{{ item.me_type }}</td>
                      <td>{{ item.me_text }}</td>
                      <td>{{ item.me_invisible ? "Non" : "Oui" }}</td>
                    </template>
                  </m-list-simple>
                </div>
              </div>
              <modal-schedule-message v-model="scheduleMessage" :row_en="row_en"></modal-schedule-message>
            </div>
            <!-- ACTIONS CARD -->
            <div class="card">
              <div class="row d-flex flex-row align-items-end mb-2">
                <div class="col-md-10">
                  <div class="uppercase bis mb-3">
                    <span v-show="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'" class="number-title">5</span
                    >Les actions
                  </div>
                  <m-form-text
                    class="ml-3"
                    type="textarea"
                    :rows="1"
                    label="Ajouter une action"
                    label-position="top"
                    v-model="newCommentAction"
                    name="newCommentAction"
                  ></m-form-text>
                </div>
                <div class="col-md-2">
                  <button class="btn btn-primary ml-2" @click="addCommentAction">Ajouter</button>
                </div>
              </div>
              <div class="row align-items-end mb-2">
                <div class="col-md-4">
                  <button class="btn btn-warning ml-3" @click="loadActions('showList')">
                    <span v-if="!showActions">Afficher</span><span v-if="showActions">Masquer</span> les actions
                  </button>
                </div>
                <!-- LIST ACTIONS -->
                <div class="col-md-4">
                  <m-form-select
                    label="Type d'action"
                    v-model="selectedActionType"
                    :name="$Utils.randomstring('ac_type')"
                    :items="listActions"
                  ></m-form-select>
                </div>
                <div class="col-md-4">
                  <m-form-date
                    class="mx-1"
                    label="Date de l'action"
                    :name="$Utils.randomstring('ac_date')"
                    formatInput="DD/MM/YYYY HH:mm:ss"
                    formatValue="YYYY-MM-DD HH:mm:ss"
                    v-model="actionDate"
                  ></m-form-date>
                </div>
              </div>
              <div v-if="showActions">
                <m-list-simple ref="commandelist" :limit="300" :dynamic="true" :items="actions" item-value="ac_id" :total="action_length">
                  <template v-slot:ths="{}">
                    <th width="160px">
                      <span class="pointer">Date</span>
                    </th>
                    <th width="220px">
                      <span class="pointer">Type</span>
                    </th>
                    <th width="220px">
                      <span class="pointer">Contact</span>
                    </th>
                    <th width="220px">
                      <span class="pointer">Utilisateur final</span>
                    </th>
                    <th width="220px">
                      <span class="pointer">User</span>
                    </th>
                    <th>
                      <span class="pointer">Infos</span>
                    </th>
                  </template>
                  <template v-slot:tds="{ item }">
                    <td>{{ item.createdAt | formatDateTime }}</td>
                    <td>{{ item.ac_type }}</td>
                    <td>{{ item.co_id | formatContactNameSimple }}</td>
                    <td>{{ item.en_id | formatEndUserName }}</td>
                    <td>{{ item.us_id | formatUserName }}</td>
                    <td>
                      <span v-if="item.ac_comment">{{ item.ac_comment }}</span>
                      <span v-if="item.ac_elapsedtime > 0">Durée : {{ Math.floor((item.ac_elapsedtime / 60) * 100) / 100 }}mn</span>
                    </td>
                  </template>
                </m-list-simple>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <!-- END USERS CARD -->
            <div class="card">
              <div class="d-flex flex-row flex-wrap justify-content-between">
                <h6>
                  <span v-show="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'" class="number-title">2</span
                  >Utilisateur final
                </h6>
                <div>
                  <button class="btn btn-primary mb-2" @click="editEnduser">Modifier</button>
                </div>
              </div>

              <div>
                <div v-if="row_en.en_linkello">
                  <span class="linkello">Associé à Linkello</span>
                </div>
                Nom complet : {{ row_en.en_fullname }}
                <hr class="hr-mini" />
                <div class="float-right">
                  <button class="btn btn-warning btn-sm mt-2" @click="sendInvitationEmail">Renvoyer</button>
                </div>
                Ident. webapp :
                <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_webapp_login)">{{ row_en.en_webapp_login }}</span
                ><br />
                Pass webapp :<span class="color-emotivi-blue" @click="copieClipboard(row_en.en_webapp_password)">
                  {{ row_en.en_webapp_password }}</span
                >
                <hr class="hr-mini" />
                Code administrateur : <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)">{{ row_en.en_admincode }}</span>
                <hr class="hr-mini" />

                Chambre : {{ row_en.en_room ? row_en.en_room : "" }} <br />
                Statut TV :
                <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)">{{ row_en.en_state_box }}</span
                ><br />
                Statut caution: <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)">{{ row_en.en_state_caution }}</span
                ><br />
                Statut abonnement:
                <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)">{{ row_en.en_state_subscription }}</span
                ><br />
                Statut colis: <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)">{{ row_en.en_state_parcel }}</span
                ><br />
                Statut support: <span class="color-emotivi-blue" @click="copieClipboard(row_en.en_admincode)">{{ row_en.en_state_support }}</span
                ><br />
                <hr class="hr-mini" />
                <div>
                  <button class="btn btn-danger mb-2" @click="resetTv">Réinitialiser la TV</button>
                </div>
              </div>
            </div>
            <!-- SUMMARY CARD -->
            <div class="card">
              <h6>
                <span v-show="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'" class="number-title">3</span
                >Résumé
              </h6>
              <div class="row mt-2">
                <div class="col-md-12">
                  <div>
                    Inscription le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.inscription
                          ? $moment(row_en.infos.actionsFirstTime.inscription).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Première install. le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.install
                          ? $moment(row_en.infos.actionsFirstTime.install).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Envoi colis le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.parcel_sended
                          ? $moment(row_en.infos.actionsFirstTime.parcel_sended).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Dernière connexion le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.connection
                          ? $moment(row_en.infos.actionsFirstTime.connection).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Réception colis le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.parcel_received
                          ? $moment(row_en.infos.actionsFirstTime.parcel_received).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Premier appel vidéo le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.videocall_firstSuccess
                          ? $moment(row_en.infos.actionsFirstTime.videocall_firstSuccess).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Premier appel audio le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.audiocall_firstSuccess
                          ? $moment(row_en.infos.actionsFirstTime.audiocall_firstSuccess).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    Dernier appel le :
                    <span class="color-emotivi-blue ml-2">
                      {{
                        row_en.infos.actionsFirstTime && row_en.infos.actionsFirstTime.lastSuccessCall
                          ? $moment(row_en.infos.actionsFirstTime.lastSuccessCall).format("DD/MM/YYYY")
                          : ""
                      }}
                    </span>
                  </div>
                  <div>
                    VIDÉO/OK/VOIX/OK/MESSAGE
                    <span class="color-emotivi-blue ml-2">
                      <span v-for="(number, index) in row_en.infos.nbCalls" :key="index"
                        >{{ number }} <span v-if="index < row_en.infos.nbCalls.length - 1">/ </span></span
                      >
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <m-message-dialog v-model="dialogMessage" :title="dialogMessageTitle" :text="dialogMessageTxt"></m-message-dialog>
    <m-select-dialog
      v-model="dialogConfirmCancelSubscriptionRib"
      text="Il s'agit d'un abonnement SEPA, souhaitez vous définir l'état comme :"
      title="Confirmation"
      :selectValues="[
        { text: `En attente d'annulation`, value: 1 },
        { text: 'Inactif (abonnement déjà arrêté auprés de la banque)', value: 0 }
      ]"
      width="750px"
      height="350px"
      @canceled="dialogConfirmCancelSubscriptionRib = false"
      @confirmed="cancelSubscriptionRib($event)"
    ></m-select-dialog>
    <m-confirm-dialog
      v-model="dialogConfirmCancelSubscription"
      text="Souhaitez-vous résilier cet TV ?"
      title="Confirmation"
      width="500px"
      @canceled="dialogConfirmCancelSubscription = false"
      @confirmed="cancelSubscription()"
    ></m-confirm-dialog>
    <m-confirm-dialog
      v-model="dialogConfirmCancelCharge"
      text="Souhaitez-vous rembourser ce paiement ?"
      title="Confirmation"
      width="500px"
      @canceled="dialogConfirmCancelCharge = false"
      @confirmed="cancelChargeConfirmed()"
    ></m-confirm-dialog>
    <m-confirm-dialog
      v-model="dialogConfirmStartSubscription"
      text="Souhaitez-vous commencer un abonnement ?"
      title="Confirmation"
      width="500px"
      @canceled="dialogConfirmStartSubscription = false"
      @confirmed="startSubscription()"
    ></m-confirm-dialog>
    <m-confirm-dialog
      v-model="dialogConfirmStartLoan"
      text="Souhaitez-vous prêter le kit ?"
      title="Confirmation"
      width="500px"
      @canceled="dialogConfirmStartLoan = false"
      @confirmed="startLoan()"
    ></m-confirm-dialog>
    <m-confirm-dialog
      v-model="dialogConfirmStopLoan"
      text="Le kit a t'il été restitué ?"
      title="Confirmation"
      width="500px"
      @canceled="dialogConfirmStopLoan = false"
      @confirmed="stopLoan()"
    ></m-confirm-dialog>
    <m-confirm-dialog
      v-model="dialogConfirmWaitingRefund"
      text="Souhaitez-vous déclarer le contact en attente de remboursement ? Vous avez également la possibilité de rembourser directement le contact en cliquant sur la poubelle de la ligne 'Paiements'"
      title="Confirmation"
      width="500px"
      @canceled="dialogConfirmWaitingRefund = false"
      @confirmed="startWaitingRefund()"
    ></m-confirm-dialog>
    <parcel-win-edit :en_id="row_en.en_id" :pa_id="parcelSelected.pa_id" v-model="dialogParcel" @parcelSaved="parcelSaved"></parcel-win-edit>
    <tv-win-edit :row_en="row_en" :row_de="row_de" v-model="dialogTv" @saved="tvSaved" @canceled="tvCancel"></tv-win-edit>

    <contact-win-edit :co_id="row_co.co_id" v-model="dialogContact" @saved="reloadEnduser" @canceled="cancelWin"></contact-win-edit>
    <enduser-win-edit
      :en_id="row_en.en_id"
      v-model="dialogEnduser"
      @winEditSaved="reloadEnduser"
      @winEditCanceled="cancelWin"
      @winEditDeleted="deletedEnduser"
    ></enduser-win-edit>
    <payment-details
      v-if="selectedPayment"
      :payment="selectedPayment"
      v-model="displayPaymentDetails"
      @winClose="closeWin('displayPaymentDetails')"
    ></payment-details>
    <m-message-dialog v-model="mailParcelSend" title="Envoyé" text="Email Colissimo envoyé" @close="mailParcelSend = false"></m-message-dialog>
    <m-message-dialog
      v-model="deletedAllMessages"
      title="Messages effacés"
      text="Tous les messages ont été effacés"
      @close="deletedAllMessages = false"
    ></m-message-dialog>
    <video-call
      v-if="displayVideoCall && row_de"
      :row_de="row_de"
      :en_id="row_en.en_id"
      :type="callType"
      v-model="dialogVideoCall"
      @hangup="videoCallHangup"
    ></video-call>
    <cancel-charge
      @amountCancelChargeChanged="cancelCharge"
      v-model="dialogCancelCharge"
      :maxvalue="100 - this.valueChargeRefunded / 100"
    ></cancel-charge>
    <div style="display: none">
      <canvas ref="canvas" width="1024" height="768"></canvas>
    </div>
  </section>
</template>

<script>
import MFormDate from "../components/MFormDate.vue";
import CancelCharge from "./CancelChargeWinEdit.vue";
const IMAGE_MAX = 1024;
export default {
  name: "tvdetail",
  components: { CancelCharge, MFormDate },
  data() {
    return {
      amountCancelCharge: Number,
      valueChargeRefunded: 0,
      loadingStripe: false,
      loadingTickets: false,
      subscriptionMethod: "",
      showDetailsTv: false,
      deletedAllMessages: false,
      mailParcelSend: false,
      filterInfoTech: true,
      filterInfoCom: true,
      row_en: { infos: { action: [] }, de_id: {}, co_id: {} },
      row_de: {},
      row_co: {},
      //action
      newCommentAction: "",
      actions: [],
      action_length: 0,
      showActions: false,
      //parcel
      parcels: [],
      parcel_length: 0,
      dialogParcel: false,
      parcelSelected: {},
      //
      dialogMessage: false,
      dialogMessageTitle: "",
      dialogMessageTxt: "",
      //contact
      dialogTv: false,
      dialogTvLog: false,
      dialogContact: false,
      dialogEnduser: false,
      addMessage: false,
      newMessage: "",
      isAdmin: true,
      acknowledgment: false,
      duration: "00:00",
      // establishmentsSelected: {},
      // fileSelected: { image: null, binary: null },
      image: null,
      photo: true,
      imageSize: { width: 0, height: 0 },
      angle: 0,
      photoJoined: false,
      showMessages: false,
      messages: [],
      messages_length: 0,
      styleAvatar: "",
      // payments
      dialogConfirmCancelSubscription: false,
      dialogConfirmCancelSubscriptionRib: false,
      dialogConfirmCancelCharge: false,
      dialogConfirmStartSubscription: false,
      dialogConfirmStartLoan: false,
      dialogConfirmStopLoan: false,
      dialogConfirmWaitingRefund: false,
      dialogVideoCall: false,
      displayVideoCall: false,
      dialogCancelCharge: false,
      callType: "video",
      subscriptionToDelete: null,
      subscriptionToDeleteObject: {},
      chargeToDelete: null,
      amountCharge: null,
      stripeSubscriptions: [],
      stripeCharges: [],
      orders: [],
      tickets: [],
      actionDate: this.$moment().format("YYYY/MM/DD HH:mm:ss"),
      selectedActionType: "COMMENT",
      listActions: [
        { value: "PARCEL_PREORDER", text: "TV" },
        { value: "PARCEL_TO_PREPARE", text: "TV à préparer" },

        { value: "PARCEL_SENT", text: "TV envoyée" },
        { value: "PARCEL_SHIPPING_ERROR", text: "Erreur lors de la livraison de la box" },
        { value: "PARCEL_SENT_BACK_AFTER_FAILURE", text: "La box a été renvoyée après une erreur lors de la livraison" },

        { value: "PARCEL_RECEIVED", text: "TV réceptionnée" },
        { value: "STRIPE_CREATE_CUSTOMER_FROM_WEB", text: "Création du client sur Stripe" },
        { value: "STRIPE_PAYMENT_DEPOSIT_SUCCESS", text: "Caution payée et validée" },
        { value: "STRIPE_CREATE_SUBSCRIPTION_FROM_WEB", text: "Caution payée, en cours de validation" },
        { value: "STRIPE_CREATE_SUBSCRIPTION_FROM_EXTRANET", text: "Démarrage de l'abonnement" },
        { value: "STRIPE_CREATE_SUBSCRIPTION_FROM_WEB_SECONDARYTRY", text: "Caution payée, en cours de validation, après l'inscription" },
        { value: "STRIPE_CANCEL_SUBSCRIPTION_FROM_WEB", text: "Demande d'annulation de l'abonnement - Action du client" },
        { value: "STRIPE_SUBSCRIPTION_PAYMENT_SUCCESS", text: "renouvellement de l'abonnement avec paiement validé" },
        { value: "STRIPE_CHANGE_CREDIT_CARD", text: "Nouvelle carte bancaire du client" },
        { value: "STRIPE_SUBSCRIPTION_PAYMENT_ERROR", text: "Erreur lors du renouvellement de l'abonnement, le paiement a échoué" },
        { value: "STRIPE_REFUND_CHARGE_FROM_EXTRANET", text: "Remboursement du client" },
        { value: "STRIPE_SUBSCRIPTION_PREP_CANCEL", text: "Annulation de l'abonnement après la période en cours" },
        { value: "STRIPE_SUBSCRIPTION_CANCELED", text: "Abonnement annulé" },
        { value: "RIB_CREATE_SUBSCRIPTION_FROM_EXTRANET", text: "Démarrage de l'abonnement - RIB" },
        { value: "COMMENT", text: "Commentaire" },
        { value: "LOANED_KIT", text: "Kit en prêt" }
      ],
      loanedKit: false,
      scheduleMessage: false,
      enduserSubscription: 0,
      displayModifySubscriptionStartDate: false,
      payments: [],
      activeSubscription: null,
      displayPaymentDetails: false,
      selectedPayment: null,
      activePayment: null
    };
  },
  watch: {
    async showMessages(val) {
      if (val) {
        let params = { en_id: this.row_en.en_id };
        let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/messages", {
          params
        });
        if (!response || !response.data) return;
        this.messages = response.data.data;
        // console.log("this.messages", this.messages);
        this.messages_length = response.data.meta.total;
        this.styleAvatar = "background-image:url('" + val + "')";
      }
    },
    async socketResponse(val) {
      // console.log("val", val);
      if (val && val.cmd === "network-quality/ok") {
        this.dialogMessageTitle = "Diagnostique réseau";
        this.dialogMessageTxt = val.data;
        this.dialogMessage = true;
      }
    },
    actions(val) {
      if (val && val.length) {
        this.loanedKit = false;
        val.forEach(action => {
          if (action.en_id && action.en_id.en_id && this.row_en.en_id === action.en_id.en_id && action.ac_type === "LOANED_KIT") {
            // changer le bouton Kit en prêt par Restituer le kit et afficher que le kit est en prêt dans les détails
            this.loanedKit = true;
          }
        });
      }
    },
    "row_en.en_send_cancel_sub_email": {
      handler(val, oldVal) {
        //console.log(val, oldVal);

        if (val !== undefined && oldVal !== undefined && val !== oldVal) {
          // console.log("save option");
          this.saveOptionSendCancelSubMail();
        }
      }
    }
  },
  async mounted() {
    await this.loadTvEnduser();
    await this.loadPayments();

    this.getParcels();
    await this.loadActions();
  },
  computed: {
    co_invoice_address() {
      let ad = [this.row_co.co_invoice_address1];
      if (this.row_co.co_invoice_address2) ad.push(this.row_co.co_invoice_address2);
      ad.push(this.row_co.co_invoice_zip);
      ad.push(this.row_co.co_invoice_city);
      return ad.join(" ");
    },
    co_shipping_address() {
      let ad = [this.row_co.co_shipping_address1];
      if (this.row_co.co_shipping_address2) ad.push(this.row_co.co_shipping_address2);
      ad.push(this.row_co.co_shipping_zip);
      ad.push(this.row_co.co_shipping_city);
      return ad.join(" ");
    },
    tabsInfos() {
      let tabsInfos = ["de_hardwareinfo", "tv_id"];
      return tabsInfos;
    },
    socketResponse() {
      return this.$store.state.socket_response;
    },
    durationToSecond() {
      return this.$moment.duration("00:" + this.duration).asSeconds() * 1;
    },
    subscriptionStartDate: {
      get() {
        return this.activeSubscription && this.activeSubscription.su_startdate
          ? this.activeSubscription.su_startdate
          : this.$moment().format("YYYY-MM-DD");
      },
      set(value) {
        this.activeSubscription.su_startdate = this.$moment(value).format("YYYY-MM-DD");
      }
    }
  },
  methods: {
    async deleteAllMessages() {
      if (window.confirm("Souhaitez-vous effacer tous les messages de cette box ?")) {
        await this.$axios.delete(this.$config.server_url + "/extranet/2.0/messages/all/" + this.row_en.en_id);
        this.deletedAllMessages = true;
      }
    },
    allinfos(what) {
      // console.log("what", what);
      let allinfos = [];
      let ignore = ["en_webapp_login", "en_webapp_password", "en_admincode"];
      if (!this.row_de) return [];

      this.$_.each(this.row_de[what], (val, key) => {
        if (this.$_.indexOf(ignore, key) >= 0) return;
        allinfos.push({ key, val });
      });
      return allinfos;
    },
    async loadTvEnduser() {
      // load enduser
      let params = {};
      this.cancelAxiosLoadContacts && this.cancelAxiosLoadContacts();
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/endusers/" + this.$route.params.en_id * 1, {
        params,
        cancelToken: new this.$axios.CancelToken(c => {
          this.cancelAxiosLoadContacts = c;
        })
      });
      if (!response || !response.data) return;
      this.row_en = response.data.data;
      // console.log("row_en", this.row_en);
      if (response.data.data.co_id) {
        this.row_co = response.data.data.co_id;
        this.loadTickets();
        // load payments
        //this.loadPayments();
      }
      if (response.data.data.de_id) this.row_de = response.data.data.de_id;
    },
    async loadPayments() {
      this.stripeSubscriptions = [];
      this.stripeCharges = [];
      this.orders = [];
      if (!this.row_co) return;
      this.loadingStripe = true;
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/orders/payments/" + this.row_co.co_id + "/" + this.row_en.en_id);
      this.loadingStripe = false;
      if (response.data.err) {
        return;
      }
      if (response.data && response.data.data && response.data.data.stripe_data && response.data.data.stripe_data.subscriptions) {
        this.stripeSubscriptions = response.data.data.stripe_data.subscriptions;
        this.activeSubscription = this.stripeSubscriptions.filter(subscription => {
          return subscription.su_state === 2;
        });
        if (this.activeSubscription.length) this.activeSubscription = this.activeSubscription[0];
        else this.activeSubscription = null;
      } else {
        this.stripeSubscriptions = [];
      }
      if (response.data && response.data.data && response.data.data.stripe_data && response.data.data.stripe_data.charges) {
        this.stripeCharges = response.data.data.stripe_data.charges;
      } else {
        this.stripeCharges = [];
      }
      if (response.data && response.data.data && response.data.data.rows_or) {
        this.orders = response.data.data.rows_or;
      } else {
        this.orders = [];
      }

      if (response.data && response.data.data && response.data.data.rows_pm) {
        this.payments = response.data.data.rows_pm;
        this.payments.forEach(payment => {
          if (payment.pm_active) this.activePayment = payment;
        });
      } else {
        this.payments = [];
      }

      // console.log("this.stripeSubscriptions", this.stripeSubscriptions);
      //console.log("this.stripeCharges", this.stripeCharges);
      // console.log("this.orders", this.orders);
    },
    async loadTickets() {
      this.tickets = [];
      this.loadingTickets = true;
      let params = { email: this.row_co.co_invoice_email };
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/tickets", { params });
      if (response.data.data && this.$_.isArray(response.data.data)) this.tickets = response.data.data;
      this.loadingTickets = false;
      // console.log("this.tickets", this.tickets);
    },
    goFreshdeskTicket(item) {
      window.open("https://emotivi.freshdesk.com/a/tickets/" + item.id, "_blank");
    },
    async printOrder(item, $event) {
      $event.stopPropagation();
      //console.log("item", item);
      window.open(this.$config.server_url + "/extranet/2.0/orders/" + item.or_id + "/pdf?d=" + new Date().getTime(), "_blank");
    },
    async sendInvitationEmail() {
      let boby = {
        co_id: this.row_co.co_id,
        en_id: this.row_en.en_id
      };
      let response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/endusers/activation", boby);
      if (response.data.data && response.data.data.success) {
        this.dialogMessageTitle = "Confirmation";
        this.dialogMessageTxt = "Les identifiants ont bien été renvoyés";
      } else {
        this.dialogMessageTitle = "Erreur";
        this.dialogMessageTxt = "Une erreurs s'est produite, le mail n'a pas été envoyé";
      }
      this.dialogMessage = true;
    },
    async startSubscription() {
      // let response =
      await this.$axios.get(this.$config.server_url + "/extranet/2.0/startsubscription/" + this.row_en.en_id + "/" + this.subscriptionMethod);
      // console.log("response", response);
      this.dialogConfirmStartSubscription = false;
      await this.loadTvEnduser();
      this.loadPayments();
    },
    async startLoan() {
      // let response =
      await this.$axios.get(this.$config.server_url + "/extranet/2.0/startloan/" + this.row_en.en_id);
      await this.loadActions();
      // console.log("response", response);
      this.dialogConfirmStartLoan = false;
    },
    async stopLoan() {
      // let response =
      await this.$axios.get(this.$config.server_url + "/extranet/2.0/stoploan/" + this.row_en.en_id);
      await this.loadActions();
      // console.log("response", response);
      this.dialogConfirmStopLoan = false;
    },
    async startWaitingRefund() {
      // let response =
      await this.$axios.get(this.$config.server_url + "/extranet/2.0/startwaitingrefund/" + this.row_en.en_id);
      // console.log("response", response);
      this.dialogConfirmWaitingRefund = false;
      this.loadPayments();
    },
    confirmCancelSubscription(sub_id) {
      this.subscriptionToDelete = sub_id;
      this.dialogConfirmCancelSubscription = true;
    },
    confirmCancelSubscriptionRib(sub_id, subscriptionObject) {
      if (!sub_id && !subscriptionObject) {
        this.stripeSubscriptions.forEach(subscription => {
          if (
            subscription.type === "rib" &&
            subscription.end_user &&
            Array.isArray(subscription.end_user) &&
            subscription.end_user.length &&
            subscription.end_user[0].en_id === this.row_en.en_id
          ) {
            this.subscriptionToDelete = subscription.id;
            this.subscriptionToDeleteObject = subscription;
            this.dialogConfirmCancelSubscriptionRib = true;
          }
        });
      } else {
        this.subscriptionToDelete = sub_id;
        this.subscriptionToDeleteObject = subscriptionObject;
        this.dialogConfirmCancelSubscriptionRib = true;
      }
    },
    getColor(type) {
      if (type === "CLE4G_EMOTIVI") {
        return "red";
      }
    },
    getBold(type) {
      if (type === "CLE4G_EMOTIVI") {
        return "font-weight-bold";
      }
    },

    confirmCancelCharge(cha_id) {
      this.chargeToDelete = cha_id;
      this.dialogConfirmCancelCharge = true;
    },
    confirmStartSubscription(method) {
      this.subscriptionMethod = method;
      this.dialogConfirmStartSubscription = true;
    },
    confirmStartLoan() {
      this.dialogConfirmStartLoan = true;
    },
    confirmStopLoan() {
      this.dialogConfirmStopLoan = true;
    },
    confirmWaitingRefund(method) {
      this.dialogConfirmWaitingRefund = true;
    },
    async saveOptionSendCancelSubMail() {
      try {
        let response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/endusers/" + this.row_en.en_id, {
          en_send_cancel_sub_email: this.row_en.en_send_cancel_sub_email
        });
        if (response && response.data && response.data.err) {
          console.error(response.data.err);
          alert("Une erreur est survenue.");
        }
      } catch (error) {
        console.error(error);
      }
    },
    async cancelSubscription() {
      await this.$axios.delete(this.$config.server_url + "/extranet/2.0/stripe/subscription/" + this.row_en.en_id + "/" + this.subscriptionToDelete);
      // console.log("response", response);
      this.dialogConfirmCancelSubscription = false;
      this.loadPayments();
    },
    /**
     * @params dataFromSelectDialog ( 0 === inactive, 1 === waiting for cancelation )
     */
    async cancelSubscriptionRib(dataFromSelectDialog) {
      let body = {};
      // RIB subscription
      body.su_state = dataFromSelectDialog;

      await this.$axios.delete(this.$config.server_url + "/extranet/2.0/stripe/subscription/" + this.row_en.en_id + "/" + this.subscriptionToDelete, {
        data: body
      });
      // console.log("response", response);
      this.dialogConfirmCancelSubscriptionRib = false;
      await this.loadTvEnduser();
      await this.loadPayments();
    },
    cancelCharge(montant) {
      //let response =
      this.amountCharge = montant;
      this.dialogCancelCharge = false;
      // console.log("montant:", this.amountCharge);
      this.dialogConfirmCancelCharge = true;
    },
    async cancelChargeConfirmed() {
      // console.log("Confirmation appelée:");
      await this.$axios.delete(
        this.$config.server_url + "/extranet/2.0/stripe/charge/" + this.row_en.en_id + "/" + this.chargeToDelete + "/" + this.amountCharge
      );
      // console.log("response", response);
      this.dialogConfirmCancelCharge = false;
      this.loadPayments();
    },
    showConfigPage() {
      window.open(this.$config.config_url + "/#/login/" + this.row_en.en_accesstoken_fromconfig);
    },
    async showAnimation() {
      // let response =
      await this.$axios.get(this.$config.server_url + "/extranet/2.0/do/start-animation/" + this.row_en.en_id);
      // console.log("response", response);
    },
    deleteFiles() {
      if (this.photo) {
        //document.querySelector(".containerToHide").style.display = "block";
        document.querySelector(".trash").style.display = "none";
        document.querySelector(".rotate-left").style.display = "none";
        document.querySelector(".rotate-right").style.display = "none";
        this.image.src = "";
        this.image = null;
        this.photoJoined = false;
      }
    },
    handleFiles() {
      if (this.$refs.myFiles.files.length == 0) return;
      let file = this.$refs.myFiles.files[0];
      // console.log("file :>> ", file);
      let isImage = /^image\//.test(file.type);

      //  //let isVideo = /^video\//.test(file.type);
      //  this.fileSelected.image = $event[0];
      //  let isImage = /^image\//.test(this.fileSelected.image.type);

      //console.log("image size : ", file.size);
      // taille des médias bloquée à 5,1Mo.
      // if (file.size > 5064986) return alert(this.lang === "fr" ? this.pageText.alertImage_tooLarge.fr : this.pageText.alertImage_tooLarge.en);
      if (!isImage) {
        // return alert(this.lang === "fr" ? this.pageText.alertImage.fr : this.pageText.alertImage.en);
        //   this.fileSelected = { image: null, binary: null };
        //   this.deleteFiles();
        //   return;
      }
      if (document.querySelector("#media-to-send")) document.querySelector("#media-to-send").remove();
      let img = new Image();
      img.style.width = "100%";
      img.style.border = "1px solid #868b90";
      img.id = "media-to-send";
      this.photo = isImage ? true : false;
      let btn = document.querySelectorAll(".btn-img");
      btn.forEach(function(el) {
        el.style.display = "block";
      });
      this.photoJoined = true;
      // this.showFile(img, file);
      // let img = new Image();
      const fileReader_base64 = new FileReader();
      fileReader_base64.onload = e => {
        img.src = fileReader_base64.result;
        setTimeout(() => {
          this.image = img;
          this.imageSize.width = img.width;
          this.imageSize.height = img.height;
          this.$refs.preview.appendChild(img);
          let { w, h } = this._calculateWidthHeightWithMax(IMAGE_MAX);
          let ctx = this.$refs.canvas.getContext("2d");
          ctx.canvas.width = w;
          ctx.canvas.height = h;
          ctx.drawImage(img, 0, 0, w, h);
        }, 500);
      };
      fileReader_base64.readAsDataURL(file);
    },
    rotateFiles(event, sens) {
      this.angle = sens === "right" ? this.angle + 90 : this.angle - 90;
      if (this.angle === 360 || this.angle === -360) this.angle = 0;
      let max = this.$refs.previewContainer.offsetWidth;
      let translateY = 0,
        w = 0,
        h = 0;
      let { w: wOri, h: hOri } = this._calculateWidthHeightWithMax(IMAGE_MAX);
      let ctx = this.$refs.canvas.getContext("2d");
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.save();

      if (this.angle === 90 || this.angle === -90 || this.angle === 270 || this.angle === -270) {
        w = Math.ceil(max / (this.imageSize.height / this.imageSize.width));
        h = max;
        translateY = (w - h) / 2;
        this.$refs.previewContainer.style.height = w + "px";
        ctx.canvas.width = hOri;
        ctx.canvas.height = wOri;
        ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2);
        ctx.rotate((this.angle * Math.PI) / 180);
        ctx.drawImage(this.image, -ctx.canvas.width / 2, -ctx.canvas.height / 2, wOri, hOri);
      }
      if (this.angle === 0 || this.angle === 180 || this.angle === -180) {
        w = max;
        h = Math.ceil(max / (this.imageSize.width / this.imageSize.height));
        this.$refs.previewContainer.style.height = h + "px";
        ctx.canvas.width = wOri;
        ctx.canvas.height = hOri;
        ctx.translate(ctx.canvas.width / 2, ctx.canvas.height / 2);
        ctx.rotate((this.angle * Math.PI) / 180);
        ctx.drawImage(this.image, -ctx.canvas.width / 2, -ctx.canvas.height / 2, ctx.canvas.width, ctx.canvas.height);
      }
      document.querySelector("#media-to-send").style.width = w + "px";
      document.querySelector("#media-to-send").style.height = h + "px";
      this.$refs.preview.style.transform = "translate(0," + translateY + "px) rotate(" + this.angle + "deg)";

      ctx.restore();
    },
    _calculateWidthHeightWithMax(max) {
      let w = this.imageSize.width,
        h = this.imageSize.height;
      if (this.imageSize.width > this.imageSize.height) {
        w = max;
        h = Math.ceil(max / (this.imageSize.width / this.imageSize.height));
      } else {
        w = Math.ceil(max / (this.imageSize.height / this.imageSize.width));
        h = IMAGE_MAX;
      }
      return { w, h };
    },
    async sendMessage() {
      if (!this.row_en.co_id.es_id && !this.row_en.co_id.es_id.es_id) return;
      if (!this.newMessage && !this.image) return; // || (!this.photo && !this.messageVideoData)
      let dataMess = {
        me_type: this.image ? "PHOTO" : "TEXT",
        me_text: this.newMessage,
        me_isAdmin: this.isAdmin,
        me_duration: this.durationToSecond,
        me_acknowledgment: this.acknowledgment,
        avatar_uuid: this.row_en.co_id.es_id.es_id,
        en_id: this.row_en.en_id,
        me_pseudo: this.row_co.es_id.es_name,
        me_avatar: this.row_co.es_id.es_avatar
      };
      try {
        let response;
        response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/messages", dataMess);
        if (response.data.err) {
          this.$store.dispatch("showDialogError", response.data.err.message);
          return;
        }
        let rows_me = response.data.data;
        if (this.photoJoined) {
          // from webapp
          let dataMedia = {
            rows_me: rows_me,
            media: this.$refs.canvas.toDataURL("image/jpeg", 1.0) //row_me.me_type === "VIDEO" ? this.videoBase64 : this.imageBase64
          };
          await this.$axios.post(this.$config.server_url + "/extranet/2.0/messages/media", dataMedia);
        }
        // console.log("response", response);
        this.resetData();
      } catch (error) {
        console.error(error);
      }
    },
    resetData() {
      this.image = null;
      this.photo = true;
      this.newMessage = "";
      this.isAdmin = true;
      //  this.messageVideoData = null;
      this.photoJoined = false;
      if (document.querySelector("#media-to-send")) document.querySelector("#media-to-send").remove();
      this.fileSelected = { image: null, binary: null };
      this.styleAvatar = "";
      // this.establishmentsSelected = {};
      this.duration = "00:00";
      this.acknowledgment = false;
    },
    fileJusteSelected($event) {
      this.fileSelected.image = $event[0];
      var reader = new FileReader();
      var me = this;
      reader.onload = (function(theFile) {
        return function(e) {
          me.fileSelected.binary = e.target.result;
        };
      })($event[0]);
      reader.readAsDataURL($event[0]);
    },
    async diagnostikReseau() {
      // send socket event /extranet/2.0/network-quality
      this.$store.dispatch("socketEmit", { cmd: "network-quality", data: { de_mac_address: this.row_de.de_mac_address } });
    },
    ///les parcels
    followParcel(item) {
      navigator.clipboard.writeText(item.pa_trackingcode).then(
        function() {
          window.open("https://www.laposte.fr/outils/suivre-vos-envois");
        },
        function() {
          window.open("https://www.laposte.fr/outils/suivre-vos-envois");
        }
      );
    },
    copieClipboard(item) {
      // console.log("item", item);
      //navigator.clipboard.writeText(item);
    },
    parcelSaved() {
      this.getParcels();
    },
    newParcel() {
      this.parcelSelected = {};
      this.$router.push("/parcels/" + this.row_en.en_id);
      this.dialogParcel = true;
    },
    editParcel(item) {
      this.parcelSelected = item;
      this.$router.push("/parcels/" + this.row_en.en_id + "/edit/" + item.pa_id);
      this.dialogParcel = true;
    },
    async mailParcel(item) {
      await this.$axios.get(this.$config.server_url + "/extranet/2.0/parcels/mail/" + item.pa_id);
      this.mailParcelSend = true;
    },
    async getParcels() {
      let params = { en_id: this.row_en.en_id };
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/parcels", {
        params
      });
      if (!response || !response.data) return;
      this.parcels = response.data.data;
      this.parcel_length = response.data.meta ? response.data.meta.total : 0;
    },
    //Formulaire montant cancel charge
    newCancelCharge(cha_id) {
      this.chargeToDelete = cha_id;
      for (let ich = 0; ich < this.stripeCharges.length; ich++) {
        const ch = this.stripeCharges[ich];
        if (ch.payment_intent == cha_id) {
          this.valueChargeRefunded = ch.amount_refunded;
        }
      }
      this.dialogCancelCharge = true;
    },
    // les actions
    async loadActions(showList) {
      if (showList) {
        if (this.showActions) {
          this.showActions = false;
          return;
        }
        this.showActions = true;
      }

      let params = { en_id: this.row_en.en_id, sort: "t1.createdAt desc" };
      let response2 = await this.$axios.get(this.$config.server_url + "/extranet/2.0/actions", {
        params
      });
      if (!response2 || !response2.data) return;
      this.actions = response2.data.data;
      // console.log("this.actions", this.actions);
      this.action_length = response2.data.meta.total;
      if (showList) this.showActions = true;
    },
    async addCommentAction() {
      if (!this.newCommentAction && this.selectedActionType === "COMMENT") return;
      let newAction = {
        ac_type: this.selectedActionType,
        ac_comment: this.newCommentAction,
        ac_done: false,
        ac_date: this.actionDate,
        en_id: this.row_en.en_id,
        us_id: this.$store.state.user.us_id
      };
      await this.$axios.post(this.$config.server_url + "/extranet/2.0/actions", newAction);
      this.showActions = false;
      this.loadActions("showList");
      this.newCommentAction = "";
    },
    //la box
    editTv(item) {
      this.$router.push("/tvs/" + this.row_en.en_id + "/edit");
      this.dialogTv = true;
    },
    displayTvLogs(item) {
      this.$router.push("/tvs/" + this.row_en.en_id + "/displayLogs");
      this.dialogTvLog = true;
    },
    tvSaved() {
      this.loadTvEnduser();
      this.$router.push("/tvs/" + this.row_en.en_id);
    },
    tvCancel() {
      this.$router.push("/tvs/" + this.row_en.en_id);
    },
    //le contact et enduser
    editContact() {
      this.dialogContact = true;
      this.$router.push("/tvs/" + this.row_en.en_id + "/edit");
    },
    editEnduser() {
      this.dialogEnduser = true;
      this.$router.push("/tvs/" + this.row_en.en_id + "/edit");
    },
    reloadEnduser() {
      this.$router.push("/tvs/" + this.row_en.en_id);
      this.loadTvEnduser();
    },
    deletedEnduser() {
      this.$router.push("/tvs");
    },
    cancelWin() {
      this.$router.push("/tvs/" + this.row_en.en_id);
    },

    async videoCall() {
      this.callType = "video";
      this.displayVideoCall = true;
      await this.$nextTick();
      this.dialogVideoCall = true;
      // this.$router.push("/video/" + this.row_en.en_id);
    },
    async audioCall() {
      this.callType = "audio";
      this.displayVideoCall = true;
      await this.$nextTick();
      this.dialogVideoCall = true;
      // this.$router.push("/video/" + this.row_en.en_id);
    },
    videoCallHangup() {
      this.displayVideoCall = false;
      this.$router.push("/tvs/" + this.row_en.en_id + "/edit");
    },
    async onChangeConnectionType(event) {
      //console.log(event.target.value);
      try {
        let response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/contacts/" + this.row_co.co_id, {
          co_box_connection_type: event.target.value,
          co_login: this.row_co.co_login
        });
        if (response && response.data && response.data.err) {
          console.error(response.data.err);
          alert("Une erreur est survenue.");
        }
      } catch (error) {
        console.error(error);
      }
    },
    async modifySubscriptionStartDate() {
      try {
        let response = await this.$axios.put(
          this.$config.server_url + "/extranet/2.0/endusers/subscription/" + this.row_en.en_id + "/" + this.activeSubscription.id,
          {
            su_startdate: this.activeSubscription.su_startdate
          }
        );
        if (response && response.data && response.data.err) {
          console.error(response.data.err);
          alert("Une erreur est survenue.");
          return;
        }
        alert("Nouvelle date de démarrage d'abonnement enregistrée pour le " + this.$moment(this.activeSubscription.su_startdate).format("L"));
      } catch (error) {
        console.error(error);
      }
    },
    modifySubscriptionStartDateDisplay() {
      this.displayModifySubscriptionStartDate = !this.displayModifySubscriptionStartDate;
    },
    displayPaymentMethodDetails(item, event) {
      this.displayPaymentDetails = true;
      this.selectedPayment = item;
    },
    closeWin(win) {
      this[win] = !this[win];
    },
    async resetTv() {
      try {
        let ok = window.confirm("Voulez-vous réinitialiser la TV ?");
        if (ok) {
          let response = await this.$axios.delete(this.$config.server_url + "/extranet/2.0/devices/tv/" + this.row_de.de_id);
          if (response && response.data && response.data.err) {
            console.error(response.data.err);
            alert("Une erreur est survenue.");
            return;
          }
          alert("La TV a été réinitialisée.");
          await this.loadTvEnduser();
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";
.container-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  border: 1px solid #b5b5b5;
  box-shadow: 2px 2px 6px 0px #d0d0d0;
}
label.m-form-checkbox {
  margin-bottom: 0 !important;
}
.fileContainer {
  height: 50px;
  background-color: #ffffff;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 0;
  //   align-items: flex-end;
  border: 1px solid #ced4da;
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}
.btn-img {
  padding: 6px;
  display: none;
  position: absolute;
  bottom: 62px;
  width: 40px;
  height: 40px;
  //   margin-bottom: 20px;
  border: 1px solid #b7b5b5;
}
.rotate-left {
  right: 150px;
}
.rotate-right {
  right: 110px;
}
.trash {
  right: 65px;
}
.btn-rotate {
  background-color: #ffffff8f;
}
#media-to-send {
  transform-origin: left;
}
.tr-tvdetail {
  border-bottom: 1px solid #e4e4e4;
}
.mygreen {
  color: green;
  font-weight: bold;
}
.myred {
  color: red;
}
// .btn-height-auto {
//   max-height: initial;
// }
.hr-mini {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}
.infComVsTech {
  margin-top: 10px;
}
.checkbox {
  height: 20px;
}
.linkello {
  color: #199aaa;
  font-weight: bold;
}
.logo-linkello {
  width: 100px;
}
</style>
