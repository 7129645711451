var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("m-form-text", {
        ref: "myinput",
        attrs: {
          "data-toggle": "dropdown",
          id: _vm.id,
          type: "text",
          name: _vm.name,
          autocomplete: _vm.autocomplete,
          readonly: _vm.readonly,
          disabled: _vm.disabled,
          autoSelectOnFocus: _vm.autoSelectOnFocus,
          label: _vm.label,
          tooltip: _vm.tooltip,
          tooltipPosition: _vm.tooltipPosition,
          rules: _vm.rules,
          placeholder: _vm.placeholder,
          appendIcon: "arrow-down",
          prependIcon: _vm.prependIcon,
          appendText: _vm.appendText,
          subText: _vm.subText,
          prependText: _vm.prependText,
          ariaHaspopup: true,
          ariaExpanded: _vm.showDropdown,
          selectMultiple: true
        },
        on: {
          click: _vm.onclick,
          input: _vm.oninput,
          focus: _vm.onfocus,
          keyup: _vm.onkeyup,
          ShowDropdownMenu: _vm.ShowDropdownMenu
        },
        model: {
          value: _vm.rawvalue,
          callback: function($$v) {
            _vm.rawvalue = $$v
          },
          expression: "rawvalue"
        }
      }),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showDropdown,
              expression: "showDropdown"
            }
          ],
          ref: "mydropdown",
          staticClass: "dropdown-menu",
          staticStyle: { display: "block" },
          attrs: { role: "menu" },
          on: { focus: _vm.onfocus }
        },
        _vm._l(_vm.rawitems, function(item, i) {
          return _c(
            "li",
            {
              key: item.value,
              class: {
                "dropdown-item": true,
                focusDropdown:
                  _vm.focusDropdown && _vm.focusDropdown.index === i
              },
              attrs: { role: "menuitem" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.setValue2($event, item)
                }
              }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }
                ],
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(item.checked)
                    ? _vm._i(item.checked, null) > -1
                    : item.checked
                },
                on: {
                  change: function($event) {
                    var $$a = item.checked,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            item,
                            "checked",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(item, "checked", $$c)
                    }
                  }
                }
              }),
              _c("label", [_vm._v(" " + _vm._s(item.text))])
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }