<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div v-if="offer_id > 0">
              <h1>
                Éditer une offre
              </h1>
              <p>créé le : {{ $moment(row_of.createdAt).format("DD/MM/YYYY HH[H]mm") }}</p>
            </div>
            <div v-else>
              <h1>
                Création d'une offre
              </h1>
            </div>
            <div class="d-flex flex-row align-items-center active">
              <m-form-checkbox label="Actif" :name="$Utils.randomstring('of_active')" v-model="row_of.of_active"></m-form-checkbox>
            </div>
          </div>
          <div class="modal-body">
            <div>
              <h6><span class="number-title">1</span>Nom et type</h6>
              <div class="row">
                <div class="col-md-8">
                  <m-form-text label="Nom de l'offre" v-model="row_of.of_name" label-position="top" name="of_name"></m-form-text>
                </div>
                <div class="col-md-4 position-relative">
                  <m-form-checkbox
                    style="right:15px;top:15px;z-index:10"
                    class="position-absolute"
                    label="Modifier"
                    v-model="enableOfferKeyModification"
                  ></m-form-checkbox>
                  <m-form-text
                    label="Identifiant"
                    v-model="row_of.of_key"
                    label-position="top"
                    name="of_key"
                    id="of_key"
                    :disabled="!enableOfferKeyModification"
                  ></m-form-text>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <m-form-text label="Nom sur la facture" v-model="row_of.of_invoice_name" label-position="top" name="of_invoice_name"></m-form-text>
                </div>
                <div class="col-md-4">
                  <m-form-text label="Compte comptable" v-model="row_of.of_accounting" label-position="top" name="of_accounting"></m-form-text>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <m-form-text
                    type="textarea"
                    label="Description"
                    v-model="row_of.of_description"
                    label-position="top"
                    name="description"
                  ></m-form-text>
                </div>
              </div>
            </div>
            <div>
              <h6 class="mt-5 mb-3"><span class="number-title">2</span> Services et produits</h6>
              <div v-for="(ofpr, index) in ProductsPrice" :key="ofpr.ofpr_id">
                <div class="row d-flex flex-row align-items-end">
                  <div class="col-md-9">
                    <m-form-combobox
                      label="Produit"
                      label-position="top"
                      :name="$Utils.randomstring('pr_id')"
                      v-model="ofpr.pr_id"
                      :store-url="$config.server_url + '/extranet/2.0/products'"
                      :store-params="{}"
                      item-value="pr_id"
                      item-text="pr_name"
                    ></m-form-combobox>
                  </div>
                  <div class="col-md-3">
                    <button class="btn btn-danger" @click="deleteProductConfirm(index, ofpr)">Supprimer le produit</button>
                  </div>
                </div>
                <div v-if="ofpr.pr_id.pr_type === 'SUBSCRIPTION'">
                  <div class="row d-flex align-items-end">
                    <div class="col-md-3 uppercase">Frais de dossier/Caution</div>
                    <div class="col-md-2">
                      <m-form-text
                        label="Prix HT"
                        @input="calculInitialPriceTtc(index)"
                        v-model="ofpr.ofpr_initial_price_ht"
                        label-position="top"
                        name="priceht"
                      ></m-form-text>
                    </div>
                    <div class="col-md-2">
                      <m-form-text label="TVA" v-model="tva" label-position="top" name="pricetva" disabled></m-form-text>
                    </div>
                    <div class="col-md-2">
                      <m-form-text label="Prix TTC" v-model="ofpr.initialPrice_ttc" label-position="top" name="pricettc" disabled></m-form-text>
                    </div>
                    <!-- <div class="col-md-3">
                      <m-form-select
                        label="Pendant"
                        label-position="top"
                        :name="$Utils.randomstring('duration')"
                        v-model="ofpr.ofpr_sub_trial_period"
                        :items="$store.state.item_of_sub_trial_period"
                      ></m-form-select>
                    </div> -->
                  </div>
                  <div class="row d-flex align-items-end">
                    <div class="col-md-3 uppercase">Forfait</div>
                    <div class="col-md-2">
                      <m-form-text
                        label="Prix HT"
                        @input="calculSubscriptionPriceTtc(index)"
                        v-model="ofpr.ofpr_subscription_price_ht"
                        label-position="top"
                        name="priceht"
                      ></m-form-text>
                    </div>
                    <div class="col-md-2">
                      <m-form-text label="TVA" v-model="tva" label-position="top" name="pricetva" disabled></m-form-text>
                    </div>
                    <div class="col-md-2">
                      <m-form-text label="Prix TTC" v-model="ofpr.subscriptionPrice_ttc" label-position="top" name="pricettc" disabled></m-form-text>
                    </div>
                    <div class="col-md-2">
                      <m-form-select
                        label="Cycle du forfait"
                        label-position="top"
                        :name="$Utils.randomstring('ofpr_subscription_cycle_billing')"
                        v-model="ofpr.ofpr_subscription_cycle_billing"
                        :items="itemCycleSubscription"
                      ></m-form-select>
                    </div>
                  </div>
                </div>
                <div v-if="ofpr.pr_id.pr_type === 'SERVICE' || ofpr.pr_id.pr_type === 'HARDWARE'">
                  <div class="row">
                    <div class="col-md-2">
                      <m-form-text
                        label="Prix HT"
                        @input="calculInitialPriceTtc(index)"
                        v-model="ofpr.ofpr_initial_price_ht"
                        label-position="top"
                        name="priceht"
                      ></m-form-text>
                    </div>
                    <div class="col-md-2">
                      <m-form-text label="TVA" v-model="tva" label-position="top" name="pricetva" disabled></m-form-text>
                    </div>
                    <div class="col-md-2">
                      <m-form-text label="Prix TTC" v-model="ofpr.initialPrice_ttc" label-position="top" name="pricettc" disabled></m-form-text>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
              <button class="btn btn-primary" @click="addProduct">Ajouter produit / service</button>
              <!--               <div class="row" v-if="showAddProduct">
                <div class="col-md-8">
                  <m-form-combobox
                    label="Produit"
                    label-position="top"
                    :name="$Utils.randomstring('products')"
                    v-model="newProduct"
                    :store-url="$config.server_url + '/extranet/2.0/products'"
                    :store-params="{}"
                    item-value="pr_id"
                    item-text="pr_name"
                  ></m-form-combobox>
                </div>
              </div>
              <div v-if="newProduct.pr_type === 'SUBSCRIPTION'">
                <div class="row d-flex align-items-end">
                  <div class="col-md-3 uppercase">Période 1</div>
                  <div class="col-md-2">
                    <m-form-text label="Prix HT" v-model="newProductPrice.ofpr_sub_trial_price" label-position="top" name="priceht"></m-form-text>
                  </div>
                  <div class="col-md-2">
                    <m-form-text label="TVA" v-model="tva" label-position="top" name="pricetva" disabled></m-form-text>
                  </div>
                  <div class="col-md-2">
                    <m-form-text label="Prix TTC" v-model="calculNewSubTrialPriceTtc" label-position="top" name="pricettc" disabled></m-form-text>
                  </div>
                  <div class="col-md-3">
                    <m-form-select
                      label="Pendant"
                      label-position="top"
                      :name="$Utils.randomstring('duration')"
                      v-model="newProductPrice.ofpr_sub_trial_period"
                    ></m-form-select>
                  </div>
                </div>
                <div class="row d-flex align-items-end">
                  <div class="col-md-3 uppercase">Puis chaque mois</div>
                  <div class="col-md-2">
                    <m-form-text label="Prix HT" v-model="newProductPrice.ofpr_price_ht" label-position="top" name="priceht"></m-form-text>
                  </div>
                  <div class="col-md-2">
                    <m-form-text label="TVA" v-model="tva" label-position="top" name="pricetva" disabled></m-form-text>
                  </div>
                  <div class="col-md-2">
                    <m-form-text label="Prix TTC" v-model="calculNewPriceTtc" label-position="top" name="pricettc" disabled></m-form-text>
                  </div>
                </div>
              </div>
              <div v-if="newProduct.pr_type === 'SERVICE'">
                <div class="row">
                  <div class="col-md-2">
                    <m-form-text label="Prix HT" v-model="newProductPrice.ofpr_price_ht" label-position="top" name="priceht"></m-form-text>
                  </div>
                  <div class="col-md-2">
                    <m-form-text label="TVA" v-model="tva" label-position="top" name="pricetva" disabled></m-form-text>
                  </div>
                  <div class="col-md-2">
                    <m-form-text label="Prix TTC" v-model="calculNewPriceTtc" label-position="top" name="pricettc" disabled></m-form-text>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between">
            <button type="button" class="btn btn-danger" @click="deleteConfirmWin">
              Supprimer
            </button>
            <div>
              <button type="button" class="btn btn-secondary" @click="cancelWin">
                Annuler
              </button>
              <button type="button" class="btn btn-primary ml-2" @click="saveWin(false)">
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <m-message-dialog v-model="dialogErr" title="Erreur" :text="dialogErrTxt"></m-message-dialog>
      <m-confirm-dialog
        v-model="confirmdelete"
        text="Souhaitez-vous cette offre ?"
        title="Confirmation"
        width="500px"
        @canceled="confirmdelete = false"
        @confirmed="deleteWin()"
      ></m-confirm-dialog>
      <m-confirm-dialog
        v-model="confirmdeleteProduct"
        :text="'Souhaitez-vous le produit ' + productToDelete.pr_id.pr_name + ' ?'"
        title="Confirmation"
        width="500px"
        @canceled="confirmdeleteProduct = false"
        @confirmed="deleteProduct()"
      ></m-confirm-dialog>
    </div>
  </transition>
</template>

<script>
export default {
  name: "OffersWinEdit",
  components: {},
  props: {
    of_id: Number,
    value: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      row_of: {},
      offer_id: -1,
      confirmdelete: false,
      tva: 20,
      showAddProduct: false,
      newProduct: {},
      newProductPrice: {},
      ProductsPrice: [],
      confirmdeleteProduct: false,
      indexProductToDelete: null,
      productToDelete: { pr_id: {} },
      dialogErrTxt: "",
      dialogErr: false,
      itemCycleSubscription: [
        { text: "Jour", value: "DAYS" },
        { text: "Semaine", value: "WEEKS" },
        { text: "Mois", value: "MONTHS" }
      ],
      enableOfferKeyModification: false
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.offer_id = this.of_id ? this.of_id : -1;
        this.loadOffer();
      }
    },
    enableOfferKeyModification(value) {
      if (value) {
        let ok = window.confirm(
          "Voulez vous modifier l'identifiant de l'offre ? Attention, cet identifiant est utilisé pour filtrer les offres, ne pas modifier l'identifiant 'emotivi' existant."
        );

        if (!ok)
          setTimeout(() => {
            this.enableOfferKeyModification = false;
          }, 0);
      }
    }
  },
  computed: {},

  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  created() {},
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    calculTTC(num) {
      return Math.round((parseFloat(num) + (num * this.tva) / 100) );
    },
    calculSubscriptionPriceTtc(index) {
      this.ProductsPrice[index].subscriptionPrice_ttc = this.calculTTC(this.ProductsPrice[index].ofpr_subscription_price_ht);
      // console.log("this.ProductsPrice[index].price_ttc", this.ProductsPrice[index].subscriptionPrice_ttc);
    },
    calculInitialPriceTtc(index) {
      this.ProductsPrice[index].initialPrice_ttc = this.calculTTC(this.ProductsPrice[index].ofpr_initial_price_ht);
      // console.log("this.ProductsPrice[index].price_ttc", this.ProductsPrice[index].initialPrice_ttc);
    },
    async loadOffer() {
      let params = {};
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/offers/" + this.offer_id, { params });
      if (!response || !response.data) return;
      this.row_of = response.data.data;
      for (let iOfpr = 0; iOfpr < this.row_of.offersproducts.length; iOfpr++) {
        const ofpr = this.row_of.offersproducts[iOfpr];
        ofpr.initialPrice_ttc = ofpr.ofpr_initial_price_ht + (ofpr.ofpr_initial_price_ht * this.tva) / 100;
        if (ofpr.ofpr_subscription_price_ht)
          ofpr.subscriptionPrice_ttc = this.calculTTC(ofpr.ofpr_subscription_price_ht);
      }
      this.ProductsPrice = this.row_of.offersproducts;
    },
    addProduct() {
      this.ProductsPrice.push({
        pr_id: {},
        ofpr_price_ht: null,
        ofpr_sub_trial_price: null,
        ofpr_sub_trial_period: null
      });
    },
    deleteProductConfirm(index, product) {
      this.confirmdeleteProduct = true;
      this.indexProductToDelete = index;
      this.productToDelete = product;
    },
    deleteProduct() {
      this.ProductsPrice.splice(this.indexProductToDelete, 1);
      this.indexProductToDelete = null;
      this.productToDelete = { pr_id: {} };
      this.confirmdeleteProduct = false;
    },
    async saveWin() {
      let err = [];
      let fieldRequired = [{ field: "of_name", text: "nom de l'offre" }];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.row_of[field.field]) err.push(field);
      }
      //check products
      let err_product = false;
      for (let ipr = 0; ipr < this.ProductsPrice.length; ipr++) {
        const product = this.ProductsPrice[ipr];
        if (!product.pr_id.pr_id || (product.pr_type === "SUBSCRIPTION" && !product.ofpr_subscription_price_ht)) {
          err_product = true;
          break;
        }
      }
      // console.log("err_product", err_product);
      if (err.length || err_product) {
        this.dialogErrTxt = "";
        if (err.length) {
          this.dialogErrTxt += "<span class='bis'>Vous devez remplir les champs : </span><br>";
          for (let ierr = 0; ierr < err.length; ierr++) {
            const error = err[ierr];
            this.dialogErrTxt += error.text + " <br>";
          }
        }
        if (err_product) {
          this.dialogErrTxt += "<br><span class='bis'>Vous devez remplir tous les champs des produits ou bien supprimer le produit. </span><br>";
        }
        this.dialogErr = true;
      } else {
        let response;
        this.row_of.offersproducts = this.ProductsPrice;
        if (this.row_of.of_id) {
          response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/offers/" + this.row_of.of_id, this.row_of);
        } else {
          this.row_of.of_active = true;
          response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/offers", this.row_of);
        }
        if (response.data.err) {
          this.$store.dispatch("showDialogError", response.data.err.message);
          return;
        }
        let row_of = response.data.data;
        this.$emit("input", false);
        this.$emit("savedWin", row_of.of_id);
        this.$router.push("/offers");
      }
    },
    cancelWin() {
      this.$emit("input", false);
      this.$router.push("/offers");
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteWin() {
      await this.$axios.delete(this.$config.server_url + "/api/1.0/materials/" + this.ma_id);
      this.confirmdelete = false;
      this.$emit("input", false);
      this.$router.push("/offers");
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";
</style>
