<template>
  <section class="d-flex w-100">
    <div class="d-flex flex-column  w-100" id="boxes">
      <div class="d-flex header-emotivi">
        <div class="d-flex align-items-center">
          <h1>Liste des établissements ({{ establishments_total }})</h1>
        </div>
        <div class="flex-grow-1 d-flex justify-content-end align-items-end" style="margin-top:-40px;">
          <m-form-text
            class="ml-3"
            label="Rechercher"
            label-position="top"
            v-model="filterText"
            name="saerch"
            autocomplete
            @input="loadEstablishmentsFilterText"
          ></m-form-text>
          <button @click="addEstablishment" class="ml-3 btn btn-sm btn-primary color-white" type="button">
            <icon name="plus" scale="1" class="mr-2"></icon> Nouveau
          </button>
        </div>
      </div>

      <div class="flex-grow-1 overflow-hidden d-flex w-100">
        <div class="card card-no-margin w-100">
          <m-list-simple
            ref="contactslist"
            :dynamic="true"
            :items="establishments"
            item-value="bo_id"
            :item-height="30"
            :limit="establishments_limit"
            :skip="establishments_skip"
            :total="establishments_total"
            @itemclick="editEstablishment"
          >
            <template v-slot:ths="{}">
              <th style="min-width:50px;" @click="sortItems(['es_name'])">
                <span class="pointer">Nom</span>
              </th>
              <th @click="sortItems(['es_city'])">
                <span class="pointer">Ville</span>
              </th>
              <th class="">
                <span class="pointer">Membres de l'établissement</span>
              </th>

              <!-- <th @click="sortItems(['nb_box'])">
                <span class="pointer">Nombre de box</span>
              </th> -->
            </template>
            <template v-slot:tds="{ item }">
              <td>{{ item.es_name }}</td>
              <td>{{ item.es_city }}</td>
              <td class="d-flex flex-row">
                <div v-for="user in item.users" :key="user.us_id">
                  <router-link class="nav-link" :to="{ path: '/users/' + user.us_id + '?establishment=true' }"
                    >{{ user | formatContactNameSimple("us_") }} ,</router-link
                  >
                </div>
              </td>

              <!-- <td>{{ item.nbBoxes }}</td> -->
              <td></td>
              <td></td>
            </template>
          </m-list-simple>
        </div>
      </div>
    </div>
    <establishment-win-edit :es_id="itemSelected.es_id" v-model="dialog" @saveEstablishment="loadEstablishments"></establishment-win-edit>
  </section>
</template>

<script>
export default {
  name: "establishments",
  components: {},
  data() {
    return {
      establishments: [],
      establishments_total: 0,
      establishments_limit: 0,
      establishments_skip: 0,
      showOccupedRoom: false,
      filterText: "",
      filterEstablishment: "",
      filterStatusCommand: "",
      sortOrder: "asc",
      sortFields: ["es_name"],
      dialog: false,
      itemSelected: {},
      timeout: null
    };
  },
  watch: {},
  async mounted() {
    let id = parseInt(this.$route.params.es_id);
    if (id) {
      this.itemSelected.es_id = id;
      this.dialog = true;
    }
    this.loadEstablishments();
  },
  computed: {},
  methods: {
    sortItems(fields) {
      // console.log("fields", fields);
      if (fields.length && this.sortFields.length && fields[0] == this.sortFields[0]) {
        this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
      }
      this.sortFields = fields;
      if (fields[0] !== "nb_box") {
        this.loadEstablishments();
      }
      if (fields[0] === "nb_box") {
        this.establishments.sort((a, b) => {
          if (this.sortOrder === "asc") return a.nbBoxes - b.nbBoxes;
          if (this.sortOrder === "desc") return b.nbBoxes - a.nbBoxes;
        });
        /*         this.patients.sort((a, b) => {
          if (this.sortOrder === "asc") {
            if (this.$moment(a.co_connection_date).isBefore(b.co_connection_date)) return -1;
            if (this.$moment(a.co_connection_date).isAfter(b.co_connection_date)) return 1;
            if (this.$moment(a.co_connection_date).isSame(b.co_connection_date)) return 0;
          } else {
            if (this.$moment(a.co_connection_date).isBefore(b.co_connection_date)) return 1;
            if (this.$moment(a.co_connection_date).isAfter(b.co_connection_date)) return -1;
            if (this.$moment(a.co_connection_date).isSame(b.co_connection_date)) return 0;
          }
        }); */
      }
    },
    loadEstablishmentsFilterText() {
      if (this.timeout) window.clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.loadEstablishments();
      }, 800);
    },
    async loadEstablishments() {
      let sort = this.sortFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.sortOrder;
        return accumulator;
      }, "");
      let params = {
        sort: sort
      };
      if (this.establishments_limit) params.limit = this.establishments_limit;
      if (this.establishments_skip) params.skip = this.establishments_skip;
      if (this.filterText) params.text = this.filterText;
      this.cancelAxiosLoadContacts && this.cancelAxiosLoadContacts();
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/establishments", {
        params,
        cancelToken: new this.$axios.CancelToken(c => {
          this.cancelAxiosLoadContacts = c;
        })
      });
      if (!response || !response.data) return;
      this.establishments = response.data.data;
      this.establishments_total = response.data.meta.total;
    },
    addEstablishment() {
      this.$router.push("/establishments/-1");
      this.itemSelected.es_id = -1;
      this.dialog = true;
    },
    editEstablishment(item) {
      this.itemSelected = item;
      this.$router.push("/establishments/" + this.itemSelected.es_id);
      this.dialog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";
a.nav-link {
  color: $emotivi-red;
  padding: 0 3px;
}
</style>
