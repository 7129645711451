<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div v-if="user_id > 0">
              <h1>
                {{ row_us | formatContactNameSimple("us_") }}
              </h1>
              <p>créé le : {{ $moment(row_us.createdAt).format("DD/MM/YYYY HH[H]mm") }}</p>
            </div>
            <div v-else>
              <h1>Création d'un membre</h1>
            </div>
            <div v-if="$store.state.user.us_type !== 'PARTNER'" class="d-flex flex-row align-items-center active">
              <label class="label-checkbox" for="active">Activer</label>
              <input type="checkbox" id="actif" name="actif" v-model="row_us.us_active" />
            </div>
          </div>
          <div class="modal-body">
            <div v-if="user.establishments > 1 || getUserTypesList().length > 1" class="row">
              <h6><span class="number-title">1</span>Type du membre et établissements</h6>
            </div>
            <div v-if="getUserTypesList().length > 1" class="row">
              <div class="col-md-6">
                <m-form-select
                  label="Type"
                  v-model="row_us.us_type"
                  :name="$Utils.randomstring('us_type')"
                  :rules="[$Validation.mandatory]"
                  :items="getUserTypesList()"
                ></m-form-select>
              </div>
            </div>
            <div v-if="$store.state.user.us_type === 'LATEAM' || user.establishments > 1" class="row">
              <div class="col-md-12">
                <div class="row d-flex align-items-end">
                  <div class="col-md-6">
                    <m-form-select
                      label="Établissements"
                      v-model="newEstablishment"
                      :name="$Utils.randomstring('newEstablishment')"
                      :rules="[$Validation.mandatory]"
                      :items="user.establishments"
                    ></m-form-select>
                  </div>
                  <div class="col-md-4">
                    <button class="btn btn-primary" @click="addEstablishment">Ajouter</button>
                  </div>
                </div>
                <div v-if="establishments && establishments.length" class="row mt-3">
                  <div class="col-md-12 d-flex">
                    <div class="establishment" v-for="es in establishments" :key="es.es_id">
                      {{ es.es_name }} <span @click="deleteEstablishment(es)" class="ml-2 pointer"> <icon name="times"></icon></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <h6>
                <span class="number-title">{{ user.establishments > 1 || getUserTypesList().length > 1 ? "2" : "1" }}</span
                >Coordonnées
              </h6>
            </div>
            <div class="row">
              <div class="col-md-2">
                <m-form-select
                  label="Civilité"
                  v-model="row_us.us_civility"
                  :name="$Utils.randomstring('us_civility')"
                  :rules="[$Validation.mandatory]"
                  :items="$store.state.items_civility"
                ></m-form-select>
              </div>
              <div class="col-md-5">
                <m-form-text
                  label="Prénom *"
                  type="text"
                  v-model="row_us.us_firstname"
                  :name="$Utils.randomstring('us_firstname')"
                  :rules="[$Validation.mandatory]"
                ></m-form-text>
              </div>
              <div class="col-md-5">
                <m-form-text
                  label="Nom *"
                  type="text"
                  v-model="row_us.us_name"
                  :name="$Utils.randomstring('us_name')"
                  :rules="[$Validation.mandatory]"
                ></m-form-text>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <m-form-text
                  label="Téléphone mobile"
                  type="text"
                  v-model="row_us.us_tel"
                  :name="$Utils.randomstring('us_tel')"
                  :rules="[$Validation.mandatory]"
                ></m-form-text>
              </div>
              <div class="col-md-4">
                <m-form-text
                  label="Email *"
                  type="text"
                  v-model="row_us.us_login"
                  :name="$Utils.randomstring('us_login')"
                  :rules="[$Validation.mandatory]"
                ></m-form-text>
              </div>
              <!--               <div class="col-md-4">
                <m-form-text
                  label="Société"
                  type="text"
                  v-model="row_us.us_society"
                  :name="$Utils.randomstring('us_society')"
                  :rules="[$Validation.mandatory]"
                ></m-form-text>
              </div> -->
            </div>

            <div class="row mt-5">
              <h6>
                <span class="number-title">{{ user.establishments > 1 || getUserTypesList().length > 1 ? "3" : "2" }}</span
                >Informations de connexion
              </h6>
            </div>
            <div class="row">
              <div class="col-md-4">
                <m-form-text
                  label="Login"
                  type="text"
                  v-model="row_us.us_login"
                  :name="$Utils.randomstring('us_login')"
                  :rules="[$Validation.mandatory]"
                  subText="Votre login est votre adresse mail"
                  disabled
                ></m-form-text>
              </div>
              <div class="col-md-4">
                <m-form-text
                  label="Mot de passe"
                  type="password"
                  v-model="password1"
                  :name="$Utils.randomstring('password1')"
                  :rules="[$Validation.mandatory]"
                ></m-form-text>
              </div>
              <div class="col-md-4">
                <m-form-text
                  label="Répétez le mot de passe"
                  type="password"
                  v-model="password2"
                  :name="$Utils.randomstring('password2')"
                  :rules="[$Validation.mandatory]"
                ></m-form-text>
              </div>
            </div>
            <!--  informations complementaire only for partner user type -->
            <div v-if="row_us.us_type === 'PARTNER' || $store.state.user.us_type === 'PARTNER'" class="row mt-5">
              <h6>
                <span class="number-title">{{ user.establishments > 1 || getUserTypesList().length > 1 ? "4" : "3" }}</span
                >Informations complémentaires
              </h6>
            </div>
            <div v-if="row_us.us_type === 'PARTNER' || $store.state.user.us_type === 'PARTNER'" class="row">
              <div class="col-md-4">
                <m-form-text
                  label="Code d'affiliation"
                  type="text"
                  v-model="row_us.af_code"
                  :name="$Utils.randomstring('af_code')"
                  :rules="[$Validation.mandatory]"
                  subText="Il s'agit du code que les clients saisiront lors de leur inscription sur le site Emotivi"
                  :disabled="$store.state.user.us_type === 'PARTNER' && us_id !== -1"
                ></m-form-text>
              </div>
            </div>
            <div v-if="$store.getters.checkUserAccess($router, '/actions')" class="row my-3">
              <div class="col-md-12">
                <button @click="showAllActions" class="btn btn-primary w-100">
                  {{ showActions ? "Masquer les actions" : "Afficher les actions" }}
                </button>
              </div>
            </div>
            <div class="row mt-4" v-if="showActions">
              <div class="col-md-12">
                <m-list-simple ref="commandelist" :dynamic="true" :items="actions" item-value="ac_id" :total="action_length">
                  <template v-slot:ths="{}">
                    <th width="200px">
                      <span class="pointer">Date</span>
                    </th>
                    <th width="200px">
                      <span class="pointer">Type</span>
                    </th>
                    <th>
                      <span class="pointer">Infos</span>
                    </th>
                  </template>
                  <template v-slot:tds="{ item }">
                    <td>{{ $moment(item.createdAt).format("DD/MM/YYYY HH[H]mm") }}</td>
                    <td>
                      {{ item.ac_type }}
                    </td>
                    <td>
                      {{ item.ac_comment }}
                    </td>
                  </template>
                </m-list-simple>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between">
            <button v-if="$store.state.user.us_type !== 'PARTNER'" type="button" class="btn btn-danger" @click="deleteConfirmWin">Supprimer</button>
            <div>
              <button type="button" class="btn btn-secondary" @click="cancelWin">Annuler</button>
              <button type="button" class="btn btn-primary btn-mouveal ml-2" @click="saveWin(false)">Enregistrer</button>
            </div>
          </div>
        </div>
      </div>
      <m-confirm-dialog
        v-model="confirmdelete"
        text="Souhaitez-vous effacer ce client ?"
        title="Confirmation"
        width="500px"
        @canceled="confirmdelete = false"
        @confirmed="deleteWin()"
      ></m-confirm-dialog>
      <m-message-dialog v-model="dialogErr" title="Erreur" :text="dialogErrTxt"></m-message-dialog>
    </div>
  </transition>
</template>

<script>
export default {
  name: "userWinEdit",
  components: {},
  props: {
    us_id: Number,
    value: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      user: {},
      user_id: -1,
      row_us: {},
      confirmdelete: false,
      showMessages: false,
      showMessagesActifsOnly: true,
      showActions: false,
      actions: [],
      action_length: 0,
      password1: "",
      password2: "",
      establishments: [],
      newEstablishment: 0,
      dialogErrTxt: "",
      dialogErr: false
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.user_id = this.us_id ? this.us_id : -1;
        this.loadUser();
      }
    },
    "row_us.af_code"(v) {
      this.row_us.af_code = this.row_us.af_code.trim();
    }
  },
  computed: {},

  mounted() {
    let user = this.$store.state.user;
    if (user.establishments.length) {
      for (let ies = 0; ies < user.establishments.length; ies++) {
        const es = user.establishments[ies];
        es.text = es.es_name;
        es.value = es.es_id;
      }
    }
    this.user = user;
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  created() {},
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    async showAllActions() {
      let params = { us_id: this.row_us.us_id, sort: "t1.createdAt desc" };
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/actions", { params });
      this.actions = response.data.data;
      this.action_length = response.data.meta.total;
      this.showActions = !this.showActions;
    },
    addEstablishment() {
      if (!this.newEstablishment) return alert("Veuillez sélectionner un établissement.");
      if (this.row_us.us_type === "EMOTIVI_USER") {
        // get es_id of Emotivi establishments
        let emotiviEstablishment = this.user.establishments.filter(establishment => {
          if (establishment.es_name.toLowerCase() === "emotivi") return true;
        });

        if (emotiviEstablishment.length && emotiviEstablishment[0].es_id !== this.newEstablishment)
          return alert("Vous devez selectionner l'établissement Emotivi.");
      }

      let es = this.$_.find(this.establishments, { es_id: this.newEstablishment });
      if (!es) {
        let esObj = this.$_.find(this.user.establishments, { es_id: this.newEstablishment });
        this.establishments.push(esObj);
      }
      this.newEstablishment = 0;
    },
    deleteEstablishment(item) {
      let esIndex = this.$_.findIndex(this.establishments, { es_id: item.es_id });
      if (esIndex >= 0) {
        this.establishments.splice(esIndex, 1);
      }
    },
    async loadUser() {
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/users/" + this.user_id);
      if (!response || !response.data) return;
      this.row_us = response.data.data;
      if (this.row_us.establishments && this.row_us.establishments.length) this.establishments = this.row_us.establishments;
    },
    async saveWin() {
      if (this.$store.state.user.us_type !== "LATEAM" && this.user.establishments.length === 1) {
        this.newEstablishment = this.user.establishments[0].es_id;
        this.addEstablishment();
      }
      if (this.getUserTypesList().length === 1) {
        this.row_us.us_type = this.getUserTypesList()[0].value;
      }
      let err = [];
      let fieldRequired = [
        { field: "us_type", text: "type" },
        { field: "us_name", text: "nom" },
        { field: "us_firstname", text: "prénom" },
        { field: "us_login", text: "login" }
      ];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.row_us[field.field]) err.push(field);
      }
      /*** vérification du mot de passe */
      if (this.password1 !== this.password2) {
        err.push({
          field: "us_password",
          text: "<br><span class='bis'>Les mots de passe ne sont pas identiques</span>"
        });
      } else {
        this.row_us.us_password = this.password1;
        this.password1 = "";
        this.password2 = "";
      }
      if (this.row_us.us_type !== "lateam" && !this.establishments.length) {
        err.push({
          field: "establishment",
          text: "<br><span class='bis'>Vous devez ajouter au moins un établissement</span>"
        });
      } else {
        this.row_us.establishments = [];
        for (let ies = 0; ies < this.establishments.length; ies++) {
          const es = this.establishments[ies];
          this.row_us.establishments.push(es.es_id);
        }
      }
      if (err.length) {
        this.dialogErrTxt = "<span class='bis'>Vous devez remplir les champs : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error.text + " <br>";
        }
        this.dialogErr = true;
      } else {
        let response;
        if (this.row_us.us_id) {
          response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/users/" + this.row_us.us_id, this.row_us);
        } else {
          this.row_us.us_cgu_date = "0000-00-00 00:00:00";
          response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/users", this.row_us);
        }
        if (response.data.err) {
          // translate message error in french
          let errorMessage = response.data.err.message;
          switch (response.data.err.code) {
            case 1102:
              errorMessage = "Veuillez saisir le code d'affiliation.";
              break;
            case 1101:
              errorMessage = "Une erreur est survenue, veuillez contacter le support Emotivi.";
              break;
            case 1100:
              errorMessage = "Le code d'affiliation est déjà utilisé.";
              break;
            case 1035:
              errorMessage = "Le login ou l'email est déjà utilisé.";
              break;
            case 404:
              errorMessage = "Une erreur est survenue, veuillez contacter le support Emotivi.";
              break;
            case 1021:
              errorMessage = "Veuillez saisir un mot de passe.";
              break;

            default:
              break;
          }
          this.$store.dispatch("showDialogError", errorMessage);
          return;
        }
        let row_us = response.data.data;
        if (row_us.us_id == this.$store.state.user.us_id) {
          this.$store.commit("set_user_simple", row_us);
        }
        this.$router.push("/users");
        this.$emit("input", false);
        this.$emit("userSaved", row_us.us_id);
      }
    },
    cancelWin() {
      this.$router.push("/users");
      this.$emit("input", false);
      this.$emit("userCancel", false);
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteWin() {
      await this.$axios.delete(this.$config.server_url + "/extranet/2.0/users/" + this.user_id);
      this.confirmdelete = false;
      this.$router.push("/users");
      this.$emit("input", false);
      this.$emit("userSaved", this.row_us.us_id);
    },
    getUserTypesList() {
      let userTypes = this.$store.state.items_us_type;
      if (!this.$store.state.user) return [];
      if (!this.$store.state.user.us_type) return [];
      if (this.$store.state.user.us_type === "LATEAM") return userTypes;
      if (this.$store.state.user.us_type === "PARTNER")
        return userTypes.filter(userType => {
          if (userType.value === "PARTNER") return true;
        });
      return userTypes.filter(userType => {
        if (userType.value !== "LATEAM" && userType.value !== "DIRECTOR" && userType.value !== "EMOTIVI_USER" && userType.value !== "PARTNER")
          return true;
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";
.establishment {
  background-color: #daece7;
  padding: 5px 10px;
  margin-right: 10px;
}
</style>
