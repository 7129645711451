<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div>
              <h1 v-if="mc_id === 0">Création du code {{ row_mc.tc_function }} pour la macro {{ selectedMacros.ma_key }}</h1>
              <h1 v-else>Modification du code {{ row_mc.tc_function }} pour la macro {{ selectedMacros.ma_key }}</h1>
            </div>
          </div>
          <div class="modal-body">
            <m-form-text
              label="Macro sélectionné "
              type="text"
              v-model="selectedMacros.ma_key"
              :name="selectedMacros.ma_key"
              :rules="[$Validation.mandatory]"
              disabled
            ></m-form-text>
            <div class="row mt-4">
              <div class="col-md-12">
                <h6>Codes Macros</h6>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-6">
                <m-form-combobox
                  class="flex-grow-1 basis-50"
                  label="Codes Existants"
                  label-position="top"
                  :name="$Utils.randomstring('mc_id')"
                  v-model="row_mc"
                  :autoSelectOnFocus="true"
                  :store-url="$config.server_url + '/extranet/2.0/tvcodemodel/' + tt_id"
                  :store-params="{ tc_id: row_mc.tc_id }"
                  item-value="tc_id"
                  item-text="tc_function"
                  :rules="[$Validation.mandatory]"
                  :disabled="mc_id !== 0"
                  @input="loadField()"
                ></m-form-combobox>
              </div>
            </div>
            <div v-if="newMacroCode">
              <m-form-text
                label="Fonction "
                type="text"
                v-model="row_mc.tc_function"
                :name="row_mc.tc_function"
                :rules="[$Validation.mandatory]"
                disabled
              ></m-form-text>
              <m-form-text
                label="Hexcode "
                type="textarea"
                :rows="3"
                v-model="row_mc.tc_hexcode"
                :name="row_mc.tc_hexcode"
                :rules="[$Validation.mandatory]"
                disabled
              ></m-form-text>
              <m-form-text
                label="Délai en seconde(s)*"
                type="number"
                min="1"
                v-model="row_mc.mc_delay"
                :name="$Utils.randomstring('mc_delay')"
                :rules="[$Validation.mandatory]"
              ></m-form-text>
            </div>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between">
            <div>
              <button type="button" v-if="mc_id != 0" class="btn btn-danger" @click="deleteConfirmWin">Supprimer</button>
            </div>
            <div class="d-flex flex-row align-items-end">
              <button type="button" class="btn btn-secondary" @click="cancelWin">Annuler</button>
              <button type="button" class="btn btn-primary btn-mouveal ml-2" @click="saveWin">Sauvegarder</button>
            </div>
          </div>
        </div>
      </div>
      <m-message-dialog v-model="dialogErr" title="Erreur" :text="dialogErrTxt"></m-message-dialog>
      <m-confirm-dialog
        v-model="confirmdelete"
        :text="`Souhaitez-vous effacer le code ${this.row_mc.tc_function} dans la macro ${selectedMacros.ma_key}  ?`"
        title="Confirmation"
        width="500px"
        @canceled="confirmdelete = false"
        @confirmed="deleteWin()"
      ></m-confirm-dialog>
    </div>
  </transition>
</template>

<script>
export default {
  name: "TvMacroCodeWinEdit",
  components: {},
  props: {
    value: {
      default: false,
      type: Boolean
    },
    tt_id: Number,
    selectedMacros: Object,
    mc_id: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      row_mc: {
        tc_id: 0,
        tc_function: "",
        tc_hexcode: "",
        mc_delay: 1
      },
      confirmdelete: false,
      dialogErr: false,
      dialogErrTxt: "",
      avatar: null,
      macrosCode: {},
      tvCode: {},
      newMacroCode: false
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getMacroCode();
        this.loadField();
      }
    }
  },
  methods: {
    async getMacroCode() {
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/tvmacrocode/" + this.mc_id);
      if (response.data && response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }
      this.row_mc = response.data && response.data.data ? response.data.data : {};
      if (this.row_mc.tc_id) {
        this.row_mc.tc_function = this.row_mc.tc_id.tc_function || "";
        this.row_mc.tc_hexcode = this.row_mc.tc_id.tc_hexcode || "";
      }
    },
    async saveWin() {
      let err = [];
      let fieldRequired = [
        { field: "mc_delay", text: "Délai du code macro" },
        { field: "tc_function", text: "Code associé au code macro" }
      ];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.row_mc[field.field]) err.push(field);
      }
      if (err.length) {
        this.dialogErrTxt = "<span class='bis'>Vous devez remplir les champs : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error.text + " <br>";
        }
        this.dialogErr = true;
      } else {
        this.row_mc.ma_id = this.selectedMacros.ma_id;
        if (this.row_mc.mc_id) {
          await this.$axios.put(this.$config.server_url + "/extranet/2.0/tvmacrocode", this.row_mc);
        } else {
          await this.$axios.post(this.$config.server_url + "/extranet/2.0/tvmacrocode", this.row_mc);
        }
        this.$emit("winEditSaved", this.selectedMacros);
      }
    },
    cancelWin() {
      this.$emit("winEditCanceled");
    },
    loadField() {
      this.row_mc.mc_delay = this.row_mc.mc_delay || 1;
      this.newMacroCode = true;
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteWin() {
      await this.$axios.delete(this.$config.server_url + "/extranet/2.0/tvmacrocode/" + this.row_mc.mc_id);
      this.$emit("winEditDeleted", this.row_mc.tc_function, "macro code");

      this.confirmdelete = false;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";

.winedit-container-header {
  width: 100%;
  justify-content: space-between;
}
.input-avatar {
  padding: 0;
}
.modal {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.active {
  min-width: 150px;
  input {
    width: auto;
  }
}
/*************** table droits **************/
tr {
  border-bottom: 0.5px solid #ccc9c9;
}
td:first-child {
  text-align: left;
}
td,
th {
  border-right: 0.5px solid #ccc9c9;
  border-left: 0.5px solid #ccc9c9;
  padding: 5px 10px;
  text-align: center;
}
th {
  background-color: #013c62;
  color: #fff;
  text-transform: uppercase;
}
th.groupe {
  color: black;
}
.groupe {
  background-color: #b5b2b2;
}
</style>
