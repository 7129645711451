var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "w-100" },
    [
      _c("div", { attrs: { id: "fiche" } }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-row flex-wrap justify-content-between align-items-center"
          },
          [
            _c("div", { staticClass: "d-flex flex-row align-items-center" }, [
              _c("h1", [
                _vm._v(
                  " (TV) Utilisateur final N°" + _vm._s(_vm.row_en.en_id) + " "
                ),
                !_vm.row_en.de_id
                  ? _c("span", [_vm._v(" (Pas encore de TV) ")])
                  : _vm._e(),
                _c("span", { staticStyle: { color: "lightgray" } }, [
                  _vm._v("|")
                ]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("formatContactNameSimple")(
                        _vm.row_co,
                        "co_invoice_"
                      )
                    ) +
                    " "
                ),
                _c("span", { staticStyle: { color: "lightgray" } }, [
                  _vm._v("|")
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "text-red-emotivi" }, [
                  _vm._v(
                    _vm._s(_vm.row_co.es_id ? _vm.row_co.es_id.es_name : "")
                  )
                ])
              ])
            ]),
            _c(
              "div",
              { staticClass: "d-flex flex-column infComVsTech" },
              [
                _c("m-form-checkbox", {
                  staticClass: "checkbox",
                  attrs: {
                    label: "Informations commerciales",
                    name: _vm.$Utils.randomstring("filterInfoCom")
                  },
                  model: {
                    value: _vm.filterInfoCom,
                    callback: function($$v) {
                      _vm.filterInfoCom = $$v
                    },
                    expression: "filterInfoCom"
                  }
                }),
                _c("m-form-checkbox", {
                  attrs: {
                    label: "Informations techniques",
                    name: _vm.$Utils.randomstring("filterInfoTech")
                  },
                  model: {
                    value: _vm.filterInfoTech,
                    callback: function($$v) {
                      _vm.filterInfoTech = $$v
                    },
                    expression: "filterInfoTech"
                  }
                })
              ],
              1
            ),
            _vm.row_en.newMessage && _vm.row_en.newMessage.length
              ? _c("div", [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "margin-left": "20px",
                        "background-color": "green",
                        color: "white",
                        "font-weight": "bold",
                        "border-radius": "50%",
                        width: "25px",
                        display: "inline-block",
                        "text-align": "center",
                        height: "25px"
                      }
                    },
                    [_vm._v(_vm._s(_vm.row_en.newMessage.length))]
                  ),
                  _vm._v(
                    " Vous avez reçu " +
                      _vm._s(_vm.row_en.newMessage.length) +
                      " nouveau(x) messages(s) aujourd'hui. "
                  )
                ])
              : _vm._e(),
            _c(
              "button",
              { staticClass: "btn btn-sm btn-primary" },
              [
                _c(
                  "router-link",
                  { staticClass: "color-white", attrs: { to: "/tvs" } },
                  [_vm._v(" Retour liste")]
                )
              ],
              1
            )
          ]
        ),
        _vm.filterInfoCom
          ? _c("div", [
              _vm._m(0),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-9" }, [
                  _vm.$store.getters.checkUserAccess(_vm.$router, "/contacts")
                    ? _c("div", { staticClass: "card" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row flex-wrap justify-content-between"
                          },
                          [
                            _c("h6", [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.$store.state.user.us_type ===
                                          "LATEAM" ||
                                        _vm.$store.state.user.us_type ===
                                          "EMOTIVI_USER",
                                      expression:
                                        "$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
                                    }
                                  ],
                                  staticClass: "number-title"
                                },
                                [_vm._v("1")]
                              ),
                              _vm._v("Contact (compte payeur) ")
                            ]),
                            _vm.$store.getters.checkUserAccess(
                              _vm.$router,
                              "/contacts/:co_id/edit"
                            )
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    on: { click: _vm.editContact }
                                  },
                                  [_vm._v(" Modifier ")]
                                )
                              : _vm._e()
                          ]
                        ),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("h5", [_vm._v("Coordonnées")]),
                            _c("div", [
                              _vm._v(" Téléphone mobile : "),
                              _c(
                                "span",
                                { staticClass: "color-emotivi-blue" },
                                [_vm._v(_vm._s(_vm.row_co.co_invoice_tel))]
                              )
                            ]),
                            _c("div", [
                              _vm._v(" Identifiant web : "),
                              _c(
                                "span",
                                { staticClass: "color-emotivi-blue" },
                                [_vm._v(_vm._s(_vm.row_co.co_login))]
                              )
                            ]),
                            _c("div", [
                              _vm._v(" Email : "),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "mailto:" + _vm.row_co.co_invoice_email
                                  }
                                },
                                [_vm._v(_vm._s(_vm.row_co.co_invoice_email))]
                              )
                            ]),
                            _c("div", { staticClass: "mt-3" }, [
                              _vm._v(
                                "Établissement : " +
                                  _vm._s(
                                    _vm.row_co.es_id
                                      ? _vm.row_co.es_id.es_name
                                      : ""
                                  )
                              )
                            ]),
                            _vm.row_co.afco_id && _vm.row_co.afco_id.af_id
                              ? _c("div", [
                                  _vm._v(
                                    "Code d'affiliation : " +
                                      _vm._s(_vm.row_co.afco_id.af_id.af_code)
                                  )
                                ])
                              : _vm._e(),
                            _vm.row_co.afco_id && _vm.row_co.afco_id.af_id
                              ? _c("div", [
                                  _vm._v(
                                    "Nom du revendeur : " +
                                      _vm._s(_vm.row_co.afco_id.af_id.af_name)
                                  )
                                ])
                              : _vm._e(),
                            _c("div", [
                              _vm._v(
                                "Commentaire : " +
                                  _vm._s(
                                    _vm.row_co.co_comment
                                      ? _vm.row_co.co_comment
                                      : ""
                                  )
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("h5", [_vm._v("Adresse de facturation")]),
                            _c("div", [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push(
                                        "/contacts/" +
                                          _vm.row_co.co_id +
                                          "/details"
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatContactNameSimple")(
                                        _vm.row_co,
                                        "co_invoice_"
                                      )
                                    )
                                  )
                                ]
                              )
                            ]),
                            _c("div", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "https://maps.google.fr?q=" +
                                      _vm.co_invoice_address,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.row_co.co_invoice_address1)
                                  ),
                                  _c("br"),
                                  _vm.row_co.co_invoice_address2
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.row_co.co_invoice_address2)
                                        ),
                                        _c("br")
                                      ])
                                    : _vm._e(),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.row_co.co_invoice_zip) +
                                      " " +
                                      _vm._s(_vm.row_co.co_invoice_city)
                                  ),
                                  _c("br")
                                ]
                              )
                            ]),
                            _vm.$store.getters.checkUserAccess(
                              _vm.$router,
                              "/invoices"
                            )
                              ? _c("h5", { staticClass: "mt-2" }, [
                                  _vm._v("Prélévement bancaire")
                                ])
                              : _vm._e(),
                            _vm.$store.getters.checkUserAccess(
                              _vm.$router,
                              "/invoices"
                            ) && _vm.row_co.co_rib
                              ? _c("div", [
                                  _c("div", [
                                    _vm._v(
                                      "Titulaire : " +
                                        _vm._s(_vm.row_co.co_rib_name)
                                    )
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      "IBAN : " + _vm._s(_vm.row_co.co_rib_iban)
                                    )
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      "BIC : " + _vm._s(_vm.row_co.co_rib_bic)
                                    )
                                  ])
                                ])
                              : _c("div", [_vm._v("Aucun")])
                          ]),
                          _c("div", { staticClass: "col-md-4" }, [
                            _c("h5", [_vm._v("Adresse de livraison")]),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.row_co.co_shipping_society_name,
                                    expression:
                                      "row_co.co_shipping_society_name"
                                  }
                                ]
                              },
                              [
                                _vm._v(
                                  "Nom de la société : " +
                                    _vm._s(_vm.row_co.co_shipping_society_name)
                                )
                              ]
                            ),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatContactNameSimple")(
                                    _vm.row_co,
                                    "co_shipping_"
                                  )
                                )
                              )
                            ]),
                            _c("div", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "https://maps.google.fr?q=" +
                                      _vm.co_shipping_address,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.row_co.co_shipping_address1)
                                  ),
                                  _c("br"),
                                  _vm.row_co.co_shipping_address2
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.row_co.co_shipping_address2
                                          )
                                        ),
                                        _c("br")
                                      ])
                                    : _vm._e(),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.row_co.co_shipping_zip) +
                                      " " +
                                      _vm._s(_vm.row_co.co_shipping_city)
                                  ),
                                  _c("br")
                                ]
                              )
                            ])
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm.$store.getters.checkUserAccess(
                    _vm.$router,
                    "/contacts/:co_id/order"
                  )
                    ? _c("div", { staticClass: "card" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row flex-wrap justify-content-between"
                          },
                          [
                            _c("h6", [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.$store.state.user.us_type ===
                                          "LATEAM" ||
                                        _vm.$store.state.user.us_type ===
                                          "EMOTIVI_USER",
                                      expression:
                                        "$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
                                    }
                                  ],
                                  staticClass: "number-title"
                                },
                                [_vm._v("3")]
                              ),
                              _vm._v("Paiements ")
                            ]),
                            _vm.loanedKit
                              ? _c("p", { staticStyle: { color: "#199aaa" } }, [
                                  _c("b", [
                                    _vm._v("Le kit est actuellement en prêt.")
                                  ])
                                ])
                              : _vm._e(),
                            _c("div", {
                              staticClass: "d-flex flex-row flex-wrap "
                            })
                          ]
                        ),
                        _c(
                          "div",
                          [
                            _c("h5", {}, [
                              _vm._v(
                                " Les paiements (abonnement mensuel en CB) "
                              ),
                              _vm.loadingStripe
                                ? _c(
                                    "span",
                                    [
                                      _c("icon", {
                                        staticClass: "mr-2 fa-spin",
                                        attrs: {
                                          width: "30",
                                          height: "30",
                                          name: "sync"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]),
                            _c("m-list-simple", {
                              ref: "commandelist",
                              attrs: {
                                dynamic: true,
                                items: _vm.stripeCharges,
                                "item-value": "ac_id",
                                total: _vm.stripeCharges.length
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "ths",
                                    fn: function(ref) {
                                      return [
                                        _c("th", { attrs: { width: "180" } }, [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Date")]
                                          )
                                        ]),
                                        _c("th", { attrs: { width: "100" } }, [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Montant")]
                                          )
                                        ]),
                                        _c("th", { attrs: { width: "160" } }, [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Statut")]
                                          )
                                        ]),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Description")]
                                          )
                                        ]),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Id - lien vers stripe")]
                                          )
                                        ]),
                                        _c("th", [
                                          _c("span", { staticClass: "pointer" })
                                        ]),
                                        _c("th", [
                                          _c("span", { staticClass: "pointer" })
                                        ]),
                                        _c("th", [
                                          _c("span", { staticClass: "pointer" })
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "tds",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$moment
                                                .unix(item.created)
                                                .format("YYYY-MM-DD HH:mm:ss")
                                            )
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatPrice")(
                                                item.amount / 100
                                              )
                                            )
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " + _vm._s(item.status) + " "
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " + _vm._s(item.description) + " "
                                          )
                                        ]),
                                        _c("td", [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: item.receipt_url,
                                                target: "_blank"
                                              }
                                            },
                                            [_vm._v(_vm._s(item.id))]
                                          )
                                        ]),
                                        _c(
                                          "td",
                                          [
                                            item.payment_intent &&
                                            item.amount_refunded != 10000
                                              ? _c("icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    width: "22",
                                                    height: "22",
                                                    name: "trash-alt",
                                                    color: "black"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.newCancelCharge(
                                                        item.payment_intent
                                                      )
                                                    }
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c("td", [
                                          item.amount_refunded
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "badge badge-danger"
                                                },
                                                [
                                                  _vm._v(
                                                    "REMBOURSÉ : " +
                                                      _vm._s(
                                                        _vm._f("formatPrice")(
                                                          item.amount_refunded /
                                                            100
                                                        )
                                                      )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]),
                                        _c("td", [
                                          item.end_user &&
                                          Array.isArray(item.end_user) &&
                                          item.end_user.length &&
                                          item.end_user[0].en_id ===
                                            _vm.row_en.en_id
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#91295a",
                                                    "font-weight": "bold"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "Caution liée à cette TV"
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2921915065
                              )
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("h5", {}, [
                              _vm._v(" Les abonnements "),
                              _vm.loadingStripe
                                ? _c(
                                    "span",
                                    [
                                      _c("icon", {
                                        staticClass: "mr-2 fa-spin",
                                        attrs: {
                                          width: "30",
                                          height: "30",
                                          name: "sync"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]),
                            _c("m-list-simple", {
                              ref: "commandelist",
                              attrs: {
                                dynamic: true,
                                items: _vm.stripeSubscriptions,
                                "item-value": "ac_id",
                                total: _vm.stripeSubscriptions.length
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "ths",
                                    fn: function(ref) {
                                      return [
                                        _c(
                                          "th",
                                          { staticStyle: { width: "75px" } },
                                          [_vm._v("TV")]
                                        ),
                                        _c(
                                          "th",
                                          { staticStyle: { width: "120px" } },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("Montant")]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "th",
                                          { staticStyle: { width: "120px" } },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("Statut")]
                                            )
                                          ]
                                        ),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Description")]
                                          )
                                        ]),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Id - lien vers stripe")]
                                          )
                                        ]),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Début abonnement")]
                                          )
                                        ]),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Fin abonnement")]
                                          )
                                        ]),
                                        _c("th", [
                                          _c("span", { staticClass: "pointer" })
                                        ]),
                                        _c("th", [
                                          _c("span", { staticClass: "pointer" })
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "tds",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("td", [
                                          item.end_user &&
                                          Array.isArray(item.end_user) &&
                                          item.end_user.length
                                            ? _c(
                                                "a",
                                                {
                                                  staticStyle: {
                                                    color: "#91295a",
                                                    "font-weight": "bold"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.$router.push(
                                                        "/tvs/" +
                                                          item.end_user[0].en_id
                                                      ) && _vm.$router.go()
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "TV " +
                                                      _vm._s(
                                                        item.end_user[0].en_id
                                                      )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatPrice")(
                                                item.plan.amount / 100
                                              )
                                            )
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.su_state_label) +
                                              " "
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.plan.of_name) +
                                              " "
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(" " + _vm._s(item.id) + " ")
                                        ]),
                                        _c("td", [
                                          item.su_startdate
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm
                                                      .$moment(
                                                        item.su_startdate
                                                      )
                                                      .format(
                                                        "DD/MM/YYYY HH:mm:ss"
                                                      )
                                                  )
                                                )
                                              ])
                                            : _vm._e()
                                        ]),
                                        _c("td", [
                                          item.su_enddate
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm
                                                      .$moment(item.su_enddate)
                                                      .format(
                                                        "DD/MM/YYYY HH:mm:ss"
                                                      )
                                                  )
                                                )
                                              ])
                                            : _vm._e()
                                        ]),
                                        _c("td"),
                                        _c("td", [
                                          item.end_user &&
                                          Array.isArray(item.end_user) &&
                                          item.end_user.length &&
                                          item.end_user[0].en_id ===
                                            _vm.row_en.en_id
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#91295a",
                                                    "font-weight": "bold"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "Abonnement lié à cette TV"
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2301042596
                              )
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("h5", {}, [
                              _vm._v(" Les factures "),
                              _vm.loadingStripe
                                ? _c(
                                    "span",
                                    [
                                      _c("icon", {
                                        staticClass: "mr-2 fa-spin",
                                        attrs: {
                                          width: "30",
                                          height: "30",
                                          name: "sync"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]),
                            _c("m-list-simple", {
                              ref: "commandelist",
                              attrs: {
                                dynamic: true,
                                items: _vm.orders,
                                "item-value": "ac_id",
                                total: _vm.stripeCharges.length
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "ths",
                                    fn: function(ref) {
                                      return [
                                        _c(
                                          "th",
                                          { attrs: { width: "200px" } },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("nom")]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "th",
                                          { attrs: { width: "200px" } },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("Statut")]
                                            )
                                          ]
                                        ),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Date")]
                                          )
                                        ]),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Total TTC")]
                                          )
                                        ]),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Actions")]
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "tds",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("td", [
                                          _vm._v(_vm._s(item.or_name))
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " + _vm._s(item.or_status) + " "
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("formatDateTime")(
                                                  item.or_date
                                                )
                                              ) +
                                              " "
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(item.or_pricettc / 100) +
                                              " €"
                                          )
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "d-flex flex-row" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: "/invoices/" + item.or_id
                                                }
                                              },
                                              [
                                                _c("icon", {
                                                  attrs: {
                                                    color: "black",
                                                    name: "edit"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "ml-2",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.printOrder(
                                                      item,
                                                      $event
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("icon", {
                                                  attrs: { name: "print" }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2932103652
                              )
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("h5", {}, [
                              _vm._v(" Les modes de paiement "),
                              _vm.loadingStripe
                                ? _c(
                                    "span",
                                    [
                                      _c("icon", {
                                        staticClass: "mr-2 fa-spin",
                                        attrs: {
                                          width: "30",
                                          height: "30",
                                          name: "sync"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]),
                            _vm.payments.length
                              ? _c("m-list-simple", {
                                  ref: "commandelist",
                                  attrs: {
                                    dynamic: true,
                                    items: _vm.payments,
                                    "item-value": "ac_id",
                                    total: _vm.payments.length
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "ths",
                                        fn: function(ref) {
                                          return [
                                            _c(
                                              "th",
                                              { attrs: { width: "200px" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "pointer" },
                                                  [_vm._v("Nom")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { width: "200px" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "pointer" },
                                                  [_vm._v("Méthode")]
                                                )
                                              ]
                                            ),
                                            _c("th", [
                                              _c(
                                                "span",
                                                { staticClass: "pointer" },
                                                [_vm._v("Statut")]
                                              )
                                            ]),
                                            _c("th", [
                                              _c(
                                                "span",
                                                { staticClass: "pointer" },
                                                [_vm._v("Type")]
                                              )
                                            ]),
                                            _c("th", [
                                              _c(
                                                "span",
                                                { staticClass: "pointer" },
                                                [_vm._v("Actions")]
                                              )
                                            ])
                                          ]
                                        }
                                      },
                                      {
                                        key: "tds",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  item.pm_name ||
                                                    item.pm_rib_name
                                                )
                                              )
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.pm_method) +
                                                  " "
                                              )
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.pm_active
                                                      ? "Utilisée"
                                                      : ""
                                                  ) +
                                                  " "
                                              )
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.subscription
                                                      ? "Abonnement N° " +
                                                          item.subscription
                                                      : item.caution
                                                      ? "Caution"
                                                      : ""
                                                  ) +
                                                  " "
                                              )
                                            ]),
                                            _c("td", {}, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "ml-2",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.displayPaymentMethodDetails(
                                                        item,
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("icon", {
                                                    attrs: {
                                                      name: "info-circle"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2443851193
                                  )
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  this.tickets.length
                    ? _c("div", { staticClass: "card" }, [
                        _c("h6", [
                          _c("span", { staticClass: "number-title" }, [
                            _vm._v("6")
                          ]),
                          _vm._v("Tickets Freshdesk "),
                          _vm.loadingTickets
                            ? _c(
                                "span",
                                [
                                  _c("icon", {
                                    staticClass: "mr-2 fa-spin",
                                    attrs: {
                                      width: "30",
                                      height: "30",
                                      name: "sync"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "row mt-2" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-12" },
                            [
                              _c("m-list-simple", {
                                ref: "commandelist",
                                attrs: {
                                  dynamic: true,
                                  items: _vm.tickets,
                                  "item-value": "id",
                                  total: 1
                                },
                                on: { itemclick: _vm.goFreshdeskTicket },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "ths",
                                      fn: function(ref) {
                                        return [
                                          _c("th", [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("Date")]
                                            )
                                          ]),
                                          _c("th", [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("Sujet")]
                                            )
                                          ]),
                                          _c("th", {}, [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("État")]
                                            )
                                          ])
                                        ]
                                      }
                                    },
                                    {
                                      key: "tds",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDateTime")(
                                                  item.created_at
                                                )
                                              )
                                            )
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " + _vm._s(item.subject) + " "
                                            )
                                          ]),
                                          _c("td", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "ticket-status",
                                                class: "status-" + item.status
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f(
                                                        "formatStatusTicketsFreshdesk"
                                                      )(item.status)
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  975253670
                                )
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c("div", { staticClass: "card" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row flex-wrap justify-content-between"
                      },
                      [
                        _c("h6", [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.$store.state.user.us_type ===
                                      "LATEAM" ||
                                    _vm.$store.state.user.us_type ===
                                      "EMOTIVI_USER",
                                  expression:
                                    "$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
                                }
                              ],
                              staticClass: "number-title"
                            },
                            [_vm._v("2")]
                          ),
                          _vm._v("Utilisateur final ")
                        ]),
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mb-2",
                              on: { click: _vm.editEnduser }
                            },
                            [_vm._v("Modifier")]
                          )
                        ])
                      ]
                    ),
                    _c("div", [
                      _vm.row_en.en_linkello
                        ? _c("div", [
                            _c("img", {
                              staticClass: "logo-linkello",
                              attrs: { src: "/images/linkello.png" }
                            }),
                            _c("span", { staticClass: "linkello" }, [
                              _vm._v("Associé à Linkello")
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(
                        " Nom complet : " + _vm._s(_vm.row_en.en_fullname) + " "
                      ),
                      _c("hr", { staticClass: "hr-mini" }),
                      _c("div", { staticClass: "float-right" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-warning btn-sm mt-2",
                            on: { click: _vm.sendInvitationEmail }
                          },
                          [_vm._v("Renvoyer")]
                        )
                      ]),
                      _vm._v(" Ident. webapp : "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(
                                _vm.row_en.en_webapp_login
                              )
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.row_en.en_webapp_login))]
                      ),
                      _c("br"),
                      _vm._v(" Pass webapp :"),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(
                                _vm.row_en.en_webapp_password
                              )
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.row_en.en_webapp_password))]
                      ),
                      _c("hr", { staticClass: "hr-mini" }),
                      _vm._v(" Code administrateur : "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.row_en.en_admincode))]
                      ),
                      _c("hr", { staticClass: "hr-mini" }),
                      _vm._v(
                        " Chambre : " +
                          _vm._s(_vm.row_en.en_room ? _vm.row_en.en_room : "")
                      ),
                      _c("br"),
                      _vm._v(" Statut TV : "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$Utils
                                .getTextFromState(
                                  _vm.$store.state.items_en_state_box,
                                  _vm.row_en.en_state_box
                                )
                                .replace(/Box/g, "TV")
                            )
                          )
                        ]
                      ),
                      _c("br"),
                      _vm._v(" Statut caution: "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$Utils.getTextFromState(
                                _vm.$store.state.items_en_state_caution,
                                _vm.row_en.en_state_caution
                              )
                            )
                          )
                        ]
                      ),
                      _c("br"),
                      _vm._v(" Statut abonnement: "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$Utils.getTextFromState(
                                _vm.$store.state.items_en_state_subscription,
                                _vm.row_en.en_state_subscription
                              )
                            )
                          )
                        ]
                      ),
                      _c("br"),
                      _vm._v(" Statut colis: "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$Utils
                                .getTextFromState(
                                  _vm.$store.state.items_en_state_parcel,
                                  _vm.row_en.en_state_parcel
                                )
                                .replace(/Box/g, "TV")
                            )
                          )
                        ]
                      ),
                      _c("br"),
                      _vm._v(" Statut support: "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$Utils.getTextFromState(
                                _vm.$store.state.items_en_state_support,
                                _vm.row_en.en_state_support
                              )
                            )
                          )
                        ]
                      ),
                      _c("br"),
                      _c("hr", { staticClass: "hr-mini mb-2" }),
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger mb-2",
                            on: { click: _vm.resetTv }
                          },
                          [_vm._v("Réinitialiser la TV")]
                        )
                      ])
                    ])
                  ]),
                  _vm.$store.getters.checkUserAccess(
                    _vm.$router,
                    "/parcels/:en_id"
                  )
                    ? _c(
                        "div",
                        { staticClass: "card" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row flex-wrap justify-content-between"
                            },
                            [
                              _c("h6", [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.$store.state.user.us_type ===
                                            "LATEAM" ||
                                          _vm.$store.state.user.us_type ===
                                            "EMOTIVI_USER",
                                        expression:
                                          "$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
                                      }
                                    ],
                                    staticClass: "number-title"
                                  },
                                  [_vm._v("4")]
                                ),
                                _vm._v("Envois colis ")
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  on: { click: _vm.newParcel }
                                },
                                [_vm._v("Ajouter")]
                              )
                            ]
                          ),
                          _vm._l(_vm.parcels, function(parcel) {
                            return _c(
                              "div",
                              {
                                key: parcel.pa_id,
                                staticClass:
                                  "d-flex flex-row flex-wrap justify-content-between mt-3"
                              },
                              [
                                _c("div", [
                                  _c("div", [
                                    _vm._v(" Type de connexion : "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "color-emotivi-blue ",
                                        class: _vm.getBold(
                                          _vm.row_co.co_box_connection_type
                                        ),
                                        style: {
                                          color: _vm.getColor(
                                            _vm.row_co.co_box_connection_type
                                          )
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.row_co.co_box_connection_type
                                          )
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("div", [
                                    _vm._v(" Société : "),
                                    _c(
                                      "span",
                                      { staticClass: "color-emotivi-blue" },
                                      [_vm._v(_vm._s(parcel.pa_company))]
                                    )
                                  ]),
                                  _c("div", [
                                    _vm._v(" N° suivi : "),
                                    _c(
                                      "span",
                                      { staticClass: "color-emotivi-blue" },
                                      [_vm._v(_vm._s(parcel.pa_trackingcode))]
                                    )
                                  ]),
                                  _c("div", [
                                    _vm._v(" Date : "),
                                    _c(
                                      "span",
                                      { staticClass: "color-emotivi-blue" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .$moment(parcel.updatedAt)
                                              .format("DD/MM/YYYY HH[H]mm")
                                          )
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("div", [
                                    _vm._v(" User : "),
                                    _c(
                                      "span",
                                      { staticClass: "color-emotivi-blue" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            parcel.us_id
                                              ? _vm.$options.filters.formatContactNameSimple(
                                                  parcel.us_id,
                                                  "us_"
                                                )
                                              : ""
                                          )
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("div", [
                                    _vm._v(" Etat :"),
                                    _c(
                                      "span",
                                      { staticClass: "color-emotivi-blue" },
                                      [_vm._v(" " + _vm._s(parcel.pa_state))]
                                    )
                                  ]),
                                  _c("div", [
                                    _vm._v(" Tarif :"),
                                    _c(
                                      "span",
                                      { staticClass: "color-emotivi-blue" },
                                      [_vm._v(" " + _vm._s(parcel.pa_price))]
                                    )
                                  ])
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column align-items-end"
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-primary btn-sm w-100",
                                        on: {
                                          click: function($event) {
                                            return _vm.editParcel(parcel)
                                          }
                                        }
                                      },
                                      [_vm._v("Modifier")]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-warning my-1 btn-sm w-100",
                                        attrs: {
                                          disabled:
                                            parcel.pa_state !== "SENT" &&
                                            parcel.pa_state !== "PREPARING"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.mailParcel(parcel)
                                          }
                                        }
                                      },
                                      [_vm._v(" Envo. mail ")]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "w-100",
                                        attrs: {
                                          href:
                                            "https://www.laposte.fr/outils/suivre-vos-envois?code=" +
                                            parcel.pa_trackingcode,
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-warning btn-sm w-100"
                                          },
                                          [_vm._v("Suivre")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.$store.state.user.us_type === "LATEAM" ||
                            _vm.$store.state.user.us_type === "EMOTIVI_USER",
                          expression:
                            "$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
                        }
                      ],
                      staticClass: "card"
                    },
                    [
                      _c("h6", [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.$store.state.user.us_type === "LATEAM" ||
                                  _vm.$store.state.user.us_type ===
                                    "EMOTIVI_USER",
                                expression:
                                  "$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
                              }
                            ],
                            staticClass: "number-title"
                          },
                          [_vm._v("5")]
                        ),
                        _vm._v("Résumé ")
                      ]),
                      _c("div", { staticClass: "row mt-2" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("div", [
                            _vm._v(" Inscription le : "),
                            _c(
                              "span",
                              { staticClass: "color-emotivi-blue ml-2" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.row_en.infos.actionsFirstTime &&
                                        _vm.row_en.infos.actionsFirstTime
                                          .inscription
                                        ? _vm
                                            .$moment(
                                              _vm.row_en.infos.actionsFirstTime
                                                .inscription
                                            )
                                            .format("DD/MM/YYYY")
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _c("div", [
                            _vm._v(" Première install. le : "),
                            _c(
                              "span",
                              { staticClass: "color-emotivi-blue ml-2" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.row_en.infos.actionsFirstTime &&
                                        _vm.row_en.infos.actionsFirstTime
                                          .install
                                        ? _vm
                                            .$moment(
                                              _vm.row_en.infos.actionsFirstTime
                                                .install
                                            )
                                            .format("DD/MM/YYYY")
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _c("div", [
                            _vm._v(" Envoi colis le : "),
                            _c(
                              "span",
                              { staticClass: "color-emotivi-blue ml-2" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.row_en.infos.actionsFirstTime &&
                                        _vm.row_en.infos.actionsFirstTime
                                          .parcel_sended
                                        ? _vm
                                            .$moment(
                                              _vm.row_en.infos.actionsFirstTime
                                                .parcel_sended
                                            )
                                            .format("DD/MM/YYYY")
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _c("div", [
                            _vm._v(" Dernière connexion le : "),
                            _c(
                              "span",
                              { staticClass: "color-emotivi-blue ml-2" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.row_en.infos.actionsFirstTime &&
                                        _vm.row_en.infos.actionsFirstTime
                                          .connection
                                        ? _vm
                                            .$moment(
                                              _vm.row_en.infos.actionsFirstTime
                                                .connection
                                            )
                                            .format("DD/MM/YYYY")
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _c("div", [
                            _vm._v(" Réception colis le : "),
                            _c(
                              "span",
                              { staticClass: "color-emotivi-blue ml-2" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.row_en.infos.actionsFirstTime &&
                                        _vm.row_en.infos.actionsFirstTime
                                          .parcel_received
                                        ? _vm
                                            .$moment(
                                              _vm.row_en.infos.actionsFirstTime
                                                .parcel_received
                                            )
                                            .format("DD/MM/YYYY")
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _c("div", [
                            _vm._v(" Premier appel vidéo le : "),
                            _c(
                              "span",
                              { staticClass: "color-emotivi-blue ml-2" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.row_en.infos.actionsFirstTime &&
                                        _vm.row_en.infos.actionsFirstTime
                                          .videocall_firstSuccess
                                        ? _vm
                                            .$moment(
                                              _vm.row_en.infos.actionsFirstTime
                                                .videocall_firstSuccess
                                            )
                                            .format("DD/MM/YYYY")
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _c("div", [
                            _vm._v(" Premier appel audio le : "),
                            _c(
                              "span",
                              { staticClass: "color-emotivi-blue ml-2" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.row_en.infos.actionsFirstTime &&
                                        _vm.row_en.infos.actionsFirstTime
                                          .audiocall_firstSuccess
                                        ? _vm
                                            .$moment(
                                              _vm.row_en.infos.actionsFirstTime
                                                .audiocall_firstSuccess
                                            )
                                            .format("DD/MM/YYYY")
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _c("div", [
                            _vm._v(" Dernier appel le : "),
                            _c(
                              "span",
                              { staticClass: "color-emotivi-blue ml-2" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.row_en.infos.actionsFirstTime &&
                                        _vm.row_en.infos.actionsFirstTime
                                          .lastSuccessCall
                                        ? _vm
                                            .$moment(
                                              _vm.row_en.infos.actionsFirstTime
                                                .lastSuccessCall
                                            )
                                            .format("DD/MM/YYYY")
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _c("div", [
                            _vm._v(" VIDÉO/OK/VOIX/OK/MESSAGE "),
                            _c(
                              "span",
                              { staticClass: "color-emotivi-blue ml-2" },
                              _vm._l(_vm.row_en.infos.nbCalls, function(
                                number,
                                index
                              ) {
                                return _c("span", { key: index }, [
                                  _vm._v(_vm._s(number) + " "),
                                  index < _vm.row_en.infos.nbCalls.length - 1
                                    ? _c("span", [_vm._v("/ ")])
                                    : _vm._e()
                                ])
                              }),
                              0
                            )
                          ])
                        ])
                      ])
                    ]
                  )
                ])
              ])
            ])
          : _vm._e(),
        _vm.filterInfoTech
          ? _c("div", [
              _vm._m(1),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-9" }, [
                  _c("div", { staticClass: "card" }, [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex flex-row justify-content-between"
                      },
                      [
                        _c("h6", [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.$store.state.user.us_type ===
                                      "LATEAM" ||
                                    _vm.$store.state.user.us_type ===
                                      "EMOTIVI_USER",
                                  expression:
                                    "$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
                                }
                              ],
                              staticClass: "number-title"
                            },
                            [_vm._v("1")]
                          ),
                          _vm._v("Informations techniques ")
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-lg-3 d-flex flex-column" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mt-2",
                              attrs: { disabled: !_vm.row_en.de_id },
                              on: { click: _vm.editTv }
                            },
                            [_vm._v("Options")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mt-2",
                              attrs: { disabled: !_vm.row_en.de_id },
                              on: { click: _vm.editTv }
                            },
                            [_vm._v("Modifier plage horaire")]
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "row my-3" }, [
                      _c("div", { staticClass: "col-md-6" }, [
                        _vm.row_de.de_callhours
                          ? _c("div", { staticClass: "bis uppercase mr-2" }, [
                              _vm._v("Plages horaire :")
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "d-flex flex-row flex-wrap mb-2" },
                          _vm._l(_vm.row_de.de_callhours, function(
                            plage,
                            index
                          ) {
                            return _c("div", { key: index }, [
                              plage.start
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          plage.start.split(":").join("H")
                                        ) +
                                        "-" +
                                        _vm._s(plage.end.split(":").join("H"))
                                    ),
                                    index < _vm.row_de.de_callhours.length - 1
                                      ? _c("span", { staticClass: "mx-1" }, [
                                          _vm._v(" / ")
                                        ])
                                      : _vm._e()
                                  ])
                                : _vm._e()
                            ])
                          }),
                          0
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-warning",
                            attrs: { disabled: !_vm.row_en.de_id },
                            on: {
                              click: function($event) {
                                _vm.showDetailsTv = !_vm.showDetailsTv
                              }
                            }
                          },
                          [
                            !_vm.showDetailsTv
                              ? _c("span", [_vm._v("Afficher")])
                              : _vm._e(),
                            _vm.showDetailsTv
                              ? _c("span", [_vm._v("Masquer")])
                              : _vm._e(),
                            _vm._v(" les détails de la TV ")
                          ]
                        )
                      ])
                    ]),
                    _vm.showDetailsTv
                      ? _c("div", { staticClass: "row mt-4" }, [
                          _vm.row_de
                            ? _c(
                                "div",
                                { staticClass: "col-md-12 d-flex flex-column" },
                                _vm._l(_vm.tabsInfos, function(tab, indexTab) {
                                  return _c(
                                    "div",
                                    { key: indexTab, staticClass: "mb-3" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "bis uppercase" },
                                        [_vm._v(_vm._s(tab))]
                                      ),
                                      _vm._l(_vm.allinfos(tab), function(info) {
                                        return _c(
                                          "div",
                                          {
                                            key: info.key,
                                            staticClass: "row tr-tvdetail"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "col-md-3",
                                                attrs: { width: "200px" }
                                              },
                                              [_vm._v(_vm._s(info.key))]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-md-9 color-emotivi-blue"
                                              },
                                              [
                                                _c("pre", [
                                                  _c("code", [
                                                    _vm._v(_vm._s(info.val))
                                                  ])
                                                ])
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ])
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    { staticClass: "card" },
                    [
                      _c("div", { staticClass: "uppercase bis mb-3" }, [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.$store.state.user.us_type === "LATEAM" ||
                                  _vm.$store.state.user.us_type ===
                                    "EMOTIVI_USER",
                                expression:
                                  "$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
                              }
                            ],
                            staticClass: "number-title"
                          },
                          [_vm._v("4")]
                        ),
                        _vm._v("Messages ")
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-warning mt-1 mr-2",
                              attrs: { disabled: !_vm.row_en.de_id },
                              on: {
                                click: function($event) {
                                  _vm.showMessages = !_vm.showMessages
                                }
                              }
                            },
                            [
                              !_vm.showMessages
                                ? _c("span", [_vm._v("Afficher")])
                                : _vm._e(),
                              _vm.showMessages
                                ? _c("span", [_vm._v("Masquer")])
                                : _vm._e(),
                              _vm._v(" les messages ")
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mt-1 mr-2",
                              attrs: { disabled: !_vm.row_en.de_id },
                              on: {
                                click: function($event) {
                                  _vm.addMessage = !_vm.addMessage
                                }
                              }
                            },
                            [_vm._v("Envoyer un message")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mt-1 mr-2",
                              attrs: { disabled: !_vm.row_en.de_id },
                              on: {
                                click: function($event) {
                                  _vm.scheduleMessage = !_vm.scheduleMessage
                                }
                              }
                            },
                            [_vm._v(" Planifier un message ")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mt-1 mr-2",
                              attrs: { disabled: !_vm.row_en.de_id },
                              on: { click: _vm.videoCall }
                            },
                            [_vm._v("Démarrer un appel vidéo")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mt-1 mr-2",
                              attrs: { disabled: !_vm.row_en.de_id },
                              on: { click: _vm.audioCall }
                            },
                            [_vm._v("Démarrer un appel audio")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger mt-1 mr-2",
                              attrs: { disabled: !_vm.row_en.de_id },
                              on: { click: _vm.deleteAllMessages }
                            },
                            [_vm._v("Effacer les messages")]
                          )
                        ])
                      ]),
                      _vm.addMessage
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "row d-flex flex-row align-items-center"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-md-12" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-column" },
                                    [
                                      _c("m-form-checkbox", {
                                        staticClass: "mt-2",
                                        attrs: {
                                          label: "Message de type ADMIN",
                                          name: _vm.$Utils.randomstring(
                                            "isAdmin"
                                          )
                                        },
                                        model: {
                                          value: _vm.isAdmin,
                                          callback: function($$v) {
                                            _vm.isAdmin = $$v
                                          },
                                          expression: "isAdmin"
                                        }
                                      }),
                                      _vm.isAdmin
                                        ? _c("m-form-checkbox", {
                                            attrs: {
                                              label: "Confirmation de lecture",
                                              name: _vm.$Utils.randomstring(
                                                "acknowledgment"
                                              )
                                            },
                                            model: {
                                              value: _vm.acknowledgment,
                                              callback: function($$v) {
                                                _vm.acknowledgment = $$v
                                              },
                                              expression: "acknowledgment"
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.isAdmin
                                        ? _c("m-form-minutes", {
                                            staticClass: "mx-1",
                                            staticStyle: {
                                              "overflow-wrap": "normal"
                                            },
                                            attrs: {
                                              label:
                                                "Durée d'affichage (minutes:secondes)",
                                              name: _vm.$Utils.randomstring(
                                                "duration"
                                              )
                                            },
                                            model: {
                                              value: _vm.duration,
                                              callback: function($$v) {
                                                _vm.duration = $$v
                                              },
                                              expression: "duration"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c("m-form-text", {
                                    attrs: {
                                      label: "Message",
                                      type: "textarea",
                                      name: "message"
                                    },
                                    model: {
                                      value: _vm.newMessage,
                                      callback: function($$v) {
                                        _vm.newMessage = $$v
                                      },
                                      expression: "newMessage"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.photoJoined,
                                          expression: "!photoJoined"
                                        }
                                      ]
                                    },
                                    [
                                      _vm._m(2),
                                      _c("input", {
                                        ref: "myFiles",
                                        staticClass: "form-control inputImage",
                                        staticStyle: { display: "none" },
                                        attrs: {
                                          type: "file",
                                          id: "upload",
                                          name: "file"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.handleFiles()
                                          }
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.photoJoined,
                                          expression: "photoJoined"
                                        }
                                      ]
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          ref: "previewContainer",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            id: "container-media",
                                            for: "upload"
                                          }
                                        },
                                        [
                                          _c("div", {
                                            ref: "preview",
                                            attrs: {
                                              id: "container-media-content"
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-rotate mr-2 btn-img rotate-left",
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.rotateFiles(
                                                $event,
                                                "left"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("icon", {
                                            attrs: { name: "undo-alt" }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-rotate mr-2 btn-img rotate-right",
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.rotateFiles(
                                                $event,
                                                "right"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("icon", {
                                            attrs: { name: "redo-alt" }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-danger mr-2 btn-img trash",
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.deleteFiles($event)
                                            }
                                          }
                                        },
                                        [
                                          _c("icon", {
                                            attrs: { name: "trash-alt" }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary mt-2",
                                      on: { click: _vm.sendMessage }
                                    },
                                    [_vm._v("Envoyer")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.showMessages
                        ? _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-12" },
                              [
                                _c("m-list-simple", {
                                  ref: "commandelist",
                                  attrs: {
                                    dynamic: true,
                                    items: _vm.messages,
                                    "item-value": "me_id",
                                    total: _vm.messages_length
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "ths",
                                        fn: function(ref) {
                                          return [
                                            _c(
                                              "th",
                                              { attrs: { width: "150px" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "pointer" },
                                                  [_vm._v("Date")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { width: "150px" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "pointer" },
                                                  [_vm._v("Auteur")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { width: "100px" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "pointer" },
                                                  [_vm._v("Avatar")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "th",
                                              { attrs: { width: "70px" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "pointer" },
                                                  [_vm._v("Type")]
                                                )
                                              ]
                                            ),
                                            _c("th", [
                                              _c(
                                                "span",
                                                { staticClass: "pointer" },
                                                [_vm._v("Message")]
                                              )
                                            ]),
                                            _c(
                                              "th",
                                              { attrs: { width: "70px" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "pointer" },
                                                  [_vm._v("Visible")]
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "tds",
                                        fn: function(ref) {
                                          var item = ref.item
                                          return [
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .$moment(item.me_date)
                                                    .format("DD/MM/YYYY")
                                                )
                                              )
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(item.me_pseudo))
                                            ]),
                                            _c("td", [
                                              _c("div", {
                                                staticClass: "container-avatar",
                                                style:
                                                  "background-image:url(" +
                                                  _vm.$config.server_url +
                                                  item.me_avatar +
                                                  ")"
                                              })
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(item.me_type))
                                            ]),
                                            _c("td", [
                                              _vm._v(_vm._s(item.me_text))
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  item.me_invisible
                                                    ? "Non"
                                                    : "Oui"
                                                )
                                              )
                                            ])
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1494743576
                                  )
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _c("modal-schedule-message", {
                        attrs: { row_en: _vm.row_en },
                        model: {
                          value: _vm.scheduleMessage,
                          callback: function($$v) {
                            _vm.scheduleMessage = $$v
                          },
                          expression: "scheduleMessage"
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "card" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row d-flex flex-row align-items-end mb-2"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-md-10" },
                          [
                            _c("div", { staticClass: "uppercase bis mb-3" }, [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.$store.state.user.us_type ===
                                          "LATEAM" ||
                                        _vm.$store.state.user.us_type ===
                                          "EMOTIVI_USER",
                                      expression:
                                        "$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
                                    }
                                  ],
                                  staticClass: "number-title"
                                },
                                [_vm._v("5")]
                              ),
                              _vm._v("Les actions ")
                            ]),
                            _c("m-form-text", {
                              staticClass: "ml-3",
                              attrs: {
                                type: "textarea",
                                rows: 1,
                                label: "Ajouter une action",
                                "label-position": "top",
                                name: "newCommentAction"
                              },
                              model: {
                                value: _vm.newCommentAction,
                                callback: function($$v) {
                                  _vm.newCommentAction = $$v
                                },
                                expression: "newCommentAction"
                              }
                            })
                          ],
                          1
                        ),
                        _c("div", { staticClass: "col-md-2" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary ml-2",
                              on: { click: _vm.addCommentAction }
                            },
                            [_vm._v("Ajouter")]
                          )
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "row align-items-end mb-2" }, [
                      _c("div", { staticClass: "col-md-4" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-warning ml-3",
                            on: {
                              click: function($event) {
                                return _vm.loadActions("showList")
                              }
                            }
                          },
                          [
                            !_vm.showActions
                              ? _c("span", [_vm._v("Afficher")])
                              : _vm._e(),
                            _vm.showActions
                              ? _c("span", [_vm._v("Masquer")])
                              : _vm._e(),
                            _vm._v(" les actions ")
                          ]
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c("m-form-select", {
                            attrs: {
                              label: "Type d'action",
                              name: _vm.$Utils.randomstring("ac_type"),
                              items: _vm.listActions
                            },
                            model: {
                              value: _vm.selectedActionType,
                              callback: function($$v) {
                                _vm.selectedActionType = $$v
                              },
                              expression: "selectedActionType"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-4" },
                        [
                          _c("m-form-date", {
                            staticClass: "mx-1",
                            attrs: {
                              label: "Date de l'action",
                              name: _vm.$Utils.randomstring("ac_date"),
                              formatInput: "DD/MM/YYYY HH:mm:ss",
                              formatValue: "YYYY-MM-DD HH:mm:ss"
                            },
                            model: {
                              value: _vm.actionDate,
                              callback: function($$v) {
                                _vm.actionDate = $$v
                              },
                              expression: "actionDate"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm.showActions
                      ? _c(
                          "div",
                          [
                            _c("m-list-simple", {
                              ref: "commandelist",
                              attrs: {
                                limit: 300,
                                dynamic: true,
                                items: _vm.actions,
                                "item-value": "ac_id",
                                total: _vm.action_length
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "ths",
                                    fn: function(ref) {
                                      return [
                                        _c(
                                          "th",
                                          { attrs: { width: "160px" } },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("Date")]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "th",
                                          { attrs: { width: "220px" } },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("Type")]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "th",
                                          { attrs: { width: "220px" } },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("Contact")]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "th",
                                          { attrs: { width: "220px" } },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("Utilisateur final")]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "th",
                                          { attrs: { width: "220px" } },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "pointer" },
                                              [_vm._v("User")]
                                            )
                                          ]
                                        ),
                                        _c("th", [
                                          _c(
                                            "span",
                                            { staticClass: "pointer" },
                                            [_vm._v("Infos")]
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "tds",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatDateTime")(
                                                item.createdAt
                                              )
                                            )
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(item.ac_type))
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatContactNameSimple")(
                                                item.co_id
                                              )
                                            )
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatEndUserName")(
                                                item.en_id
                                              )
                                            )
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatUserName")(
                                                item.us_id
                                              )
                                            )
                                          )
                                        ]),
                                        _c("td", [
                                          item.ac_comment
                                            ? _c("span", [
                                                _vm._v(_vm._s(item.ac_comment))
                                              ])
                                            : _vm._e(),
                                          item.ac_elapsedtime > 0
                                            ? _c("span", [
                                                _vm._v(
                                                  "Durée : " +
                                                    _vm._s(
                                                      Math.floor(
                                                        (item.ac_elapsedtime /
                                                          60) *
                                                          100
                                                      ) / 100
                                                    ) +
                                                    "mn"
                                                )
                                              ])
                                            : _vm._e()
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3041250532
                              )
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "col-md-3" }, [
                  _c("div", { staticClass: "card" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row flex-wrap justify-content-between"
                      },
                      [
                        _c("h6", [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.$store.state.user.us_type ===
                                      "LATEAM" ||
                                    _vm.$store.state.user.us_type ===
                                      "EMOTIVI_USER",
                                  expression:
                                    "$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
                                }
                              ],
                              staticClass: "number-title"
                            },
                            [_vm._v("2")]
                          ),
                          _vm._v("Utilisateur final ")
                        ]),
                        _c("div", [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary mb-2",
                              on: { click: _vm.editEnduser }
                            },
                            [_vm._v("Modifier")]
                          )
                        ])
                      ]
                    ),
                    _c("div", [
                      _vm.row_en.en_linkello
                        ? _c("div", [
                            _c("span", { staticClass: "linkello" }, [
                              _vm._v("Associé à Linkello")
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(
                        " Nom complet : " + _vm._s(_vm.row_en.en_fullname) + " "
                      ),
                      _c("hr", { staticClass: "hr-mini" }),
                      _c("div", { staticClass: "float-right" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-warning btn-sm mt-2",
                            on: { click: _vm.sendInvitationEmail }
                          },
                          [_vm._v("Renvoyer")]
                        )
                      ]),
                      _vm._v(" Ident. webapp : "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(
                                _vm.row_en.en_webapp_login
                              )
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.row_en.en_webapp_login))]
                      ),
                      _c("br"),
                      _vm._v(" Pass webapp :"),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(
                                _vm.row_en.en_webapp_password
                              )
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.row_en.en_webapp_password))]
                      ),
                      _c("hr", { staticClass: "hr-mini" }),
                      _vm._v(" Code administrateur : "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.row_en.en_admincode))]
                      ),
                      _c("hr", { staticClass: "hr-mini" }),
                      _vm._v(
                        " Chambre : " +
                          _vm._s(_vm.row_en.en_room ? _vm.row_en.en_room : "") +
                          " "
                      ),
                      _c("br"),
                      _vm._v(" Statut TV : "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.row_en.en_state_box))]
                      ),
                      _c("br"),
                      _vm._v(" Statut caution: "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.row_en.en_state_caution))]
                      ),
                      _c("br"),
                      _vm._v(" Statut abonnement: "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.row_en.en_state_subscription))]
                      ),
                      _c("br"),
                      _vm._v(" Statut colis: "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.row_en.en_state_parcel))]
                      ),
                      _c("br"),
                      _vm._v(" Statut support: "),
                      _c(
                        "span",
                        {
                          staticClass: "color-emotivi-blue",
                          on: {
                            click: function($event) {
                              return _vm.copieClipboard(_vm.row_en.en_admincode)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.row_en.en_state_support))]
                      ),
                      _c("br"),
                      _c("hr", { staticClass: "hr-mini" }),
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger mb-2",
                            on: { click: _vm.resetTv }
                          },
                          [_vm._v("Réinitialiser la TV")]
                        )
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "card" }, [
                    _c("h6", [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.$store.state.user.us_type === "LATEAM" ||
                                _vm.$store.state.user.us_type ===
                                  "EMOTIVI_USER",
                              expression:
                                "$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
                            }
                          ],
                          staticClass: "number-title"
                        },
                        [_vm._v("3")]
                      ),
                      _vm._v("Résumé ")
                    ]),
                    _c("div", { staticClass: "row mt-2" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("div", [
                          _vm._v(" Inscription le : "),
                          _c(
                            "span",
                            { staticClass: "color-emotivi-blue ml-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.row_en.infos.actionsFirstTime &&
                                      _vm.row_en.infos.actionsFirstTime
                                        .inscription
                                      ? _vm
                                          .$moment(
                                            _vm.row_en.infos.actionsFirstTime
                                              .inscription
                                          )
                                          .format("DD/MM/YYYY")
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("div", [
                          _vm._v(" Première install. le : "),
                          _c(
                            "span",
                            { staticClass: "color-emotivi-blue ml-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.row_en.infos.actionsFirstTime &&
                                      _vm.row_en.infos.actionsFirstTime.install
                                      ? _vm
                                          .$moment(
                                            _vm.row_en.infos.actionsFirstTime
                                              .install
                                          )
                                          .format("DD/MM/YYYY")
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("div", [
                          _vm._v(" Envoi colis le : "),
                          _c(
                            "span",
                            { staticClass: "color-emotivi-blue ml-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.row_en.infos.actionsFirstTime &&
                                      _vm.row_en.infos.actionsFirstTime
                                        .parcel_sended
                                      ? _vm
                                          .$moment(
                                            _vm.row_en.infos.actionsFirstTime
                                              .parcel_sended
                                          )
                                          .format("DD/MM/YYYY")
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("div", [
                          _vm._v(" Dernière connexion le : "),
                          _c(
                            "span",
                            { staticClass: "color-emotivi-blue ml-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.row_en.infos.actionsFirstTime &&
                                      _vm.row_en.infos.actionsFirstTime
                                        .connection
                                      ? _vm
                                          .$moment(
                                            _vm.row_en.infos.actionsFirstTime
                                              .connection
                                          )
                                          .format("DD/MM/YYYY")
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("div", [
                          _vm._v(" Réception colis le : "),
                          _c(
                            "span",
                            { staticClass: "color-emotivi-blue ml-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.row_en.infos.actionsFirstTime &&
                                      _vm.row_en.infos.actionsFirstTime
                                        .parcel_received
                                      ? _vm
                                          .$moment(
                                            _vm.row_en.infos.actionsFirstTime
                                              .parcel_received
                                          )
                                          .format("DD/MM/YYYY")
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("div", [
                          _vm._v(" Premier appel vidéo le : "),
                          _c(
                            "span",
                            { staticClass: "color-emotivi-blue ml-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.row_en.infos.actionsFirstTime &&
                                      _vm.row_en.infos.actionsFirstTime
                                        .videocall_firstSuccess
                                      ? _vm
                                          .$moment(
                                            _vm.row_en.infos.actionsFirstTime
                                              .videocall_firstSuccess
                                          )
                                          .format("DD/MM/YYYY")
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("div", [
                          _vm._v(" Premier appel audio le : "),
                          _c(
                            "span",
                            { staticClass: "color-emotivi-blue ml-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.row_en.infos.actionsFirstTime &&
                                      _vm.row_en.infos.actionsFirstTime
                                        .audiocall_firstSuccess
                                      ? _vm
                                          .$moment(
                                            _vm.row_en.infos.actionsFirstTime
                                              .audiocall_firstSuccess
                                          )
                                          .format("DD/MM/YYYY")
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("div", [
                          _vm._v(" Dernier appel le : "),
                          _c(
                            "span",
                            { staticClass: "color-emotivi-blue ml-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.row_en.infos.actionsFirstTime &&
                                      _vm.row_en.infos.actionsFirstTime
                                        .lastSuccessCall
                                      ? _vm
                                          .$moment(
                                            _vm.row_en.infos.actionsFirstTime
                                              .lastSuccessCall
                                          )
                                          .format("DD/MM/YYYY")
                                      : ""
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c("div", [
                          _vm._v(" VIDÉO/OK/VOIX/OK/MESSAGE "),
                          _c(
                            "span",
                            { staticClass: "color-emotivi-blue ml-2" },
                            _vm._l(_vm.row_en.infos.nbCalls, function(
                              number,
                              index
                            ) {
                              return _c("span", { key: index }, [
                                _vm._v(_vm._s(number) + " "),
                                index < _vm.row_en.infos.nbCalls.length - 1
                                  ? _c("span", [_vm._v("/ ")])
                                  : _vm._e()
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          : _vm._e()
      ]),
      _c("m-message-dialog", {
        attrs: { title: _vm.dialogMessageTitle, text: _vm.dialogMessageTxt },
        model: {
          value: _vm.dialogMessage,
          callback: function($$v) {
            _vm.dialogMessage = $$v
          },
          expression: "dialogMessage"
        }
      }),
      _c("m-select-dialog", {
        attrs: {
          text:
            "Il s'agit d'un abonnement SEPA, souhaitez vous définir l'état comme :",
          title: "Confirmation",
          selectValues: [
            { text: "En attente d'annulation", value: 1 },
            {
              text: "Inactif (abonnement déjà arrêté auprés de la banque)",
              value: 0
            }
          ],
          width: "750px",
          height: "350px"
        },
        on: {
          canceled: function($event) {
            _vm.dialogConfirmCancelSubscriptionRib = false
          },
          confirmed: function($event) {
            return _vm.cancelSubscriptionRib($event)
          }
        },
        model: {
          value: _vm.dialogConfirmCancelSubscriptionRib,
          callback: function($$v) {
            _vm.dialogConfirmCancelSubscriptionRib = $$v
          },
          expression: "dialogConfirmCancelSubscriptionRib"
        }
      }),
      _c("m-confirm-dialog", {
        attrs: {
          text: "Souhaitez-vous résilier cet TV ?",
          title: "Confirmation",
          width: "500px"
        },
        on: {
          canceled: function($event) {
            _vm.dialogConfirmCancelSubscription = false
          },
          confirmed: function($event) {
            return _vm.cancelSubscription()
          }
        },
        model: {
          value: _vm.dialogConfirmCancelSubscription,
          callback: function($$v) {
            _vm.dialogConfirmCancelSubscription = $$v
          },
          expression: "dialogConfirmCancelSubscription"
        }
      }),
      _c("m-confirm-dialog", {
        attrs: {
          text: "Souhaitez-vous rembourser ce paiement ?",
          title: "Confirmation",
          width: "500px"
        },
        on: {
          canceled: function($event) {
            _vm.dialogConfirmCancelCharge = false
          },
          confirmed: function($event) {
            return _vm.cancelChargeConfirmed()
          }
        },
        model: {
          value: _vm.dialogConfirmCancelCharge,
          callback: function($$v) {
            _vm.dialogConfirmCancelCharge = $$v
          },
          expression: "dialogConfirmCancelCharge"
        }
      }),
      _c("m-confirm-dialog", {
        attrs: {
          text: "Souhaitez-vous commencer un abonnement ?",
          title: "Confirmation",
          width: "500px"
        },
        on: {
          canceled: function($event) {
            _vm.dialogConfirmStartSubscription = false
          },
          confirmed: function($event) {
            return _vm.startSubscription()
          }
        },
        model: {
          value: _vm.dialogConfirmStartSubscription,
          callback: function($$v) {
            _vm.dialogConfirmStartSubscription = $$v
          },
          expression: "dialogConfirmStartSubscription"
        }
      }),
      _c("m-confirm-dialog", {
        attrs: {
          text: "Souhaitez-vous prêter le kit ?",
          title: "Confirmation",
          width: "500px"
        },
        on: {
          canceled: function($event) {
            _vm.dialogConfirmStartLoan = false
          },
          confirmed: function($event) {
            return _vm.startLoan()
          }
        },
        model: {
          value: _vm.dialogConfirmStartLoan,
          callback: function($$v) {
            _vm.dialogConfirmStartLoan = $$v
          },
          expression: "dialogConfirmStartLoan"
        }
      }),
      _c("m-confirm-dialog", {
        attrs: {
          text: "Le kit a t'il été restitué ?",
          title: "Confirmation",
          width: "500px"
        },
        on: {
          canceled: function($event) {
            _vm.dialogConfirmStopLoan = false
          },
          confirmed: function($event) {
            return _vm.stopLoan()
          }
        },
        model: {
          value: _vm.dialogConfirmStopLoan,
          callback: function($$v) {
            _vm.dialogConfirmStopLoan = $$v
          },
          expression: "dialogConfirmStopLoan"
        }
      }),
      _c("m-confirm-dialog", {
        attrs: {
          text:
            "Souhaitez-vous déclarer le contact en attente de remboursement ? Vous avez également la possibilité de rembourser directement le contact en cliquant sur la poubelle de la ligne 'Paiements'",
          title: "Confirmation",
          width: "500px"
        },
        on: {
          canceled: function($event) {
            _vm.dialogConfirmWaitingRefund = false
          },
          confirmed: function($event) {
            return _vm.startWaitingRefund()
          }
        },
        model: {
          value: _vm.dialogConfirmWaitingRefund,
          callback: function($$v) {
            _vm.dialogConfirmWaitingRefund = $$v
          },
          expression: "dialogConfirmWaitingRefund"
        }
      }),
      _c("parcel-win-edit", {
        attrs: { en_id: _vm.row_en.en_id, pa_id: _vm.parcelSelected.pa_id },
        on: { parcelSaved: _vm.parcelSaved },
        model: {
          value: _vm.dialogParcel,
          callback: function($$v) {
            _vm.dialogParcel = $$v
          },
          expression: "dialogParcel"
        }
      }),
      _c("tv-win-edit", {
        attrs: { row_en: _vm.row_en, row_de: _vm.row_de },
        on: { saved: _vm.tvSaved, canceled: _vm.tvCancel },
        model: {
          value: _vm.dialogTv,
          callback: function($$v) {
            _vm.dialogTv = $$v
          },
          expression: "dialogTv"
        }
      }),
      _c("contact-win-edit", {
        attrs: { co_id: _vm.row_co.co_id },
        on: { saved: _vm.reloadEnduser, canceled: _vm.cancelWin },
        model: {
          value: _vm.dialogContact,
          callback: function($$v) {
            _vm.dialogContact = $$v
          },
          expression: "dialogContact"
        }
      }),
      _c("enduser-win-edit", {
        attrs: { en_id: _vm.row_en.en_id },
        on: {
          winEditSaved: _vm.reloadEnduser,
          winEditCanceled: _vm.cancelWin,
          winEditDeleted: _vm.deletedEnduser
        },
        model: {
          value: _vm.dialogEnduser,
          callback: function($$v) {
            _vm.dialogEnduser = $$v
          },
          expression: "dialogEnduser"
        }
      }),
      _vm.selectedPayment
        ? _c("payment-details", {
            attrs: { payment: _vm.selectedPayment },
            on: {
              winClose: function($event) {
                return _vm.closeWin("displayPaymentDetails")
              }
            },
            model: {
              value: _vm.displayPaymentDetails,
              callback: function($$v) {
                _vm.displayPaymentDetails = $$v
              },
              expression: "displayPaymentDetails"
            }
          })
        : _vm._e(),
      _c("m-message-dialog", {
        attrs: { title: "Envoyé", text: "Email Colissimo envoyé" },
        on: {
          close: function($event) {
            _vm.mailParcelSend = false
          }
        },
        model: {
          value: _vm.mailParcelSend,
          callback: function($$v) {
            _vm.mailParcelSend = $$v
          },
          expression: "mailParcelSend"
        }
      }),
      _c("m-message-dialog", {
        attrs: {
          title: "Messages effacés",
          text: "Tous les messages ont été effacés"
        },
        on: {
          close: function($event) {
            _vm.deletedAllMessages = false
          }
        },
        model: {
          value: _vm.deletedAllMessages,
          callback: function($$v) {
            _vm.deletedAllMessages = $$v
          },
          expression: "deletedAllMessages"
        }
      }),
      _vm.displayVideoCall && _vm.row_de
        ? _c("video-call", {
            attrs: {
              row_de: _vm.row_de,
              en_id: _vm.row_en.en_id,
              type: _vm.callType
            },
            on: { hangup: _vm.videoCallHangup },
            model: {
              value: _vm.dialogVideoCall,
              callback: function($$v) {
                _vm.dialogVideoCall = $$v
              },
              expression: "dialogVideoCall"
            }
          })
        : _vm._e(),
      _c("cancel-charge", {
        attrs: { maxvalue: 100 - this.valueChargeRefunded / 100 },
        on: { amountCancelChargeChanged: _vm.cancelCharge },
        model: {
          value: _vm.dialogCancelCharge,
          callback: function($$v) {
            _vm.dialogCancelCharge = $$v
          },
          expression: "dialogCancelCharge"
        }
      }),
      _c("div", { staticStyle: { display: "none" } }, [
        _c("canvas", { ref: "canvas", attrs: { width: "1024", height: "768" } })
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-9" }, [
        _c("h3", {}, [_vm._v("Informations commerciales")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-9" }, [
        _c("h3", {}, [_vm._v("Informations techniques")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "fileContainer d-flex justify-content-center",
        attrs: { for: "upload" }
      },
      [
        _c("div", { staticClass: "align-self-center" }, [
          _vm._v("Choisir un fichier")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }