<template>
  <div
    :style="{ display: displaySpinner, 'background-color': backgroundColor, color: textColor, '--spinnerColor': spinnerColor }"
    :class="'spinner ' + animatedSpinner"
  >
    {{ textSpinner }}
    <div v-if="textSpinner2">
      <hr />
      <p>{{ textSpinner2 }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    _text: String,
    _display: String,
    _backgroundColor: String,
    _textColor: String,
    _spinnerColor: String
  },
  mounted() {
    if (this._display) this.display = this._display;
    if (this._text) this.text = this._text;
    if (this._backgroundColor) this.backgroundColor = this._backgroundColor;
    if (this._textColor) this.textColor = this._textColor;
    if (this._spinnerColor) this.spinnerColor = this._spinnerColor;
  },
  data() {
    return {
      display: "none",
      text: "",
      text2: "",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      textColor: "white",
      animatedSpinner: "lds-dual-ring",
      spinnerColor: "white"
    };
  },
  methods: {
    changeDisplay(display, text) {
      this.display = display;
      this.text = text;
    },
    addText(_text2) {
      this.text2 = _text2;
    }
  },
  computed: {
    displaySpinner() {
      return this.display;
    },
    textSpinner() {
      return this.text;
    },
    textSpinner2() {
      return this.text2;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid var(--spinnerColor);
  border-color: var(--spinnerColor) transparent var(--spinnerColor) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
.spinner {
  display: none;

  position: absolute;
  flex-direction: column;
  justify-content: center;
  padding: 3%;

  height: 100%;
  width: 100%;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 500;
  text-align: center;
  left: 0;
  top: 0;

  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  font-weight: 800;
  color: white;
}
p {
  font-weight: 300;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
