<template>
  <section class="d-flex boxes">
    <div class="d-flex flex-column" id="boxes">
      <div class="d-flex header-emotivi">
        <div class="d-flex align-items-center">
          <h1>Liste des boxes ({{ boxes_total }})</h1>
        </div>
        <div class="flex-grow-1 d-flex flex-column justify-content-end align-items-end">
          <div class="d-flex flex-wrap">
            <m-form-checkbox
              class="ml-2 col-form-label-sm"
              label="A faire"
              name="aFaire"
              v-model="filtersBox.aFaire"
              @input="loadBoxes(false)"
            ></m-form-checkbox>
            <m-form-checkbox
              class="ml-2 col-form-label-sm"
              label="Boxes"
              name="checkboxBoxes"
              v-model="filtersBox.checkboxBoxes"
              @input="loadBoxes(false)"
            ></m-form-checkbox>
            <m-form-checkbox
              class="ml-2 col-form-label-sm"
              label="TV"
              name="checkboxTV"
              v-model="filtersBox.checkboxTV"
              @input="loadBoxes(false)"
            ></m-form-checkbox>
            <m-form-checkbox
              class="ml-2 col-form-label-sm"
              label="Sans appareils"
              name="checkboxWithoutDevices"
              v-model="filtersBox.checkboxWithoutDevices"
              @input="loadBoxes(false)"
            ></m-form-checkbox>
            <m-form-text
              class="ml-2 col-form-label-sm"
              style="margin-top: -5px"
              width="150px"
              placeholder="Rechercher"
              label-position="top"
              v-model="filtersBox.filterText"
              name="search"
              autocomplete
              @input="loadBoxes(false)"
            ></m-form-text>
            <button class="btn btn-secondary color-white input-filter btn-sm" type="button" @click="eraseFilters()">X</button>
          </div>
          <div style="margin-top: -12px; z-index: 1000">
            <a href="javascript:void(0);" class="ml-2 col-form-label-sm" @click="moreFilters = !moreFilters">
              {{ moreFilters ? "- de filtres" : "+ de filtres" }}
            </a>
            <a href="javascript:void(0);" class="ml-2 col-form-label-sm" @click="moreActions = !moreActions">
              {{ moreActions ? "- d'actions" : "+ d'actions" }}
            </a>
          </div>
          <div class="more-filters" :style="{ display: moreFilters ? 'flex' : 'none', 'margin-top': '-20px' }">
            <m-form-text
              class="ml-2 col-form-label-sm"
              v-if="$store.state.user.us_type === 'LATEAM'"
              label="Filtrer par numéro de version"
              label-position="top"
              :name="$Utils.randomstring('filterVersionBox')"
              v-model="filtersBox.filterVersionBox"
              @input="loadBoxes('boxOnly', 1000)"
            ></m-form-text>
            <!-- <m-form-select
                class="ml-2 col-form-label-sm"
                v-if="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
                label="Etat de la commande"
                label-position="top"
                :name="$Utils.randomstring('filterStatusBox')"
                v-model="filtersBox.filterStatusBox"
                :items="items_box_connectee"
                @input="loadBoxes(false)"
              ></m-form-select> -->
            <m-form-select
              class="ml-2 col-form-label-sm"
              style="width: 240px"
              v-if="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'EMOTIVI_USER'"
              label="Groupes"
              label-position="top"
              item-value="gr_id"
              :name="$Utils.randomstring('filterStatus')"
              v-model="filtersBox.filterStatus"
              :items="$store.state.mookGroups"
              @input="loadBoxes(false)"
            ></m-form-select>
            <m-form-combobox
              style="width: 200px"
              class="ml-2 col-form-label-sm"
              v-if="$store.getters.checkUserAccess($router, '/establishments')"
              label="Etablissement"
              label-position="top"
              :name="$Utils.randomstring('filterEstablishment')"
              v-model="filtersBox.filterEstablishment"
              :store-url="$config.server_url + '/extranet/2.0/establishments'"
              :store-params="{ ignorePopulate: true }"
              item-value="es_id"
              item-text="es_name"
              @input="loadBoxes(false)"
            ></m-form-combobox>
            <m-form-text
              class="ml-2 col-form-label-sm"
              v-if="$store.state.user.us_type === 'LATEAM'"
              label="Filtrer par site partenaire"
              label-position="top"
              :name="$Utils.randomstring('filterPartnerSite')"
              v-model="filtersBox.filterPartnerSite"
              @input="loadBoxes('boxOnly', 1000)"
            ></m-form-text>
          </div>
          <div class="more-actions" :style="{ display: moreActions ? 'flex' : 'none' }">
            <button
              v-if="$store.getters.checkUserAccess($router, '/boxes/new')"
              class="btn btn-primary color-white input-filter btn-sm"
              type="button"
              @click="newEnduser"
            >
              <icon name="plus" scale="1" class="mr-2"></icon> Nouveau
            </button>
            <button
              v-if="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'DIRECTOR' || $store.state.user.us_type === 'STAFF'"
              class="btn btn-primary color-white input-filter btn-sm"
              type="button"
              @click="showModalToSendMessage"
            >
              Envoyer un message à tous
            </button>
            <button
              v-if="$store.state.user.us_type === 'LATEAM' || $store.state.user.us_type === 'DIRECTOR' || $store.state.user.us_type === 'STAFF'"
              class="btn btn-primary color-white input-filter btn-sm"
              type="button"
              @click="showModalToScheduleMessage"
            >
              Planifier un message pour tous
            </button>
            <button
              v-if="
                $store.state.user.us_type === 'LATEAM' ||
                  $store.state.user.us_type === 'DIRECTOR' ||
                  $store.state.user.us_type === 'TECHNICIAN' ||
                  $store.state.user.us_type === 'STAFF'
              "
              class="btn btn-primary color-white input-filter btn-sm"
              type="button"
              @click="showModalToSendIrMacro"
            >
              Exécuter une macro infrarouge
            </button>
          </div>
        </div>
      </div>

      <div class="flex-grow-1 overflow-hidden d-flex">
        <div class="card card-no-margin">
          <m-list
            ref="boxeslist"
            :dynamic="true"
            :items="boxes"
            item-value="en_id"
            :item-height="30"
            :limit="boxes_limit"
            :skip="boxes_skip"
            :total="boxes_total"
            :current="boxes_current"
            :bgstrip="true"
            @changerange="boxesChangerange"
            @itemclick="goBoxe"
          >
            <template v-slot:ths="{}">
              <th style="width: 150px" class="" @click="sortItems(['en_id'])">
                <span class="pointer">Numéro (en_id)</span>
              </th>
              <th style="width: 120px">
                <span class="pointer">Connectée</span>
              </th>
              <th style="width: 160px">
                <span class="pointer">Bouton & mise à jour</span>
              </th>
              <th style="width: 180px" @click="sortItems(['en_fullname'])">
                <span class="pointer">Utilisateur final</span>
              </th>
              <th style="width: 180px">
                <span class="pointer">Établissement</span>
              </th>
              <th>
                <span style="width: 170px" class="pointer">Fournisseurs</span>
              </th>
              <th v-if="$store.state.user.us_type !== 'PARTNER'" style="width: 170px">
                <span class="pointer">Vidéo/Ok/Voix/Ok/Msg</span>
              </th>
              <th>
                <span class="pointer">A faire</span>
              </th>
              <th v-if="$store.state.user.us_type !== 'PARTNER'" style="width: 80px">
                <span class="pointer">Actions</span>
              </th>
            </template>
            <template v-slot:tds="{ item }">
              <td style="width: 150px">
                {{ item.en_id }} <span v-if="item.bo_id">{{ item.bo_id.bo_mac_address }}</span>
              </td>
              <td
                style="width: 120px"
                :class="{
                  mygreen: item.bo_id && $store.state.connectedBoxes.indexOf(item.bo_id.bo_mac_address) >= 0,
                  myred: !item.de_id && (!item.bo_id || $store.state.connectedBoxes.indexOf(item.bo_id.bo_mac_address) < 0),
                  myblack: item.de_id
                }"
              >
                {{
                  item.de_id
                    ? "Television"
                    : item.bo_id && $store.state.connectedBoxes.indexOf(item.bo_id.bo_mac_address) >= 0
                    ? "Connecté"
                    : "Non connecté"
                }}
              </td>
              <td style="width: 160px">
                <div class="d-flex">
                  <span class="d-flex flex-grow-1 justify-content-start align-items-center">{{
                    item.bo_id && item.bo_id.bo_config_button && item.bo_id.bo_config_button.batterylevel
                      ? item.bo_id.bo_config_button.batterylevel + "%"
                      : ""
                  }}</span>

                  <span class="d-flex flex-grow-1 justify-content-end align-items-center">{{
                    item.bo_id && item.bo_id.bo_to_update !== "0" && item.bo_id.bo_to_update !== "1"
                      ? "Mettre  à jour sur " + item.bo_id.bo_to_update
                      : ""
                  }}</span>
                </div>
              </td>
              <td
                style="width: 180px"
                :class="{
                  resiliate: item.en_resiliate_subscription
                }"
              >
                {{ item.en_fullname }}
                <span class="color-emotivi-blue">
                  {{
                    item.co_id.afco_id && item.co_id.afco_id.af_id && item.co_id.afco_id.af_id.af_code
                      ? "(Affilié à " + item.co_id.afco_id.af_id.af_name + " " + item.co_id.afco_id.af_id.af_code + ")"
                      : ""
                  }}
                  {{ item.en_linkello ? "(Associé à Linkello)" : "" }}
                </span>
              </td>
              <td style="width: 180px">
                {{ item.co_id && item.co_id.es_id ? item.co_id.es_id.es_name : "" }}
              </td>
              <td>
                <span v-if="item.co_id.es_id.es_white_label" style="width: 170px" class="pointer">
                  <img :src="$config.server_url + item.co_id.es_id.es_avatar" alt="logo fournisseur" width="20px" />
                </span>
              </td>
              <td v-if="$store.state.user.us_type !== 'PARTNER'" style="width: 170px">
                <span v-for="(number, index) in item.infos.nbCalls" :key="index"
                  >{{ number }} <span v-if="index < item.infos.nbCalls.length - 1">/ </span></span
                >

                <span
                  style="
                    margin-left: 20px;
                    background-color: green;
                    color: white;
                    font-weight: bold;
                    border-radius: 50%;
                    width: 25px;
                    display: inline-block;
                    text-align: center;
                    padding: 5px;
                    margin-top: 5px;
                  "
                  v-if="item.newMessage && item.newMessage > 0"
                  :data-popover="textMessage(item.newMessage)"
                >
                  {{ item.newMessage }}
                </span>
              </td>
              <td :data-popover="toDo(item)">
                <span>{{ toDo(item) }}</span>
              </td>

              <td v-if="$store.state.user.us_type !== 'PARTNER'" style="width: 60px">
                <div class="d-flex flex-row">
                  <div v-if="$store.state.user.us_type !== 'STAFF'" @click="diagnostikReseau(item, $event)">
                    <icon width="20" height="20" name="cogs" class=""></icon>
                  </div>
                  <div @click="sendEmail(item, $event)" v-if="item.co_id && $store.state.user.us_type !== 'TECHNICIAN'">
                    <a :href="'mailto:' + item.co_id.co_invoice_email">
                      <icon width="20" height="20" name="envelope" color="black" class="ml-2"></icon>
                    </a>
                  </div>
                  <!-- <div @click="sendIdentifiant(item, $event)">
                    <icon width="20" height="20" name="key" class="ml-2"></icon>
                  </div> -->
                </div>
              </td>
            </template>
          </m-list>
        </div>
      </div>
    </div>
    <m-message-dialog v-model="dialogDiagnostiqueReseau" title="Diagnostic réseau" :text="dialogDiagnostiqueReseauTxt" width="70%"></m-message-dialog>
    <m-message-dialog v-model="dialogMessage" title="Confirmation" :text="dialogMessageTxt"></m-message-dialog>
    <enduser-win-edit :en_id="-1" v-model="dialogEnduser" @WinEditSaved="loadBoxes" @winEditCanceled="cancelWin"> </enduser-win-edit>
    <modal-send-message-all v-model="modalSendMessage"></modal-send-message-all>
    <modal-schedule-message-all v-model="modalScheduleMessage"></modal-schedule-message-all>
    <modal-send-ir-macro v-model="modalSendIrMacro"></modal-send-ir-macro>
    <popover ref="popover" />
  </section>
</template>

<script>
import Popover from "../components/Popover.vue";
export default {
  name: "boxes",
  components: { Popover },
  data() {
    return {
      filtersBox: { ...this.$store.state.filtersBox },
      boxes_current: "",
      boxes: [],
      en_ids: [],
      boxes_total: 0,
      boxes_limit: 50,
      boxes_skip: 0,
      moreFilters: false,
      moreActions: false,
      showNoActive: false,
      showOccupedRoom: false,
      filterText: "",
      filterEstablishment: "",
      filterStatus: "",
      filterStatusBox: "",
      filterVersionBox: "",
      sortOrder: "desc",
      sortFields: ["en_id"],

      dialog: false,
      itemSelected: {},
      dialogDiagnostiqueReseau: false,
      dialogDiagnostiqueReseauTxt: "",
      dialogMessage: false,
      dialogMessageTxt: "",
      dialogEnduser: false,
      items_box_connectee: [
        { text: "Active", value: "ACTIVE" },
        { text: "Non active", value: "NOTACTIVE" },
        { text: "Non payé", value: "UNPAID" }
      ],
      modalSendMessage: false,
      modalSendIrMacro: false,
      modalScheduleMessage: false
    };
  },
  watch: {
    onlyBoxes(val) {
      this.loadBoxes(val);
    },
    filtersBox: {
      handler(val) {
        window.localStorage.setItem("savedBoxFilters", JSON.stringify(val));
      },
      deep: true
    }
  },
  async mounted() {
    // console.log("mounted");
    this.getBoxFilters();
    await this.loadBoxes(false);
    this.$nextTick(() => {
      this.$refs.boxeslist.update(this.$store.state.scrollPosition);
    });
  },
  computed: {
    onlyBoxes() {
      return this.$route.query.onlyBoxes;
    }
  },
  methods: {
    getBoxFilters() {
      //Get saved filters from local storage
      if (!window.localStorage.getItem("savedBoxFilters")) window.localStorage.setItem("savedBoxFilters", JSON.stringify(this.filtersBox));

      let savedFilters = JSON.parse(window.localStorage.getItem("savedBoxFilters"));

      this.filtersBox = savedFilters;

      if (this.filtersBox.filterVersionBox || this.filtersBox.filterEstablishment || this.filtersBox.filterStatus) this.moreFilters = true;
    },
    eraseFilters() {
      this.filtersBox = { ...this.$store.state.filtersBox };
      this.loadBoxes(false);
    },
    textMessage(nbre) {
      const message = "Vous avez reçu " + nbre + " nouveau(x) message(s)";
      return message;
    },
    newEnduser() {
      this.dialogEnduser = true;
      this.$router.push("/boxes/new").catch(err => {});
    },
    async sendIdentifiant(item, $event) {
      $event.stopPropagation();
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/endusers/" + item.en_id + "/sendlogin");
      if (!response || !response.data) return;
      if (response.data.data.success) {
        this.dialogMessageTxt = "Les identifiants et mot de passe ont bien été envoyé au contact";
        this.dialogMessage = true;
      }
    },
    sendEmail(item, $event) {
      $event.stopPropagation();
    },
    async diagnostikReseau(item, $event) {
      $event.stopPropagation();
      //let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/boxes/network");
      //if (!response || !response.data) return;
      if (!item.bo_id) this.dialogDiagnostiqueReseauTxt = "pas de box";
      else this.dialogDiagnostiqueReseauTxt = item.bo_id.bo_config_network;
      // this.dialogDiagnostiqueReseauTxt = item.bo_id.bo_config_network;
      this.dialogDiagnostiqueReseau = true;
    },
    sortItems(fields) {
      // console.log("fields", fields);
      if (fields.length && this.filtersBox.sortFields.length && fields[0] == this.filtersBox.sortFields[0]) {
        this.filtersBox.sortOrder = this.filtersBox.sortOrder == "asc" ? "desc" : "asc";
      }
      this.filtersBox.sortFields = fields;
      if (fields[0] !== "co_connection_date") {
        this.loadBoxes(false);
      } else {
        this.patients.sort((a, b) => {
          if (this.filtersBox.sortOrder === "asc") {
            if (this.$moment(a.co_connection_date).isBefore(b.co_connection_date)) return -1;
            if (this.$moment(a.co_connection_date).isAfter(b.co_connection_date)) return 1;
            if (this.$moment(a.co_connection_date).isSame(b.co_connection_date)) return 0;
          } else {
            if (this.$moment(a.co_connection_date).isBefore(b.co_connection_date)) return 1;
            if (this.$moment(a.co_connection_date).isAfter(b.co_connection_date)) return -1;
            if (this.$moment(a.co_connection_date).isSame(b.co_connection_date)) return 0;
          }
        });
      }
    },
    /**
     * loadBoxes - triggered on page load or on search input - get end user list by range ( skip && limit )
     * @param boxOnly
     * @param limit
     */
    async loadBoxes(boxOnly, limit) {
      let types = [];
      let sort = this.filtersBox.sortFields.reduce((accumulator, field) => {
        if (accumulator) accumulator += ", ";
        accumulator += field + " " + this.filtersBox.sortOrder;
        return accumulator;
      }, "");
      let params = {
        types: types,
        skip: this.boxes_skip,
        limit: limit || this.boxes_limit,
        sort: sort
      };
      //if (boxOnly || this.onlyBoxes) params.bo_id = ">0";
      if (this.filtersBox.checkboxBoxes) params.boxes = "true";
      if (this.filtersBox.checkboxTV) params.devices = "true";
      if (this.filtersBox.checkboxWithoutDevices) params.withoutDevices = "true";
      //if (this.checkboxWithoutDevices) to do
      if (this.filterText) params.text = this.filterText;
      if (this.filterEstablishment) params.establishment = this.filterEstablishment.es_id;
      if (this.filtersBox.filterStatus) params.gr_id = this.filtersBox.filterStatus;
      if (this.filtersBox.aFaire) params.aFaire = "true";
      if (this.filterStatusBox) params.en_active = this.filterStatusBox;
      if (this.filterVersionBox) params.box_version = this.filterVersionBox;
      if (this.filtersBox.filterText) params.text = this.filtersBox.filterText;
      if (this.filtersBox.filterEstablishment) params.establishment = this.filtersBox.filterEstablishment.es_id;
      if (this.filtersBox.filterStatusBox) params.en_active = this.filtersBox.filterStatusBox;
      if (this.filtersBox.filterVersionBox) params.box_version = this.filtersBox.filterVersionBox;
      if (this.filtersBox.filterPartnerSite) params.partner_site = this.filtersBox.filterPartnerSite;
      if (this.$store.state.user.us_type === "PARTNER") params.en_code_affiliation = this.$store.state.user.us_code_affiliation;
      this.cancelAxiosLoadContacts && this.cancelAxiosLoadContacts();
      //this.$store.commit("set_filtersBox", this.filtersBox);
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/endusers", {
        params,
        cancelToken: new this.$axios.CancelToken(c => {
          this.cancelAxiosLoadContacts = c;
        })
      });
      if (!response || !response.data) return;
      this.boxes = response.data.data;
      this.boxes_total = response.data.meta.total;
      // this.$refs.boxeslist.update();
      // console.log("this.boxes", this.boxes);
    },
    toDo(boxe) {
      let problems = [];
      if (boxe.en_state_parcel == "TO_PREPARE") {
        problems.push(this.$Utils.getTextFromState(this.$store.state.items_en_state_parcel, "TO_PREPARE"));
      }
      if (boxe.en_state_parcel == "TO_SEND") {
        problems.push(this.$Utils.getTextFromState(this.$store.state.items_en_state_parcel, "TO_SEND"));
      }
      if (boxe.en_state_parcel == "WAITING") {
        problems.push(this.$Utils.getTextFromState(this.$store.state.items_en_state_parcel, "WAITING"));
      }
      if (boxe.en_state_box == "NOT_INSTALLED") {
        problems.push(this.$Utils.getTextFromState(this.$store.state.items_en_state_box, "NOT_INSTALLED"));
      }
      if (boxe.en_state_caution == "REFUND_WAITING") {
        problems.push(this.$Utils.getTextFromState(this.$store.state.items_en_state_caution, "REFUND_WAITING"));
      }
      if (boxe.en_state_caution == "NOT_PAYED") {
        problems.push(this.$Utils.getTextFromState(this.$store.state.items_en_state_caution, "NOT_PAYED"));
      }
      if (boxe.en_state_subscription == "WAITING_STOPPED") {
        problems.push(this.$Utils.getTextFromState(this.$store.state.items_en_state_subscription, "WAITING_STOPPED"));
      }
      if (boxe.en_state_subscription == "RIB_TO_CONFIRM") {
        problems.push(this.$Utils.getTextFromState(this.$store.state.items_en_state_subscription, "RIB_TO_CONFIRM"));
      }
      if (boxe.en_state_subscription == "RIB_PROBLEM") {
        problems.push(this.$Utils.getTextFromState(this.$store.state.items_en_state_subscription, "RIB_PROBLEM"));
      }
      if (boxe.en_state_subscription == "CB_PROBLEM") {
        problems.push(this.$Utils.getTextFromState(this.$store.state.items_en_state_subscription, "CB_PROBLEM"));
      }
      if (boxe.en_state_support != "NONE") {
        problems.push(this.$Utils.getTextFromState(this.$store.state.items_en_state_support, boxe.en_state_support));
      }
      return problems.join(", ");
    },
    boxesChangerange(skip, scrollTop) {
      // console.log("skip, scrollTop", skip, scrollTop);
      this.boxes_skip = skip;
      this.loadBoxes(false);
    },
    goBoxe(item) {
      if (this.$store.state.user.us_type === "PARTNER") return;
      this.$store.commit("set_scrollPosition", this.$refs.boxeslist.scrollTop);
      this.itemSelected = item;
      this.$router.push("/boxes/" + this.itemSelected.en_id);
      this.dialog = true;
    },
    showModalToSendMessage() {
      this.modalSendMessage = true;
    },
    showModalToScheduleMessage() {
      this.modalScheduleMessage = true;
    },
    showModalToSendIrMacro() {
      this.modalSendIrMacro = true;
    },
    cancelWin() {
      this.$router.push("/boxes");
    }
  },
  updated() {
    this.$refs.popover.init();
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";

.more-filters {
  display: none;
}

.myblack {
  color: black;
}

.mygreen {
  color: green;
  font-weight: bold;
}

.myred {
  color: red;
}
.resiliate {
  background-color: #ffbb00;
  color: white;
}

.input-filter {
  width: 100%;
}

@media (min-width: 576px) {
  .input-filter {
    width: auto;
    margin-left: 5px;
  }
}

@media (min-width: 768px) {
  .boxes {
    padding-top: 0px !important;
  }
}

.badge {
  position: relative;
}

.badge[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 0.7em;
  background: green;
  color: white;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  box-shadow: 0 0 1px #333;
}

.badge1 {
  position: relative;
  color: green;
  top: 2px;
  border-radius: 50px !important;
}

.badge1[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  // top: -10px;
  // right: -10px;
  font-size: 0.7em;
  background: green;
  color: white;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  box-shadow: 0 0 1px #333;
}
</style>
