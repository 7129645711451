<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div>
              <h1>Édition d'un établissement</h1>
            </div>
            <!--  <div class="d-flex flex-row align-items-center active">
              <label class="label-checkbox" for="active">Activer</label>
              <input type="checkbox" id="actif" name="actif" v-model="row_es.es_active" />
            </div> -->
          </div>
          <div class="modal-body">
            <h6><span class="number-title">1</span>Nom</h6>
            <div class="row">
              <div class="col-md-6">
                <m-form-text label="Nom" label-position="top" name="name" v-model="row_es.es_name"></m-form-text>
              </div>
              <div class="col-md-6">
                <m-form-select
                  label="Type d'établissement  "
                  v-model="row_es.es_type"
                  :name="$Utils.randomstring('es_type')"
                  :rules="[$Validation.mandatory]"
                  :items="$store.state.items_es_type"
                ></m-form-select>
              </div>
            </div>
            <m-form-text type="textarea" label="Description" label-position="top" name="numtv" v-model="row_es.es_description"></m-form-text>

            <div class="row">
              <div class="col-md-2">
                <div class="container-avatar" :style="styleAvatar"></div>
                <!-- <img class="img-fluid mt-3 rounded-circle" :src="fileSelected.binary ? fileSelected.binary : getSrcAvatar()" alt="" /> -->
              </div>
              <div class="col-md-10">
                <m-form-file class="mt-4" label="Choisissez un avatar" ref="inputImageAvatar" v-model="fileSelected" @inputfile="fileJusteSelected">
                </m-form-file>
              </div>
            </div>
            <div class="my-5">
              <h6><span class="number-title">2</span>Coordonnées</h6>
              <div class="row">
                <div class="col-md-6" v-if="row_es.es_name !== 'Emotivi'">
                  <m-form-text label="Téléphone" label-position="top" name="tel" v-model="row_es.es_tel"></m-form-text>
                </div>
                <div class="col-md-6">
                  <m-form-text label="Email" label-position="top" name="email" v-model="row_es.es_email"></m-form-text>
                </div>
              </div>
              <div class="row d-flex flex-row align-items-end" v-if="row_es.es_name !== 'Emotivi'">
                <div class="col-md-6">
                  <h5 class="mt-4">Adresse de l'établissement</h5>
                  <div style="position: relative">
                    <m-form-text label="Adresse" v-model="address_autocomplete" :disabled="foreignAddress ? true : false"></m-form-text>
                    <div v-if="address_choice.length" class="container-address-choice">
                      <div v-for="address in address_choice" :key="address.id">
                        <div @click="selectAddress(address)" class="d-flex flex-column address-choice">
                          <div class="bis">{{ address.properties.label }}</div>
                          <div>{{ address.properties.context }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <m-form-checkbox
                    class="mt-3"
                    label="Adresse à l'étranger"
                    :name="$Utils.randomstring('foreignAddress')"
                    v-model="foreignAddress"
                  ></m-form-checkbox>
                </div>
              </div>
              <div class="row" v-if="row_es.es_name !== 'Emotivi'">
                <div class="col-md-6">
                  <m-form-text
                    label="Adresse ligne 1"
                    v-model="row_es.es_address1"
                    label-position="top"
                    name="address1"
                    :disabled="foreignAddress ? false : true"
                  ></m-form-text>
                </div>
                <div class="col-md-6">
                  <m-form-text label="Adresse ligne 2 " v-model="row_es.es_address2" label-position="top" name="address2"></m-form-text>
                </div>
              </div>
              <div class="row" v-if="row_es.es_name !== 'Emotivi'">
                <div class="col-md-6">
                  <m-form-text
                    label="zip"
                    v-model="row_es.es_zip"
                    label-position="top"
                    name="zip"
                    :disabled="foreignAddress ? false : true"
                  ></m-form-text>
                </div>
                <div class="col-md-6">
                  <m-form-text
                    label="ville"
                    v-model="row_es.es_city"
                    label-position="top"
                    name="ville"
                    :disabled="foreignAddress ? false : true"
                  ></m-form-text>
                </div>
              </div>
            </div>
            <div class="my-5">
              <h6><span class="number-title">3</span>Techniciens</h6>
              <div class="row">
                <div class="col-md-6">
                  <p class="text-info">
                    Vous pouvez ajouter une ou des adresses email de techniciens, si vous souhaitez que ces derniers puissent recevoir des alertes
                    techniques liées aux boiters Emotivi
                  </p>
                  <m-form-text label="Email" v-model="technicianEmailToAdd" label-position="top" name="technicianEmail"></m-form-text>
                  <button type="button" class="btn btn-primary btn-mouveal mt-2" @click="addTechnicianEmail()">Ajouter un technicien</button>
                </div>
                <div class="col-md-6">
                  <m-list
                    v-if="Array.isArray(technicianmails) && technicianmails.length"
                    ref="technicianslist"
                    :dynamic="true"
                    :items="technicianmails"
                    :item-height="30"
                    :bgstrip="true"
                  >
                    <template v-slot:ths="{}">
                      <th style="min-width: 50px">
                        <span class="pointer">Email</span>
                      </th>
                      <th>
                        <span class="pointer">Supprimer</span>
                      </th>
                    </template>
                    <template v-slot:tds="{ item }">
                      <td>
                        {{ item }}
                      </td>
                      <td>
                        <div class="d-flex flex-row align-items-center justify-content-end">
                          <div class="pointer ml-2 d-flex align-items-center justify-content-end" @click="deleteTechnicianEmail(item, $event)">
                            <icon width="20" height="20" name="trash-alt" class="mr-2" color="#91295a"></icon>
                          </div>
                        </div>
                      </td>
                    </template>
                  </m-list>
                  <p class="alert alert-info" v-else>Il n'y a aucune adresse email associée à cet établissement.</p>
                </div>
              </div>
            </div>
            <div class="my-5">
              <h6><span class="number-title">4</span>Marque blanche</h6>
              <div class="row">
                <div class="col-md-8">
                  <p class="text-info">
                    Vous pouvez choisir de mettre votre marque en avant en affichant votre logo sur les téléviseurs Emotivi, ainsi que dans
                    l’application famille.
                  </p>
                </div>
                <div class="col-md-4">
                  <m-form-checkbox class="mt-3" label="Marque blanche" name="whiteLabel" v-model="row_es.es_white_label"></m-form-checkbox>
                </div>
              </div>
              <div class="row m-2" v-if="row_es.shorturls">
                <p>
                  Url à la webapp:
                  <span v-for="sh_url in row_es.shorturls" :key="sh_url"
                    >{{ sh_url }} <a :href="sh_url" target="_blank"><icon name="globe" scale="1" class="mr-2"></icon></a
                  ></span>
                </p>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between">
            <button
              type="button"
              class="btn btn-danger"
              @click="deleteConfirmWin"
              v-if="row_es.es_name !== 'Emotivi' && this.$store.state.user.us_type === 'LATEAM'"
            >
              Supprimer
            </button>
            <div v-else></div>
            <div>
              <button type="button" class="btn btn-secondary" @click="cancelWin">Annuler</button>
              <button type="button" class="btn btn-primary btn-mouveal ml-2" @click="saveWin(false)">Enregistrer</button>
            </div>
          </div>
        </div>
      </div>
      <m-confirm-dialog
        v-model="confirmdelete"
        text="Souhaitez-vous effacer cet établissement ?"
        title="Confirmation"
        width="500px"
        @canceled="confirmdelete = false"
        @confirmed="deleteWin()"
      ></m-confirm-dialog>
      <m-message-dialog v-model="dialogErr" title="Erreur" :text="dialogErrTxt"></m-message-dialog>
    </div>
  </transition>
</template>

<script>
export default {
  name: "EstablishmentWinEdit",
  components: {},
  props: {
    es_id: Number,
    value: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      establishment_id: -1,
      row_es: {},
      confirmdelete: false,
      dialogErrTxt: "",
      dialogErr: false,
      fileSelected: { image: null, binary: null },
      /*** address autocomplete  ***/
      address_choice: [],
      address_autocomplete: "",
      addressSelected: {},
      timeout: null,
      foreignAddress: false,
      /********/
      avatarUrl: "",
      styleAvatar: "",
      technicianmails: [],
      technicianEmailToAdd: "",
      row_us: {}
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.establishment_id = this.es_id ? this.es_id : -1;
        this.fileSelected = { image: null, binary: null };
        this.loadestablishments();
      }
    },
    address_autocomplete(val) {
      if (this.timeout) window.clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        let params = { address: val };
        let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/contacts/address", { params });
        if (!response || !response.data) return;
        this.address_choice = response.data.data;
      }, 1000);
    },
    avatarUrl(val) {
      if (this.fileSelected.image) {
        this.styleAvatar = "background-image:url('" + this.fileSelected.binary + "')";
      } else {
        this.styleAvatar = "background-image:url('" + val + "')";
      }
    }
  },
  computed: {},

  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  created() {},
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    fileJusteSelected($event) {
      this.fileSelected.image = $event[0];
      var reader = new FileReader();
      var me = this;
      reader.onload = (function (theFile) {
        return function (e) {
          me.fileSelected.binary = e.target.result;
          me.avatarUrl = e.target.result;
        };
      })($event[0]);
      reader.readAsDataURL($event[0]);
    },
    selectAddress(address) {
      this.addressSelected = address;
      this.row_es.es_address1 = address.properties.name;
      this.row_es.es_zip = address.properties.postcode;
      this.row_es.es_city = address.properties.city;
      this.address_choice = [];
      this.address_autocomplete = "";
    },
    addPlageGo() {
      this.addPlage = false;
    },
    async loadestablishments() {
      let params = {};
      let response = await this.$axios.get(this.$config.server_url + "/extranet/2.0/establishments/" + this.establishment_id, { params });
      if (!response || !response.data) return;
      this.row_es = response.data.data;
      if (this.row_es.technicianMails) this.technicianmails = this.row_es.technicianMails;
      this.avatarUrl = this.$config.server_url + this.row_es.es_avatar;
    },
    async saveWin() {
      let err = [];
      let fieldRequired = [
        { field: "es_name", text: "Nom" },
        { field: "es_email", text: "Email" },
        { field: "es_type", text: "Type d'établissement" }
      ];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        // accept 0 as a valid value for es_type
        if (field.field === "es_type" && this.row_es[field.field] === 0) continue;
        if (!this.row_es[field.field]) err.push(field);
      }
      if (err.length) {
        this.dialogErrTxt = "<span class='bis'>Vous devez remplir les champs : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += error.text + " <br>";
        }
        this.dialogErr = true;
      } else {
        let response;
        if (this.row_es.es_id) {
          response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/establishments/" + this.es_id, {
            ...this.row_es,
            technicianMails: this.technicianmails
          });
        } else {
          response = await this.$axios.post(this.$config.server_url + "/extranet/2.0/establishments", {
            ...this.row_es,
            technicianMails: this.technicianmails
          });
          let response2 = await this.$axios.get(this.$config.server_url + "/extranet/2.0/users/" + this.$store.state.user.us_id);
          if (!response2 || !response2.data) return;
          this.row_us = response2.data.data;
          this.$store.commit("set_user", this.row_us);
        }
        if (response.data.err) {
          this.$store.dispatch("showDialogError", response.data.err.message);
          return;
        }
        let row_es = response.data.data;
        if (this.fileSelected.image) {
          await this.saveImage(this.fileSelected.image, row_es.es_id);
        }
        this.$emit("input", false);
        this.$emit("saveEstablishment");
        this.$router.push("/establishments");
        this.fileSelected = { image: null, binary: null };
        this.row_es = {};
        this.technicianmails = [];
        this.technicianEmailToAdd = "";
      }
    },
    async saveImage(file, es_id) {
      if (!file) return;
      let formData = new FormData();
      formData.append("es_id", es_id);
      formData.append("image", file, file.name);
      await this.$axios.post(this.$config.server_url + "/extranet/2.0/establishments/image", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      this.fileSelected = { image: null, binary: null };
    },
    cancelWin() {
      this.$emit("input", false);
      this.$router.push("/establishments");
      this.fileSelected = { image: null, binary: null };
      this.row_es = {};
      this.technicianmails = [];
      this.technicianEmailToAdd = "";
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteWin() {
      await this.$axios.delete(this.$config.server_url + "/extranet/2.0/establishments/" + this.establishment_id);
      this.confirmdelete = false;
      this.$router.push("/establishments");
      this.$emit("input", false);
      this.$emit("saveEstablishment");
      this.row_es = {};
      this.technicianmails = [];
      this.technicianEmailToAdd = "";
    },
    deleteTechnicianEmail(email, event) {
      let ok = confirm("Etes vous certain de vouloir supprimer cet email ?");
      if (ok) {
        this.technicianmails = this.technicianmails.filter(item => item !== email);
      }
    },
    addTechnicianEmail() {
      if (!this.technicianEmailToAdd) return alert("Veuillez saisir un email.");
      if (this.technicianmails.indexOf(this.technicianEmailToAdd) >= 0) return alert("Cet email a déjà été ajouté.");
      this.technicianmails.push(this.technicianEmailToAdd);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";
.container-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: 8px;
  background-position: center;
  background-size: cover;
  border: 1px solid #b5b5b5;
  box-shadow: 2px 2px 6px 0px #d0d0d0;
}
</style>
