import store from "./store";
import utils from "./utils";

export default new (class Bistri {
  constructor() {
    this.roomData = null;
    this.sessionData = null;
    this.visioMetadata = null;
    this.remoteStream = null;
    this.localStream = null;
    this.isOnlyAudio = false;
    this.lang = "fr";
    this.currentErrorMessage = "";
    this.remotePeer = null;
    this.callUnavailable = false;
    this.isCalling = 0;
    this.loopCheckRoomMembers = 0;
    this.bistriConnexionError = null;
    this.$sockets = null;
    this.$uuid = null;
    this.$axios = null;
    this.$config = null;
    this.en_id = null;
  }
  async init(sockets, uuid, config, axios) {
    if (store.state.bistri_initialized) {
      return;
    }
    // let ok = await utils.wait(() => {
    //   return store.state.bistri_sdk_state;
    // }, 10000);

    //if (ok) {
    this.lang = store.state.lang;
    this.$sockets = sockets;
    this.$uuid = uuid;
    this.$axios = axios;
    this.$config = config;

    window.bc.init({
      appId: this.$config.bistri.app_id,
      appKey: this.$config.bistri.app_key,
      userName: store.state.user.us_firstname + " " + store.state.user.us_name,
      debug: false,
      autoReconnect: true
    });
    store.commit("set_camera", store.state.camera ? store.state.camera : await this.getCurrentCameraSource());
    window.bc.signaling.bind("onError", error => {
      console.error("onError : ", error);
      this.bistriConnexionError = error;
    });
    window.bc.signaling.bind("onConnected", session => {
      console.warn("onConnected - ");
      this.bistriConnexionError = "";
      this.sessionData = session;
      this.joinRoom();
    });
    window.bc.signaling.bind("onJoinedRoom", currentRoom => {
      console.warn("onJoinedRoom", currentRoom);

      this.roomData = currentRoom;
      // check if the box is already in the room -> meaning that a call is already running
      this.roomData.members.forEach(member => {
        if (member.name && member.name.includes("Emotivibox")) {
          // call in progress...
          this.callUnavailable = true;
        }
      });
    });
    window.bc.signaling.bind("onPeerJoinedRoom", newPeer => {
      // there is a new peer -> check if it's the box only
      console.warn("onPeerJoinedRoom", newPeer);
      if (this.localStream && newPeer && newPeer.name && newPeer.name.includes("Emotivibox")) {
        this.remotePeer = newPeer;
      }
    });
    window.bc.signaling.bind("onQuittedRoom", data => {
      // room quitted
      this.roomData = null;
      window.bc.disconnect();
    });
    window.bc.signaling.bind("onPeerQuittedRoom", peer => {
      // HANGUP
      // uniquement si un appel a abouti et qu'il s'agit de la box
      if (this.remotePeer && peer.pid === this.remotePeer.pid && this.remoteStream) {
        console.warn("HANGUP PEER HAS QUITTED THE ROOM - > Emotivibox");
        this.hangUp(false, true, this.lang === "fr" ? "La box a raccrochée." : "The box has hang up");
      }

      console.warn("onPeerQuittedRoom", peer);
    });
    window.bc.signaling.bind("onDisconnected", peer => {
      console.warn("onDisconnected");
      this.sessionData = null;
    });
    window.bc.signaling.bind("onJoinRoomError", error => {
      // error stop the visio
      this.manageErrors(error);
    });
    window.bc.signaling.bind("onQuitRoomError", error => {
      // error quitting bistri room
      this.manageErrors(error);
    });

    window.bc.streams.bind("onStreamAdded", (stream, id) => {
      console.warn("onStreamAdded", stream, id, this.remotePeer);
      // comment faire s'il ne s'agit pas du stream de la box ? mais d'un autre utilisateur de la web app..
      // la room est peut contenir 256 utilisateurs.. 256 streams avec  un id qui ne correspond pas au pid du remotePeer
      if (this.remotePeer) {
        if (this.callerVue.$refs.waitingSpinner) this.callerVue.$refs.waitingSpinner.changeDisplay("none", "");
        if (this.callerVue.$refs.audiowaves) this.callerVue.$refs.audiowaves.changeDisplay("flex", "");
        this.callerVue.visioConnected = 1;
        this.callerVue.clearAutoDisconnect();
        this.remoteStream = stream;
        this.attachRemoteStream();
      }
    });
    window.bc.streams.bind("onStreamClosed", (stream, id) => {
      console.warn("onStreamClosed", stream, id);
    });
    window.bc.streams.bind("onStreamRemoved", (stream, id) => {
      console.warn("onStreamRemoved", stream, id);
    });
    window.bc.streams.bind("onStreamError", error => {
      console.error("onStreamError", error);
      this.hangUp();
      this.manageErrors(error);
    });

    store.commit("set_bistri_initialized", 1);
    // } else {
    //   console.error("Bistri sdk not loaded after 10 seconds");
    // }
  }
  joinRoom() {
    let roomName = this.$uuid.v1();
    window.bc.joinRoom(roomName, 256);
  }
  async call(callerVue, en_id, row_bo) {
    this.currentErrorMessage = "";
    this.en_id = en_id;
    this.callerVue = callerVue;
    if (this.bistriConnexionError) return this.manageErrors(this.bistriConnexionError);
    if (this.callerVue.$refs.waitingSpinner)
      this.callerVue.$refs.waitingSpinner.changeDisplay("flex", this.lang === "fr" ? "Appel en cours" : "Call in progress");
    // check first if bistri socket are connected
    // if (BistriConference && BistriConference.signaling && BistriConference.signaling._socket && !BistriConference.signaling._socket._opened) {
    //   BistriConference.signaling._socket.reconnect();
    // }
    let isOnline = false;
    if (navigator.onLine) {
      // we use the navigator.onLine feature to check if we are connected to the internet or not
      isOnline = navigator.onLine;
    } else isOnline = true;
    let ok = await utils.wait(() => {
      return (
        this.roomData &&
        // BistriConference.signaling._socket._opened &&
        // store.state.$socket.connected &&
        store.state.mysocket_connected_to_room &&
        isOnline
      );
    }, 6000);
    if (ok) {
      // check first if the call is available
      //if (this.callUnavailable) return this.manageErrors({ code: "102" });
      let errorBistri = await this.checkRoomMembers("isBoxPresent");
      if (errorBistri) return this.manageErrors(errorBistri);

      this.isOnlyAudio = this.callerVue.isOnlyAudio;
      let publishObject = {
        audio: true,
        video: {
          width: {
            min: "160",
            ideal: "640",
            max: "1920"
          },
          height: {
            min: "120",
            ideal: "480",
            max: "1080"
          },
          frameRate: {
            min: "5",
            ideal: "30",
            max: "60"
          }
        }
      };
      if (this.isOnlyAudio) {
        publishObject = "pure-audio";
      }
      if (store.state.camera && !this.isOnlyAudio) {
        publishObject.video.facingMode = store.state.camera;
      }

      window.bc.startStream(
        publishObject,
        async stream => {
          this.localStream = stream;
          console.warn("local stream  :", stream);
          window.bc.attachStream(stream, this.isOnlyAudio ? document.getElementById("myaudio") : document.getElementById("myvideo"), {
            // autoplay: true,
            fullscreen: false,
            controls: false,
            mirror: true
          });
          // let errorBistri = await this.checkRoomMembers("", "isCurrentUserPresent");
          // if (errorBistri) return this.manageErrors(errorBistri);
          this.callBox(en_id, row_bo);
        },
        error => {
          console.error("BISTRI START TREAM ERROR > ", error);
        }
      );
    } else {
      //ERROR BISTRI

      this.callerVue.showModalAlert(this.currentErrorMessage);
    }
  }
  async callBox(en_id, row_bo) {
    try {
      let dataBistriCall = {
        type: this.isOnlyAudio ? "audio" : "video",
        callerName: store.state.user.us_firstname + " " + store.state.user.us_name,
        autoresponse: row_bo.bo_autoresponse ? "yes" : "no",
        bistriCallerId: this.sessionData.id,
        bistriRoomName: this.roomData.room,
        callerAvatar: this.$config.server_url + "/public/2.0/avatar/default-avatar.png",
        callExtranetUserId: store.state.user.us_id
      };
      // let response =
      let resStartCall = await this.$axios.post(this.$config.server_url + "/extranet/2.0/do/start-call/" + en_id, dataBistriCall);
      // console.log("response", response);
      if (resStartCall.data.err) {
        if (resStartCall.data.err.key == "callhours_mismatch") {
          let hours = [];
          for (let iH = 0; iH < resStartCall.data.errData.length; iH++) {
            const plage = resStartCall.data.errData[iH];
            hours.push(`<li>de ${plage.start.split(":").join("H")} à ${plage.end.split(":").join("H")}</li>`);
          }
          this.hangUp(false, true, `L'appel est impossible pour le moment.<br/>Les appels sont possible : <ul>${hours.join("")}</ul>`);
        } else {
          this.hangUp(false, true, resStartCall.data.err.message);
        }
      }
      if (resStartCall.data.ac_id) {
        store.commit("set_ac_id", resStartCall.data.ac_id);
      }
      this.isCalling = 1;
      //if (store.state.isTvMobile) this.answeringMessage = false;
    } catch (error) {
      console.error("callBox ERROR : ", error);
      //this.destroyBistri(this.lang === "fr" ? this.pageText.alertBistriError.fr : this.pageText.alertBistriError.en, "startVideoRoom error");
    }
  }
  attachRemoteStream() {
    //utils.doRequestIo("get", "/peerConnected?event=" + store.state.eventID + callerName + onlyaudio, null);
    window.bc.attachStream(this.remoteStream, this.isOnlyAudio ? document.getElementById("audioremote1") : document.getElementById("videoremote1"), {
      autoplay: true,
      fullscreen: false,
      controls: false,
      mirror: false
    });
  }
  async hangUp(boxAlreadyInCall, disabled, message) {
    let mypeer = this.sessionData.id;
    if (this.callerVue && this.callerVue.$refs && this.callerVue.$refs.waitingSpinner)
      this.callerVue.$refs.waitingSpinner.changeDisplay("flex", this.lang === "fr" ? "Déconnexion" : "Disconnection");
    if (this.localStream) {
      window.bc.stopStream(this.localStream, () => {
        // remove stream from the page

        window.bc.detachStream(this.localStream);
        this.localStream = null;
      });
    }
    if (this.remoteStream) {
      window.bc.stopStream(this.remoteStream, () => {
        // remove stream from the page

        window.bc.detachStream(this.remoteStream);
        this.remoteStream = null;
      });
    }

    if (this.callerVue) {
      this.callerVue.visioConnected = 0;
      this.callerVue.clearAutoDisconnect();
      if (!this.callerVue.webApp_hangup) {
        if (boxAlreadyInCall) this.manageErrors({ code: "102" });
        else if (message && typeof message === "object") {
          this.callerVue.showModalAlert(this.lang === "fr" ? message.fr : message.en);
        } else if (message) {
          this.callerVue.showModalAlert(message);
        }
      } else {
        if (this.callerVue) {
          // pour éviter de rappeller trop vite..
          //setTimeout(() => {
          store.state.disableCall = true;
          this.callerVue.$router.push("home");
          // }, 6 * 1000);
          setTimeout(() => {
            store.state.disableCall = false;
          }, 6 * 1000);
        }
      }
      this.callerVue.$emit("input", false);
      this.callerVue.$emit("hangup");
    }

    if (this.isCalling && !boxAlreadyInCall && !disabled) {
      this.notifStopVisio(mypeer);
    }
    this.isCalling = 0;
  }
  /**  fonction qui envoie une requête http au serveur emotivi pour informer l'emobox que nous avons raccroché */
  async notifStopVisio(mypeer) {
    if (this.en_id) {
      await this.$axios.post(this.$config.server_url + "/extranet/2.0/do/hangup-call/" + this.en_id, {
        bistriCallerId: mypeer,
        typeofcall: this.isOnlyAudio ? "AUDIO" : "VIDEO",
        ac_id: store.state.ac_id
      });
      store.commit("set_ac_id", "");
      this.en_id = null;
    }

    return;
  }
  manageErrors(hangup_message) {
    /* 
		PermissionDeniedError: "NotAllowedError",
		PermissionDismissedError: "NotAllowedError",
		InvalidStateError: "NotAllowedError",
		DevicesNotFoundError: "NotFoundError",
		ConstraintNotSatisfiedError: "OverconstrainedError",
		TrackStartError: "NotReadableError",
		MediaDeviceFailedDueToShutdown: "NotAllowedError",
		MediaDeviceKillSwitchOn: "NotAllowedError",
		TabCaptureError: "AbortError",
		ScreenCaptureError: "AbortError",
		DeviceCaptureError: "AbortError"
		*/
    let displayText = "";

    if (
      (hangup_message && hangup_message.message && hangup_message.message === "Permission denied") ||
      (hangup_message && hangup_message.name && hangup_message.name.indexOf("NotAllowedError") >= 0) ||
      (hangup_message && hangup_message.name && hangup_message.name.indexOf("NotFoundError") >= 0)
    ) {
      displayText =
        this.lang === "fr"
          ? "La caméra ou le microphone est inaccessible, veuillez autoriser la web app à accéder à la caméra ou au microphone"
          : "camera or microphone unavailable, please authorize the web app to access the camera / microphone";
    } else if (hangup_message && hangup_message.name && hangup_message.name.indexOf("OverconstrainedError") >= 0) {
      displayText =
        this.lang === "fr"
          ? "La camera ne possède aucune résolution adaptée pour effectuer  un appel vidéo"
          : "The camera has no resolution suitable for making a video call";
    } else if (hangup_message && hangup_message.name && hangup_message.name.indexOf("NotReadableError") >= 0) {
      displayText =
        this.lang === "fr"
          ? "La caméra ou le microphone est inaccessible, veuillez vérifier si la camera/microphone n'est pas déjà en cours d'utilisation."
          : "The camera or microphone is unavailable, please check if the camera / microphone is not already in use.";
    } else if (hangup_message && hangup_message.name && hangup_message.name.indexOf("AbortError") >= 0) {
      displayText =
        this.lang === "fr"
          ? "Impossible de récuperer le flux de la camera/microphone."
          : "Unable to retrieve the stream from the camera / microphone.";
    } else if (hangup_message && hangup_message.code) {
      switch (hangup_message.code) {
        case "000": //  api access limit exceeded
          displayText =
            this.lang === "fr"
              ? "Une erreur est survenue lors de l'appel vidéo...veuillez nous contacter à support@emotivi.io"
              : "the call is unavailable for the moment...please contact us at support@emotivi.io";
          break;
        case "101": //  user is already present in room
          displayText = this.lang === "fr" ? "L'appel a déjà été demarré..." : "call already in progress...";
          break;
        case "102": //  the room reached its max capacity
          displayText = this.lang === "fr" ? "Un appel est en cours..." : "the call is unavailable for the moment...";
          break;
        case "103": //  the room does not exist
          console.warn("error 103: the room doesn’t exists");
          displayText = this.lang === "fr" ? "Une erreur est survenue lors de l'appel vidéo ..." : "An error occured during the call...";
          break;
        case "104": //  the user is not present in the room
          console.warn("error 104 - user is not present in the room");
          displayText =
            this.lang === "fr"
              ? "Une erreur est survenue lors de l'appel vidéo, vous n'avez pas pu rejoindre le serveur vidéo   ..."
              : "An error occured during the call, you ...";
          break;
        case "106": //  maximum simultaneous joined rooms reached
          console.warn("error 106: maximum simultaneous joined rooms reached");
          displayText = this.lang === "fr" ? "Un appel est en cours..." : "the call is unavailable for the moment...";
          break;

        default:
          displayText = this.lang === "fr" ? "Un appel est en cours..." : "the call is unavailable for the moment...";
          break;
      }
    } else {
      let more = "";
      if (hangup_message) {
        more = hangup_message;
      }
      displayText =
        this.lang === "fr" ? "Erreur lors de l'appel - raccrochage automatique. " + more : "Video call error - automatic hanging up. " + more;
    }
    if (this.callerVue) this.callerVue.showModalAlert(displayText);
    else {
      console.error(displayText);
      this.currentErrorMessage = displayText;
    }
  }
  async checkRoomMembers(isBoxPresent, isCurrentUserPresent) {
    if (this.roomData) {
      let error = null;
      if (isBoxPresent) error = await this.isBoxPresent();
      if (isCurrentUserPresent) error = await this.isCurrentUserPresent();
      this.loopCheckRoomMembers = 0;
      return error; // if null , we can call the box
    } else {
      return { code: "103" }; // room not created ?..
    }
  }
  isBoxPresent() {
    return new Promise(resolve => {
      let me = this;
      let error = false;
      function getRoomMemebers_recursive() {
        if (me.loopCheckRoomMembers > 20) {
          me.loopCheckRoomMembers = 0;
          resolve({ code: "104" });
        }
        me.loopCheckRoomMembers++;
        let roomMembers = window.bc.getRoomMembers(me.roomData.room);
        roomMembers.forEach(member => {
          //  check if the box is already in the room -> a call is already set
          if (member.name && member.name.includes("Emotivibox")) {
            me.loopCheckRoomMembers = 0;
            error = true;
            resolve({ code: "102" });
          }
        });
        if (!error) resolve();
        setTimeout(() => {
          getRoomMemebers_recursive();
        }, 500); // if no error and we're not yet present in the room , we check again
      }
      getRoomMemebers_recursive();
    });
  }
  isCurrentUserPresent() {
    return new Promise(resolve => {
      let me = this;
      function getRoomMemebers_recursive() {
        if (me.loopCheckRoomMembers > 20) {
          me.loopCheckRoomMembers = 0;
          resolve({ code: "104" });
        }
        me.loopCheckRoomMembers++;
        let roomMembers = window.bc.getRoomMembers(me.roomData.room);
        roomMembers.forEach(member => {
          // we are present in the room so we can start the call
          if (member.id === me.sessionData.id) {
            me.loopCheckRoomMembers = 0;
            resolve();
          }
        });

        setTimeout(() => {
          getRoomMemebers_recursive();
        }, 500); // if no error and we're not yet present in the room , we check again
      }
      getRoomMemebers_recursive();
    });
  }
  logout() {
    if (this.roomData) window.bc.quitRoom(this.roomData.room);
  }
  async getCurrentCameraSource() {
    let deviceList = await new Promise(resolve => {
      window.bc.getMediaSources(function(sources) {
        resolve(sources);
      });
    });
    let camera = new Promise(resolve => {
      if (deviceList && deviceList.length) {
        let facingMode = "";

        deviceList.forEach(_device => {
          console.warn(_device);
          if (_device.kind.indexOf("video") >= 0 && _device.facing && _device.deviceId === "default") facingMode = _device.facing;
        });
        resolve(facingMode);
      } else resolve("");
    });
    return camera;
  }
})();
