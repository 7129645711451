<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="value ? 'display:block;background-color:#3333337a;' : 'display:none;'"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <div>
              <h1>Édition de la tv numéro {{ tv.de_id }}</h1>
              <p></p>
            </div>
          </div>
          <div class="modal-body modal-padding">
            <div class="d-flex flex-row align-items-center active">
              <input type="checkbox" id="tvmobile" name="tvmobile" v-model="tv.de_mobile" />
              <label class="label-checkbox" for="tvmobile">Télévision mobile</label>
            </div>
            <hr />

            <div class="mt-3">
              <h5>Plages horaires des appels vidéo</h5>
              <div class="row">
                <div class="col-md-6">
                  <m-form-checkbox
                    label="Désactiver les horaires"
                    v-model="tv.de_disable_callhours"
                    :name="$Utils.randomstring('disablehours')"
                    class=""
                  ></m-form-checkbox>
                  <div v-for="(plage, index) in manageHourReformat" :key="index" class="mb-2">
                    <div class="d-flex flex-row align-items-end">
                      <v-time-picker format="HHHmm" v-model="plage.start_time" class="time-picker"></v-time-picker>
                      <v-time-picker format="HHHmm" v-model="plage.end_time" class="time-picker ml-2"></v-time-picker>
                      <div @click="deletePlage(plage, index)" class="ml-2 mb-1 pointer">
                        <icon width="25" height="25" name="times"> </icon>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                    <button @click="addPlage = true" type="button" class="btn btn-primary mt-2">
                      Ajouter une plage horaire
                    </button>
                  </div>
                  <div v-if="addPlage" class="d-flex flex-row align-items-end mt-2">
                    <div class="d-flex flex-column">
                      <div>Heure de début</div>
                      <v-time-picker v-model="newHourStart" class="time-picker" :minute-interval="10"></v-time-picker>
                    </div>
                    <div class="d-flex flex-column">
                      <div>Heure de fin</div>
                      <v-time-picker v-model="newHourEnd" class="time-picker ml-2" :minute-interval="10"></v-time-picker>
                    </div>
                    <button @click="addPlageGo" type="button" class="btn btn-primary ml-2">
                      Ajouter
                    </button>
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <h5>Options des appels</h5>
            <m-form-checkbox
              label="Décrochage automatique activé pour l’établissement et l’administrateur"
              v-model="tv.de_autoresponse"
              :name="$Utils.randomstring('de_autoresponse')"
              class="mr-3"
              @input="onChangeAutoresponse"
            ></m-form-checkbox>
            <m-form-checkbox
              label="Décrochage automatique activé pour tous (établissement, administrateur et membres de la famille)"
              v-model="tv.de_autoresponse_forall"
              :name="$Utils.randomstring('de_autoresponse_forall')"
              class="mr-3"
              :disabled="!tv.de_autoresponse"
            ></m-form-checkbox>
            <m-form-checkbox
              label="Désactiver l'appel vidéo"
              v-model="tv.de_disable_video"
              :name="$Utils.randomstring('de_disable_video')"
              class="mr-3"
            ></m-form-checkbox>
          </div>
          <div class="modal-footer d-flex flex-row justify-content-between">
            <button type="button" class="btn btn-danger" @click="deleteConfirmWin">
              Supprimer
            </button>
            <div>
              <button type="button" class="btn btn-secondary" @click="cancelWin">
                Annuler
              </button>
              <button type="button" class="btn btn-primary btn-mouveal ml-2" @click="saveWin(false)">
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <m-confirm-dialog
        v-model="confirmdelete"
        text="Souhaitez-vous effacer cette tv ?"
        title="Confirmation"
        width="500px"
        @canceled="confirmdelete = false"
        @confirmed="deleteWin()"
      ></m-confirm-dialog>
    </div>
  </transition>
</template>

<script>
export default {
  name: "TvWinEdit",
  components: {},
  props: {
    row_de: Object,
    row_en: Object,
    value: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      tv: {},
      confirmdelete: false,
      items: [
        { text: "rouge", value: "0" },
        { text: "vert", value: "1" },
        { text: "rose", value: "2" },
        { text: "orange", value: "3" }
      ],
      // résolution
      resolutionSelected: {},
      resolutionItems: [],
      // les plages horaires
      manageHour: [],
      manageHourReformat: [],
      newHourStart: "",
      newHourEnd: "",
      addPlage: false,

      majorTvVersion: 0,
      minorTvVersion: 0
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.tv = this.row_de;
        //les plages hoaraires
        if (this.tv.de_callhours) this.manageHour = this.tv.de_callhours;
        this.manageHourReformat = [];
        for (let iplage = 0; iplage < this.manageHour.length; iplage++) {
          const plage = this.manageHour[iplage];
          let obj = {};

          obj.start_time = { HH: plage.start.split(":")[0], mm: plage.start.split(":")[1] };
          obj.end_time = { HH: plage.end.split(":")[0], mm: plage.end.split(":")[1] };
          this.manageHourReformat.push(obj);
          // console.log("this.manageH", this.manageHourReformat);
        }

        // get formated tv version
        this.formatTvVersion();
      }
    }
  },
  computed: {},

  mounted() {
    window.addEventListener("keyup", this.onKeyup);
    // get formated tv version
    this.formatTvVersion();
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  created() {},
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    onChangeAutoresponse() {
      if (!this.tv.de_autoresponse) {
        this.tv.de_autoresponse_forall = false;
      }
    },
    addPlageGo() {
      if (!this.newHourStart || !this.newHourEnd) return;
      this.manageHourReformat.push({
        start_time: { HH: this.newHourStart.split(":")[0], mm: this.newHourStart.split(":")[1] },
        end_time: { HH: this.newHourEnd.split(":")[0], mm: this.newHourEnd.split(":")[1] }
      });
      this.newHourStart = "";
      this.newHourEnd = "";
      this.addPlage = false;
    },
    deletePlage(plage, index) {
      // console.log("plage, index", plage, index);
      this.manageHourReformat.splice(index, 1);
    },

    async saveWin() {
      this.manageHour = [];
      for (let iplage = 0; iplage < this.manageHourReformat.length; iplage++) {
        const plage = this.manageHourReformat[iplage];
        this.manageHour.push({ start: plage.start_time.HH + ":" + plage.start_time.mm, end: plage.end_time.HH + ":" + plage.end_time.mm });
      }
      this.tv.de_callhours = JSON.stringify(this.manageHour);
      let response = await this.$axios.put(this.$config.server_url + "/extranet/2.0/devices/tv/" + this.row_de.de_id, this.tv);

      if (response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }
      this.$emit("input", false);
      this.$emit("saved");

      this.tv = {};
    },
    cancelWin() {
      this.$emit("input", false);
      this.$emit("canceled");
    },
    deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteWin() {
      this.$emit("input", false);
      this.$emit("canceled");
    },

    formatTvVersion() {
      try {
        // get formated tv version
        // ex 2.18.0 === [2,18,0]
        if (this.tv.de_hardwareinfo && this.tv.de_hardwareinfo.appemotivi_version) {
          let boxVersion = this.tv.de_hardwareinfo.appemotivi_version.split(".");
          this.majorTvVersion = parseInt(boxVersion[0]);
          this.minorTvVersion = parseInt(boxVersion[1]);
        }
      } catch (error) {
        console.error("cannot format tv version : ", error);
      }
    },
    async saveOptions(option, data) {},
    /**
     * isTvCompatible - check if the current tv version is compatible with new options
     * @param {Object} row_en
     * @returns {Boolean}
     */
    isTvCompatible(withWhat) {
      try {
        return this.tv && this.tv.tv_id && this.tv.tv_id.tv_options && this.tv.tv_id.tv_options.hasOwnProperty(withWhat);
      } catch (error) {
        return false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "../scss/styles.scss";
.time-picker input {
  border-radius: 4px;
}
.modal-footer {
  margin-top: 50px;
}
.screenPreview {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100px;
}
.screenPreviewChild {
  display: flex;
  margin-left: 17px;
  margin-bottom: -5px;
  width: 100px;
  justify-content: space-around;
  z-index: 1;
  height: 35px;
}
.screenPreviewTv {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  font-weight: 100;
  z-index: 0;
}
.section-subtitle {
  color: #199aaa;
  font-weight: bold;
}
h5 {
  color: #91295a;
  margin-bottom: 1rem;
}
</style>
